// TODO : 이 컴포넌트 포함하여 Form 컴포넌트는 추후 정리 필요
import * as React from 'react';
import {
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Controller } from "react-hook-form";

const FormInputAutoComplete = ({ id="auto_complete", name, control, setValue, defaultValue, label, size = "small", options, onCustomChange, errorStyle, ...props }) => {
  const [selectValue, setSelectValue] = React.useState(defaultValue || '');
  const [inputValue, setInputValue] = React.useState('');

  return (
    <FormControl size={size} fullWidth>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange },
          fieldState: { error },
          formState,
        }) => (
          <Autocomplete
            value={selectValue}
            onChange={(event, newValue) => {
              console.log(newValue)
              onChange(event);
              setSelectValue(newValue);
              setValue && setValue(name, newValue);
              onCustomChange && onCustomChange(event, newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id={id}
            options={options}
            {...props}
            renderInput={
              (props) => {
                return <TextField
                  {...props}
                  label={label}
                  size={size}
                  helperText={errorStyle?.message && error ? error.message : null}
                  error={errorStyle?.border && !!error}
                />
              }
            }
          />
        )}
      />
    </FormControl>
  );
}

export default FormInputAutoComplete;
