import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";

import * as gorderActions from "../../store/gorder";

// 아래 form components의 name과 연계
const defaultValues = {
  comments: "",
};

const GOrderProcessDialog = ({
  open,
  setOpen,
  id,
  setParentOpen,
  refresh,
  // onClose,
}) => {  
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues });

  const sendOrderDirect = ({ id, status, comments }) => gorderActions.sendDirect({ id, status, comments })

  const onSubmit = async ({ comments }) => {
    setErrors([]);
    
    setLoading(true);
    
    await sendOrderDirect({ id, status: 'SEND', comments });

    setTimeout(() => {
      setLoading(false);
      handleDialogClose();
    }, 1000);

    // selectAllByStatusByQuery(['SEND', 'RECEIVE']);
    // onClose && onClose();

    // if (from?.params?.gprojectId) { // TODO : 추후 Drawer 방식으로 변경할 것
    //   await selectAllByProjectByQuery(from.params.gprojectId);
    // } else {
    //   await selectAllByQuery();
    // }

    setTimeout(() => {
      setParentOpen && setParentOpen(false);
      refresh && refresh();
    }, 1500);
  }

  const handleDialogClose = () => {
    setValue("comments", "");
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {"발주 전송"}
      </DialogTitleClose>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"노트"}
              multiline
              rows={3}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleSubmit(onSubmit)}>{"전송"}</Button> */}
        <LoadingButton
          size="small"
          onClick={() => handleSubmit(onSubmit)('save')}
          loading={loading}
        >
          {"저장"}
        </LoadingButton>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GOrderProcessDialog;
