import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Filter2,
} from '@mui/icons-material';
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
} from "../../utils";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import * as gcomponentActions from "../../store/gcomponent";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import * as dialogActions from "../../store/components/dialog";
// import GComponentConfirmDialog from "./GComponentDeleteConfirmDialog";

const theme = createTheme();

const GComponentChildManagement = () => {

  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [loadedChild, setLoadedChild] = useState(false);
  const [loadedChildItems, setLoadedChildItems] = useState(false);

  const rowsChild = useSelector((state) => state.gcomponent.gcomponentsChild);
  const rowsChildItems = useSelector((state) => state.gcomponentItem.gcomponentItems);
  const selectedRows = useSelector((state) => state.datagrid.selectedRows);

  const dispatch = useDispatch();

  const select = (id) => dispatch(gcomponentActions.select(id))
  const selectByPid = (pid) => dispatch(gcomponentActions.selectByPid(pid))
  const modifyGComponentType = ({ id, type }) => dispatch(gcomponentActions.modifyType({ id, type }))
  const selectParentAllByQuery = () => dispatch(gcomponentActions.selectAllByQuery())
  const reorder = ({ type, id }) => gcomponentActions.reorder({ type, id })

  useEffect(() => setLoadedChild(true), [rowsChild])
  useEffect(() => setLoadedChildItems(true), [rowsChildItems])

  // 속성 다이얼로그(GComponentDialog), 삭제 Confirm 다이얼로그(GComponentConfirmDialog)는 모두 상위의 GComponentManagement에 있다.
  const handleSelect = async ({ type, params }) => {
    const { id } = params;
    if (type === "detail") {
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'R' }));
    } else if (type === "edit") {
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'U' }));
    } else if (type === "paste") { // 복사하기는 수정하기나 상세보기와 같고, 모드만 등록모드(자동아이디 부여)임
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'C' }));
    } else if (type === "delete") {
      dispatch(confirmDialogActions.setOptions({ open: true, params, action: remove }));
    }
  }

  const columnsChild = useMemo(() => [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 100,
    //   hide: true,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 300,
      // editable: true,
    },
    {
      field: 'type',
      headerName: '구분',
      width: 180,
      // hide: true,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let type = params.row.type;
        if (params.row.type === "") {
          type = 'none';
        }
        return (
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            size={"small"}
          >
            <ToggleButton value="none" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'none', params, e })}>{"없음"}</ToggleButton>
            <ToggleButton value="group" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'group', params, e })}>{"그룹"}</ToggleButton>
            <ToggleButton value="property" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'property', params, e })}>{"속성"}</ToggleButton>
          </ToggleButtonGroup>
        )
      },
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    // {
    //   field: 'gunitId',
    //   headerName: '단위아이디',
    //   width: 100,
    //   hide: true,
    //   // hide: true,
    //   // editable: true,
    // },
    // {
    //   field: 'gunitName',
    //   headerName: '단위',
    //   width: 140,
    //   // editable: true,
    //   description: "단위를 표시합니다.",
    // },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    // {
    //   field: 'pid',
    //   headerName: '부모아이디',
    //   width: 100,
    //   hide: true,
    //   // editable: true,
    // },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>, // TODO : followCursor ???
      width: checked ? 200 : 70,
      // description: "수정/삭제",
      type: 'actions',
      getActions: (params) => generateActionsChild(params),
    },
  ], [handleSelect]);

  const generateActionsChild = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      const one = rowsChild?.length === 1 ? true : false;
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F' || one} // 검색한 행이 하나면 정렬관련 버튼 모두 비활성화
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F' || one}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L' || one}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L' || one}
          />
        ]
      );
    }

    return arrActions;
  }

  const handleChangeToggle = async ({ type, params, e }) => {
    e.stopPropagation(); // 행선택 기본 기능 발생하지 않도록 제어

    await modifyGComponentType({ id: params.id, type }) // "구분" 수정
    await selectParentAllByQuery(); // 상위 구성요소 목록 재검색

    // 하위 구성요소 목록을 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      selectByPid(selectedRows[0].id);
      setTimeout(() => setLoadedChild(true), 300);
    }
  }

  const handleSelectUpDown = async ({ type, id }) => {
    await reorder({ type, id }); // 순서정렬
    await selectParentAllByQuery(); // 상위 구성요소 목록 재검색

    // 하위 구성요소 목록을 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      selectByPid(selectedRows[0].id);
      setTimeout(() => setLoadedChild(true), 300);
    }
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const columnsChildItems = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 300,
    //   hide: true,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    // {
    //   // field: 'gcomponentName',
    //   field: 'gName',
    //   headerName: '분류',
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: 'applyType',
    //   headerName: '유리사양적용',
    //   width: 120,
    //   // hide: true,
    //   // editable: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => {
    //     const applyType = params.value;
    //     let text = "";
    //     if (applyType === 'productName') {
    //       text = "이름";
    //     } else if (applyType === 'etc') {
    //       text = "비고";
    //     } else if (applyType === 'none') {
    //       text = "적용안함";
    //     }

    //     return text;
    //   },
    // },
    {
      field: 'itemType',
      headerName: '구분',
      width: 120,
      // hide: true,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const itemType = params.value;
        let text = "";
        if (itemType === 'RAW_MATERIAL') {
          text = "원자재";
        } else if (itemType === 'SUB_MATERIAL_PROCESS') {
          text = "가공부자재";
        } else if (itemType === 'NONE') {
          text = "속성";
        }

        return text;
      },
    },
    // {
    //   field: 'gclient',
    //   headerName: '제조사',
    //   width: 150,
    //   // editable: true,
    //   // valueGetter: (params) => params.value?.name,
    //   renderCell: (params) => {
    //     if (params.value && Array.isArray(params.value) && params.value.length > 0) {
    //       return params.value.length === 1
    //         ?
    //           (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers([params.value[0].gclient])}>{params.value[0].gclient.name}</Button>)
    //         :
    //           (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers(params.value.map(val => val.gclient))}>{"2+"}</Button>)
    //     };
    //   }
    // },
    // {
    //   field: 'g04docu',
    //   headerName: '규격',
    //   width: 300,
    //   // editable: true,
    //   // valueGetter: (params) => {
    //   //   if (params.row.g04docuName && params.row.gstandardNo) {
    //   //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
    //   //   }

    //   //   return "";
    //   // },
    //   renderCell: (params) => {
    //     if (params.row.g04docuName && params.row.gstandardNo) {
    //       return <>{params.row.g04docuName}<Chip label={params.row.gstandardNo} size="small" sx={{ ml: 1 }}></Chip></>;
    //     } else {
    //       return "";
    //     }
    //   }
    // },
    {
      field: 'price',
      headerName: '단가',
      width: 100,
      // editable: true,
      type: 'number',
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  const remove = (params) => {
    const { id: removeId } = params;
    dispatch(gcomponentActions.remove(removeId))
      .then(async res => {
        // 'await' has no effect on the type of this expression. 그러나 상위 데이터가 검색되기를 기다리고 나서 하위를 검색하면
        // 하위목록이 먼저 보여지고 극단적으로 한참후에 상위목록이 보여지는 문제를 막을 수 있어 await를 사용함
        await selectParentAllByQuery(); // 하위 구성요소를 삭제하면 상위 구성요소를 재검색해야 함

        // 하위 요소를 삭제하고 하위 요소 목록을 다시 조회해야 하므로 선택한 상위 요소의 하위 요소를 다시 검색
        if (selectedRows.length === 1) {
          selectByPid(selectedRows[0].id);
        }
      })
      .catch(async (res) => {
        // const data = await res.json(); // TODO : 예외처리가 필요하면 예외처리 할 것
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={10} display="flex" alignItems="center">
            <Filter2 />
            <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"하위 구성요소"}</Typography>
          </Grid>
          {
            selectedRows && ((selectedRows.length === 1 && selectedRows[0].type !== 'property') || selectedRows.length === 0) && (
              <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={checked}
                      onChange={handleChangeOrder}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={"순서"}
                />
              </Grid>
            )
          }
        </Grid>
        {
          selectedRows && ((selectedRows.length === 1 && selectedRows[0].type !== 'property') || selectedRows.length === 0) && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                loading={!loadedChild}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                disableMultipleRowSelection={true}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={rowsChild}
                columns={columnsChild}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                columnHeaderHeight={38}
                rowHeight={34}
                onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              />
            </div>
          )
        }
        {
          selectedRows && selectedRows.length === 1 && selectedRows[0].type === 'property' && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                loading={!loadedChildItems}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                disableMultipleRowSelection={true}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={rowsChildItems}
                columns={columnsChildItems}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                columnHeaderHeight={38}
                rowHeight={34}
              />
            </div>
          )
        }
      </Box>
    </ThemeProvider>
  )
};

export default GComponentChildManagement;
