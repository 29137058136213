// TODO : 추후 FormInputAutoComplete와 통합 필요 (value 부분 객체가 아닌 string으로 수정)
import * as React from 'react';
import {
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Controller } from "react-hook-form";

const FormInputAutoComplete = ({ id="auto_complete", name, control, setValue, defaultValue, label, size = "small", options, onCustomChange, errorStyle, ...props }) => {
  const [selectValue, setSelectValue] = React.useState(defaultValue || '');
  const [inputValue, setInputValue] = React.useState('');

  return (
    <FormControl size={size} fullWidth>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange },
          fieldState: { error },
          formState,
        }) => (
          <Autocomplete
            value={selectValue}
            onChange={(event, newValue) => {
              const { id } = newValue;
              onChange(event);
              setSelectValue(newValue);
              // setValue && setValue(name, newValue);
              // onCustomChange && onCustomChange(event, newValue);
              setValue && setValue(name, id);
              onCustomChange && onCustomChange(event, id);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id={id}
            options={options}
            {...props}
            renderInput={
              (renderProps) => {
                // console.log(props)
                return <TextField
                  {...props}
                  {...renderProps}
                  label={label}
                  size={size}
                  helperText={errorStyle?.message && error ? error.message : null}
                  error={errorStyle?.border && !!error}
                />
              }
            }
          />
        )}
      />
    </FormControl>
  );
}

export default FormInputAutoComplete;
