import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, useGridApiContext, koKR } from '@mui/x-data-grid-pro';
// import { bgBG as pickersKoKR } from '@mui/x-date-pickers';
// import { bgBG as coreKoKR } from '@mui/material/locale';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Table,
  TableContainer,
  TableBody,
  // TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  DesignServices,
  SlowMotionVideo,
  SwitchAccessShortcutAdd,
  Compress,
  Dehaze,
  Add,
  Remove,
}
from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import usePrevious from "../../hook/usePrevious";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../accordion";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../../datagrid";
import {
  dateFormat,
  objectEmptyCheck,
  hideWatermark,
  getTextColorByBackgroundColor,
} from "../../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../../form";
import NumberFormatCustom from "../../form/inputProps/NumberFormatCustom";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../../dialog";

import * as gworkOrderActions from "../../../store/gworkOrder";

const defaultValues = {

};

const theme = createTheme();

const GWorkOrderStatusByGLineDialog = ({
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputMode, setInputMode] = useState('basic');
  const [detailLengthByGProcess, setDetailLengthByGProcess] = useState(0);
  const [editingAmount, setEditingAmount] = useState(false);

  const [searchParams] = useSearchParams();
  

  const handleDialogClose = () => {
    setOpen(false);
  };
  
  const gworkOrderDetailsByGLine = useSelector((state) => state.gworkOrderDetail.gworkOrderDetailsByGLine);
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // 데이터 관리
  const dispatch = useDispatch();
  
  useEffect(
    async () => {

    }, [dispatch]
  );

  useEffect(
    () => {
      console.log(selectedRow)
    }, [selectedRow]
  )

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 120,
      hide: true,
    },
    {
      field: 'gplanName',
      headerName: '일정관리번호',
      width: 340,
    },
    {
      field: 'schedule',
      headerName: '일정',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'currentSpecification',
      headerName: '사양',
      width: 320,
    },
    {
      field: 'width',
      headerName: '가로',
      width: 120,
      type: 'number',
    },
    {
      field: 'height',
      headerName: '세로',
      width: 120,
      type: 'number',
    },
    {
      field: 'amount',
      headerName: '수량',
      width: 120,
      type: 'number',
    },
    {
      field: 'amountCompleted',
      headerName: '생산',
      width: 120,
      type: 'number',
    },
    {
      field: 'amountIncomplete',
      headerName: '미생산',
      width: 120,
      type: 'number',
    },
    {
      field: 'amountDefect',
      headerName: '결품',
      width: 120,
      type: 'number',
    },
  ];

  // 아래는 목록에서 한 행 설정시 selectedRow값이 있는 상태로 호출되고, 등록하기 누르면 목록에서 init~하여 redux 통해서 null 설정되어 selectedRow값이 null인채로 호출됨
  useEffect(
    async () => {
      console.log(selectedRow)
      // selectedRow.id => gprocessId
    }, [selectedRow]
  );

  useEffect(
    () => {
      console.log(gworkOrderDetailsByGLine)
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setTimeout(async () => {
        await hideWatermark();
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      }, 300);
    }, [gworkOrderDetailsByGLine]
  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Dialog
          open={open}
          onClose={handleDialogClose}
          PaperComponent={PaperComponent}
          fullWidth={true} // TODO : fullWidth 없어서 사이즈가 작게 나와 왜 그런지 한참 헤매였음
          maxWidth="lg"
          scroll="body"
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            color={`${getTextColorByBackgroundColor(selectedRow?.gprocessColor)}`}
            style={{ cursor: 'move', backgroundColor: `${selectedRow?.gprocessColor}` }}
            buttonColor={`${getTextColorByBackgroundColor(selectedRow?.gprocessColor)}`}
          >
            {`생산현황 - ${selectedRow?.name}`}
          </DialogTitleClose>
          <DialogContent dividers>
            {/* TODO : 아래 ul 때문에 공간이 생김 */}
            <ul>
              {/* {errors.map((error, idx) => <li key={idx}>{error}</li>)} */}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ height: 500, width: '100%' }}>
                  <DataGridPro
                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                    columnHeaderHeight={38}
                    rowHeight={34}
                    sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: LinearProgress,
                    }}
                    loading={!loaded}
                    rows={gworkOrderDetailsByGLine}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    // onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default GWorkOrderStatusByGLineDialog;
