import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import  {
  Add,
  Remove,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FilePond } from 'react-filepond';

import { gcoreServerUrl } from '../../config';
import uploadLabels from '../../FildPondLabel';
import {
  FormInputCheckbox,
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gstandardActions from "../../store/gstandard";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";
import * as g04docuFileActions from "../../store/g04docuFile";
// import * as alertDialogActions from "../../store/components/alertDialog";

// import G04docuFileAlertDialog from "./G04docuFileAlertDialog";

const today = new Date();
let endDate = new Date();

// 아래 form components의 name과 연계
const defaultValues = {
  startDate: today.getDateWithStartHours(),
  // endDate: endDate.getDateWithEndHours(),
  endDate: null,
  validYN: false,
};

let pond;

const G04docuFileDialogEtc = ({
  open,
  setOpen,
  selectedRow,
  gclientId,
  gclient,
  refresh,
  selectedGComponentItemType,
}) => {
  const { g04docuEtcId, title, documentType, documentPath/*, startDate, endDate*/ } = selectedRow;
  
  const [alertInfo, setAlertInfo] = useState({});
  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentPath, setUploadedDocumentPath] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setFiles([]);
    setDisabled(true);
    // setSelectedDocumentPath("");
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const createGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, gclient, documentPath, documentType, validYN, startDate, endDate }) => dispatch(gclientG04docuEtcMapActions.create({ gclientId, g04docuEtcId, gclient, documentPath, documentType, validYN, startDate, endDate }))
  const updateGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuEtcMapActions.modifyDate({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }))
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

  useEffect(
    () => {
    }, [dispatch]
  )

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === "endDate") {
            setValue(item, selectedRow[item] ? (new Date(selectedRow[item])) : value);
          } else {
            setValue(item, selectedRow[item] || value);
          }
        }

        // 그외 설정할 것들은 여기서 한다.
        selectedRow["endDate"] && documentPath && setDisabled(false);
      }
    }, [selectedRow]
  );

  useEffect(
    async () => {
      if (open && documentPath) {
        const arr = documentPath.split("/");
        const fileName = arr[arr.length - 1];

        const res = await downloadG04docuFile(documentPath);
        const blob = await res.blob();
        
        setFiles(
          [{
            // source: blob,
            source: JSON.stringify({ file: documentPath }),
            options: {
              type: 'limbo',
              file: {
                name: fileName,
                size: blob.size,
                type: blob.type,
              },
            }
          }]
        )
      }

      // selectedStartDate && setValue('startDate', selectedStartDate);
      // selectedEndDate && setValue('endDate', selectedEndDate);
    }, [open]
  )

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    console.log(name, value)
    console.log({ files, documentPath, uploadedDocumentPath });
    if ((documentPath || uploadedDocumentPath) && getValues("endDate")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setValue(name, value);
    setValue("validYN", false);
    return true;
  }

  const handleClickUpdateDate = async () => {
    // setLoading(true);

    // 날짜를 변경하지 않고 처음 조회해 온 상태는 getValues("endDate")는 string이므로 아래 코드에서 getDateWithEndHours 함수 호출시 오류 발생
    // 따라서 string일 경우 date로 변경
    let endDate = getValues("endDate");
    if (endDate && typeof endDate === 'string') {
      endDate = new Date(endDate)
    } else {
      const valid = getValues("validYN");
      if (!valid && !endDate) {
        // TODO : alertDialogActions을 사용하여 AlertDialog를 띄우면 Profile에서 배경이 너무 어둡게 나와 setAlertInfo를 사용하여 이 컴포넌트에서 직접 AlertDialog를 띄움.
        // 아마도 Dialog depth가 다른 것 같음
        // const messageAlert = (<div>
        //   <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
        //   <span>{"이 유효하지 않습니다."}</span>
        // </div>);
        // dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (
            <div>
              <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
              <span>{"이 유효하지 않습니다."}</span>
            </div>
          ),
          open: true,
        });

        return false;
      }
    }

    await updateGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath: documentPath || uploadedDocumentPath, validYN: getValues("validYN"), startDate: getValues("startDate"), endDate: endDate?.getDateWithEndHours() });
    
    // setTimeout(() => setLoading(false), 1000);
    
    selectedGComponentItemType ? refresh(gclientId, selectedGComponentItemType) : refresh(gclientId);

    return true;
  }

  const handleChangeCheckButton = (event, checked, name) => {
    console.log({ event, checked, name });
    if (checked) {
      setValue("endDate", null);
    }
  }

  const save = (mode) => {
    // server option의 process 시작됨 (창닫기는 server option의 process 안에서 실행됨)
    pond.processFiles().then(async () => {
      const completed = document.getElementsByClassName("filepond--processing-complete-indicator"); // TODO : 현재 상태를 파악하기 위한 방법을 몰라 이와 같이 요소로 검색함
      let result = true;
      if (completed.length > 0) { // 완료된 상태에서는 만료일 관련 정보만 업데이트 한다.
        result = await handleClickUpdateDate();
      }
  
      if (mode === 'close' && result) {
        setTimeout(() => handleDialogClose(), 300);
      }
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {`${title} 첨부`}
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {/* <Grid item xs={4}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"유효기간 시작"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                    value={getValues("startDate")}
                    setValue={setValue}
                    // onEdit={checkEdit}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"만료일"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "endDate")}
                    value={getValues("endDate")}
                    setValue={setValue}
                    getValues={getValues}
                    // onEdit={checkEdit}
                  />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"flex-start"} alignItems={"center"}>
                  {/* <LoadingButton
                    variant={"contained"}
                    onClick={handleClickUpdateDate}
                    loading={loading}
                    // TODO : 파일첨부, 날짜수정 등 동작이나 값 변경 useEffect등을 통해 disabled 조작하도록 할 것
                    // disabled={files?.length === 0}
                    // disabled={!(documentPath || uploadedDocumentPath) || files?.length === 0}
                    // disabled={(documentPath || uploadedDocumentPath) && files?.length > 0 && getValues("endDate") ? false : true}
                    // disabled={(documentPath || uploadedDocumentPath)  ? false : true}
                    disabled={disabled}
                  >
                    {"만료일 변경"}
                  </LoadingButton> */}
                  <FormInputCheckbox
                    name="validYN"
                    control={control}
                    setValue={setValue}
                    onChangeCheckValue={handleChangeCheckButton}
                  />
                  <Typography>{"만료일 없음"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FilePond
                  ref={ref => pond = ref}
                  files={files}
                  credits={false}
                  // allowDownloadByUrl={false} // by default downloading by URL disabled
                  allowMultiple={false}
                  allowReplace={false}
                  allowProcess={false}
                  allowRevert={false}
                  instantUpload={false}
                  acceptedFileTypes={['image/*', 'application/pdf']}
                  onupdatefiles={setFiles} // onupdatefiles(files)
                  labelIdle={uploadLabels.labelIdle}
                  labelInvalidField={uploadLabels.labelInvalidField}
                  labelFileWaitingForSize={uploadLabels.labelFileWaitingForSize}
                  labelFileSizeNotAvailable={uploadLabels.labelFileSizeNotAvailable}
                  labelFileLoading={uploadLabels.labelFileLoading}
                  labelFileLoadError={uploadLabels.labelFileLoadError}
                  labelFileProcessing={uploadLabels.labelFileProcessing}
                  labelFileProcessingComplete={uploadLabels.labelFileProcessingComplete}
                  labelFileProcessingAborted={uploadLabels.labelFileProcessingAborted}
                  labelFileProcessingError={uploadLabels.labelFileProcessingError}
                  labelFileProcessingRevertError={uploadLabels.labelFileProcessingRevertError}
                  labelFileRemoveError={uploadLabels.labelFileRemoveError}
                  labelTapToCancel={uploadLabels.labelTapToCancel}
                  labelTapToRetry={uploadLabels.labelTapToRetry}
                  labelTapToUndo={uploadLabels.labelTapToUndo}
                  labelButtonRemoveItem={uploadLabels.labelButtonRemoveItem}
                  labelButtonAbortItemLoad={uploadLabels.labelButtonAbortItemLoad}
                  labelButtonRetryItemLoad={uploadLabels.labelButtonRetryItemLoad}
                  labelButtonAbortItemProcessing={uploadLabels.labelButtonAbortItemProcessing}
                  labelButtonUndoItemProcessing={uploadLabels.labelButtonUndoItemProcessing}
                  labelButtonRetryItemProcessing={uploadLabels.labelButtonRetryItemProcessing}
                  labelButtonProcessItem={uploadLabels.labelButtonProcessItem}
                  server={{
                    url: gcoreServerUrl,
                    timeout: 700000,
                    process: {
                        url: `/api/g04docuFiles/uploadFile`,
                        ondata: (formData) => {
                          // console.log("server:process:ondata");
                          // TODO : 이렇게 body로 데이터를 넘길지 header를 사용할지 추후 검토. 우선은 body 사용
                          formData.append('gclientId', gclientId);
                          formData.append('gclientName', gclient?.name);
                          formData.append('docuFileType', documentType);                          
                          // 주의 : build하면 files가 []임. 원인파악 안됨. 확인결과 pond.getFiles()는 사용가능하여 이 코드로 대체
                          // formData.append('fileName', files[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
                          formData.append('fileName', (pond.getFiles())[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
                          formData.append('validYN', getValues("validYN"));
                          // formData.append('endDate', getValues('endDate')); // 이렇게 보내면 null이 string으로 전달됨
                          const newEndDate = getValues('endDate')?.getDateWithEndHours();
                          if (newEndDate) { // newEndDate가 없으면 서버에서 endDate는 undefined임
                            formData.append('endDate', newEndDate);
                          }
                          
                          // console.log(formData);
                          return formData;
                        },
                        onerror: (res) => {
                          console.log(res);
                          if (typeof res === 'string') {
                            const jsonRes = JSON.parse(res);
                            if (jsonRes.msg === 'invalid endDate') {
                              // const messageAlert = (<div>
                              //   <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
                              //   <span>{"이 유효하지 않습니다."}</span>
                              // </div>);
                              // dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));
                              setAlertInfo({
                                titleAlert: "안내",
                                messageAlert: (
                                  <div>
                                    <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
                                    <span>{"이 유효하지 않습니다."}</span>
                                  </div>
                                ),
                                open: true,
                              });
                            }
                          }
                        },
                        onload: /*async*/ (res) => { // 서버에서 api 동작 후 리턴되는 callback
                          // alert("server:process:onload1");
                          console.log("server:process:onload1");
                          console.log(res);
                          console.log(JSON.parse(res).file);
                          
                          createGClientG04docuEtcMap({ gclientId, g04docuEtcId, gclient, documentPath: JSON.parse(res).file, documentType, validYN: getValues("validYN"), startDate: getValues("startDate"), endDate: getValues("endDate")?.getDateWithEndHours() })
                            .then(response => {
                              // console.log(response);
                              selectedGComponentItemType ? refresh(gclientId, selectedGComponentItemType) : refresh(gclientId);

                              setUploadedDocumentPath(JSON.parse(res).file);
                              
                              // setTimeout(() => handleDialogClose(), 1000);
                            });

                          // alert("server:process:onload2");
                          console.log("server:process:onload2");
                          return res;
                        },
                    },
                    // custom
                    // process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    //   const formData = new FormData();
                    //   formData.append(fieldName, file, file.name);
                    //   formData.append('gclientId', gclientId);
                    //   formData.append('docuFileType', docuFileType);

                    //   // store 사용
                    //   window.fetch(`${gcoreServerUrl}/api/g04docuFiles/uploadFile`, {
                    //     method: 'POST',
                    //     body: formData,
                    //   })
                    // },
                    revert: {
                      url: `/api/g04docuFiles/deleteFile`,
                      method: 'DELETE',
                      // 중요: 아래 헤더가 없으면 backend에서 req.body를 얻지 못함
                      headers: {
                        "Content-Type": "application/json"
                      },
                      onload: /*async*/ (res) => { // 서버에서 api 동작 후 리턴되는 callback
                        // alert("server:revert:ondata1");
                        console.log("server:revert:ondata1");
                        console.log(res);
                        console.log(JSON.parse(res).file);
                        
                        removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath: JSON.parse(res).file })
                          .then(response => {
                            console.log(response);
                            setValue("endDate", null);
                            setUploadedDocumentPath("");
                            setDisabled(true);
                            selectedGComponentItemType ? refresh(gclientId, selectedGComponentItemType) : refresh(gclientId);
                          });
                        
                        // alert("server:revert:ondata2");
                        console.log("server:revert:ondata2");
                        
                        return res;
                      },
                    },        
                  }}
                >
                </FilePond>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleClickUpload}
            disabled={files?.length === 0}
          >
            {"업로드"}
          </Button> */}
          <Button onClick={save}>{"저장"}</Button>
          <Button onClick={() => save('close')}>{"저장 후 닫기"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      {/* <G04docuFileAlertDialog /> */}
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default G04docuFileDialogEtc;
