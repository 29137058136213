import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import * as XLSX from "xlsx";
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gglassActions from "../../store/gglass";
import SelectGGlassTemplate from "./SelectGGlassTemplate";

const theme = createTheme();

const getFlags = () => [
  { isoCode: "swe" },
  { isoCode: "deu" },
  { isoCode: "mex" },
  { isoCode: "" }
];

const getColumns = () => [{ columnId: "flag", width: 150 }];

const headerRow = {
  rowId: "header",
  height: 40,
  cells: [{ type: "header", text: "Flags" }]
};

const getRows = (flags) => [
  headerRow,
  ...flags.map((flag, idx) => ({
    rowId: idx,
    height: 60,
    cells: [{ type: "flag", text: flag.isoCode }]
  }))
];

const GOrderExcelTest = ({
  
}) => {
  
  const [flags] = React.useState(getFlags());

  const rows = getRows(flags);
  const columns = getColumns();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              {/* <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // startIcon={<Add />}
                onClick={handleClickImportExcel}
              >
                {"엑셀 가져오기"}
              </Button> */}
              {/* <FileUpload
                text={"엑셀 가져오기"}
                extension={".xls,.xlsx"}
                id={"excelImport"}
                handleFile={(e) => handleUploadFile(e)}
              /> */}
            </Grid>
          </Grid>
          {
            rows?.length > 0 && (
              <div style={{ height: 800, width: '100%', overflow: 'scroll' }}>
                <ReactGrid
                  rows={rows}
                  columns={columns}
                  customCellTemplates={{ flag: new SelectGGlassTemplate() }}
                />
              </div>
            )
          }
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GOrderExcelTest;
