import React, { useEffect } from "react";
import {
  FormControl,
  TextField,
} from '@mui/material';
import { Controller } from "react-hook-form";
// TODO : 추후 Locale 적용할 수 있음
import koLocale from 'date-fns/locale/ko';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// TODO : 시간 부분 처리 고려할 것
// 본 컴포넌트의 입출력은 문자열이 아닌 Date 객체로 한다.
// 단, 유효기간 설정시 사용하는 경우 마지막 기간의 날짜를 ~23:59:59.999Z(~T14:59:59.999Z) 로 설정하는데, 이때 본 컴포넌트의 날짜 변경시 milliseconds 부분이 000으로 되므로 재설정 필요
const FormInputDate = ({ value, setValue, getValues, name, control, size = "small", label, color, customWidth, focused, onEdit, onChangePeriodValue, views=null, inputFormat='yyyy-MM-dd', mask='____-__-__', ...props}) => {
  const [datePickerValue, setDatePickerValue] = React.useState(null);

  const displayDateValue = () => {
    // console.log(value);
    // console.log(datePickerValue);
    if (getValues && !getValues(name)) {
      return getValues(name);
    }

    return datePickerValue || value;
  }

  useEffect(() => {
    if (datePickerValue) {
      setValue && setValue(name, datePickerValue); // setValue에서 렌더링 발생
    }
  }, [datePickerValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
      <FormControl size={size} fullWidth>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <DatePicker
              // views={['year']}
              views={views}
              inputFormat={inputFormat}
              mask={mask}
              label={label}
              // value={value}
              value={displayDateValue()}
              // onChange={onChange}
              onChange={
                (newValue) => {
                  if (onChangePeriodValue && !onChangePeriodValue(newValue)) {
                    return;
                  }
                  
                  setDatePickerValue(newValue);
                  onChange(newValue);
                  // setValue && setValue(name, value || datePickerValue);
                  onEdit && onEdit();
                }
              }
              renderInput={(params) => {
                // console.log(params)
                return <TextField size={size} color={color} focused={focused} {...params} {...props} sx={{ input: { color: color === "error" ? "red" : "" }, width: customWidth }} />
              }}
              // <TextField label="Outlined secondary" color="error" focused />
              {...props}
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  )
}

export default FormInputDate;