import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, /*useFieldArray*/ } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Delete,
  Remove,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDate,
  FormInputDateRangePicker,
  // FormInputSwitch,
  FormInputText,
  FormInputDropdown,
} from "../form";
import NumberFormatCustom from "../form/inputProps/NumberFormatCustom";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  addDays,
  dateFormat,
} from "../../utils";
import * as gunitActions from "../../store/gunit";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gglassActions from "../../store/gglass";
// import * as gprojectGlassPriceActions from "../../store/gprojectGlassPrice";

import { GGlassForm } from "../GGlass";
import AreaTypeButton from "./area/AreaTypeButton";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  comments: "",
  gtypeId: "",
  specification: "",
  otherSpecs: "",
  labelPositionView: "inout",
  labelPosition: "TopLeft",

  // 단가 변환용
  priceConverted: "", // 초기값 설정안되면 setValue시 label과 값이 겹쳐 보임
};

const GProjectGlassDialog = ({
  open,
  getProjectValues,
  setOpenGGlass,
  gGlass,
  gtypeDetailsWithGComponent,
  gtypes,
  refresh,
  mode,
  setMode,
  // from,
  handleRegisterGGlassToProject,
  handleModifyGGlassToProject,
  gtypeDetailsWithGComponentLength,
  selectedGcomponentItems,
  setSelectedGcomponentItems,
}) => {
  console.log(gtypeDetailsWithGComponent);
  const [modify, setModify] = useState(false);
  const [errors, setErrors] = useState([]);
  const [gprojectGlassPrices, setGprojectGlassPrices] = useState([]);
  const [selectedRowGGlass, setSelectedRowGGlass] = useState({});
  // const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [gglassId, setGglassId] = useState("");
  const [areaGUnits, setAreaGUnits] = useState([]);
  // const [areaType, setAreaType] = useState("");
  const [expandConvertPrice, setExpandConvertPrice] = useState(false);
  const [disableAddPriceButton, setDisableAddPriceButton] = useState(true);
  const [alertInfo, setAlertInfo] = useState({});
  const [openAlert, setOpenAlert] = useState(false);

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  // const gunits = useSelector((state) => state.gunit.gunits);

  // 데이터 관리
  const selectGUnitsAllByTypeDirect = (type) => gunitActions.selectAllByTypeDirect(type)

  const initGTypeDetailsWithGComponent = () => dispatch(gtypeDetailActions.initWithComponent())
  // const selectPricesDirect = (gprojectId, gglassId) => gprojectGlassPriceActions.selectDirect(gprojectId, gglassId);
  const selectDirectGlass = (id) => gglassActions.selectDirect(id);

  const onSubmit = ({ id, no, name, /*code, */ standard, specification, otherSpecs, comments, gtypeId, labelPositionView, labelPosition }, mode) => {
    console.log({ id, no, name, /*code, */ standard, specification, otherSpecs, comments, gtypeId, /*gtypeName: gGlass.gtypeName, TODO : DB 분리*/ selectedGcomponentItems, gprojectGlassPrices, mode, labelPositionView, labelPosition });
    if (mode === 'register') {
      handleRegisterGGlassToProject({
        id,
        no,
        name,
        standard,
        specification,
        otherSpecs,
        comments,
        gtypeId,
        // gtypeName: gGlass.gtypeName, // TODO : DB 분리
        selectedGcomponentItems,
        gprojectGlassPrices,
        gglassId,
        labelPositionView,
        labelPosition,
        //
        createdAt: gGlass.createdAt,
        gprojectId: gGlass.gprojectId,
        order: gGlass.order,
        orderDetail: gGlass.orderDetail,
        updatedAt: gGlass.updatedAt,
      });
      handleGlassDialogClose();
    } else if (mode === 'modify') {
      handleModifyGGlassToProject({
        id,
        no,
        name,
        standard,
        specification,
        otherSpecs,
        comments,
        gtypeId,
        // gtypeName: gGlass.gtypeName, // TODO : DB 분리
        selectedGcomponentItems,
        gprojectGlassPrices,
        gglassId,
        labelPositionView,
        labelPosition,
        //
        createdAt: gGlass.createdAt,
        gprojectId: gGlass.gprojectId,
        order: gGlass.order,
        orderDetail: gGlass.orderDetail,
        updatedAt: gGlass.updatedAt,
      });
      handleGlassDialogClose();
    }
  }

  useEffect(
    async () => {
      // setGprojectGlassPrices([{
      //   index: uuidv4(),
      //   contractPrice: 0,
      //   orderPrice: 0,
      //   validPeriodStart: new Date(),
      //   validPeriodEnd: new Date(),
      // }]);
      const areaGUnits = await selectGUnitsAllByTypeDirect('area');
      setAreaGUnits(areaGUnits);
    }, [dispatch]
  )

  // useEffect(
  //   () => {
  //     alert("123")
  //   }, [selectedGcomponentItems]
  // )

  useEffect(
    async () => {
      setSelectedRowGGlass(gGlass);
      // 프로젝트에 등록할 때는 샘플의 id가 gglassId가 되고, 등록된 제품을 선택시에는 gglassId가 저장되어 있으므로 사용
      // console.log(gGlass)
      setGglassId(gGlass.gglassId ? gGlass.gglassId : gGlass.id);
      // TODO : 아래와 같이 left, right 구분없이 setSelectedGcomponentItems하면
      // 같은 유리 종류의 경우 전부 같은 selectedGcomponentItems값이 설정됨.
      // 따라서 left일 경우 DB 값으로 초기화 필요. 또한 아래 코드가 right일때만 동작한다면 처음 다이얼로그 띄울 때 설정값이 보이지 않음(닫았다 다시 띄우면 그때부터 보임). 추후 정확한 동작원리 재검토해볼 것
      // 아래처럼 setState가 두번연속 있으면 async이므로 오동작할 가능성 높음. 따라서 selectedGcomponentItems, setSelectedGcomponentItems을 상위 컴포넌트(GProjectDialog)로 이동
      // if (gGlass.selectedGcomponentItems) {
      //   setSelectedGcomponentItems(gGlass.selectedGcomponentItems);
      // }
      // 단가 보여주기
      if (gGlass.side === "left") { // 유리샘플
        // 아래 코드 (예)는 완전 초기화. 이렇게 하면 안되고 DB에서 조회해와야 함
        // const gcomponentItems = Object.assign([], gGlass.selectedGcomponentItems);
        // const newSelectedGcomponentItems = gcomponentItems.map(gcomponentItem => {
        //   return gcomponentItem.map(item => {
        //     return {
        //       ...item,
        //       value: {},
        //     }
        //   })
        // });
        
        // 상위 컴포넌트(GProjectDialog)로 이동
        // setSelectedGcomponentItems(newSelectedGcomponentItems);
        // const gglass = await selectDirectGlass(gGlass.id);
        // setSelectedGcomponentItems(gglass.selectedGcomponentItems);

        const validPeriodStart = (new Date(getProjectValues("startDate"))).getDateWithStartHours();
        const validPeriodEnd = (new Date(getProjectValues("endDate"))).getDateWithEndHours();

        setGprojectGlassPrices([{
          index: uuidv4(),
          contractAreaType: "m2",
          contractAreaTypeOrg: "m2",
          contractPrice: 0,
          contractPriceOrg: 0,
          orderAreaType: "평",
          orderAreaTypeOrg: "평",
          orderPrice: 0,
          orderPriceOrg: 0,
          marginRate: 0,
          // 프로젝트 유리 내역에 등록하는 등록창이므로 단가정보는 초기화 즉 데이터가 없는 상태이고, 기본 유효기간은 프로젝트 기간으로 설정한다.
          // validPeriodStart: dateFormat(getProjectValues("startDate"), 'yyyy-MM-dd 00:00:00'),
          // validPeriodEnd: dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00'),
          validPeriodStart,
          validPeriodEnd,
        }]);

        // setAreaType('m2');
        setDisableAddPriceButton(true);
      } else if (gGlass.side === "right") { // 프로젝트에 등록된 유리제품
        // setAreaType(gGlass.areaType);
        
        const newGProjectGlassPrices = gGlass.gprojectGlassPrices;
        console.log(newGProjectGlassPrices)
        const newPrices = newGProjectGlassPrices.map(price => {
          const {
            gprojectId,
            gglassId,
            order,
            contractAreaType,
            orderAreaType,
            contractPrice,
            orderPrice,
            validPeriodStart,
            validPeriodEnd,
            index,
            createdAt,
            updatedAt,
          } = price;

          let validPeriodStartColor;
          let validPeriodStartFocused;
          let validPeriodEndColor;
          let validPeriodEndFocused;

          // const validStart = dateFormat(validPeriodStart, 'yyyy-MM-dd 00:00:00');
          // const validEnd = dateFormat(validPeriodEnd, 'yyyy-MM-dd 00:00:00');
          const validStart = (new Date(validPeriodStart)).getDateWithStartHours();
          const validEnd = (new Date(validPeriodEnd)).getDateWithEndHours();

          // TODO : getProjectValues("startDate"), getProjectValues("endDate")을 dateFormat한 것이 여러군데서 사용되므로 미리할 것을 고려
          // const projectStart = dateFormat(getProjectValues("startDate"), 'yyyy-MM-dd 00:00:00');
          // const projectEnd = dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00');
          const projectStart = getProjectValues("startDate");
          const projectEnd = getProjectValues("endDate");

          if (validStart < projectStart || validStart > projectEnd) {
            validPeriodStartColor = "error";
            validPeriodStartFocused = true;
          }

          if (validEnd < projectStart || validEnd > projectEnd) {
            validPeriodEndColor = "error";
            validPeriodEndFocused = true;
          }

          // TODO : 아래 계산식이 여러번 쓰이므로 모듈화할 것
          // 수주단가 면적 기준으로 통일한 후 (수주단가-발주단가)/수주단가*100
          let marginRate = 0;
              
          const priceToConvert = orderPrice;
          const toConvertType = orderAreaType;
          const convertedType = contractAreaType;

          const convertedOrderPrice = convertPrice(toConvertType, convertedType, priceToConvert);

          marginRate = (contractPrice - convertedOrderPrice) / contractPrice * 100;

          return {
            index: index ? index : uuidv4(),
            contractAreaType,
            contractAreaTypeOrg: contractAreaType,
            orderAreaType,
            orderAreaTypeOrg: orderAreaType,
            contractPrice,
            contractPriceOrg: contractPrice,
            orderPrice,
            orderPriceOrg: orderPrice,
            marginRate,
            validPeriodStart: validStart,
            validPeriodStartColor,
            validPeriodStartFocused,
            validPeriodEnd: validEnd,
            validPeriodEndColor,
            validPeriodEndFocused,
            gprojectId,
            gglassId,
            order,
            createdAt,
            updatedAt,
          }
        });
        
        if (newPrices.length > 0) { 
          const result = newPrices.filter(price => price.validPeriodStartColor === "error" || price.validPeriodEndColor === "error");
          result.length && setAlertInfo({
            titleAlert: "안내",
            messageAlert: "프로젝트 일정이 변경되었으므로 단가의 유효기간을 확인하세요.",
            open: true,
          });

          // 마지막 행의 유효기간 마감이 프로젝트 마감일 안쪽이라면 추가버튼을 활성화하고 마감일이라면 비활성화한다.
          // const validEndLastRow = dateFormat(newPrices[newPrices.length-1]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
          // const projectEndDate = dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00');
          const validEndLastRow = newPrices[newPrices.length-1]["validPeriodEnd"];
          const projectEndDate = getProjectValues("endDate");
          if (validEndLastRow < projectEndDate) {
            setDisableAddPriceButton(false);
          } else {
            setDisableAddPriceButton(true);
          }
        }
        
        console.log(newPrices);
        setGprojectGlassPrices(newPrices);
      }
      
      // 단가환산 감추기 및 초기화
      setExpandConvertPrice(false);
      setValue("areaTypeToConvert", "평");
      setValue("priceToConvert", "");
      setValue("areaTypeConverted", "m2");
      setValue("priceConverted", "");
    }, [gGlass]
  )

  const addPrice = () => {
    // const newPriceInfos = gprojectGlassPrices; // 중요 : 안됨
    const newPriceInfos = gprojectGlassPrices.map(info => info);

    const nextDay = addDays(new Date(newPriceInfos[newPriceInfos.length-1]["validPeriodEnd"]), +1);
    const validPeriodStart = (new Date(nextDay)).getDateWithStartHours();
    const validPeriodEnd = getProjectValues("endDate");

    const { contractAreaType, orderAreaType } = newPriceInfos[newPriceInfos.length-1];
    newPriceInfos.push({
      index: uuidv4(),
      contractAreaType,
      orderAreaType,
      contractPrice: 0,
      orderPrice: 0,
      // TODO : 한곳에서 유효성 체크를 할지 추후 결정
      // validPeriodStart: addDays(new Date(newPriceInfos[newPriceInfos.length-1]["validPeriodEnd"]), +1, 'yyyy-MM-dd 00:00:00'),
      // validPeriodEnd: dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00'), 
      validPeriodStart,
      validPeriodEnd, 
    });

    console.log(newPriceInfos);

    setGprojectGlassPrices(newPriceInfos);
    setDisableAddPriceButton(true);
  }

  const deletePrice = (deleteInfo) => {
    const newPriceInfos = gprojectGlassPrices/*.map(info => info)*/.filter(info => info.index !== deleteInfo.index);
    
    // TODO : 한곳에서 유효성 체크를 할지 추후 결정
    // const validPeriodEnd = dateFormat(newPriceInfos[newPriceInfos.length-1]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
    // const projectEndDate = dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00');
    const validPeriodEnd = newPriceInfos[newPriceInfos.length-1]["validPeriodEnd"];
    const projectEndDate = getProjectValues("endDate");
    if (validPeriodEnd >= projectEndDate) { // 정확히는 ===
      setDisableAddPriceButton(true);
    } else {
      setDisableAddPriceButton(false);
    }

    console.log(newPriceInfos);
    setGprojectGlassPrices(newPriceInfos);
  }

  const handleChange = (e, index, name) => {
    const newPriceInfos = gprojectGlassPrices.map(info => {
      const newInfo = Object.assign({}, info);
      if (index === info.index) {
        // newInfo[name.split("_")[0]] = e.target.value;
        newInfo[name] = e.target.value;
        // setValue(name, e.target.value);
      }
      return newInfo;
    });

    // console.log(newPriceInfos)
    setGprojectGlassPrices(newPriceInfos);
  }

  // 입력을 마치는 시점의 단가와 기준면적이 오리지널 값이 된다. 면적 변환시 이 값으로 기준을 삼는다.
  // 오리지널 면적과 단가는 화면에서만 필요함
  // 이후 변경하여 저장하면 이 값이 DB에 저장되고, 이 값을 불러 오면 그때 오리지널 값이 설정되고 handlerBlur에서 오리지널 값을 바꾼다.
  const handleBlur = (e, index, name) => {
    const newPriceInfos = gprojectGlassPrices.map(info => {
      const newInfo = Object.assign({}, info);
      if (index === info.index) {
        const priceOrg = newInfo[`${name}Price`];
        const areaTypeOrg = newInfo[`${name}AreaType`];

        newInfo[`${name}PriceOrg`] = priceOrg;
        newInfo[`${name}AreaTypeOrg`] = areaTypeOrg;

        // 이익률 계산
        let toConvertType = "";
        let convertedType = "";
        let priceToConvert = 0;
        let orderPrice = 0;
        // let marginRate = 0;
        let contractPrice = 0;
        if (name === "contract") {
          toConvertType = newInfo[`orderAreaTypeOrg`];
          convertedType = areaTypeOrg;
          priceToConvert = newInfo[`orderPriceOrg`];
  
          orderPrice = convertPrice(toConvertType, convertedType, priceToConvert);
          
          contractPrice = priceOrg;
          // marginRate = (priceOrg - priceConverted) / priceOrg * 100;
        } else { // name === "order"
          toConvertType = areaTypeOrg;
          convertedType = newInfo[`contractAreaTypeOrg`];
          priceToConvert = priceOrg;
  
          orderPrice = convertPrice(toConvertType, convertedType, priceToConvert);
          
          contractPrice = newInfo[`contractPriceOrg`];
          // marginRate = (newInfo[`contractPriceOrg`] - priceConverted) / newInfo[`contractPriceOrg`] * 100;
        }

        // marginRate = (contractPrice - priceConverted) / contractPrice * 100;

        newInfo["marginRate"] = (contractPrice - orderPrice) / contractPrice * 100;

      }
      return newInfo;
    });

    // console.log(newPriceInfos)
    setGprojectGlassPrices(newPriceInfos);
  }

  const handleChangePriceAreaGUnit = (areaType, index, name) => {
    const newPriceInfos = gprojectGlassPrices.map(info => {
      const newInfo = Object.assign({}, info);
      if (index === info.index) {
        newInfo[`${name}AreaType`] = areaType;
        // 기준값(~Org)로부터 변환
        const priceToConvert = newInfo[`${name}PriceOrg`];
        const toConvertType = newInfo[`${name}AreaTypeOrg`];
        const convertedType = areaType;

        const priceConverted = convertPrice(toConvertType, convertedType, priceToConvert);

        newInfo[`${name}Price`] = priceConverted;
      }
      return newInfo;
    });

    // console.log(newPriceInfos)
    setGprojectGlassPrices(newPriceInfos);
  }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, index, name) => {
    if (!value || value.toString() === "Invalid Date") {
      return false;
    }

    let validResult = true;
    const newPriceInfos = gprojectGlassPrices.map((info, idx) => {
      const newInfo = Object.assign({}, info);
      if (index === info.index) {
        const validPeriodName = name.split("_")[0];
        // const preValue = info[validPeriodName].toLocaleString();
        // const preValue = dateFormat(info[validPeriodName], 'yyyy-MM-dd 00:00:00');
        // const newValue = dateFormat(value, 'yyyy-MM-dd 00:00:00');
        const preValue = info[validPeriodName];
        const newValue = value;
        if (validPeriodName === "validPeriodStart") {
          // const validPeriodEndCurrentRow = dateFormat(gprojectGlassPrices[idx]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
          const validPeriodEndCurrentRow = gprojectGlassPrices[idx]["validPeriodEnd"];
          if (newValue > validPeriodEndCurrentRow) {
            // TODO : 추후 상위로 옮기로 redux 사용하는 방법 고려
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: "유효기간 마감일자보다 늦습니다.",
              open: true,
            });

            newInfo[validPeriodName] = preValue;
            validResult = false;
            return newInfo;
          }

          if (idx === 0) {
            // const projectStartDate = dateFormat(getProjectValues("startDate"), 'yyyy-MM-dd 00:00:00');
            const projectStartDate = getProjectValues("startDate");
            if (newValue < projectStartDate) {
              // TODO : 추후 상위로 옮기로 redux 사용하는 방법 고려
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "프로젝트 시작일자보다 빠릅니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;
              validResult = false;
              return newInfo;
            }
          } else { // 이전 유효마감일보다 이전이면 안됨
            // const validPeriodEndPrevRow = dateFormat(gprojectGlassPrices[idx-1]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
            const validPeriodEndPrevRow = gprojectGlassPrices[idx-1]["validPeriodEnd"];
            if (newValue <= validPeriodEndPrevRow) {
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "이전 유효마감일자보다 빠릅니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;
              validResult = false;
              return newInfo;
            }
          }

          newInfo.validPeriodStartColor = "";
          newInfo.validPeriodStartFocused = false;
        } else { //validPeriodName === "valiePeriodEnd"
          // const validPeriodStartCurrentRow = dateFormat(gprojectGlassPrices[idx]["validPeriodStart"], 'yyyy-MM-dd 00:00:00');
          const validPeriodStartCurrentRow = gprojectGlassPrices[idx]["validPeriodStart"];
          if (newValue < validPeriodStartCurrentRow) {
            // TODO : 추후 상위로 옮기로 redux 사용하는 방법 고려
            setAlertInfo({
              titleAlert: "안내",
              // messageAlert: "유효기간 일자보다 늦습니다.",
              messageAlert: "유효기간 시작일자보다 빠릅니다.",
              open: true,
            });

            newInfo[validPeriodName] = preValue;
            validResult = false;
            return newInfo;
          }

          // const projectEndDate = dateFormat(getProjectValues("endDate"), 'yyyy-MM-dd 00:00:00');
          const projectEndDate = getProjectValues("endDate");
          if (idx === gprojectGlassPrices.length-1) {
            if (newValue > projectEndDate) {
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "프로젝트 마감일자보다 늦습니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;
              validResult = false;
              return newInfo;
            } else if (newValue < projectEndDate ) {
              setDisableAddPriceButton(false);
            } else if (newValue === projectEndDate ) {
              setDisableAddPriceButton(true);
            }
          } else/* if (idx > 0 ) */{ // TODO : 검토 필요. GLineDialog에서 기능구현하면서 보니 이상???
            // const validPeriodStartPrevRow = dateFormat(gprojectGlassPrices[idx-1]["validPeriodStart"], 'yyyy-MM-dd 00:00:00');
            const validPeriodStartPrevRow = gprojectGlassPrices[idx+1]["validPeriodStart"];
            if (newValue >= validPeriodStartPrevRow) {
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "이후 유효시작일자보다 늦습니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;
              validResult = false;
              return newInfo;
            }
          }
          newInfo.validPeriodEndColor = "";
          newInfo.validPeriodEndFocused = false;
        }

        // newInfo[validPeriodName] = value;
        newInfo[validPeriodName] = newValue;
      }
      return newInfo;
    });

    console.log(newPriceInfos)
    setGprojectGlassPrices(newPriceInfos);

    return validResult;
  }

  const handleGlassDialogClose = () => {
    initGTypeDetailsWithGComponent();
    setOpenGGlass(false);
  }
  
  // const handleChangeAreaGUnit = (e, areaType) => {
  //   e.stopPropagation();

  //   // setAreaType(areaType);
  // }

  const handleClickConvertPrice = () => {
    setExpandConvertPrice(!expandConvertPrice);
  }

  const handlePriceConvert = () => {
    const toConvertType = getValues("areaTypeToConvert");
    const convertedType = getValues("areaTypeConverted");
    const priceToConvert = getValues("priceToConvert");
    // const priceConverted = getValues("priceConverted");

    const priceConverted = convertPrice(toConvertType, convertedType, priceToConvert);

    setValue("priceConverted", priceConverted);
  }

  const convertPrice = (toConvertType, convertedType, priceToConvert) => {
    const meterToJa = 1000/303*1000/303;
    const meterToFeet = 1000/304.8*1000/304.8;
    
    let priceConverted = 0;
    if (toConvertType === convertedType) {
      priceConverted = priceToConvert;
    } else {
      let priceMeter = "";
      const rule = `${toConvertType}-${convertedType}`;
      switch (rule) {
        case 'm2-평':
          priceConverted = priceToConvert/meterToJa;
          break;
        case 'm2-ft2':
          priceConverted = priceToConvert/meterToFeet;
          break;
        case '평-m2':
          priceConverted = priceToConvert*meterToJa;
          break;
        case '평-ft2':
          priceMeter = priceToConvert*meterToJa;
          priceConverted = priceMeter/meterToFeet;
          break;
        case 'ft2-m2':
          priceConverted = priceToConvert*meterToFeet;
          break;
        case 'ft2-평':
          priceMeter = priceToConvert*meterToFeet;
          priceConverted = priceMeter/meterToJa;
          break;
        default:
          // console.log(`Sorry, we are out of ${rule}.`);
      }
    }

    return priceConverted;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleGlassDialogClose}
        PaperComponent={PaperComponent}
        // aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitleClose
          // id="draggable-dialog-title"
          // onClose={handleDialogClose}
          // style={{ cursor: 'move' }}
        >
          {"프로젝트 유리 내역 등록"}
        </DialogTitleClose>
        <DialogContent dividers>
          <GGlassForm
            errors={errors}
            modify={gGlass.side === "left" ? false : true}
            selectedRow={selectedRowGGlass}
            gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
            selectedGcomponentItems={selectedGcomponentItems}
            setSelectedGcomponentItems={setSelectedGcomponentItems}
            gtypes={gtypes}
            control={control}
            getValues={getValues}
            setValue={setValue} // TODO : setValue가 GProject의 setValue가 아니므로 다이얼로그를 별도로 분리하여 관리할 것
            gtypeDisabled={true} // 안에서 템플릿 구조 바꾸지 못하도록 함
            fromwhere={{ from: "project", side: gGlass.side }}
            gtypeDetailsWithGComponentLength={gtypeDetailsWithGComponentLength}
          />
          <Divider sx={{ mt: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h6" component="div">
                {"단가 정보"}
                <Tooltip title={"단위환산"}>
                  <IconButton edge="end" aria-label="expand" onClick={handleClickConvertPrice}>
                    { expandConvertPrice ? <ExpandLess /> : <ExpandMore /> }
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ mt: 3, mb: 2 }} display="flex" justifyContent="flex-end" alignItems="center">
              <Typography variant="subtitle2" component="div" color="primary" sx={{ mr: 1 }}>{"기준면적단위"}</Typography>
              <ToggleButtonGroup
                color="primary"
                value={areaType}
                exclusive
                size="small"
                onChange={handleChangeAreaGUnit}
              >
                {
                  areaGUnits?.map(unit => {
                    return <ToggleButton key={unit.code} value={unit.code} sx={{ textTransform: 'none' }}>{unit.name}</ToggleButton>
                  })
                }
              </ToggleButtonGroup>  
            </Grid> */}
            {
              expandConvertPrice && (
                <Grid container spacing={2} display="flex" alignItems="center" sx={{ mt: 1, ml: 2, mb: 1, backgroundColor: '#eaeaea', borderRadius: "5px" }}>
                  <Grid item xs={12} sm={2} sx={{ mb: 2 }}>
                    <FormInputDropdown
                      name={"areaTypeToConvert"}
                      control={control}
                      label={"타입"}
                      options={
                        areaGUnits?.map(unit => {
                          return {
                            label: unit.name,
                            value: unit.code,
                          }
                        })
                      }
                      // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ mb: 2 }}>
                    <FormInputText
                      name={`priceToConvert`}
                      control={control}
                      label={"단가"}
                      // onChange={(e) => handleChange(e, info.index, `contractPrice_${info.index}`)}
                      // value={getValues("priceToConvert")}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          style: { textAlign: "right" },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ mb: 2 }}>
                    {/* {"▶"} */}
                    <Button
                      variant="contained"
                      onClick={handlePriceConvert}
                    >
                      {"변환 ▶"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ mb: 2 }}>
                    <FormInputDropdown
                      name={"areaTypeConverted"}
                      control={control}
                      label={"타입"}
                      options={
                        areaGUnits?.map(unit => {
                          return {
                            label: unit.name,
                            value: unit.code,
                          }
                        })
                      }
                      // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
                    <FormInputText
                      name={`priceConverted`}
                      control={control}
                      label={"변환된 단가"}
                      // onChange={(e) => handleChange(e, info.index, `contractPrice_${info.index}`)}
                      // value={price}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          style: { textAlign: "right" },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ mb: 2 }}>
                  </Grid>
                </Grid>
              )
            }
            
            {
              gprojectGlassPrices.map((info, idx) => {
                // console.log(gprojectGlassPrices)
                return (
                  // TODO : 아래와 같이 key의 값으로 map의 idx를 사용하는 것은 비효율적이라고 함. 고유한 id를 부여할 것을 고려
                  <Grid container spacing={1} sx={{ mt: 2 }} key={idx}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                            <AreaTypeButton
                              index={info.index}
                              name={`contract`}
                              areaGUnits={areaGUnits}
                              type={info.contractAreaType}
                              onChange={handleChangePriceAreaGUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormInputText
                              // name={`contractPrice_${info.index}`}
                              control={control}
                              // label={areaType ? `수주단가(원/${areaType})` : "수주단가(원/m2)"}
                              label={"수주단가"}
                              onChange={(e) => handleChange(e, info.index, /*`contractPrice_${info.index}`*/"contractPrice")}
                              onBlur={(e) => handleBlur(e, info.index, /*`contractPrice_${info.index}`*/"contract")}
                              value={info.contractPrice}
                              InputProps={{
                                inputComponent: NumberFormatCustom, // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                                inputProps: {
                                  style: { textAlign: "right", suffix: " 원" }, // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                            <AreaTypeButton
                              index={info.index}
                              name={`order`}
                              areaGUnits={areaGUnits}
                              type={info.orderAreaType}
                              onChange={handleChangePriceAreaGUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormInputText
                              // name={`orderPrice_${info.index}`}
                              control={control}
                              // label={areaType ? `발주단가(원/${areaType})` : "발주단가(원/m2)"}
                              label={"발주단가"}
                              onChange={(e) => handleChange(e, info.index, /*`orderPrice_${info.index}`*/"orderPrice")}
                              onBlur={(e) => handleBlur(e, info.index, /*`contractPrice_${info.index}`*/"order")}
                              value={info.orderPrice}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                inputProps: {
                                  style: { textAlign: "right", suffix: " 원" }, // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={3}>
                            <FormInputText
                              control={control}
                              label={"이익률(%)"}
                              value={info.marginRate?.toFixed(2)}
                              color={info.marginRate < 0 ? "error" : ""}
                              focused={info.marginRate < 0 ? true : false}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                inputProps: {
                                  style: { textAlign: "right", suffix: " %", color: info.marginRate < 0 ? "red" : "" }, // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormInputDate
                              name={`validPeriodStart_${info.index}`}
                              control={control}
                              label={"유효기간 시작"}
                              onChangePeriodValue={(value) => handleChangePeriod(value, info.index, `validPeriodStart_${info.index}`)}
                              color={info.validPeriodStartColor}
                              focused={info.validPeriodStartFocused}
                              value={info.validPeriodStart}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormInputDate
                              name={`validPeriodEnd_${info.index}`}
                              control={control}
                              label={"유효기간 마감"}
                              onChangePeriodValue={(value) => handleChangePeriod(value, info.index, `validPeriodEnd_${info.index}`)}
                              value={info.validPeriodEnd}
                              color={info.validPeriodEndColor}
                              focused={info.validPeriodEndFocused}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1} display="flex" justifyContent="flex-end" alignItems="center">
                            {
                              idx === 0 ? (
                                <Fab
                                  color="primary"
                                  size="small"
                                  // variant="extended"
                                  disabled={disableAddPriceButton}
                                  onClick={addPrice}
                                >
                                  <Add />
                                  {/* {"추가"}&nbsp; */}
                                </Fab>
                              ) : (
                                <Fab
                                  color="secondary"
                                  size="small"
                                  // variant="extended"
                                  onClick={() => deletePrice(info)}
                                >
                                  <Remove />
                                  {/* {"삭제"}&nbsp; */}
                                </Fab>
                              )
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            }
          </Grid>
          <Divider sx={{ mt: selectedGcomponentItems?.length > 0 ? 2 : 0 }} />
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            {
              gGlass.side === "left" ? (
                <Button onClick={handleSubmit((param) => onSubmit(param, 'register'))}>{"프로젝트에 등록"}</Button>
              ) : (
                <Button onClick={handleSubmit((param) => onSubmit(param, 'modify'))}>{"수정"}</Button>
              )
            }
            <Button onClick={handleGlassDialogClose}>{"닫기"}</Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        // open={openAlert}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GProjectGlassDialog;
