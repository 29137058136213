// import React from "react";
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  //margin: 4,
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1rem',
    maxWidth: 1000,
    // height: 100,
    backgroundColor: "rgb(255,255,255)",
    margin: 4,
    padding: 8,
    whiteSpace: "pre-line",
    border: "solid #777 1px",
    borderRadius: "10px",
    color: '#777',
  }
}));

export default StyledTooltip;