import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Grid,
} from '@mui/material';

import {
  FormInputDropdown,
} from "../form";

const defaultValues = {
  orderColumnFirst: "",
  orderByFirst: "ASC",
  orderColumnSecond: "",
  orderBySecond: "ASC",
  orderColumnThird: "",
  orderByThird: "ASC",
  orderColumnFourth: "",
  orderByFourth: "ASC",
}

const orderOptions = [{ label: "오름차순", value: 'ASC' }, { label: "내림차순", value: 'DESC' }];

const getOrderColumnOptions = () => {
  return [
    { label: "품번", value: 2 },
    { label: "가로", value: 5 },
    { label: "세로", value: 6 },
    { label: "수량", value: 7 },
  ]
};

const GWorkOrderOrderBy = ({
  handleOrder,
}) => {
  const [orderColumnFirstOptions, setOrderColumnFirstOptions] = useState(getOrderColumnOptions());
  const [orderColumnSecondOptions, setOrderColumnSecondOptions] = useState(/*getOrderColumnOptions()*/[]);
  const [orderColumnThirdOptions, setOrderColumnThirdOptions] = useState(/*getOrderColumnOptions()*/[]);
  const [orderColumnFourthOptions, setOrderColumnFourthOptions] = useState(/*getOrderColumnOptions()*/[]);

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const initOptions = () => {
    setValue("orderColumnFirst", "");
    setValue("orderByFirst", "ASC");
    setValue("orderColumnSecond", "");
    setValue("orderBySecond", "ASC");
    setValue("orderColumnThird", "");
    setValue("orderByThird", "ASC");
    setValue("orderColumnFourth", "");
    setValue("orderByFourth", "ASC");

    setOrderColumnFirstOptions(getOrderColumnOptions());
    setOrderColumnSecondOptions([]);
    setOrderColumnThirdOptions([]);
    setOrderColumnFourthOptions([]);
  }

  const onSubmit = ({
    orderColumnFirst,
    orderByFirst,
    orderColumnSecond,
    orderBySecond,
    orderColumnThird,
    orderByThird,
    orderColumnFourth,
    orderByFourth,
  }) => {
    console.log({ orderColumnFirst, orderByFirst, orderColumnSecond, orderBySecond, orderColumnThird, orderByThird, orderColumnFourth, orderByFourth });

    // TODO : 설정 유효성 처리할 것

    handleOrder({ orderColumnFirst, orderByFirst, orderColumnSecond, orderBySecond, orderColumnThird, orderByThird, orderColumnFourth, orderByFourth });
  }

  const handleChangeOrderFirstOptions = (e) => {
    // alert(e.target.value);

    const columns = getOrderColumnOptions();
    const result = columns.filter(item => item.value !== e.target.value);
    setOrderColumnSecondOptions(result);
  }

  const handleChangeOrderSecondOptions = (e) => {
    const first = getValues(("orderColumnFirst"));
    const columns = getOrderColumnOptions();
    const result = columns.filter(item => item.value !== e.target.value && item.value !== first);
    setOrderColumnThirdOptions(result);
  }

  const handleChangeOrderThirdOptions = (e) => {
    const first = getValues(("orderColumnFirst"));
    const second = getValues(("orderColumnSecond"));
    const columns = getOrderColumnOptions();
    const result = columns.filter(item => item.value !== e.target.value && item.value !== first && item.value !== second);
    setOrderColumnFourthOptions(result);
  }

  // const handleChangeOrderFourthOptions = (e) => {
  //   setValue(e.target.value);
  // }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} sm={1}>
        <FormInputDropdown
          name="orderColumnFirst"
          control={control}
          label={"정렬순서1"}
          onChangeSelectValue={handleChangeOrderFirstOptions}
          options={orderColumnFirstOptions}
          // defaultValue={""}
          sx={{ mb: 2 }}
        />
        <FormInputDropdown
          name="orderByFirst"
          control={control}
          label={"순서"}
          options={orderOptions}
        />
      </Grid>
      <Grid item xs={8} sm={1}>
        <FormInputDropdown
          name="orderColumnSecond"
          control={control}
          label={"정렬순서2"}
          onChangeSelectValue={handleChangeOrderSecondOptions}
          options={orderColumnSecondOptions}
          sx={{ mb: 2 }}
        />
        <FormInputDropdown
          name="orderBySecond"
          control={control}
          label={"순서"}
          options={orderOptions}
          // defaultValue={"DESC"}
        />
      </Grid>
      <Grid item xs={8} sm={1}>
        <FormInputDropdown
          name="orderColumnThird"
          control={control}
          label={"정렬순서3"}
          onChangeSelectValue={handleChangeOrderThirdOptions}
          options={orderColumnThirdOptions}
          sx={{ mb: 2 }}
        />
      
        <FormInputDropdown
          name="orderByThird"
          control={control}
          label={"순서"}
          options={orderOptions}
          // defaultValue={"DESC"}
        />
      </Grid>
      <Grid item xs={8} sm={1}>
        <FormInputDropdown
          name="orderColumnFourth"
          control={control}
          label={"정렬순서4"}
          // onChangeSelectValue={handleChangeOrderFourthOptions}
          options={orderColumnFourthOptions}
          sx={{ mb: 2 }}
        />
        <FormInputDropdown
          name="orderByFourth"
          control={control}
          label={"순서"}
          options={orderOptions}
          // defaultValue={"DESC"}
        />
      </Grid>
      <Grid item xs={8} sm={1}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>{"정렬"}</Button>
        <Button variant="contained" onClick={initOptions}>{"초기화"}</Button>
      </Grid>
    </Grid>
  )
}

export default GWorkOrderOrderBy;
