import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_GCLIENT_G04_DOCU_FILES = "gclientG04docuFiles/initGClientG04docuFiles";
const SET_GCLIENT_G04_DOCU_FILES = "gclientG04docuFiles/setGClientG04docuFiles";

const initGClientG04docuFiles = () => ({
  type: INIT_GCLIENT_G04_DOCU_FILES,
});

const setGClientG04docuFiles = (gclientG04docuFiles) => ({
  type: SET_GCLIENT_G04_DOCU_FILES,
  payload: gclientG04docuFiles,
});

export const initializeGClientG04docuFiles = () => async dispatch => {
  dispatch(initGClientG04docuFiles());
}

export const selectAllByQuery = ({ gclientId, types, searchName }) => async dispatch => {
  const queryStrings = [];
  if (gclientId) {
    queryStrings.push(`gclientId=${gclientId}`);
  }
  
  if (types && types.length > 0) {
    queryStrings.push(`${types.map(type => `type=${type}`).join('&')}`);
  }

  if (searchName) {
    queryStrings.push(`searchName=${searchName}`);
  }

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuFiles/query/selectAll?${queryStrings.join('&')}`);
  const data = await response.json();
  dispatch(setGClientG04docuFiles(data.gclientG04docuFiles));
  return response;
}

const initialState = {
  gclientG04docuFiles: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_FILES:
      newState = Object.assign({}, state, { gclientG04docuFiles: initialState.gclientG04docuFiles });
      return newState;
    case SET_GCLIENT_G04_DOCU_FILES:
      newState = Object.assign({}, state, { gclientG04docuFiles: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
