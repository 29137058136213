import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { objectEmptyCheck } from "../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gclientActions from "../../store/gclient";
import * as gprojectActions from "../../store/gproject";
import * as gorderActions from "../../store/gorder";

import GWorkOrderExcel from "./GWorkOrderExcel";
import GWorkOrderProcessDialog from "./GWorkOrderProcessDialog";

// 아래 form components의 name과 연계
const defaultValues = {
  greceptionId: undefined,
  // etc: "",
  orderer: "",
  receiveSerialNo: "",
  orderSerialNo: "",
  // id: "",
  // gclientId: "",
  // gprojectId: undefined,
  // site: "",
  // inChargeNameGclient: "",
  // inChargePhoneGclient: "",
  // // inChargeEmailGclient: "",
  // deliveryAddress: "",
  // orderDate: new Date(),
  // deliveryDate: new Date(),
  // // owner: "",
  // ordererName: "",
  // ordererPhone: "",
  // ordererEmail: "",
  // comments: "",
  // gorderDetails: undefined,
  gworkOrderDetails: undefined,
};

// TODO : GWorkOrderExcel의 행수가 앞으로는 고정이 아닐 수도 있으므로 추후 검토할 것
const rowLength = 51;
for(let i=0 ; i<rowLength-1 ; i++) {
  defaultValues[`checkRow_${i}`] = false;
}

// console.log(defaultValues)

let selectProjectInput = null;
// let oldGProjectId = null;

const GWorkOrderDialogRegister = ({
  open,
  setOpen,
  selectedRow,
  refresh,
  // greceptionId,
  originRefresh,
}) => {
  const [errors, setErrors] = useState([]);
  // const [fullScreen, setFullScreen] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  // const [tabValue, setTabValue] = useState(1);
  const [openProgress, setOpenProgress] = useState(false);
  const [openChangeProject, setOpenChangeProject] = useState(false);
  const [gproject, setGProject] = useState({});
  const [mode, setMode] = useState("");
  const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  // const [oldGProjectId, setOldGProjectId] = useState(null);
  const [gorderDetails, setGorderDetails] = useState([]);
  const [gworkOrderDetails, setGworkorderDetails] = useState([]);

  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [receiveId, setReceiveId] = useState();
  const [receiveType, setReceiveType] = useState("");
  // const [deliveryDate, setDeliveryDate] = useState(new Date())

  const [searchParams] = useSearchParams();

  const handleDialogClose = () => {
    // console.log(gproject)
    // setDialogInfo();
    setOpen(false);
    if (!objectEmptyCheck(gproject)) {
      setGProject({});
      setValue("gprojectId", null);
      // 프로젝트로부터 발주시 창을 닫고 등록하기를 누르면 useEffect ~[selectedRow]가 호출되지 않으므로 창 닫을 때 주문창을 초기화해야 한다.
      setDialogInfo();
      // [
      //   "id",
      //   "orderNo",
      //   "gprojectId",
      //   "gclientId",
      //   "site",
      //   "inChargeNameGclient",
      //   "inChargePhoneGclient",
      //   "deliveryAddress",
      //   "orderDate",
      //   "deliveryDate",
      //   "ordererName",
      //   "ordererPhone",
      //   "ordererEmail",
      //   "comments",
      //   "gorderDetails"
      // ].forEach(item => {
      //   if (item === "orderDate" || item === "deliveryDate") {
      //     setValue(item, new Date())
      //   } else if (item === "gorderDetails") {
      //     setValue(item, selectedRow && selectedRow[item] || undefined);
      //   } else {
      //     setValue(item, selectedRow && selectedRow[item] || "");
      //   }
      // });
      navigate('/gorders');
    } else {
      initDialogInfo();
    }
  };

  // const handleDialogMinMax = () => {
  //   setFullScreen(!fullScreen);
  // }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const gclients = useSelector((state) => state.gclient.gclients);
  const gprojects = useSelector((state) => state.gproject.gprojects);

  // 데이터 관리
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // const selectAllGClient = () => dispatch(gclientActions.selectAll());
  const selectGClientByType = (type) => dispatch(gclientActions.selectByTypeByQuery(type));
  const selectAllGProject = () => dispatch(gprojectActions.selectAll());
  const selectGProjectDirect = (gprojectId) => gprojectActions.selectDirect(gprojectId);
  const init = () => dispatch(gorderActions.initGOrder());
  const selectAllByProjectByDirect = (gprojectId) => gorderActions.selectAllByProjectByDirect(gprojectId);

  useEffect(
    async () => {
      // /*await */selectAllGClient();
      /*await */selectGClientByType('FABRICATOR');
      /*await */selectAllGProject();

    }, [dispatch]
  );

  const setDialogInfo = () => {
    const init = {};
    [
      "id",
      "receiveSerialNo",
      "orderSerialNo",
      "orderNo",
      "gprojectId",
      "gclientId",
      "site",
      "inChargeNameGclient",
      "inChargePhoneGclient",
      "deliveryAddress",
      "orderDate",
      "deliveryDate",
      "ordererName",
      "ordererPhone",
      "ordererEmail",
      "comments",
      "status",
      "gorderDetails"
    ].forEach(item => {
      if (item === "orderDate" || item === "deliveryDate") {
        // setValue(item, new Date())
        setValue(item, selectedRow && selectedRow[item] || new Date());
      } else if (item === "gorderDetails") {
        setValue(item, selectedRow && selectedRow[item] || undefined);
        setGorderDetails(selectedRow?.gorderDetails);
      } else {
        setValue(item, selectedRow && selectedRow[item] || "");
      }
    });

    setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
  }

  const initDialogInfo = () => {
    const init = {};
    [
      "id",
      "orderNo",
      // "gclientId",
      "site",
      "deliveryDate",
      // "gorderDetails"
    ].forEach(item => {
      if (item === "deliveryDate") {
        setValue(item, new Date());
      // } else if (item === "gorderDetails") {
      //   setValue(item, selectedRow && selectedRow[item] || undefined);
      //   setGorderDetails(selectedRow?.gorderDetails);
      } else {
        setValue(item, "");
      }
    });

    setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
  }

  // useEffect(
  //   () => {
  //     // console.log(`modify: ${modify}, opne: ${open}`);
  //     if (open) { // 다이얼로그가 열릴 때
  //       if (!modify) { // 등록인 경우 기존 데이터 초기화
  //         setDialogInfo();
  //       }
  //     }
  //   }, [open]
  // )

  // 아래는 목록에서 한 행 설정시 selectedRow값이 있는 상태로 호출되고, 등록하기 누르면 목록에서 init~하여 redux 통해서 null 설정되어 selectedRow값이 null인채로 호출됨
  useEffect(
    async () => {
      setDialogInfo();
      // if (selectedRow) {
        // const init = {};
        // [
        //   "id",
        //   "orderNo",
        //   "gprojectId",
        //   "gclientId",
        //   "site",
        //   "inChargeNameGclient",
        //   "inChargePhoneGclient",
        //   "deliveryAddress",
        //   "orderDate",
        //   "deliveryDate",
        //   "ordererName",
        //   "ordererPhone",
        //   "ordererEmail",
        //   "comments",
        //   "gorderDetails"
        // ].forEach(item => {
        //   if (item === "orderDate" || item === "deliveryDate") {
        //     // 날짜 데이터의 경우 selectedRow값이 있으면 설정되나 없을 경우 new Date()해도 기존값이 보임. useState 이용해서 갱신해야 함
        //     setValue(item, selectedRow && selectedRow[item] || new Date());
        //   } else if (item === "gorderDetails") {
        //     setValue(item, selectedRow && selectedRow[item] || undefined);
        //     setGorderDetails(selectedRow?.gorderDetails);
        //   } else {
        //     setValue(item, selectedRow && selectedRow[item] || "");
        //   }
        // });
        // setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
      // } else {
      //   setDialogInfo();
      // }
      
      // 프로젝트 수정창에서 발주를 진행한 경우
      const gprojectId = searchParams.get('gprojectId');
      const mode = searchParams.get('mode');
      if (gprojectId && mode) {
        const gproject = await selectGProjectDirect(gprojectId);
        setGProject(gproject);
        if (!objectEmptyCheck(gproject)) {
          setValue("gprojectId", gproject.id);
          setMode(mode);

          // 마지막 발주 정보 설정. 첫 발주일 경우 프로젝트 정보 설정
          setLastOrderInfoOrProjectInfo(gproject);
        }
      }
      
      // selectedRow && setOpenProgress(true);
      // alert(index)
      // // TODO : 임시. 데이터 로딩에 시간이 걸려 GWorkOrderExcel안에서 데이터로딩 후 진행바 삭제. 그러나 일반의 경우 아직 미구현이므로 여기서 진행바 임시 삭제
      // if(index === "1") {
      //   setOpenProgress(false);
      // }
    }, [selectedRow]
  );

  // 마지막 발주 정보 설정. 첫 발주일 경우 프로젝트 정보 설정
  const setLastOrderInfoOrProjectInfo = async (gproject) => {
    const { gorders } = await selectAllByProjectByDirect(gproject.id);
    if (gorders.length > 0) {
      [
        "gclientId",
        "site",
        "inChargeNameGclient",
        "inChargePhoneGclient",
        "deliveryAddress",
        "ordererName",
        "ordererPhone",
        "ordererEmail"
      ].forEach(item => {
        setValue(item, gorders[0][item]);
      });
    } else {
      const { site, siteAddress, gclientDetails } = gproject;
    
      setValue("site", site);
      setValue("deliveryAddress", siteAddress);
  
      // 가공업체는 프로젝트 설정시 첫번째 가공업체를 기본으로 한다.
      const gclient = gclientDetails.filter(gclient => gclient.code === 'FABRICATOR');
      if (gclient[0].gclients.length > 0) {
        setValue("gclientId", gclient[0].gclients[0].value.id);
      }
    }
  }

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   setFullScreen(true);
  // };

  const confirm = async (target) => {
    setOpenChangeProject(true)
    const { gprojectId } = target;
    const gproject = await selectGProjectDirect(gprojectId);
    if (gproject) {
      setDialogInfo();
      setValue("gprojectId", gprojectId);
      setLastOrderInfoOrProjectInfo(gproject);
      
      // 사용자가 프로젝트 변경을 하면 유리내역을 초기화한다.
      setGorderDetails([]);
    }
    setOpenChangeProject(false);
  }
  
  const cancel = async (target) => {
    // 프로젝트 선택 되돌리기
    const { prevGprojectId } = target;
    setValue("gprojectId", prevGprojectId);
  }

  const handleSelectProject = async (e, oldValue) => {
    // 발주 내역이 있는 경우 프로젝트 변경을 하면 안내창을 띄운다.
    const gprojectId = e.target.value;
    const selectedGGlass = getValues("gorderDetailsBeforeValidation");
    if (selectedGGlass > 0 ) {
      setRemoveObject({ gprojectId, prevGprojectId: oldValue });
      setConfirmOpen(true);
    } else {
      const gproject = await selectGProjectDirect(gprojectId);
      setDialogInfo();
      setValue("gprojectId", gprojectId);
      setLastOrderInfoOrProjectInfo(gproject);
    }
    
    // const site = getValues("site");
    // const deliveryAddress = getValues("deliveryAddress");
    // if (!site) {
    //   setValue("site", project.site);
    // }
    // if (!deliveryAddress) {
    //   setValue("deliveryAddress", project.siteAddress);
    // }
  }

  // const handleChangePeriod = (value, name) => {
  //   return true;
  // }

  const setFocusSelectProjectInput = () => {
    selectProjectInput.focus();
  }

  const setSelectProjectInputRef = element => {
    selectProjectInput = element;
  };

  const handleGreceptionProcess = (type) => {
    setReceiveId(getValues("id"));
    setReceiveType(type);
    setReceiveDialogOpen(true);
  }

  const handleCreateGWorkOrder = () => {
    // alert("111 ")
  }

  return (
    <>
      <Dialog open={openProgress}>
        <DialogContent>
          <Box sx={{ width: '500px' }}>
            {"발주서 작성 중입니다."}
            <LinearProgress sx={{ mt: 1 }} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openChangeProject}>
        <DialogContent>
          <Box sx={{ width: '500px' }}>
            {"프로젝트를 변경 중입니다."}
            <LinearProgress sx={{ mt: 1 }} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          // onMinMax={tabValue === 1 && handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          {"작업의뢰서 발행"}
        </DialogTitleClose>
        <DialogContent dividers>
          <>
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <FormInputText
                      name={"workOrderNo"}
                      control={control}
                      label={"의뢰번호"}
                    /> */}
                    <FormInputText
                      name={"receiveSerialNo"}
                      control={control}
                      label={"수주일련번호"}
                      // value={gorderId}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={"orderNo"}
                      control={control}
                      label={"발주번호"}
                      // readOnly
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputText
                      name={"site"}
                      control={control}
                      label={"현장명"}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputDate
                      name="deliveryDate"
                      control={control}
                      label={"납기일"}
                      // value={deliveryDate}
                      value={getValues("deliveryDate")}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputText
                      name={"orderer"}
                      control={control}
                      label={"발주업체"}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={"comments"}
                      control={control}
                      label={"설명"}
                      multiline
                      maxRows={5}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <GWorkOrderExcel
              // initExcelDatas={selectedRow?.gorderDetails}
              initExcelDatas={gorderDetails}
              selectedRow={selectedRow}
              control={control}
              setValue={setValue}
              getValues={getValues}
              // setOpenProgress={setOpenProgress}
              focus={setFocusSelectProjectInput}
              // setDeliveryDate={setDeliveryDate}
              setInitData={setInitData}
              setGworkorderDetails={setGworkorderDetails}
              // greceptionId={greceptionId}
              refresh={refresh}
              originRefresh={originRefresh}
              onOriginClose={handleDialogClose}
            />
          </>
        </DialogContent>
        <DialogActions>
          {
            getValues("status") === 'RECEIVE' ? (
              // <Button onClick={() => handleGreceptionProcess('receive')}>{"작업의뢰서 발행"}</Button>
              <Button>
                <Link
                  href={`/gworkOrders?greceptionId=${getValues("id")}&mode=register`}
                  variant="body2"
                  underline="none"
                >
                  {"작업의뢰서 발행"}
                </Link>
              </Button>
            ) : (
              <>
                {/* <Button onClick={() => handleGreceptionProcess('receive')}>{"접수"}</Button>
                <Button onClick={() => handleGreceptionProcess('return')}>{"반송"}</Button> */}
                <Button onClick={handleCreateGWorkOrder}>{"일괄발행"}</Button>
              </>
            )
          }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        removeId={removeObject}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirm}
        onCancel={cancel}
      >
        {`프로젝트를 변경하면 작성중인 발주내역이 초기화됩니다. 계속 진행하시겠습니까?`}
      </ConfirmDialog>
      <GWorkOrderProcessDialog
        open={receiveDialogOpen}
        setOpen={setReceiveDialogOpen}
        id={receiveId}
        receiveType={receiveType}
        onClose={handleDialogClose}
        refresh={refresh}
        originRefresh={originRefresh}
      />
    </>
  );
};

export default GWorkOrderDialogRegister;
