import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, selectedIdsLookupSelector, koKR } from '@mui/x-data-grid-pro';
import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Delete,
  Done,
  Edit,
  FactCheck,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardReturn,
  Undo,
  OpenInNew,
  Send,
  Shortcut,
  WarningAmber,
} from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';
import { useMovieData } from '@mui/x-data-grid-generator';
import {
  FormInputText,
  FormInputRadio,
  FormInputCheckbox,
  FormInputMultipleCheckbox,
} from "../../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../../datagrid";
import {
  dateFormat,
  hideWatermark,
  getTextColorByBackgroundColor,
} from "../../../utils";
import * as gprocessActions from "../../../store/gprocess";
import * as gorderActions from "../../../store/gorder";
import * as gworkOrderActions from "../../../store/gworkOrder";
import * as gworkOrderDetailActions from "../../../store/gworkOrderDetail";
import GWorkOrderStatusDialog from "./GWorkOrderStatusDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 40,
  lineHeight: '40px',
}));

const GWorkOrderStatusManagement = ({
  title,
  gorderId,
  originRefresh,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [modifyRegister, setModifyRegister] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [receiveId, setReceiveId] = useState();
  const [params, setParams] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [gproject, setGProject] = useState({});
  const [mode, setMode] = useState("");
  const [receiveType, setReceiveType] = useState("");
  const [statusWorkOrder, setStatusWorkOrder] = useState("");
  const [amountsPersistance, setAmountsPersistance] = useState({});
  const [refreshParams, setRefreshParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [selectedRow, setSelectedRow] = useState({});
  const [gprocesses, setGprocesses] = useState([]);
  const [statusClick, setStatusClick] = useState([]); // FormInputMultipleCheckbox 클릭시 조회하기 전 값을 얻기 위해 필요. 단 값을 getValues를 통해 얻음 
  const [selectedGProcessCode, setSelectedGProcessCode] = useState("ALL");

  const [searchParams] = useSearchParams();
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const getTypeAndId = (id) => {
    let detailType;
    let selectId;

    try {
      let pos = id.indexOf("gworkOrderProcessId");
      if (pos >= 0) {
        detailType = "GWORK_ORDER_PROCESS"; // 2
        const indexOfFirst = id.lastIndexOf("]");
        const indexOfSecond = id.lastIndexOf("[#", (indexOfFirst + 1));
        selectId = id.substring(indexOfSecond+2, indexOfFirst);
      } else {
        pos = id.indexOf("workOrderNo");
        if (pos >= 0) {
          detailType = "GWORK_ORDERS"; // 1
          const indexOfFirst = id.indexOf("[#");
          const indexOfSecond = id.indexOf("]", (indexOfFirst + 1));
          selectId = id.substring(indexOfFirst+2, indexOfSecond);
        } else {
          detailType = "GWORK_ORDER_DETAIL"; // 3
          selectId = id;
        }
      }
    } catch(e) { // 3의 경우 id가 integer이므로 id.indexOf 에서 예외발생. 참고로, 위의 코드에서 integer가 아닌 경우도 되도록 else 문으로 만들어놓긴 했음
      detailType = "GWORK_ORDER_DETAIL";
      selectId = id;
    }

    return {
      idType: detailType,
      id: selectId,
    }
  }

  const handleSelect = async ({ type, params }) => {
    console.log(params)
    
    setOpenBackdrop(true);
    
    setSelectedRow(params.row); // 목록내용에서 추가로 조회하지 않아도 되므로 다른 상세화면처럼 select 함수 호출하지 않고 바로 state 이용

    await selectAllDetailsByGWorkOrderByQuery(params.row.workOrderNo);
    
    setOpen(true);

    setOpenBackdrop(false); // setOpen 후에 호출하는게 화면 전환이 부드러움
  }
  
  const openOrderDialog = () => {
    setOpen(true);
    setModify(false);
    initGOrder(); // 다이얼로그 초기화
  }

  const generateActions = (params) => {

    const detailAction = <GridActionsCellItem icon={<OpenInNew />} label={"상세"} onClick={() => handleSelect({ type: 'detail', params })} showInMenu />;

    let arrActions = [].concat([detailAction]);

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'status',
    //   headerName: '결과',
    //   width: 80,
    //   headerAlign: 'center',
    //   align: 'center',
    //   // editable: true,
    //   renderCell: (params) => {
    //     const { status } = params.row;
    //     let result;
    //     if (status === 'PRE') {
    //       result = <Chip icon={<Shortcut />} label={"미접수"} variant="outlined" color="warning" size="small" />
    //     } else if (status === 'RECEIVE') {
    //       result = <Chip icon={<Done />} label={"접수"} variant="outlined" color="success" size="small" />
    //     } else if (status === 'RETURN') {
    //       result = <Chip icon={<Undo />} label={"반송"} variant="outlined" color="error" size="small" />
    //       // result = <Chip icon={<WarningAmber />} label={"반송"} variant="outlined" color="error" />
    //     }

    //     return (
    //       <div>
    //         {result}
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'id',
      headerName: '아이디',
      width: 120,
      hide: true,
    },
    {
      field: 'workOrderNo',
      headerName: '작업의뢰번호',
      width: 320,
      valueGetter: (params) => `${params.row.workOrderNo}-${params.row.workOrderNoSuffix}`
    },
    {
      field: 'receiveSerialNo',
      headerName: '수주일련번호',
      width: 120,
    },
    // {
    //   field: 'orderer',
    //   headerName: '발주처',
    //   width: 150,
    //   valueGetter: (params) => params.value?.name,
    // },
    // {
    //   field: 'receiver',
    //   headerName: '수주처',
    //   width: 150,
    //   valueGetter: (params) => params.value?.name,
    // },
    // {
    //   field: 'site',
    //   headerName: '현장명',
    //   width: 180,
    // },
    // {
    //   field: 'orderSerialNo',
    //   headerName: '발주일련번호',
    //   width: 120,
    // },
    // {
    //   field: 'orderNo',
    //   headerName: '발주번호',
    //   width: 120,
    // },
    // {
    //   field: 'comments',
    //   headerName: '설명',
    //   width: 300,
    // },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
    },
    {
      field: 'deliveryDate',
      headerName: '납기일',
      width: 88,
      // editable: true,
      headerAlign: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    // {
    //   field: 'currentOtherSpecs',
    //   headerName: '비고',
    //   width: 480,
    //   headerAlign: 'center',
    // },
    // {
    //   field: 'sumAmountTotal',
    //   headerName: '수주전체',
    //   width: 100,
    //   headerAlign: 'center',
    //   type: 'number',
    //   renderCell: (param) => {
    //     return (
    //       <div
    //         style={{
    //           backgroundColor: '#b2dfdb',
    //           width: 80,
    //           textAlign: 'right',
    //           paddingRight: '10px',
    //           paddingTop: '4px',
    //           paddingBottom: '4px',
    //           borderRadius: '8px',
    //         }}
    //       >
    //         {param.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
    //       </div>
    //     )
    //   }
    //   // backgroundColor: '#b2dfdb',
    // },
    {
      field: 'sumAmount',
      headerName: '수량',
      width: 100,
      // headerAlign: 'center',
      type: 'number',
      renderCell: (param) => {
        return (
          <div
            style={{
              backgroundColor: '#b2dfdb',
              width: 80,
              textAlign: 'right',
              paddingRight: '10px',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRadius: '8px',
            }}
          >
            {param.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        )
      }
      // backgroundColor: '#b2dfdb',
    },
    {
      field: 'sumAmountCompleted',
      headerName: '생산',
      width: 100,
      // headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        const sumAmountCompleted = params.row.sumAmountCompleted || 0;
        return (
          <div
            style={{
              backgroundColor: '#81d4fa',
              width: 80,
              textAlign: 'right',
              paddingRight: '10px',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRadius: '8px',
            }}
          >
            {/* TODO : type이 number이면 소수자리표시는 필요없을 것. 확인필요 */}
            {sumAmountCompleted.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        );
      },
    },
    {
      field: 'sumAmountIncomplete',
      headerName: '미생산',
      width: 100,
      // headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        const sumAmountIncomplete = params.row.sumAmountIncomplete || 0;
        return (
          <div
            style={{
              backgroundColor: '#ffcdd2',
              width: 80,
              textAlign: 'right',
              paddingRight: '10px',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRadius: '8px',
            }}
          >
            {sumAmountIncomplete.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        );
      },
    },
    {
      field: 'sumAmountDefect',
      headerName: '결품',
      width: 100,
      // headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        const sumAmountDefect = params.row.sumAmountDefect || 0;
        return (
          <div
            style={{
              backgroundColor: '#ef9a9a',
              width: 80,
              textAlign: 'right',
              paddingRight: '10px',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRadius: '8px',
            }}
          >
            {sumAmountDefect.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        );
      },
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];
  
  const gworkOrdersByGReception = useSelector((state) => state.gworkOrder.gworkOrdersByGReception);

  const { control, setValue, getValues } = useForm({ });

  // 데이터 관리
  const dispatch = useDispatch();

  const selectGProcessesDirect = () => gprocessActions.selectAllDirect()
  const selectAllProcessDetailsByQuery = () => dispatch(gworkOrderActions.selectAllProcessDetailsByQuery())
  const selectAllByGreceptionIdByQuery = (greceptionId) => dispatch(gworkOrderActions.selectAllByGreceptionIdByQuery(greceptionId))
  const selectGOrderDirect = (gorderId) => gorderActions.selectDirect(gorderId)
  const initGOrder = () => dispatch(gorderActions.initGOrder())
  const selectAllDetailsByQuery = ({ status, gprocess, condition }) => dispatch(gworkOrderDetailActions.selectAllDetailsByQuery({ status, gprocess, condition }))
  const selectAllGWorkOrdersByGReception = () => dispatch(gworkOrderActions.selectAllByGReception())
  const selectAllGWorkOrdersByGReceptionByWorkingAmount = ({ condition }) => dispatch(gworkOrderActions.selectAllByGReceptionByWorkingAmount({ condition }))
  const selectAllDetailsByGWorkOrderByQuery = (workOrderNo) => dispatch(gworkOrderDetailActions.selectAllDetailsByGWorkOrderByQuery(workOrderNo))

  useEffect(
    async () => {
      const gprocessesAll = await selectGProcessesDirect();
      setGprocesses(gprocessesAll);
      const lastGprocessCode = gprocessesAll[gprocessesAll.length - 1].code;
      setSelectedGProcessCode(lastGprocessCode)
      // await selectAllDetailsByQuery({ status: 'ASSIGN', gprocess: lastGprocessCode, condition: [] });

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);

      // 접수목록에서 띄운경우 등록버튼 보이기 여부 결정
      if (gorderId) {
        const gorder = await selectGOrderDirect(gorderId);
        setStatusWorkOrder(gorder.statusWorkOrder);;
      } else {
        setStatusWorkOrder("init");
      }

      // 수주기준 작업현황
      await selectAllGWorkOrdersByGReception();
    }, [dispatch]
  );

  const selectAllRefresh = () => {
    const queryStringGorderId = searchParams.get('gorderId') || gorderId;
    if (queryStringGorderId) {
      selectAllByGreceptionIdByQuery(queryStringGorderId);
    } else {
      // selectAllByQuery();
      selectAllProcessDetailsByQuery();
    }
  }

  // TODO : 테스트
  // const data = useMovieData();
  // const { columns: groupGridColumns, rows: groupGridRows } = data;
  // console.log(groupGridColumns)
  // console.log(groupGridRows) 

  const calAmountIncomplete = (params) => {
    let { amount, amountCompleted, amountIncomplete, amountDefects } = params.row;
    // amountDefect는 계산되어진 값으로 가져오면 undefined로 되어 있어 아래와 같이 직접 입력되는
    // 컬럼으로부터 가져온다.
    let sum = 0;
    amountDefects.forEach(item => {
      if(params.row[item.defect] === undefined) {
        sum = sum + item.amount;
      } else {
        sum = sum + (params.row[item.defect] || 0);
      }
    });

    // 주의 : 셀을 직접 편집한 것이 아니므로 params.row.amountIncomplete 값은 변하지 않음
    // 그래서 "적용" 버튼을 눌렸을 때 DB 저장전 다시 계산해주어야 한다.
    amountIncomplete = amount - (amountCompleted || 0) - (sum || 0);
    return amountIncomplete;
  }

  const calAmountDefect = (params) => {
    const { amountDefects } = params.row;
    let sum = 0;
    amountDefects.forEach(item => {
      if(params.row[item.defect] === undefined) {
        sum = sum + item.amount;
      } else {
        sum = sum + (params.row[item.defect] || 0);
      }
    });

    // 주의 : 셀을 직접 편집한 것이 아니므로 params.row.amountDefect 값은 변하지 않음
    // 그래서 "적용" 버튼을 눌렸을 때 DB 저장전 다시 계산해주어야 한다.
    return sum;
  }

  // const handleChangeGProcessRadioButton = async (event) => {
  //   setOpenBackdrop(true);
  //   setLoaded(false);
    
  //   const gprocess = event.target.value;
    
  //   await selectAllDetailsByQuery({ status: 'ASSIGN', gprocess, condition: getValues("status") });

  //   setTimeout(() => setOpenBackdrop(false), 300);
    
  //   setSelectedGProcessCode(gprocess);

  //   setLoaded(true);
  // }
  
  useEffect(
    async () => {
      setOpenBackdrop(true);
      setLoaded(false);

      // await selectAllDetailsByQuery({ status: 'ASSIGN', gprocess: selectedGProcessCode, condition: getValues("status") });
      // ['done', 'incomplete', 'defect']
      const condition = getValues("status");
      if (condition && condition.length && condition.length > 0) {
        await selectAllGWorkOrdersByGReceptionByWorkingAmount({ condition });
      } else {
        await selectAllGWorkOrdersByGReception();
      }

      setTimeout(() => setOpenBackdrop(false), 300);

      setLoaded(true);
    }, [statusClick]
  )

  const handleChangeStatusCheckButton = async (event, value) => {
    setStatusClick([]); // []로 설정함에 유의. ""은 값의 변화가 없으므로 useEffect[statusClick] 호출안됨
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GWorkOrderStatusDialog
          modify={modify}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          gorderId={gorderId}
          refresh={selectAllRefresh}
          originRefresh={originRefresh}
          refreshParams={refreshParams}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <ItemHeader key={1} elevation={4}>
                    {"공정"}
                  </ItemHeader>
                </Grid>
                <Grid item xs={10} display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      '& > :not(style)': {
                        // width: 800,
                        height: 40,
                      },
                    }}
                  >
                    <Paper variant="outlined">
                      <Grid container>
                        <Grid item xs={12} display="flex" sx={{ pl: 1.3 }}>
                          {
                            gprocesses.length > 0 && (
                              <FormInputRadio
                                row
                                name={"gprocess"}
                                control={control}
                                options={
                                  [{ label: "전체", value: "ALL", color: 'black' }].concat(gprocesses.map(gprocess => {
                                    const { code, name, color } = gprocess;
                                    return {
                                      label: name,
                                      value: code,
                                      color,
                                    }
                                  }))
                                }
                                onChangeButton={handleChangeGProcessRadioButton}
                                defaultValue={gprocesses[gprocesses.length - 1]?.code} 
                              />
                            )
                          }
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <ItemHeader key={1} elevation={4}>
                    {"상태"}
                  </ItemHeader>
                </Grid>
                <Grid item xs={10} display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      '& > :not(style)': {
                        height: 40,
                      },
                    }}
                  >
                    <Paper variant="outlined">
                      <Grid container>
                        <Grid item xs={12} display="flex" alignItems="center" sx={{ ml: 1.4 }}>
                          {/* <FormInputCheckbox
                            name="done"
                            control={control}
                            onChangeCheckValue={handleChangeStatusCheckButton}
                          />
                          <Typography sx={{ mr: 0.3 }}>{"완료"}</Typography>
                          <FormInputCheckbox
                            name="incomplete"
                            control={control}
                            onChangeCheckValue={handleChangeStatusCheckButton}
                          />
                          <Typography>{"미생산"}</Typography>
                          <FormInputCheckbox
                            name="defect"
                            control={control}
                            onChangeCheckValue={handleChangeStatusCheckButton}
                          />
                          <Typography sx={{ mr: 2 }}>{"결함"}</Typography> */}
                          <FormInputMultipleCheckbox
                            control={control}
                            setValue={setValue}
                            name={"status"}
                            // label={"Checkbox Input"}
                            options={[
                              {
                                label: "완료",
                                value: "done",
                              },
                              {
                                label: "미생산",
                                value: "incomplete",
                              },
                              {
                                label: "결함",
                                value: "defect",
                              },
                            ]}
                            onChangeCheckValue={handleChangeStatusCheckButton}
                            defaultValues={[/*"done"*/]}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* TODO : 테스트. 임시로 높이 조정 */}
              <div style={{ height: 800, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  // initialState={{ pinnedColumns: { left: [/*'status', */'id', 'workOrderNo'], right: ['gprocessName', 'deliveryDate', 'actions'] } }}
                  initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loaded}
                  rows={gworkOrdersByGReception}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GWorkOrderStatusManagement;
