import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP = "gclientG04docuGTestSubstituteMaps/initGClientG04docuGTestSubstituteMap";
const SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP = "gclientG04docuGTestSubstituteMaps/setGClientG04docuGTestSubstituteMap";
const INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS = "gclientG04docuGTestSubstituteMaps/initGClientG04docuGTestSubstituteMaps";
const SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS = "gclientG04docuGTestSubstituteMaps/setGClientG04docuGTestSubstituteMaps";
const REMOVE_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP = "gclientG04docuGTestSubstituteMaps/removeGClientG04docuGTestSubstituteMap";

const initGClientG04docuGTestSubstituteMap = () => ({
  type: INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP,
});

const setGClientG04docuGTestSubstituteMap = (gclientG04docuGTestSubstituteMap) => ({
  type: SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP,
  payload: gclientG04docuGTestSubstituteMap,
});

const initGClientG04docuGTestSubstituteMaps = () => ({
  type: INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS,
});

const setGClientG04docuGTestSubstituteMaps = (gclientG04docuGTestSubstituteMaps) => ({
  type: SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS,
  payload: gclientG04docuGTestSubstituteMaps,
});

const removeGClientG04docuGTestSubstituteMap = () => ({
  type: REMOVE_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP,
});

export const create = ({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestSubstituteMap(data.gclientG04docuGTestSubstituteMap));
  return response;
};

export const modifyDate = ({ gclientId, g04docuGTestId, documentPath, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04docuGTestId, documentPath, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestSubstituteMap(data.gclientG04docuGTestSubstituteMap));
  return response;
};

export const initializeGClientG04docuGTestSubstituteMap = () => async dispatch => {
  dispatch(initGClientG04docuGTestSubstituteMap());
}

export const initializeGClientG04docuGTestSubstituteMaps = () => async dispatch => {
  dispatch(initGClientG04docuGTestSubstituteMaps());
}

export const selectAllByGComponentItemOrProcessByQuery = (id, division, gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/query/selectAll/gcomponentItemOrProcess/${id}/division/${division}`, {
    method: 'POST',
    body: JSON.stringify({ gclientId }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestSubstituteMaps(data.gclientG04docuGTestSubstituteMaps));
  return response;
}

// export const generateDirect = async (treeData) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/generate`, {
//     method: "POST",
//     body: JSON.stringify({ data: treeData }),
//   });
  
//   const data = await response.json();
//   return data.generatedTreeData;
// };

export const selectByQueryDirect = async (id) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/query/select/${id}`);
  const data = await response.json();
  return data.gclientG04docuGTestSubstituteMaps;
}

// 성적서의 경우 gcomponentItemId 필요하다 생각했으나 => documentPath가 있으므로 문제 안됨
export const remove = ({ gclientId, g04docuGTestId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}/g04docuGTestPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuGTestSubstituteMap());
  return response;
};

export const removeWithFile = ({ gclientId, g04docuGTestId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}/g04docuGTestPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuGTestSubstituteMap());
  return response;
};

export const modifyMajority = ({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestSubstituteMaps/${id}`, {
    method: "PUT",
    body: JSON.stringify({ g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestSubstituteMaps(data.gclientG04docuGTestSubstituteMaps));
  return response;
}

const initialState = {
  gclientG04docuGTestSubstituteMap: null,
  gclientG04docuGTestSubstituteMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP:
      newState = Object.assign({}, state, { gclientG04docuGTestSubstituteMap: initialState.gclientG04docuGTestSubstituteMap });
      return newState;
    case SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAP:
      newState = Object.assign({}, state, { gclientG04docuGTestSubstituteMap: action.payload });
      return newState;
    case INIT_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGTestSubstituteMaps: initialState.gclientG04docuGTestSubstituteMaps });
      return newState;
    case SET_GCLIENT_G04_DOCU_GTEST_SUBSTITUTE_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGTestSubstituteMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
