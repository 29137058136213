import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, DEFAULT_GRID_COL_TYPE_KEY, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import "./GPlan.css";
import {
  getRGBfromHex,
} from "../../utils";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const weekName = ["일", "월", "화", "수", "목", "금", "토"];

const GPlanStatus2 = ({
  alarms,
  searchParamsForStatus2,
}) => {
  const gcapacitiesByGProcess = useSelector((state) => state.glineCapacity.gcapacitiesByGProcess);
  const statusPeriod = useSelector((state) => state.gline.statusPeriod);
  const gcapacitiesByGLine = useSelector((state) => state.glineCapacity.gcapacitiesByGLine);

  // 데이터 관리
  // const dispatch = useDispatch();

  const { gprocessCode, dateType } = searchParamsForStatus2;
  
  // console.log(searchParamsForStatus2);
  console.log(gcapacitiesByGLine)

  // TODO : 유효범위가 맞지 않아 비워진 경우 빈 그래프를 그려야 하지 않을까???
  return (
    <Box sx={{ p: 1 }}>
    {
      gprocessCode === 'ALL' && (
        <Stack spacing={1}>
        {
          gcapacitiesByGProcess.map(capacity => {
            const { gprocessId, gprocessCode, gprocessName, gprocessColor, area, amount } = capacity;
            const capacityByGProcess = statusPeriod[`${gprocessId}_${gprocessCode}`] ? Number(statusPeriod[`${gprocessId}_${gprocessCode}`].areaJa) : 0;
            const ratioCapacity = capacityByGProcess / Number(area) * 100;
  
            let colorProgress = alarms[0].color;
            if (ratioCapacity >= 100) {
              colorProgress = alarms[2].color;
            } else if(ratioCapacity >= 80) {
              colorProgress = alarms[1].color;
            }
            
            const rgb = getRGBfromHex(gprocessColor);
            console.log(rgb)

            const data = {
              // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
              datasets: [
                {
                  label: 'Percent(%)',
                  // data: [12, 19, 3, 5, 2, 3],
                  data: [(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2), (Number(area)/(capacityByGProcess+Number(area))*100).toFixed(2)],
                  backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    colorProgress,
                    'rgba(150, 150, 150, 0.2)',
                  ],
                  borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    colorProgress,
                    'rgba(150, 150, 150, 0.2)',
                  ],
                  borderWidth: 1,
                },
              ],
            };
  
            const plugins = [{
              beforeDraw: function(chart) {
                var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                //  var text = "Foo-bar",
                var text = `${(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2)} %`,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
                ctx.fillStyle = colorProgress;
                ctx.fillText(text, textX, textY);
                ctx.save();
              }
            }]

            return (
              <Stack direction="row" spacing={1}>
                <Box
                  display="flex" justifyContent="center" alignItems="center"
                  sx={{
                    bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                    border: 2,
                    // borderColor: gprocessColor,
                    borderColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.4)`,
                    borderRadius: 2,
                    pb: 1.5,
                    width: '200px',
                    height: '170px',
                  }}
                >
                  <Typography variant="h6" sx={{ color: gprocessColor, fontWeight: 'bold' }}>
                    {gprocessName}
                  </Typography>
                </Box>
                <Box
                  display="flex" justifyContent="center" alignItems="center"
                  sx={{
                    // bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                    border: 2,
                    // borderColor: gprocessColor,
                    borderColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.4)`,
                    borderRadius: 2,
                    pb: 1.5,
                    width: '300px',
                    height: '170px',
                  }}
                >
                  <Doughnut
                    data={data}
                    options={{ responsive: false }}
                    plugins={plugins} 
                    sx={{ width: 100 }}
                  />
                </Box>
              </Stack>
            )
          }) 
        } 
        </Stack>
      )
    }
    {
      gprocessCode !== 'ALL' && dateType !== 'week' && (
        <Stack spacing={1}>
        {
          gcapacitiesByGLine.map(capacity => {
            const { glineId, glineName, gprocessId, gprocessCode, gprocessName, gprocessColor, area, amount } = capacity;
            const capacityByGProcess = statusPeriod[glineId] ? Number(statusPeriod[glineId].areaJa) : 0;
            const ratioCapacity = capacityByGProcess / Number(area) * 100;
  
            let colorProgress = alarms[0].color;
            if (ratioCapacity >= 100) {
              colorProgress = alarms[2].color;
            } else if(ratioCapacity >= 80) {
              colorProgress = alarms[1].color;
            }
            
            const rgb = getRGBfromHex(gprocessColor);
            console.log(rgb)

            const data = {
              // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
              datasets: [
                {
                  label: 'Percent(%)',
                  // data: [12, 19, 3, 5, 2, 3],
                  data: [(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2), (Number(area)/(capacityByGProcess+Number(area))*100).toFixed(2)],
                  backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    colorProgress,
                    'rgba(150, 150, 150, 0.2)',
                  ],
                  borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    colorProgress,
                    'rgba(150, 150, 150, 0.2)',
                  ],
                  borderWidth: 1,
                },
              ],
            };
  
            const plugins = [{
              beforeDraw: function(chart) {
                var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                //  var text = "Foo-bar",
                var text = `${(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2)} %`,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
                ctx.fillStyle = colorProgress;
                ctx.fillText(text, textX, textY);
                ctx.save();
              }
            }]

            return (
              <Stack direction="row" spacing={1}>
                <Box
                  display="flex" justifyContent="center" alignItems="center"
                  sx={{
                    bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                    border: 1,
                    borderColor: gprocessColor,
                    borderRadius: 2,
                    pb: 1.5,
                    width: '200px',
                    height: '170px',
                  }}
                >
                  <Typography variant="h6" sx={{ color: gprocessColor, fontWeight: 'bold' }}>
                    {glineName}
                  </Typography>
                </Box>
                <Box
                  display="flex" justifyContent="center" alignItems="center"
                  sx={{
                    // bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                    border: 2,
                    borderColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.4)`,
                    borderRadius: 2,
                    pb: 1.5,
                    width: '300px',
                    height: '170px',
                  }}
                >
                  <Doughnut
                    data={data}
                    options={{ responsive: false }}
                    plugins={plugins} 
                    sx={{ width: 100 }}
                  />
                </Box>
              </Stack>
            )
          }) 
        } 
        </Stack>
      )
    }
    {
      gprocessCode !== 'ALL' && dateType === 'week' && (
        <>
          <Stack spacing={1}>
          {
            <Grid container spacing={1} sx={{ ml: 0 }}>
              {
                <>
                  <Grid
                    item
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{
                      // bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                      // border: 1,
                      // borderColor: gprocessColor,
                      borderRadius: 2,
                      width: '50px',
                    }}
                  >
                    {/* {"요일"} */}
                  </Grid>
                  {
                    gcapacitiesByGLine[0]?.[Object.keys(gcapacitiesByGLine[0])[0]]?.map(gline => {
                        return (
                          <Grid
                            item
                            display="flex" justifyContent="center" alignItems="center"
                            sx={{
                              // bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                              // border: 1,
                              // borderColor: gprocessColor,
                              borderRadius: 2,
                              width: '200px',
                              ml: 1,
                            }}
                          >
                            {gline.glineName}
                          </Grid>
                        )
                      }
                    )
                  }
                </>
              }
            </Grid>
          }
          {
            gcapacitiesByGLine.map((capacity, idx) => {
              console.log(Object.keys(capacity)[0])
              console.log(capacity[Object.keys(capacity)[0]])
              
              return (
                <Grid container spacing={1} sx={{ ml: 0 }}>
                  <>
                    <Grid
                      item
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        bgcolor: `#ba68c8`,
                        color: 'white',
                        // border: 1,
                        // borderColor: gprocessColor,
                        borderRadius: 25,
                        p: 1,
                        width: '50px',
                        height: '50px',
                        fontWeight: 'bold',
                      }}
                    >
                      {weekName[(new Date(Object.keys(capacity)[0])).getDay()]}
                    </Grid>
                    {
                      capacity[Object.keys(capacity)[0]].map(gline => {
                        const { glineId, glineName, area, amount, gprocessColor } = gline;

                        let capacityByGProcess = 0;
                        if (statusPeriod[Object.keys(capacity)[0]] && statusPeriod[Object.keys(capacity)[0]][glineId]) {
                          capacityByGProcess = statusPeriod[Object.keys(capacity)[0]][glineId].areaJa;
                        }

                        const ratioCapacity = capacityByGProcess / Number(area) * 100;
              
                        let colorProgress = alarms[0].color;
                        if (ratioCapacity >= 100) {
                          colorProgress = alarms[2].color;
                        } else if(ratioCapacity >= 80) {
                          colorProgress = alarms[1].color;
                        }
                        
                        const rgb = getRGBfromHex(gprocessColor);
                        console.log(rgb)

                        const plugins = [{
                          beforeDraw: function(chart) {
                            var width = chart.width,
                            height = chart.height,
                            ctx = chart.ctx;
                            ctx.restore();
                            var fontSize = (height / 160).toFixed(2);
                            ctx.font = fontSize + "em sans-serif";
                            ctx.textBaseline = "top";
                            //  var text = "Foo-bar",
                            var text = `${(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2)} %`,
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = height / 2;
                            ctx.fillStyle = colorProgress;
                            ctx.fillText(text, textX, textY);
                            ctx.save();
                          }
                        }]

                        const data = {
                          // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                          datasets: [
                            {
                              label: 'Percent(%)',
                              // data: [12, 19, 3, 5, 2, 3],
                              data: [(capacityByGProcess/(capacityByGProcess+Number(area))*100).toFixed(2), (Number(area)/(capacityByGProcess+Number(area))*100).toFixed(2)],
                              backgroundColor: [
                                colorProgress,
                                'rgba(150, 150, 150, 0.2)',
                              ],
                              borderColor: [
                                colorProgress,
                                'rgba(150, 150, 150, 0.2)',
                              ],
                              borderWidth: 1,
                            },
                          ],
                        };

                        return (
                          <Grid
                            item
                            display="flex" justifyContent="center" alignItems="center"
                            sx={{
                              // bgcolor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`,
                              border: 2,
                              borderColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.4)`,
                              borderRadius: 2,
                              pb: 1.5,
                              width: '200px',
                              height: '170px',
                              ml: 1,
                            }}
                          >
                            <Doughnut
                              data={data}
                              options={{ responsive: false }}
                              plugins={plugins} 
                              sx={{ width: 100 }}
                            />
                          </Grid>
                        )
                      })
                    }
                  </>
                </Grid>
              )
            }) 
          } 
          </Stack>
        </>
      )
    }
    </Box>
  );
};

export default GPlanStatus2;
