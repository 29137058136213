import React, { useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import usePrevious from "../hook/usePrevious";
import {
  FormInputCheckbox,
  FormInputText,
  FormInputDropdown,
  FormInputToggleButton,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import { objectEmptyCheck } from "../../utils";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as g04docuActions from "../../store/g04docu";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as gclientActions from "../../store/gclient";
// import * as gmanufacturerActions from "../../store/gmanufacturer";
import * as gcomponentActions from "../../store/gcomponent";
import * as gcomponentItemActions from "../../store/gcomponentItem";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  gcomponentId: undefined,
  applyType: "none",
  itemType: "NONE",
  madeBy: "국내산",
  g04docuGCertificationId: null, // ""은 fk 오류발생
  price: 0,
  comments: "",
  dependentGcomponentItem: "",
};

const validateFields = {
  name: "이름",
  code: "코드",
};

const requiredMessage = "을(를) 입력해주세요.";

const schema = yup.object({
  name: yup.string().required(requiredMessage),
  code: yup.string().required(requiredMessage),
}).required();

const theme = createTheme();

// TODO : 이렇게 객체로 쓰면 간편한데??? 추후 검토
const cellStyle = {
  borderColor: '#dcdcdc',
  borderBottomWidth: 0, borderBottomWidth: 1, borderBottomStyle: 'solid',
  borderRightWidth: 0, borderRightWidth: 1, borderRightStyle: 'solid',
};

const GComponentItemDialog = ({
  crudMode,
  setCrudMode,
  // paste,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchItemType = queryStrings["searchItemType"];
  
  // const [errors, setErrors] = useState([]);

  // 다이얼로그를 닫을 때 초기화로 결정 (열때는 초기화하고 값을 설정하는 부분에서 실수할 확률이 있으므로)
  // 그리고 다이얼로그를 초기화하는 방식이 redux 값을 바꾸는 방법이 있으나(selectedRow를 초기화 => 그러면 selectedRow가 null 또는 undefined인 경우에도 처리되므로 비효율적) 직접 다이얼로그를 초기화하는 방식으로 결정
  // 참고로 selectedRow가 null 또는 undefined인가는 redux 초기화값에 따라 다름 (통일할 필요성 있음)
  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    // 아래 배열도 defaultValues 객체의 프로퍼티를 이용하여 바꿈. TODO : 추후 다른 다이얼로그도 확대 적용할 것
    // ["id", "name", "code", "gcomponentId", "g04docuGCertificationId", "price", "comments", "applyType", "dependentGcomponentItem", "itemType"].forEach(item => {
    //   setValue(item, defaultValues[item]);
    // });
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    initializeGComponentItem();
    initializeG04docuGCertifications();
    setG04docuGCertification(null);

    reset(); // validation error 포함하여 초기화
  }
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });
  
  const gcomponents = useSelector((state) => state.gcomponent.gcomponents);
  const gclients = useSelector((state) => state.gclient.gclients);
  const g04docus = useSelector((state) => state.g04docu.g04docus);
  // const g04docuGCertification = useSelector((state) => state.g04docuGCertification.g04docuGCertification);
  const g04docuGCertifications = useSelector((state) => state.g04docuGCertification.g04docuGCertifications);
  
  const [gcomponentItems, setGcomponentItems] = useState([]);
  const prevOpen = usePrevious(open);
  const [showDependentGcomponentItem, setShowDependentGcomponentItem] = useState(true);
  const [title, setTitle] = useState("");
  const [g04docuGCertification, setG04docuGCertification] = useState(null);
  const [currentSelectedClassifications, setCurrentSelectedClassifications] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});

  // 데이터 관리
  const dispatch = useDispatch();
  
  const addGComponentItem = ({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }) => dispatch(gcomponentItemActions.create({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }))
  const modifyGComponentItem = ({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }) => dispatch(gcomponentItemActions.modify({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }))
  const initializeGComponentItem = () => dispatch(gcomponentItemActions.initializeGComponentItem())
  const selectGComponentsByType = (type) => dispatch(gcomponentActions.selectByType(type))
  const selectGClientsByTypeByQuery = (type) => dispatch(gclientActions.selectByTypeByQuery(type))
  
  const selectG04docuGCertificationByDivisionByQuery = (division) => dispatch(g04docuGCertificationActions.selectByDivisionByQuery(division))
  const initializeG04docuGCertifications = () => dispatch(g04docuGCertificationActions.initializeG04docuGCertifications())
  const selectG04docuGCertificationByDirect = (g04docuGCertificationId) => g04docuGCertificationActions.selectByDirect(g04docuGCertificationId)
  const selectAllDirect = () => gcomponentItemActions.selectAllDirect();

  useEffect(
    () => {
      if (!objectEmptyCheck(errors)) {
        // console.log(errors)
        let error;
        for (const [key, value] of Object.entries(validateFields)) {
          // console.log(`${key}: ${value}`);
          if (errors[key]) {
            error = errors[key];
            error.fieldName = value;
            break;
          }
        }
        // console.log(error)
        const messageAlert = (<div>
          <span style={{ color: "#1976d2" }}>{error.fieldName}</span>{error.message}
        </div>);

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });
      }
    }, [errors]
  )

  // TODO : validation 처리 필요. 현재 name, code, itemType만 validation 처리함
  const onSubmit = ({ id, name, code, gcomponentId, /*gclientId, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy }) => {
    // setErrors([]);
    
    // TODO : 아래와 같이 gclient 정보를 찾는 것은 비효율적. 선택시 값이 설정되어 저장하도록 할 것 (추가로 거래처 정보도 많아질테니 거래처 검색 창을 추가로 구현해야 할 것으로 보임)
    // const gclient = gclients.find(gclient => gclient.id === gclientId);

    if (searchItemType === 'materials' && itemType === defaultValues.itemType) {
      const messageAlert = (<div>
        <span style={{ color: "#1976d2" }}>{"자재구분"}</span>{"을 선택해주세요."}
      </div>);

      setAlertInfo({
        titleAlert: "안내",
        messageAlert,
        open: true,
      });

      return;
    }

    // console.log({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications: currentSelectedClassifications });
    // return;

    let func;
    if (crudMode === 'C') {
      func = addGComponentItem;
    } else if (crudMode === 'U') {
      func = modifyGComponentItem;
    }
    
    func({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy: itemType === 'RAW_MATERIAL' ? madeBy: null, selectedClassifications: currentSelectedClassifications })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        // if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      await selectGClientsByTypeByQuery('MANUFACTURER'); // 수정 다이얼로그 로딩 전 완료하기 위해 // TODO : 추후 삭제
      await selectGComponentsByType('property');

      const gcomponentItems = await selectAllDirect();
      setGcomponentItems(gcomponentItems);
    }, [dispatch]
  );

  useEffect(
    async () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }
        
        // 그외 설정할 것들은 여기서 한다.
        const g04docuGCertificationId = selectedRow["g04docuGCertificationId"];
        if (g04docuGCertificationId) {
          const newG04docuGCertification = await selectG04docuGCertificationByDirect(g04docuGCertificationId);
          setG04docuGCertification(newG04docuGCertification);
        }

        const selectedClassifications = selectedRow["selectedClassifications"];
        if (selectedClassifications && Array.isArray(selectedClassifications)) {
          setCurrentSelectedClassifications(selectedClassifications);
        } else {
          const classifications = selectedRow["classifications"];
          if (classifications && Array.isArray(classifications)) {
            setCurrentSelectedClassifications(classifications);
          }
        }
      }
    }, [selectedRow]
  );
  
  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }

      // 원부자재와 속성은 같은 컴포넌트이므로 useEffect([dispatch])에서 아래 작업을 하면 한번만 실행됨
      if (searchItemType === 'materials') {
        setTitle("원부자재")
      } else if (searchItemType === 'preferences') {
        setTitle("속성")
      }
    }, [crudMode]
  );

  // 참고 : 부모폼의 호출순서에 따라 useEffect 순서가 변함에 유의. 부모폼의 handleSelect를 보면 select를 먼저하고 setOpen 호출하므로 여기서는 useEffect([selectedRow]) -> useEffect([open]) 순서로 호출됨
  useEffect(
    async () => {
      if (prevOpen === false && open === true) {
        // TODO : 아래도 매번 호출할 필요가 있을까? useEffect([dispatch]로 우선 이동. 추후 검토 필요)
        // const gcomponentItems = await selectAllDirect();
        // console.log(gcomponentItems);
        // setGcomponentItems(gcomponentItems);
        if (selectedRow && selectedRow["itemType"]) {
          selectedRow["itemType"] !== "NONE" ? selectG04docuGCertificationByDivisionByQuery(selectedRow["itemType"]) : initializeG04docuGCertifications ();
        }
      }
    }, [open]
  )

  // useEffect(
  //   async () => {
  //     console.log("[paste]")
  //   }, [paste]
  // )

  const handleChangeGcomponent = (e) => {
    const { value } = e.target;
    const selectedGcomponent = gcomponents.filter(gcomponent => gcomponent.id === value);
    setValue("gcomponentId", value);

    if (selectedGcomponent.length > 0) {
      const { multipleYN } = selectedGcomponent[0];
      // 아래는 소스가 setShowDependentGcomponentItem가 한번 나타나지만 show라는 변수가 하나 늘어나므로
      // 그 아래 코드를 선호함
      // let show = false;
      // if (multipleYN) {
      //   show = true;
      // }
      
      // setShowDependentGcomponentItem(show);

      if (multipleYN) {
        setShowDependentGcomponentItem(false);
        setValue("dependentGcomponentItem", "");
      } else {
        setShowDependentGcomponentItem(true);
      }
    }
  }

  const handleChangeApplyType = (e) => {
    if (crudMode === 'R') {
      return;
    }
    
    setValue("applyType", e.target.value);
  }

  const handleChangeItemType = (e) => {
    if (crudMode === 'R') {
      return;
    }

    const { value } = e.target;
    setValue("itemType", value);
    if (value !== "NONE") {
      selectG04docuGCertificationByDivisionByQuery(value);
    } else {
      initializeG04docuGCertifications();
    }
  }

  const handleChangeMadeBy = (e) => {
    if (crudMode === 'R') {
      return;
    }
    
    setValue("madeBy", e.target.value);
  }

  // TODO : 추후 다른 다이얼로그도 적용할 것. 적용하지 않으면 목록에서 하나를 선택한 후 다이얼로그를 닫지 않고 다른 메뉴를 누른 경우 다시 들어오면 등록하기 클릭시 초기화되어 있지 않음
  useEffect(() => {
    return () => {
      // Your code you want to run on unmount.
      // console.log(selectedRow)
      // if (selectedRow) { // TODO : 선택하고 다른 router로 넘어갈 때 selectedRow가 왜 null일까
        initializeGComponentItem();
      // }
    };
  }, []);

  const handleChangeCheckValue = (e, checked, name) => {
    // const { selectedClassifications } = selectedRow;
    console.log(currentSelectedClassifications)
    // console.log(e)
    console.log(checked)
    // console.log(name)

    // const { name } = e.target;
    const arr = [].concat(currentSelectedClassifications);
    
    const newCertifications = arr.map(certification => {
      if (certification.name === name) {
        return {
          ...certification,
          checked,
        }
      } else {
        return {
          ...certification,
          checked: false,
        }
      }
    })

    console.log(newCertifications);
    setCurrentSelectedClassifications(newCertifications);
  }

  const handleChangeGCertification = async (e) => {
    const g04docuGCertificationId = e.target.value;
    if (g04docuGCertificationId) {
      const newG04docuGCertification = await selectG04docuGCertificationByDirect(g04docuGCertificationId);
      setG04docuGCertification(newG04docuGCertification);
      setCurrentSelectedClassifications(newG04docuGCertification.classifications);
    } else {
      setG04docuGCertification(null);
    }
  }

  // useEffect(
  //   () => {
  //     if (g04docuGCertification && g04docuGCertification.classifications) {
  //       setCurrentSelectedClassifications(g04docuGCertification.classifications);
  //     }
  //   }, [g04docuGCertification]
  // )

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          { crudMode === 'C' && `${title} 등록` }
          { crudMode === 'R' && `${title} 상세` }
          { crudMode === 'U' && `${title} 수정` }
        </DialogTitleClose>
        <DialogContent dividers>
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                required
                fullWidth
                label={"이름"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
                errorStyle={{ message: false, border: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"code"}
                control={control}
                required
                fullWidth
                label={"코드"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
                errorStyle={{ message: false, border: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                select
                name="gcomponentId"
                control={control}
                label={"구성요소"}
                // sx={{ width: 300 }}
                options={
                  // undefined는 선택이 안됨. API backend에서도 객체에서 빠짐
                  [{ label: '없음', value: /*undefined*/null }].concat(gcomponents.map(gcomponent => {
                    return {
                      label: gcomponent.name,
                      value: gcomponent.id,
                    }
                  }))
                }
                onChange={handleChangeGcomponent}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" sx={{ border: '1px solid #C4C4C4', borderRadius: 1, width: '100%', height: '40px' }}>
                <Grid container>
                  <Grid item xs={12} sm={3} display="flex" alignItems="center">
                    <Typography component="div" sx={{ color: '#7B7B7B', ml: 1.6 }}>{"유리사양적용"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <FormInputToggleButton
                      name="applyType"
                      control={control}
                      // label="유리사양적용"
                      exclusive
                      options={[
                        {
                          label: "이름",
                          value: "productName",
                        },
                        {
                          label: "비고",
                          value: "etc",
                        },
                        {
                          label: "적용안함",
                          value: "none"
                        },
                      ]}
                      onChange={handleChangeApplyType}
                      sx={{ height: 24 }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {
              searchItemType === 'materials' && (
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" sx={{ border: '1px solid #C4C4C4', borderRadius: 1, width: '100%', height: '40px' }}>
                    <Grid container>
                      <Grid item xs={12} sm={3} display="flex" alignItems="center">
                        <Typography component="div" sx={{ color: '#7B7B7B', ml: 1.6 }}>{"자재구분"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputToggleButton
                          name="itemType"
                          control={control}
                          // label="자재구분"
                          exclusive
                          options={[
                            {
                              label: "원자재",
                              value: "RAW_MATERIAL",
                            },
                            {
                              label: "가공부자재",
                              value: "SUB_MATERIAL_PROCESS",
                            },
                            {
                              label: "시공부자재",
                              value: "SUB_MATERIAL_BUILD"
                            },
                          ]}
                          onChange={handleChangeItemType}
                          sx={{ height: 24 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            }
            {
              getValues("itemType") === 'RAW_MATERIAL' && (
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" sx={{ border: '1px solid #C4C4C4', borderRadius: 1, width: '100%', height: '40px' }}>
                    <Grid container>
                      <Grid item xs={12} sm={3} display="flex" alignItems="center">
                        <Typography component="div" sx={{ color: '#7B7B7B', ml: 1.6 }}>{"생산지구분"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputToggleButton
                          name="madeBy"
                          control={control}
                          exclusive
                          options={[
                            {
                              label: "국내산",
                              value: "국내산",
                            },
                            {
                              label: "국내산(로이)",
                              value: "국내산(로이)",
                            },
                            {
                              label: "수입산",
                              value: "수입산"
                            },
                            {
                              label: "수입산(로이)",
                              value: "수입산(로이)"
                            },
                          ]}
                          onChange={handleChangeMadeBy}
                          sx={{ height: 24 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            }
            {
              searchItemType === 'materials' && (
                <Grid item xs={12}>
                  <FormInputText
                    select
                    name="g04docuGCertificationId"
                    control={control}
                    label={"규격"}
                    // sx={{ width: 300 }}
                    options={
                      [{ label: '없음', value: null }].concat(g04docuGCertifications.map(g04docuGCertification => {
                        return {
                          label: <><Chip label={g04docuGCertification.gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{g04docuGCertification.name}</>,
                          value: g04docuGCertification.id,
                        }
                      }))
                    }
                    onCustomChange={handleChangeGCertification}
                    inputProps={
                      { readOnly: crudMode === 'R' }
                    }
                  />
                </Grid>
              )
            }
            {
              g04docuGCertification && g04docuGCertification.gstandardClassificationComponents && ( // GStandards의 classificationComponents 임
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Card>
                    <CardHeader
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      title={"종류·등급·호칭 또는 모델"}
                      sx={{ bgcolor: "#eaeaea" }}
                    />
                    <CardContent>
                      <Grid conatiner>
                        <Grid item xs={12}>
                          <Stack direction="row">
                            {
                              g04docuGCertification.gstandardClassificationComponents.map((types, i) => {
                                let prevArrayLength = 1;
                                if (i > 0) {
                                  for(let k=i-1; k>=0; k--) prevArrayLength = g04docuGCertification.gstandardClassificationComponents[k].length*prevArrayLength; // 현재 배열의 앞 배열들의 곱집합 크기
                                }

                                if (i === g04docuGCertification.gstandardClassificationComponents.length - 1) {
                                  return (
                                    <>
                                      <Grid container>
                                        {
                                          Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                                            types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                                              <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                                            </Grid>))
                                          ))
                                        }
                                      </Grid>
                                    </>
                                  )
                                } else {
                                  return (
                                    <Grid container>
                                      {
                                        Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                                          types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                                            <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                                          </Grid>))
                                        ))
                                      }
                                    </Grid>
                                  )
                                }
                              })
                            }
                            <Grid container sx={{ width: '42px' }}>
                            {
                              currentSelectedClassifications?.map((item, i) => {
                                // console.log(currentSelectedClassifications)
                                return (
                                  <Grid item xs={12} display="flex" alignItems="center" sx={{ ...cellStyle }}>
                                    <FormInputCheckbox
                                      name={`${i}_classificationStandard`}
                                      control={control}
                                      // label={item}
                                      setValue={setValue}
                                      onChangeCheckValue={handleChangeCheckValue}
                                      // item.check가 초기에 undefined이면 checked가 초기값이 설정되지 않으므로 state 변경시 rendering에 문제
                                      // TODO : controlled / uncontrolled 학습할 것 => 다른 form input 들도 내부에서 강제 rendering 했는데 이 문제일 수 있음
                                      checked={item.checked ? item.checked : false}
                                    />
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
            {/* TODO : 현재 사용하지는 않으나 추후 표준단가(소비자권장가 개념?) 등 사용할 수도 있으므로 남겨둠 */}
            {/* <Grid item xs={12}>
              <FormInputText
                name={"price"}
                control={control}
                label={"단가"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            {/* 설정한 값이 선택되어 있지 않아 분석한 결과 id를 uuid 형식으로 바꾼 것 때문이었음 */}
            {
              showDependentGcomponentItem && (<Grid item xs={12}>
                <FormInputText
                  select
                  name="dependentGcomponentItem"
                  control={control}
                  label={"의존구성요소값설정"}
                  // sx={{ width: 300 }}
                  options={
                    [{ label: '없음', value: undefined }].concat(gcomponentItems.map(gcomponentItem => {
                      // console.log(JSON.stringify(gcomponentItem) === JSON.stringify(selectedRow["dependentGcomponentItem"]));
                      const newGComponentItem = Object.assign({}, gcomponentItem);
                      delete newGComponentItem.createdAt;
                      delete newGComponentItem.updatedAt;

                      return {
                        label: newGComponentItem.name,
                        value: JSON.stringify(newGComponentItem),
                      }
                    }))
                  }
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>)
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            crudMode === 'C' && (<>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>)
          }
          { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GComponentItemDialog;
