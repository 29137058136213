// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_GDOMESTIC_AUTH_MAPS = 'gdomesticAuthMap/setGDomesticAuthMaps';

const setGDomesticAuthMaps = (gdomesticAuthMaps) => ({
  type: SET_GDOMESTIC_AUTH_MAPS,
  payload: gdomesticAuthMaps,
});

export const selectByGProjectDirect = async (gprojectId) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gdomesticAuthMaps/gproject/${gprojectId}`);
  const data = await response.json();
  return data.gdomesticAuthMaps;
}

export const selectByGProject = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gdomesticAuthMaps/gproject/${gprojectId}`);
  const data = await response.json();
  dispatch(setGDomesticAuthMaps(data.gdomesticAuthMaps));
  return response;
}

export const selectByGProjectByQuery = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gdomesticAuthMaps/query/select/project/${gprojectId}`);
  const data = await response.json();
  dispatch(setGDomesticAuthMaps(data.gdomesticAuthMaps));
  return response;
}

const initialState = {
  gdomesticAuthMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GDOMESTIC_AUTH_MAPS:
      newState = Object.assign({}, state, { gdomesticAuthMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
