import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
} from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  DesignServices,
  SlowMotionVideo,
  SwitchAccessShortcutAdd,
  Compress,
  Dehaze,
  OpenInNew,
} from '@mui/icons-material';
import './GWorkOrderDetailProcess.css';
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gunitActions from "../../store/gunit";
import * as gworkOrderDetailProcessActions from "../../store/gworkOrderDetailProcess";
import GWorkOrderDetailProcessDialog from "./GWorkOrderDetailProcessDialog";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    // height: 3,
    height: 0,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  width: 60,
  height: 40,
  position: 'relative',
  background: '#cacaca',
  // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  // zIndex: 1,
  // color: '#fff',
  // width: 50,
  // height: 50,
  display: 'flex',
  // borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: '10px solid white',
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    right: -10,
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: '10px solid #cacaca',
    ...(ownerState.active && {
      borderLeft: '10px solid #42a5f5',
    }),
    ...(ownerState.completed && {
      borderLeft: '10px solid #1976d2',
    }),
    ...(ownerState.error && {
      borderLeft: '10px solid #42a5f5',
    }),
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
  ...(ownerState.active && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#42a5f5',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#1976d2',
  }),
  ...(ownerState.error && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#1976d2',
  }),
}));

function ColorlibStepIcon(props) {
  // console.log("ColorlibStepIcon");
  // console.log(props);
  const { active, completed, className } = props;

  const defaultSx = { ml: 1 };
  const defaultRotate90Sx = { ml: 1, transform: "rotate(90deg)" };
  const rotate = {
    ml: 1,
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  };

  const icons = {
    // 1: <DesignServices sx={ active ? rotate : defaultSx } />,
    // 2: <SlowMotionVideo sx={ active ? rotate : defaultSx } />,
    // 3: <SwitchAccessShortcutAdd sx={ active ? rotate : defaultSx } />,
    // 4: <Compress sx={ active ? rotate : defaultSx } />,
    // 5: <Dehaze sx={ active ? rotate : defaultSx } />,
    "CUT": <DesignServices sx={ active ? rotate : defaultSx } />,
    "PREPROCESS": <SlowMotionVideo sx={ active ? rotate : defaultSx } />,
    "HEAT": <SwitchAccessShortcutAdd sx={ active ? rotate : defaultSx } />,
    "LAMINATION": <Compress sx={ active ? rotate : defaultRotate90Sx } />,
    "COUPLED": <Dehaze sx={ active ? rotate : defaultRotate90Sx } />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {/* {icons[String(props.icon)]} */}
      {icons[props.step.gprocessCode]}
    </ColorlibStepIconRoot>
  );
}

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const GWorkOrderDetailProcessManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    // const id = selectedRows[0].id;
    const { id } = params;

    if (type === "detail") {
      setModify(true);
      setOpen(true);
      selectByGworkOrderDetailId(id);
    }
  }

  const handleSelectUpDown = async ({ type, id }) => {
    await reorder({ type, id });
    setLoaded(false);
    await selectAll();
    setTimeout(() => setLoaded(true), 300)
  }

  const remove = (removeId) => {
    dispatch(gunitActions.remove(removeId))
    .then(res => selectAll())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    setOpen(true);
    setModify(false);
    // initGWorkOrderProcess(); // 다이얼로그 초기화
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 80,
      // editable: true,
    },
    {
      field: 'gprocess',
      headerName: '공정',
      width: 500,
      headerAlign: 'center',
      renderCell: (params) => {
        const { gprocess} = params.row;
        // console.log(params)
        // console.log(gprocess);
        const steps = gprocess;
        return (
          <Stack
            // sx={{ width: '100%' }}
            spacing={4}
          >
            {/* <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Tooltip title={label}>
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ label }} sx={{ ml: -0.8 }}>
                    {label}
                  </StepLabel>
                </Step>
                </Tooltip>
              ))}
            </Stepper> */}
            {/* TODO : 생산현황에 따라 아래 activeStep 조정 */}
            <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
              {steps.map((step) => (
                <Tooltip title={step.gprocessName}>
                <Step key={step.id}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ step }} sx={{ ml: -0.7 }}>
                  </StepLabel>
                </Step>
                </Tooltip>
              ))}
            </Stepper>
          </Stack>
        );
        
      },
    },
    {
      field: 'no',
      headerName: '품번',
      width: 120,
      // editable: true,
    },
    {
      field: 'standard',
      headerName: '규격',
      width: 200,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 200,
    },
    {
      field: 'width',
      headerName: '가로',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => params.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'height',
      headerName: '세로',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => params.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'amount',
      headerName: '수량',
      width: 80,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => params.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const rows = useSelector((state) => state.gworkOrderDetailProcess.gworkOrderDetailProcesses);
  const selectedRow = useSelector((state) => state.gworkOrderDetailProcess.gworkOrderDetailProcess);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = () => dispatch(gworkOrderDetailProcessActions.selectAllByQuery())
  const selectByGworkOrderDetailId = (gworkOrderDetailId) => dispatch(gworkOrderDetailProcessActions.selectByGworkOrderDetailId(gworkOrderDetailId))
  // const initGWorkOrderProcess = () => dispatch(gunitActions.initGWorkOrderProcess())
  const reorder = ({ type, id }) => gunitActions.reorder({ type, id })

  useEffect(
    async () => {
      await selectAll();

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GWorkOrderDetailProcessDialog
          modify={modify}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAll}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              {/* <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GWorkOrderDetailProcessManagement;
