import * as React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  // console.log(other.style)
  // React.useImperativeHandle(ref, () => ({
  //   focus: () => {
  //     // logic to focus the rendered component from 3rd party belongs here
  //   },
  //   // hiding the value e.g. react-stripe-elements
  // }));

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      // suffix=" 원"
      prefix={other.style.prefix}
      suffix={other.style.suffix}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberFormatCustom;