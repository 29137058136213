import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TableContainer,
  Table,  
  TableCell,
  TableBody,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import {
  CloudUpload,
  Close,
  FileUpload,
} from '@mui/icons-material';
import {
  FormInputText,
} from "../form";
import { AlertDialog } from "../dialog";
// import ImagePreview from "./ImagePreview";
import * as sessionActions from "../../store/session";
import * as gclientActions from "../../store/gclient";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "200px"
  },
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  //   height: 20
  // }
}));

const CertifiedBox = ({
  // max = 10,
  max = 1,
  control,
  setValue,
  getValues,
  defaultValues,
}) => {
  const [URLThumbnail, setURLThumbnail] = useState("");
  // const [files, setFiles] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const sessionUserLoginType = useSelector((state) => state.session.sessionUser.sessionUserLoginType);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);

  const uploadFile = (gclientId, formData) => gclientActions.uploadFileDirect(gclientId, formData)
  const downloadFile = (documentPath) => gclientActions.downloadDirect(documentPath)
  const removeFile = (gclientId, documentPath) => gclientActions.removeDirect(gclientId, documentPath)

  const dispatch = useDispatch();

  const updateSessionUser = (gclient) => dispatch(sessionActions.updateSessionUser(gclient))
  const restoreSessionUser = () => dispatch(sessionActions.restoreSessionUser())

  const [uploadBoxStyle, setUploadBoxStyle] = useState({ border: 1, borderRadius: 2, borderColor: 'divider' });

  const uploadBoxRef = useRef();
  const inputRef = useRef();

  // const uploadBox = uploadBoxRef.current;
  // const input = inputRef.current;

  // event handler
  const fileTypes = ["png", "jpeg", "bmp", "jpg", "gif"];
  
  const dragOverHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: '#1565c0', backgroundColor: '#e1f5fe' })
  };
  const dragLeaveHandler = (event) => { setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: 'divider' }); }
  const mouseOverHandler = (event) => { setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: '#1565c0', backgroundColor: '#e1f5fe' }); }
  const mouseLeaveHandler = (event) => { setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: 'divider' }); }
  // 

  useEffect(
    () => {
      const uploadBox = uploadBoxRef.current;
      const input = inputRef.current;
      
      // const handleFiles = (files) => {
      //   for (const file of files) {
      //     if (!file.type.startsWith("image/")) continue;
      //     const reader = new FileReader();
      //     reader.onloadend = (e) => {
      //       const result = e.target.result;
      //       if (result) {
      //         setUploadedImages((state) => [...state, result].slice(0, max));
      //       }
      //     };
      //     reader.readAsDataURL(file);
      //   }
      // };
      
      // const changeHandler = (event) => {
      //   event.preventDefault();
      //   event.stopPropagation();
      //   const files = event.target.files;
      //   handleFiles(files);
      // };
      
      // const dropHandler = (event) => {
      //   event.preventDefault();
      //   event.stopPropagation();
      //   const files = event.dataTransfer.files;
      //   handleFiles(files);
      // };
      const createImageURLAndUpload = async (fileBlob) => {  // createObjectURL 방식
        // console.log(fileBlob)
        if (URLThumbnail) {
          URL.revokeObjectURL(URLThumbnail);
        }
        
        setLoadingAdd(true);

        // handleClicUpload();
        const formData = new FormData();
              formData.append('gclientName', sessionUser.name);
              formData.append('images', fileBlob);
              formData.append('fileName', fileBlob.name); // TODO : 한글 파일명인 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
        // console.log(Object.fromEntries(formData.entries()))
        // console.log(gclient)
        const signDocumentPath = await uploadFile(sessionUser.id, formData);
        // console.log(signDocumentPath)

        // setFiles([]);

        // updateSessionUser({
        //   ...sessionUser,
        //   signDocumentPath,
        // });
        restoreSessionUser();

        const url = URL.createObjectURL(fileBlob);
        setURLThumbnail(url);
        // setFiles([fileBlob]);

        setLoadingAdd(false);
      };

      const dropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: 'divider' })
    
        const { files } = e.dataTransfer;
    
        if (!files || !files[0]) return;
        
        if (files.length > 1) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "하나의 파일만 선택해주세요.",
            open: true,
          });

          return;
        }

        const { type } = files[0];
        
        const matches = fileTypes.filter(fileType => type.indexOf(fileType) > 0);
        if (matches.length === 0) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: (<><span style={{ color: "#1976d2" }}>{fileTypes.join(", ")}</span>{" 파일만 사용이 가능합니다."}</>),
            open: true,
          });
          return;
        }
        
        createImageURLAndUpload(files[0]);
      };


      const changeHandler = async (e) => {        
        e.preventDefault();
        e.stopPropagation();
    
        const { files } = e.target;
        console.log(files)
    
        if (!files || !files[0]) return;
        
        if (files.length > 1) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "하나의 파일만 선택해주세요.",
            open: true,
          });

          e.target.value = ''; // 같은 파일을 올리면 onChange 이벤트가 발생하지 않으므로 초기화

          return;
        }

        const { type } = files[0];
        
        const matches = fileTypes.filter(fileType => type.indexOf(fileType) > 0);
        if (matches.length === 0) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: (<><span style={{ color: "#1976d2" }}>{fileTypes.join(", ")}</span>{" 파일만 사용이 가능합니다."}</>),
            open: true,
          });

          e.target.value = ''; // 같은 파일을 올리면 onChange 이벤트가 발생하지 않으므로 초기화

          return;
        }

        createImageURLAndUpload(files[0]);
    
        e.target.value = ''; // 같은 파일을 올리면 onChange 이벤트가 발생하지 않으므로 초기화
      };
      
      uploadBox.addEventListener("drop", dropHandler);
      uploadBox.addEventListener("dragover", dragOverHandler);
      uploadBox.addEventListener("dragleave", dragLeaveHandler);
      uploadBox.addEventListener("mouseover", mouseOverHandler);
      uploadBox.addEventListener("mouseleave", mouseLeaveHandler);
      
      input.addEventListener("change", changeHandler);
      
      return () => {
        uploadBox.removeEventListener("drop", dropHandler);
        uploadBox.removeEventListener("dragover", dragOverHandler);
        uploadBox.removeEventListener("dragleave", dragLeaveHandler);
        uploadBox.removeEventListener("mouseover", mouseOverHandler);
        uploadBox.removeEventListener("mouseleave", mouseLeaveHandler);
        
        input.removeEventListener("change", changeHandler);
      };
    }, [max, URLThumbnail]
  );
  
  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     const response = await MyAPI.getData(someId);
  //     // ...
  //   }
  //   fetchData();
  // }, [someId]); // Or [] if effect doesn't need props or state
  useEffect(() => {
    // console.log(sessionUser)
    const { signDocumentPath } = sessionUser;

    if (signDocumentPath) {
      async function load () {
        const res = await downloadFile(signDocumentPath);
        const blob = await res.blob(); // res.blob()는 비동기 함수이므로 await 필요
        const url = URL.createObjectURL(blob);
        setURLThumbnail(url);
        // setFiles([blob]);
      }
      load();
    }
  }, [sessionUser])
  
  // useEffect(
  //   () => {
  //     const imageJSXs = uploadedImages.map((image, index) => {
  //       const isDeleteImage = (element) => {
  //         return element === image;
  //       };
  //       const deleteFunc = () => {
  //         uploadedImages.splice(uploadedImages.findIndex(isDeleteImage), 1);
  //         setUploadedImages([...uploadedImages]);
  //       };
  //       return <ImagePreview image={image} deleteFunc={deleteFunc} key={index} />;
  //     });
  //     setPreviewImages(imageJSXs);
  //   }, [uploadedImages]
  // );

  // const handleClicUpload = async () => {
  //   // console.log(files)

  //   const formData = new FormData();
  //         formData.append('gclientName', sessionUser.name);
  //         formData.append('images', files[0]);
  //         formData.append('fileName', files[0].name); // TODO : 한글 파일명인 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함

  //   // console.log(gclient)
  //   const signDocumentPath = await uploadFile(sessionUser.id, formData);
  //   // console.log(signDocumentPath)

  //   // setFiles([]);

  //   updateSessionUser({
  //     ...sessionUser,
  //     signDocumentPath,
  //   });

  //   // FormData는 브라우저 정책상 console.log로 출력안되므로 아래와 같이 햐여 값을 볼 수 있다.
  //   // for (let [index, value] of formData) {
  //   //   console.log({ index, value})
  //   // }
  // }

  const handleClickDelete = async () => {
    const { id, signDocumentPath } = sessionUser;
    if (signDocumentPath) {
      setLoadingDelete(true);
      await removeFile(id, signDocumentPath); // 서버에서 파일 삭제
      setTimeout(() => setLoadingDelete(false), 1000);
    }

    URL.revokeObjectURL(URLThumbnail);
    
    setURLThumbnail("");

    // updateSessionUser({
    //   ...sessionUser,
    //   signDocumentPath: null,
    // });
    restoreSessionUser();
    // setFiles([]);
  }

  const handleChangeSupplierLabel = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setValue("supplierLabel", value);  
    } else {
      // setValue("supplierLabel", defaultValues.supplierLabel);
      setValue("supplierLabel", "");
    }
  }

  const handleChangeSupplier = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setValue("supplier", value);  
    } else {
      // setValue("supplier", "제공사");
      setValue("supplier", "");
    }
  }

  const handleChangePhrase = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setValue("phrase", value);
    } else {
      // setValue("phrase", `이 문서는 ${getValues("supplier")}가 제출처에게 발행한 문서입니다.`);
      setValue("phrase", "");
    }
  }
  
  const handleClickTextInit = () => {
    setValue("supplierLabel", "제공사");
    setValue("supplier", sessionUser.name);
    // setValue("phrase", `이 문서는 ${sessionUser.name}가 제출처에게 발행한 문서입니다.`);
    setValue("phrase", `본 문서는 원본과 같음을 확인하며, 상기 현장 외에 사용을 금합니다.`);
  }

  return (
    <Box>
      {/* label로 input과 결합 */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid xs={12} display="flex" justifyContent={"center"} alignItems="center">
              <TableContainer>
                <Table aria-label="날일정보" sx={{ width: 400 }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colspan={2} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '90%', fontSize: '20px', color: '#664AA0' }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          {"원본대조필 (인)"}
                        </Box>
                      </TableCell>
                      {/* {URLThumbnail && <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '60px' }} />} */}
                      <TableCell sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0', p: 0 }}>
                        {/* {
                          URLThumbnail ? (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                            <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                          </Box>) : (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                            {"(인)"}
                          </Box>)
                        } */}
                        {
                          URLThumbnail && (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                            <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                          </Box>)
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, color: '#664AA0', width: '30%' }}>
                        <Grid container>
                          <Grid item xs={10}>
                            <FormInputText
                              autoComplete="supplierLabel"
                              name={"supplierLabel"}
                              control={control}
                              required
                              fullWidth
                              // label={"제공사 라벨명"}
                              errorStyle={{ message: false, border: true }}
                              onChange={handleChangeSupplierLabel}
                              variant="standard"
                              // InputProps={{
                              //   disableUnderline: true,
                              // }}
                              inputProps={{ style: { fontSize: 14, color: '#664AA0' }, disabled: sessionUserRole === 'ADMIN_ROLE' ? false : true }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {" : "}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0',  }}>
                        <FormInputText
                          autoComplete="supplier"
                          name={"supplier"}
                          control={control}
                          required
                          fullWidth
                          // label={"제공사"}
                          errorStyle={{ message: false, border: true }}
                          onChange={handleChangeSupplier}
                          variant="standard"
                          inputProps={{ style: { fontSize: 14, color: '#664AA0' }, disabled: sessionUserRole === 'ADMIN_ROLE' ? false : true }}
                          // InputProps={{
                          //   disableUnderline: true,
                          // }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                        <Grid container>
                          <Grid item xs={10}>
                            {"제출처"}
                          </Grid>
                          <Grid item xs={2}>
                            {" : "}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                        {"제출처명"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                        <Grid container>
                          <Grid item xs={10}>
                            {"현장명"}
                          </Grid>
                          <Grid item xs={2}>
                            {" : "}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                        {"현장명"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ border: 1, borderColor: '#664AA0' }}>
                        <FormInputText
                          name={"phrase"}
                          control={control}
                          // label={"문구"}
                          // fullWidth
                          multiline
                          maxRows={5}
                          onChange={handleChangePhrase}
                          variant="standard"
                          // InputProps={{
                          //   disableUnderline: true,
                          // }}
                          inputProps={{ style: { fontSize: 14, color: '#664AA0' }, disabled: sessionUserRole === 'ADMIN_ROLE' ? false : true }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1, display: sessionUserRole === 'ADMIN_ROLE' ? "" : "none" }}>
          <Tooltip title={"여기에 파일을 놓거나 누르세요."}>
            <LoadingButton
              // variant={"contained"}
              variant={"outlined"}
              disabled={URLThumbnail ? true : false}
              sx={{ mr: 1 }}
              loading={loadingAdd}
            >
              <label htmlFor={"input_image"} ref={uploadBoxRef}>
                <Grid container sx={{ cursor: 'pointer' }}>
                  {"인감등록"}
                </Grid>
              </label>
              <input hidden
                type="file"
                multiple
                /*accept="image/*"*/
                accept=".png,.jpeg,.bmp,.jpg,.gif"
                id={"input_image"}
                ref={inputRef}
              />
            </LoadingButton>
          </Tooltip>
          <LoadingButton
            // variant={"contained"}
            variant={"outlined"}
            onClick={handleClickDelete}
            disabled={URLThumbnail ? false : true}
            loading={loadingDelete}
          >
            {"인감삭제"}
          </LoadingButton>
          <Button
            // variant={"contained"}
            variant={"outlined"}
            onClick={handleClickTextInit}
            sx={{ ml: 1 }}
          >
            {"기본 날인 내용 입력"}
          </Button>
        </Grid>
      </Grid>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </Box>
  )
}

export default CertifiedBox;