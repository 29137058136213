// TODO : 여러 곳에서 사용하다 보니 특정 목적에 맞게 구현된 부분이 있어서 추후 통합 검토해 볼 것
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useController } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.0 + ITEM_PADDING_TOP + 10,
      width: 250,
    },
  },
};

// TODO : code가 없는 object도 사용할 수 있으므로... 일단 id로 교체 => id도 아닐 수 있음. 외부에서 비교할 값을 설정하도록 해야 할 듯
// function getStyles(code, items, theme) {
//   return {
//     fontWeight:
//       // items.indexOf(code) === -1
//       items.filter(item => item !== undefined && item !== null && JSON.parse(item).code === code).length === 0
//         ? theme.typography.fontWeightRegular
//         // : theme.typography.fontWeightMedium,
//         : theme.typography.fontWeightBold,
//   };
// }
function getStyles(id, items, theme) {
  return {
    fontWeight:
      // items.indexOf(code) === -1
      items.filter(item => item !== undefined && item !== null && JSON.parse(item).id === id).length === 0
        ? theme.typography.fontWeightRegular
        // : theme.typography.fontWeightMedium,
        : theme.typography.fontWeightBold,
  };
}

// TODO : 초기화 관련하여 initialize, setInitialize 추가하였는데 다른 곳에서 문제 발생시 우선 검토할 것
const FormInputMultipleSelect = React.forwardRef(({ id, name, control, value, options, setMultiselectValue, label, setValue, onChangeItem, dynamicItems, dynamicGClients, dynamicGClients1, dynamicItemsBasic, initialize, setInitialize, chipSize, ...props }, ref) => {
  React.useImperativeHandle(ref, () => ({
    setItemsEmpty, // 목록이 비워졌는데 설정값이 남는 경우 강제로 설정값을 해제하는 함수로 외부에서 호출할 수 있다.
  }))
  
  const setItemsEmpty = () => {
    setItems([]);
  }

  const theme = useTheme();
  const [items, setItems] = React.useState([]);

  const {
    field: { onChange },
    fieldState: { error },
    formState,
  } = useController({
    name,
    control,
    // rules: { required: true },
    // defaultValue: "Hansen",
  });

  const handleChange = (event) => {
    setInitialize && setInitialize(false);
    const { target: { value } } = event;
    // console.log(value)
    setItems(value);
    onChangeItem && onChangeItem(value);
  };

  /**
   * 아래 함수를 Select의 value값에 설정하여 외부에서 들어온 값(value 또는 dynamicItems 등...)으로 선택한 값을 관리한다.
   * 그리고 displayValue의 반환값에 배열이 비어 있지 않은 경우 renderValue가 호출됨 (비어 있는 경우 호출되지 않음)
   * renderValue안에서 필요에 따라 items를 값과 동기화한다. items와 options 값으로 선택한 상태를 표시한다.
   * 그러나 displayValue의 반환값이 없는 경우 items와 동기화할 방법이 없어 외부에 items 관리 함수를 만들어 필요시 호출하도록 했다.
   */
  const displayValue = () => {
    const result = value && Array.isArray(value) && value.filter(item => item !== null);
    if (result && result.length > 0) {
      
      return value.map(item => JSON.stringify(item));

    } else {
      if (dynamicItems) { // TODO : 유리구성요소에 특화되어 있음
        const { selectedGcomponentItems, gtypeDetailsIndex, gcomponentItemIndex, gcomponentItem } = dynamicItems;
        // console.log(dynamicItems)
        // TODO : 화면에서 두 배열의 크기가 달라지는 시점이 발생하여 여러번 렌더링되므로 이 코드(아래 if)를 사용하여 예외처리함. (gtypeDetailsWithComponent 와 selectedGcomponentItems가 항시 같아야 하지만 변경시 순차적으로 변하면서 다른 상태에서 렌더링됨. 화면에서 두 배열의 크기를 검증하는 것도 방법)
        if (selectedGcomponentItems[gtypeDetailsIndex] && selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex]) {
          const arrItems = selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex].value;
          if (Array.isArray(arrItems)) {
            const selected = gcomponentItem.properties.filter(property => selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex].value.map(component => component.code).includes(property.code)); // TODO : id or code 추후 검토
            // console.log(selected)
            if (selected.length > 0) {
              const jsonSelected = selected.map(select => JSON.stringify(select));
              const itemsNew = items.concat(jsonSelected);
      
              const unique = itemsNew.filter((item, pos) => itemsNew.indexOf(item) === pos);
              // console.log(unique)
              return unique;
            } else {
              return [];
            }
          }
        }
      } else if (dynamicGClients) { // TODO : 가공업체 목록에 특화되어 있음, 전체 변경. 추후 검토 필요
        const { selectedProcessGClients, i, selectedGclients } = dynamicGClients;
        // console.log(dynamicGClients)
        const arrItems = selectedProcessGClients[i].value;
        if (Array.isArray(arrItems) && Array.isArray(selectedGclients)) {
          const selected = selectedGclients.filter(gclient => selectedProcessGClients[i].value.map(component => component.id).includes(gclient.id)); // TODO : id or code 추후 검토
          // console.log(selected)
          if (selected.length > 0) {
            const jsonSelected = selected.map(select => JSON.stringify(select));
            const itemsNew = items.concat(jsonSelected);
    
            const unique = itemsNew.filter((item, pos) => itemsNew.indexOf(item) === pos);
            // console.log(unique)
            return unique;
          } else {
            return [];
          }
        }
      } else if (dynamicGClients1) { // TODO : 가공업체 목록에 특화되어 있음, 전체 변경. 추후 검토 필요
        const { selectedProcessGClients, i, selectedGClients } = dynamicGClients1;
        // console.log(dynamicGClients1)
        const arrItems = selectedProcessGClients[i].gclients;
        if (Array.isArray(arrItems) && Array.isArray(selectedGClients)) {
          const selected = selectedGClients.filter(gclient => selectedProcessGClients[i].gclients.map(item => item.id).includes(gclient.id)); // TODO : id or code 추후 검토
          // console.log(selected)
          if (selected.length > 0) {
            const jsonSelected = selected.map(select => JSON.stringify(select));
            const itemsNew = items.concat(jsonSelected);
    
            const unique = itemsNew.filter((item, pos) => itemsNew.indexOf(item) === pos);
            // console.log(unique)
            return unique;
          } else {
            return [];
          }
        }
      } else if (dynamicItemsBasic) {
        // console.log(dynamicItemsBasic)
        const arrItems = [].concat(dynamicItemsBasic);
        if (arrItems.length > 0) {
          /**
           * TODO : 아래의 코드는 외부에서 값을 설정시 기존값과 비교하여 중복을 제거하고 병합하는 부분인데,
           * 기존에 item이 있는 경우에 추가 또는 삭제할 때 삭제시 삭제되지 않는 문제 발생
           * 위의 코드들(dynamicItems, dynamicGClients, dynamicGClients1)은 문제 없는지 검토 필요
           */
          // const jsonSelected = arrItems.map(select => JSON.stringify(select));
          // const itemsNew = items.concat(jsonSelected);
          // // console.log(itemsNew)
          // const unique = itemsNew.filter((item, pos) => itemsNew.indexOf(item) === pos);
          // // console.log(unique)
          // return unique;
          const newItems = arrItems.map(select => JSON.stringify(select))
          // console.log({ items, newItems })
          return newItems;
        } else {
          return [];
        }
      }

      return initialize ? value : items;
    }
  }

  useEffect(() => {
    setMultiselectValue && setMultiselectValue([]); // 외부에서 설정한 value값 초기화
    if (items && items.length > 0) {
      // setMultiselectValue && setMultiselectValue([]); // 외부에서 설정한 value값 초기화
      setValue(name, items.filter(item => item !== undefined && item !== null).map(item => JSON.parse(item))); // filter를 먼저 수행. 목록이 변경되었는데 선택된 내용이 남아 있으면 문제 발생
    } else {
      setValue(name, value);
    }
  }, [items]);

  return (
    <div>
      <FormControl size={"small"} fullWidth>
        <InputLabel id={`multi-select-${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`multi-select-${id}-label`}
          id={`multi-select-${id}`}
          multiple
          value={displayValue()}
          onChange={handleChange}
          label={label}
          renderValue={ // value가 값이 있을 때만 들어옴
            (selected) => {
              if (items && items.length === 0 || items.length !== selected.length) {
                setItems(selected)
              }
              
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {items.map((value) => {
                    // console.log(value)
                    if (value === undefined || value === null) {
                      return <></>;
                    }

                    return (
                      <Chip key={JSON.parse(value).id} color="primary" variant="outlined" label={JSON.parse(value).name} size={chipSize} />
                    )
                  })}
                </Box>
              )
            }
          }
          MenuProps={MenuProps}
          {...props}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={JSON.stringify(option)} // Object를 담기 위해 이와 같이 사용 => 추후 객체가 아닌 문자열의 아이디나 코드만 사용하고 객체는 필요시 찾아서 쓰는 방식이 어떨까 한다.
              // style={getStyles(option.code, items, theme)} // TODO : code가 없는 object도 사용할 수 있으므로... 일단 id로 교체
              style={getStyles(option.id, items, theme)}
              disabled={option.disabled ? option.disabled : false}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
});

export default FormInputMultipleSelect;
