// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GWORKORDER_DETAIL = 'gworkOrderDetail/setGWorkOrderDetail';
const REMOVE_GWORKORDER_DETAIL = "gworkOrderDetail/removeGWorkOrderDetail";
const SET_GWORKORDER_DETAILS = 'gworkOrderDetail/setGWorkOrderDetailProcesses';
const SET_GWORKORDER_DETAILS_BY_GWORKORDERNO_BY_GPROCESS = 'gworkOrderDetail/setGWorkOrderDetailProcessesByGWorkOrderByGProcess';
const SET_GWORKORDER_DETAILS_BY_GLINE = 'gworkOrderDetail/setGWorkOrderDetailsByGLine';

const setGWorkOrderDetail = (gworkOrderDetail) => ({
  type: SET_GWORKORDER_DETAIL,
  payload: gworkOrderDetail,
});

const removeGWorkOrderDetail = () => ({
  type: REMOVE_GWORKORDER_DETAIL,
});

const setGWorkOrderDetails = (gworkOrderDetails) => ({
  type: SET_GWORKORDER_DETAILS,
  payload: gworkOrderDetails,
});

const setGWorkOrderDetailsByGWorkOrderByGProcess = (gworkOrderDetailsByGWorkOrderByGProcess) => ({
  type: SET_GWORKORDER_DETAILS_BY_GWORKORDERNO_BY_GPROCESS,
  payload: gworkOrderDetailsByGWorkOrderByGProcess,
});

const setGWorkOrderDetailsByGLine = (gworkOrderDetailsByGLine) => ({
  type: SET_GWORKORDER_DETAILS_BY_GLINE,
  payload: gworkOrderDetailsByGLine,
});



// export const countNoWorkOrderByGOrderIdByQueryByDirect = async (gorderId) => {
//   const response = await csrfFetch(null, `/api/gworkOrderDetails/query/noWorkOrder/gorderId/${gorderId}`);
//   const data = await response.json();
//   return data.count;
// }

export const selectAllDetailsByIdTypeByQuery = (idType, id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAllByIdType/idType/${idType}/id/${id}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetails(data.gworkOrderDetails));
  return response;
}

export const setAmounts = ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrderDetails/amounts", {
    method: "PUT",
    body: JSON.stringify({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }),
  });
  const data = await response.json();
  dispatch(setGWorkOrderDetail(data.gworkOrderDetail));
  return response;
};

export const setAmountsDirect = async ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => {
  const response = await csrfFetch(null, "/api/gworkOrderDetails/amounts", {
    method: "PUT",
    body: JSON.stringify({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }),
  });
  const data = await response.json();
  return data.gworkOrderDetail;
};

export const selectAllDetailsByStatusAndGProcessByQuery = (status, gprocess) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAllByStatus/status/${status}/gprocess/${gprocess}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetails(data.gworkOrderDetails));
  return response;
}

export const selectAllDetailsByGWorkOrderByQuery = (workOrderNo) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAllByGWorkOrder/workOrderNo/${workOrderNo}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetails(data.gworkOrderDetails));
  return response;
}

export const selectAllByGWorkOrderByGProcessByQuery = (workOrderNo, gprocessId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAllByGWorkOrderByGProcess/workOrderNo/${workOrderNo}/gprocessId/${gprocessId}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetailsByGWorkOrderByGProcess(data.gworkOrderDetailsByGWorkOrderByGProcess));
  return response;
}

export const selectAllByGLineByQuery = (glineId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAllByGLine/glineId/${glineId}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetailsByGLine(data.gworkOrderDetailsByGLine));
  return response;
}

export const selectAllDetailsByQuery = ({
  status,
  gprocess,
  condition,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetails/query/selectAll`, {
    method: 'POST',
    body: JSON.stringify({
      status,
      gprocess,
      condition,
    }),
  });
  const data = await response.json();
  dispatch(setGWorkOrderDetails(data.gworkOrderDetails));
  return response;
}

const initialState = {
  gworkOrderDetail: null,
  gworkOrderDetails: [],
  // TODO : 아래 두개는 하나로 쓸 수 있으면 통합할 것
  gworkOrderDetailsByGWorkOrderByGProcess: [],
  gworkOrderDetailsByGLine:[],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GWORKORDER_DETAIL:
      newState = Object.assign({}, state, { gworkOrderDetail: action.payload });
      return newState;
    case REMOVE_GWORKORDER_DETAIL:
      newState = Object.assign({}, state, { gworkOrderDetail: null });
      return newState;
    case SET_GWORKORDER_DETAILS:
      newState = Object.assign({}, state, { gworkOrderDetails: action.payload });
      return newState;
    case SET_GWORKORDER_DETAILS_BY_GWORKORDERNO_BY_GPROCESS:
      newState = Object.assign({}, state, { gworkOrderDetailsByGWorkOrderByGProcess: action.payload });
      return newState;
    case SET_GWORKORDER_DETAILS_BY_GLINE:
      console.log(action.payload)
      newState = Object.assign({}, state, { gworkOrderDetailsByGLine: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
