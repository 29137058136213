import { WbShade } from "@mui/icons-material";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
// import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import { dateFormat, addDays } from "./Date";

const ExcelUtil = {
  //headers 컬럼명
  //keys 타입 (kay-value 형식의 key)
  excelDownload : function(menu, data, headers, keys, res) {
    //파일이름에들어갈 오늘날짜를 위해서 데이트선언 ( 중복 방지는 덤 )
    const currentDate = new Date();
    
    //오늘날짜를 YYYY-MM-DD 로 선언하여 파일이름에 붙이기 위해서.
    const currentDayFormat = currentDate.getFullYear()+"-"+ (currentDate.getMonth()+1)+"-"+ currentDate.getDate();
                            
    //exceljs 를써서 새로운 엑셀객체 생성
    const workbook = new ExcelJS.Workbook();
    
    //해당객체의 시트를 생성하면서 이름을 정해준다.
    const worksheet = workbook.addWorksheet('Test Excel Sheet');
    
    //엑셀 헤더부분에 들어갈 첫줄데이터, 구분자로 되어있기때문에 받아서 다시 배열로 셋팅하는것.
    headers = headers.split(',');
    //데이터를 넣을때 헤더부분에 셋팅하게위해 키값을 선언하여 addrow 데이터의 json key값과 동일하게 선언해주면된다.
    keys = keys.split(',');
    
    //var fullData = JSON.parse(data);//page값으로 리턴되기때문에 전체데이터를 받아서 content 데이터를 따로 때주는작업을 진행
    data = data.content;
    
    //시트의 첫 헤더를 정해줄 데이터를 담을 json데이터
    const columnsHeader = {
      headerData: []
    };
    
    //위에서 ,구분자로 짤라서 담아놓은 배열의 사이즈만큼 반복하여 헤더데이터에 셋팅 셋팅값은 {header : --, key: -- 두가지는 필수값}
    for(var i=0;i<headers.length;i++){
      columnsHeader.headerData[i] = { header: headers[i], key: keys[i]};
    }

    //포문을 통해서 직접 셋팅하게되면 write 할시에 에러를 뱉어내기때문에 다른객체에 담아서 넣어줍니다.
    worksheet.columns = columnsHeader.headerData;
    
    //사용자데이터의 길이만큼 반복하여 엑셀시트의 row를 생성해줌.
    for(var dataIndex = 0; dataIndex<data.length;dataIndex++){
      var rows = {};//row데이터를 담아서 셋팅해줄 json데이터
      //한개의 row데이터를 셋팅할때 json형식으로 셋팅하기때문에 헤더의 키와 맞춰주기위해서 키순서대로 반복
      for(var keyIndex = 0; keyIndex<keys.length; keyIndex++){
          if(keys[keyIndex]== 'test key'){
              rows[keys[keyIndex]] = data[dataIndex].testData;
          }
          /**
          * 이런식으로 key값과 data값을 맞춰주며 반복하고, 일일히 데이터 수정 가능하게끔 작성
          */
      //1row당 키값을 전부셋팅했으면 시트에 추가해주는것.
      worksheet.addRow(rows);
    }
    //엑셀시트을 생성하기위해서 헤더셋팅
    res.setHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    res.setHeader("Content-Disposition", "attachment; filename=" + menu+currentDayFormat+".xlsx");//menu와 파일명을 동일하게 취급하여 파일명 = 메뉴+오늘날짜.xlsx 로 셋팅
    //실제로 workbook을 만들고 파일로 다운로드 할수있게 만들어주는함수
    workbook.xlsx.write(res)
      .then(function (data) {
        res.end();
        console.log('File write done........');
      });
    }
  },
  createExcelForOrders: async function (orderDatas) {
    console.log(orderDatas)
    const {
      // id,
      // orderSerialNo,
      orderNo,
      // gprojectId,
      gclientName,
      site,
      inChargeNameGclient,
      inChargePhoneGclient,
      // inChargeEmailGclient,
      deliveryAddress,
      orderDate,
      deliveryDate,
      // owner,
      orderer,
      ordererName,
      ordererPhone,
      ordererEmail,
      // comments,
      gorderDetails,
      // sumAmount,
      // sumAreaMeter,
      // sumAreaJa,
      // sumAreaFeet,
      // sumOrderPriceTotal,
    } = orderDatas;

    const wb = new ExcelJS.Workbook();
    // const ws = wb.addWorksheet()
    const ws = wb.addWorksheet(
      orderNo, // sheet name
      {
        properties: {
          tabColor: { argb:'FF00FF00' }
        },
        views:[{
          // state: 'frozen',
          // ySplit: 5, // fixed row
          activeCell: 'A1',
          showGridLines:false, // 그리드 라인 삭제
        }],
        // headerFooter: {
        //   firstHeader: "Hello Exceljs",
        //   firstFooter: "Hello World",
        // },
        pageSetup: {
          paperSize: 9,
          orientation:'landscape',
        },
      }
    );
    
    const title = "발  주  서"
    ws.addRow([title]);
    // row.font = { bold: true };

    ws.mergeCells('A1:I1');
    ws.getCell('A1').font = {
      name: '맑은 고딕',
      family: 4,
      size: 45,
      // underline: true,
      bold: true
    };

    ws.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    
    const colA = ws.getColumn(1);
    colA.width = 6;
    const colB = ws.getColumn(2);
    colB.width = 6;
    const colC = ws.getColumn(3);
    colC.width = 22;
    const colD = ws.getColumn(4);
    colD.width = 40;
    const colE = ws.getColumn(5);
    colE.width = 10;
    const colF = ws.getColumn(6);
    colF.width = 10;
    const colG = ws.getColumn(7);
    colG.width = 10;
    const colH = ws.getColumn(8);
    colH.width = 10;
    const colI = ws.getColumn(9);
    colI.width = 30;
    
    ws.addRow([]);
    ws.addRow([]);

    // TODO : 추후 변수들을 배열로 담고 반복문으로 처리하는 방법도 고려
    let row = ws.addRow(["발주번호:"]);
    row.height = 29;
    ws.mergeCells('A4:B4');
    ws.getCell('A4').font = { size: 15 };
    // ws.getCell('C4').font = { size: 15, bold: true };
    ws.getCell('C4').font = { size: 15 };
    ws.getCell('C4').value = orderNo;
    ws.getCell('C4').font = { size: 15 };
    
    row = ws.addRow(["거 래 처:"]);
    row.height = 29;
    ws.mergeCells('A5:B5');
    ws.getCell('A5').font = { size: 15 };
    ws.getCell('C5').value = gclientName;
    ws.getCell('C5').font = { size: 15 };

    row = ws.addRow(["현 장 명:"]);
    row.height = 29;
    ws.mergeCells('A6:B6');
    ws.getCell('A6').font = { size: 15 };
    ws.getCell('C6').value = site;
    ws.getCell('C6').font = { size: 15 };

    row = ws.addRow(["담 당 자:"]);
    row.height = 29;
    ws.mergeCells('A7:B7');
    ws.getCell('A7').font = { size: 15 };
    ws.getCell('C7').value = `${inChargeNameGclient} ${inChargePhoneGclient}`;
    ws.getCell('C7').font = { size: 15 };
    
    row = ws.addRow(["착지주소:"]);
    row.height = 29;
    ws.mergeCells('A8:B8');
    ws.getCell('A8').font = { size: 15 };
    ws.getCell('C8').value = deliveryAddress;
    ws.getCell('C8').font = { size: 15 };
    
    row = ws.addRow(["발 주 일:"]);
    row.height = 29;
    ws.mergeCells('A9:B9');
    ws.getCell('A9').font = { size: 15 };
    ws.getCell('C9').value = new Date(dateFormat(orderDate, 'yyyy-MM-dd'));
    
    ws.getCell('C9').numFmt = 'yyyy년 m월 d일';
    ws.getCell('C9').font = { size: 15 };

    row = ws.addRow(["납 기 일:"]);
    row.height = 29;
    ws.mergeCells('A10:B10');
    ws.getCell('A10').font = { size: 15 };
    ws.getCell('C10').value = new Date(dateFormat(deliveryDate, 'yyyy-MM-dd'));
    ws.getCell('C10').numFmt = 'yyyy년 m월 d일';
    ws.getCell('C10').font = { size: 15 };

    // TODO: 로그인 정보가 아닌 이미 발주한 정보에서 출력. 그러나 임시 발주서 출력 등 발주가 생성되기 전에는???
    ws.getCell('G4').value = orderer.name;
    // ws.getCell('G4').font = { size: 15, bold: true };
    ws.getCell('G4').font = { size: 15 };
    // TODO: 로그인 정보가 아닌 이미 발주한 정보에서 출력. 그러나 임시 발주서 출력 등 발주가 생성되기 전에는???
    ws.getCell('G5').value = orderer.address;
    // ws.getCell('G5').font = { size: 15, bold: true };
    ws.getCell('G5').font = { size: 15 };
    ws.getCell('G6').value = ordererEmail;
    // ws.getCell('G6').font = { size: 15, bold: true };
    ws.getCell('G6').font = { size: 15 };
    ws.getCell('G7').value = `${ordererName} ${ordererPhone}`;
    // ws.getCell('G7').font = { size: 15, bold: true };
    ws.getCell('G7').font = { size: 15 };
    
    ws.addRow([]);
    ws.addRow(["아래와 같이 생산의뢰합니다."]);
    ws.mergeCells('A12:I12');
    ws.getCell('A12').font = { size: 15 };
    ws.getCell('A12').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.addRow([]);

    ws.addRow(["특 이 사 항"]);
    ws.mergeCells('A14:F14');
    ws.getCell('A14').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('A14').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };
    ws.getCell('A14').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('A14').font = { size: 13 };
    
    ws.mergeCells('G14:I14');
    ws.getCell('G14').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('G14').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };
    ws.getCell('G14').value = "제 작 사 양";
    ws.getCell('G14').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('G14').font = { size: 13 };

    ws.mergeCells('A15', 'F21');
    ws.getCell('A15').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };

    ws.mergeCells('G15', 'I21');
    ws.getCell('G15').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };

    ws.mergeCells('A22', 'A23');
    ws.getCell('A22').value = "NO";
    ws.getCell('A22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('A22').font = { size: 13 };
    ws.getCell('A22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('A22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.mergeCells('B22', 'C23');
    ws.getCell('B22').value = "품번";
    ws.getCell('B22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('B22').font = { size: 13 };
    ws.getCell('B22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('B22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.mergeCells('D22', 'D23');
    ws.getCell('D22').value = "유리사양";
    ws.getCell('D22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('D22').font = { size: 13 };
    ws.getCell('D22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('D22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.mergeCells('E22', 'F22');
    ws.getCell('E22').value = "규격(mm)";
    ws.getCell('E22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('E22').font = { size: 13 };
    ws.getCell('E22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('E22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.getCell('E23').value = "가로";
    ws.getCell('E23').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('E23').font = { size: 13 };
    ws.getCell('E23').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('E23').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.getCell('F23').value = "세로";
    ws.getCell('F23').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('F23').font = { size: 13 };
    ws.getCell('F23').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('F23').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.mergeCells('G22', 'G23');
    ws.getCell('G22').value = "수량(EA)";
    ws.getCell('G22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('G22').font = { size: 13 };
    ws.getCell('G22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('G22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    ws.mergeCells('H22', 'H23');
    ws.getCell('H22').value = "면적(M2)";
    ws.getCell('H22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('H22').font = { size: 13 };
    ws.getCell('H22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('H22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };
    
    ws.mergeCells('I22', 'I23');
    ws.getCell('I22').value = "비고";
    ws.getCell('I22').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('I22').font = { size: 13 };
    ws.getCell('I22').border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell('I22').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    // A: No, B: 품번, C: 유리사양,  D: 가로, E: 세로, F: 수량, G: 면적, H: 비고
    gorderDetails?.forEach((gorderDetail, index) => {
      // ws.getCell('H22').value = "비고";
      // ws.getCell('H22').alignment = { vertical: 'middle', horizontal: 'center' };
      const cellA = 'A' + (23+index+1);
      // ws.getCell(cellA).font = { size: 13, bold: true };
      ws.getCell(cellA).font = { size: 13 };
      ws.getCell(cellA).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      // TODO : formula 사용법도 가능한지 체크해볼 것
      if (index === 0) {
        ws.getCell(cellA).value = 1;
      } else {
        ws.getCell(cellA).value = ws.getCell('A' + (23+index)).value + 1;
      }
      
      const cellB = 'B' + (23+index+1);
      ws.mergeCells('B' + (23+index+1), 'C' + (23+index+1));
      ws.getCell(cellB).value = gorderDetail.no;
      // ws.getCell(cellB).font = { size: 13, bold: true };
      ws.getCell(cellB).font = { size: 13 };
      ws.getCell(cellB).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellD = 'D' + (23+index+1);
      // ws.getCell(cellD).font = { size: 13, bold: true };
      ws.getCell(cellD).font = { size: 13 };
      ws.getCell(cellD).value = gorderDetail.specification;
      ws.getCell(cellD).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellE = 'E' + (23+index+1);
      // ws.getCell(cellE).font = { size: 13, bold: true };
      ws.getCell(cellE).font = { size: 13 };
      ws.getCell(cellE).numFmt = '#,##0';
      ws.getCell(cellE).value = gorderDetail.width;
      ws.getCell(cellE).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellF = 'F' + (23+index+1);
      // ws.getCell(cellF).font = { size: 13, bold: true };
      ws.getCell(cellF).font = { size: 13 };
      ws.getCell(cellF).value = gorderDetail.height;
      ws.getCell(cellF).numFmt = '#,##0';
      ws.getCell(cellF).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellG = 'G' + (23+index+1);
      // ws.getCell(cellG).font = { size: 13, bold: true };
      ws.getCell(cellG).font = { size: 13 };
      ws.getCell(cellG).numFmt = '#,##0';
      ws.getCell(cellG).value = gorderDetail.amount;
      ws.getCell(cellG).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellH = 'H' + (23+index+1);
      // ws.getCell(cellH).font = { size: 13, bold: true };
      ws.getCell(cellH).font = { size: 13 };
      ws.getCell(cellH).numFmt = '#,##0.00';
      ws.getCell(cellH).value = Number(gorderDetail.areaMeter);
      ws.getCell(cellH).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };

      const cellI = 'I' + (23+index+1);
      // ws.getCell(cellI).font = { size: 13, bold: true };
      ws.getCell(cellI).font = { size: 13 };
      ws.getCell(cellI).value = gorderDetail.notes;
      ws.getCell(cellI).border = {
        top: {style:'thin', color: {argb:'FF000000'}},
        left: {style:'thin', color: {argb:'FF000000'}},
        bottom: {style:'thin', color: {argb:'FF000000'}},
        right: {style:'thin', color: {argb:'FF000000'}}
      };
    })

    const cellTotalA = 'A' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalA).value = "계";
    ws.getCell(cellTotalA).font = { size: 13, bold: true };
    ws.getCell(cellTotalA).alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell(cellTotalA).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalA).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const cellTotalB = 'B' + (23 + (gorderDetails.length+1));
    ws.mergeCells('B' + (23 + (gorderDetails.length+1)), 'C' + (23 + (gorderDetails.length+1)))
    ws.getCell(cellTotalB).font = { size: 13, bold: true };
    ws.getCell(cellTotalB).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalB).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const cellTotalD = 'D' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalD).font = { size: 13, bold: true };
    ws.getCell(cellTotalD).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalD).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const cellTotalE = 'E' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalE).font = { size: 13, bold: true };
    ws.getCell(cellTotalE).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalE).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const cellTotalF = 'F' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalF).font = { size: 13, bold: true };
    ws.getCell(cellTotalF).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalF).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const cellTotalG = 'G' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalG).font = { size: 13, bold: true };
    ws.getCell(cellTotalG).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalG).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };
    ws.getCell(cellTotalG).value = { formula: `SUM(G${23+1}:G${23+gorderDetails.length})`};

    const cellTotalH = 'H' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalH).font = { size: 13, bold: true };
    ws.getCell(cellTotalH).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalH).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };
    ws.getCell(cellTotalH).numFmt = '#,##0.00';
    ws.getCell(cellTotalH).value = { formula: `SUM(H${23+1}:H${23+gorderDetails.length})`};

    const cellTotalI = 'I' + (23 + (gorderDetails.length+1));
    ws.getCell(cellTotalI).font = { size: 13, bold: true };
    ws.getCell(cellTotalI).border = {
      top: {style:'thin', color: {argb:'FF000000'}},
      left: {style:'thin', color: {argb:'FF000000'}},
      bottom: {style:'thin', color: {argb:'FF000000'}},
      right: {style:'thin', color: {argb:'FF000000'}}
    };
    ws.getCell(cellTotalI).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFD9D9D9'}, // bgColor가 아니네???
      // bgColor:{argb:'FFD9D9D9'}
    };

    const lastRow = 23 + (gorderDetails.length+1+1);
    const cellFooter = 'A' + lastRow;
    ws.mergeCells(`A${lastRow}:J${lastRow}`)
    ws.getCell(cellFooter).value = "WARPCORE x Attitude l Knowledge l Skill";
    ws.getCell(cellFooter).font = { size: 13, bold: true };
    ws.getCell(cellFooter).alignment = { vertical: 'middle', horizontal: 'center' };

    

    // worksheet.mergeCells('K10', 'M12'); // === worksheet.mergeCells(10,11,12,13);

    // const cell = ws.getCell('C3');
    // // Modify/Add individual cell
    // cell.value = new Date(1968, 5, 1);

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${site}-${orderNo}.xlsx`);
  }
}

export default ExcelUtil;