import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Avatar,
  IconButton,
  Typography,
  typographyClasses,
} from '@mui/material';
import  {
  Delete,
} from '@mui/icons-material';
import allLocales from '@fullcalendar/core/locales-all';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import listPlugin from '@fullcalendar/list';
import "./GPlan.css";
import {
  ConfirmDialog,
} from "../dialog";
import {
  addDays,
  dateFormat,
  getTextColorByBackgroundColor,
  objectSortByKey,
} from "../../utils";

import * as gworkOrderActions from "../../store/gworkOrder";
import * as gplanActions from "../../store/gplan";
import * as gprocessActions from "../../store/gprocess";
import * as gplanPlanningActions from "../../store/gplanPlanning";
import * as glineActions from "../../store/gline";

import GPlanDialog from "./GPlanDialog";
import gplanDayViewPlugin from "./GPlanDayView";

const theme = createTheme();

const defaultHeaderToolbar = {
  left: 'prevYear,prev,next,nextYear today',
  center: 'title',
}

const GPlanCalendar = forwardRef(({
  defaultHeaderRight,
  resourceHeaderRights,
  selectedGProcessCode,
  alarms,
  externalGPlans,
  refresh,
  setExternalDateTypeAndRefresh,
}, ref) => {

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    getAPI,
  }))

  const getAPI = () => {
    return CalendarAPI();
  }
  
  const calendarRef = useRef();

  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  // const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [targetObjectRevert, setTargetObjectRevert] = useState();
  const [confirmOpenRevert, setConfirmOpenRevert] = useState(false);
  const [revertMessage, setRevertMessage] = useState("");
  const [glinesByGProcess, setGlinesByGProcess] = useState([]);
  const [dayCellColor, setDayCellColor] = useState({});
  const [dayCellColorWithoutGLine, setDayCellColorWithoutGLine] = useState({});

  const gplans = useSelector((state) => state.gplan.gplans);
  const gplansForStatus = useSelector((state) => state.gplan.gplansForStatus);
  const gprocesses = useSelector((state) => state.gprocess.gprocesses);
  const glines = useSelector((state) => state.gline.glines);
  const glinesForStatus = useSelector((state) => state.gline.glinesForStatus);

  // 데이터 관리
  const dispatch = useDispatch();

  const setStatusByGLine = (status) => dispatch(glineActions.setStatusByGLine(status))
  const setStatusByGLineAll = (statusAll) => dispatch(glineActions.setStatusByGLineAll(statusAll))
  const selectGWorkOrdersByStatusAndGProcess = ({ status, gprocess }) => dispatch(gplanPlanningActions.selectGWorkOrdersByStatusAndGProcess({ status, gprocess }))
  const assignGWorkOrderToPlanning = (gworkOrderId) => dispatch(gplanPlanningActions.assignGWorkOrderToPlanning(gworkOrderId))
  const cancelGWorkOrderToPlanning = (event) => dispatch(gplanPlanningActions.cancelGWorkOrderToPlanning(event))
  const setGPlansPlanning = (gplans) => dispatch(gplanPlanningActions.setGPlansPlanning(gplans))
  const setGPlans = (gplans) => dispatch(gplanActions.setGPlans(gplans))
  const establishSechdule = ({ gplansPlanning, gworkOrdersPlanning }) => gplanPlanningActions.establishSechdule({ gplansPlanning, gworkOrdersPlanning })
  const createGPlan = (gplan) => dispatch(gplanActions.create(gplan))
  const modifyGPlan = (gplan) => dispatch(gplanActions.modify(gplan))
  const removeGPlan = (id) => dispatch(gplanActions.remove(id))
  const selectAll = () => dispatch(gplanActions.selectAll())
  // const selectAllWithinPeriod = (start, end) => dispatch(gplanActions.selectAllWithinPeriod(start, end))
  // const selectAllDirect = () => gplanActions.selectAllDirect()
  const selectByGProcess = (gprocess, start, end, calendar, status) => dispatch(gplanActions.selectByGProcess(gprocess, start, end, calendar, status))
  // const selectByGProcessDirect = (gprocess) => gplanActions.selectByGProcessDirect(gprocess)
  const selectGProcesses = () => dispatch(gprocessActions.selectAll())
  const applyGPlan = () => dispatch(gplanActions.applyGPlan())
  // const selectAllGLinesDirect = () => glineActions.selectAllDirectByQuery()
  const selectGLinesByGProcessDirect = (gprocess) => glineActions.selectByGProcessDirectByQuery(gprocess)
  const selectAllGLines = (calendar, status) => dispatch(glineActions.selectAllByQuery(calendar, status))
  const selectGLinesByGProcess = (gprocess) => dispatch(glineActions.selectByGProcessByQuery(gprocess))

  const assignToGPlan = (id) => dispatch(gworkOrderActions.assignToGPlan(id))
  const cancelFromGPlan = (id) => dispatch(gworkOrderActions.cancelFromGPlan(id))

  useEffect(
    async () => {
      console.log("useEffect[dispatch]");
      const start = CalendarAPI()?.view.activeStart;
      const end = CalendarAPI()?.view.activeEnd;
      console.log(start)
      console.log(end)
      
      await selectAllGLines(true, true); // 생산라인 조회
      // await selectAllWithinPeriod(start, end); // 생산일정 조회

      // TODO : 아래의 경우 GPlanManagement에서 함. 공통으로 쓰는 데이터의 경우 여러군데서 호출하고 있을 수 있으므로 중복호출이 예상되며 점검할 것
      // 단, React Component 포함여부에 따라 (여기서는 GPlanManangement가 GPlanCalendar를 포함) 중복을 하지 않도록 해야 될 수도 있으나, 사용자가 특정 동작시(실시간 최신 데이터가 필요)나 
      // Component가 독자적으로 쓰일 수 있는 등의 상황에 따라 호출할 수도 있음
      await selectGProcesses(); // 전체 공정 조회

      // TODO : 주석처리. GPlanManagement의 useEffect[gprocess] 에서 동일하게 호출하므로... 문제없는지 확인필요
      // TODO : 추가로 start, end를 api 전달시 Date 객체를 문자열로 바꿀 필요성 있어 보임
      // await selectByGProcess("ALL", start, end, true, true);

      // // event id는 unique id, event.extendedProps.id는 gworkOrder의 id => event.extendedProps.id는 캘린더에서 groupId로 사용(하나의 작업의뢰서가 근무시간, 또는 여러가지 이유로 인해 여러개의 일정으로 분리될 수 있음)
      // // 외부 Drag & Drop 스케줄(작업의뢰) 설정
      // let draggableEl = document.getElementById("external-events");
      // new Draggable(draggableEl, {
      //   itemSelector: ".fc-event",
      //   // 아래처럼 dom으로부터 데이터를 가져와 event structure를 사용함. 외부 데이터를 drag 하는 순간 아래 function이 호출됨
      //   eventData: (eventEl) => {
      //     const data = eventEl.getAttribute("data");
      //     const gworkOrder = JSON.parse(data);
      //     return {
      //       // 아래와 같이 ...gworkOrder하면 gworkOrder의 프로퍼티들 중 fullcalendar event와 프로퍼티가 같은 것은 event 구조로... 그 외의는 모두 extendedProps로 넘어감.
      //       // 그러나 이렇게 하면 id가 event 구조로 들어가고 extendedProps에서는 빠져버리는 문제 발생하여 ...gworkOrder 방식 사용안함
      //       // ...gworkOrder,
      //       // id: gworkOrder.id,
      //       id: uuidv4(), // 캘린더의 event id이자 GPlans의 id(pk)
      //       // 초기는 GWorkOrders의 gorderId이고 해제/그룹핑이 가능해야 한다. 해제: groupId: gworkOrder.id, 그룹핑: groupId: gworkOrder.gorderId
      //       // groupId: gworkOrder.id,
      //       groupId: gworkOrder.gorderId,
      //       title: `${gworkOrder.workOrderNo}-${gworkOrder.workOrderNoSuffix}`,
      //       extendedProps: {...gworkOrder},
      //       color: gworkOrder.gprocessColor,
      //       textColor: `${getTextColorByBackgroundColor(gworkOrder.gprocessColor)}`,
      //     };
      //   }
      // });
    }, [dispatch]
  );

  useEffect(
    () => {
      console.log("useEffect[gplans]");
      setDayCellColor({});
      setDayCellColorWithoutGLine({});
      setStatusByGLine({});
      
      refreshEvaluateCapacity();

      // CalendarAPI().getOption('locale')
      console.log(CalendarAPI());

      // const calendar = CalendarAPI();
      // const { type } = calendar.view;
      // console.log(type)
      // if (type === "dayGridMonth") {
      //   setExternalDateTypeAndRefresh(0, calendar);
      // } else if (type === "dayGridWeek") {
      //   setExternalDateTypeAndRefresh(1, calendar);
      // } else if (type === "listDay") {
      //   setExternalDateTypeAndRefresh(2, calendar);
      // }

    }, [gplans]
  )

  // useEffect(
  //   () => {
  //     const events = CalendarAPI().getEvents();
  //     // TODO : 전체 events를 가지고 statusAll 가지고 있어야 한다.
  //     // 상태 정보
  //     const eventsByGLineAll = {};
  //     glinesForStatus.forEach(gline => events.forEach(event => {
  //         const { gworkOrderDetails } = event.extendedProps;
  //         // const amount = gworkOrderDetails?.map(item => item.amount).reduce((prev, curr) => prev + curr, 0); // 구하려는 합산값이 하나였다면 이처럼 구하는 것도 방법
  //         let amount = 0;
  //         let areaJa = 0;
  //         gworkOrderDetails.forEach(item => {
  //           amount += item.amount; // amount 숫자
  //           areaJa += Number(item.areaJa); // amount 문자
  //         });
          
  //         const { resourceIds } = event.extendedProps;
  //         if (resourceIds?.length > 0 && gline.id.toString() === resourceIds[0]) {
  //           eventsByGLineAll[event.startStr] = eventsByGLineAll[event.startStr] ? eventsByGLineAll[event.startStr] : {};
  //           eventsByGLineAll[event.startStr][gline.id] = eventsByGLineAll[event.startStr][gline.id] ? eventsByGLineAll[event.startStr][gline.id] : [];
  //           eventsByGLineAll[event.startStr][gline.id].push({
  //             amount,
  //             areaJa,
  //             title: event.title,
  //             details: event.extendedProps,
  //           });
  //         }
  //       })
  //     );
      
  //     const newEventsByGLineAll = objectSortByKey(eventsByGLineAll); // 키(날짜)로 정렬
  //     console.log(newEventsByGLineAll);
  //     setStatusByGLineAll(newEventsByGLineAll);

  //   }, [gplansForStatus]
  // )

  const CalendarAPI = () => {
    return calendarRef.current ? calendarRef.current.getApi() : null;
  }

  // 달력에서 날짜칸을 선택했을 때
  const handleDateSelect = (selectInfo) => {
    // let calendarApi = selectInfo.view.calendar
    // let title = prompt('Please enter a new title for your event')

    // calendarApi.unselect() // clear date selection
    
    // if (title) {
    //   // 캘린더에 추가하는 API
    //   calendarApi.addEvent({ // will render immediately. will call handleEventAdd
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   }, true) // temporary=true, will get overwritten when reducer gives new events
    // }
  }

  // TODO : renderEventContent 사용하면 글자가 길 경우 넘어감
  // 이벤트 자체에 삭제버튼 넣는 방식에서 클릭 후 상세 정보 보기 및 삭제하는 방식으로 변경
  const renderEventContent = (eventInfo) => {
    // console.log(eventInfo)
    return (
      <>
        <IconButton
          edge="end"
          aria-label="openInNew"
          // size="small"
          sx={{
            width: 6,
            height: 6,
            mr: 1,
          }}
          // onClick={(e) => handleGlassDetails(e, value, side)}
          onClick={(e) => {
            cancelGWorkOrderToPlanning(eventInfo.event);
            eventInfo.event.remove();
          }}
        >
          <Delete />
        </IconButton>
        {/* <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i> */}
        <Typography variant="body">{eventInfo.event.title}</Typography>
      </>
    )
  }

  // event 구조
  //   allDay: true
  //   allow: null
  //   backgroundColor: ""
  //   borderColor: ""
  //   classNames: Array(0)
  //   constraint: null
  //   display: "auto"
  //   durationEditable: undefined
  //   end: null
  //   endStr: ""
  //   extendedProps: Object
  //   groupId: ""
  //   id: "1"
  //   overlap: null
  //   source: EventSourceApi
  //   start: Mon Aug 08 2022 00:00:00 GMT+0900 (한국 표준시)
  //   startEditable: undefined
  //   startStr: "2022-08-08"
  //   textColor: ""
  //   title: "All-day event"
  //   url: ""

  const handleEventAdd = (e) => {
    console.log("handleEventAdd")
    console.log(e)
  }

  const drop = async (info) => {
    // console.log("drop")
    // console.log(info)

    const valid = await validGlines(info);
    if (!valid) {
      setTargetObjectRevert(info);
      setRevertMessage("올바른 공정라인이 아니므로 이동하거나 변경할 수 없습니다.");
      setConfirmOpenRevert(true);
    } else {
      const { event } = info;

      // await modifyGPlan(event);

      const events = CalendarAPI().getEvents(); // 캘린더 로딩 상태에 따라 events가 일정하지 않음
  
      const groupedEvent = events.filter(e => e.groupId === event.groupId && e.id !== event.id);
      const promises = groupedEvent.map(async (e) => {
        return await modifyGPlan(e);
      });

      await Promise.all(promises);

      await modifyGPlan(event);

      // TODO : 공정라인별 dnd에서 refresh는 capacity 적용 O, refreshEvaluateCapacity는 적용 X => 추후 확인해 볼 것
      refresh();
      // refreshEvaluateCapacity();
    }
  }

  const handleEventRemove = (e) => {
    console.log("handleEventRemove")
    console.log(e)
  }

  const handleEventAfterAllRender = (e) => {
    console.log("handleEventAfterAllRender")
    console.log(e)
  }

  // // 초기로딩시 useEffect [dispatch]에서 호출. 범위: viewType에 따라 기간이 달라짐
  // // 날짜 변경시 handleDates에서 호출. 범위: 해당 날짜 startStr ~ endStr
  // // 작업의뢰 drop시 handleEventReceive에서 호출. 범위: 당일
  // // 캘린더내 이동 및 수정시 handleEventChange에서 호출(drop -> eventChange순) oldEvent 날짜/newEvent 날짜
  // // 공정선택시 호출
  // const evaluateCapacity_backup = (events, start, end, oldEvent) => {
  //   // TODO : 조건에 맞는 events를 추리자. events는 하나이거나 배열임
  //   console.log("evaluateCapacity");
  //   const startStr = start.substring(0, 10);
  //   let endStr = "";
  //   if (end === "") {
  //     endStr = startStr;
  //   } else {
  //     const endDate = new Date(end.substring(0, 10));
  //     endStr = addDays(endDate, -1, 'yyyy-MM-dd');
  //   }

  //   // 옮기기 전 이벤트로 만일 해당 날짜에서 속성 수정시 날짜 변경이 없다면 아래는 생략
  //   let oldEventStartStr = "";
  //   let oldEventEndStr = "";
  //   if (oldEvent) {
  //     console.log(oldEvent)
  //     oldEventStartStr = oldEvent.startStr;
  //     oldEventEndStr = oldEvent.endStr;
  //     if (oldEventEndStr === "") {
  //       oldEventEndStr = oldEventStartStr;
  //     } else {
  //       const endDate = new Date(oldEventEndStr);
  //       oldEventEndStr = addDays(endDate, -1, 'yyyy-MM-dd');
  //     }
  //   }

  //   // console.log("event")
  //   // console.log(startStr);
  //   // console.log(endStr);

  //   // console.log("oldEvent")
  //   // console.log(oldEventStartStr);
  //   // console.log(oldEventEndStr);

  //   // console.log(events);

  //   // startStr ~ endStr 사이의 event 구하기
  //   let selectedEvents;
  //   if (Array.isArray(events)) {
  //     selectedEvents = events.filter(event => {
  //       let eventStart = event.startStr;
  //       let eventEnd = event.endStr;
  //       if (eventEnd === "") {
  //         eventEnd = eventStart;
  //       } else {
  //         const endDate = new Date(eventEnd.substring(0, 10));
  //         eventEnd = addDays(endDate, -1, 'yyyy-MM-dd');
  //       }
  
  //       if ((startStr <= eventStart && eventStart <= endStr) &&
  //           (startStr <= eventEnd && eventEnd <= endStr)
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //   } else {
  //     selectedEvents = [events];
  //   }

  //   console.log(selectedEvents);
  //   // console.log(glines)
  //   // 

  //   // TODO : 현재 detail 정보가 없음. 아래 코드 재작성할 것
  //   const eventsByGLine = {};
  //   glines.forEach(gline => {
  //     selectedEvents.forEach(event => {
  //       // amount => 숫자, amountJa => 문자임
  //       const { amount, areaJa } = event.extendedProps;
  //       // const resources = event.getResources();
  //       // console.log(resources);
  //       // setResources 적용시 그룹내 이벤트의 모두가 적용됨. 따라서 DB에 저장된 값으로 처리함
  //       // const resourceIds = resources.map(resource => resource.id); // 현재는 하나의 라인에만 할당되는 것을 전제로 함
  //       const resourceIds = event.extendedProps.resourceIds;
  //       // console.log(gline);
  //       // console.log(resourceIds);
        
  //       if (resourceIds?.length > 0 && gline.id.toString() === resourceIds[0]) {
  //         eventsByGLine[event.startStr] = eventsByGLine[event.startStr] ? eventsByGLine[event.startStr] : {};
  //         eventsByGLine[event.startStr][gline.id] = eventsByGLine[event.startStr][gline.id] ? eventsByGLine[event.startStr][gline.id] : [];
  //         eventsByGLine[event.startStr][gline.id].push({
  //           amount,
  //           areaJa: Number(areaJa),
  //           details: event.extendedProps,
  //         });
  //       }
  //       // else {
  //       //   eventsByGProcess[processCode] = eventsByGProcess[processCode] ? eventsByGProcess[processCode] : [];
  //       //   eventsByGProcess[processCode].push(amount);
  //       // }
        
  //     })
  //   });

  //   // console.log(eventsByGLine);
    
  //   // 키(날짜)로 정렬된 객체
  //   const newEventsByGLine = objectSortByKey(eventsByGLine);
  //   console.log(newEventsByGLine);
    
  //   // setStatusByGLine(eventsByGLine);
  //   setStatusByGLine(newEventsByGLine);

  //   // 기간배열
  //   // console.log(endStr)
  //   // console.log(new Date(endStr))
  //   // TODO : 추후 format 문제없는지 살펴볼 것
  //   const period = Math.abs(new Date(endStr) - new Date(startStr));
  //   const diffDays = Math.ceil(period / (1000 * 60 * 60 * 24));
  //   const periodArr = [];
  //   for(let i=0; i<=diffDays; i++) {
  //     periodArr.push(addDays(new Date(startStr), i, 'yyyy-MM-dd'));
  //   }

  //   // console.log(periodArr);

  //   const initialValue = 0;
  //   const newDayCellColor = {};
  //   const newDayCellColorWithoutGLine = {};
  //   periodArr.forEach(date => {
  //     if (eventsByGLine[date]) {
  //       // console.log(eventsByGLine[date])
  //       let maxSum = 0;
  //       for (const [key, value] of Object.entries(eventsByGLine[date])) { // key === glineId
  //         const sumAmountArr = eventsByGLine[date][key].map(item => item.amount);
  //         const sumAreaJaArr = eventsByGLine[date][key].map(item => item.areaJa);
  //         const sumAmount = sumAmountArr.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
  //         const sumAreaJa = sumAreaJaArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);

  //         const targetGLine = glines.filter(gline => gline.id.toString() === key)
  //         const { capacity, capacityType } = targetGLine[0];
  //         const compare = capacity[capacityType];
  //         let capacityPercent = 0;
  //         if (capacityType === "amount") {
  //           capacityPercent = sumAmount/compare*100;
  //         } else { // areaJa
  //           capacityPercent = sumAreaJa/compare*100
  //         }

  //         // #EC7A67 => 경고 (100% 이상)
  //         // #F7D57C => 주의 (80% 이상)
  //         // #87C58C => 여유 (80% 미만)
  //         // console.log(capacityPercent)
  //         // console.log(maxSum)

  //         if (maxSum < capacityPercent) {
  //           maxSum = capacityPercent;

  //           let color = alarms[0].color;
  //           if (maxSum >= 100) {
  //             color = alarms[2].color;
  //           } else if(maxSum >= 80) {
  //             color = alarms[1].color;
  //           }

  //           // TODO : 마지막 날짜만 갱신됨
  //           // loop 중 계속 갱신하면 마지막 결과값만 기록됨. 해당 날짜에서 공정라인별로 가장 높은 비율(max)값으로 체크
  //           if (key) {
  //             newDayCellColor[date] = {};
  //             newDayCellColor[date][key] = color;
  //           } 

  //           newDayCellColorWithoutGLine[date] = color;
  //         }
  //       }
  //     }
  //   });

  //   // console.log(newDayCellColor);

  //   setDayCellColor(/*{
  //     ...dayCellColor,
  //     [date]: color,
  //   }*/newDayCellColor);

  //   setDayCellColorWithoutGLine(newDayCellColorWithoutGLine);
    

  //   // const allEvents = info.view.getCurrentData().calendarApi.getEvents();
  //   // if (allEvents && Array.isArray(allEvents) && events.length > 0) {
  //   //   // console.log(info);
  //   //   // console.log(allEvents);
  //   //   const events = allEvents.filter(event => event.start.toString() === info.date.toString());
  //   //   if (events.length > 0) {
  //   //     // TODO : 이벤트를 공정라인별로 (라인이 없는 경우 공정별)로 나누어야 묶어야 함
  //   //     console.log(events);
  //   //     console.log(selectedGProcessCode)
  //   //     // TODO : 임시
  //   //     const resources = events[0].getResources();
  //   //     const resourceIds = resources.map(resource => resource.id);
  //   //     console.log(resourceIds)
  //   //     const eventsByGprocess = [];
  //   //     const eventsNoGprocess = [];
  //   //     const initialValue = 0;
  //   //     const sumAmountArr = events.map(event => event.extendedProps.amount);
  //   //     const sumAreaJaArr = events.map(event => event.extendedProps.areaJa);
  //   //     const sumAmount = sumAmountArr.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
  //   //     const areaJa = sumAreaJaArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
  //   //     // TODO : 공정라인별로 하나라도 capacity 100% 이상이면 경고. 주의/여유 단계도 공정라인별로 체크하여 표시
  //   //     // 이렇게 하면 공정라인을 전체표시해도, 공정별로 표시해도 capacity가 표시될 것으로 예상됨
  //   //   }
  //   // } else {


  //   // console.log(start);
  //   // console.log(end);
  //   // console.log(oldEvent);
  //   // setDayCellColor({
  //   //   ...dayCellColor,
  //   //   [event.start.toString()]: 'black',
  //   // });
  // }

  // gplans 변경시(재검색 - refresh) 호출됨
  // gplans 변경 : 최초검색, 공정선택, 일정변경(drop, 속성수정)
  // events는 이미 필터링되어 있음
  const evaluateCapacity = (events) => { // TODO : events 사용여부 검토할 것
    console.log("evaluateCapacity");
    if (events.length <= 0) {
      return;
    }

    // TODO : 상태 정보는 GPlanStatus2의 조회 버튼과 관련하여 수정 필요. GPlanManagement로 옮기는 것도 고려
    // 상태 정보
    const eventsByGLine = {};
    // 아래 상태 정보 로직은 다른 곳에서도 쓰이므로 events에서 gplans로 변경 검토할 것
    // glinesForStatus.forEach(gline => events.forEach(event => {
    glinesForStatus.forEach(gline => gplans.forEach(event => {
        const { gworkOrderDetails } = event.extendedProps;
        // const amount = gworkOrderDetails?.map(item => item.amount).reduce((prev, curr) => prev + curr, 0); // 구하려는 합산값이 하나였다면 이처럼 구하는 것도 방법
        let amount = 0;
        let areaJa = 0;
        gworkOrderDetails.forEach(item => {
          amount += item.amount; // amount 숫자
          areaJa += Number(item.areaJa); // amount 문자
        });
        
        const { resourceIds } = event.extendedProps;
        if (resourceIds?.length > 0 && gline.id.toString() === resourceIds[0]) {
          // startStr => start
          eventsByGLine[event.start] = eventsByGLine[event.start] ? eventsByGLine[event.start] : {};
          eventsByGLine[event.start][gline.id] = eventsByGLine[event.start][gline.id] ? eventsByGLine[event.start][gline.id] : [];
          eventsByGLine[event.start][gline.id].push({
            amount,
            areaJa,
            title: event.title,
            details: event.extendedProps,
          });
        }
      })
    );
    
    const newEventsByGLine = objectSortByKey(eventsByGLine); // 키(날짜)로 정렬
    // console.log(newEventsByGLine);
    setStatusByGLine(newEventsByGLine);

    // capacity에 따른 색상설정
    const newDayCellColor = {};
    const newDayCellColorWithoutGLine = {};
    Object.keys(newEventsByGLine).forEach(date => {
      let maxSum = 0;
      for (const [glineId] of Object.entries(newEventsByGLine[date])) {
        let capacityPercent = 0;
        let sumAmount = 0;
        let sumAreaJa = 0;

        newEventsByGLine[date][glineId].forEach(item => {
          sumAmount += item.amount;
          sumAreaJa += item.areaJa;
        });
        
        // TODO : 추후 해당하는 날짜의 gline Capa만 가져올 것을 검토 (기간별 생산용량이 계속 쌓이게 되므로)
        const { glineCapacities } = glines.find(gline => gline.id.toString() === glineId);
        if (glineCapacities && Array.isArray(glineCapacities) && glineCapacities.length > 0) {
          const { capacityType, capacity } = glineCapacities.find(glineCapacity => {
            const { validPeriodStart, validPeriodEnd } = glineCapacity;
            const start = dateFormat(new Date(validPeriodStart), 'yyyy-MM-dd');
            const end = dateFormat(new Date(validPeriodEnd), 'yyyy-MM-dd');
            if (start <= date && date <= end) {
              return true;
            }

            return false;
          });

          console.log(date);
          console.log(glines)
          const compare = capacity[capacityType];
          
          if (capacityType === "amount") {
            capacityPercent = sumAmount/compare*100;
          } else { // areaJa
            capacityPercent = sumAreaJa/compare*100
          }

          if (maxSum < capacityPercent) {
            maxSum = capacityPercent;

            let color = alarms[0].color;
            if (maxSum >= 100) {
              color = alarms[2].color;
            } else if(maxSum >= 80) {
              color = alarms[1].color;
            }
            
            // loop 중 계속 갱신하면 마지막 결과값만 기록됨. 해당 날짜에서 공정라인별로 가장 높은 비율(max)값으로 체크
            if (glineId) {
              newDayCellColor[date] = {};
              newDayCellColor[date][glineId] = color;
            } 

            newDayCellColorWithoutGLine[date] = color;
          }
        }
      }
    });

    setDayCellColor(newDayCellColor);
    setDayCellColorWithoutGLine(newDayCellColorWithoutGLine);
  }

  const refreshEvaluateCapacity = () => {
    console.log("refreshEvaluateCapacity");
    const events = CalendarAPI().getEvents();
    
    if (events.length > 0) {
      evaluateCapacity(events);
    }
  }

  // 참고로 useEffect([dispatch]) 보다 먼저 들어오는데 초기 로딩시에는 events가 없음
  const handleDates = async (info) => {
    console.log("handleDates1")
    console.log(info)

    // useEffect[dispatch] 보다 먼저 들어올 때 여기서 조회하고 또한번 조회하게 되므로
    // 아래와 같은 조건을 주어 초기로딩은 useEffect[dispatch]에서 하도록 하고, 그 다음 날짜 변경은 여기서 수행
    if (!CalendarAPI()) {
      return;
    }

    const { type } = info.view;
    console.log(type)
    if (type === "dayGridMonth") {
      setExternalDateTypeAndRefresh(0, info.view.calendar);
    } else if (type === "dayGridWeek") {
      setExternalDateTypeAndRefresh(1, info.view.calendar);
    } else if (type === "listDay") {
      setExternalDateTypeAndRefresh(2, info.view.calendar);
    }

    console.log("handleDates2")

    // activeStart, activeEnd는 캘린더에 보이는 전체, currentStart, currentEnd는 현재 달.
    // end 부분은 항상 다음날 00:00:00.000Z 임. start보다 크거나 같고 end보다 작게 검색 필요. 현재 db에서는 between 검색하므로 end보다 작거나 같음으로 검색 => TODO : 문제여부 검토
    const { activeStart, activeEnd } = info.view; // const { start, end } = info 와 동일. start, end 는 Date 객체, startStr, endStr은 iso string
    
    // console.log(activeStart, activeEnd);
    // console.log(selectedGProcessCode)
    
    // TODO : 위의 setExternalDateTypeAndRefresh 에서도 생산현황을 보기 위해 동일한 쿼리를 보내므로 중복됨. 해결방안 모색 필요
    // 코드가 자연스럽진 않지만 현재로서는 위의 setExternalDateTypeAndRefresh에서 리턴받아 직접 dispatch 하는 수 밖에는 없어 보임
    await selectByGProcess(selectedGProcessCode, activeStart, activeEnd, true, true);
  }

  // 캘린더에서 이벤트 내용을 변경했을 때 아래 callback에서 DB 정보 수정 처리
  // eventChange나 drop, GPlanDialg의 submit 등에서 처리했으나 여러가지 문제 발생
  // TODO : eventDidMount에서 가장 처리하기 쉬움. 단, 변경되지 않은 이벤트들도 수정되므로 개선 필요
  const handleEventDidMount = async (info) => {
    // console.log("handleEventDidMount")
    // console.log(info)
    // console.log(CalendarAPI().getEvents())
    // const { event } = info;
    // // console.log(event)
    // // await modifyGPlan(event);

    // // refreshEvaluateCapacity(info);
  }

  const handleEventClick = async (info) => {
    const { event } = info;
    console.log(event)
    // console.log(event.getResources()[0].id)
    // var resources = event.getResources();
    // console.log(resources);
    // var resourceIds = resources.map(function(resource) { return resource.id });
    // console.log(resourceIds);

    // cancelGWorkOrderToPlanning(event);
    // event.remove();

    // 상세정보창 띄우기
    const gprocess = event.extendedProps.gprocessCode;
    // /*const newGLinesByGProcess = */await selectGLinesByGProcess(gprocess);
    const glinesByProcess = await selectGLinesByGProcessDirect(gprocess); // 유효한 공정라인 조회
    setGlinesByGProcess(glinesByProcess);
    // setGlinesByGProcess(newGLinesByGProcess.map(gline => {
    //   return {
    //     id: gline.id,
    //     code: gline.id,
    //     name: gline.name,
    //   }
    // }));
    // setGlinesByGProcess(newGLinesByGProcess.map(gline => {
    //   return {
    //     value: gline.id,
    //     label: gline.name,
    //   }
    // }));
    setSelectedEvent(event);

    setOpen(true);
  }

  /**
   * 2022-08-25
   * 1. 캘린더에서 변경 후 확정하기를 통해 DB에 일괄 적용하는 방법 => 캘린더에서 메모리에 보유하고 있는 events를 다루기에 복잡도 상승
   * 2. 일정을 변경할 때마다 DB에 즉각 적용하는 방법 => 되돌리기(일부 또는 전체)가 불가능한 문제
   * 택2 : 2022-08-25
   */
  // const establish = async () => {
  //   // alert("111")
  //   return;
  //   setOpenBackdrop(true);
  //   // TODO : gworkOrdersPlanning와 gplans 정보로 일정확정(DB에 반영)하기
  //   // console.log("gworkOrdersPlanning");
  //   // console.log(gworkOrdersPlanning);
  //   // console.log("gplansPlanning");
  //   // console.log(gplansPlanning);
  //   const events = CalendarAPI()?.getEvents();
    
  //   // gplans에 대하여 JSON.stringify로 request body를 서버로 전달할 때 source에서 recursive 오류 발생으로 인하여 삭제
  //   // 또한 source안의 데이터를 굳이 저장할 필요가 없으므로 삭제
  //   const gplans = events && Array.isArray(events) && events.map(event => {
  //     const {
  //       allDay,
  //       allow,
  //       backgroundColor,
  //       borderColor,
  //       classNames,
  //       constraint,
  //       display,
  //       durationEditable,
  //       end,
  //       endStr,
  //       extendedProps,
  //       groupId,
  //       id,
  //       overlap,
  //       // source,
  //       start,
  //       startEditable,
  //       startStr,
  //       textColor,
  //       title,
  //       url,
  //     } = event;
  
  //     const resources = event.getResources();
  //     const resourceIds = resources.map(resource => resource.id);

  //     const newExtendedProps = Object.assign({}, extendedProps);
  //     newExtendedProps.resourceIds = resourceIds;

  //     return {
  //       allDay,
  //       allow,
  //       backgroundColor,
  //       borderColor,
  //       classNames,
  //       constraint,
  //       display,
  //       durationEditable,
  //       end,
  //       endStr,
  //       extendedProps: newExtendedProps,
  //       groupId,
  //       id,
  //       overlap,
  //       // source,
  //       start,
  //       startEditable,
  //       startStr,
  //       textColor,
  //       title,
  //       url,
  //       resourceIds,
  //     };
  //   });

  //   console.log(events);
  //   console.log({ gplansPlanning: gplans, gworkOrdersPlanning });
  //   // return;
    
  //   await establishSechdule({ gplansPlanning: gplans, gworkOrdersPlanning });
  //   // console.log(eventApis)
  //   // const obj = eventApis[0];
  //   // console.log(obj)
  //   // console.log(obj.event)
  //   // let result = '';
  //   // for (let i in obj) {
  //   //   // obj.hasOwnProperty()를 사용해서 객체의 프로토타입 체인에 존재하지 않는 속성을 제외
  //   //   // if (obj.hasOwnProperty(i)) {
  //   //     result += `test.${i} = ${obj[i]}\n`;
  //   //   // }
  //   // }
  //   // console.log(result);
  //   setTimeout(() => setOpenBackdrop(false), 300);
  // }

  // eventDidMount: function(info) {
  //   var tooltip = new Tooltip(info.el, {
  //     title: info.event.extendedProps.description,
  //     placement: 'top',
  //     trigger: 'hover',
  //     container: 'body'
  //   });
  // },

  /**
   * 캘린더에 drop할 때 공정라인이 맞지 않으면 revert 시킴. eventChange에서도 동일 동작이 필요.
   * 캘린더 정보(event)의 resourceIds랑 비교하는데 resourceIds가 없는 경우는 pass(revert X)
   * 다른 점은 eventReceive에서는 작업의뢰건이므로 공정정보만 있고 라인정보는 없고, eventChange에서는 라인정보가 있음
   */
  const validGlines = async (info) => {
    const { event } = info;
    console.log(event);
    const resources = event.getResources(); // event.extendedProps.resourceIds가 아님에 유의
    console.log(resources)
    const resourceIds = resources.map(resource => resource.id);
    if (resourceIds.length > 0) { // 참고로 라인(resource)에 걸쳐서 할당이 되지 않으므로 resourceIds는 하나임 (현재 파악결과는 그럼)
      const gprocess = event.extendedProps.gprocessCode;
      const glinesByProcess = await selectGLinesByGProcessDirect(gprocess); // 유효한 공정라인 조회
      // resourceIds 내의 모든 요소가 glinesByProcess에 포함되어야 함
      const compare = glinesByProcess.map(gline => gline.id.toString()); // resourceIds안의 배열값이 string이고 gline.id는 integer이므로 형을 맞춰줌
      const intersection = resourceIds.filter(x => compare.includes(x)); // 교집합
      return intersection.length === resourceIds.length; // resourcesIds의 모든 요소가 glinesByProcess에 속해 있는 경우
    }
    
    return true;
  }

  /**
   * 외부(작업의뢰) => 캘린더 drop시 발생하는 이벤트
   * drop 대신 eventReceive 사용 => 이유: event 접근이 가능하고, revert 기능이 필요함
   * 근무시간은 복잡도 증가에 따라 사용자 편의성에 문제가 있다고 판단되어 사용하지 않기로 함. 따라서 현재로서는 종일(allDay) 이벤트 형태로만 사용. 2022-08-19
   */
  const handleEventReceive = async (info) => {
    console.log("handleEventReceive");
    console.log(info);
    const { event } = info;
    const valid = await validGlines(info);
    // console.log(glines)
    // console.log(event.extendedProps)
    // const { amount, areaJa } = event.extendedProps;
    // event.setProp("title", `${amount} ${areaJa}`);

    // TODO : capacity 계산 후 설정.
    // 해당 기간에 대하여 capacity 계산
    // const { startStr, endStr } = event;
    // evaluateCapacity(event, startStr, endStr);
    // setDayCellColor({
    //   ...dayCellColor,
    //   [event.start.toString()]: 'black',
    // })
    // info.date = event.start;
    // info.dayNumberText = "111";
    // handleDayCellContent(info);
    // return;
    if (valid) {
      assignGWorkOrderToPlanning(event.extendedProps.id);

      // // TODO : 임시. 초기 마감시간 설정. 추후 capacity 고려하여 적용
      // const start = event.start
      // let endDate;
      // if (event.end) { // 사실 여기는 null
      //   endDate = event.end;
      // } else {
      //   // TODO : 여기서 capacity에 따라 기간 설정. 공정라인별 capacity는 redux에 넣어두도록...
      //   endDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours()+4, 0);
      // }
      
      // // TODO : capacity와 물량(또는 면적 등) 계산으로 기본 시간 설정(바로 위) 및 근무시간 이후로 넘어갈 경우 다음날로 이벤트 추가 생성(groupId 공유)
      // // TODO : 생산라인별 근무시간(시작~마감)과 capacity 설정 필요
      // event.setDates(start, endDate);
      
      // // const { ... } = event 로 하면 반복적인 event. 은 줄어드나 실제로 코드 라인수가 늘어 사용안함
      // const calendarApi = info.view.calendar; // or CalendarAPI() 사용
      // const dividedEvent = {
      //   allDay: event.allDay,
      //   allow: event.allow,
      //   backgroundColor: event.backgroundColor,
      //   borderColor: event.borderColor,
      //   classNames: event.classNames,
      //   constraint: event.constraint,
      //   display: event.display,
      //   durationEditable: event.durationEditable,
      //   end: new Date(), // TODO : 추후 계산 반영
      //   // endStr, // end 설정하면 실제로 endStr은 자동설정됨
      //   extendedProps: event.extendedProps,
      //   groupId: event.groupId, // TODO : 그룹아이디를 묶으면 같이 움직이므로 묶고 푸는 것 고려
      //   overlap: event.overlap,
      //   source: event.source,
      //   start: new Date(), // TODO : 추후 계산 반영
      //   // startEditable: event.startEditable === undefined ? true: event.startEditable, // startEditable: undefined로 설정하면 실제로 startEditable: false가 됨
      //   // startStr, // start 설정하면 실제로 endStr은 자동설정됨
      //   textColor: event.textColor,
      //   title: event.title,
      //   url: event.url, // url에 값이 있으면 hyperlink로 화면 텍스트 색상이 바뀜. 참고로 url 설정시 절대경로/상대경로 주의
      // };
      
      // // addEvent() will render immediately. will call handleEventAdd
      // calendarApi.addEvent(dividedEvent, true); // temporary=true, will get overwritten when reducer gives new events
    } else {
      // setAlertInfo({
      //   titleAlert: "안내",
      //   messageAlert: "올바른 공정라인이 아니므로 생산일정에 할당할 수 없습니다.",
      //   open: true,
      // });

      // info.revert();
      // TODO : 아래처럼 따로따로 useState하면 여러번 render될 수 있으므로 추후 하나로 하는 방법 고려
      setTargetObjectRevert(info);
      setRevertMessage("올바른 공정라인이 아니므로 생산일정에 할당할 수 없습니다.");
      setConfirmOpenRevert(true);
    }
    
    console.log(event);
    await createGPlan(event); // 즉각 적용

    await assignToGPlan({ id: event.extendedProps.id });

    externalGPlans.push(event); // 깜빡임 개선을 위해 새로 추가된 이벤트만 지우도록 하기 위해 이벤트 목록을 저장해둔다.

    // TODO : resourceView일 때만 refresh
    const currentView = CalendarAPI().view.type;
    const found = resourceHeaderRights.find(element => element === currentView);
    if (found) {
      refresh();
    }
  }

  // 내용변경 (캘린더 내 이동 포함). 현재 GPlanDialog에서 속성 변경 후 수정하면 본 함수가 여러 번 호출됨
  const handleEventChange = async (info) => {
    console.log("handleEventChange");
    console.log(info)
    const valid = await validGlines(info);
    if (!valid) {
      setTargetObjectRevert(info);
      setRevertMessage("올바른 공정라인이 아니므로 이동하거나 변경할 수 없습니다.");
      setConfirmOpenRevert(true);
    } else {
      const { event, oldEvent } = info;
      const { startStr, endStr } = event;
      // evaluateCapacity(event, startStr, endStr, oldEvent); // capacity 체크

      // handleEventChange에서 DB 수정을 하면 여러번 호출되어 문제가 있음
      // await modifyGPlan(event);

      // refresh(); // TODO : 같은 이벤트 그룹에서 하나의 이벤트를 그룹해제하면 리로딩이 필요한데 여기서 수행. 단 그룹해제인지 그외 수정인지 모두 refresh하므로 성능상 문제소지가 있음
    }
  }

  const onRevert = (info) => {
    info.revert();
  }

  // 상단의 요일 표시 부분
  const handleDayHeaderContent = (info) => {
    
  }
  
  const getAlarmsLabel = (color) => {
    const selected = alarms.find(alarm => alarm.color === color);
    return selected.label;
  }

  const handleDayCellContent = (info) => {
    // console.log("handleDayCellContent")
    // console.log(info)
    // console.log(dayCellColor)
    // console.log(dayCellColorWithoutGLine)
    let cellContent;
    const date = dateFormat(new Date(info.date), 'yyyy-MM-dd');
    if(info.resource && info.resource.id) {
      if (dayCellColor[date] && dayCellColor[date][info.resource.id]) {
        cellContent = (
          <Avatar
            variant="rounded"
            sx={{ /*width: 36, */height: 20, bgcolor: dayCellColor[date][info.resource.id] ? dayCellColor[date][info.resource.id] : alarms[0].color }}
          >
            <span style={{ fontSize: '0.7em'}}>{info.dayNumberText ? info.dayNumberText : getAlarmsLabel(dayCellColor[date][info.resource.id] ? dayCellColor[date][info.resource.id] : alarms[0].color)}</span>
          </Avatar>
        );  
      } else {
        cellContent =  (
          <Avatar
            variant="rounded"
            sx={{ /*width: 36, */height: 20, bgcolor: alarms[0].color }}
          >
            <span style={{ fontSize: '0.7em'}}>{info.dayNumberText ? info.dayNumberText : alarms[0].label}</span>
          </Avatar>
        );
      }
    } else {
      cellContent =  (
        <Avatar
          variant="rounded"
          sx={{ height: 20, bgcolor: dayCellColorWithoutGLine[date] ? dayCellColorWithoutGLine[date] : alarms[0].color }}
        >
          <span style={{ fontSize: '0.7em'}}>{info.dayNumberText ? info.dayNumberText : getAlarmsLabel(dayCellColorWithoutGLine[date] ? dayCellColorWithoutGLine[date] : alarms[0].color)}</span>
        </Avatar>
      );
    }

    return cellContent;
  }

  // TODO : event가 캘린더에 올라가기 전 인것으로 보임
  const handleDayCellDidMount = (info) => {
    // console.log("handleDayCellDidMount")
    // console.log(info)
    // console.log(info.view.getCurrentData());
    // console.log(info.view.getCurrentData().calendarApi.getEvents());
    // // console.log(CalendarAPI()?.getEvents());
    // if (info.view.getCurrentData().calendarApi.getEvents().length > 0) {
    //   console.log("~~~~~~~~~~~~~~~~~")
    // }
    // // info.el.bgColor = "blue"
    // // #EC7A67 => 경고 (100% 이상)
    // // #F7D57C => 주의 (80% 이상)
    // // #87C58C => 여유 (80% 미만)
  }
  
  const removeFromGPlan = async (event) => {
    await removeGPlan(event.id); // GPlans에서 삭제
    await cancelGWorkOrderToPlanning(event); // 왼쪽의 작업의뢰 목록에서 삭제
    await cancelFromGPlan({ id: event.extendedProps.id }); // 작업의뢰 상태를 PRE로 변경

    refresh();
  }

  // // TODO : 부모로 옮겨 화면의 다른 곳(GPlanWorkDialog 등)에서도 사용할 수 있도록 해야 한다.
  // const refresh = async () => {
  //   // CalendarAPI().removeAllEvents();
  //   externalGPlans.forEach(event => event.remove());
  //   externalGPlans.splice(0, externalGPlans.length);

  //   // 캘린더 UI 상에서의 동작으로 이벤트에 변경이 있을 때 아래와 같이 <FullCalendar>의 events={gplans} 변경시 일시적으로 이벤트가 중복됨 => Backdrop을 이용하여 현상을 일부 막음
  //   setOpenBackdrop(true);
    
  //   // 약간의 속도개선(UI상 매끄러움)을 위해 Backdrop을 사이에 두고 두개의 조회 블럭으로 분리
  //   if (selectedGProcessCode === "ALL") {
  //     await selectAll(); // 참고로 await가 없으면 깜박임 현상 있음
  //   } else {
  //     await selectByGProcess(selectedGProcessCode);
  //   }

  //   setTimeout(() => setOpenBackdrop(false), 300);

  //   if (selectedGProcessCode === "ALL") {
  //     await selectAllGLines();
  //   } else {
  //     await selectGLinesByGProcess(selectedGProcessCode);
  //   }
  // }

  const handleWindowResize = (arg) => {
    console.log(arg)
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <GPlanDialog
        modify={modify}
        open={open}
        setOpen={setOpen}
        selectedEvent={selectedEvent}
        glinesByGProcess={glinesByGProcess}
        // cancel={cancelGWorkOrderToPlanning}
        cancel={removeFromGPlan}
        events={CalendarAPI()?.getEvents()}
        refresh={refresh}
      />
      <FullCalendar
        height={window.innerHeight*0.74}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, resourceDayGridPlugin, gplanDayViewPlugin, listPlugin]}
        // headerToolbar={{
        //   left: 'prevYear,prev,next,nextYear today',
        //   center: 'title',
        //   // right: 'dayGridMonth,timeGridWeek,timeGridDay,resourceTimeGridDay,resourceTimeGridFourDay,custom,listWeekNoHour,listDayNoHour, establishButton'
        //   // right: 'dayGridMonth,listWeekNoHour,listDayNoHour,resourceTimeGridDay,resourceTimeGridFourDay establishButton' // 최근
        //   // right: 'dayGridMonth,timeGridWeek,timeGridDay,resourceTimeGridDay,resourceTimeGridFourDay establishButton'
        //   // right: 'dayGridMonth,dayGridWeek,listDayNoHour,resourceTimeGridDay,resourceTimeGridFourDay establishButton'
        //   // right: 'resourceDayGridMonth,resourceDayGridWeek,resourceDayGridDay,resourceDayGrid'
        //   // right: 'resourceDayGridMonth,resourceDayGridWeek,resourceDayGridDay'
        //   // right: 'dayGridMonth,dayGridWeek,listDay'
        //   right: headerToolbarRight,
        // }}
        // headerToolbar={headerToolbar}
        headerToolbar={
          {
            ...defaultHeaderToolbar,
            right: defaultHeaderRight,
          }
        }
        initialView='dayGridMonth'
        firstDay={1} // 월요일 기준
        // initialView='basicWeek'
        // initialView='custom'
        // eventColor="purple"
        rerenderDelay={10}
        fixedWeekCount={false} // true => If true, the calendar will always be 6 weeks tall. If false, the calendar will have either 4, 5, or 6 weeks, depending on the month.
        customButtons={{
          establishButton: {
            text: '확정하기', // TODO : 추후 localization 필요
            click: async () => {
              // alert('clicked the custom button!');
              setConfirmOpen(true);
            }
          },
        }}
        schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'} // TODO : 추후 라이센스 구입 필요
        datesAboveResources={false} // true => 날짜별 리소스별, false => 리소스별 날짜별
        views={{
          // resourceTimeGridDay: { // resourceTimeGridDay는 예약어로 views에 포함하지 않아도 되는 것 같지만 buttonText가 "일"이므로 변경을 위해 추가했음
          //   type: 'resourceTimeGrid',
          //   duration: { days: 1 },
          //   buttonText: '일/라인', // TODO : 추후 localization 필요
          //   allDaySlot: true,
          //   // 아래 두줄로 시간영역 선으로 만들어 없애는 효과. 대신 영역이 남음으로 css에서 종일 영역을 키움
          //   slotMinTime: "00:00:00",
          //   slotMaxTime: "00:00:00",
          // },
          // resourceTimeGridFourDay: {
          //   type: 'resourceTimeGrid',
          //   duration: { days: 7 },
          //   buttonText: '주/라인', // TODO : 추후 localization 필요
          //   allDaySlot: true,
          //   // 아래 두줄로 시간영역 선으로 만들어 없애는 효과. 대신 영역이 남음으로 css에서 종일 영역을 키움
          //   slotMinTime: "00:00:00",
          //   slotMaxTime: "00:00:00",
          // },
          // gplanDayViewPlugin,
          // listWeekNoHour: {
          //   type: 'listWeek',
          //   buttonText: '주',
          // },
          listDay: { // type이름과는 다름. 이 이름이 headerToolbar에서 사용됨
            type: 'listDay',
            buttonText: '일',
          },
        }}
        // TODO : 생산라인별로 businessHours 적용 가능. 현재는 모든 이벤트를 allDay로 한다.
        resourceOrder="order" // DESC 인경우 propertyName 앞에 '-'를 붙인다. 예를 들면, -order, -title 등
        resources={glines.map(gline => {
          return {
            id: gline.id,
            title: gline.name,
            order: gline.order,
            color: gline.color, // resource.extendedProps.color
            // eventTextColor: gline.color, ???
          }
        })}
        // resourceLabelContent={GridLine}
        resourceLabelContent={info => info.resource.title}
        resourceLabelDidMount={(info) => {
          const { color } = info.resource.extendedProps;
          info.el.style.color = getTextColorByBackgroundColor(color);
          info.el.style.backgroundColor = color;
          // TODO : 아래 코드와 위의 코드는 다른가???
          // info.el.style = {
          //   ...info.el.style,
          //   color: getTextColorByBackgroundColor(color), // => ""임 ???
          //   backgroundColor: color, // => ""임 ???
          // };
          // console.log(info.el.style);
        }}
        // defaultTimedEventDuration={"02:00"} // TODO : 동적으로 설정하는 방법???
        // eventDurationEditable={false}
        eventDidMount={handleEventDidMount}
        eventStartEditable={true} // false => dnd 안됨
        eventDurationEditable={true}
        editable={true}
        droppable={true}
        // drop={(info) => { //external drop시 호출됨
        //   console.log(info)
        //   // info.event.extendedProps = Object.assign({}, { test: 'test' });
        //   // console.log(info.view.calendar.getEvents())
        //   // console.log(calendarRef.getEvents())
        //   // console.log(info.view.getCurrentData())
        //   assignGWorkOrderToPlanning(info.draggedEl.id);
        //   // TODO : 아래 방식을 사용하지 말고 gworkOrdersPlanning를 컨트롤하도록 할 것. info.draggedEl.id로 gworkOrdersPlanning 찾아서 삭제
        //   // info.draggedEl.parentNode.removeChild(info.draggedEl);
        // }}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        datesSet={handleDates}
        select={handleDateSelect}
        events={gplans}
        
        // TODO : eventSources를 통해 호출 후 react 상에서 어떤 동작을 하면(아마도 FullCalendar가 다시 렌더링되고 나면???) 그 이후로는 날짜변경시(datesSet 같은) feed 호출이 되지 않음???
        // eventSources의 url 변경을 통해 공정별 filtering을 시도했으나 현재는 원화는 결과를 얻지 못해 보류한 상태
        // 아래 주석들은 eventSources의 여러 사용례
        // eventSources={[
        //   '/api/gplans',
        //   '/feed2.php',
        // ]}
        // eventSources={
        //   // [
        //   {
        //     url: `${testRequest}`,
        //     failure: function() {
        //       alert('there was an error while fetching events!');
        //     },
        //     color: 'yellow',
        //     textColor: 'black',
        //   }
        //   // ]
        // }
        // eventSources={[{"events":[{"title":"Event1","start":"2022-08-25"},{"title":"Event2","start":"2022-08-26"}],"color":"yellow","textColor":"black"}]}

        eventDrop={drop} // 캘린더 내 이동(dnd)후 호출됨
        // eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventAdd={handleEventAdd} // external drop시는 호출되지 않음
        eventChange={handleEventChange} // called for drag-n-drop/resize
        // eventsSet={handleEventsSet} // Called after event data is initialized OR changed in any way.
        eventRemove={handleEventRemove}
        // eventAfterRender={handleEventAfterAllRender}
        eventReceive={handleEventReceive}
        dayCellDidMount={handleDayCellDidMount}
        dayCellContent={handleDayCellContent}
        dayHeaderContent={handleDayHeaderContent}
        // 
        // buttonText={{
        //   today: '오늘',
        //   month: '월간',
        //   week: '주간',
        //   day: '일간',
        //   list: '목록'
        // }}
        navLinks={true} // 날짜 눌러서 일 캘린더 보는 기능
        // 현재 업무상 단순함 강조로 인해 시간별 event가 아닌 allDay 기준이므로 주석처리
        // businessHours={[
        //   {
        //     // days of week. an array of zero-based day of week integers (0=Sunday)
        //     daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Friday
          
        //     startTime: '06:00', // a start time (6am in this example)
        //     endTime: '18:00', // an end time (6pm in this example)
        //   },
        //   {
        //     // days of week. an array of zero-based day of week integers (0=Sunday)
        //     daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Friday
          
        //     startTime: '20:00', // a start time (6am in this example)
        //     endTime: '22:00', // an end time (6pm in this example)
        //   },
        // ]}
        locales={allLocales}
        locale='ko' // the initial locale
        windowResize={handleWindowResize}
      />
      <ConfirmDialog
        removeId={targetObjectRevert}
        title={"안내"}
        open={confirmOpenRevert}
        setOpen={setConfirmOpenRevert}
        onConfirm={onRevert}
        type="ALERT"
      >
        {revertMessage}
      </ConfirmDialog>
    </ThemeProvider>
  );
});

export default GPlanCalendar;
