import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { ConfirmDialog } from "../../components/dialog";

// store actions
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientGComponentItemMapActions from "../../store/gclientGComponentItemMap";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";

import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";
import G04docuFileDialog from "../G04docuFile/G04docuFileDialog";
import TreeView from "./GTreeView";

const G04docuGTestSubMaterial = ({
  division,
  handleClickFileDownload,
  handleClickViewEachDoc,
  selectedGClient,
}) => {
  const [mode, setMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedDocuFileType, setSelectedDocuFileType] = useState("");
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [etcType, setEtcType] = useState("");
  const [open, setOpen] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState(null);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");
  const [selected, setSelected] = useState([]);
  const [subMaterialProcessTreeData, setSubMaterialProcessTreeData] = useState([]);
  const [subMaterialBuildTreeData, setSubMaterialBuildTreeData] = useState([]);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gcomponentItemsSubMaterialProcess = useSelector((state) => state.gclientGComponentItemMap.gcomponentItemsSubMaterialProcess); // 가공부자재 성적서
  const gcomponentItemsSubMaterialBuild = useSelector((state) => state.gclientGComponentItemMap.gcomponentItemsSubMaterialBuild); // 시공부자재 성적서

  const dispatch = useDispatch();

  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const selectMyItemsForTree = (gclientId, division, status) => dispatch(gclientGComponentItemMapActions.selectAllGComponentItemsByDivisionByStatusForTree(gclientId, division, status)) // 원자재, 가공부자재, 시공부자재 성적서
  const removeGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath }) => dispatch(gclientG04docuGTestMapActions.remove({ gclientId, g04docuGTestId, documentPath }))
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))

  useEffect(
    () => {
      console.log(division)
      selectMyItemsForTree(sessionUser.id, division, 'all');
    }, [dispatch]
  )

  useEffect(
    async () => {
      const gtestsAllForTree = gcomponentItemsSubMaterialProcess.map(item => ({
          id: item.id,
          label: item.name,
          data: item,
          // type: 'ITEM',
          children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
            const { order : value1 } = a;
            const { order : value2 } = b;
            if (value1 < value2) return -1;
            if (value1 > value2) return 1;
            return 0;
          }).map(item1 => ({
            id: `${item.id}/${item1.id}`,
            label: item1.name,
            data: item1,
            type: 'DOCTEST',
          }))
        }
      ));
      console.log(gcomponentItemsSubMaterialProcess)
      setSubMaterialProcessTreeData(gtestsAllForTree);
    }, [gcomponentItemsSubMaterialProcess]
  )

  useEffect(
    async () => {
      const gtestsAllForTree = gcomponentItemsSubMaterialBuild.map(item => ({
          id: item.id,
          label: item.name,
          data: item,
          // type: 'ITEM',
          children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
              const { code : value1 } = a;
              const { code : value2 } = b;
              if (value1 < value2) return -1;
              if (value1 > value2) return 1;
              return 0;
            }).map(item1 => ({
              id: `${item.id}/${item1.id}`,
              label: item1.name,
              data: item1,
              type: 'DOCTEST',
            }))
        }
      ));
      console.log(gcomponentItemsSubMaterialBuild)
      setSubMaterialBuildTreeData(gtestsAllForTree);
    }, [gcomponentItemsSubMaterialBuild]
  )

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    setMode(newMode);

    selectMyItemsForTree(sessionUser.id, division, newMode);
  }

  const selectAllGComponentItems = (gclientId, division) => {
    selectMyItemsForTree(gclientId, division, mode);
  }

  const removeFile = async () => {
    const { gclientId, g04docuGTestId, g04docuEtcId, division, documentPath, type } = removeFileObject;
    await removeG04docuFile(documentPath);

    if (type === 'TEST') {
      await removeGClientG04docuGTestMap({ gclientId, g04docuGTestId, documentPath })
      selectAllGComponentItems(gclientId, division);
    } else if (type === 'ETC') {
      await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
      // if (etcType === 'TEST' && selectedGComponentItemType === 'PROCESS') { // TODO : division과 동일한 것 같음. 추후 정리할 것
      //   selectAllProcesses(gclientId);
      // } else if (etcType === 'ETC') {
      //   selectAllG04docuEtcs(gclientId);
      // } else {
      //   selectAllGComponentItems(gclientId, division);
      // }
      if (etcType === 'TEST') {
        selectAllGComponentItems(gclientId, division);
      }
    }
  }

  return (
    <>
      {
        (division === 'SUB_MATERIAL_PROCESS' ? subMaterialProcessTreeData.length > 0 : subMaterialBuildTreeData.length || mode === 'nofile' || mode === 'invalid') && (
          <Grid item xs={12}>
            {/*
              TODO : 파일 다이얼로그가 트리 아이템이 많으면 늦게 뜨는데 그 이유는 
              파일 다이얼로그와 트리가 같은 레벨이고 파일 다이얼로그의 state가 변하므로 트리도 다시 rendering 되는 것으로 보임
              따라서 파일 다이얼로그를 트리 안으로 넣으면 속도가 개선될 것으로 보임. 추후 적용해 볼 것
             */}
            <G04docuFileDialog
              open={open}
              setOpen={setOpen}
              selectedRow={selectedRow}
              selectedGClientId={sessionUser.id}
              selectedGClient={selectedGClient}
              selectedGComponentItemId={selectedGComponentItemId}
              selectedGComponentItemName={selectedGComponentItemName}
              selectedGComponentItemType={selectedGComponentItemType}
              selectedDocuFileType={selectedDocuFileType}
              refresh={selectAllGComponentItems}
              from={'PROFILE'}
            />
            <G04docuFileDialogEtc
              open={openEtc}
              setOpen={setOpenEtc}
              selectedRow={selectedRowEtc}
              gclientId={sessionUser.id}
              gclient={selectedGClient}
              selectedGComponentItemType={selectedGComponentItemType}
              refresh={selectAllGComponentItems} // TODO : 일반문서/인증서/성적서 종류에 따라 refresh 함수가 다름
            />
            <ConfirmDialog
              removeId={removeFileObject}
              title={"삭제"}
              open={confirmOpenRemoveFile}
              setOpen={setConfirmOpenRemoveFile}
              onConfirm={removeFile}
              onCancel={() => {}}
            >
              <div>
                <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
                <br /><br />
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
                  { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
                </Box>
              </div>
            </ConfirmDialog>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="basic-content" id="basic-header">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6" component="div">
                      { division === 'SUB_MATERIAL_PROCESS' && "가공부자재 성적서 / 시험성과대비표"}
                      { division === 'SUB_MATERIAL_BUILD' && "시공부자재 성적서 / 시험성과대비표"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography variant="subtitle2" component="div" /*color="primary" */sx={{ mr: 1 }} onClick={(e) => e.stopPropagation()}>{"보기"}</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={mode}
                      exclusive
                      size="small"
                      onChange={handleChangeMode}
                    >
                      <ToggleButton value="nofile">{"성적서 없음"}</ToggleButton>
                      <ToggleButton value="invalid">{"만료일 경과"}</ToggleButton>
                      <ToggleButton value="all">{"전체"}</ToggleButton>
                    </ToggleButtonGroup>  
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <TreeView
                    selected={selected}
                    onSelect={setSelected}
                    disableRoot
                    items={division === 'SUB_MATERIAL_PROCESS' ? subMaterialProcessTreeData : subMaterialBuildTreeData}
                    handleClickFileDownload={handleClickFileDownload}
                    handleClickViewEachDoc={handleClickViewEachDoc}
                    setSelectedRow={setSelectedRow}
                    setSelectedRowEtc={setSelectedRowEtc}
                    setSelectedGComponentItemId={setSelectedGComponentItemId}
                    setSelectedGComponentItemName={setSelectedGComponentItemName}
                    setSelectedGComponentItemType={setSelectedGComponentItemType}
                    setSelectedDocuFileType={setSelectedDocuFileType}
                    setRemoveFileObject={setRemoveFileObject}
                    setConfirmOpenRemoveFile={setConfirmOpenRemoveFile}
                    setOpen={setOpen}
                    setOpenEtc={setOpenEtc}
                    setEtcType={setEtcType}
                  />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      }
    </>
  )
}

export default G04docuGTestSubMaterial;