import { csrfFetch } from "./csrf.js";

const RECEIVE_PLANS = "gplan/receivePlans";
const RECEIVE_PLANS_FOR_STATUS = "gplan/receivePlansForStatus";
const CREATE_PLAN = 'gplan/createPlan';
const UPDATE_PLAN = 'gplan/updatePlan';
const REMOVE_PLAN = 'gplan/removePlan';

const receivePlans = (gplans) => ({
  type: RECEIVE_PLANS,
  payload: gplans,
});

const receivePlansForStatus = (gplans) => ({
  type: RECEIVE_PLANS_FOR_STATUS,
  payload: gplans,
});

const createPlan = (gplan) => ({
  type: CREATE_PLAN,
  payload: gplan,
});

const updatePlan = (gplan) => ({
  type: UPDATE_PLAN,
  payload: gplan,
});

const removePlan = () => ({
  type: REMOVE_PLAN,
});

export const create = (gplan) => async dispatch => {
  const resources = gplan.getResources();
  const resourceIds = resources.map(resource => resource.id);
  const newGPlan = JSON.parse(JSON.stringify(gplan));
  newGPlan.extendedProps.resourceIds = resourceIds;
  // console.log(newGPlan)
  const response = await csrfFetch(dispatch, "/api/gplans", {
    method: "POST",
    body: JSON.stringify(newGPlan),
  });
  const data = await response.json();
  dispatch(createPlan(data.gplan));
  return response;
};

export const createPlans = (gplans) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans/bulk", {
    method: "POST",
    body: JSON.stringify(gplans),
  });
  const data = await response.json();
  dispatch(receivePlans(data.gplans));
  return response;
};

export const createPlansDirect = async (gplans) => {
  const response = await csrfFetch(null, "/api/gplans/bulk", {
    method: "POST",
    body: JSON.stringify(gplans),
  });
  const data = await response.json();
  return data.gplans;
};

export const applyGPlan = (event, oldEvent) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans", {
    method: "POST",
    body: JSON.stringify({ event, oldEvent }),
  });
  const data = await response.json();
  dispatch(receivePlans(data.gplans));
  return response;
};

export const modify = (gplan) => async dispatch => {
  // alert("00")
  console.log(gplan)
  const resources = gplan.getResources();
  console.log(resources)
  let resourceIds = null;
  if (resources.length > 0 && resources[0] !== null) {
    resourceIds = resources.map(resource => resource.id);
  } else {
    resourceIds = gplan.extendedProps.resourceIds;
  }
  const newGPlan = JSON.parse(JSON.stringify(gplan));
  newGPlan.extendedProps.resourceIds = resourceIds ? resourceIds : [];
  // console.log(newGPlan)
  const response = await csrfFetch(dispatch, "/api/gplans", {
    method: "PUT",
    body: JSON.stringify(newGPlan),
  });
  const data = await response.json();
  dispatch(updatePlan(data.gplan));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans");
  const data = await response.json();
  console.log(data)
  dispatch(receivePlans(data.gplans));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, "/api/gplans");
  const data = await response.json();
  console.log(data)
  return data.gplans;
}

// export const selectAllWithinPeriod = (start, end) => async dispatch => {
//   const response = await csrfFetch(dispatch, `/api/gplans/start/${start}/end/${end}`);
//   const data = await response.json();
//   console.log(data)
//   dispatch(receivePlans(data.gplans));
//   return response;
// }

// 참고
// const a = new Date()
// a.toUTCString()
// a.toISOString()
// a.toGMTString()
// a.toDateString()
// a.toLocaleDateString()
// a.toLocaleString()
// a.toLocaleTimeString()
// ...

export const selectByGProcess = (gprocess, start, end, calendar=true, status=false) => async dispatch => {
  // Date 객체를 JSON.stringify 하면 iso string 형태가 된다. (단 ""로 싸여 있음)
  const startDate = JSON.stringify(start).replaceAll("\"", "");
  const endDate = JSON.stringify(end).replaceAll("\"", "");

  console.log("selectByGProcess");
  console.log(startDate, endDate);

  // const response = await csrfFetch(dispatch, `/api/gplans/gprocess/${gprocess}/start/${start}/end/${end}`);
  const response = await csrfFetch(dispatch, `/api/gplans/gprocess/${gprocess}/start/${startDate}/end/${endDate}`);
  const data = await response.json();
  
  calendar && dispatch(receivePlans(data.gplans));
  status && dispatch(receivePlansForStatus(data.gplans));
  
  return response;
}

export const selectByGProcessDirect = async (gprocess, start, end) => {
  const startDate = JSON.stringify(start).replaceAll("\"", "");
  const endDate = JSON.stringify(end).replaceAll("\"", "");

  console.log("selectByGProcessDirect");
  console.log(startDate, endDate);

  // const response = await csrfFetch(null, `/api/gplans/gprocess/${gprocess}/start/${start}/end/${end}`);
  const response = await csrfFetch(null, `/api/gplans/gprocess/${gprocess}/start/${startDate}/end/${endDate}`);
  const data = await response.json();
  return data.gplans;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/gplans/${id}`, {
    method: "DELETE",
  });
  dispatch(removePlan());
  return response;
};

export const removeByGWorkOrderIdDirect = async (gworkOrderId) => {
  const response = await csrfFetch(null, `/api/gplans/gworkOrder/${gworkOrderId}`, {
    method: "DELETE",
  });
  const data = await response.json();
  return data.count;
};

export const setGPlans = (gplans) => async (dispatch) => {
  dispatch(receivePlans(gplans));
  return;
}

const initialState = {
  gplan: null,
  gplans: [],
  gplansForStatus: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case CREATE_PLAN:
      // alert("gplan")
      newState = Object.assign({}, state, {
        gplan: action.payload,
      });
      return newState;
    case UPDATE_PLAN:
      // console.log(action.payload)
      newState = Object.assign({}, state, {
        gplan: action.payload,
        // gplans: state.gplans.map(i => {
        //   if (i === action.payload.id) {
        //     return action.payload;
        //   } else {
        //     return i;
        //   }
        // })
      });

      return newState;
    case REMOVE_PLAN:
      newState = Object.assign({}, state, {
        gplan: null,
        // gplans: state.gplans.filter(i => i.id !== action.payload.id),
      });
      
      return newState;
    case RECEIVE_PLANS:
      newState = Object.assign({}, state, { gplans: action.payload });
      return newState;
    case RECEIVE_PLANS_FOR_STATUS:
      newState = Object.assign({}, state, { gplansForStatus: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
