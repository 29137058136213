import React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';

const Copyright = (props) => {
  return (
    <Container component="main" maxWidth="false" sx={{ mt: 10, bgcolor: /*"#000"*/"#222", fontSize: '0.8rem' }}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container maxWidth="xl" sx={{ color: '#F59715', p: 5 }}>
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            {"상호명: (주)워프코어 | 대표: 최 완 | 사업자등록번호: 623-81-02116 | 주소: 서울특별시 강서구 화곡로 416 가양 더 스카이밸리 지식산업센터 1117호 | 전화번호: 02-2131-4562"}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Typography variant="h6" /*color="text.secondary"*/ align="center" {...props}>
              {'Copyright © '}
              {new Date().getFullYear()}
              <Link color="inherit" href="http://www.warpcore.co.kr" style={{ textDecoration: 'none' }}>
                {" by WARPCORE Co.,LTD."}
              </Link>{' '}
              {'All Rights Reserved.'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Copyright;