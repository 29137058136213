import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {
  Box,
  IconButton,
  Card,
  Grid,
} from '@mui/material';
import  {
  Add,
  Remove,
  Delete,
  Edit,
  FileOpen,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Redo,
} from '@mui/icons-material';
// import './GProject.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({
  documentPath,
}) => {
  const [numPages, setNumPages] = useState(null); // 총 페이지수
  const [pageNumber, setPageNumber] = useState(1); // 현재 페이지
  const [pageScale, setPageScale] = useState(1); // 페이지 스케일
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onDocumentLoadSuccess = ({numPages}) => {
    console.log(`numPages ${numPages}`);
    setNumPages(numPages);
    console.log("~~~~~~~~~~~~~~")
    console.log(document.documentElement.clientWidth);
    console.log(document.documentElement.clientHeight);

    console.log(window.innerWidth);
    console.log(window.innerHeight);
  }

  return (
    <>
      {/* pdf 크기가 1280 * 720이 넘는 경우, overflow 처리 */}
      {/* <div style={{width: '1280px', height: '720px', overflow: 'auto'}}> */}
      {/* <div style={{ height: '900px', overflow: 'auto' }}>
        <Document file="http://localhost:5002/files/금강글라스(주) (61ebea97-a1e8-4578-9062-361b6922cc73)/BIZ_REG/사업자등록증_금강글라스(주).pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={1280} height={720} scale={pageScale} pageNumber={pageNumber}/>
        </Document>
      </div> */}
      <Box sx={{ height: '60px' }} display="flex" alignItems="center">
        <Grid container spacing={1}>
          <Grid item display="flex" alignItems="center" sx={{ ml: 2 }}>
            {`페이지: ${pageNumber}/${numPages}`}
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={() => setPageNumber(pageNumber === 1 ? pageNumber : pageNumber - 1)}>
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={() => setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1)}>
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
          <Grid item display="flex" alignItems="center" sx={{ ml: 2 }}>
            {`확대/축소`}
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={() => setPageScale(pageScale === 3 ? 3 : pageScale + 0.1)}>
              <Add />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={() => setPageScale((pageScale - 1) < 1 ? 1 : pageScale - 1)}>
              <Remove />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: '#acacac', p: 3 }}>
        <Card sx={{ mt: 10 }}/*sx={{ height: `${document.documentElement.clientHeight-64-200}px`, overflow: 'auto' }}*/>
          {/* <Document file="http://localhost:5002/files//Material Approvals/(주)워프코어(356d05cd-2bf1-46b4-8075-c6a951707bb7)/NEW(ccfdbe72-d918-4132-a884-41b876bc0bc6)/NEW_ma_2023-08-03-183410-660.pdf" onLoadSuccess={onDocumentLoadSuccess}> */}
          <Document file={documentPath} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              // width={300}
              // height={500}
              scale={pageScale}
              pageNumber={pageNumber}
              // canvasRef={ref => {
              //   console.log(ref)
              //   if (!ref) {
              //     return;
              //   }

                
              //   // setWidth(width);
              //   // setHeight(height);
              // }}
            />
          </Document>
          {/* <Page pageNumber={1} height={document.getElementsByClassName('PdfDiv')[0]?.clientHeight*0.8 ?? 150} */}
        </Card>
      </Box>
    </>
  );
};

export default PdfViewer;