import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import {
  addDays,
  differenceInDays,
  getTextColorByBackgroundColor,
  objectEmptyCheck,
} from "../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputMultipleSelect,
  FormInputSwitch,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as glineActions from "../../store/gline";
import * as gplanActions from "../../store/gplan";
import { gridDensityValueSelector } from "@mui/x-data-grid-pro";

// amount: 10
// amountCompleted: 0
// amountDefect: 0
// amountDefects: (3) [{…}, {…}, {…}]
// amountIncomplete: 10
// areaFeet: "107.63910416709722"
// areaJa: "108.92178326743564"
// areaMeter: "10"
// areaUnitFeet: "10.763910416709722"
// areaUnitJa: "10.892178326743565"
// areaUnitMeter: "1"
// comments: ""
// createdAt: "2022-08-11T06:41:45.968Z"
// currentSteps: [{…}]
// endStr: ""
// etc: ""
// gglassId: "외부 접합 삼중복층유리"
// gorderDetailId: 627
// gorderId: "20220725-001"
// gprocessCode: "CUT"
// gprocessColor: "#4a90e2"
// gprocessComments: "재단 공정입니다."
// gprocessDefects: (3) [{…}, {…}, {…}]
// gprocessId: 1
// gprocessName: "재단"
// gprocessSteps: [{…}]
// gtypeId: "외부 접합 삼중복층유리"
// height: 1000
// id: 377
// labelPosition: "BottomRight"
// labelPositionView: "inout"
// name: "외부 접합 삼중복층유리"
// no: "GL07"
// notes: ""
// orderAreaType: "m2"
// orderPrice: "900"
// orderPriceFeet: "83.612736"
// orderPriceJa: "82.6281"
// orderPriceMeter: "900"
// orderPriceTotal: "9000"
// otherSpecs: "2도 면취(Seaming) 곡 + PVB + 2도 면취(Seaming) 곡 + 2도 면취(Seaming) 곡 + 2도 면취(Seaming) 곡 "
// selectedGcomponentItems: (7) [Array(7), Array(3), Array(7), Array(14), Array(7), Array(14), Array(7)]
// source: null
// specification: "3 CL H/S Sand Blast FRIT + 0.38 + 3 CL H/S Sand Blast FRIT + 6 AR + 3 CL H/S Sand Blast FRIT + 6 AR + 3 CL H/S Sand Blast FRIT"
// standard: "111"
// startStr: "2022-08-01"
// status: "PRE"
// updatedAt: "2022-08-11T11:03:14.697Z"
// width: 1000
// workOrderNo: "22-08-11-001"
// workOrderNoSuffix: "금강유리(주)-가양역 현장-4차 "

const theme = createTheme();

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  workOrderNo: "",
  workOrderNoSuffix: "",
  gprocessName: "",
  specification: "",
  selectedGLine: "",
  start: new Date(),
  startTime: "",
  end: new Date(),
  endTime: "",
  allDay: false,
  gorderGroup: true,
};

const dayTimes = [
  // { value: "0000", label: "00:00"},
  // { value: "0030", label: "00:30"},
  // ...
];

let hour;
let min;
for (let i=0; i<=48; i++) {
  hour = Math.floor(i/2).toString().padStart(2, '0');
  min = i%2 === 0 ? "00" : "30";
  // console.log(hour, min)
  dayTimes.push({
    value: `${hour}${min}`,
    label: `${hour}:${min}`,
  });
}

// const AntSwitch = styled(FormInputSwitch)(({ theme }) => ({
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const GPlanDialog = ({
  modify,
  open,
  setOpen,
  selectedEvent,
  glinesByGProcess,
  cancel,
  refresh,
  events,
}) => {
  const [errors, setErrors] = useState([]);
  const [checkedAllDay, setCheckedAllDay] = useState(true);
  const [checkedGorderGroup, setCheckedGorderGroup] = useState(true);
  // const [selectedGLines, setSelectedGLines] = useState([]);
  const [canRemove, setCanRemove] = useState(false);

  const gprocesses = useSelector((state) => state.gprocess.gprocesses);

  const handleDialogClose = () => {
    setOpen(false);

    setCheckedGorderGroup(true);
    // TODO : 우선은 boolean 및 기타 데이터타입에 상관없이 공백처리("")했으나 문제발생시 조치할 것
    ["id", "workOrderNo", "workOrderNoSuffix", "gprocessName", "specification", "selectedGLine", "gorderGroup", "start", "end", "allDay"].forEach(item => setValue(item, ""))
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  // const addGUnit = ({ name, code, type, comments }) => dispatch(gunitActions.create({ name, code, type, comments }))
  // const modifyGUnit = ({ id, name, code, type, comments }) => dispatch(gunitActions.modify({ id, name, code, type, comments }))
  const selectGLinesByGProcessDirect = (gprocess) => glineActions.selectByGProcessDirectByQuery(gprocess)
  const modifyGPlan = (gplan) => dispatch(gplanActions.modify(gplan))
  const removeByGWorkOrderIdDirect = (gworkOrderId) => gplanActions.removeByGWorkOrderIdDirect(gworkOrderId)

  const onSubmit = async ({ id, workOrderNo, workOrderNoSuffix, gprocessName, specification, selectedGLine, start, startTime, end, endTime, allDay, gorderGroup }) => {
    setErrors([]);
    
    console.log({ id, workOrderNo, workOrderNoSuffix, gprocessName, specification, start, startTime, end, endTime });

    // console.log(newStartDate);
    // console.log(newEndDate);
    handleDialogClose();
    // selectedEvent.setExtendedProp("aaa", newEndDate);
    console.log(selectedEvent);
    let newStartDate;
    let newEndDate;

    // TODO : 아래 setDates, setAllDay, setResources 호출할 때마다 handleEventChange가 호출됨. 줄일 수 있을까?
    if (allDay) {
      // end를 다음날짜 00:00로 설정.
      // 예를 들면 allDay 2022-08-17이면 2022-08-17 00:00 ~ 2022-08-18 00:00
      // allDay 2022-08-17 ~ 이면 2022-08-18이면 2022-08-17 00:00 ~ 2022-08-19 00:00
      // 주의사항은 +1 했으므로 다이얼로그 보여줄 땐 -1해서 보여줘야 함
      newStartDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0);
      newEndDate = new Date(end.getFullYear(), end.getMonth(), end.getDate()+1, 0, 0); // +1 중요

      // Sets an event’s start date, end date, and allDay properties at the same time.
      selectedEvent.setDates(newStartDate, newEndDate);
      
      selectedEvent.setAllDay(allDay, { maintainDuration : true });
    } else {
      // new Date(year, monthIndex, day, hours, minutes)
      newStartDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), Number(startTime.substring(0, 2)), Number(startTime.substring(2)));
      newEndDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), Number(endTime.substring(0, 2)), Number(endTime.substring(2)));
      
      selectedEvent.setDates(newStartDate, newEndDate); // 호출하고 나면 extendedProp이 사라짐??? -> 사라지는 것이 아니었음. JSON recursive 오류로 인해 추가한 getCircularReplacer가 문제였음
    }

    // // 같은 그룹의 나머지 이벤트들의 날짜들을 조정한다. => 잘못 생각하고 있었음. 캘린더상에서 자동으로 조정하므로 조정할 필요가 없음.
    // const groupedEvent = events.filter(e => e.groupId === selectedEvent.groupId && e.id !== selectedEvent.id);
    // console.log(groupedEvent);
    // groupedEvent.forEach(async (event) => {
    //   event.setStart(event.start); // 반드시 필요
    //   await modifyGPlan(event);
    // });

    // TODO : groupId로 묶인 모든 이벤트에 적용됨. (캘린더 내부적으로 그룹을 풀지 않기 위해 하는 동작으로 보임). 그런데 그룹해제된 후(해제 후 refresh하여 다시 DB조회) 다시 그룹핑하면 묶여서 움직임???
    // => 그룹 해제시 같은 그룹의 나머지 이벤트에 대해서 setProp("groupId") 해보고, 안되면 모든 이벤트를 지웠다가 다시 DB 조회해야 할 수 있음
    if (gorderGroup) {
      // selectedEvent.setProp("groupId", selectedEvent.extendedProps.gorderId);
      selectedEvent.setProp("groupId", selectedEvent.extendedProps.gworkOrderId);
    } else {
      // const groupedEvent = events.filter(event => event.groupId === selectedEvent.groupId && event.id !== selectedEvent.id);
      // groupedEvent.forEach(event => event.setProp("groupId", event.extendedProps.gorderId)); // eventChange 이벤트 발생하며 DB에서 수정됨

      selectedEvent.setProp("groupId", selectedEvent.id);
    }

    // TODO : 공정라인 변경이 있다면 변경. 여기서 고려할 사항은 fullCalendar에서 여러 리소스에 할당되면 시작, 마감이 동시에 움직임.
    // 공정라인별로 capacity가 다르기 때문에 이렇게 동작하면 안될 것 같음. 만일 하나의 작업의뢰건으로 공정라인을 분리하려면 그룹으로 묶고 별도의 event로 나누어야 할 것으로 보임
    selectedEvent.setResources([selectedGLine]);

    // 그룹해제시 저장하고 다시 DB에서 조회해야 됨. 그렇지 않으면 groupId가 캘린더에서 자동으로 바뀌어 그룹해제 효과가 사라짐
    await modifyGPlan(selectedEvent);

    refresh();

    // console.log(events)
    // console.log(selectedEvent);
    // selectedEvent.setStart(newStartDate);
    // selectedEvent.setAllDay(true);
    

    // let func;
    // if (modify) {
    //   func = modifyGUnit;
    // } else {
    //   func = addGUnit
    // }
    // func({ id, name, code, type, comments })
    //   .then (res => {
    //     handleDialogClose();
    //     // refresh();
    //   })
    //   .catch (async (res) => {
    //     const data = await res.json();
    //     if (data && data.errors) setErrors(data.errors);
    //   });
  }

  useEffect(
    async () => {
      // console.log(gprocesses)
      // console.log(selectedEvent);
      // console.log(selectedEvent.extendedProps);
      // '출고' 공정만 삭제 가능 (해당 작업의뢰 전체 삭제). '출고' 공정은 마지막 공정으로 판단
      if (gprocesses[gprocesses.length - 1]?.id === selectedEvent?.extendedProps?.gprocessId) {
        setCanRemove(true);
      } else {
        setCanRemove(false);
      }
      // var resources = selectedEvent && selectedEvent.getResources && selectedEvent.getResources();
      // var resourceIds = resources && Array.isArray(resources) && resources.map(resource => resource.id);
      // console.log(resourceIds);
      ["id", "workOrderNo", "workOrderNoSuffix", "gprocessName", "specification", "selectedGLine", "gorderGroup", "start", "end", "allDay"].forEach(item => {
        if (item === "id") {
          setValue(item, selectedEvent && selectedEvent[item] || "");
        } else if (item === "selectedGLine") {
          // let resourceIds = selectedEvent.extendedProps.resourceIds;
          // if (resourceIds && Array.isArray(resourceIds) && resourceIds.length > 0) {
          //   setValue("selectedGLine", selectedEvent.extendedProps.resourceIds[0]);
          // } else {
            // 현재의(이동했다면 이동한) 공정라인을 보여주어야 하므로 데이터베이스 기준이 아니라 캘린더 기준임
            const resources = objectEmptyCheck(selectedEvent) ? [] : selectedEvent.getResources();
            const resourceIds = resources.map(resource => resource.id);
            setValue("selectedGLine", resourceIds[0]);
          // }
        } else if (item === "gorderGroup") {
          console.log(selectedEvent)
          if (selectedEvent.id === selectedEvent.groupId) {
            setValue("gorderGroup", false);
            setCheckedGorderGroup(false);
          // } else if (selectedEvent.extendedProps.gorderId === selectedEvent.groupId) {
          } else if (selectedEvent.extendedProps.gworkOrderId.toString() === selectedEvent.groupId) {
            setValue("gorderGroup", true);
            setCheckedGorderGroup(true);
          }
        } else if (item === "start" || item === "end") {
          const date = selectedEvent[item]; // selectedEvent.extendedProps가 아님에 유의
          if (selectedEvent["allDay"] && item === "end") {
            if (date) {
              date.setDate(date.getDate()-1);
              setValue(item, date);
              // setValue(`${item}Time`, `0000`);
            } else {
              const start = selectedEvent["start"];
              setValue(item, start);
              // setValue(`${item}Time`, `0000`);
            }
          } else {
            setValue(item, date);
            // setValue(`${item}Time`, `${date?.getHours().toString().padStart(2, '0')}${date?.getMinutes().toString().padStart(2, '0')}`);
          }

        } else if (item === "allDay") {
          // value 값이 있는 form control의 경우 (react hook form) setValue("name", value)로 값이 설정되나
          // value 값이 없는 경우 setValue로 form control의 설정상태가 바뀌지 않으므로 아래와 같이 처리
          // TODO : 작성한 form control(src/components/form) 안에서 setValue 시 적용된 값을 설정하는 방법 연구 필요
          console.log(selectedEvent)
          setCheckedAllDay(selectedEvent && selectedEvent[item] || false);

          // setValue(item, selectedRow && selectedRow[item] || ""); // 값 자체가 false 인데 ||로 인해 ""로 바뀜
          // setValue(selectedRow && selectedRow[item]) // 실수로 이렇게 했었는데 path.split is not a function 발생 (첫번째 인자 오류)
          setValue(item, selectedEvent && selectedEvent[item] || false);
        } else {
          setValue(item, selectedEvent && selectedEvent.extendedProps && selectedEvent.extendedProps[item] || "");
        }
      })
      // const newSelectedGLines = glinesByGProcess.filter(gline => resourceIds.includes(gline.id.toString()));
      // setSelectedGLines(newSelectedGLines.map(gline => {
      //   return {
      //     id: gline.id,
      //     code: gline.id,
      //     name: gline.name,
      //   }
      // }));
    }, [selectedEvent]
  );

  const cancelEvent = async () => {
    handleDialogClose();
    // console.log(selectedEvent.extendedProps.gworkOrderId)
    const count = await removeByGWorkOrderIdDirect(selectedEvent.extendedProps.gworkOrderId);
    if (count > 0) {
      refresh();
    }
    // cancel(selectedEvent);
    // selectedEvent.remove();
  }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    return true;
  }

  // const handleChangeAllDay = (e) => {
  //   // TODO : form control 안에서 제어 가능한지 연구 필요
  //   setValue("allDay", !checkedAllDay)
  //   setCheckedAllDay(!checkedAllDay);
  // }
  
  const handleChangeGorderGroup = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요
    setValue("gorderGroup", !checkedGorderGroup)
    setCheckedGorderGroup(!checkedGorderGroup);
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{
          cursor: 'move',
          backgroundColor: `${selectedEvent?.extendedProps?.gprocessColor}`,
          color: `${getTextColorByBackgroundColor(selectedEvent?.extendedProps?.gprocessColor)}`,
        }}
      >
        {"일정"}
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              control={control}
              label={"의뢰번호"}
              value={`${getValues("workOrderNo")}-${getValues("workOrderNoSuffix")}`}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"gprocessName"}
              control={control}
              label={"공정명"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          {/* TODO : 전체사양이 아닌 해당 유리컴포넌트의 사양을 보여주어야 함 */}
          <Grid item xs={12}>
            <FormInputText
              name={"specification"}
              control={control}
              label={"사양"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            {/* <FormInputMultipleSelect
              id="multiple-type-select"
              name="glines"
              label={"공정라인"}
              control={control}
              setValue={setValue}
              value={selectedGLines}
              setMultiselectValue={setSelectedGLines}
              options={glinesByGProcess}
              // options={[
              //   {id: 1, code: 'code1', name: 'name1'},
              //   {id: 2, code: 'code2', name: 'name2'}
              // ]}
            /> */}
            <FormInputDropdown
              name={"selectedGLine"}
              control={control}
              label={"공정라인"}
              options={glinesByGProcess.map(gline => {
                return {
                  value: gline.id,
                  label: gline.name,
                }
              })}
              // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingLeft: '20px', border: "1px solid #C4C4C4", borderRadius: '5px' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{"해제"}</Typography>
                {/* <AntSwitch */}
                <FormInputSwitch
                  name={"gorderGroup"}
                  checked={checkedGorderGroup}
                  onChange={handleChangeGorderGroup}
                  control={control}
                  // label={checkedGorderGroup ? "그룹해제" : "그룹"}
                  label={"그룹"}
                  inputProps={{ 'aria-label': 'ant design' }}
                  // color="success"
                />
              </Stack>
            </div>
          </Grid>
          {/* 근무시간은 사용자의 복잡도에 따라 배제하기로 함. 따라서 현재로서는 종일(allDay) 이벤트 형태로만 사용. 2022-08-19 */}
          <Grid item xs={12} sx={{ display: 'none' }}>
            <div style={{ paddingLeft: '20px', border: "1px solid #C4C4C4", borderRadius: '5px' }}>
              <FormInputSwitch
                name={"allDay"}
                checked={checkedAllDay}
                // onChange={handleChangeAllDay}
                control={control}
                label={"종일"}
                // color="success"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={checkedAllDay ? 12 : 6}>
                <FormInputDate
                  name="start"
                  control={control}
                  label={"시작"}
                  onChangePeriodValue={(value) => handleChangePeriod(value, "start")}
                  value={getValues("start")}
                  setValue={setValue}
                  // onEdit={checkEdit}
                />
              </Grid>
              {/* {
                !checkedAllDay && (
                  <Grid item xs={6}>
                    <FormInputDropdown
                      name={"startTime"}
                      control={control}
                      label={"시작시간"}
                      options={dayTimes}
                      // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                    />
                  </Grid>
                )
              } */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={checkedAllDay ? 12 : 6}>
                <FormInputDate
                  name="end"
                  control={control}
                  label={"마감"}
                  onChangePeriodValue={(value) => handleChangePeriod(value, "end")}
                  value={getValues("end")}
                  setValue={setValue}
                  // onEdit={checkEdit}
                />
              </Grid>
              {/* {
                !checkedAllDay && (
                  <Grid item xs={6}>
                    <FormInputDropdown
                      name={"endTime"}
                      control={control}
                      label={"마감시간"}
                      options={dayTimes}
                      // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                    />
                  </Grid>
                )
              } */}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button>
        {
          canRemove && <Button onClick={cancelEvent}>{"삭제"}</Button>
        }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GPlanDialog;
