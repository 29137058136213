import Modal from "./Modal";
import { usePrompt } from "./usePrompt";

const NavigationGuard = ({
  when,
  message = "Are you sure to leave this page?"
}) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

  const handleClickNo = () => {
    cancelNavigation();
  };

  const handleClickYes = () => {
    confirmNavigation();
  };

  return (
    <Modal
      visible={showPrompt}
      onCancel={handleClickNo}
      onConfirm={handleClickYes}
    >
      {message}
    </Modal>
  );
};

export default NavigationGuard;
