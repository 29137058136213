/**
 * Config.js 파일
 * 
 * 로컬 => 개발자의 개발 frontend
 * 로컬 서버 => 개발자의 개발 서버(core + db)
 * 테스트 서버 => 운영 서버 배포전 동일한 형태의 테스트 서버 node env === 'development' (core + db)
 * 운영 서버 => www.04docu.co.kr 서버 node env === 'production' (core + db)
 * 
 * [ mode 설명 ]
 * 
 * # 개발용
 * dev-java-api                 => 로컬 - 자바API 테스트서버(https://121.78.79.54)
 * dev-local                    => 로컬 - 로컬 서버
 * dev-development              => 로컬 -> 시연 서버
 * dev-development-external     => 로컬 -> 시연 서버 (외부IP)
 * dev-development2              => 로컬 -> 배포 테스트 서버
 * dev-development2-external     => 로컬 ->배포 테스트 서버 (외부IP)
 * dev-production               => 로컬 -> 운영 서버 => 사용안함
 * dev-production-external      => 로컬 -> 운영 서버 (외부IP) => 사용안함
 * 
 * # 배포용 (~/frontend에서 npm run build)
 * dist-development   => 시연 서버 빌드
 * dist-development2   => 배포 테스트 서버 빌드
 * dist-production    => 운영 서버 빌드 => 사용안함
 * dist-production-idc    => 운영 서버 빌드
 * 
 * dev-development(-external)와 dev-production(-external)은 개발자의 frontend에서 직접 서버로 접속하여 테스트해 볼 용도로 사용 (frontend 개발시 서버에 배포전 바로 확인할 수 있는 장점이 있음)
 * 
 * [사용법] 
 * 해당 용도에 맞게 하나의 모드만 켜고 주석처리하여 사용한다.
 * 참고로 아래 명령어 수행시
 * react-scripts start => 자등으로 NODE_ENV === "development"
 * react-scripts build => 자등으로 NODE_ENV === "production"
 */

let development;
let production;
let mode = "";

mode = "dev-local";
// mode = "dev-development";
// mode = "dev-development-external";
// mode = "dev-development2";
// mode = "dev-development2-external";
// mode = "dev-production";
// mode = "dev-production-external";
// mode = "dev-production-idc-external";
// mode = "dist-development";
mode = "dist-development2";
// mode = "dist-production";
// mode = "dist-production-idc";
// mode = "dev-java-api";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 개발용
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (mode === "dev-java-api") {
  const url = "http://121.78.79.54";
  // const url = "http://192.168.0.30:8080";
  development = {
    authServerUrl: `${url}/auth`,
    projectServerUrl: `${url}/project`,
    gcoreServerUrl: `${url}/gcore`,
    gcoreMAServerUrl: `${url}/gcoreMA`,
    fileServerUrl: `${url}/files`,
    uploadFilePath: 'D:\\04docu\\core\\gcoreServer\\g04docu', // 개발서버의 DB에는  D:\Project\core\gcoreServer\g04docu 으로 되어 있음
    mode,
  };
} else if (mode === "dev-local") {
  const url = "http://localhost";
  development = {
    // authServerUrl: `http://192.168.0.30:8080/auth`,
    authServerUrl: `${url}:5000/auth`,
    projectServerUrl: `${url}:5001/project`,
    gcoreServerUrl: `${url}:5002/gcore`,
    gcoreMAServerUrl: `${url}:5003/gcoreMA`,
    fileServerUrl: `${url}:5002/files`,
    // fileServerUrl: `${url}:3000//g04docu`,
    uploadFilePath: 'D:\\04docu\\core\\gcoreServer\\g04docu', // 개발서버의 DB에는  D:\Project\core\gcoreServer\g04docu 으로 되어 있음
    // uploadFilePath: 'D:\\Project\\gop\\frontend\\public\\g04docu', // 개발서버의 DB에는  D:\Project\core\gcoreServer\g04docu 으로 되어 있음
    mode,
  };
} else if (mode === "dev-development") { // 내부에서 접속
  const url = "http://192.168.0.12";
  development = {
    authServerUrl: `${url}:3000/auth`,
    projectServerUrl: `${url}:3000/project`,
    gcoreServerUrl: `${url}:3000/gcore`,
    gcoreMAServerUrl: `${url}:3000/gcoreMA`,
    fileServerUrl: `${url}:3000/files`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} else if (mode === "dev-development-external") { // 외부에서 접속
  const url = "http://220.86.31.114";
  development = {
    authServerUrl: `${url}:3001/auth`,
    projectServerUrl: `${url}:3001/project`,
    gcoreServerUrl: `${url}:3001/gcore`,
    gcoreMAServerUrl: `${url}:3001/gcoreMA`,
    fileServerUrl: `${url}:3001/files`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} else if (mode === "dev-development2") { // 내부에서 접속
  const url = "http://192.168.0.71";
  development = {
    authServerUrl: `${url}:5000/auth`,
    projectServerUrl: `${url}:5001/project`,
    gcoreServerUrl: `${url}:5002/gcore`,
    gcoreMAServerUrl: `${url}:5003/gcoreMA`,
    fileServerUrl: `${url}:5004`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} else if (mode === "dev-development2-external") { // 외부에서 접속
  const url = "http://220.86.31.114";
  development = {
    authServerUrl: `${url}:3000/auth`,
    projectServerUrl: `${url}:3000/project`,
    gcoreServerUrl: `${url}:3000/gcore`,
    gcoreMAServerUrl: `${url}:3000/gcoreMA`,
    fileServerUrl: `${url}:3000/files`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} /* else if (mode === "dev-production") { // 내부에서 접속
  const url = "http://192.168.0.71";
  development = {
    authServerUrl: `${url}:5000/auth`,
    projectServerUrl: `${url}:5001/project`,
    gcoreServerUrl: `${url}:5002/gcore`,
    gcoreMAServerUrl: `${url}:5003/gcoreMA`,
    fileServerUrl: `${url}:5004`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} else if (mode === "dev-production-external") { // 외부에서 접속
  const url = "http://www.04docu.co.kr"; // www.04docu.co.kr = 220.86.31.114
  development = {
    authServerUrl: `${url}/auth`,
    projectServerUrl: `${url}/project`,
    gcoreServerUrl: `${url}/gcore`,
    gcoreMAServerUrl: `${url}/gcoreMA`,
    fileServerUrl: `${url}/files`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} else if (mode === "dev-production-idc-external") {
  const url = "http://www.04docu.co.kr";
  development = {
    authServerUrl: `${url}/auth`,
    projectServerUrl: `${url}/project`,
    gcoreServerUrl: `${url}/gcore`,
    gcoreMAServerUrl: `${url}/gcoreMA`,
    fileServerUrl: `${url}:5004`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
} */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 배포용
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
else if (mode === "dist-development" || mode === "dist-development2" || /*mode === "dist-production" || */mode === "dist-production-idc") {
  production = { // 배포용 파일을 만들면 자등으로 NODE_ENV === "production" 이므로 production 객체임에 유의
    // TODO : 테스트중
    // authServerUrl: `${url}:7000/auth`,
    // projectServerUrl: `${url}:7001/project`,
    // gcoreServerUrl: `${url}:7002/gcore`,
    // gcoreMAServerUrl: `${url}:7003/gcoreMA`,
    // fileServerUrl: `${url}:7004`,
    // uploadFilePath: '/home/warpcore/project/g04docu',
    authServerUrl: `/auth`,
    projectServerUrl: `/project`,
    gcoreServerUrl: `/gcore`,
    gcoreMAServerUrl: `/gcoreMA`,
    fileServerUrl: `/files`,
    uploadFilePath: '/home/warpcore/project/g04docu',
    mode,
  };
}

const {
  authServerUrl,
  projectServerUrl,
  gcoreServerUrl,
  gcoreMAServerUrl,
  fileServerUrl,
  uploadFilePath,
} = process.env.NODE_ENV === "development" ? development : production;

export {
  authServerUrl,
  projectServerUrl,
  gcoreServerUrl,
  gcoreMAServerUrl,
  fileServerUrl,
  uploadFilePath, // 서버에서의 파일 저장 경로로 DB에 저장되어 있다. frontend에서 문서보기할때 uploadFilePath를 fileServerUrl로 replace하여 사용
  mode, // 현재 App.js에서만 사용하고 있음 => mode에 따라 console.log 동작 중지하는 용도로 사용
}