import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const INIT_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP = "gclientG04docuGCertificationDetailMap/initGClientG04docuGCertificationDetailMap";
const SET_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP = "gclientG04docuGCertificationDetailMap/setGClientG04docuGCertificationDetailMap";

export const initGClientG04docuGCertificationDetailMap = () => ({
  type: INIT_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP,
});

const setGClientG04docuGCertificationDetailMap = (gclientG04docuGCertificationDetailMap) => ({
  type: SET_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP,
  payload: gclientG04docuGCertificationDetailMap,
});

export const create = ({ gclientId, g04docuGCertificationId, selectedClassifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationDetailMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuGCertificationId, selectedClassifications }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationDetailMap(data.gclientG04docuGCertificationDetailMap));
  return response;
};

const initialState = {
  gclientG04docuGCertificationDetailMap: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP:
      newState = Object.assign({}, state, { gclientG04docuGCertificationDetailMap: initialState.gclientG04docuGCertificationDetailMap });
      return newState;
    case SET_GCLIENT_G04_DOCU_GCERTIFICATION_DETAIL_MAP:
      newState = Object.assign({}, state, { gclientG04docuGCertificationDetailMap: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
