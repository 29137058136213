import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller } from "react-hook-form";
// import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";

const FormInputToggleButton = ({ name, control, label, size = "small", options, onCustomChange, ...props }) => {
  const generateToggleButtons = () => {
    return options && options.map((option) => {
      return (
        <ToggleButton key={option.value} value={option.value} disabled={props.disabled || false}>{option.label}</ToggleButton>
      );
    });
  };
  
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        // <TextField
        //   label={label}
        //   helperText={error ? error.message : null}
        //   size={size}
        //   error={!!error}
        //   onChange={(e) => { onChange(e); onCustomChange(e); }}
        //   value={value}
        //   fullWidth
        //   variant="outlined"
        //   {...props}
        // >
        //   {generateSingleOptions()}
        // </TextField>
        <>
          <Typography variant="subtitle2" component="div" color="primary" sx={{ mr: 1 }}>{label}</Typography>
          <ToggleButtonGroup
            color="primary"
            size={size}
            value={value}
            exclusive
            onChange={onChange}
            {...props}
          >
            {generateToggleButtons()}
            {/* <ToggleButton value="android">Android</ToggleButton> */}
          </ToggleButtonGroup>
        </>
      )}
    />
  );
};

export default FormInputToggleButton;
