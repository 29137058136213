import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GSTANDARD_TYPE = 'gstandardType/setGStandardType';
const REMOVE_GSTANDARD_TYPE = "gstandardType/removeGStandardType";
const SET_GSTANDARD_TYPES = 'gstandardType/setGStandardTypes';

const setGStandardType = (gstandardType) => ({
  type: SET_GSTANDARD_TYPE,
  payload: gstandardType,
});

const removeGStandardType = () => ({
  type: REMOVE_GSTANDARD_TYPE,
});

const setGStandardTypes = (data) => ({
  type: SET_GSTANDARD_TYPES,
  payload: data,
});

export const create = ({ id, name, code, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, comments }),
  });
  const data = await response.json();
  dispatch(setGStandardType(data.gstandardType));
  return response;
};

export const modify = ({ id, name, code, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, comments }),
  });
  const data = await response.json();
  dispatch(setGStandardType(data.gstandardType));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes`);
  const data = await response.json();
  dispatch(setGStandardTypes(data)); // data 임에 유의
  return response;
}

export const selectPaging = (queryPaging) => async dispatch => {
  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes/offset/${page}/limit/${pageSize}`);
  const data = await response.json();
  dispatch(setGStandardTypes(data));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes/${id}`);
  const data = await response.json();
  dispatch(setGStandardType(data.gstandardType));
  return response;
}

export const initGStandardType = () => async dispatch => {
  dispatch(setGStandardType(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandardTypes/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGStandardType());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gstandardTypes/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gstandardType: null,
  gstandardTypes: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSTANDARD_TYPE:
      newState = Object.assign({}, state, { gstandardType: action.payload });
      return newState;
    case REMOVE_GSTANDARD_TYPE:
      newState = Object.assign({}, state, { gstandardType: null });
      return newState;
    case SET_GSTANDARD_TYPES:
      newState = Object.assign({}, state, { gstandardTypes: action.payload.gstandardTypes, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;
