import React, { useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
} from '@mui/material';
import { Controller } from "react-hook-form";

const options = [
  {
    label: "Radio Option 1",
    value: "1"
  },
  {
    label: "Radio Option 2",
    value: "2"
  },
];

const FormInputSwitch = ({ name, control, label, checked, ...props }) => {
  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
                {...props}
              />
            }
          label={label} />
        )}
      />
    </FormControl>
  );
}

export default FormInputSwitch;