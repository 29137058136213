import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const INIT_GCOMPONENT_ITEM = 'gcomponentItem/initGComponentItem'; 
const SET_GCOMPONENT_ITEM = 'gcomponentItem/setGComponentItem';
const REMOVE_GCOMPONENT_ITEM = "gcomponentItem/removeGComponentItem";
const INIT_GCOMPONENT_ITEMS = 'gcomponentItem/initGComponentItems'; 
const SET_GCOMPONENT_ITEMS = 'gcomponentItem/setGComponentItems';
const SET_GCOMPONENT_ITEMS_BY_ITEM_TYPE = 'gcomponentItem/setGComponentItemsByItemType';
const SET_GCOMPONENT_ITEMS_NOT_MINES_BY_ITEM_TYPE = 'gcomponentItem/setGComponentItemsNotMinesByItemType';

const initGComponentItem = () => ({
  type: INIT_GCOMPONENT_ITEM,
});

const initGComponentItems = () => ({
  type: INIT_GCOMPONENT_ITEMS,
});

const setGComponentItem = (gcomponentItem) => ({
  type: SET_GCOMPONENT_ITEM,
  payload: gcomponentItem,
});

const removeGComponentItem = () => ({
  type: REMOVE_GCOMPONENT_ITEM,
});

const setGComponentItems = (gcomponentItems) => ({
  type: SET_GCOMPONENT_ITEMS,
  payload: gcomponentItems,
});

const setGComponentItemsByItemType = (gcomponentItemsByItemType) => ({
  type: SET_GCOMPONENT_ITEMS_BY_ITEM_TYPE,
  payload: gcomponentItemsByItemType,
});

const setGComponentItemsNotMinesByItemType = (gcomponentItemsNotMinesByItemType) => ({
  type: SET_GCOMPONENT_ITEMS_NOT_MINES_BY_ITEM_TYPE,
  payload: gcomponentItemsNotMinesByItemType,
});


export const create = ({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const modify = ({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, gcomponentId, /*gclientId, gclient, specification,*/ g04docuGCertificationId, comments, applyType, dependentGcomponentItem, itemType, madeBy, selectedClassifications }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const modifyApplyType = ({ id, applyType }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/applyType`, {
    method: "PUT",
    body: JSON.stringify({ id, applyType }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const modifyItemType = ({ id, itemType }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/itemType`, {
    method: "PUT",
    body: JSON.stringify({ id, itemType }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const modifyMadeBy = ({ id, madeBy }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/madeBy`, {
    method: "PUT",
    body: JSON.stringify({ id, madeBy }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const modifyGComponentItemValid = ({ id, valid }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/valid`, {
    method: "PUT",
    body: JSON.stringify({ id, valid }),
  });
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems`);
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gcomponentItems/`);
  const data = await response.json();
  return data.gcomponentItems;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/selectAll`);
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
};

export const selectAllByGcomponentId = (gcomponentId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/gcomponentId/${gcomponentId}`);
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
}

export const selectAllByGcomponentCode = (gcomponentCode) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/gcomponentCode/${gcomponentCode}`);
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
}

export const selectAllByGcomponentIdDirect = async (gcomponentId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gcomponentItems/gcomponentId/${gcomponentId}`);
  const data = await response.json();
  return data.gcomponentItems;
}

export const selectAllByGcomponentIdByQuery = (gcomponentId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/selectAllByGcomponentId/${gcomponentId}`);
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
};

export const selectAllByItemTypeByQuery = (itemType) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/selectAll/itemType/${itemType}`);
  const data = await response.json();
  dispatch(setGComponentItemsByItemType(data.gcomponentItemsByItemType));
  return response;
};

// TODO : itemType별로 검색하는 API와 다르게 여러개의 itemType을 검색하는 것이 다름.
// 실제로 GComponentItems를 원자재/가공부자재와 시공부자재를 통합하고 규격(수치값들)을 새로 부여하며 DBGrid 검색이 바뀌어 생성됨.
export const selectAllByItemTypesByQuery = (itemTypes) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/selectAll/itemTypes`, {
    method: 'POST',
    body: JSON.stringify({ itemTypes }),
  });
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
};

export const selectNotMinesByItemTypeByQuery = (gclientId, itemType) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/selectAll/notMines/gclient/${gclientId}/itemType/${itemType}`);
  const data = await response.json();
  dispatch(setGComponentItemsNotMinesByItemType(data.gcomponentItemsNotMinesByItemType));
  return response;
};
  
export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/${id}`);
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
}

export const selectByQuery = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/query/select/${id}`);
  const data = await response.json();
  dispatch(setGComponentItem(data.gcomponentItem));
  return response;
}

export const initializeGComponentItem = () => dispatch => {
  // TODO : 초기값 선택 여부 검토
  // dispatch(setGComponentItem(null));
  // dispatch(setGComponentItem(initialState.gcomponentItem));
  dispatch(initGComponentItem());
}

export const initializeGComponentItems = () => dispatch => {
  dispatch(initGComponentItems());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponentItems/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGComponentItem());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gcomponentItems/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gcomponentItem: null,
  gcomponentItems: [],
  gcomponentItemsByItemType: [],
  gcomponentItemsNotMinesByItemType: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCOMPONENT_ITEM:
      newState = Object.assign({}, state, { gcomponentItem: initialState.gcomponentItem });
      return newState;
    case SET_GCOMPONENT_ITEM:
      newState = Object.assign({}, state, { gcomponentItem: action.payload });
      return newState;
    case REMOVE_GCOMPONENT_ITEM:
      newState = Object.assign({}, state, { gcomponentItem: null });
      return newState;
    case INIT_GCOMPONENT_ITEMS:
      newState = Object.assign({}, state, { gcomponentItems: initialState.gcomponentItems });
      return newState;
    case SET_GCOMPONENT_ITEMS:
      newState = Object.assign({}, state, { gcomponentItems: action.payload });
      return newState;
    case SET_GCOMPONENT_ITEMS_BY_ITEM_TYPE:
      newState = Object.assign({}, state, { gcomponentItemsByItemType: action.payload });
      return newState;
    case SET_GCOMPONENT_ITEMS_NOT_MINES_BY_ITEM_TYPE:
      newState = Object.assign({}, state, { gcomponentItemsNotMinesByItemType: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
