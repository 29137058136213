import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import * as XLSX from "xlsx";
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  TextCell2Template,
} from "../excelgrid";
import {
  dateFormat,
  hideWatermark,
  objectEmptyCheck,
} from "../../utils";
import * as gunitActions from "../../store/gunit";
import * as gglassActions from "../../store/gglass";
import * as gprojectGlassActions from "../../store/gprojectGlass"
import {
  Delete,
  Search,
  TextsmsTwoTone
} from "@mui/icons-material";

// 아래 form components의 name과 연계
// const defaultValues = {
//   id: "",
//   name: "",
//   code: "",
//   comments: "",
// };

// const theme = createTheme();

// const rowLength = 51;
// const columnLength = 19;
// const fixedColumnLength = 11;
// const columnWidth = 88;
// const arrRow = [];

// for(let i=0; i<rowLength; i++) {
//   arrRow.push("");
// }

const gglassMap = new Map();
const columnMap = {};
const emptyColumns = {};

const GOrderExcel = ({
  initExcelDatas,
  selectedRow,
  setValue,
  getValues,
  setOpenProgress,
  focus,
}) => {
  
  const rowLength = initExcelDatas && initExcelDatas.length && initExcelDatas.length > 50 ? initExcelDatas.length+1 : 51;
  const columnLength = 19;
  const fixedColumnLength = 11;
  const columnWidth = 88;
  const arrRow = [];

  for(let i=0; i<rowLength; i++) {
    arrRow.push("");
  }

  // const gglassMap = new Map();
  // const columnMap = {};
  // const emptyColumns = {};

  const getColumns = () => {
    return arrCol.map((item, cIdx) => {
      let width = columnWidth;
      // 0. 항목, 1. 기능, 2. 품번, 3. 규격, 4. 사양, 크기(5. 가로, 6. 세로), 7. 수량, 단위면적(8. m2, 9. 평, 10. ft2), 면적(11. m2, 12. 평, 13. ft2), 단가(14. m2, 15. 평, 16. ft2), 17. 금액, 18. 비고
      if (cIdx === 0) {
        width = 60;
      } else if (cIdx === 1) {
        width = 70;
      } else if (cIdx === 2) {
        width = 120;
      } else if (cIdx === 3) {
        width = 300;
      } else if (cIdx === 4) {
        width = 300;
      } else if (cIdx === 5) {
        width = 100;
      } else if (cIdx === 6) {
        width = 100;
      } else if (cIdx === 7) {
        width = 100;
      } else if (cIdx === 8) {
        width = 100;
      } else if (cIdx === 9) {
        width = 100;
      } else if (cIdx === 10) {
        width = 100;
      } else if (cIdx === 11) {
        width = 100;
      } else if (cIdx === 12) {
        width = 100;
      } else if (cIdx === 13) {
        width = 100;
      } else if (cIdx === 14) {
        width = 100;
      } else if (cIdx === 15) {
        width = 100;
      } else if (cIdx === 16) {
        width = 100;
      } else if (cIdx === 17) {
        width = 140;
      } else if (cIdx === 18) {
        width = 300;
      }
      
      return {
        columnId: item,
        width, // TODO : 절대수치가 아닌 화면비율에 따른 상대수치 가능한지 검토
        resizable: true,
        reorderable: true,
      };
    });
  };

  // TODO : isOpen 동작 방식 정확한 파악 필요. 현재 사용안함
  const getDatas = () => {
    // console.log("getDatas")
    arrCol.forEach((item, idx) => {
      if (idx === 0) {
        columnMap[Number(item)] = `항목`;
      } else {
        columnMap[Number(item)] = `열 ${idx+1}`;
      }

      emptyColumns[Number(item)] = "";
    });

    return arrRow.map((item, idx) => {
      const columns = Object.assign({}, {...emptyColumns});
      // columns.isOpen = false;
      let firstColumn = (idx+1).toString();
      if (idx === rowLength-1) {
        firstColumn = "소계";
        // columns[0] = "소계";
      }

      columns[0] = firstColumn;
      
      return columns;
    });
  }
  
  const alignCenter = {
    justifyContent: "center"
  };

  const alignRight = {
    justifyContent: "right"
  };

  const getRows = (columnsOrder) => {
    console.log("getRows")
    // const headers = {
    //   rowId: "header",
    //   cells: [
    //     { type: "header", text: "항목", rowspan: 2, style: alignCenter },
    //     { type: "header", text: "아이디", rowspan: 2, style: alignCenter },
    //     { type: "header", text: "유리사양", rowspan: 2, style: alignCenter },
    //     { type: "header", text: "규격", colspan: 2, style: alignCenter },
    //     { type: "header", text: "" },
    //     { type: "header", text: "수량", rowspan: 2, style: alignCenter },
    //     { type: "header", text: "면적", rowspan: 2, style: alignCenter },
    //     { type: "header", text: "비고", rowspan: 2, style: alignCenter },
    //   ].concat(
    //     // slice 안의 숫자는 고정컬럼의 수
    //     arrCol.slice(fixedColumnLength).map(item => ({ type: "header", text: columnMap[columnsOrder[Number(item)]], rowspan: 2, style: alignCenter }))
    //   )
    // },
    // {
    //   rowId: "header",
    //   cells: [
    //     { type: "header", text: "" },
    //     { type: "header", text: "" },
    //     { type: "header", text: "" },
    //     { type: "header", text: "가로", style: alignCenter },
    //     { type: "header", text: "세로", style: alignCenter },
    //     { type: "header", text: "" },
    //     { type: "header", text: "" },
    //     { type: "header", text: "" },
    //   ].concat(
    //     // slice 안의 숫자는 고정컬럼의 수
    //     arrCol.slice(fixedColumnLength).map(item => ({ type: "header", text: "" }))
    //   )
    // },
    const rowsReactGrid = [
      {
        rowId: "header1",
        cells: [
          { type: "header", text: "항목", rowspan: 2, style: alignCenter },
          { type: "header", text: "기능", rowspan: 2, style: alignCenter },
          { type: "header", text: "품번", rowspan: 2, style: alignCenter },
          { type: "header", text: "규격", rowspan: 2, style: alignCenter },
          { type: "header", text: "사양", rowspan: 2, style: alignCenter },
          { type: "header", text: "크기", colspan: 2, style: alignCenter },
          { type: "header", text: "" },
          // { type: "header", text: `가로 (mm)`, style: alignCenter },
          // { type: "header", text: "세로 (mm)", style: alignCenter },
          { type: "header", text: "수량", rowspan: 2, style: alignCenter },
          { type: "header", text: "단위면적", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "면적", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "단가", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "금액", rowspan: 2, style: alignCenter },
          // { type: "header", text: areaType ? `단위면적 (${areaType})`: "단위면적 (m2)", style: alignCenter },
          // { type: "header", text: areaType ? `면적 (${areaType})`: "면적 (m2)", style: alignCenter },
          // { type: "header", text: areaType ? `단가 (원/${areaType})`: "단가 (원/m2)", style: alignCenter },
          // { type: "header", text: areaType ? `금액 (원/${areaType})`: "금액 (원/m2)", style: alignCenter },
          { type: "header", text: "비고", rowspan: 2, style: alignCenter },
        ]
        // .concat(
        //   arrCol.slice(fixedColumnLength).map(item => ({ type: "header", text: columnMap[columnsOrder[Number(item)]], style: alignCenter }))
        // ),
      },
      {
        rowId: "header2",
        cells: [
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "가로", style: alignCenter },
          { type: "header", text: "세로", style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
        ]
      },
      ...datas.map((data, idx) => {
        return {
          // rowId: data.id, // 중요
          rowId: idx, // 중요
          reorderable: true, // 중요
          height: 40, // default = 25
          cells: arrCol.map((item, cIdx) => {
            let column = {};
            // if (cIdx === 1) {
            //   column = {
            //     // type: "text",
            //     type: "dropdown",
            //     values: [
            //       { label: "aaa", value: "1" },
            //       { label: "bbb", value: "2" },
            //       { label: "ccc", value: "3" },
            //     ],
            //     // values: gglasses.map(gglass => {
            //     //   return {
            //     //     label: `${gglass.name}`,
            //     //     value: gglass.id,
            //     //   }
            //     // }),
            //     // selectedValue: "1",
            //     // inputValue: "1",
            //     isOpen: data.isOpen,
            //     // isDisabled: false,
            //     // text: data[columnsOrder[Number(item)]],
            //   };
            // } else {
              
            let customStyle = {
              border : {
                left: {},
                top: {},
                right: {},
                bottom: {},
              },
            };

            // if (idx === 0) {
            //   customStyle.border.top = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(cIdx) ? { color: "#1976d2", style: "solid", width: "2px" } : {};
            // }

            // if (idx === datas.length - 1) {
            //   customStyle.border.bottom = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(cIdx) ? { color: "#1976d2", style: "solid", width: "2px" } : {};
            //   if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(cIdx)) {
            //     customStyle.border.bottom = { color: "#1976d2", style: "solid", width: "2px" };
            //     customStyle.color = "white";
            //     customStyle.backgroundColor = "#1976d2";
            //   }

            //   if (cIdx === 2) {
            //     customStyle = Object.assign(customStyle, {
            //       justifyContent: "center",
            //     });
            //   }
            // }

            // if (cIdx === 1) {
            //   customStyle.border.left = { color: "#1976d2", style: "solid", width: "2px" }
            // }

            // if (cIdx === 10) {
            //   customStyle.border.right = { color: "#1976d2", style: "solid", width: "2px" }
            // }

            // 숫자 데이터의 경우 우측정렬
            if ([0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(cIdx)) { // TODO : 배열 변수화할 것
              customStyle = Object.assign(customStyle, alignRight);
            }

            let colspan = 0;
            if (idx === datas.length - 1) {
              // customStyle.border.bottom = { color: "#1976d2", style: "solid", width: "2px" };
              customStyle.color = "white";
              // customStyle.backgroundColor = "#1976d2";
              customStyle.backgroundColor = "#00897b";

              if (cIdx === 0) {
                colspan = 7;
                customStyle = Object.assign(customStyle, { justifyContent: "center" });
              }
            }

            if ([0, 1].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#f2f2f2" });
            }

            if ([2, 3, 4].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#fff3e0" });
            }

            if ([5, 6, 7].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e1f5fe" });
            }

            if ([8, 9, 10].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e1f5fe" });
            }

            if ([11, 12, 13].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#f1f8e9" });
            }

            if ([14, 15, 16].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e8f5e9" });
            }

            if (cIdx === 17 && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { fontWeight: "bold", color: "#00695c", backgroundColor: "#b2dfdb" });
            }

            if ([18].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#fbe9e7" });
            }

            // 기준면적 셀 색상 처리
            filledCells.forEach(filledCell => {
              const { rowIdx, colIds } = filledCell;
              colIds.forEach(colId => {
                if (rowIdx === idx && Number(colId) === cIdx) {
                  customStyle = Object.assign(customStyle, { fontWeight: "bold",  color: "#1976d2", backgroundColor: "#bbdefb" }); 
                }
              });
            });

            column = {
              // type: "text",
              type: "textCell2",
              colspan,
              nonEditable: [0, 1, /*2, 3, 4,*/ 8, 9, 10, 11, 12, 13, 17].includes(cIdx) ? true : false, // 항목번호, 계산되어지는 단위면적, 면적, 금액 non editable. 단, 단가는 수정이 가능해야 함
              // text: data[columnsOrder[Number(item)]], // TODO : columnsOrder 필요한가
              text: data[Number(item)],
              style: customStyle,
              renderer: (string) => {
                // console.log(string)
                // TODO : 숫자 데이터의 경우 소수점 처리. 원 데이터는 그대로 두고 render시에만 표시 가능?
                // TODO : 기능의 경우 여기에 react component 추가. select box, input 등
                // TODO : 추후 customCellTemplates 사용방법 강구할 것
                if (cIdx === 1) {
                  return (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        {/* <Button variant="outlined" size="small" onClick={() => handleClickFind([idx])}>{"유리"}</Button> */}
                        <IconButton
                          aria-label="search"
                          size="small"
                          sx={{
                            ':hover': {
                              bgcolor: 'primary.main', // theme.palette.primary.main
                              color: 'white',
                            },
                          }}
                          onClick={() => handleClickFind([idx])}
                        >
                          <Search />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <Button variant="outlined" size="small" onClick={() => handleClickFind([idx])}>{"삭제"}</Button> */}
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{
                            ':hover': {
                              bgcolor: 'primary.main', // theme.palette.primary.main
                              color: 'white',
                            },
                          }}
                          onClick={() => removeRow([idx])}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                } else if([5, 6, 7].includes(cIdx)) {
                  return string === "" || /*Number(string).toString()*/string.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else if([8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(cIdx)) {
                  return string === "" || Number(string).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return string;
                }
              }
            };
            // }
            return column;
          })
        }
      })
    ];

    console.log(rowsReactGrid)
    return rowsReactGrid;
  }
  
  // const handleClickTest = (e) => {
  //   e.preventDefault();
  //   alert("111")
  // }

  const [arrCol, setArrCol] = useState(Array.from({length: columnLength}, (v, i) => i.toString()));
  const [columns, setColumns] = useState(getColumns());
  const [datas, setDatas] = useState(getDatas());
  const [filledCells, setFilledCells] = useState([]);

  const [openFindDialog, setOpenFindDialog] = useState(false);
  const [loadedFind, setLoadedFind] = useState(false);
  const [showFind, setShowFind] = useState(false);
  const [selectedRowFind, setSelectedRowsFind] = useState([]);
  const [selectedRowIdsExcel, setSelectedRowIdsExcel] = useState([]);
  // const [alertInfo, setAlertInfo] = useState({ isAlert: false, message: "" });
  const [alertInfo, setAlertInfo] = useState({});
  const [areaType, setAreaType] = useState("m2");
  const [areaGUnits, setAreaGUnits] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  // const [rows, setRows] = useState([]);

  const rows = getRows(columns.map(c => c.columnId));
  
  const rowsFind = useSelector((state) => state.gprojectGlass.gprojectGlasses);

  // 데이터 관리
  const dispatch = useDispatch();

  // const selectAllGGlassesDirect = () => gglassActions.selectAllDirect()
  // const selectAllProjectGlassesByProjectAndAreaTypeByQuery = (gprojectId, areaType) => dispatch(gprojectGlassActions.selectAllByProjectAndAreaTypeByQuery(gprojectId, areaType));
  const selectAllProjectGlassesByProjectByQuery = (gprojectId) => dispatch(gprojectGlassActions.selectAllByProjectByQuery(gprojectId));
  const selectGUnitsAllByTypeDirect = (type) => gunitActions.selectAllByTypeDirect(type)

  const arrayColumn = (arr, n) => arr.map(x => x[n]);

  const applyChangesToDatas = (changes, prevData) => {
    console.log("applyChangesToDatas");
    console.log(changes);
    console.log(prevData);
    // 엑셀에서 셀 복사 후 붙여넣기의 경우 배열에 유효하지 않은 빈 셀 정보가 하나 더 붙어 있다.
    // TODO : 왜인지는...
    // const newChanges = changes.length > 1 ? changes.filter(change => change.newCell.text !== "" && change.newCell.value !== NaN) : changes;
    let newChanges = Object.assign([], changes);
    if (newChanges.length > 1 && newChanges[newChanges.length-1].columnId < newChanges[newChanges.length-2].columnId) {
      newChanges = newChanges.splice(0, newChanges.length - 1);
    }

    let newFilledCells = [].concat(filledCells);
    let copy = false;
    console.log(newChanges);
    newChanges.forEach((change) => {
      // const dataIndex = change.rowId;
      // const fieldName = change.columnId;
      // prevData[dataIndex][fieldName] = change.newCell.text;
      const dataRowId = change.rowId;
      const fieldName = change.columnId;

      // 프로젝트에 등록된 유리제품의 품번, 사양 표시 컬럼의 셀들은 임의로 수정할 수 없고, 컬럼 복사시 아이디, 사양 두개를 동시에 복사해야만 함
      let columnIds = [];
      let rowIds = [];
      let newColumnIds = [];
      let newRowIds = [];
      if ([2, 3, 4].includes(Number(fieldName))) {
        if (/*changes*/newChanges.length === 1 || newChanges.length === 2) {
          return;
        } else {
          columnIds = /*changes*/newChanges.map(change => change.columnId);
          rowIds = /*changes*/newChanges.map(change => change.rowId);
          newColumnIds = [...new Set(columnIds)]; // 중복제거
          newRowIds = [...new Set(rowIds)]; // 중복제거
          if (newColumnIds.length === 2) {
            return;
          }
        }
      } else {
        columnIds = /*changes*/newChanges.map(change => change.columnId);
        newColumnIds = [...new Set(columnIds)]; // 중복제거
      }

      // 품번과 규격, 유리사양 중 하나만 다른 컬럼과 선택된 상태에서 복사하면 복사되지 않도록 한다. 품번과 규격, 유리사양이 선택되어있다면 셋 다 선택되어 있어야 함을 의미
      if (newColumnIds.findIndex(e => e === "2" || e === "3" || e === "4") >= 0 && newColumnIds.join(",").indexOf("2,3,4") < 0) {
        return;
      }
      
      // prevData.find의 리턴객체인 dataRow를 수정하면 prevData에 적용됨. gglassMap도 영향받는 것으로 보임
      let dataRow = prevData.find((d, idx) => idx === dataRowId);
      // console.log(dataRow);
      // if (!dataRow) {
      //   dataRow = getEmptyDataRow();
      //   prevData.push(dataRow);
      // }
      if ((change.type === "text" || change.type === "textCell2") && typeof dataRow[fieldName] === "string") {
        // dataRow[fieldName] = change.newCell.text.replace(",", "").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        
        if ([5, 6, 7, 14, 15, 16].includes(Number(fieldName))) {
          dataRow[fieldName] = change.newCell.text.replace(",", "");
        } else {
          dataRow[fieldName] = change.newCell.text;
        }

        // TODO : 현재 복사되어 있는 값에 대한 gglassDetails 정보를 알지 못하므로 fieldName이 2인 항목값에 대한 데이터 구조(map)를 만들어놓아야 한다.
        // 데이터 구조를 만드는 시점은 로딩될 때 또는 유리 샘플 찾기할 때...
        if (fieldName === "2") {
          dataRow["gglassDetails"] = gglassMap.get(dataRow[fieldName]);

          // newColumnIds 상태로 복사시 선택한 컬럼을 파악할 수 있다.
          if (/*newColumnIds.length === 2 && */newColumnIds.join(",").indexOf("2,3,4") >= 0) { // 품번과 규격, 유리사양만 선택되어 복사된 경우
            console.log(gglassMap);
            console.log(dataRow["gglassDetails"])
            console.log(newColumnIds)
            
            const orderAreaType = dataRow?.gglassDetails?.orderAreaType;
            let colIds = [];
            if (orderAreaType === "m2") {
              // dataRow[14] = Number(dataRow["gglassDetails"].orderPriceMeter).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              dataRow[14] = Number(dataRow["gglassDetails"].orderPrice).toString();
              dataRow[15] = Number(dataRow["gglassDetails"].orderPriceJa).toString();
              dataRow[16] = Number(dataRow["gglassDetails"].orderPriceFeet).toString();
              colIds = ["8", "11", "14"];
            } else if (orderAreaType === "평") {
              dataRow[14] = Number(dataRow["gglassDetails"].orderPriceMeter).toString();
              dataRow[15] = Number(dataRow["gglassDetails"].orderPrice).toString();
              dataRow[16] = Number(dataRow["gglassDetails"].orderPriceFeet).toString();
              colIds = ["9", "12", "15"];
            } else if (orderAreaType === "ft2") {
              dataRow[14] = Number(dataRow["gglassDetails"].orderPriceMeter).toString();
              dataRow[15] = Number(dataRow["gglassDetails"].orderPriceJa).toString();
              dataRow[16] = Number(dataRow["gglassDetails"].orderPrice).toString();
              colIds = ["10", "13", "16"];
            } else { // dataRow?.gglassDetails?.orderAreaType가 없는 경우는 삭제임
              dataRow[14] = "0";
              dataRow[15] = "0";
              dataRow[16] = "0";
              dataRow[17] = "0";
            }
            
            // TODO : copy 논리 재점검할 것
            copy = true;
            newFilledCells.push({
              rowIdx: dataRowId, colIds,
            });
          }
        }

        // TODO : 추후 아래 부분에서 컬럼이 변경되었을 때 영향을 주는 부분만 정확히 재계산하도록 할지 검토할 것
        // 5. 가로, 6. 세로, 7. 수량, 14/15/16. 단가
        if ([2, 3, 4, 5, 6, 7, 14, 15, 16].includes(Number(fieldName))) {
          const mmToMeter = 1000*1000;
          const meterToJa = 1000/303*1000/303;
          const meterToFeet = 1000/304.8*1000/304.8;
          
          const width = dataRow[5];
          const height = dataRow[6];
          const amount = dataRow[7];
          
          const areaUnitMM = Number(width)*Number(height);
          const areaUnitM = areaUnitMM/mmToMeter;
          const areaUnitJa = areaUnitM*meterToJa;
          const areaUnitFeet = areaUnitM*meterToFeet;

          // 단위면적. m2 기준으로부터 변환
          dataRow[8] = areaUnitM.toString();
          dataRow[9] = areaUnitJa.toString();
          dataRow[10] = areaUnitFeet.toString();
          
          const areaM = areaUnitM * Number(amount);
          const areaJa = areaM*meterToJa;
          const areaFeet = areaM*meterToFeet;

          // 총면적 = 단위면적 * 수량. m2 기준으로부터 변환
          dataRow[11] = areaM.toString();
          dataRow[12] = areaJa.toString();
          dataRow[13] = areaFeet.toString();

          // 단가 변경시 면적기준별 단가 재산출
          if ([14, 15, 16].includes(Number(fieldName))) {
            let colIds = [];
            if (Number(fieldName) === 14) {
              const orderPriceMeter = Number(dataRow[14]);
              const orderPriceJa = orderPriceMeter/meterToJa;
              const orderPriceFeet = orderPriceMeter/meterToFeet;

              dataRow[15] = orderPriceJa.toString();
              dataRow[16] = orderPriceFeet.toString();

              // console.log(gglassMap)
              // console.log(dataRow)
              // TODO : 아래 동작 후 gglassMap에 변화가 있는데 그 원인을 아직 모르겠음. 어쨋든 그 변화로 인해 gglassMap 갱신없이 정상사용하고는 있음
              if (dataRow && dataRow.gglassDetails) {
                dataRow.gglassDetails.orderAreaType = "m2";
                dataRow.gglassDetails.orderPrice = orderPriceMeter.toString();
                dataRow.gglassDetails.orderPriceMeter = orderPriceMeter.toString();
                dataRow.gglassDetails.orderPriceJa = orderPriceJa.toString();
                dataRow.gglassDetails.orderPriceFeet = orderPriceFeet.toString();
              }
              colIds = ["8", "11", "14"];
            } else if (Number(fieldName) === 15) {
              const orderPriceJa = Number(dataRow[15]);
              const orderPriceMeter = orderPriceJa*meterToJa;
              const orderPriceFeet = orderPriceMeter/meterToFeet;

              dataRow[14] = orderPriceMeter.toString();
              dataRow[16] = (orderPriceMeter/meterToFeet).toString();

              if (dataRow && dataRow.gglassDetails) {
                dataRow.gglassDetails.orderAreaType = "평";
                dataRow.gglassDetails.orderPrice = orderPriceJa.toString();
                dataRow.gglassDetails.orderPriceMeter = orderPriceMeter.toString();
                dataRow.gglassDetails.orderPriceJa = orderPriceJa.toString();
                dataRow.gglassDetails.orderPriceFeet = orderPriceFeet.toString();
              }

              colIds = ["9", "12", "15"];
            } else if (Number(fieldName) === 16) {
              const orderPriceFeet = Number(dataRow[16]);
              const orderPriceMeter = orderPriceFeet*meterToFeet;
              const orderPriceJa = orderPriceMeter/meterToJa;

              dataRow[14] = orderPriceMeter.toString();
              dataRow[15] = orderPriceJa.toString();

              if (dataRow && dataRow.gglassDetails) {
                dataRow.gglassDetails.orderAreaType = "ft2";
                dataRow.gglassDetails.orderPrice = orderPriceFeet.toString();
                dataRow.gglassDetails.orderPriceMeter = orderPriceMeter.toString();
                dataRow.gglassDetails.orderPriceJa = orderPriceJa.toString();
                dataRow.gglassDetails.orderPriceFeet = orderPriceFeet.toString();
              }

              colIds = ["10", "13", "16"];
            }

            if (dataRow && dataRow.gglassDetails) {
              // 기준면적 셀 색상 설정
              newFilledCells = changeUnitAreaCellColor(colIds, dataRowId);
              if (newFilledCells.length > 0) {
                setFilledCells(newFilledCells);
              }
            }
          }

          // 금액
          const orderAreaType = dataRow.gglassDetails?.orderAreaType;
          if (orderAreaType === "m2") {
            dataRow[17] = (/*Number(dataRow["gglassDetails"]["orderPrice"])*/Number(dataRow[14]) * Number(dataRow[11])).toString();
          } else if (orderAreaType === "평") {
            dataRow[17] = (Number(dataRow[15]) * Number(dataRow[12])).toString();
          } else if (orderAreaType === "ft2") {
            dataRow[17] = (Number(dataRow[16]) * Number(dataRow[13])).toString();
          }
          
          // 수량 합, 총면적 합, 금액 합
          const sumAmountArr = arrayColumn(prevData, 7).slice(0, -1); // slice(0, -1)은 마지막 소계행 제외 합산이기 때문에
          const sumAreaArr = arrayColumn(prevData, 11).slice(0, -1);
          const sumPrice = arrayColumn(prevData, 17).slice(0, -1);

          const initialValue = 0;
          // 수량 합
          const sumAmount = sumAmountArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
          prevData[datas.length-1][7] = sumAmount.toString();

          // m2 기준으로부터 변환
          const sumAreaMeter = sumAreaArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
          const sumAreaJa = sumAreaMeter*meterToJa;
          const sumAreaFeet = sumAreaMeter*meterToFeet;

          // 총면적 합
          prevData[datas.length-1][11] = sumAreaMeter.toString();
          prevData[datas.length-1][12] = sumAreaJa.toString();
          prevData[datas.length-1][13] = sumAreaFeet.toString();

          // 금액 합
          const sumOrderPriceTotal = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
          prevData[datas.length-1][17] = sumOrderPriceTotal.toString();

          setValue("sumAmount", sumAmount);
          setValue("sumAreaMeter", sumAreaMeter);
          setValue("sumAreaJa", sumAreaJa);
          setValue("sumAreaFeet", sumAreaFeet);
          // sumContractPriceTotal // 서버에서 계산
          setValue("sumOrderPriceTotal", sumOrderPriceTotal);
        }
      } else if (
        change.type === "number" && typeof dataRow[fieldName] === "number") {
        dataRow[fieldName] = change.newCell.value;
      } else if (
        change.type === "checkbox" && typeof dataRow[fieldName] === "boolean") {
        dataRow[fieldName] = change.newCell.checked;
      } else if (change.type === "dropdown") {
        dataRow[fieldName] = change.newCell.inputValue;
        // CHANGED: set the isOpen property to the value received.
        // dataRow.isOpen = change.newCell.isOpen;
        dataRow.isOpen = change.newCell.isOpen;
      } else {
        console.log("ERROR", change.type, dataRow[fieldName]);
      }
    });

    // if (newFilledCells.length > 0) {
    //   setFilledCells(newFilledCells);
    // }
    // TODO : copy 논리 재점검할 것
    if (copy && newFilledCells.length > 0) {
      setFilledCells(newFilledCells);
    }

    return [...prevData];
  };

  const reorderArray = (arr, idxs, to, mode) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    const targetIdx = Math.min(...idxs) < to ? to += 1 : to -= idxs.filter(idx => idx < to).length;
    const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
    const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));

    if (mode === "col") {
      return [...leftSide, ...movedElements, ...rightSide];
    } else { // row
      return [...leftSide, ...movedElements, ...rightSide].map((item, idx) => {
        item[0] = (idx+1).toString();
        return item;
      })
    }
  }

  // const handleColumnsReorder = (targetColumnId, columnIds) => {
  //   const to = columns.findIndex((column) => column.columnId === targetColumnId);
  //   const columnIdxs = columnIds.map((columnId) => columns.findIndex((c) => c.columnId === columnId));
  //   setColumns(prevColumns => reorderArray(prevColumns, columnIdxs, to, "col"));
  // }

  const handleRowsReorder = (targetRowId, rowIds) => {
    setDatas((prevData) => {
      // const to = datas.findIndex(data => data.id === targetRowId);
      // const rowsIds = rowIds.map((id) => datas.findIndex(data => data.id === id));
      const to = datas.findIndex((data, idx) => idx === targetRowId);
      const rowsIds = rowIds.map((id) => datas.findIndex((data, idx) => idx === id));
      return reorderArray(prevData, rowsIds, to, "row");
    });
  }

  const handleChanges = (changes) => {
    setDatas((prevData) => applyChangesToDatas(changes, prevData)); 
  }; 

  const handleColumnResize = (ci, width) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  }

  const insertColumn = (selectedColIds) => {
    setArrCol(prevArrCol => prevArrCol.concat([prevArrCol.length.toString()]));
    setColumns(prevColumns => prevColumns.concat([{ columnId: prevColumns.length.toString(), width: prevColumns[selectedColIds[0]].width, resizable: true, reorderable: true, }]));
    setDatas(prevData => {
      const data = Object.assign([], prevData);
      
      return data.map((item, idx) => {
        const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
        // key의 타입이 string 임에 유의
        for (const [key, value] of Object.entries(item).reverse()) {
          if (Number(key) >= 8) {
            newItem[Number(key)+1] = value;
          }
        }
        newItem[selectedColIds[0]] = "";
        return newItem;
      });
    });
  }
  
  const removeColumn = (selectedColIds) => {
    setArrCol(prevArrCol => prevArrCol.slice(0, prevArrCol.length-1));
    setColumns(prevColumns => prevColumns.slice(0, prevColumns.length-1));
    setDatas(prevData => {
      const data = Object.assign([], prevData);
      
      return data.map((item, idx) => {
        const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
        // newItem[selectedColIds[0]] = "";
        let lastKey = 0;
        for (const [key, value] of Object.entries(item)) {
          // key의 타입이 string 임에 유의
          if (Number(key) - 1 > 7) {
            if (selectedColIds[0] < Number(key)) {
              newItem[Number(key)-1] = value;
              lastKey = key;
            }
          }
        }
        
        newItem[Number(lastKey)] = "";
        return newItem;
      });
    })
  }

  const orderBySelectedColumn = (selectedColIds, mode) => {
    let direction = mode === "asc" ? -1 : 1;
    
    const isNumber = [3, 4, 5, 6].includes(Number(selectedColIds[0]));
    
    setDatas(prevData => {
      const data = Object.assign([], prevData);
      const result = data.sort((a, b) => {
        let prev = a[selectedColIds[0]].toLowerCase();
        let next = b[selectedColIds[0]].toLowerCase();
        
        // 공백이 있을 경우 아래로 배치
        if (prev === "" && next !== "") {
          return 1;
        }
        
        if (prev !== "" && next === "") {
          return 1;
        }

        if (isNumber) {
          prev = Number(prev.replace(",", ""));
          next = Number(next.replace(",", ""));
        }

        if (prev < next) {
          return direction;
        } else if (prev > next) {
          return -direction;
        } else {
          return 0;
        }
      });
      
      return result.map((item, idx) => {
        const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
        newItem[0] = (idx+1).toString();
        return newItem;
      });
    })
  }

  const removeRow = (delRowId) => {
    // 기준면적 셀 배경색상 삭제
    // const newFilledCells = filledCells.map(filledCell => {
    //   const { rowIdx } = filledCell;
    //   if (delRowId.includes(rowIdx)) {
    //     return {
    //       rowIdx,
    //       colIds: [],
    //     }  
    //   } else {
    //     return filledCell;
    //   }
    // });
    // setFilledCells(newFilledCells);
    const newFilledCells = filledCells.filter(filledCell => {
      const { rowIdx } = filledCell;
      return !delRowId.includes(rowIdx);
    });
    
    setFilledCells(newFilledCells.map((filledCell, idx) => {
      return {
        ...filledCell,
        rowIdx: idx,
      }
    }));

    // 행삭제
    setDatas(prevData => {
      const newData = [...prevData.filter((data, idx) => !delRowId.includes(idx))];

      // 수량 합, 총면적 합, 금액 합
      const sumAmountArr = arrayColumn(newData, 7).slice(0, -1); // slice(0, -1)은 마지막 소계행 제외 합산이기 때문에
      const sumAreaArr = arrayColumn(newData, 11).slice(0, -1);
      const sumPrice = arrayColumn(newData, 17).slice(0, -1);

      const initialValue = 0;
      // 수량 합
      const sumAmount = sumAmountArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);

      // m2 기준으로부터 변환
      // const mmToMeter = 1000*1000;
      const meterToJa = 1000/303*1000/303;
      const meterToFeet = 1000/304.8*1000/304.8;

      const sumAreaMeter = sumAreaArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
      const sumAreaJa = sumAreaMeter*meterToJa;
      const sumAreaFeet = sumAreaMeter*meterToFeet;

      // 금액 합
      const sumOrderPriceTotal = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);

      setValue("sumAmount", sumAmount);
      setValue("sumAreaMeter", sumAreaMeter);
      setValue("sumAreaJa", sumAreaJa);
      setValue("sumAreaFeet", sumAreaFeet);
      // sumContractPriceTotal // 서버에서 계산
      setValue("sumOrderPriceTotal", sumOrderPriceTotal);

      return newData.map((item, idx) => {
        // const newItem = item;
        const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
        let firstColumn = (idx+1).toString();
        if (idx === newData.length -1 ) {

          newItem[7] = sumAmount.toString(); // 수량 합

          // 총면적 합
          newItem[11] = sumAreaMeter.toString();
          newItem[12] = sumAreaJa.toString();
          newItem[13] = sumAreaFeet.toString();

          newItem[17] = sumOrderPriceTotal.toString(); // 금액 합

          firstColumn = "소계";
        }
        newItem[0] = firstColumn;
        return newItem;
      });
      // return newData;
    })
  }

  const simpleHandleContextMenu = (
    selectedRowIds,
    selectedColIds,
    selectionMode,
    menuOptions
  ) => {
    // setSelectedRowIdsExcel(selectedRowIds);
    // console.log("simpleHandleContextMenu")
    // console.log(`selectionMode: ${selectionMode}, selectedRowIds: ${selectedRowIds.join(",")}, selectedColIds: ${selectedColIds.join(",")}`);
    const defaultMenuOptions = [...menuOptions].map(option => {
      return option;
    });
    
    if (selectionMode === "row") {
      menuOptions = [
        ...defaultMenuOptions,
        {
          id: "insertRow",
          label: "행추가",
          handler: () => {
            setDatas(prevData => {
              let arr = []; // 전체
              let arr1 = []; // 연속된 인덱스 그룹
              let prev = 0;
              selectedRowIds.forEach((item, idx) => {
                if (idx === 0) {
                  arr1.push(item);
                } else {
                  if (item - prev === 1) {
                    arr1.push(item);
                  } else {
                    arr.push(arr1);
                    arr1 = [item];
                  }
                }

                prev = item;
              });

              arr.push(arr1);
              
              // 선택한 행 바로 위에 새로운 행을 추가하되 행이 여러개 선택되었을 경우 행 개수만큼 추가
              // 단, 연속된 행을 그룹으로 묶고 그 단위로 위의 동작을 수행
              const data = Object.assign([], prevData);
              let prevLength = 0;
              arr.forEach(item => {
                item.forEach(i => data.splice(item[0]+prevLength, 0, emptyColumns));
                prevLength = item.length;
              })
              
              return data.map((item, idx) => {
                const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
                let firstColumn = (idx+1).toString();
                if (idx === data.length -1 ) {
                  firstColumn = "소계";
                }
                newItem[0] = firstColumn;
                return newItem;
              });
            })
          }
        },
        {
          id: "removeRow",
          label: "행삭제",
          handler: () => {
            removeRow(selectedRowIds);
          }
        },
        {
          id: "findGlass",
          label: "유리제품 찾기",
          handler: () => {
            handleClickFind(selectedRowIds);
          }
        }
      ];
    } else if (selectionMode === "column") {
      if (selectedColIds.length === 1 && selectedColIds) {
        const orderOptions = [
          {
            id: "orderAsc",
            label: "정렬(오름차순)",
            handler: () => {
              orderBySelectedColumn(selectedColIds, "asc");
            }
          },
          {
            id: "orderDesc",
            label: "정렬(내림차순)",
            handler: () => {
              orderBySelectedColumn(selectedColIds, "desc");
            }
          },
        ];

        const insertColOptions = [
          {
            id: "insertColumn",
            label: "열추가",
            handler: () => {
              insertColumn(selectedColIds);
            }
          },
          {
            id: "removeColumn",
            label: "열삭제",
            handler: () => {
              removeColumn(selectedColIds);
            }
          },
        ];

        if (selectedColIds[0] < 8) {
          menuOptions = [
            ...defaultMenuOptions,
            ...orderOptions,
          ];
        } else {
          menuOptions = [
            ...defaultMenuOptions,
            ...orderOptions,
            ...insertColOptions,
          ];
        }
        
      }
    }
    return menuOptions;
  }

  // const FileUpload = (props) => {
  //   const fileInputInfo = useRef(null);

  //   const handleClick = (e) => {
  //     fileInputInfo.current.click();
  //   }

  //   const handleChangeUpload = (e) => {
  //     props.handleFile(e);
  //   }

  //   return (
  //     <>
  //       <Button
  //         variant="contained"
  //         sx={{ mt: 3, mb: 2 }}
  //         onClick={(e) => handleClick(e)}
  //       >
  //         {props.text}
  //       </Button>
  //       <input
  //         type="file"
  //         id={props.id}
  //         accep={props.extension}
  //         ref={fileInputInfo}
  //         style={{ display: 'none' }}
  //         onChange={(e) => handleChangeUpload(e)}
  //       />
  //     </>
  //   )
  // }

  // const handleUploadFile = (event) => {
  //     const filesInfo = {
  //       [event.target.id]: event.target.files[0],
  //     };

  //     // console.log(filesInfo.hasOwnProperty("excelImport"));
  //     let result = new Array();
  //     if (filesInfo.hasOwnProperty("excelImport")) {
  //       const reader = new FileReader();
  //       const files = filesInfo["excelImport"];
  //       reader.onload = (e) => {
  //         let data = e.target.result;
  //         const wb = XLSX.read(data, { type: "binary" });
  //         const wsName = wb.SheetNames[0];
  //         let worksheet = wb.Sheets[wsName];
  //         let row;
  //         let rowNum;
  //         let colNum;
  //         let range = XLSX.utils.decode_range(worksheet["!ref"]);
          
  //         // TODO : 아래 경고는 시간이 지나면 사라지도록 구현할 것
  //         if (range.e.r-range.s.r+1 > rowLength || range.e.c-range.s.c+1 > columnLength) {
  //           setAlertInfo({
  //             isAlert: true,
  //             message: `엑셀의 행수가 최대행수 ${rowLength} 또는 최대열수 ${columnLength} 를 초과했습니다.`,
  //           });
  //           return;
  //         }

  //         for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
  //           row = [];
  //           for(colNum = range.s.c; colNum <= range.e.c; colNum++) {
  //             var nextCell = worksheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
  //             if (typeof nextCell === "undefined") {
  //               row.push(void 0);
  //             } else {
  //               row.push(nextCell.w);
  //             }
  //           }
  //           result.push(row);
  //         }

  //         console.log(result); // Array in Array
  //         // setDatas(result);
  //         console.log(datas); // Object in Array
  //         const newDatas = Object.assign([], datas);
  //         result.forEach((row, rIdx) => {
  //           row.forEach((col, cIdx) => {
  //             newDatas[rIdx][cIdx+fixedColumnLength] = result[rIdx][cIdx] ? result[rIdx][cIdx] : "";
  //           })
  //         });
  //         console.log("~~~~~~~~")
  //         console.log(newDatas);
  //         setDatas(newDatas);
  //       }
  //       reader.readAsBinaryString(files);
  //     }
  // }

  const handleFindDialogClose = () => {
    setLoadedFind(false);
    setOpenFindDialog(false);
  }

  // 기준면적 셀 색상 설정
  const changeUnitAreaCellColor = (colIds, rowId) => {
    let newFilledCells = [];
    const arr = filledCells.filter(filledCell => filledCell.rowIdx === rowId);
    if (arr.length > 0 ) {
      newFilledCells = filledCells.map(filledCell => {
        const { rowIdx } = filledCell;
        if (rowIdx === rowId) {
          return {
            rowIdx,
            colIds,
          }  
        } else {
          return filledCell;
        }
      });
    } else {
      newFilledCells = [].concat(filledCells);
      newFilledCells.push({
        rowIdx: rowId, colIds,
      })
    }

    return newFilledCells;
  }
  
  const handleSelectFind = () => {
    console.log(selectedRowFind);
    const rIdx = selectedRowIdsExcel[0];

    let newFilledCells = [];
    // console.log(newFilledCells)

    setDatas(prevData => {
      const newData = Object.assign([], prevData);
      const { orderAreaType, no, standard, specification, orderPrice, orderPriceMeter, orderPriceJa, orderPriceFeet } = selectedRowFind.row;

      newData[rIdx][2] = no;
      newData[rIdx][3] = standard;
      newData[rIdx][4] = specification;

      let colIds = [];
      // 단가 14, 15, 16 및 기준면적 셀 색상 설정
      if (orderAreaType === "m2") {
        newData[rIdx][14] = orderPrice; // or orderPriceMeter
        newData[rIdx][15] = orderPriceJa;
        newData[rIdx][16] = orderPriceFeet;
        newData[rIdx][17] = (Number(newData[rIdx][11])*Number(newData[rIdx][14])).toString();

        colIds = ["8", "11", "14"];
      } else if (orderAreaType === "평") {
        newData[rIdx][14] = orderPriceMeter;
        newData[rIdx][15] = orderPrice; // or orderPriceJa
        newData[rIdx][16] = orderPriceFeet;
        newData[rIdx][17] = (Number(newData[rIdx][12])*Number(newData[rIdx][15])).toString();

        colIds = ["9", "12", "15"];
      } else if (orderAreaType === "ft2") {
        newData[rIdx][14] = orderPriceMeter;
        newData[rIdx][15] = orderPriceJa;
        newData[rIdx][16] = orderPrice; // or orderPriceFeet
        newData[rIdx][17] = (Number(newData[rIdx][13])*Number(newData[rIdx][16])).toString();

        colIds = ["10", "13", "16"];
      }
      
      // 기준면적 셀 색상 설정
      newFilledCells = changeUnitAreaCellColor(colIds, rIdx);
      // const arr = filledCells.filter(filledCell => filledCell.rowIdx === rIdx);
      // if (arr.length > 0 ) {
      //   newFilledCells = filledCells.map(filledCell => {
      //     const { rowIdx } = filledCell;
      //     if (rowIdx === rIdx) {
      //       return {
      //         rowIdx,
      //         colIds,
      //       }  
      //     } else {
      //       return filledCell;
      //     }
      //   });
      // } else {
      //   newFilledCells = [].concat(filledCells);
      //   newFilledCells.push({
      //     rowIdx: rIdx, colIds,
      //   })
      // }

      const sumPrice = arrayColumn(newData, 17).slice(0, -1);
      const initialValue = 0;
      newData[datas.length-1][17] = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toString();
      // selectedRowFind.row.gprojectGlassId = selectedRowFind.row.id;
      newData[rIdx]["gglassDetails"] = selectedRowFind.row; // TODO : 없는 키를 삽입. 문제없는지 추가 검토 필요
      
      return newData;
    });
    
    if (newFilledCells.length > 0) {
      setFilledCells(newFilledCells);
    }
    // gglassMap.set(selectedRowFind.row.id, selectedRowFind.row);
    gglassMap.set(selectedRowFind.row.no, selectedRowFind.row);
    console.log(gglassMap)
    setOpenFindDialog(false);
  }

  const handleClickFind = async (selectedRowIds) => {
    // console.log(selectedRowIds)
    const gprojectId = getValues("gprojectId");
    if (gprojectId) {
      await selectAllProjectGlassesByProjectByQuery(gprojectId);
          
      setOpenFindDialog(true);

      setTimeout(async () => {
        await hideWatermark(); // 임시
        setShowFind(true);
        setSelectedRowIdsExcel(selectedRowIds);
        setTimeout(() => setLoadedFind(true), 300);
      }, 300);
    } else { // 선택된 프로젝트가 없으면 알림 처리
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "먼저 프로젝트를 선택하세요.",
        open: true,
      });

      focus(); // 프로젝트 선택박스로 포커스 이동할 것
    }
  }

  // useEffect(
  //   async () => {
  //     if (openFindDialog) {
  //       setShowFind(true);
  //       // setSelectedRowIdsExcel(selectedRowIds);
  //       setTimeout(() => setLoadedFind(true), 300);
  //     }
  //   }, [openFindDialog]
  // )

  const columnsFind = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 100,
    // },
    // {
    //   field: 'gtypeName',
    //   headerName: '템플릿 구조',
    //   width: 140,
    //   // editable: true,
    // },
    {
      field: 'no',
      headerName: '품번',
      width: 100,
    },
    {
      field: 'name',
      headerName: '품명',
      width: 160,
      // editable: true,
    },
    // {
    //   field: 'code',
    //   headerName: '코드',
    //   width: 100,
    //   // editable: true,
    // },
    {
      field: 'standard',
      headerName: '규격',
      width: 200,
      // editable: true,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
      // editable: true,
    },
    {
      field: 'contractAreaType',
      headerName: '수주면적단위',
      width: 120,
      // editable: true,
    },
    {
      field: 'orderAreaType',
      headerName: '발주면적단위',
      width: 120,
      // editable: true,
    },
    {
      field: 'contractPriceMeter',
      headerName: '수주단가(m2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value) // sequelize DataType DECIMAL의 경우 string을 리턴하므로 천단위 콤마를 사용하려면 숫자형으로 변환해주어야 한다.
    },
    {
      field: 'orderPriceMeter',
      headerName: '발주단가(m2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'contractPriceJa',
      headerName: '수주단가(평)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'orderPriceJa',
      headerName: '발주단가(평)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'contractPriceFeet',
      headerName: '수주단가(ft2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'orderPriceFeet',
      headerName: '발주단가(ft2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'otherSpecs',
      headerName: '비고',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  // const handleChangeAreaGUnit = (e, newAreaType) => {
  //   e.stopPropagation();
  //   // alert(areaType)

  //   // TODO : 단위면적, 면적, 단가, 금액 환산. 편집중일 수도 있으므로 데이터베이스에서 조회는 안됨
  //   // TODO : 금액 및 단위환산 계산식이 여기저기 중복된 점이 있으므로 모듈화할 것
  //   // console.log(datas)
  //   const newDatas = datas.map(data => {
  //     // 3: 가로, 4: 세로, 5: 수량, 6: 단위면적, 7: 면적, 8: 단가, 9: 금액
  //     const newData = Object.assign({}, data);

  //     const mmToMeter = 1000*1000;
  //     const meterToJa = 1000/303*1000/303;
  //     const meterToFeet = 1000/304.8*1000/304.8;

  //     if (data[3] !== "" && data[4] !== "") {
  //       if (newAreaType === "m2") {
  //         newData[6] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //         newData[7] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter*Number(data[5].replace(",", ""))).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //       } else if (newAreaType === "평") {
  //         newData[6] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter*meterToJa).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //         newData[7] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter*meterToJa*Number(data[5].replace(",", ""))).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //       } else if (newAreaType === "ft2") {
  //         newData[6] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter*meterToFeet).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //         newData[7] = (Number(data[3].replace(",", ""))*Number(data[4].replace(",", ""))/mmToMeter*meterToFeet*Number(data[5].replace(",", ""))).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //       }
  //     }

  //     // 화면상에서 변환하지 않고 저장된 값을 활용하기로 결정. 이유는 변환 중 데이터의 값이 소수점 아래에서 변하기 때문
  //     if (data[1] !== "" && data[2] !== "") {
  //       console.log(data["gglassDetails"])
  //       if (newAreaType === "m2") {
  //         newData[8] = data["gglassDetails"].orderPriceMeter/*.toString()*/.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");;
  //       } else if (newAreaType === "평") {
  //         newData[8] = data["gglassDetails"].orderPriceJa/*.toString()*/.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");;
  //       } else if (newAreaType === "ft2") {
  //         newData[8] = data["gglassDetails"].orderPriceFeet/*.toString()*/.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");;
  //       }

  //     //   const toConvertType = areaType;
  //     //   const convertedType = newAreaType;
  //     //   const priceToConvert = Number(newData[8].replace(",", ""));
  //     //   // m2, 평, ft2
  //     //   if (toConvertType === convertedType) {
  //     //     newData[8] = priceToConvert.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //   } else {
  //     //     let priceMeter = "";
  //     //     const rule = `${toConvertType}-${convertedType}`;
  //     //     switch (rule) {
  //     //       case 'm2-평':
  //     //         newData[8] = (priceToConvert/meterToJa).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       case 'm2-ft2':
  //     //         newData[8] = (priceToConvert/meterToFeet).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       case '평-m2':
  //     //         newData[8] = (priceToConvert*meterToJa).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       case '평-ft2':
  //     //         const priceMeter1 = priceToConvert*meterToJa;
  //     //         newData[8] = (priceMeter1/meterToFeet).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       case 'ft2-m2':
  //     //         newData[8] = (priceToConvert*meterToFeet).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       case 'ft2-평':
  //     //         const priceMeter2 = priceToConvert*meterToFeet;
  //     //         newData[8] = (priceMeter2/meterToJa).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     //         break;
  //     //       default:
  //     //         // console.log(`Sorry, we are out of ${rule}.`);
  //     //     }
  //     //   }

  //       // TODO : 소수자리수 두자리에서 자를지 말지 고민할 것. 잘라도 되겠다.
  //       // newData[9] = (Number(newData[5].replace(",", ""))*Number(newData[7].replace(",", ""))*Number(newData[8].replace(",", ""))).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //       newData[9] = (Number(newData[5].replace(",", ""))*Number(newData[7].replace(",", ""))*Number(newData[8].replace(",", ""))).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //     };

  //     return newData;
  //   });

  //   const sumAmount = arrayColumn(newDatas, 5).slice(0, -1).map(i => i.replace(",", ""));
  //   const sumAreaUnit = arrayColumn(newDatas, 6).slice(0, -1).map(i => i.replace(",", ""));
  //   const sumArea = arrayColumn(newDatas, 7).slice(0, -1).map(i => i.replace(",", ""));
  //   const sumPrice = arrayColumn(newDatas, 9).slice(0, -1).map(i => i.replace(",", ""));

  //   const initialValue = 0;
  //   newDatas[datas.length-1][5] = sumAmount.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //   newDatas[datas.length-1][6] = sumAreaUnit.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //   newDatas[datas.length-1][7] = sumArea.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //   // TODO : 소수자리수 두자리에서 자를지 말지 고민할 것. 잘라도 되겠다.
  //   // newDatas[datas.length-1][9] = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //   newDatas[datas.length-1][9] = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

  //   setDatas(newDatas);

  //   setAreaType(newAreaType);
  // }

  const emptyCheck = (data/*, idx*/) => {
    let result = true;
    // 맨마지막 행은 소계로 처음에는 따로 저장하려고 했으나 목록을 가지고 서버에서 계산에서 저장하는 방식으로 결정
    // if (idx === datas.length -1) {
    //   return result;
    // }
    // console.log(data)
    for (const [key, value] of Object.entries(data)) {
      // gglassDetails 키도 다음 통과
      // if (Number(key) < fixedColumnLength-1/* -1은 비고로 제외 */ && value === "") {
      if (2 <= Number(key) && Number(key) < 18/* -1은 비고로 제외 */ && value === "") {
        result = false;
        break;
      }
    }

    return result;
  }

  const selectGGlassCheck = (data/*, idx*/) => {
    let result = true;
    // 맨마지막 행은 소계로 처음에는 따로 저장하려고 했으나 목록을 가지고 서버에서 계산에서 저장하는 방식으로 결정
    // if (idx === datas.length -1) {
    //   return result;
    // }
    // console.log(data)
    for (const [key, value] of Object.entries(data)) {
      // gglassDetails 키도 다음 통과
      // if (Number(key) < fixedColumnLength-1/* -1은 비고로 제외 */ && value === "") {
      if (2 <= Number(key) && Number(key) < 5/* -1은 비고로 제외 */ && value === "") {
        result = false;
        break;
      }
    }

    return result;
  }

  useEffect(
    async () => {
      // let arr = [];
      // if (areaType === "m2") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceMeter", "orderPriceTotalMeter", "notes"]);
      // } else if (areaType === "평") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceJa", "orderPriceTotalJa", "notes"]);
      // } else if (areaType === " ft2") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceFeet", "orderPriceTotalFeet", "notes"]);
      // }

      const newDatas = Object.assign([], datas);
      // console.log(selectedRow);
      // console.log(initExcelDatas);
      const newFilledCells = [];
      initExcelDatas && initExcelDatas?.length > 0 && initExcelDatas.forEach((row, rIdx) => {
        const {
          gglassId,
          no,
          // name,
          // gtypeId,
          // comments,
          // order,
          // orderDetails,
          // selectedGcomponentItems,
          standard,
          specification,
          // otherSpecs,
          width,
          height,
          amount,
          areaUnitMeter,
          areaUnitJa,
          areaUnitFeet,
          areaMeter,
          areaJa,
          areaFeet,
          orderAreaType,
          orderPrice,
          orderPriceMeter,
          orderPriceJa,
          orderPriceFeet,
          orderPriceTotal,
          notes,
        } = row;

        gglassMap.set(no, row);
        
        let columnIds = [];
        if (orderAreaType === "m2") {
          columnIds = [].concat(["8", "11", "14"]);
        } else if (orderAreaType === "평") {
          columnIds = [].concat(["9", "12", "15"]);
        } else if (orderAreaType === "ft2") {
          columnIds = [].concat(["10", "13", "16"]);
        }
        
        newFilledCells.push({
          rowIdx: rIdx, colIds: columnIds,
        });

        // sequelize에서 integer는 아래와 같이 문자로 바꾸고, decimal은 string으로 리턴하므로 그래도 둔다.
        newDatas[rIdx][2] = no;
        newDatas[rIdx][3] = standard || ""; // 입력시 필수 입력이나 만약을 위해 값이 없을 경우 공백처리
        newDatas[rIdx][4] = specification || ""; // 입력시 필수 입력이나 만약을 위해 값이 없을 경우 공백처리
        newDatas[rIdx][5] = width.toString();
        newDatas[rIdx][6] = height.toString();
        newDatas[rIdx][7] = amount.toString();
        newDatas[rIdx][8] = areaUnitMeter;
        newDatas[rIdx][9] = areaUnitJa;
        newDatas[rIdx][10] = areaUnitFeet;
        newDatas[rIdx][11] = areaMeter;
        newDatas[rIdx][12] = areaJa;
        newDatas[rIdx][13] = areaFeet;
        newDatas[rIdx][14] = orderPriceMeter;
        newDatas[rIdx][15] = orderPriceJa;
        newDatas[rIdx][16] = orderPriceFeet;
        newDatas[rIdx][17] = orderPriceTotal;
        newDatas[rIdx][18] = notes || "";

        newDatas[rIdx]["gglassDetails"] = row;
        
        // const gglassDetails = {
        //   id: gglassId,
        //   no,
        //   name,
        //   // code,
        //   gtypeId,
        //   comments,
        //   order,
        //   orderDetails,
        //   selectedGcomponentItems,
        //   standard,
        //   specification,
        //   otherSpecs,
        //   orderPrice,
        //   orderPriceMeter,
        //   orderPriceJa,
        //   orderPriceFeet,
        //   // createdAt: ,
        //   // updatedAt: ,
        // };
        
        // for (const [key, value] of Object.entries(row)) {
        //   const index = arr.indexOf(key);
        //   if (index > 0) {
        //     console.log(arr[index])
        //     console.log(typeof value)
        //     if (typeof value === "number") {
        //       newDatas[rIdx][index] = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        //     } else if (index === 6 || index === 7 || index === 8 || index === 9 ) { // TODO : postgresql DECIMAL의 경우 sequelize에서 string으로 받음. 추후 방안 마련
        //       newDatas[rIdx][index] = parseFloat(value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        //     } else {
        //       newDatas[rIdx][index] =  value;
        //     }
            
        //     newDatas[rIdx]["gglassDetails"] = gglassDetails;
        //   }
        // }
        // // gglassMap.set(arr[1], gglassDetails); // gglassMap 구성 필요
        // // gglassMap.set(gglassId, gglassDetails); // gglassMap 구성 필요
        // gglassMap.set(no, gglassDetails); // gglassMap 구성 필요
      });

      // if (selectedRow) {
      if (selectedRow && !objectEmptyCheck(selectedRow)) {
        const { sumAmount, sumAreaMeter, sumAreaJa, sumAreaFeet, sumOrderPriceTotal } = selectedRow;
        newDatas[datas.length-1][7] = sumAmount;
        newDatas[datas.length-1][11] = sumAreaMeter;
        newDatas[datas.length-1][12] = sumAreaJa;
        newDatas[datas.length-1][13] = sumAreaFeet;
        newDatas[datas.length-1][17] = sumOrderPriceTotal;

        setValue("sumAmount", sumAmount);
        setValue("sumAreaMeter", sumAreaMeter);
        setValue("sumAreaJa", sumAreaJa);
        setValue("sumAreaFeet", sumAreaFeet);
        // sumContractPriceTotal // 서버에서 계산
        setValue("sumOrderPriceTotal", sumOrderPriceTotal);
      }

      setFilledCells(newFilledCells);
      // if (selectedRow) {
      //   // 소계 데이터 GOrders 테이블에 저장하고 그것을 조회한 결과를 여기에서 맨 마지막 행에 들어가도록 함
      //   const sumAmount = selectedRow.sumAmount || "0";
      //   const sumAreaUnitMeter = selectedRow.sumAreaUnitMeter || "0";
      //   const sumAreaMeter = selectedRow.sumAreaMeter || "0";
      //   const sumPriceMeter = selectedRow.sumPriceMeter || "0";
      //   newDatas[datas.length-1][5] = parseFloat(sumAmount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      //   newDatas[datas.length-1][6] = parseFloat(sumAreaUnitMeter).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      //   newDatas[datas.length-1][7] = parseFloat(sumAreaMeter).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      //   newDatas[datas.length-1][9] = parseFloat(sumPriceMeter).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // }

      // newDatas[datas.length-1][5] = parseFloat(sumAmount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      // console.log(newDatas);
      setDatas(newDatas);
      // setOpenProgress(false);

      // const areaGUnits = await selectGUnitsAllByTypeDirect('area');
      // setAreaGUnits(areaGUnits);
    }, [dispatch]
  )

  useEffect(
    () => {
      const newDatas = Object.assign([], datas.filter((data/*, idx*/) => emptyCheck(data/*, idx*/)));
      const selectGGlassDatas = Object.assign([], datas.filter((data/*, idx*/) => selectGGlassCheck(data/*, idx*/)));
      
      console.log(newDatas)
      setValue("gorderDetails", newDatas);
      setValue("gorderDetailsBeforeValidation", selectGGlassDatas.length); // 프로젝트 목록에서 프로젝트 변경시 작성중인 유리 내역이 있는 경우 체크를 위해 기록. TODO : react-hook-form에 기록할지 따로 GOrderDialog에서 prop을 받을지 추후 검토할 것
      // setOpenProgress(false);
    }, [datas]
  )

  // useEffect(
  //   () => {
  //     console.log(rowsFind)
  //   }, [rowsFind]
  // )

  useEffect(
    () => {
      if (initExcelDatas && initExcelDatas.length === 0) {
        setDatas(getDatas());
        setFilledCells([]);
      }
    }, [initExcelDatas]
  )

  return (
    // <ThemeProvider theme={theme}>
      // <Container component="main" maxWidth="lg">
      <>
        <CssBaseline />
        {/* 다이얼로그 open을 통해 열고 닫는 방식에서 화면에는 띄워놓고 숨겼다가 보이는 방식으로 변경. watermark 때문 */}
        <Dialog
          // open={openFindDialog}
          open={true}
          onClose={handleFindDialogClose}
          fullWidth={true}
          maxWidth={"lg"}
          sx={{ display: openFindDialog ? 'visible' : 'none' }}
        >
          <DialogTitleClose
            onClose={handleFindDialogClose}
          >
            {"유리제품 선택"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container>
              <div style={{ height: 400, width: '100%' }}>
                {/* TODO : 유리샘플로부터 프로젝트에 등록한 유리제품 조회하도록 변경할 것 */}
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: showFind ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loadedFind}
                  rows={rowsFind}
                  columns={columnsFind}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowClick={(params) => {
                    setSelectedRowsFind(params);
                  }}
                  onRowDoubleClick={handleSelectFind}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSelectFind}>{"선택"}</Button>
            <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          alertInfo={alertInfo}
          // open={openAlert}
          setAlertInfo={setAlertInfo}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={3} display="flex" alignItems="center">
              <Typography variant="subtitle2" component="div" color="primary" sx={{ mr: 1 }}>{"기준면적단위"}</Typography>
              <ToggleButtonGroup
                color="primary"
                value={areaType}
                exclusive
                size="small"
                onChange={handleChangeAreaGUnit}
                sx={{ mr: 1 }}
              >
                {
                  areaGUnits?.map(unit => {
                    return <ToggleButton value={unit.code} sx={{ textTransform: 'none' }}>{unit.name}</ToggleButton>
                  })
                }
              </ToggleButtonGroup>
            </Grid> */}
            <Grid item xs={12} display="flex" alignItems="center">
              {
                alertInfo.isAlert && (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{alertInfo?.message}</Alert>
                  </Stack>
                )
              }
            </Grid>
            {/* <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
              <FileUpload
                text={"엑셀 가져오기"}
                extension={".xls,.xlsx"}
                id={"excelImport"}
                handleFile={(e) => handleUploadFile(e)}
              />
            </Grid> */}
          </Grid>
          {
            // rows?.length > 0 && (
              <div style={{ height: 800, width: '100%', overflow: 'scroll' }}>
                <ReactGrid
                  rows={rows}
                  columns={columns}
                  customCellTemplates={{ textCell2: new TextCell2Template() }}
                  onCellsChanged={handleChanges}
                  onColumnResized={handleColumnResize}
                  // onColumnsReordered={handleColumnsReorder}
                  onRowsReordered={handleRowsReorder}
                  enableRowSelection
                  enableColumnSelection
                  enableRangeSelection
                  stickyLeftColumns={7}
                  stickyRightColumns={2}
                  stickyTopRows={2}
                  stickyBottomRows={1} // TODO : 소계 : 수량합, 면적합 (피트평(ft2), 평(자), 제곱미터(m2) 모두 표현)
                  onContextMenu={simpleHandleContextMenu}
                  // // focusLocation={{ columnId: 'name', rowId: '2' }}
                  enableFillHandle
                  labels={{
                    copyLabel: '복사하기',
                    pasteLabel: '붙여넣기',
                    cutLabel: '잘라내기',
                }}
                // highlights={highlights}
                />
              </div>
            // )
          }
        </Box>
      </>
      // </Container>
    // </ThemeProvider>
  );
};

export default GOrderExcel;
