// TODO : id/gcomponentItemId, type/itemType => frontend, backend(api), db 혼용하여 혼동됨. 정리 필요
import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config';

const INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS = "gclientG04docuGCertificationMaps/initGClientG04docuGCertificationMaps";
const SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAP = "gclientG04docuGCertificationMaps/setGClientG04docuGCertificationMap";
const SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS = "gclientG04docuGCertificationMaps/setGClientG04docuGCertificationMaps";
const REMOVE_GCLIENT_G04_DOCU_GCERTIFICATION_MAP = "gclientG04docuGCertificationMaps/removeGClientG04docuGCertificationMap";
const INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION = "gclientG04docuGCertificationMaps/initGClientG04docuGCertificationMapsForCertification";
const INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST = "gclientG04docuGCertificationMaps/initGClientG04docuGCertificationMapsForTest";
const SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION = "gclientG04docuGCertificationMaps/setGClientG04docuGCertificationMapsForCertification";
const SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST = "gclientG04docuGCertificationMaps/setGClientG04docuGCertificationMapsForTest";

const initGClientG04docuGCertificationMaps = () => ({
  type: INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS,
});

const setGClientG04docuGCertificationMap = (gclientG04docuGCertificationMap) => ({
  type: SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAP,
  payload: gclientG04docuGCertificationMap,
});

const setGClientG04docuGCertificationMaps = (gclientG04docuGCertificationMaps) => ({
  type: SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS,
  payload: gclientG04docuGCertificationMaps,
});

const initGClientG04docuGCertificationMapsForCertification = () => ({
  type: INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION,
});

const initGClientG04docuGCertificationMapsForTest = () => ({
  type: INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST,
});

const setGClientG04docuGCertificationMapsForCertification = (gclientG04docuGCertificationMapsForTest) => ({
  type: SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION,
  payload: gclientG04docuGCertificationMapsForTest,
});

const setGClientG04docuGCertificationMapsForTest = (gclientG04docuGCertificationMapsForTest) => ({
  type: SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST,
  payload: gclientG04docuGCertificationMapsForTest,
});

const removeGClientG04docuGCertificationMap = () => ({
  type: REMOVE_GCLIENT_G04_DOCU_GCERTIFICATION_MAP,
});

// export const createMaps = (gclientG04docuGCertificationMaps) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/bulk`, {
//     method: "POST",
//     body: JSON.stringify(gclientG04docuGCertificationMaps),
//   });
//   const data = await response.json();
//   dispatch(setGClientG04docuGCertificationMaps(data.gclientG04docuGCertificationMaps));
//   return response;
// };

// export const createMapsDirect = async (gclientG04docuGCertificationMaps) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/bulk`, {
//     method: "POST",
//     body: JSON.stringify(gclientG04docuGCertificationMaps),
//   });
//   const data = await response.json();
//   return data.gclientG04docuGCertificationMaps;
// };
export const create = ({ gclientId, g04docuGCertificationId, gcomponentItemId, gclient, documentPath, documentType, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuGCertificationId, gcomponentItemId, gclient, documentPath, documentType, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMap(data.gclientG04docuGCertificationMap));
  return response;
};

export const modifyDate = ({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMap(data.gclientG04docuGCertificationMap));
  return response;
};

// export const modifySelectedDetails = ({ id, selectedDetails }) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/modifyDate`, {
//     method: "PUT",
//     body: JSON.stringify({ id, selectedDetails }),
//   });
//   const data = await response.json();
//   dispatch(setGClientG04docuGCertificationMap(data.gclientG04docuGCertificationMap));
//   return response;
// };

// export const modify = ({ gclientId, g04docuGCertificationId, gclient, documentPath }) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps`, {
//     method: "PUT",
//     body: JSON.stringify({ gclientId, g04docuGCertificationId, gclient, documentPath }),
//   });
//   const data = await response.json();
//   dispatch(setGClientG04docuGCertificationMap(data.gclientG04docuGCertificationMap));
//   return response;
// };

export const initializeGClientG04docuGCertificationMaps = () => async dispatch => {
  dispatch(initGClientG04docuGCertificationMaps());
}

// TODO : 인증서. 사용안하고 있음. 검토 필요
export const selectAllByGClientByG04docuGCertification = (gclientId, g04docuGCertificationId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}/g04docuGCertification/${g04docuGCertificationId}`);
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMaps(data.gclientG04docuGCertificationMaps));
  return response;
}

// TODO : 인증서. 사용안하고 있음. 검토 필요
// 인증서
export const selectAllByGClientByG04docuForCertification = (gclientId, g04docuGCertificationId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}/g04docuGCertification/${g04docuGCertificationId}`);
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMapsForCertification(data.gclientG04docuGCertificationMapsForCertification));
  return response;
}

// 성적서
export const selectAllByGClientByG04docuForTest = (gclientId, g04docuGCertificationId, gcomponentItemId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}/g04docuGCertification/${g04docuGCertificationId}/gcomponentItem/${gcomponentItemId}`);
  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMapsForTest(data.gclientG04docuGCertificationMapsForTest));
  return response;
}

// TODO : init을 따로 만들지 setGClientG04docuGCertificationMapsForTest를 사용할지 검토
export const initializeByGClientByG04docuForCertification = () => async dispatch => {
  dispatch(initGClientG04docuGCertificationMapsForCertification());
  return;
}

// TODO : init을 따로 만들지 setGClientG04docuGCertificationMapsForTest를 사용할지 검토
export const initializeByGClientByG04docuForTest = () => async dispatch => {
  dispatch(initGClientG04docuGCertificationMapsForTest());
  return;
}

export const selectAllByGClient = (gclientId) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuGCertificationIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuGCertificationIds = dat.g04docuGCertificationIds;
  console.log(g04docuGCertificationIds)
  
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ g04docuGCertificationIds }),
  });

  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMaps(data.gclientG04docuGCertificationMaps));
  return response;
}

export const selectAllByGClientByStatus = (gclientId, status) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuGCertificationIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuGCertificationIds = dat.g04docuGCertificationIds;
  console.log(g04docuGCertificationIds)
  
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}/status/${status}`, {
    method: "POST",
    body: JSON.stringify({ g04docuGCertificationIds }),
  });

  const data = await response.json();
  dispatch(setGClientG04docuGCertificationMaps(data.gclientG04docuGCertificationMaps));
  return response;
}

export const selectByQueryDirect = async (id, gclientId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/select/${id}/gclient/${gclientId}`);
  const data = await response.json();
  return data.gclientG04docuGCertificationMaps;
}

// export const select = (id, gclientId, itemType) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/${id}/gclient/${gclientId}/itemType/${itemType}`);
//   const data = await response.json();
//   dispatch(setGClientG04docuGCertificationMap(data.gclientG04docuGCertificationMap));
//   return response;
// }

// export const selectAllByGClientByG04docuGCertificationIds = (gclientId, g04docuGCertificationIds) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/query/selectAll/gclient/${gclientId}`, {
//     method: "POST",
//     body: JSON.stringify({ g04docuGCertificationIds }),
//   });

//   const data = await response.json();
//   dispatch(setGClientG04docuGCertificationMaps(data.gclientG04docuGCertificationMaps));
//   return response;
// }

// 성적서의 경우 gcomponentItemId 필요하다 생각했으나 => documentPath가 있으므로 문제 안됨
export const remove = ({ gclientId, g04docuGCertificationId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGCertificationMaps/gclient/${gclientId}/g04docuGCertification/${g04docuGCertificationId}/documentPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuGCertificationMap());
  return response;
};

const initialState = {
  gclientG04docuGCertificationMap: null,
  gclientG04docuGCertificationMaps: [],
  gclientG04docuGCertificationMapsForCertification: [],
  gclientG04docuGCertificationMapsForTest: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMaps: initialState.gclientG04docuGCertificationMaps });
      return newState;
    case SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAP:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMap: action.payload });
      return newState;
    case SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMaps: action.payload });
      return newState;
    case INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMapsForCertification: initialState.gclientG04docuGCertificationMapsForCertification });
      return newState;
    case INIT_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMapsForTest: initialState.gclientG04docuGCertificationMapsForTest });
      return newState;
    case SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_CERTIFICATION:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMapsForCertification: action.payload });
      return newState;
    case SET_GCLIENT_G04_DOCU_GCERTIFICATION_MAPS_FOR_TEST:
      newState = Object.assign({}, state, { gclientG04docuGCertificationMapsForTest: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
