const SecurityUtil = {
  /**
   * 정보 공개 여부 판단
   * @param {*} gclientId 
   * @returns 
   */
  isPublicAbountMe : (gclientId, securityOpenedGClientsAboutMe) => {
    // console.log({ gclientId, securityOpenedGClientsAboutMe })
    const openedGClients = securityOpenedGClientsAboutMe.filter(gclient => gclient.id === gclientId);
    if (openedGClients.length > 0) {
      return true;
    } else {
      return false;
    }
  },
}

export default SecurityUtil;