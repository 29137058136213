import React from "react";
import { useDispatch, useSelector } from "react-redux";

// 자체 컴포넌트
import { ConfirmDialog } from "../dialog";

// store actions
import * as confirmDialogActions from "../../store/components/confirmDialog";

const UserConfirmDialog = ({ }) => {
  const dispatch = useDispatch();

  const options = useSelector((state) => state.confirmDialog.options);
  const selectedRows = useSelector((state) => state.datagrid.selectedRows);

  const setOptions = (options) => dispatch(confirmDialogActions.setOptions(options));

  return (
    <ConfirmDialog
      removeId={options?.params}
      title={options?.title}
      open={options?.open || false}
      setOpen={() => setOptions({ ...options, open: false })}
      onConfirm={(params) => options?.action && options?.action(params, selectedRows)}
      onCancel={() => {}}
    >
      {options?.message}
    </ConfirmDialog>
  )
}

export default UserConfirmDialog;