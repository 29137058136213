// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_GPROJECT_GLASS_PRICES = 'gproject/setGProjectGlassPrices';

const setGProjectGlassPrices = (gprojectGlassPrices) => ({
  type: SET_GPROJECT_GLASS_PRICES,
  payload: gprojectGlassPrices,
});

export const select = (gprojectId, gglassId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectGlassPrices/project/${gprojectId}/glass/${gglassId}`);
  const data = await response.json();
  console.log(data.gprojectGlassPrices)
  dispatch(setGProjectGlassPrices(data.gprojectGlassPrices));
  return response;
}

export const selectDirect = async (gprojectId, gglassId) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojectGlassPrices/project/${gprojectId}/glass/${gglassId}`);
  const data = await response.json();
  console.log(data.gprojectGlassPrices)
  return data.gprojectGlassPrices;
}

const initialState = {
  gprojectGlassPrices: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GPROJECT_GLASS_PRICES:
      newState = Object.assign({}, state, { gprojectGlassPrices: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
