import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom"
import * as sessionActions from "../../store/session";
import * as gclientActions from "../../store/gclient";
// import * as errorActions from "../../store/error";

let passwordInputRef = null;
let passwordNewInputRef = null;
let passwordNewConfirmInputRef = null;

// 아래 form components의 name과 연계
const defaultValues = {
  // credential: "",
  bizRegNumber: "",
  email: "",
  userId: "",
  password: "",
  // loginType: true,
  passwordNew: "",
  passwordNewConfirm: "",
};

const PasswordChangeDialog = ({
  open,
  setOpen,
  mode,
  type,
  // setType,
  credential,
}) => {
  const [errors, setErrors] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const error = useSelector(state => state.error.error);
  
  // 데이터 관리
  const dispatch = useDispatch();

  // password만 바뀌므로 sessionUser정보를 update할 필요가 없어 ~Direct로 호출함
  const initPasswordDirect = ({ type, bizRegNumber, email, userId }) => sessionActions.initPasswordDirect({ type, bizRegNumber, email, userId })
  const changePasswordDirect = ({ credential, type, password, passwordNew, passwordNewConfirm }) => sessionActions.changePasswordDirect({ credential, type, password, passwordNew, passwordNewConfirm });
  const selectEmailByBizRegNumberDirect = (bizRegNumber) => gclientActions.selectEmailByBizRegNumberDirect(bizRegNumber)

  const sessionUser = useSelector((state) => state.session.sessionUser);

  const setPasswordInputRef = element => {
    passwordInputRef = element;
  };

  const setPasswordNewInputRef = element => {
    passwordNewInputRef = element;
  };

  const setPasswordNewConfirmInputRef = element => {
    passwordNewConfirmInputRef = element;
  };

  const onSubmit = async ({ bizRegNumber, email, userId, password, passwordNew, passwordNewConfirm }) => {
    setErrors([]);
    
    // console.log({ mode, credential, bizRegNumber, email, userId, password, passwordNew, passwordNewConfirm, type });
    // return;

    try {
      let messageAlert;
      if (mode === 'I') {
        const res = await initPasswordDirect({ type, bizRegNumber, email, userId });
        const { sessionUser, info } = res;
        if (sessionUser && info) {
          // TODO : info는 메일전송 결과임. 추후 info 검토 후 아래 처리 정리 필요
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: (
              <>
                <span>{"비밀번호가 초기화되었습니다."}</span><br />
                <span>{`${type === 'GCLIENT' ? sessionUser.email : sessionUser.user[0].userId} 메일을 확인해주세요.`}</span>
              </>
            ),
            open: true,
          });
          
          handleDialogClose();

          return;
        }

        // TODO : 야래 코드의 email과 위의 email에서 Cannot access 'email' before initialization 발생 ???
        // const { email, inCharges } = sessionUser;
        // let ccInfo = "";
        // if (inCharges && Array.isArray(inCharges) && inCharges.length > 0) {
        //   ccInfo = (
        //     <>
        //       <br />
        //       <span>
        //         {`참조 : ${inCharges[0].inChargeName} <${inCharges[0].inChargeEmail}>`}
        //       </span> 
        //     </>
        //   );
        // }

        // messageAlert = (
        //   <>
        //     <span>
        //       {`회사 대표메일 <${email}>로 비밀번호를 전송하였습니다.`}
        //     </span>
        //     {ccInfo}
        //   </>
        // );

      } else if ((mode === 'C')) {
        const result = validate({ password, passwordNew, passwordNewConfirm });
        if (!result[0]) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: result[1],
            open: true,
          });

          return;
        }

        console.log(sessionUser)
        const { sessionUserLoginType, bizRegNumber } = sessionUser;
        await changePasswordDirect({ credential: sessionUserLoginType === 'GCLIENT' ? bizRegNumber : sessionUser.user[0].userId, type: sessionUserLoginType, password, passwordNew, passwordNewConfirm });
        messageAlert = `비밀번호가 수정되었습니다.`;
      }
      
      setAlertInfo({
        titleAlert: "안내",
        messageAlert,
        open: true,
      });

      handleDialogClose();
    } catch (e) {
      console.log(e)
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: e.serverResponse.message,
        open: true,
      });
    }
  }

  const validate = ({ password, passwordNew, passwordNewConfirm }) => {
    let msg = "";
    if (!password) {
      passwordInputRef.focus();
      msg = "기존 비밀번호를 입력해주세요.";
      return [false, msg];
    }

    if (!passwordNew) {
      passwordNewInputRef.focus();
      msg = "새 비밀번호를 입력해주세요.";
      return [false, msg];
    }

    if (!passwordNewConfirm) {
      passwordNewConfirmInputRef.focus();
      msg = "확인을 위하여 한번 더 새 비밀번호 입력해주세요.";
      return [false, msg];
    }

    if (passwordNew != passwordNewConfirm) {
      passwordNewInputRef.focus();
      msg = "새 비밀번호와 확인을 위해 입력한 새 비밀번호가 일치하지 않습니다.";
      return [false, msg];
    }

    return [true, msg];
  }

  // const handleChangeCheckValue = (e, checked) => {
  //   if (checked) {
  //     setType("GCLIENT");
  //     setCheckValue("loginType", true);
  //   } else {
  //     setType("USER");
  //     setCheckValue("loginType", false);
  //   }
  // }

  useEffect(
    async () => {
      if (type === 'GCLIENT') {
        // setValue("loginType", true);
        setValue("bizRegNumber", credential);
        // credential(사업자번호)이 있으면 email값 넣을 것
        if (credential && credential.length === 12) {
          const email = await selectEmailByBizRegNumberDirect(credential);
          setValue("email", email);
        } else {
          setValue("email", "");
        }
        setValue("userId", "");
      } else { // type === 'USER'
        // setValue("loginType", false);
        setValue("bizRegNumber", "");
        setValue("email", "");
        setValue("userId", credential);
      }
    }, [open]
  )

  const handleChange = async () => {
    const inputBizRegNumber = getValues("bizRegNumber");
    if (inputBizRegNumber && inputBizRegNumber.length === 12) {
      const email = await selectEmailByBizRegNumberDirect(inputBizRegNumber);
      setValue("email", email);
    } else {
      setValue("email", "");
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          { mode === 'I' && "비밀번호 초기화"}
          { mode === 'C' && "비밀번호 변경"}
        </DialogTitleClose>
        <DialogContent dividers>
          {/* <Grid display="flex" justifyContent={"center"}>
            <Box> */}
              {/* {
                mode === 'I' && (
                  <Grid container diplay="flex">
                    <Grid item xs={12} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                      <FormInputCheckbox
                        name={"loginType"}
                        control={control}
                        // label={""}
                        onChangeCheckValue={handleChangeCheckValue}
                      />
                      <Tooltip title={"사업자가 아닌 경우는 일반 개인 사용자입니다."}>
                        <Typography>{"사업자"}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              } */}
              <Grid container diplay="flex" spacing={2} sx={{ mt: 1, mb: 2 }}>
                {
                  mode === 'I' && type === 'GCLIENT' && (
                    <>
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          autoComplete="bizRegNumber"
                          name={"bizRegNumber"}
                          control={control}
                          required
                          fullWidth
                          label={"사업자번호"}
                          autoFocus
                          InputProps={{
                            inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                            inputProps: {
                              style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                mask: "000-00-00000",
                                // definitions: {
                                //   '#': /[1-9]/,
                                // },
                              },
                            },
                          }}
                          onCustomChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="email"
                          name={"email"}
                          control={control}
                          required
                          fullWidth
                          label={"회사대표메일"}
                          errorStyle={{ message: false, border: true }}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </>
                  )
                }
                {
                  mode === 'I' && type === 'USER' && (
                    <>
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          autoComplete="bizRegNumber"
                          name={"bizRegNumber"}
                          control={control}
                          required
                          fullWidth
                          label={"사업자번호"}
                          autoFocus
                          InputProps={{
                            inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                            inputProps: {
                              style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                mask: "000-00-00000",
                                // definitions: {
                                //   '#': /[1-9]/,
                                // },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          autoComplete="userId"
                          name={"userId"}
                          control={control}
                          required
                          fullWidth
                          label={"이메일 아이디"}
                          // autoFocus
                        />
                      </Grid>
                    </>
                  )
                }
                {
                  mode === 'C' && (
                    <>
                      {/* <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          autoComplete="credential"
                          name={"credential"}
                          control={control}
                          required
                          fullWidth
                          label={"회원사 사용자 아이디(이메일)"}
                          // autoFocus
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          required
                          fullWidth
                          name={"password"}
                          control={control}
                          label={"기존 비밀번호"}
                          type="password"
                          autoComplete="current-password"
                          inputProps={{ // InputProps와는 다름
                            ref: setPasswordInputRef,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          required
                          fullWidth
                          name={"passwordNew"}
                          control={control}
                          label={"새 비밀번호"}
                          type="password"
                          autoComplete="current-password"
                          inputProps={{ // InputProps와는 다름
                            ref: setPasswordNewInputRef,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          // margin="normal"
                          required
                          fullWidth
                          name={"passwordNewConfirm"}
                          control={control}
                          label={"새 비밀번호 확인"}
                          type="password"
                          autoComplete="current-password"
                          inputProps={{ // InputProps와는 다름
                            ref: setPasswordNewConfirmInputRef,
                          }}
                        />
                      </Grid>
                    </>
                  )
                }
              </Grid>
            {/* </Box>
          </Grid> */}
        </DialogContent>
        <DialogActions>
          { mode === 'I' && <Button onClick={handleSubmit(onSubmit)}>{"비밀번호 초기화"}</Button> }
          { mode === 'C' && <Button onClick={handleSubmit(onSubmit)}>{"변경"}</Button> }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default PasswordChangeDialog;
