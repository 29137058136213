import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowDown,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
  GlassUtils,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import { SecurityUtils } from "../../utils";

import * as gclientActions from "../../store/gclient";
import * as securityActions from "../../store/security";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as securityRequestActions from "../../store/securityRequest";
import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";

import TreeView from './GProjectG04TreeView';
// import PdfViewer from './PdfViewer';
// import FileViewer from 'react-file-viewer';

import { G04docuMaterialApprovalManagement } from '../G04docuMaterialApproval';
import { G04docuGTestManagement } from '../G04docuGTest';


const GProjectG04GeneratorDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
}) => {
  // const [errors, setErrors] = useState([]);
  const [fullScreen, setFullScreen] = useState(true);
  const [show, setShow] = useState(true);
  const [selected, setSelected] = React.useState(['DOMESTIC_AUTH']); // 국내가공유리제품 노드 기본 체크하여 출력되지 않도록 한다.
  const [g04docuDataWithoutDocus, setG04docuDataWithoutDocus] = useState([]);
  const [g04docuDataWithDocus, setG04docuDataWithDocus] = useState([]);
  const [documentPath, setDocumentPath] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [fileType, setFileType] = useState("");
  const [drawerState, setDrawerState] = useState({});
  const [openSubstitute, setOpenSubstitute] = useState(false);
  const [dataForMaterialApproval, setDataForMaterialApproval] = useState(undefined);
  const [dataForGTestSubstitute, setDataForGTestSubstitute] = useState(undefined);
  const [openRequest, setOpenRequest] = useState(false);
  const [closedGClientList, setClosedGClientList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [showRequest, setShowRequest] = useState(false);
  const [gclientsInMaterialApproval, setGClientsInMaterialApproval] = useState([]);

  // 미리 선언해야 사용할 수 있으므로 위치 변경
  // const g04docusSubMaterialProcess = useSelector((state) => state.g04docu.g04docusSubMaterialProcess);

  const handleRequestDialogClose = () => {
    setOpenRequest(false);
  }

  const handleDialogClose = () => {
    // TODO : 수정한 것이 있다면 닫기 전 confirm 창 띄울 것
    setOpen(false);

    initDialog();
  };
  
  const dispatch = useDispatch();
  const location = useLocation();

  const sessionUser = useSelector(state => state.session.sessionUser);
  // const gclients = useSelector(state => state.gclient.gclients);
  // const securityOpenedGClientsAboutMe = useSelector((state) => state.security.securityOpenedGClientsAboutMe);

  // 데이터 관리
  
  // no dispatch
  const generateDirect = (treeData, gprojectId) => g04docuFileActions.generateDirect(treeData, gprojectId)
  const makeMaterialApproval = ({ gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions, domesticAuths }) => g04docuMaterialApprovalActions.makeMaterialApproval({ gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions, domesticAuths })
  const selectAllGClientsDirect = () => gclientActions.selectAllDirect() // TODO : 회원사 정보 조회를 다이얼로그가 나타날 때 해야 할 것으로 보임

  // dispatch
  const selectOpenedAboutMeGClientListDirect = (service, gclientId) => securityActions.selectOpenedAboutMeGClientListDirect(service, gclientId)
  const createSecurityRequest = ({ id, senderId, receiverId, service, status }) => dispatch(securityRequestActions.create({ id, senderId, receiverId, service, status }))
  const modifySecurityRequest = ({ id, senderId, receiverId, service, status }) => dispatch(securityRequestActions.modify({ id, senderId, receiverId, service, status }))
  const selectGClientsBySecurityRequestStatusByQueryDirect = ({ senderId, receiverIds }) => securityRequestActions.selectGClientsBySecurityRequestStatusByQueryDirect({ senderId, receiverIds });

  const sortByOrder = (a, b) => { // 정렬되어 있는 순서대로 정렬(order 필드값). 추후 이름으로 정렬한다면 sortByName을 만들고 아래에서 order 대신 name 사용
    const { order : value1 } = a;
    const { order : value2 } = b;
    if (value1 < value2) return -1;
    if (value1 > value2) return 1;
    return 0;
  }

  const compositeTreeItems = (items, itemsForTree, relatedGClients, securityOpenedGClientsAboutMe, gclients) => { // 코드 분석할 때 아래 구조와 /g04docuFiles/generate' API와 매칭해서 볼 것
    items.forEach(item => {
      const { gclientId, items } = item;

      const gclient = gclients.find(gc => gc.id === gclientId); // 새로 조회한 거래선 정보를 이용함
      // console.log(gclientId)
      // console.log(gclients)
      // console.log(gclient)
      if (!relatedGClients.find(gc => gc.id === gclient.id)) {
        relatedGClients.push(gclient);
      }

      if (gclient) {
        gclient.opened = SecurityUtils.isPublicAbountMe(gclient.id, securityOpenedGClientsAboutMe)
      }

      const gprojectId = selectedRow.id;

      const treeItem = {
        id: `${uuidv4()}/${gprojectId}/${gclientId}`,
        label: `${gclient.name}:${items.sort(sortByOrder).map(i => (i.itemType === 'RAW_MATERIAL' ? GlassUtils.getMaterialItemCodeOrName('GLASS PRODUCT NAME', i.name) : i.name)).join(",")}`, // :와 ,로 분리하여 거래선명과 mui Chip으로 목록 표시
        type: 'CLIENT', // 거래선과 아이템 목록
        data: {
          gclientId,
          gclient,
          // items,
        },
        children: [
          {
            id: `${uuidv4()}/${gprojectId}/${gclientId}/general`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 [상위 id/현재 id]로 구성 ('/'를 연결자로 사용)
            label: "일반서류",
            data: {
              // gclientId,
              // gclient,
            },
            type: 'GENERALS', // 일반서류
          },
          {
            id: `${uuidv4()}/${gprojectId}/${gclientId}/certification`,
            label: "인증서",
            data: {
              // gclientId,
              g04docuGCertificationIds:
                items.map(i => i.g04docuGCertificationId)
                     // HST, ARGON 추가 인증서
                     .concat(items.filter(i => i.g04docuGCertificationAdditionalId).map(i => i.g04docuGCertificationAdditionalId)), // 아이템 목록에서 중복없이 인증서를 구한다.
              g04docuGCertifications:
                items.map(i => ({
                  g04docuGCertificationId: i.g04docuGCertificationId,
                  selectedClassifications: i.specs.map(spec => spec.selectedClassifications),
                })).concat( // HST, ARGON 추가 인증서
                  items.filter(i => i.g04docuGCertificationAdditionalId).map(i => ({
                    g04docuGCertificationId: i.g04docuGCertificationAdditionalId,
                    selectedClassifications: [], // HST와 ARGON은 종류/등급 선택 없음
                  }))
                ), // 아이템 목록에서 중복없이 인증서를 구한다. 종류/등급 포함
            },
            type: 'CERTIFICATIONS', // 인증서
          },
          // 시험성과대비표 추가 => TODO : 추후 여기가 아니라 최상단에 요약표에 들어가야 함
          // {
          //   id: `${uuidv4()}/${gprojectId}/${gclientId}/test`,
          //   label: "시험성과대비표",
          //   type: 'TEST_COMPARISONS', // 성적서
          //   children: [
          //     ...items.sort(sortByOrder)?.map(i => {
          //       const { itemType } = i;
          //       return {
          //         id: `${gprojectId}/${gclientId}/test_comparison/${i.id}`,
          //         label: i.name,
          //         type: 'ITEM',
          //         data: {
          //           ...i,
          //           gclientId,
          //         },
          //         // 시가공부자재 => specs: [{ id, gId, code, name, comments, g04docuGCertificationId }]
          //         children: i.specs?.sort(sortByOrder)?.map(spec => {
          //           const { name, code, elements, selectedClassifications } = spec;
          //           return {
          //             id: `${gprojectId}/${gclientId}/test_comparison/${i.id}/${spec.id}`,
          //             label: name,
          //             type: (itemType === 'SUB_MATERIAL_PROCESS' || itemType === 'SUB_MATERIAL_BUILD') ? 'ITEM' : 'DOCTEST',
          //             data: {
          //               name,
          //               code,
          //               gclientId,
          //               // id: i.id,
          //               id: spec.id, // TODO : 현재 itemType이 PROCESS이면 사양(specification)이 들어가고 그 외의 경우 GComponentItems.id가 들어감
          //               elements: itemType === 'PROCESS' ? elements : null,
          //               division: itemType,
          //               selectedClassifications,
          //               gcomponentItemId: itemType === 'PROCESS' ? "" : i.id,
          //               gcomponentItemName: itemType === 'PROCESS' ? "" : i.gcomponentItemName,
          //               g04docuGCertificationId: i.g04docuGCertificationId,
          //               g04docuGCertificationName: i.g04docuGCertificationName,
          //             }
          //           }
          //         })
          //       }
          //     })
          //   ]
          // },
          {
            id: `${uuidv4()}/${gprojectId}/${gclientId}/test`,
            label: "성적서",
            type: 'TESTS', // 성적서
            children: [
              ...items.sort(sortByOrder)?.map(i => {
                const { itemType } = i;
                return {
                  id: `${gprojectId}/${gclientId}/test/${i.id}`,
                  label: itemType === 'RAW_MATERIAL' ? GlassUtils.getMaterialItemCodeOrName('GLASS PRODUCT NAME', i.name) : i.name,
                  type: 'ITEM',
                  data: { // TODO : 추후 불필요한 데이터 제거하여 경량화 필요
                    ...i,
                    specs: [], // 경량화 필요. 아래의 children에 specs 데이터가 대부분 들어감
                    gclientId,
                  },
                  // 시가공부자재 => specs: [{ id, gId, code, name, comments, g04docuGCertificationId }]
                  children: i.specs?.sort(sortByOrder)?.map(spec => {
                    const { name, code, elements, selectedClassifications, performanceData } = spec;
                    return {
                      id: `${gprojectId}/${gclientId}/test/${i.id}/${spec.id}`,
                      label: name,
                      type: (itemType === 'SUB_MATERIAL_PROCESS' || itemType === 'SUB_MATERIAL_BUILD') ? 'ITEM' : 'DOCTEST',
                      data: {
                        name,
                        code,
                        gclientId,
                        // id: i.id,
                        id: spec.id, // TODO : 현재 itemType이 PROCESS이면 사양(specification)이 들어가고 그 외의 경우 GComponentItems.id가 들어감
                        elements: itemType === 'PROCESS' ? elements : null,
                        division: itemType,
                        selectedClassifications,
                        gcomponentItemId: itemType === 'PROCESS' ? "" : i.id,
                        gcomponentItemName: itemType === 'PROCESS' ? "" : i.gcomponentItemName,
                        g04docuGCertificationId: i.g04docuGCertificationId,
                        g04docuGCertificationName: i.g04docuGCertificationName,
                        performanceData, // 성능확인서 추가
                      }
                    }
                  })
                }
              })
            ]
          },
          {
            id: `${uuidv4()}/${gprojectId}/${gclientId}/etc`,
            label: "기타",
            type: 'ETCS',
          }
        ]
      };
      
      itemsForTree.push(treeItem);
      console.log(itemsForTree)
    })
  }

  useEffect(
    async () => {
      // if (selectedRow) {
      //   const relatedGClients = [];
      //   // console.log(selectedRow);
      //   const rawMaterialItemsForTree = [];
      //   const processItemsForTree = [];
      //   const subMaterialProcessItemsForTree = [];
      //   const subMaterialBuildItemsForTree = [];
      //   if (selectedRow) {
      //     const service = '04docu';
      //     const securityOpenedGClientsAboutMe = await selectOpenedAboutMeGClientListDirect(service, sessionUser.id);

      //     const { selectedGlasses, selectedSubMaterialProcessItems, selectedSubMaterialBuildItems } = selectedRow;
      //     console.log(selectedGlasses);
      //     if (selectedGlasses && selectedGlasses.length > 0) {
      //       const gcomponentItems = []; // 원자재용
      //       const processItems = []; // 가공용
      //       const glasses = JSON.parse(JSON.stringify(selectedGlasses)); // TODO : 변환 필요한건지 추후 검토
      //       const gclientIds = [];
      //       // 1. 원자재
      //       glasses.forEach(glass => {
      //         glass.selectedGcomponentItems.forEach((gtypeDetails, i) => {
      //           let thickness = []; // 각 레이어에서 유리 두께는 하나임
      //           gtypeDetails.forEach((item, j) => {
      //             const items = [];
      //             // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
      //             if (j === 0) {
      //               return;
      //             }

      //             console.log(item);
      //             if (item.code === 'GL THICKNESS') { // 유리 두께일 경우
      //               // thickness.push(item);
      //               thickness.push(item.value);
      //             }

      //             if (item.value?.selectedGClientId) { // 해당 아이템의 제조사를 선택한 경우 (제조사 선택을 하지 않으면 목록에서 빠짐)
      //               const ele = gclientIds.find(id => id === item.value?.selectedGClientId)
      //               console.log(item.value)
      //               const { id, gcomponentId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName, selectedClassifications, itemType, order } = item.value;
      //               if (!ele) {
      //                 gclientIds.push(item.value.selectedGClientId); // 같은 거래선의 아이템끼리 묶기 위해 사용
      //                 // 같은 아이템이 있으면 pass. TODO : 이 코드가 필요한가??? 거래선이 없어서 처음 추가한 거라면 거래선 하위에 아이템도 없을 것이고 위 반복문 (gtypeDetails.forEach((item, j) => {)을 보면 그 하위에 items 배열이 있으므로 늘 같은 아이템은 없어보임
      //                 // const sameItem = items.find(i => i.id === id);
      //                 // if (!sameItem) {
      //                   if (item.code === 'GLASS PRODUCT NAME') {
      //                     console.log(item.value)
      //                     items.push({ id, gcomponentId, code, name, gcomponentItemName: name, comments, g04docuGCertificationId, g04docuGCertificationName, specs: thickness.map(t => ({ ...t, selectedClassifications })), itemType, order });
      //                   }/* else {
      //                     items.push({ id, gcomponentId, code, name, comments, g04docuGCertificationId, itemType }); // TODO : 원판이 아닌 경우 이 코드가 필요한가?
      //                   }*/
      //                 // }

      //                 gcomponentItems.push({
      //                   gclientId: item.value.selectedGClientId,
      //                   // 임시 : gclient 필요정보 점검
      //                   // gclient: item.value.gclients.find(gclient => gclient.gclient.id === item.value.selectedGClientId)?.gclient,
      //                   gclient: item.value.gclients.find(gclient => gclient.id === item.value.selectedGClientId),
      //                   items,
      //                 });
      //               } else if (ele) {
      //                 // if (!gcomponentItems[gcomponentItems.length - 1].items.find(i => i.id === id)) { // TODO : 맨 마지막이 맞음??? 틀리네... 해당 거래선을 찾아야 함
      //                 //   gcomponentItems[gcomponentItems.length - 1].items.push({ id, /*gId*/gcomponentId, code, name, comments, g04docuGCertificationId });
      //                 // } else {
      //                 //   // "유리 원판 제품명"일 경우 유리두께 정보를 배열로 추가해야 한다.
      //                 //   if (item.code === 'GLASS PRODUCT NAME') {
      //                 //     // gcomponentItems[gcomponentItems.findIndex(g => g.)].items.push({ id, /*gId*/gcomponentId, code, name, comments, g04docuGCertificationId });
      //                 //   }
      //                 // }
      //                 gcomponentItems.forEach(gcomponentItem => {
      //                   if (gcomponentItem.gclientId === item.value.selectedGClientId) {
      //                     // console.log(gcomponentItem.items.find(i => i.id === id))
      //                     // if (!gcomponentItem.items.find(i => i.id === id)) {
      //                     if (!gcomponentItem.items.find(i => i.id === id) && item.code === 'GLASS PRODUCT NAME') {
      //                       // gcomponentItem.items.push({ id, gcomponentId, code, name, comments, g04docuGCertificationId });
      //                       gcomponentItem.items.push({ id, gcomponentId, code, name, gcomponentItemName: name, comments, g04docuGCertificationId, g04docuGCertificationName, specs: thickness.map(t => ({ ...t, selectedClassifications })), itemType, order });
      //                     } else {
      //                       gcomponentItem.items.forEach(i => {
      //                         if (i.id === id && item.code === 'GLASS PRODUCT NAME') {
      //                           // i.thickness = i.thickness.concat(thickness);
      //                           if (i.specs.filter(t => t.id === thickness[0].id).length <= 0) {
      //                             i.specs = i.specs.concat(thickness).map(t => ({ ...t, selectedClassifications }));
      //                           }
      //                         }
      //                       })
      //                     }
      //                   }
      //                 })
      //               }
      //             }
      //           })
      //         })
      //       })

      //       console.log({ gcomponentItems })
      //       compositeTreeItems(gcomponentItems, rawMaterialItemsForTree, relatedGClients, securityOpenedGClientsAboutMe); // TODO : 임시주석

      //       // 2. 가공
      //       gclientIds.splice(0, gclientIds.length); // 배열 비우기
      //       glasses.forEach(glass => {
      //         const { selectedProcessGClients } = glass;
      //         console.log(selectedProcessGClients)
      //         selectedProcessGClients?.forEach(process => {
      //           const { selectedGClients, specification, g04docuGCertification, specificationElements } = process;
      //           selectedGClients.forEach(gclient => {
      //             const ele = processItems.find(processItem => processItem.gclientId === gclient.id);
      //             const { id, name, code, order, selectedClassifications } = g04docuGCertification;
      //             if (!ele) {
      //               processItems.push({
      //                 gclientId: gclient.id,
      //                 gclient,
      //                 items: [{
      //                   id,
      //                   name,
      //                   code,
      //                   g04docuGCertificationId: id,
      //                   g04docuGCertificationName: name,
      //                   itemType: 'PROCESS',
      //                   specs: [{
      //                     id: specification,
      //                     name: specification.replaceAll("|", " ").replaceAll("+", " + "),
      //                     code: specification,
      //                     elements: specificationElements,
      //                     selectedClassifications: selectedClassifications,
      //                   }],
      //                   order,
      //                 }],
      //               })
      //             } else {
      //               processItems.forEach(processItem => {
      //                 if (processItem.gclientId === gclient.id) {
      //                   const index = processItem.items.findIndex(i => i.id === id);
      //                   if (index === -1) {
      //                     processItem.items.push({
      //                       id,
      //                       name,
      //                       code,
      //                       g04docuGCertificationId: id,
      //                       g04docuGCertificationName: name,
      //                       itemType: 'PROCESS',
      //                       specs: [{
      //                         id: specification,
      //                         name: specification.replaceAll("|", " ").replaceAll("+", " + "),
      //                         code: specification,
      //                         elements: specificationElements,
      //                         selectedClassifications: selectedClassifications,
      //                       }],
      //                       order,
      //                     });
      //                   } else {
      //                     if (!processItem.items[index].specs.find(t2 => t2.id === specification)) {
      //                       // TODO : 추후 specs 정렬 필요?
      //                       processItem.items[index].specs.push({
      //                         id: specification,
      //                         name: specification.replaceAll("|", " ").replaceAll("+", " + "),
      //                         code: specification,
      //                         elements: specificationElements,
      //                         selectedClassifications: g04docuGCertification.selectedClassifications,
      //                       });
      //                     }
      //                   }
      //                 }
      //               })
      //             }
      //           })
      //         })
              
      //         // const specificationElement = [];
      //         // let fullSpecification = "";
      //         // selectedGcomponentItems.forEach((gtypeDetails, i) => {
      //         //   let productName = "";
                
      //         //   // specification 구성 및 specification 구성부품 마련
      //         //   gtypeDetails.forEach((item, j) => {
      //         //     if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
      //         //       return;
      //         //     }
                  
      //         //     if (Array.isArray(item.value)) {
      //         //       item.value.forEach(i => {
      //         //         const { applyType, code } = i;
      //         //         if (applyType === 'productName') {
      //         //           productName += code ? `${code}|` : "";
      //         //           specificationElement.push({ code: i.code, value: i.value });
      //         //         }
      //         //       })
      //         //     } else {
      //         //       const { applyType, code } = item.value;
      //         //       if (applyType === 'productName') {
      //         //         productName += code ? `${code}|` : "";
      //         //         specificationElement.push({ code: item.code, value: item.value });
      //         //       }
      //         //     }
      //         //   })
      //         //   productName = productName.substring(0, productName.length - 1);
      //         //   fullSpecification += (productName !== "" ? `${productName}+` : "");
      //         // })
              
      //         // fullSpecification = fullSpecification.substring(0, fullSpecification.length - 1);
              
      //         // // 참고 : F/T(강화) F/T HST(강화) H/S(배강도). 
      //         // selectedProcessGClients?.forEach((process, i) => { // PROCESS 인증규격이 모두 들어가 있음
      //         //   // console.log(process);
      //         //   const { code, value } = process; // value는 가공업체 정보
                
      //         //   if (value.length > 0) {
      //         //     // TODO : 가공방법에 따라 두께 정리. 로직 검토 필요
      //         //     const specifications = [];
      //         //     // <<<주의 : 코드 하드코딩 사용>>>
      //         //     if (code === 'HEAT_STRENGTHENED') { // 배강도 (단판) => TODO : 유리품종안에 여러개의 배강도가 있을 수 있음. specificationElement에서 찾아서 조합해야 할 것 같음(아래의 접합처럼)
      //         //       const spec = specificationElement.map(s => s.value.code).join("|");
      //         //       specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
      //         //     } else if (code === 'TEMPERED') { // 완전강화 (단판)
      //         //       const spec = specificationElement.map(s => s.value.code).join("|");
      //         //       specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
      //         //     } else if (code === 'LAMINATED') { // 접합
      //         //       console.log(specificationElement);
                    
      //         //       // TODO : 접합필름 앞뒤의 유리원판 두께와 필름 두께를 합해야 한다.
      //         //       specificationElement.forEach((t, i) => {
      //         //         let filmIndex = 0;

      //         //         // 필름 앞 뒤의 유리 원판을 구한다.
      //         //         // 배열안에서 앞 유리원판의 하나앞 원소가 유리 두께. 여기서부터 뒤 유리원판까지의 specification을 구한다.
      //         //         if (t.code === 'FILM THICKNESS') { // 필름 앞 위의 유리 원판을 구하여 그 두께를 합한다.
      //         //           // 현재 필름 앞에서 제일 가까이 있는 유리 원판 찾을 것
      //         //           filmIndex = i;
      //         //           let firstIndex = 0;
      //         //           let lastIndex = 0;
      //         //           for(let j=i-1; j>=0; j--) {
      //         //             if (specificationElement[j].code === 'GLASS PRODUCT NAME') {
      //         //               firstIndex = j;
      //         //               break;
      //         //             }
      //         //           }
                        
      //         //           // 현재 필름 뒤부터 제일 앞에 있는 유리 원판 찾을 것
      //         //           for(let j=0; j<specificationElement.length-1; j++) { // TODO : let j=i 아닐까???
      //         //             if (i < j && specificationElement[j].code === 'GLASS PRODUCT NAME') {
      //         //               lastIndex = j;
      //         //               break;
      //         //             }
      //         //           }

      //         //           firstIndex = firstIndex - 1;
      //         //           lastIndex = lastIndex + 1;
      //         //           console.log(`first: ${firstIndex}, last: ${lastIndex}`)
      //         //           const laminated = specificationElement.slice(firstIndex, lastIndex);
      //         //           let laminatedSpecification = "";
      //         //           console.log(laminated);
      //         //           laminated.forEach((s, idx) => {
      //         //             laminatedSpecification = laminatedSpecification + s.value.code + (idx === i-1 || idx === i+1 ? "+" : "|");
      //         //           })
      //         //           console.log(laminatedSpecification)
      //         //           const spec = laminatedSpecification.substring(0, laminatedSpecification.length - 1);
      //         //           specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
      //         //           // film = specificationElement[i].value.code;

      //         //           // if (preElem && film && nextElem) {
      //         //           //   specifications.push((parseFloat(preElem) + parseFloat(film) + parseFloat(nextElem)).toString());
      //         //           // }
      //         //         }
      //         //       });

      //         //       console.log(specifications);
      //         //     } else if (code === 'INSULATED_GLASS_UNIT') { // 복층
      //         //       specifications.push({ id: fullSpecification, name: fullSpecification, code: fullSpecification, elements: specificationElement });
      //         //     }
                
      //         //     value.forEach(value => {
      //         //       const items = []; // 가공방법

      //         //       const ele = processItems.find(processItem => processItem.gclientId === value.id);
      //         //       if (!ele) {
      //         //         const { id, name, code } = process;
      //         //         // items.push({ ...process, g04docuGCertificationId: process.id, thickness: thicknesses }); // ...process 하면 중복된 데이터도 들어감
      //         //         items.push({ id, name, code, g04docuGCertificationId: process.id, itemType: 'PROCESS', specs: specifications });

      //         //         processItems.push({
      //         //           gclientId: value.id,
      //         //           gclient: value,
      //         //           items,
      //         //         })
      //         //       } else {
      //         //         processItems.forEach(processItem => {
      //         //           if (processItem.gclientId === value.id) {
      //         //             const index = processItem.items.findIndex(i => i.id === process.id);
      //         //             if (index === -1) {
      //         //               processItem.items.push({ ...process, g04docuGCertificationId: process.id, itemType: 'PROCESS', specs: specifications });
      //         //             } else {
      //         //               // 중복제거
      //         //               // 실제로 specifications는 length가 1이지만 배열이라는 가정하에...
      //         //               specifications.forEach(t1 => {
      //         //                 if (!processItem.items[index].specs.find(t2 => t2 === t1)) {
      //         //                   processItem.items[index].specs.push(t1);
      //         //                 }
      //         //               })
      //         //               // const thicknessesNoDuplicated = [...new Set(processItem.items[index].thickness)]; // 중복제거
      //         //               // console.log(thicknessesNoDuplicated)
      //         //             }
      //         //           }
      //         //         })
      //         //       }
      //         //     })
      //         //   }
      //         // })
      //       })

      //       console.log({ processItems });
      //       compositeTreeItems(processItems, processItemsForTree, relatedGClients, securityOpenedGClientsAboutMe); // TODO : 임시주석
      //     }

      //     // 3. 가공부자재, 4. 시공부자재
      //     [1, 2].forEach((element, i) => {
      //       const selectedSubMaterialItems = JSON.parse(JSON.stringify(i === 0 ? selectedSubMaterialProcessItems.filter(smpi => smpi.usable) : selectedSubMaterialBuildItems.filter(smbi => smbi.usable)));
      //       if (selectedSubMaterialItems && selectedSubMaterialItems.length > 0) {
      //         const subMaterialItems = [];
      //         const gclientIds = [];
      //         console.log(selectedSubMaterialItems)
      //         selectedSubMaterialItems.forEach((item, i) => {
      //           // item 구조
      //           // id: G04docus.id. 해당 아이템에 연결된 아이디. (GComponentItems 또는 GSubItems의 g04docuId). 참고로 G04docus에서 실제 아이템의 상위이므로 GClientG04docuMaps에는 연결되지 않는다.
      //           // name : 해당 아이템의 상위 GComponents 또는 GSubs의 name(type = 'group')
      //           // index : 같은 제품을 여러개 선택할 수 있으므로 구분짓기 위한 인덱스 순서
      //           // value : 선택한 GComponentItems 또는 GSubItems의 실제 내용. 상세내용은 아래에...
      //           // usable : 사용여부 (자재승인서류에 포함할지 안할지 체크하는 부분)
      //           const items = [];
      //           if (item.value.selectedGClientId) { // 해당 아이템의 제조사롤 선택한 거래선
      //             const ele = gclientIds.find(id => id === item.value.selectedGClientId);
      //             // value 구조 예
      //             // "id": "0f9661a2-4263-48d2-b010-4e239fc2f245",
      //             // "gId": "e7cbad89-3c56-474e-8598-4d3f1063e689",
      //             // "code": "SWS 간봉",
      //             // "name": "Swisspacer",
      //             // "type": "etc",
      //             // "order": 10,
      //             // "price": null,
      //             // "comments": "SST 0.1T + Alum Foil",
      //             // "gclients": [
      //             //   {
      //             //     "gclient": {
      //             //       "id": "874d3817-5775-4511-90fb-4c6c7989387a",
      //             //       "code": "SWISSPACER",
      //             //       "name": "SWISSPACER",
      //             //       "order": 8,
      //             //       "phone": "",
      //             //       "address": "",
      //             //       "comments": "간봉/스위스페이서",
      //             //       "createdAt": "2022-04-05T03:24:21.454Z",
      //             //       "systemUrl": null,
      //             //       "updatedAt": "2022-04-13T14:57:45.810Z",
      //             //       "orderDetail": "",
      //             //       "gclientTypes": [
      //             //         {
      //             //           "id": 8,
      //             //           "code": "MANUFACTURER",
      //             //           "name": "제조사",
      //             //           "color": "#9013fe",
      //             //           "order": 8,
      //             //           "comments": "제조사",
      //             //           "createdAt": "2022-04-05T03:21:17.321Z",
      //             //           "updatedAt": "2022-04-13T14:27:47.563Z",
      //             //           "orderDetail": "L"
      //             //         }
      //             //       ],
      //             //       "inChargeName": "",
      //             //       "inChargeEmail": "",
      //             //       "inChargePhone": ""
      //             //     }
      //             //   }
      //             // ],
      //             // "createdAt": "2022-03-23T05:41:39.48+00:00",
      //             // "g04docuId": "34d66dee-30d7-44b3-acde-2359e72744e2", // 실제 선택된 아이템의 g04docuId가 아닌 상위 아이디임에 주의
      //             // "updatedAt": "2023-03-14T06:50:08.546+00:00",
      //             // "orderDetail": "",
      //             // "itemType": "SUB_MATERIAL_PROCESS",
      //             // "selectedGClientId": "874d3817-5775-4511-90fb-4c6c7989387a",
      //             // "dependentGcomponentItem": ""
      //             const { id, gId, code, name, comments, g04docuGCertificationId, itemType } = item.value; // TODO : 해당 아이템과 연결된 GClientG04docuMaps의 g04docuId가 추가적으로 필요함. 실제 문서 관련
      //             if (!ele) {
      //               // items에 규격, specs에 제품이 들어가도록 한다. => item에 규격정보, item.value가 제품임
      //               /**
      //                * 근거 : 시가공부자재는 원판이나 가공처럼 "제품 > 스펙" 구조가 아니다. (예, Clear > 3T, 4T, ...)
      //                * 그래서 성적서의 계층구조를 유지하면서 시가공부자재의 특성을 고려하여 "규격 > 제품" 구조로 설계
      //                * TODO : 이럴 경우 성적서 등록 화면에서는 해당 제품에 여러 스펙의 성적서를 등록할 수 있는데 시가공제품의 경우 하나만 등록하게 하거나 여러개 등록 후 대표성적서를 선택하게 하는 방법이 있을 수 있음
      //                *  */
      //               gclientIds.push(item.value.selectedGClientId); // 같은 거래선의 아이템끼리 묶기 위해 사용

      //               items.push({ id: item.id/*, gId: null*/, code: item.code, name: item.name/*, comments: null, g04docuGCertificationId: null*/, specs: [{ id, gId, code, name, comments, g04docuGCertificationId }], itemType});
      //               subMaterialItems.push({
      //                 gclientId: item.value.selectedGClientId,
      //                 // 임시 : gclient 필요정보 점검
      //                 // gclient: item.value.gclients.find(gclient => gclient.gclient.id === item.value.selectedGClientId)?.gclient,
      //                 gclient: item.value.gclients.find(gclient => gclient.id === item.value.selectedGClientId),
      //                 items,
      //               });
      //             } else if (ele) {
      //               subMaterialItems.forEach(subMaterialItem => {
      //                 if (subMaterialItem.gclientId === item.value.selectedGClientId) { // 같은 거래선(제조사) 하위에 추가
      //                   const foundItem = subMaterialItem.items.find(smItem => smItem.id === item.id); // 같은 규격(개념적으로 같은 제품군)이면 해당 규격아래로 제품을 추가한다.
      //                   if (foundItem) {
      //                     foundItem.specs.push({ id, gId, code, name, comments, g04docuGCertificationId });
      //                   } else { // 규격이 다르면 새 규격과 제품을 등록한다.
      //                     subMaterialItem.items.push({ id: item.id/*, gId: null*/, code: item.code, name: item.name/*, comments: null, g04docuGCertificationId: null*/, specs: [{ id, gId, code, name, comments, g04docuGCertificationId }], itemType });
      //                   }
      //                 }
      //               })
      //             }
      //           }
      //         })

      //         console.log({ subMaterialItems });
      //         compositeTreeItems(subMaterialItems, i === 0 ? subMaterialProcessItemsForTree : subMaterialBuildItemsForTree, relatedGClients, securityOpenedGClientsAboutMe); // TODO : 임시주석
      //       }
      //     })

      //     const data = [
      //       {
      //         id: 'SUPPLIER',
      //         label: '공급업체',
      //         type: 'DIVISION',
      //       },
      //       {
      //         id: 'RAW_MATERIAL',
      //         label: '원자재',
      //         type: 'DIVISION',
      //       },
      //       {
      //         id: 'PROCESS',
      //         label: '가공',
      //         type: 'DIVISION',
      //       },
      //       {
      //         id: 'SUB_MATERIAL_PROCESS',
      //         label: '가공부자재',
      //         type: 'DIVISION',
      //       },
      //       {
      //         id: 'SUB_MATERIAL_BUILD',
      //         label: '시공부자재',
      //         type: 'DIVISION',
      //       },
      //     ];

      //     // console.log(sessionUser);
      //     const { id, name } = sessionUser;
      //     const gprojectId = selectedRow.id;
      //     const supplierForTree = [{
      //       id: `${gprojectId}/${id}`,
      //       label: name,
      //       type: 'CLIENT',
      //       data: { gclientId: id, gclient: { ...sessionUser, opened: true } },
      //       children: [
      //         {
      //           id: `${gprojectId}/${id}/general`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
      //           label: "일반서류",
      //           data: {
      //             // gclientId: id,
      //             // gclient: sessionUser,
      //           },
      //           type: 'GENERALS',
      //         },
      //         // TODO: 공급업체에 인증서나 성적서가 필요한가???
      //         // {
      //         //   id: `${gprojectId}/${id}/certification`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
      //         //   label: "인증서",
      //         //   data: {
      //         //     // gclientId: id,
      //         //     // gclient: sessionUser,
      //         //   },
      //         //   type: 'CERTIFICATIONS',
      //         // },
      //         // {
      //         //   id: `${gprojectId}/${id}/test`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
      //         //   label: "성적서",
      //         //   data: {
      //         //     // gclientId: id,
      //         //     // gclient: sessionUser,
      //         //   },
      //         //   type: 'TESTS',
      //         // },
      //         {
      //           id: `${gprojectId}/${id}/etc`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
      //           label: "기타",
      //           data: {
      //             // gclientId: id,
      //             // gclient: sessionUser,
      //           },
      //           type: 'ETCS',
      //         }
      //       ]
      //     }];

      //     data.find(i => i.id === 'SUPPLIER').children = supplierForTree; // TODO : 임시주석
      //     data.find(i => i.id === 'RAW_MATERIAL').children = rawMaterialItemsForTree; // TODO : 임시주석
      //     data.find(i => i.id === 'PROCESS').children = processItemsForTree; // TODO : 임시주석
      //     data.find(i => i.id === 'SUB_MATERIAL_PROCESS').children = subMaterialProcessItemsForTree; // TODO : 임시주석
      //     data.find(i => i.id === 'SUB_MATERIAL_BUILD').children = subMaterialBuildItemsForTree; // TODO : 임시주석
          
      //     // const processItems = data.find(i => i.id === 'SUB_MATERIAL_PROCESS');
      //     // if (processItems) {
      //     //   processItems.children = subMaterialProcessItemsForTree;
      //     // }

      //     // const buildItems = data.find(i => i.id === 'SUB_MATERIAL_BUILD');
      //     // if (buildItems) {
      //     //   buildItems.children = subMaterialBuildItemsForTree;
      //     // }

      //     // data[2].children = subMaterialProcessItemsForTree;
      //     // data[3].children = subMaterialBuildItemsForTree;

      //     // console.log(JSON.stringify(data, null, 2));
          
      //     const resData = await generateDirect(data, gprojectId); // TODO : 임시주석
      //     // const resData = data;
      //     console.log(data);
      //     console.log(resData);

      //     setG04docuDataWithoutDocus(data);
      //     setG04docuDataWithDocus(resData);

      //     // 필요 자재승인서류 내 거래선과 securityOpenedGClientsAboutMe 비교하여 해당 비공개 거래선에 대하여 안내한다.
      //     // const closedAboutMe = relatedGClients.filter(a => securityOpenedGClientsAboutMe.some(b => a.id !== b.id));
      //     setGClientsInMaterialApproval(relatedGClients);

      //     const closedAboutMe = compare(relatedGClients, securityOpenedGClientsAboutMe)
      //     if (closedAboutMe.length > 0) {
      //       // console.log(closedAboutMe)
      //       const closedAboutMeWithStatus = await selectGClientsBySecurityRequestStatusByQueryDirect({ senderId: sessionUser.id, receiverIds: closedAboutMe.map(gc => gc.id) });
      //       setClosedGClientList(closedAboutMeWithStatus);
      //       setOpenRequest(true);
      //     }
      //   }
      // }
      regenerate(selectedRow);
    }, [selectedRow]
  );

  const regenerate = async (selRow) => {
    if (selRow) {
      // TODO : 여기서 최신의 회원사 정보를 조회하여 사용하는게 맞겠다.
      const newGClients = await selectAllGClientsDirect();

      const relatedGClients = [];
      console.log(selRow);
      const gdomesticAuths = [];
      const rawMaterialItemsForTree = [];
      const processItemsForTree = [];
      const subMaterialProcessItemsForTree = [];
      const subMaterialBuildItemsForTree = [];
      if (selRow) {
        const service = '04docu';
        const securityOpenedGClientsAboutMe = await selectOpenedAboutMeGClientListDirect(service, sessionUser.id);

        const { selectedGlasses, selectedSubMaterialProcessItems, selectedSubMaterialBuildItems } = selRow;
        console.log(selectedGlasses);
        if (selectedGlasses && selectedGlasses.length > 0) {
          const gcomponentItems = []; // 원자재용
          const processItems = []; // 가공용
          const glasses = JSON.parse(JSON.stringify(selectedGlasses)); // TODO : 변환 필요한건지 추후 검토
          const gclientIds = [];
          
          // 1. 원자재
          glasses.forEach(glass => {
            glass.selectedGcomponentItems.forEach((gtypeDetails, i) => {
              let thickness = []; // 각 레이어에서 유리 두께는 하나임
              gtypeDetails.forEach((item, j) => {
                const items = [];
                // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
                if (j === 0) {
                  return;
                }

                console.log(item);
                if (item.code === 'GL THICKNESS') { // 유리 두께일 경우
                  // thickness.push(item);
                  thickness.push(item.value);
                }

                // 2024.08.07 원자재는 유리원판에 대해서만 모은다. 기존에는 제조사가 있는 제품(예를 들면 접합필름 등)이 원자재에 보이는 문제가 있었음
                if (item.code === 'GLASS PRODUCT NAME' && item.value?.selectedGClientId) { // 해당 아이템의 제조사를 선택한 경우 (제조사 선택을 하지 않으면 목록에서 빠짐)
                  const ele = gclientIds.find(id => id === item.value?.selectedGClientId)
                  console.log(item.value)
                  const { id, gcomponentId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName, selectedClassifications, itemType, order } = item.value;
                  if (!ele) {
                    gclientIds.push(item.value.selectedGClientId); // 같은 거래선의 아이템끼리 묶기 위해 사용
                    // 같은 아이템이 있으면 pass. TODO : 이 코드가 필요한가??? 거래선이 없어서 처음 추가한 거라면 거래선 하위에 아이템도 없을 것이고 위 반복문 (gtypeDetails.forEach((item, j) => {)을 보면 그 하위에 items 배열이 있으므로 늘 같은 아이템은 없어보임
                    // const sameItem = items.find(i => i.id === id);
                    // if (!sameItem) {
                      if (item.code === 'GLASS PRODUCT NAME') {
                        console.log(item.value)
                        items.push({ id, gcomponentId, code, name, gcomponentItemName: name, comments, g04docuGCertificationId, g04docuGCertificationName, specs: thickness.map(t => ({ ...t, selectedClassifications })), itemType, order });
                      }/* else {
                        items.push({ id, gcomponentId, code, name, comments, g04docuGCertificationId, itemType }); // TODO : 원판이 아닌 경우 이 코드가 필요한가?
                      }*/
                    // }

                    gcomponentItems.push({
                      gclientId: item.value.selectedGClientId,
                      // 임시 : gclient 필요정보 점검
                      // gclient: item.value.gclients.find(gclient => gclient.gclient.id === item.value.selectedGClientId)?.gclient,
                      gclient: item.value.gclients.find(gclient => gclient.id === item.value.selectedGClientId),
                      items,
                    });
                  } else if (ele) {
                    // if (!gcomponentItems[gcomponentItems.length - 1].items.find(i => i.id === id)) { // TODO : 맨 마지막이 맞음??? 틀리네... 해당 거래선을 찾아야 함
                    //   gcomponentItems[gcomponentItems.length - 1].items.push({ id, /*gId*/gcomponentId, code, name, comments, g04docuGCertificationId });
                    // } else {
                    //   // "유리 원판 제품명"일 경우 유리두께 정보를 배열로 추가해야 한다.
                    //   if (item.code === 'GLASS PRODUCT NAME') {
                    //     // gcomponentItems[gcomponentItems.findIndex(g => g.)].items.push({ id, /*gId*/gcomponentId, code, name, comments, g04docuGCertificationId });
                    //   }
                    // }
                    gcomponentItems.forEach(gcomponentItem => {
                      if (gcomponentItem.gclientId === item.value.selectedGClientId) {
                        // console.log(gcomponentItem.items.find(i => i.id === id))
                        // if (!gcomponentItem.items.find(i => i.id === id)) {
                        if (!gcomponentItem.items.find(i => i.id === id) && item.code === 'GLASS PRODUCT NAME') {
                          // gcomponentItem.items.push({ id, gcomponentId, code, name, comments, g04docuGCertificationId });
                          gcomponentItem.items.push({ id, gcomponentId, code, name, gcomponentItemName: name, comments, g04docuGCertificationId, g04docuGCertificationName, specs: thickness.map(t => ({ ...t, selectedClassifications })), itemType, order });
                        } else {
                          gcomponentItem.items.forEach(i => {
                            if (i.id === id && item.code === 'GLASS PRODUCT NAME') {
                              // i.thickness = i.thickness.concat(thickness);
                              if (i.specs.filter(t => t.id === thickness[0].id).length <= 0) {
                                i.specs = i.specs.concat(thickness).map(t => ({ ...t, selectedClassifications }));
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
            })
          })

          console.log({ gcomponentItems })
          compositeTreeItems(gcomponentItems, rawMaterialItemsForTree, relatedGClients, securityOpenedGClientsAboutMe, newGClients); // TODO : 임시주석

          // 2. 가공
          gclientIds.splice(0, gclientIds.length); // 배열 비우기
          glasses.forEach(glass => {
            const { selectedProcessGClients } = glass;
            console.log(selectedProcessGClients)
            // const certificationsOnly = selectedProcessGClients.filter(process => process.certificationOnly === true);
            // console.log(certificationsOnly)
            selectedProcessGClients?.forEach(process => {
              const { selectedGClients, specification, g04docuGCertification, specificationElements, type, performanceData } = process;
              selectedGClients?.forEach(gclient => {
                const ele = processItems.find(processItem => processItem.gclientId === gclient.id);
                const { id, name, code, order, selectedClassifications } = g04docuGCertification;
                if (!ele) {
                  processItems.push({
                    gclientId: gclient.id,
                    gclient,
                    items: [{
                      id,
                      name,
                      code,
                      g04docuGCertificationId: id,
                      g04docuGCertificationName: name,
                      g04docuGCertificationAdditionalId: process.g04docuGCertificationAdditional?.id,
                      g04docuGCertificationAdditionalName: process.g04docuGCertificationAdditional?.name,
                      itemType: 'PROCESS',
                      specs: [{
                        id: specification,
                        name: specification.replaceAll("|", " ").replaceAll("+", " + "),
                        code: specification,
                        elements: specificationElements,
                        selectedClassifications: selectedClassifications,
                        performanceData, // 성능확인서 추가
                      }],
                      order,
                    }],
                  });
                } else {
                  processItems.forEach(processItem => {
                    if (processItem.gclientId === gclient.id) {
                      const index = processItem.items.findIndex(i => i.id === id);
                      if (index === -1) {
                        processItem.items.push({
                          id,
                          name,
                          code,
                          g04docuGCertificationId: id,
                          g04docuGCertificationName: name,
                          g04docuGCertificationAdditionalId: process.g04docuGCertificationAdditional?.id,
                          g04docuGCertificationAdditionalName: process.g04docuGCertificationAdditional?.name,
                          itemType: 'PROCESS',
                          specs: [{
                            id: specification,
                            name: specification.replaceAll("|", " ").replaceAll("+", " + "),
                            code: specification,
                            elements: specificationElements,
                            selectedClassifications: selectedClassifications,
                            performanceData, // 성능확인서 추가
                          }],
                          order,
                        });
                      } else {
                        if (!processItem.items[index].specs.find(t2 => t2.id === specification)) {
                          // TODO : 추후 specs 정렬 필요?
                          processItem.items[index].specs.push({
                            id: specification,
                            name: specification.replaceAll("|", " ").replaceAll("+", " + "),
                            code: specification,
                            elements: specificationElements,
                            // selectedClassifications: g04docuGCertification.selectedClassifications,
                            selectedClassifications: selectedClassifications,
                            performanceData, // 성능확인서 추가
                          });
                        }
                      }
                    }
                  })
                }
              })
            })
            
            // const specificationElement = [];
            // let fullSpecification = "";
            // selectedGcomponentItems.forEach((gtypeDetails, i) => {
            //   let productName = "";
              
            //   // specification 구성 및 specification 구성부품 마련
            //   gtypeDetails.forEach((item, j) => {
            //     if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
            //       return;
            //     }
                
            //     if (Array.isArray(item.value)) {
            //       item.value.forEach(i => {
            //         const { applyType, code } = i;
            //         if (applyType === 'productName') {
            //           productName += code ? `${code}|` : "";
            //           specificationElement.push({ code: i.code, value: i.value });
            //         }
            //       })
            //     } else {
            //       const { applyType, code } = item.value;
            //       if (applyType === 'productName') {
            //         productName += code ? `${code}|` : "";
            //         specificationElement.push({ code: item.code, value: item.value });
            //       }
            //     }
            //   })
            //   productName = productName.substring(0, productName.length - 1);
            //   fullSpecification += (productName !== "" ? `${productName}+` : "");
            // })
            
            // fullSpecification = fullSpecification.substring(0, fullSpecification.length - 1);
            
            // // 참고 : F/T(강화) F/T HST(강화) H/S(배강도). 
            // selectedProcessGClients?.forEach((process, i) => { // PROCESS 인증규격이 모두 들어가 있음
            //   // console.log(process);
            //   const { code, value } = process; // value는 가공업체 정보
              
            //   if (value.length > 0) {
            //     // TODO : 가공방법에 따라 두께 정리. 로직 검토 필요
            //     const specifications = [];
            //     // <<<주의 : 코드 하드코딩 사용>>>
            //     if (code === 'HEAT_STRENGTHENED') { // 배강도 (단판) => TODO : 유리품종안에 여러개의 배강도가 있을 수 있음. specificationElement에서 찾아서 조합해야 할 것 같음(아래의 접합처럼)
            //       const spec = specificationElement.map(s => s.value.code).join("|");
            //       specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
            //     } else if (code === 'TEMPERED') { // 완전강화 (단판)
            //       const spec = specificationElement.map(s => s.value.code).join("|");
            //       specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
            //     } else if (code === 'LAMINATED') { // 접합
            //       console.log(specificationElement);
                  
            //       // TODO : 접합필름 앞뒤의 유리원판 두께와 필름 두께를 합해야 한다.
            //       specificationElement.forEach((t, i) => {
            //         let filmIndex = 0;

            //         // 필름 앞 뒤의 유리 원판을 구한다.
            //         // 배열안에서 앞 유리원판의 하나앞 원소가 유리 두께. 여기서부터 뒤 유리원판까지의 specification을 구한다.
            //         if (t.code === 'FILM THICKNESS') { // 필름 앞 위의 유리 원판을 구하여 그 두께를 합한다.
            //           // 현재 필름 앞에서 제일 가까이 있는 유리 원판 찾을 것
            //           filmIndex = i;
            //           let firstIndex = 0;
            //           let lastIndex = 0;
            //           for(let j=i-1; j>=0; j--) {
            //             if (specificationElement[j].code === 'GLASS PRODUCT NAME') {
            //               firstIndex = j;
            //               break;
            //             }
            //           }
                      
            //           // 현재 필름 뒤부터 제일 앞에 있는 유리 원판 찾을 것
            //           for(let j=0; j<specificationElement.length-1; j++) { // TODO : let j=i 아닐까???
            //             if (i < j && specificationElement[j].code === 'GLASS PRODUCT NAME') {
            //               lastIndex = j;
            //               break;
            //             }
            //           }

            //           firstIndex = firstIndex - 1;
            //           lastIndex = lastIndex + 1;
            //           console.log(`first: ${firstIndex}, last: ${lastIndex}`)
            //           const laminated = specificationElement.slice(firstIndex, lastIndex);
            //           let laminatedSpecification = "";
            //           console.log(laminated);
            //           laminated.forEach((s, idx) => {
            //             laminatedSpecification = laminatedSpecification + s.value.code + (idx === i-1 || idx === i+1 ? "+" : "|");
            //           })
            //           console.log(laminatedSpecification)
            //           const spec = laminatedSpecification.substring(0, laminatedSpecification.length - 1);
            //           specifications.push({ id: spec, name: spec, code: spec, elements: specificationElement });
            //           // film = specificationElement[i].value.code;

            //           // if (preElem && film && nextElem) {
            //           //   specifications.push((parseFloat(preElem) + parseFloat(film) + parseFloat(nextElem)).toString());
            //           // }
            //         }
            //       });

            //       console.log(specifications);
            //     } else if (code === 'INSULATED_GLASS_UNIT') { // 복층
            //       specifications.push({ id: fullSpecification, name: fullSpecification, code: fullSpecification, elements: specificationElement });
            //     }
              
            //     value.forEach(value => {
            //       const items = []; // 가공방법

            //       const ele = processItems.find(processItem => processItem.gclientId === value.id);
            //       if (!ele) {
            //         const { id, name, code } = process;
            //         // items.push({ ...process, g04docuGCertificationId: process.id, thickness: thicknesses }); // ...process 하면 중복된 데이터도 들어감
            //         items.push({ id, name, code, g04docuGCertificationId: process.id, itemType: 'PROCESS', specs: specifications });

            //         processItems.push({
            //           gclientId: value.id,
            //           gclient: value,
            //           items,
            //         })
            //       } else {
            //         processItems.forEach(processItem => {
            //           if (processItem.gclientId === value.id) {
            //             const index = processItem.items.findIndex(i => i.id === process.id);
            //             if (index === -1) {
            //               processItem.items.push({ ...process, g04docuGCertificationId: process.id, itemType: 'PROCESS', specs: specifications });
            //             } else {
            //               // 중복제거
            //               // 실제로 specifications는 length가 1이지만 배열이라는 가정하에...
            //               specifications.forEach(t1 => {
            //                 if (!processItem.items[index].specs.find(t2 => t2 === t1)) {
            //                   processItem.items[index].specs.push(t1);
            //                 }
            //               })
            //               // const thicknessesNoDuplicated = [...new Set(processItem.items[index].thickness)]; // 중복제거
            //               // console.log(thicknessesNoDuplicated)
            //             }
            //           }
            //         })
            //       }
            //     })
            //   }
            // })
          })

          console.log({ processItems });
          compositeTreeItems(processItems, processItemsForTree, relatedGClients, securityOpenedGClientsAboutMe, newGClients); // TODO : 임시주석
        }

        // 3. 가공부자재, 4. 시공부자재
        [1, 2].forEach((element, i) => {
          const selectedSubMaterialItems = JSON.parse(JSON.stringify(i === 0 ? selectedSubMaterialProcessItems.filter(smpi => smpi.usable) : selectedSubMaterialBuildItems.filter(smbi => smbi.usable)));
          if (selectedSubMaterialItems && selectedSubMaterialItems.length > 0) {
            const subMaterialItems = [];
            const gclientIds = [];
            console.log(selectedSubMaterialItems)
            selectedSubMaterialItems.forEach((item, i) => {
              // item 구조
              // id: G04docus.id. 해당 아이템에 연결된 아이디. (GComponentItems 또는 GSubItems의 g04docuId). 참고로 G04docus에서 실제 아이템의 상위이므로 GClientG04docuMaps에는 연결되지 않는다.
              // name : 해당 아이템의 상위 GComponents 또는 GSubs의 name(type = 'group')
              // index : 같은 제품을 여러개 선택할 수 있으므로 구분짓기 위한 인덱스 순서
              // value : 선택한 GComponentItems 또는 GSubItems의 실제 내용. 상세내용은 아래에...
              // usable : 사용여부 (자재승인서류에 포함할지 안할지 체크하는 부분)
              const items = [];
              if (item.value.selectedGClientId) { // 해당 아이템의 제조사롤 선택한 거래선
                const ele = gclientIds.find(id => id === item.value.selectedGClientId);
                // value 구조 예
                // "id": "0f9661a2-4263-48d2-b010-4e239fc2f245",
                // "gId": "e7cbad89-3c56-474e-8598-4d3f1063e689",
                // "code": "SWS 간봉",
                // "name": "Swisspacer",
                // "type": "etc",
                // "order": 10,
                // "price": null,
                // "comments": "SST 0.1T + Alum Foil",
                // "gclients": [
                //   {
                //     "gclient": {
                //       "id": "874d3817-5775-4511-90fb-4c6c7989387a",
                //       "code": "SWISSPACER",
                //       "name": "SWISSPACER",
                //       "order": 8,
                //       "phone": "",
                //       "address": "",
                //       "comments": "간봉/스위스페이서",
                //       "createdAt": "2022-04-05T03:24:21.454Z",
                //       "systemUrl": null,
                //       "updatedAt": "2022-04-13T14:57:45.810Z",
                //       "orderDetail": "",
                //       "gclientTypes": [
                //         {
                //           "id": 8,
                //           "code": "MANUFACTURER",
                //           "name": "제조사",
                //           "color": "#9013fe",
                //           "order": 8,
                //           "comments": "제조사",
                //           "createdAt": "2022-04-05T03:21:17.321Z",
                //           "updatedAt": "2022-04-13T14:27:47.563Z",
                //           "orderDetail": "L"
                //         }
                //       ],
                //       "inChargeName": "",
                //       "inChargeEmail": "",
                //       "inChargePhone": ""
                //     }
                //   }
                // ],
                // "createdAt": "2022-03-23T05:41:39.48+00:00",
                // "g04docuId": "34d66dee-30d7-44b3-acde-2359e72744e2", // 실제 선택된 아이템의 g04docuId가 아닌 상위 아이디임에 주의
                // "updatedAt": "2023-03-14T06:50:08.546+00:00",
                // "orderDetail": "",
                // "itemType": "SUB_MATERIAL_PROCESS",
                // "selectedGClientId": "874d3817-5775-4511-90fb-4c6c7989387a",
                // "dependentGcomponentItem": ""
                const { id, gId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName, itemType } = item.value; // TODO : 해당 아이템과 연결된 GClientG04docuMaps의 g04docuId가 추가적으로 필요함. 실제 문서 관련
                if (!ele) {
                  // items에 규격, specs에 제품이 들어가도록 한다. => item에 규격정보, item.value가 제품임
                  /**
                   * 근거 : 시가공부자재는 원판이나 가공처럼 "제품 > 스펙" 구조가 아니다. (예, Clear > 3T, 4T, ...)
                   * 그래서 성적서의 계층구조를 유지하면서 시가공부자재의 특성을 고려하여 "규격 > 제품" 구조로 설계
                   * => 자재승인서 구성시 가공부자재/시공부자재 탭에서 부자재 선택할 때 그룹별로 되어 있고 그 안에 제품을 선택하는 것을 상기하면 이해하기 쉬움)
                   * 
                   * (이전) 이럴 경우 성적서 등록 화면에서는 해당 제품에 여러 스펙의 성적서를 등록할 수 있는데 시가공제품의 경우 하나만 등록하게 하거나 여러개 등록 후 대표성적서를 선택하게 하는 방법이 있을 수 있음
                   * => (현재) 복수의 성적서를 등록하도록 변경
                   *  */
                  gclientIds.push(item.value.selectedGClientId); // 같은 거래선의 아이템끼리 묶기 위해 사용

                  items.push({ id: item.id/*, gId: null*/, code: item.code, name: item.name/*, comments: null, g04docuGCertificationId: null*/, specs: [{ id, gId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName }], itemType});
                  subMaterialItems.push({
                    gclientId: item.value.selectedGClientId,
                    // 임시 : gclient 필요정보 점검
                    // gclient: item.value.gclients.find(gclient => gclient.gclient.id === item.value.selectedGClientId)?.gclient,
                    gclient: item.value.gclients.find(gclient => gclient.id === item.value.selectedGClientId),
                    items,
                  });
                } else if (ele) {
                  subMaterialItems.forEach(subMaterialItem => {
                    if (subMaterialItem.gclientId === item.value.selectedGClientId) { // 같은 거래선(제조사) 하위에 추가
                      const foundItem = subMaterialItem.items.find(smItem => smItem.id === item.id); // 같은 규격(개념적으로 같은 제품군)이면 해당 규격아래로 제품을 추가한다.
                      if (foundItem) {
                        foundItem.specs.push({ id, gId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName });
                      } else { // 규격이 다르면 새 규격과 제품을 등록한다.
                        subMaterialItem.items.push({ id: item.id/*, gId: null*/, code: item.code, name: item.name/*, comments: null, g04docuGCertificationId: null*/, specs: [{ id, gId, code, name, comments, g04docuGCertificationId, g04docuGCertificationName }], itemType });
                      }
                    }
                  })
                }
              }
            })

            console.log({ subMaterialItems });
            compositeTreeItems(subMaterialItems, i === 0 ? subMaterialProcessItemsForTree : subMaterialBuildItemsForTree, relatedGClients, securityOpenedGClientsAboutMe, newGClients); // TODO : 임시주석
          }
        })

        const data = [
          {
            id: 'DOMESTIC_AUTH',
            label: '국내가공유리제품 사전 인증',
            type: 'DIVISION',
          },
          {
            id: 'SUPPLIER',
            label: '공급업체',
            type: 'DIVISION',
          },
          {
            id: 'RAW_MATERIAL',
            label: '원자재',
            type: 'DIVISION',
          },
          {
            id: 'PROCESS',
            label: '가공',
            type: 'DIVISION',
          },
          {
            id: 'SUB_MATERIAL_PROCESS',
            label: '가공부자재',
            type: 'DIVISION',
          },
          {
            id: 'SUB_MATERIAL_BUILD',
            label: '시공부자재',
            type: 'DIVISION',
          },
        ];

        // console.log(sessionUser);
        const { id, name } = sessionUser;
        const gprojectId = selRow.id;

        const gdomesticAuthsForTree = selectedGlasses.map((glass, i) => {
          const { no, gglassId, gglassName, specification, authYN, authName, types, authDocumentPath } = glass;
          const replacedSpecification = specification.replaceAll("|", " ").replaceAll("+", " + ");
          return {
            id: `${gprojectId}/${i}`,
            label: `${no}:${gglassName}:${replacedSpecification}`,
            type: 'DOMESTIC_AUTH',
            data: { gclientId: id, /*gclient: { ...sessionUser, opened: true }, */no, gglassId, gglassName, specification: replacedSpecification, authYN, authName, types, authDocumentPath },
          }
        });
        
        const supplierForTree = [{
          id: `${gprojectId}/${id}`,
          label: name,
          type: 'CLIENT',
          data: { gclientId: id, gclient: { ...sessionUser, opened: true } },
          children: [
            {
              id: `${gprojectId}/${id}/general`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
              label: "일반서류",
              data: {
                // gclientId: id,
                // gclient: sessionUser,
              },
              type: 'GENERALS',
            },
            // TODO: 공급업체에 인증서나 성적서가 필요한가???
            // {
            //   id: `${gprojectId}/${id}/certification`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
            //   label: "인증서",
            //   data: {
            //     // gclientId: id,
            //     // gclient: sessionUser,
            //   },
            //   type: 'CERTIFICATIONS',
            // },
            // {
            //   id: `${gprojectId}/${id}/test`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
            //   label: "성적서",
            //   data: {
            //     // gclientId: id,
            //     // gclient: sessionUser,
            //   },
            //   type: 'TESTS',
            // },
            {
              id: `${gprojectId}/${id}/etc`, // 트리에서 각 노드는 유일한 id를 가져야 하므로 상위/현재 아이디로 구성 ('/'를 연결자로 사용)
              label: "기타",
              data: {
                // gclientId: id,
                // gclient: sessionUser,
              },
              type: 'ETCS',
            }
          ]
        }];

        data.find(i => i.id === 'DOMESTIC_AUTH').children = gdomesticAuthsForTree; // TODO : 임시주석
        data.find(i => i.id === 'SUPPLIER').children = supplierForTree; // TODO : 임시주석
        data.find(i => i.id === 'RAW_MATERIAL').children = rawMaterialItemsForTree; // TODO : 임시주석
        data.find(i => i.id === 'PROCESS').children = processItemsForTree; // TODO : 임시주석
        data.find(i => i.id === 'SUB_MATERIAL_PROCESS').children = subMaterialProcessItemsForTree; // TODO : 임시주석
        data.find(i => i.id === 'SUB_MATERIAL_BUILD').children = subMaterialBuildItemsForTree; // TODO : 임시주석
        
        // const processItems = data.find(i => i.id === 'SUB_MATERIAL_PROCESS');
        // if (processItems) {
        //   processItems.children = subMaterialProcessItemsForTree;
        // }

        // const buildItems = data.find(i => i.id === 'SUB_MATERIAL_BUILD');
        // if (buildItems) {
        //   buildItems.children = subMaterialBuildItemsForTree;
        // }

        // data[2].children = subMaterialProcessItemsForTree;
        // data[3].children = subMaterialBuildItemsForTree;

        // console.log(JSON.stringify(data, null, 2));
        
        const resData = await generateDirect(data, gprojectId); // TODO : 임시주석
        // const resData = data;
        console.log(data);
        console.log(resData);

        setG04docuDataWithoutDocus(data);
        setG04docuDataWithDocus(resData);

        // 필요 자재승인서류 내 거래선과 securityOpenedGClientsAboutMe 비교하여 해당 비공개 거래선에 대하여 안내한다.
        // const closedAboutMe = relatedGClients.filter(a => securityOpenedGClientsAboutMe.some(b => a.id !== b.id));
        setGClientsInMaterialApproval(relatedGClients);

        const closedAboutMe = compare(relatedGClients, securityOpenedGClientsAboutMe);
        if (closedAboutMe.length > 0) {
          // console.log(closedAboutMe)
          const closedAboutMeWithStatus = await selectGClientsBySecurityRequestStatusByQueryDirect({ senderId: sessionUser.id, receiverIds: closedAboutMe.map(gc => gc.id) });
          setClosedGClientList(closedAboutMeWithStatus);
          setOpenRequest(true);
        }
      }
    }
  }

  const compare = (array1, array2) => array1.filter(i => !array2.some(o => i.id === o.id))

  // useEffect(
  //   async () => {
  //     setLoaded(false);
      
  //     // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
  //     setTimeout(async () => {
  //       await hideWatermark();
  //       setShowRequest(true);
  //       setLoaded(true);
  //     }, 300);
  //   }, [closedGClientList]
  // )

  useEffect(
    async () => {
      if (openRequest) {
        setLoaded(false);
      
        // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
        setTimeout(async () => {
          await hideWatermark();
          setShowRequest(true);
          setLoaded(true);
        }, 300);
      }
    }, [openRequest]
  )

  const reGenerate = async () => {
    // const resData = await generateDirect(g04docuDataWithoutDocus, selectedRow.id);
    // setG04docuDataWithDocus(resData);
    regenerate(selectedRow)
  }

  const initDialog = () => {
    // for (const [item, value] of Object.entries(defaultValues)) {
    //   setValue(item, value);
    // }

    // // 그외 초기화할 것들은 여기서 초기화
  }

  useEffect(
    async () => {
      if (open) {
        // console.log(securityOpenedGClientsAboutMe)
      }
    }, [open]
  )
  
  const handleDialogMinMax = () => {
    setFullScreen(!fullScreen);
  }

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace('D:\\Project\\core\\gcoreServer\\g04docu', 'http://localhost:5002/files/');
  //   setDocumentPath(path);
  // }

  // const handleClickViewMaterialApproval = (documentPath) => {
  //   const path = documentPath.replace('D:\\Project\\core\\gcoreServer\\g04docu', 'http://localhost:5002/files/');
  //   setDocumentPath(path);
  // }
  
  // TODO : 미리보기가 공통으로 많이 사용되므로 공통모듈로 이동할 것
  // TODO : 미리보기할 때 원본을 그대로 보여줄 경우 거래선의 문서들이 무작위로 배포될 우려가 있으므로 문서첨부시 미리보기용 문서를 만들고(원본문서+미리보기 표시) 그것을 보여줘야 한다.
  const handleClickViewEachDoc = (documentPath) => {
    const path = documentPath.replace(uploadFilePath, fileServerUrl);
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  const closeDrawerAndRegenerate = () => {
    const e = new Event(null);
      
    toggleDrawer("G04DOCU_GTEST_SUBSTITUTE", false)(e);  
    reGenerate();
  }

  const handleClickAddSubstituteTestDoc = (e, data) => {
    console.log(selectedRow)
    console.log(data)
    // setOpenSubstitute(true);
    
    const { id, site } = selectedRow;
    const { name, code, division, gclientId, g04docuGCertificationName, gcomponentItemName } = data;

    setDataForGTestSubstitute({
      source: "GProjectG04GeneratorDialog",
      params: {
        name: name,
        code: code,
        gclientId: gclientId,
        division: division,
        itemOrCertificationName: division === 'RAW_MATERIAL' ? gcomponentItemName : g04docuGCertificationName,
        gprojectId: id,
        site: site,
        closeDrawerAndRegenerate,
      }
    })

    // setShow(false); // 현재 다이얼로그를 숨기고 Drawer 열어야 함. 그래서 toggleDrawer 내부를 보면 닫힐 때 다시 다이얼로그를 보이게 하는 부분이 있음

    // toggleDrawer("G04DOCU_GTEST_SUBSTITUTE", true)(e);  
  }

  useEffect(
    () => {
      console.log(dataForGTestSubstitute)
      if (dataForGTestSubstitute) {
        setShow(false); // 현재 다이얼로그를 숨기고 Drawer 열어야 함. 그래서 toggleDrawer 내부를 보면 닫힐 때 다시 다이얼로그를 보이게 하는 부분이 있음
        const e = new Event(null);
      
        toggleDrawer("G04DOCU_GTEST_SUBSTITUTE", true)(e);  
      }
    }, [dataForGTestSubstitute]
  )

  useEffect(
    () => {
      console.log(dataForMaterialApproval)
      if (dataForMaterialApproval) {
        setShow(false); // 현재 다이얼로그를 숨기고 Drawer 열어야 함. 그래서 toggleDrawer 내부를 보면 닫힐 때 다시 다이얼로그를 보이게 하는 부분이 있음
        const e = new Event(null);
      
        toggleDrawer("G04DOCU_MATERIAL_APPROVAL", true)(e);  
      }
    }, [dataForMaterialApproval]
  )

  // const dataForMaterialApproval = () => {
  //   setOpenSubstitute(false);
  // }

  const handleClickViewMaterialApproval = (documentPath) => {
    const path = documentPath.replace(uploadFilePath, fileServerUrl);
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  useEffect(
    () => {
      const pgViewerWrapper = document.getElementsByClassName("pg-viewer-wrapper");
      const pgViewer = document.getElementById("pg-viewer");
      
      console.log(pgViewerWrapper)
      console.log(pgViewerWrapper[0])
      console.log(pgViewer)
      if (pgViewerWrapper && pgViewerWrapper.length > 0 && pgViewer) {
      // if (pgViewerWrapper && pgViewer) {
        pgViewer.style.backgroundColor = "grey";
        pgViewerWrapper[0].style.overflowY = "hidden";
      }
    }, [showFileViewer]
  )

  const onError = (e) => {
    console.log(e)
  }

  // TODO : G04docuMaterialApprovalDialog.js에서 사용중이므로 통합할 것
  const getFileType = (documentPath) => {
    let ext = "pdf";
    const pos = documentPath.lastIndexOf(".");
    if (pos > -1) {
      ext = documentPath.substring(pos+1);
    }

    return ext;
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    setDrawerState({ ...drawerState, [anchor]: open });

    if (!open) {
      // setOpen(true);
      setShow(true);
    }
  };

  const handleClickRequest = async (e, params) => {
    // console.log(params)
    const { id: receiverId } = params.row; // receiverId;

    await createSecurityRequest({ id: uuidv4(), senderId: sessionUser.id, receiverId, service: '04docu', status: '요청' });
    
    refreshClosedGClients();
  }

  const handleClickRequestAgain = async (e, params) => {
    // console.log(params)
    const { securityRequestId: id, id: receiverId } = params.row; // securityRequestId;

    await modifySecurityRequest({ id, senderId: sessionUser.id, receiverId, service: '04docu', status: '요청' });
    
    refreshClosedGClients();
  }

  const refreshClosedGClients = async () => {
    const service = '04docu';
    const securityOpenedGClientsAboutMe = await selectOpenedAboutMeGClientListDirect(service, sessionUser.id);
    const closedAboutMe = compare(gclientsInMaterialApproval, securityOpenedGClientsAboutMe)
    if (closedAboutMe.length > 0) {
      const closedAboutMeWithStatus = await selectGClientsBySecurityRequestStatusByQueryDirect({ senderId: sessionUser.id, receiverIds: closedAboutMe.map(gc => gc.id) });
      setClosedGClientList(closedAboutMeWithStatus);
    }

    // const closedAboutMeWithStatus = await selectGClientsBySecurityRequestStatusByQueryDirect({ senderId: sessionUser.id, receiverIds: closedGClientList.map(gc => gc.id) });
    // setClosedGClientList(closedAboutMeWithStatus);
  }

  const closeDrawer = () => {
    const e = new Event(null);
      
    toggleDrawer("G04DOCU_MATERIAL_APPROVAL", false)(e);
  }

  const showMaterialApproval = (data) => {
    setDataForMaterialApproval({
      source: "GProjectG04GeneratorDialog",
      params: {
        // gprojectId: selectedRow?.id,
        g04docuMaterialApprovalId: data.g04docuMaterialApproval.id,
        closeDrawer,
      }
    })
  }

  const columns = [
    {
      field: 'funcions',
      headerName: '기능 및 상태',
      width: 150,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { status } = params.row;
        if (status) {
          if (status === '요청') {
            return (
              <Button
                disabled
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/requesting.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          } else if (status === '비공개') {
            return (
              <Button
                onClick={(e) => handleClickRequestAgain(e, params)}
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/request-again.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          }
        } else {
          return (
            <Button
              onClick={(e) => handleClickRequest(e, params)}
              sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/request.png")', backgroundSize: "60px 60px" }}
            >             
            </Button>
          )
        }
      },
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      // editable: true,
    },
    {
      field: 'name',
      headerName: '거래선명',
      width: 240,
      // editable: true,
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 150,
      // editable: true,
    },
    {
      field: 'phone',
      headerName: '대표전화번호',
      width: 160,
      // editable: true,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
      // editable: true,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 240,
      // editable: true,
    },
  ];

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          onMinMax={handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          <div id="dialog-position" /*ref={dialogRef}*/>
          {/* <div
            id="dialog-position"
            ref={el => {
              if (el) {
                dialogTitleRect = el.getBoundingClientRect();
                // setDialogTitleBoundingClientRect()
              }
            }}
          > */}
            {"서류발급"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          {/* <pre>{JSON.stringify({ selected }, null, 2)}</pre> */}
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          {/* <SplitterLayout> */}
            <TreeView
              crudMode={crudMode}
              setCrudMode={setCrudMode}
              selected={selected}
              onSelect={setSelected}
              disableRoot
              itemsWithDocus={g04docuDataWithDocus}
              itemsWithoutDocus={g04docuDataWithoutDocus}
              handleClickViewEachDoc={handleClickViewEachDoc}
              handleClickAddSubstituteTestDoc={handleClickAddSubstituteTestDoc}
              handleClickViewMaterialApproval={handleClickViewMaterialApproval}
              disableMultiParentSelection={false}
              makeMaterialApproval={makeMaterialApproval}
              gclient={sessionUser}
              gproject={selectedRow}
              from={
                {
                  source: "GProjectG04GeneratorDialog",
                  // params: {
                  // }
                }
              }
              // toggleDrawer={toggleDrawer}
              showMaterialApproval={showMaterialApproval}
              setParentShow={setShow}
              reGenerate={reGenerate}
              closedGClientList={closedGClientList}
              setOpenRequest={setOpenRequest}
              refreshClosedGClients={refreshClosedGClients}
            />
            {/* <PdfViewer documentPath={documentPath} /> */}
            {/* <iframe width="100%" height="100%"></iframe> */}
            {/* {
              openProgress && (
                <Grid display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
                  <CircularProgress color="primary" />
                </Grid>
              )
            } */}
            {/* <div style={{ width: '100%', height: fileType !== 'pdf' ? '100%' : '' }}>
              {
                documentPath && showFileViewer && (
                  <>
                    <FileViewer
                      fileType={fileType}
                      filePath={documentPath}
                      // errorComponent={CustomErrorComponent}
                      onError={onError}
                    />
                  </>
                )
              }
            </div> */}
          {/* </SplitterLayout> */}
        </DialogContent>
        <DialogActions>
          {/* <LoadingButton
            size="small"
            onClick={() => handleSubmit(onSubmit)('save')}
            loading={loading}
          >
            {"저장"}
          </LoadingButton> */}
        </DialogActions>
      </Dialog>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerState["G04DOCU_MATERIAL_APPROVAL"]}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer("G04DOCU_MATERIAL_APPROVAL", false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer("G04DOCU_MATERIAL_APPROVAL", false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        <G04docuMaterialApprovalManagement
          // title={`서류보관함`}
          title={`자재승인서류 생성 결과`}
          from={dataForMaterialApproval}
          // from={
          //   {
          //     source: "GProjectG04GeneratorDialog",
          //     params: {
          //       gprojectId: selectedRow?.id,
          //     }
          //   }
          // }
        />
      </Drawer>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerState["G04DOCU_GTEST_SUBSTITUTE"]}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer("G04DOCU_GTEST_SUBSTITUTE", false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer("G04DOCU_GTEST_SUBSTITUTE", false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        <G04docuGTestManagement
          title={`대체성적서`}
          from={dataForGTestSubstitute}
        />
      </Drawer>
      {/* TODO : 추후 별도의 컴포넌트로 만들도록 할 것 */}
      {/* <Dialog
        open={openSubstitute}
        onClose={handleDialogSubstituteClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogSubstituteClose}
        >
          <div id="dialog-position">
            {"대체성적서 첨부"}
          </div>
        </DialogTitleClose>
        <DialogContent dividers>
          <div style={{ height: 800, width: '100%' }}>
            <Skeleton variant="rounded" height={800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: showByGComponentItem ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loadedByGComponentItem}
              rows={rowsTestByGComponentItem}
              columns={columnsByGComponentItem}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelectGTest({ type: 'detail', params })}
            />
          </div>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openRequest}
        onClose={handleRequestDialogClose}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        fullWidth
        scroll="body"
        sx={{ visibility: showRequest ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleRequestDialogClose}
        >
          <div id="dialog-position">
            {"서류 열람 안내"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            {/* TODO : Skeleton 처리를 하는게 보기 좋은지 아닌지 의견 수렴 필요 */}
            <Skeleton variant="rounded" height={showRequest ? 0 : 400} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              headerHeight={64}
              rowHeight={64}
              sx={{ visibility: showRequest ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em', height: showRequest ? 400 : 0 }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={closedGClientList}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestDialogClose}>
            {"닫기"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GProjectG04GeneratorDialog;
