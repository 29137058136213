import React, { useState, useEffect } from "react";

import {
  Button,
} from '@mui/material';

const AreaTypeButton = ({
  index,
  name,
  areaGUnits,
  type,
  onChange,
}) => {
  const [areaType, setAreaType] = useState("");

  useEffect(
    () => {
        setAreaType(type);
    }, [type]
  )

  const handleClick = () => {
    const length = areaGUnits.length;
    let pos = 0;
    areaGUnits.forEach((unit, idx) => {
      if (areaType === unit.code) {
        pos = (idx%length+1)%length;
        return false;
      }
    })
    
    const newAreaType = areaGUnits[pos].code;
    setAreaType(newAreaType);

    onChange(newAreaType, index, name);
  }

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
    >
      {areaType}
    </Button>
  );
}

export default AreaTypeButton;
