import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Fab,
  Grid,
  Paper,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  ListItem,
} from '@mui/material';
import  {
  Add,
  Delete,
  Remove,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDropdown,
  FormInputText,
  FormInputDate,
} from "../form";
import NumberFormatCustom from "../form/inputProps/NumberFormatCustom";
import {
  addDays,
  dateFormat,
  getTextColorByBackgroundColor,
} from "../../utils";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gunitActions from "../../store/gunit";
import * as gprocessActions from "../../store/gprocess";
import * as glineActions from "../../store/gline";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  gprocessId: null,
  comments: "",
};

const GLineDialog = ({
  modify,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [areaGUnits, setAreaGUnits] = useState([]);
  const [capacity, setCapacity] = useState({});
  const [glineCapacities, setGlineCapacities] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedGProcessColor, setSelectedGProcessColor] = useState("");

  const handleDialogClose = () => {
    setOpen(false);

    // ["id", "name", "gprocessId", "comments", "capacityType", "capacity"].forEach(item => {
    //   if (item === "capacityType") {
    //     // setCapacityType(selectedRow && selectedRow[item] || "amount");
    //   } else if (item === "capacity") {
    //     setCapacity({});
    //   } else {
    //     setValue(item, "");
    //   }
    // })
  };

  const gprocesses = useSelector((state) => state.gprocess.gprocesses);
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addGLine = ({ id, name, gprocessId, comments, glineCapacities }) => dispatch(glineActions.create({ id, name, gprocessId, comments, glineCapacities }))
  const modifyGLine = ({ id, name, gprocessId, comments, glineCapacities }) => dispatch(glineActions.modify({ id, name, gprocessId, comments, glineCapacities }))

  const selectAllProcesses = () => dispatch(gprocessActions.selectAll())

  const selectGUnitsAllByTypeDirect = (type) => gunitActions.selectAllByTypeDirect(type)

  const onSubmit = ({ id, name, gprocessId, comments }) => {
    setErrors([]);
    
    // console.log({ id, name, gprocessId, comments, glineCapacities });
    // return;
    let func;
    if (modify) {
      func = modifyGLine;
    } else {
      func = addGLine
    }

    func({ id, name, gprocessId, comments, glineCapacities: glineCapacities.filter(glineCapacity => glineCapacity.glineId !== "") })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      console.log(selectedRow);
      // const areaGUnits = await selectGUnitsAllByTypeDirect('area');
      // setAreaGUnits(areaGUnits);

      ["id", "name", "gprocessId", "gprocessColor", "comments"].forEach(item => {
        if (item === "id") {
          setValue(item, selectedRow && selectedRow[item] || uuidv4());
        } else if (item === "gprocessColor") {
          setSelectedGProcessColor(selectedRow && selectedRow[item]);
        } else {
          setValue(item, selectedRow && selectedRow[item] || "");
        }
      })

      await selectAllProcesses(); // 함수 상단에서 아래로 옮김
      
      if (selectedRow && selectedRow.glineCapacities && Array.isArray(selectedRow.glineCapacities) && selectedRow.glineCapacities.length > 0) {
        // setGlineCapacities(selectedRow.glineCapacities);
        setGlineCapacities(selectedRow.glineCapacities.map(glineCapacity => {
          const { validPeriodStart, validPeriodEnd } = glineCapacity;

          return {
            ...glineCapacity,
            validPeriodStart: (new Date(validPeriodStart)).getDateWithStartHours(), // TODO : DB에 제대로 저장되어 있다면 000Z 세팅 불필요할 것으로 보임
            validPeriodEnd: (new Date(validPeriodEnd)).getDateWithEndHours(), // TODO : DB에 제대로 저장되어 있다면 999Z 세팅 불필요할 것으로 보임
          }
        }));
      }
      else {
        // const now = new Date();
        const validPeriodStart = (new Date()).getDateWithStartHours();
        const validPeriodEnd = (new Date()).getDateWithEndHours();

        setGlineCapacities([{
          index: uuidv4(),
          glineId: getValues("id"),
          capacityType: "amount",
          capacity: "",
          // validPeriodStart: dateFormat(now, 'yyyy-MM-dd 00:00:00'),
          // validPeriodEnd: dateFormat(now, 'yyyy-MM-dd 00:00:00'),
          validPeriodStart,
          validPeriodEnd,
        }]);
      }
    }, [selectedRow]
  );

  const handleCapacity = (e, index, type) => {
    const newGlineCapacities = glineCapacities.map(item => {
      if (index === item.index) {
        return {
          ...item,
          capacity: {
            ...item.capacity,
            [type]: e.target.value,
          },
        }
      }

      return item;
    });

    console.log(newGlineCapacities);
    setGlineCapacities(newGlineCapacities);
  }

  const handleChangeCapacityType = (e, index) => {
    const newGlineCapacities = glineCapacities.map(item => {
      if (item.index === index) {
        return {
          ...item,
          capacityType: e.target.value,
        }
      }

      return item;
    });
    
    console.log(newGlineCapacities)
    setGlineCapacities(newGlineCapacities);
  }

  const handleChangePeriod = (value, index, name) => {
    // value는 Date 객체
    
    // TODO : 전에 구현하면서 miliseconds 부분이 날짜변경시 000Z로 초기화되는 것으로 파악했으나 여기서는 유지가 되고 있음. 확인 필요
    if (!value || value.toString() === "Invalid Date") {
      return false;
    }

    let validResult = true;
    const newGlineCapacities = glineCapacities.map((info, idx) => {
      const newInfo = Object.assign({}, info);
      if (index === info.index) {
        const validPeriodName = name.split("_")[0];
        // const preValue = info[validPeriodName].toLocaleString();
        
        // const preValue = dateFormat(info[validPeriodName], 'yyyy-MM-dd 00:00:00');
        // const newValue = dateFormat(value, 'yyyy-MM-dd 00:00:00');
        const preValue = info[validPeriodName];
        const newValue = value;
        
        if (validPeriodName === "validPeriodStart") {
          // const validPeriodEndCurrentRow = dateFormat(glineCapacities[idx]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
          const validPeriodEndCurrentRow = glineCapacities[idx]["validPeriodEnd"];
          if (newValue > validPeriodEndCurrentRow) {
            // TODO : 추후 상위로 옮기로 redux 사용하는 방법 고려
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: "유효기간 마감일자보다 늦습니다.",
              open: true,
            });

            newInfo[validPeriodName] = preValue;

            // newInfo.validPeriodStartColor = "error";
            // newInfo.validPeriodStartFocused = true;

            validResult = false;
            return newInfo;
          }
          
          if (idx > 0) {// 이전 유효마감일보다 이전이면 안됨
            // const validPeriodEndPrevRow = dateFormat(glineCapacities[idx-1]["validPeriodEnd"], 'yyyy-MM-dd 00:00:00');
            const validPeriodEndPrevRow = glineCapacities[idx-1]["validPeriodEnd"];
            if (newValue <= validPeriodEndPrevRow) {
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "이전 유효마감일자보다 빠릅니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;

              // newInfo.validPeriodStartColor = "error";
              // newInfo.validPeriodStartFocused = true;

              validResult = false;
              return newInfo;
            }
          }

          newInfo.validPeriodStartColor = "";
          newInfo.validPeriodStartFocused = false;
        } else { //validPeriodName === "valiePeriodEnd"
          // const validPeriodStartCurrentRow = dateFormat(glineCapacities[idx]["validPeriodStart"], 'yyyy-MM-dd 00:00:00');
          const validPeriodStartCurrentRow = glineCapacities[idx]["validPeriodStart"];
          if (newValue < validPeriodStartCurrentRow) {
            // TODO : 추후 상위로 옮기로 redux 사용하는 방법 고려
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: "유효기간 시작일자보다 빠릅니다.",
              open: true,
            });

            newInfo[validPeriodName] = preValue;

            // newInfo.validPeriodEndColor = "error";
            // newInfo.validPeriodEndFocused = true;

            validResult = false;
            return newInfo;
          }

          if (idx !== glineCapacities.length-1 ) {
            // const validPeriodStartPrevRow = dateFormat(glineCapacities[idx+1]["validPeriodStart"], 'yyyy-MM-dd 00:00:00');
            const validPeriodStartPrevRow = glineCapacities[idx+1]["validPeriodStart"];
            if (newValue >= validPeriodStartPrevRow) {
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "이후 유효시작일자보다 늦습니다.",
                open: true,
              });

              newInfo[validPeriodName] = preValue;

              // newInfo.validPeriodEndColor = "error";
              // newInfo.validPeriodEndFocused = true;

              validResult = false;
              return newInfo;
            }
          }
          newInfo.validPeriodEndColor = "";
          newInfo.validPeriodEndFocused = false;
        }

        // newInfo[validPeriodName] = value;
        newInfo[validPeriodName] = newValue;
      }
      return newInfo;
    });

    console.log(newGlineCapacities)
    setGlineCapacities(newGlineCapacities);

    return validResult;
  }

  // TODO : 추가 유효성 체크 필요
  const addCapacity = () => {
    const newGlineCapacities = glineCapacities.map(info => info);
    
    const nextDay = addDays(new Date(newGlineCapacities[newGlineCapacities.length-1]["validPeriodEnd"]), +1);
    const validPeriodStart = (new Date(nextDay)).getDateWithStartHours();
    const validPeriodEnd = (new Date(nextDay)).getDateWithEndHours();

    newGlineCapacities.push({
      index: uuidv4(),
      glineId: selectedRow.id,
      capacityType: "amount",
      capacity: "",
      // validPeriodStart: addDays(new Date(newGlineCapacities[newGlineCapacities.length-1]["validPeriodEnd"]), +1, 'yyyy-MM-dd 00:00:00'),
      // validPeriodEnd: dateFormat(new Date(), 'yyyy-MM-dd 00:00:00'),
      validPeriodStart,
      validPeriodEnd,
    });

    // console.log(newGlineCapacities);

    setGlineCapacities(newGlineCapacities);
  }

  // TODO : 삭제 유효성 체크 필요
  const deleteCapacity = (deleteInfo) => {
    const newGlineCapacities = glineCapacities.filter(info => info.index !== deleteInfo.index);

    console.log(newGlineCapacities);
    setGlineCapacities(newGlineCapacities);
  }

  const handleChangeSelectGProcess = (e, oldValue) => {
    const gprocess = gprocesses.find(gprocess => gprocess.id === e.target.value);
    if (gprocess) {
      setSelectedGProcessColor(gprocess.color)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          color={`${getTextColorByBackgroundColor(selectedGProcessColor)}`}
          style={{ cursor: 'move', backgroundColor: `${selectedGProcessColor}` }}
          buttonColor={`${getTextColorByBackgroundColor(selectedGProcessColor)}`}
        >
          {modify ? "공정라인 수정" : "공정라인 등록"}
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                label={"이름"}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputDropdown
                name={"gprocessId"}
                control={control}
                label={"공정"}
                options={
                  // 마지막 공정은 출고이므로 제외
                  gprocesses.filter((gprocess, idx) => idx < gprocesses.length - 1).map(gprocess => {
                    const { id, name, color } = gprocess;
                    return {
                      label: name,
                      value: id,
                      color,
                    }
                  })
                  // [
                  //   {
                  //     label: "길이",
                  //     value: "length",
                  //   },
                  //   {
                  //     label: "면적",
                  //     value: "area",
                  //   },
                  // ]
                }
                // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                onChangeSelectValue={handleChangeSelectGProcess}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControl>
                <FormLabel id="capacity-group-label" sx={{ mt: 2, mb: 2 }} >{"일일생산능력"}</FormLabel>
                <RadioGroup
                  // row
                  aria-labelledby="capacity-group-label"
                  name="capacity-group"
                  defaultValue="amount"
                  onChange={handleChange}
                  value={capacityType}
                >
                  <Grid container spacing={2} display="flex" alignItems="center">
                    <Grid item xs={2}>
                      <FormControlLabel value="area" control={<Radio />} label={"면적"} />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name={"area"}
                        size="small"
                        label=""
                        variant="outlined"
                        disabled={capacityType !== "area"}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            style: { textAlign: "right" },
                          }
                        }}
                        onChange={handleCapacity}
                        value={capacity?.area}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {"평"}
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel value="amount" control={<Radio />} label={"수량"} />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name={"amount"}
                        size="small"
                        label=""
                        variant="outlined"
                        disabled={capacityType !== "amount"}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            style: { textAlign: "right" },
                          }
                        }}
                        onChange={handleCapacity}
                        value={capacity?.amount}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {"EA"}
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputDate
                          // name={`validPeriodStart_${info.index}`}
                          control={control}
                          label={"유효기간 시작"}
                          // onChangePeriodValue={(value) => handleChangePeriod(value, info.index, `validPeriodStart_${info.index}`)}
                          // value={info.validPeriodStart}
                        />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputDate
                          // name={`validPeriodStart_${info.index}`}
                          control={control}
                          label={"유효기간 마감"}
                          // onChangePeriodValue={(value) => handleChangePeriod(value, info.index, `validPeriodStart_${info.index}`)}
                          // value={info.validPeriodStart}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} display="flex" justifyContent="flex-end" alignItems="center">
                      <Fab
                        color="primary"
                        size="small"
                        // variant="extended"
                        // disabled={disableAddPriceButton}
                        // onClick={addPrice}
                      >
                        <Add />
                      </Fab>
                              
                            </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl> */}
              <Divider sx={{ mt: 2 }} />
              <Grid item xs={12}>
                <Typography sx={{ mt: 3, mb: 2 }} variant="h6" component="div">
                  {"일일생산능력"}
                </Typography>
              </Grid>
              {
                glineCapacities.map((item, idx) => {
                  const { index, glineId, capacityType, capacity, validPeriodStart, validPeriodEnd, validPeriodStartColor, validPeriodStartFocused, validPeriodEndColor, validPeriodEndFocused } = item;
                  return (
                    <Grid container spacing={1} sx={{ mt: 2 }} key={idx}>
                      <Grid item xs={12} sm={2}>
                        <Grid container>
                          <Grid item display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography variant="subtitle2" component="div" color="primary" sx={{ mr: 1 }}>{"기준"}</Typography>
                            <ToggleButtonGroup
                              color="primary"
                              value={capacityType}
                              exclusive
                              size="small"
                              onChange={(e) => handleChangeCapacityType(e, index)}
                            >
                              <ToggleButton value="area">{"면적"}</ToggleButton>
                              <ToggleButton value="amount">{"수량"}</ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={8}>
                            {
                              capacityType === "amount" ? (
                                <FormInputText
                                  name={`amount_${index}`}
                                  control={control}
                                  // size="small"
                                  label=""
                                  variant="outlined"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    }
                                  }}
                                  onChange={(e) => handleCapacity(e, index, "amount")}
                                  value={capacity[capacityType] ? capacity[capacityType] : ""}
                                />
                              ) : (
                                <FormInputText
                                  name={`area_${index}`}
                                  control={control}
                                  // size="small"
                                  label=""
                                  variant="outlined"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    }
                                  }}
                                  onChange={(e) => handleCapacity(e, index, "area")}
                                  value={capacity[capacityType] ? capacity[capacityType] : ""}
                                />
                              )
                            }
                          </Grid>
                          <Grid item xs={12} sm={4} display="flex" alignItems="center">
                            {capacityType === "amount" ? "EA" : "평"}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormInputDate
                          name={`validPeriodStart_${index}`}
                          control={control}
                          label={"유효기간 시작"}
                          onChangePeriodValue={(value) => handleChangePeriod(value, index, `validPeriodStart_${index}`)}
                          // color={validPeriodStartColor}
                          // focused={validPeriodStartFocused}
                          value={validPeriodStart}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormInputDate
                          name={`validPeriodEnd_${index}`}
                          control={control}
                          label={"유효기간 마감"}
                          onChangePeriodValue={(value) => handleChangePeriod(value, index, `validPeriodEnd_${index}`)}
                          // color={validPeriodEndColor}
                          // focused={validPeriodEndFocused}
                          value={validPeriodEnd}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} display="flex" justifyContent="flex-end" alignItems="center">
                        {
                          idx === 0 ? (
                            <Fab
                              color="primary"
                              size="small"
                              onClick={addCapacity}
                            >
                              <Add />
                            </Fab>
                          ) : (
                            <Fab
                              color="secondary"
                              size="small"
                              onClick={() => deleteCapacity(item)}
                            >
                              <Remove />
                            </Fab>
                          )
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
            </Grid>
            {/* <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{"요일"}</TableCell>
                      <TableCell align="center">{"주간"}</TableCell>
                      <TableCell align="center">{"야간"}</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>{modify ? "수정" : "저장"}</Button>
          {/* TODO : 아래 코드 안되는 이유? */}
          {/* <Button onClick={reset}>{"초기화"}</Button> */}
          <Button onClick={() => reset()}>{"초기화"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        // open={openAlert}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GLineDialog;
