// import React, { useEffect, useState, useRef } from "react";
// import { useForm } from "react-hook-form";

// import {
//   Button,
//   Paper,
//   Typography,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   Dialog,
//   DialogContent,
//   Grid,
//   DialogActions,
// } from '@mui/material';

// import {
//   FormInputDate,
//   FormInputDateRangePicker,
//   FormInputDropdown,
//   FormInputMultipleCheckbox,
//   FormInputRadio,
//   FormInputSlider,
//   FormInputText,
//   FormInputSwitch,
//   FormInputToggleButton,
//   FormInputMultipleSelect,
//   FormInputCheckbox,
//   FormInputAutoComplete,
// } from "./components/form";

// import {
//   DialogTitleClose,
//   PaperComponent,
// } from "./components/dialog";

// import {
//   TextCell2Template,
// } from "./components/excelgrid";
// import { ReactGrid, Column, Row, DefaultCellTypes } from "@silevis/reactgrid";

// import FormDemoDialog from "./FormDemoDialog";

// // 아래 form components의 name과 연계
// const defaultValues = {
//   testAutoComplete: "",
//   textValue: "",
//   radioValue: "1",
//   checkBox: false,
//   checkboxValue: [],
//   dateValue: new Date(),
//   textSelectValue: "",
//   // dropdownValue: "", // undefined이면 아래 오류 발생하고 setValue("dropdownValue", "1") 동작시 unctrolled로 FormInputDropdown에 값 설정 안됨
//   // MUI: A component is changing the uncontrolled value state of Select to be controlled.
//   // Elements should not switch from uncontrolled to controlled (or vice versa).
//   // Decide between using a controlled or uncontrolled Select element for the lifetime of the component.
//   // The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.
//   // More info: https://fb.me/react-controlled-components
//   sliderValue: 0,
//   switchValue: false,
//   toggleButtonValue: "",
//   // textValue1: "",
// };

// const getPeople = () => [
//   { name: "Thomas", surname: "Goldman" },
//   { name: "Susie", surname: "Quattro" },
//   { name: "", surname: "" }
// ];

// const getColumns = () => [
//   { columnId: "name", width: 150 },
//   { columnId: "surname", width: 150 }
// ];

// const headerRow = {
//   rowId: "header",
//   cells: [
//     { type: "header", text: "Name" },
//     { type: "header", text: "Surname" }
//   ]
// };

// const getRows = (people) => [
//   headerRow,
//   ...people.map((person, idx) => ({
//     rowId: idx,
//     cells: [
//       { type: "textCell2", text: person.name },
//       { type: "textCell2", text: person.surname }
//     ]
//   }))
// ];

// const FormDemo = () => {

//   const [people, setPeople] = React.useState(getPeople());

//   const rows = getRows(people);
//   const columns = getColumns();

//   const [dropdownValue, setDropdownValue] = useState("1");
//   const [open, setOpen] = useState(false);
//   const [modify, setModify] = useState(false);

//   const inputRef = useRef();

//   // const [multiselectValue, setMultiselectValue] = useState([JSON.stringify({ id: 1, name: "발주처", code: "DEVELOPER" })]);
//   const [multiselectValue, setMultiselectValue] = useState([{ id: 1, name: "발주처", code: "DEVELOPER" }]);
//   // const [multiselectValue, setMultiselectValue] = useState([]);

//   /**
//    * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
//    * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
//    * the value changing from undefined to a defined value, which should not happen. Decide between using
//    * a controlled or uncontrolled input element for the lifetime of the component.
//    */
//   const { handleSubmit, reset, control, setValue, register, watch } = useForm({ defaultValues: defaultValues });

//   const { ref, ...rest } = register('textValue1');

//   const onSubmit = (data) => {
//     // console.log(inputRef.current)
//     // console.log(inputRef.current.value);
//     // inputRef.current.focus();
//     // inputRef.current.disabled = true;
//     // // inputRef.current.value = "2"
//     // setDropdownValue("2");
    
//     // setValue("textValue1", "1")
//     // setValue("textValue", "1")
//     // setValue("textSelectValue", "1")
//     // // setValue("dropdownValue", "2")
//     // setValue("switchValue", true)
//     // setValue("sliderValue", 20)
//     // setValue("dateValueRangePicker", ["2022-02-28T15:00:00.000Z", "2022-02-28T15:00:00.000Z"]);
//     console.log(data);
//   }

//   const handleChanges = (changes) => {
//     setPeople((prevPeople) => applyChangesToPeople(changes, prevPeople));
//   };

//   const applyChangesToPeople = (changes, prevPeople) => {
//     changes.forEach((change) => {
//       const personIndex = change.rowId;
//       const fieldName = change.columnId;
//       prevPeople[personIndex][fieldName] = change.newCell.text;
//     });

//     return [...prevPeople];
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//     // setTimeout(() => setModify(true), 3000)
//     setModify(true);
//   }

//   const options = [
//     { label: 'Option 1' },
//     { label: 'Option 2' },
//   ];

//   return (
//     <>
//       <Button onClick={handleSubmit(onSubmit)} variant={"contained"}>        
//         Submit
//       </Button>
//       <FormInputAutoComplete
//         name={"testAutoComplete"}
//         control={control}
//         label={"testAutoComplete"}
//         options={options}
//         setValue={setValue}
//       />
//       {/* <div>
//         <input type="checkbox" {...register('input-a', { required: true })} name="input-a" />
//         <input {...register('test2', { required: true })} name="input-b" disabled={!watch("input-a")} />
//       </div> */}
//       {/* <FormControl size={"small"} fullWidth>
//           <InputLabel>{"test"}</InputLabel>
//           <Select
//             name="name"
//             label={"test"}
//           >
//             <MenuItem key={"1"} value={"1"}>
//               {"1"}
//             </MenuItem>
//             <MenuItem key={"2"} value={"2"}>
//               {"2"}
//             </MenuItem>
//           </Select>
//         </FormControl> */}
//       {/* <Paper
//         style={{
//             display: "grid",
//             gridRowGap: "20px",
//             padding: "20px",
//             margin: "10px 300px",
//         }}
//       >
//         <Typography variant="h6"> Form Demo</Typography>
        
//         <FormInputMultipleSelect
//           id="multiple-chip"
//           name="multiSelectValue"
//           label={"sample"}
//           control={control}
//           setValue={setValue}
//           value={multiselectValue}
//           setMultiselectValue={setMultiselectValue}
//           options={[
//             {id: 1, name: "발주처", code: "DEVELOPER" },
//             {id: 2, name: "건설사", code: "CONSTRUCTOR" },
//             {id: 3, name: "창호업체", code: "WINDOW_PL" },
//             {id: 4, name: "커튼월업체", code: "WINDOW_AL" },
//             {id: 5, name: "시공업체", code: "INSTALLER" },
//             {id: 6, name: "가공업체", code: "FABRICATOR" },
//           ]}
//         />
//         <FormInputText
//           name={"textValue"}
//           control={control}
//           label={"Text Value"}
//         />
//         <FormInputText
//           name={"textValue1"}
//           control={control}
//           label={"Text Value"}
//           // inputRef={(e) => {
//           //   // alert("111")
//           //   // ref(e);
//           //   inputRef.current = e;
//           // }}
//         />
//         <FormInputText
//           select
//           setValue={setValue}
//           name={"textSelectValue"}
//           control={control}
//           label={"Text Select Value"}
//           options={
//             [
//               { label: "1", value: "1" },
//               { label: "2", value: "2" }
//             ]
//           }
//         />
//         <FormInputRadio
//           name={"radioValue"}
//           control={control}
//           label={"Radio Input"}
//           options = {[
//             {
//               label: "Radio Option 111",
//               value: "1"
//             },
//             {
//               label: "Radio Option 222",
//               value: "2"
//             },
//           ]}
//         />
//         <FormInputDropdown
//           value={dropdownValue}
//           name="dropdownValue"
//           control={control}
//           label="Dropdown Input"
//           inputRef={(e) => {
//             // alert("111")
//             // ref(e);
//             inputRef.current = e;
//           }}
//           options={[
//             {
//               label: "Dropdown Option 1",
//               value: "1"
//             },
//             {
//               label: "Dropdown Option 2",
//               value: "2"
//             },
//           ]}
//           // defaultValue="1"
//         />
        
//         <FormInputDate
//           name="dateValue"
//           control={control}
//           label="Date Input"
//           // value={getValues("dateValue")}
//           // setValue={setValue}
//         />
//         <FormInputDateRangePicker
//           name="dateValueRangePicker"
//           control={control}
//           startText={"start"}
//           endText={"end"}
//           setValue={setValue}
//           label="Date Input Range"
//           // value={["2022-02-28T15:00:00.000Z", "2022-02-28T15:00:00.000Z"]}
//         />
//         <FormInputMultipleCheckbox
//           control={control}
//           setValue={setValue}
//           name={"checkboxValue"}
//           label={"Checkbox Input"}
//           options={[
//             {
//               label: "Checkbox Option 1",
//               value: "1",
//             },
//             {
//               label: "Checkbox Option 2",
//               value: "2",
//             },
//           ]}
//         />
//         <FormInputSlider
//           name={"sliderValue"}
//           control={control}
//           setValue={setValue}
//           label={"Slider Input"}
//         />
//         <FormInputSwitch
//           name={"switchValue"}
//           control={control}
//           label={"Switch Input"}
//         />
//         <FormInputToggleButton
//           name="toggleButtonValue"
//           control={control}
//           label="Dropdown Input"
//           options={[
//             {
//               label: "Button 1",
//               value: "a"
//             },
//             {
//               label: "Button 2",
//               value: "b"
//             },
//           ]}
//         />
//         <FormInputCheckbox
//           name="checkBox"
//           control={control}
//           label={""}
//           setValue={setValue}
//         />
//         <Button onClick={handleSubmit(onSubmit)} variant={"contained"}>        
//           Submit
//         </Button>
//         <Button onClick={() => reset()} variant={"outlined"}>
//           Reset
//         </Button>
//       </Paper>
//       <Paper
//         style={{
//           display: "grid",
//           gridRowGap: "20px",
//           padding: "20px",
//           margin: "10px 300px",
//         }}
//       >
//         <ReactGrid
//           rows={rows}
//           columns={columns}
//           customCellTemplates={{ textCell2: new TextCell2Template() }}
//           onCellsChanged={handleChanges}
//         />
//       </Paper> */}
//       <Paper
//         style={{
//           display: "grid",
//           gridRowGap: "20px",
//           padding: "20px",
//           margin: "10px 300px",
//       }}>
//         <Button onClick={handleClickOpen} variant={"outlined"}>{"open"}</Button>
//         <FormDemoDialog
//           modify={modify}
//           open={open}
//           setOpen={setOpen}
//         />
//       </Paper>
//     </>
//   );
// };

// export default FormDemo;
import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDemoData } from '@mui/x-data-grid-generator';

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(
      () => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      },
      Math.random() * 500 + 100,
    ); // simulate network latency
  });
}

export default function RowOrderingGrid() {
  const { data, loading: initialLoadingState } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 20,
    maxColumns: 20,
  });

  const [rows, setRows] = React.useState(data.rows);
  const [loading, setLoading] = React.useState(initialLoadingState);

  React.useEffect(() => {
    setRows(data.rows);
  }, [data]);

  React.useEffect(() => {
    setLoading(initialLoadingState);
  }, [initialLoadingState]);

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );

    setRows(newRows);
    setLoading(false);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro
        {...data}
        loading={loading}
        rows={rows}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
    </div>
  );
}
