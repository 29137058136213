import React from "react";
import {
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import  {
  Add,
  Delete,
} from '@mui/icons-material';

const GClientList = ({ gclientDetails, label, onChange, gclientOptions, gclientCode, addClient, deleteClient }) => {
  const typeList = gclientDetails?.filter(detail => detail.code === gclientCode);
  const list = typeList?.length > 0 && typeList[0].gclients.map((gclient, idx) => {
    let name = `name-${gclientCode}-${idx}`;
    
    return (
      <Grid container spacing={2} key={gclient.index}>
        <Grid item xs={9}>
          {/* InputLabel도 있고 Select에도 label이 있어야 함.
          InputLabel에만 있으면 값 상단 레이블에 박스 테두리가 나타남 */}
          <FormControl size={"small"} fullWidth sx={{ mt: idx === 0 ? 0 : 2 }}>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              name="gclientId"
              data-id={idx}
              id={name}
              value={gclient.value.id}
              onChange={(e) => onChange(e, gclient, gclientOptions, gclientCode)}
            >
              {
                gclientOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {/* {`${option.name} (${option.type})`} */}
                    {option.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="center" sx={{ mt: idx === 0 ? 0 : 2 }}>
          {idx === 0 ? (
            <Fab
              color="primary"
              size="small"
              variant="extended"
              onClick={() => addClient(gclientCode)}
            >
              <Add />
              {"추가"}&nbsp;
            </Fab>
          ) : (
            <Fab
              color="secondary"
              size="small"
              variant="extended"
              onClick={() => deleteClient(gclient, gclientCode)}
            >
              <Delete />
              {"삭제"}&nbsp;
            </Fab>
          )}
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      {list}
    </>
  )
};
export default GClientList;
