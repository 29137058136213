import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_GCLIENT_G04_DOCU_ETC_MAP = "gclientG04docuEtcMaps/initGClientG04docuEtcMap";
const SET_GCLIENT_G04_DOCU_ETC_MAP = "gclientG04docuEtcMaps/setGClientG04docuEtcMap";
const INIT_GCLIENT_G04_DOCU_ETC_MAPS = "gclientG04docuEtcMaps/initGClientG04docuEtcMaps";
const SET_GCLIENT_G04_DOCU_ETC_MAPS = "gclientG04docuEtcMaps/setGClientG04docuEtcMaps";
const REMOVE_GCLIENT_G04_DOCU_ETC_MAP = "gclientG04docuEtcMaps/removeGClientG04docuEtcMap";

const initGClientG04docuEtcMap = () => ({
  type: INIT_GCLIENT_G04_DOCU_ETC_MAP,
});

const setGClientG04docuEtcMap = (gclientG04docuEtcMap) => ({
  type: SET_GCLIENT_G04_DOCU_ETC_MAP,
  payload: gclientG04docuEtcMap,
});

const initGClientG04docuEtcMaps = () => ({
  type: INIT_GCLIENT_G04_DOCU_ETC_MAPS,
});

const setGClientG04docuEtcMaps = (gclientG04docuEtcMaps) => ({
  type: SET_GCLIENT_G04_DOCU_ETC_MAPS,
  payload: gclientG04docuEtcMaps,
});

const removeGClientG04docuEtcMap = () => ({
  type: REMOVE_GCLIENT_G04_DOCU_ETC_MAP,
});

export const create = ({ gclientId, g04docuEtcId, gclient, documentPath, documentType, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuEtcId, gclient, documentPath, documentType, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuEtcMap(data.gclientG04docuEtcMap));
  return response;
};

export const modifyDate = ({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuEtcMap(data.gclientG04docuEtcMap));
  return response;
};

export const initializeGClientG04docuEtcMap = () => async dispatch => {
  dispatch(initGClientG04docuEtcMap());
}

export const initializeGClientG04docuEtcMaps = () => async dispatch => {
  dispatch(initGClientG04docuEtcMaps());
}

export const selectAllByGClientByQuery = (gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/query/selectAll/gclient/${gclientId}`);
  const data = await response.json();
  dispatch(setGClientG04docuEtcMaps(data.gclientG04docuEtcMaps));
  return response;
}

export const selectByGClientByTypeByStatus = (gclientId, type, status) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/query/selectAll/gclient/${gclientId}/type/${type}/status/${status}`);
  const data = await response.json();
  dispatch(setGClientG04docuEtcMaps(data.gclientG04docuEtcMaps));
  return response;
}

export const selectByQueryDirect = async (id) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/query/select/${id}`);
  const data = await response.json();
  return data.gclientG04docuEtcMaps;
}

export const remove = ({ gclientId, g04docuEtcId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/gclient/${gclientId}/g04docuEtc/${g04docuEtcId}/g04docuEtcPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuEtcMap());
  return response;
};

export const removeWithFile = ({ gclientId, g04docuEtcId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuEtcMaps/gclient/${gclientId}/g04docuEtc/${g04docuEtcId}/g04docuEtcPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuEtcMap());
  return response;
};

const initialState = {
  gclientG04docuEtcMap: null,
  gclientG04docuEtcMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_ETC_MAP:
      newState = Object.assign({}, state, { gclientG04docuEtcMap: initialState.gclientG04docuEtcMap });
      return newState;
    case SET_GCLIENT_G04_DOCU_ETC_MAP:
      newState = Object.assign({}, state, { gclientG04docuEtcMap: action.payload });
      return newState;
    case INIT_GCLIENT_G04_DOCU_ETC_MAPS:
      newState = Object.assign({}, state, { gclientG04docuEtcMaps: initialState.gclientG04docuEtcMaps });
      return newState;
    case SET_GCLIENT_G04_DOCU_ETC_MAPS:
      newState = Object.assign({}, state, { gclientG04docuEtcMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
