import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GTRANS = 'gperfData/setGTrans';

const setGTrans = (gtrans) => ({
  type: SET_GTRANS,
  payload: gtrans,
});

export const selectGPerfData1 = async ({ glass }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gperfDatas/type1`, {
    method: "POST",
    body: JSON.stringify({ glass }),
  });
  const data = await response.json();
  return data.gperfData;
}

export const selectGPerfData2 = async ({
  ex_glass,
  gas_thickness,
  gas_material,
  in_glass,
}) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gperfDatas/type2`, {
    method: "POST",
    body: JSON.stringify({
      ex_glass,
      gas_thickness,
      gas_material,
      in_glass,
    }),
  });
  
  const data = await response.json();
  return data.gperfData;
}

export const selectGPerfData3 = async ({
  ex_glass,
	gas_thickness1,
	gas_material1,
	mid_glass,
	build_aspect,
	gas_thickness2,
	gas_material2,
	in_glass,
}) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gperfDatas/type3`, {
    method: "POST",
    body: JSON.stringify({
      ex_glass,
      gas_thickness1,
      gas_material1,
      mid_glass,
      build_aspect,
      gas_thickness2,
      gas_material2,
      in_glass,
    }),
  });
  const data = await response.json();
  return data.gperfData;
}

export const selectGTrans = () => async dispatch => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gperfDatas/gtrans`);
  const data = await response.json();
  dispatch(setGTrans(data.gtrans));
  return data.response;
}

const initialState = {
  gtrans: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GTRANS:
      newState = Object.assign({}, state, { gtrans: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
