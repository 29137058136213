// TODO : 삭제 예정
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import usePrevious from "../hook/usePrevious";
import {
  FormInputText,
  FormInputDropdown,
  FormInputToggleButton,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as g04docuActions from "../../store/g04docu";
import * as gclientActions from "../../store/gclient";
// import * as gmanufacturerActions from "../../store/gmanufacturer";
import * as gsubActions from "../../store/gsub";
import * as gsubItemActions from "../../store/gsubItem";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  gsubId: null,
  g04docuId: null, // ""은 fk 오류발생
  comments: "",
};

const theme = createTheme();

const GSubItemDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    // 아래 배열도 defaultValues 객체의 프로퍼티를 이용하여 바꿈. TODO : 추후 다른 다이얼로그도 확대 적용할 것
    // ["id", "name", "code", "gcomponentId", "g04docuId", "price", "comments", "type", "dependentGcomponentItem", "itemType"].forEach(item => {
    //   setValue(item, defaultValues[item]);
    // });
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화

  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  const gsubs = useSelector((state) => state.gsub.gsubs);
  const gsubsBottomLevel = useSelector((state) => state.gsub.gsubsBottomLevel);
  const gclients = useSelector((state) => state.gclient.gclients);
  const g04docus = useSelector((state) => state.g04docu.g04docus);
  
  // const [gsubItems, setGsubItems] = useState([]);
  const prevOpen = usePrevious(open);

  // 데이터 관리
  const dispatch = useDispatch();
  
  const addGSubItem = ({ id, name, code, gsubId, g04docuId, comments }) => dispatch(gsubItemActions.create({ id, name, code, gsubId, g04docuId, comments }))
  const modifyGSubItem = ({ id, name, code, gsubId, g04docuId, comments }) => dispatch(gsubItemActions.modify({ id, name, code, gsubId, g04docuId, comments }))

  const selectAllBottomLevel = (type) => dispatch(gsubActions.selectAllBottomLevel())
  // const selectAllDirect = () => gsubItemActions.selectAllDirect();
  const selectG04docuByDivisionAndTypeByQuery = (division, type) => dispatch(g04docuActions.selectByDivisionAndTypeByQuery(division, type))

  const onSubmit = ({ id, name, code, gsubId, g04docuId, comments }) => {
    setErrors([]);
    
    // // TODO : 아래와 같이 gclient 정보를 찾는 것은 비효율적. 선택시 값이 설정되어 저장하도록 할 것 (추가로 거래처 정보도 많아질테니 거래처 검색 창을 추가로 구현해야 할 것으로 보임)
    // const gclient = gclients.find(gclient => gclient.id === gclientId);

    // console.log({ id, name, code, gsubId, g04docuId, comments });
    // return;
    
    let func;
    if (crudMode === 'C') {
      func = addGSubItem
    } else if (crudMode === 'U') {
      func = modifyGSubItem;
    }
    
    func({ id, name, code, gsubId, g04docuId, comments })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      selectAllBottomLevel();
    }, [dispatch]
  );

  useEffect(
    () => {
      // ["id", "name", "code", "gsubId", "specification", "comments"].forEach(item => {
      //   // if (paste && item === "id") {
      //   //   setValue(item, selectedRow && `${selectedRow[item]}_copy` || "");
      //   if (item === "id") {
      //     setValue(item, selectedRow && selectedRow[item] || uuidv4());
      //   // } else if (item === "dependentGsubItem") {
      //   //   if (selectedRow && selectedRow[item]) {
      //   //     setValue(item, JSON.stringify(selectedRow[item]));
      //   //   } else {
      //   //     setValue(item, "");
      //   //   }
      //   //   // console.log(JSON.stringify(null));
      //   //   // setValue(item, selectedRow && JSON.stringify(selectedRow[item]) || /*{}*/""); // {} 값은 빈 값이 아니므로 FormInputText(select)에서 값을 선택한 것으로 판단(label이 상단에 표시됨)
      //   } else {
      //     setValue(item, selectedRow && selectedRow[item] || "");
      //   }
      // });
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
      }
    }, [selectedRow]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      if (prevOpen === false && open === true) {
        selectG04docuByDivisionAndTypeByQuery('SUB_MATERIAL_BUILD', 'STANDARD');
      }
    }, [open]
  )

  // useEffect(
  //   async () => {
  //     if (prevOpen === false && open === true) {
  //       const gsubItems = await selectAllDirect();
  //       console.log(gsubItems);
  //       setGsubItems(gsubItems);
  //     }
  //   }, [open]
  // )

  const handleChangeGsub = (e) => {
    const { value } = e.target;
    setValue("gsubId", value);
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "구성요소 아이템 등록" }
        { crudMode === 'R' && "구성요소 아이템 상세" }
        { crudMode === 'U' && "구성요소 아이템 수정" }
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              select
              name="gsubId"
              control={control}
              label={"구성요소"}
              // sx={{ width: 300 }}
              options={
                [{ label: '없음', value: undefined }].concat(gsubsBottomLevel.map(gsub => {
                  return {
                    label: gsub.name,
                    value: gsub.id,
                  }
                }))
              }
              onChange={handleChangeGsub}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormInputText
              name={"specification"}
              control={control}
              label={"규격"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormInputText
              select
              name="g04docuId"
              control={control}
              label={"규격"}
              // sx={{ width: 300 }}
              options={
                [{ label: '없음', value: null }].concat(g04docus.map(g04docu => {
                  return {
                    // label: `${g04docu.name} ( ${g04docu.gstandardNo} )`,
                    label: <>{g04docu.name}<Chip label={g04docu.gstandardNo} size="small" sx={{ ml: 1 }}></Chip></>,
                    // label: g04docu.name,
                    value: g04docu.id,
                  }
                }))
              }
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GSubItemDialog;
