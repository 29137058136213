import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Avatar,
  Backdrop,
  // Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  AccessTime,
  RadioButtonUnchecked,
  Contrast,
  Add,
  Assignment,
  Delete,
  Done,
  Edit,
  FactCheck,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardReturn,
  Undo,
  OpenInNew,
  Send,
  Shortcut,
  SubdirectoryArrowRight,
  WarningAmber,
} from '@mui/icons-material';

import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import GWorkOrderManagement from "../GWorkOrder/GWorkOrderManagement";
// import * as gorderActions from "../../store/gorder";
import * as greceptionActions from "../../store/greception";
import GReceptionDialog from "./GReceptionDialog";

import GReceptionProcessDialog from "./GReceptionProcessDialog";
import { selectAllByGreceptionId } from "../../store/greceptionDetail";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const GReceptionManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [params, setParams] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [gproject, setGProject] = useState({});
  const [mode, setMode] = useState("");
  const [receiveType, setReceiveType] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [greceptionId, setGreceptionId] = useState("");
  const [receiveSerialNo, setReceiveSerialNo] = useState("");
  const [pageSize, setPageSize] = useState(100);
  // const [statusWorkOrder, setStatusWorkOrder] = useState("");

  const [searchParams] = useSearchParams();
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;

    // 기본적으로 읽기전용이미르 crudMode 적용할 필요 없음
    if (type === "detail") {
      setGreceptionId(id);
      await select(id);
      setOpen(true);
    } else if (type === "receive") {
      setGreceptionId(id);
      setParams(params);
      setReceiveDialogOpen(true);
      setReceiveType(type);
    } else if (type === "return") {
      setGreceptionId(id);
      setParams(params);
      setReceiveDialogOpen(true);
      setReceiveType(type);
    } else if (type === "workOrder") {
      setGreceptionId(id);
      setReceiveSerialNo(params.row.receiveSerialNo);
      // setStatusWorkOrder(params.row.statusWorkOrder);
      setDrawerOpen(true);
    }

    setOpenBackdrop(false);
  }

  // const remove = (removeId) => {
  //   dispatch(gorderActions.remove(removeId))
  //   .then(res => selectAllByStatusReceptionByQuery())
  //   .catch(async (res) => {
  //     const data = await res.json();
  //     if (data && data.errors) setErrors(data.errors);
  //   });
  // }
  
  const openOrderDialog = () => {
    setOpen(true);
    setModify(false);
    initGReception(); // 다이얼로그 초기화
  }

  // 등록 다이얼로그
  const handleClickOpen = () => {
    // openOrderDialog();
    setOpen(true);
    setModify(false);
    initGReception(); // 다이얼로그 초기화
  };

  // <OpenInNew />
  const generateActions = (params) => {
    // console.log("params"); 
    // console.log(params);
    const { statusReception } = params.row;

    const detailAction = <GridActionsCellItem icon={<OpenInNew />} label={"상세"} onClick={() => handleSelect({ type: 'detail', params })} showInMenu />;
    const receiveAction = <GridActionsCellItem icon={<Done />} label={"접수"} onClick={() => handleSelect({ type: 'receive', params })} showInMenu />;
    const returnAction = <GridActionsCellItem /*icon={<WarningAmber />}*/ icon={<Undo />} label={"반송"} onClick={() => handleSelect({ type: 'return', params })} showInMenu />;
    const workOrderAction = <GridActionsCellItem icon={<Assignment />} /*icon={<FactCheck />}*/ label={"작업의뢰"} onClick={() => handleSelect({ type: 'workOrder', params })} showInMenu />;

    let arrActions = [];
    
    switch(statusReception) {
      case 'PRE-RECEIVE':
        arrActions = [].concat([detailAction, receiveAction, returnAction]);
        break;
      case 'RECEIVE':
        arrActions = [].concat([detailAction, returnAction, workOrderAction]);
        break;
      case 'RETURN':
        arrActions = [].concat([detailAction]);
        break;
      default:
        arrActions = [].concat([detailAction]);
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 300,
    },
    {
      field: 'receiveSerialNo',
      headerName: '수주일련번호',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'deliveryDate',
      headerName: '납기일',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'statusRecption',
      headerName: '접수',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { statusReception } = params.row;
        let result;
        if (statusReception === 'PRE-RECEIVE') {
          result = <Chip icon={<Shortcut />} label={"미접수"} variant="outlined" color="warning" size="small" sx={{ width: '80px' }} />
        } else if (statusReception === 'RECEIVE') {
          result = <Chip icon={<Done />} label={"접수"} variant="outlined" color="success" size="small" sx={{ width: '80px' }} />
        } else if (statusReception === 'RETURN') {
          result = <Chip icon={<Undo />} label={"반송"} variant="outlined" color="error" size="small" sx={{ width: '80px' }} />
          // result = <Chip icon={<WarningAmber />} label={"반송"} variant="outlined" color="error" />
        }

        return (
          <div>
            {result}
          </div>
        );
      },
    },
    {
      field: 'statusWorkOrder',
      headerName: '작업의뢰',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { statusWorkOrder } = params.row;
        let result;
        if (statusWorkOrder === 'PRE') {
          result = <Chip icon={<RadioButtonUnchecked />} label={"의뢰전"} variant="outlined" color="info" size="small" sx={{ width: '80px' }} />
        } else if (statusWorkOrder === 'ING') {
          result = <Chip icon={<Contrast />} label={"의뢰중"} variant="outlined" color="warning" size="small" sx={{ width: '80px' }} />
        } else if (statusWorkOrder === 'DONE') {
          result = <Chip icon={<Done />} label={"완료"} variant="outlined" color="success" size="small" sx={{ width: '80px' }} />
        } else if (statusWorkOrder === 'RETURN') {
          result = <Chip icon={<KeyboardReturn />} label={"반송"} variant="outlined" color="error" size="small" sx={{ width: '80px' }} />
          // result = <Chip icon={<WarningAmber />} label={"반송"} variant="outlined" color="error" />
        }

        return (
          <div>
            {result}
          </div>
        );
      },
    },
    {
      field: 'statusWorkOrderResult',
      headerName: '진행상황',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { statusWorkOrderResult } = params.row;
        // console.log(statusWorkOrderResult)
        const pres = statusWorkOrderResult?.filter(item => item === "PRE");
        const recevies = statusWorkOrderResult?.filter(item => item === "RECEIVE");
        const returns = statusWorkOrderResult?.filter(item => item === "RETURN");

        if ((recevies && recevies.length > 0) || (returns && returns.length > 0)) {
          // return `접수: ${recevies.length} / 반송: ${returns.length}`;
          return <>
            <Chip label={`미`} avatar={<Avatar>{pres.length}</Avatar>} variant="outlined" color="warning" size="small" sx={{ mr: 1 }}/>
            <Chip label={`접`} avatar={<Avatar>{recevies.length}</Avatar>} variant="outlined" color="success" size="small" sx={{ mr: 1 }}/>
            <Chip label={`반`} avatar={<Avatar>{returns.length}</Avatar>} variant="outlined" color="error" size="small" />
          </>
        } else {
          return "";
        }
      },
    },
    {
      field: 'orderer',
      headerName: '발주업체',
      width: 200,
      valueGetter: (params) => params.value?.name,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 180,
      // editable: true,
    },
    {
      field: 'orderSerialNo',
      headerName: '발주일련번호',
      width: 120,
    },
    {
      field: 'orderNo',
      headerName: '발주번호',
      width: 120,
      // editable: true,
    },
    {
      field: 'sumAmount',
      headerName: '수량',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaMeter',
      headerName: '면적 (m2)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaJa',
      headerName: '면적 (평)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaFeet',
      headerName: '면적 (ft2)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'orderDate',
      headerName: '발주일',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'deliveryAddress',
      headerName: '착지주소',
      width: 280,
      // editable: true,
    },
    {
      field: 'inChargeNameGclient',
      headerName: '담당자명',
      width: 100,
      // editable: true,
    },
    {
      field: 'inChargePhoneGclient',
      headerName: '담당자연락처',
      width: 120,
      // editable: true,
    },
    {
      field: 'ordererName',
      headerName: '발주자명',
      width: 120,
      // editable: true,
    },
    {
      field: 'ordererPhone',
      headerName: '발주자연락처',
      width: 120,
      // editable: true,
    },
    {
      field: 'ordererEmail',
      headerName: '발주자이메일',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    // {
    //   field: 'inChargeEmailGclient',
    //   headerName: '담당자이메일',
    //   width: 120,
    //   // editable: true,
    // },
    // {
    //   field: 'owner',
    //   headerName: '발주자',
    //   width: 120,
    //   // editable: true,
    // },
    // {
    //   field: 'sumContractPriceTotal',
    //   headerName: '수주금액',
    //   width: 100,
    //   // editable: true,
    //   headerAlign: 'right',
    //   align: 'right',
    //   valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    // },
    {
      field: 'sumOrderPriceTotal',
      // headerName: '발주금액',
      headerName: '수주접수금액',
      width: 140,
      // editable: true,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      headerAlign: 'center',
      align: 'center',
      width: 160,
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      align: 'center',
      width: 160,
      headerAlign: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/접수/반송/작업의뢰"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const rows = useSelector((state) => state.greception.greceptions);
  const selectedRow = useSelector((state) => state.greception.greception);
  
  // const defaultValues = {
  //   comments: "",
  // }

  // const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues });

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllByStatusReceptionByQuery = (statusReceptionArray) => dispatch(greceptionActions.selectAllByStatusReceptionByQuery(statusReceptionArray))
  const select = (id) => dispatch(greceptionActions.select(id))
  const initGReception = () => dispatch(greceptionActions.initGReception())

  useEffect(
    async () => {
      await selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN']);

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  const refreshManagementList = async () => {
    setLoaded(false);
    await selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN']);
    setTimeout(() => setLoaded(true), 300);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GReceptionDialog
          modify={modify}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          // refresh={selectAllByStatusReceptionByQuery}
          // refresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
          refresh={refreshManagementList}
          // 목록에 있는 GReceptionProcessDialog를 다이얼로그 안에서 사용하기 위해...
          setReceiveDialogOpen={setReceiveDialogOpen}
          // greceptionId={greceptionId}
          setGreceptionId={setGreceptionId}
          setReceiveType={setReceiveType}
          setParentOpen={setOpen}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              {/* <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button> */}
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <Skeleton variant="rounded" height={show ? 0 : 800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
            />
          </div>
        </Box>
        {/* <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog> */}
        <GReceptionProcessDialog
          open={receiveDialogOpen}
          setOpen={setReceiveDialogOpen}
          id={greceptionId}
          receiveType={receiveType}
          setParentOpen={setOpen}
          // refresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
          refresh={refreshManagementList}
        />
        {/* <Dialog
          open={receiveDialogOpen}
          onClose={handleReceiveDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="sm"
          // onConfirm={sendOrder}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleReceiveDialogClose}
            style={{ cursor: 'move' }}
          >
            {"접수"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"노트"}
                  multiline
                  rows={3}
                  maxRows={5}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit(onSubmit)}>{"접수"}</Button>
            <Button onClick={handleReceiveDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog> */}
      </Container>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GWorkOrderManagement
          title={`작업의뢰 ( 수주일련번호 : ${receiveSerialNo} )`}
          greceptionId={greceptionId}
          // statusWorkOrder={statusWorkOrder}
          originRefresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default GReceptionManagement;
