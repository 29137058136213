import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_G04_DOCU_ALARM_EMAILS = 'g04docuAlarmEmail/setG04docuAlarmEmails';
const REMOVE_G04_DOCU_ALARM_EMAILS = "g04docuAlarmEmail/removeG04docuAlarmEmail";

const setG04docuAlarmEmails = (g04docuAlarmEmails) => ({
  type: SET_G04_DOCU_ALARM_EMAILS,
  payload: g04docuAlarmEmails,
});

const removeG04docuAlarmEmail = () => ({
  type: REMOVE_G04_DOCU_ALARM_EMAILS,
});

export const bulkCreate = ({ gclientId, g04docuId, g04docuAlarmEmails }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuAlarmEmails`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuId, g04docuAlarmEmails }),
  });
  const data = await response.json();
  dispatch(setG04docuAlarmEmails(data.g04docuAlarmEmails));
  return response;
};

export const bulkCreateDirect = async ({ gclientId, g04docuId, g04docuAlarmEmails }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuAlarmEmails/bulk`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuId, g04docuAlarmEmails }),
  });
  const data = await response.json();
  return data.g04docuAlarmEmails;
};

export const select = ({ gclientId, g04docuId }) => async dispatch => {
  const queryStrings = [];
  if (gclientId) {
    queryStrings.push(`gclientId=${gclientId}`);
  }

  if (g04docuId) {
    queryStrings.push(`g04docuId=${g04docuId}`);
  }

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuAlarmEmails?${queryStrings.join("&")}`);
  const data = await response.json();
  dispatch(setG04docuAlarmEmails(data.g04docuAlarmEmails));
  return response;
}

export const selectDirect = async ({ gclientId, g04docuId }) => {
  const queryStrings = [];
  if (gclientId) {
    queryStrings.push(`gclientId=${gclientId}`);
  }

  if (g04docuId) {
    queryStrings.push(`g04docuId=${g04docuId}`);
  }

  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuAlarmEmails?${queryStrings.join("&")}`);
  const data = await response.json();
  return data.g04docuAlarmEmails;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuAlarmEmails/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docuAlarmEmail());
  return response;
};

const initialState = {
  g04docuAlarmEmail: {},
  g04docuAlarmEmails: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_G04_DOCU_ALARM_EMAILS:
      newState = Object.assign({}, state, { g04docuAlarmEmails: action.payload });
      return newState;
    case REMOVE_G04_DOCU_ALARM_EMAILS:
      newState = Object.assign({}, state, { g04docuAlarmEmail: {} });
    default:
      return state;
  }
}

export default reducer;
