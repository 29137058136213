import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { objectEmptyCheck, ExcelUtils, dateFormat } from "../../utils";
import {
  FormInputDate1,
  FormInputDropdown,
  FormInputDropdown1,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import * as gclientActions from "../../store/gclient";
import * as gprojectActions from "../../store/gproject";
import * as gorderActions from "../../store/gorder";

import GOrderExcel from "./GOrderExcel";

const today = new Date();

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  orderSerialNo: "",
  orderNo: "",
  gclientId: undefined,
  gprojectId: undefined,
  site: "",
  inChargeNameGclient: "",
  inChargePhoneGclient: "",
  // inChargeEmailGclient: "",
  deliveryAddress: "",
  orderDate: today.getDateWithStartHours(),
  deliveryDate: today.getDateWithEndHours(),
  // owner: "",
  orderer: null,
  ordererName: "",
  ordererPhone: "",
  ordererEmail: "",
  comments: "",
  // gorderDetails: undefined,
  // status: "WRITING",
  status: "",
};

let selectProjectInput = null;
// let oldGProjectId = null;

const GOrderDialog = ({
  // detail,
  // modify,
  crudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
  // gprojectId,
  setSendDialogOpen,
  setGorderId,
  from,
}) => {
  // TODO : FormInputDropdown과 FormInputDate의 경우 컴포넌트가 렌더링된 뒤에는 동적으로 값 설정이 변경되지 않아 아래와 같이 직접 set 함수 구현하여 호출하고 있음. 추후 검토 필요
  const gclientDropDown = React.useRef(); // 자식 함수 호출하기 위함(gclient FormInputDropDown의 set 함수 호출)
  const orderDate1 = React.useRef(); // 자식 함수 호출하기 위함
  const deliveryDate1 = React.useRef(); // 자식 함수 호출하기 위함

  const [errors, setErrors] = useState([]);
  // const [fullScreen, setFullScreen] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  // const [tabValue, setTabValue] = useState(1);
  const [openProgress, setOpenProgress] = useState(false);
  const [openChangeProject, setOpenChangeProject] = useState(false);
  const [gproject, setGProject] = useState({});
  // const [mode, setMode] = useState("");
  // const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  // const [oldGProjectId, setOldGProjectId] = useState(null);
  const [gorderDetails, setGorderDetails] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [orderConfirmOpen, setOrderConfirmOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const handleDialogClose = () => {
    setOpen(false);
    initWithInitialValue({});

    // TODO : 필요없어 보임. 일단 주석처리
    // if (!objectEmptyCheck(gproject) && !gprojectId) {
    //   setGProject({});
    //   setValue("gprojectId", null);
      
      // setDialogInfo(); // 프로젝트로부터 발주시 창을 닫고 등록하기를 누르면 useEffect ~[selectedRow]가 호출되지 않으므로 창 닫을 때 주문창을 초기화해야 한다. => TODO : 지금도 필요한 기능인가??? 일단 주석처리해도 문제없어보임
    // }
  };

  // const handleDialogMinMax = () => {
  //   setFullScreen(!fullScreen);
  // }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const sessionUser = useSelector(state => state.session.sessionUser);
  const gclients = useSelector((state) => state.gclient.gclients);
  const gprojects = useSelector((state) => state.gproject.gprojects);

  // 데이터 관리
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const selectAllGClient = () => dispatch(gclientActions.selectAll());
  // const selectGClientByType = (type) => dispatch(gclientActions.selectByTypeByQuery(type));
  const selectAllGProject = () => dispatch(gprojectActions.selectAll());
  const selectGProjectDirect = (gprojectId) => gprojectActions.selectDirect(gprojectId);
  const init = () => dispatch(gorderActions.initGOrder());
  const initWithInitialValue = (initialValue) => dispatch(gorderActions.initGOrderWithInitialValue(initialValue));
  const selectAllByProjectByDirect = (gprojectId) => gorderActions.selectAllByProjectByDirect(gprojectId);

  const addGOrder = ({
    id,
    orderNo,
    gprojectId,
    receiver,
    site,
    inChargeNameGclient,
    inChargePhoneGclient,
    // inChargeEmailGclient,
    deliveryAddress,
    orderDate,
    deliveryDate,
    // owner,
    ordererName,
    ordererPhone,
    ordererEmail,
    comments,
    gorderDetails,
    sumAmount,
    sumAreaMeter,
    sumAreaJa,
    sumAreaFeet,
    sumOrderPriceTotal,
  }) => dispatch(gorderActions.create({
    id,
    orderNo,
    gprojectId,
    receiver,
    site,
    inChargeNameGclient,
    inChargePhoneGclient,
    // inChargeEmailGclient,
    deliveryAddress,
    orderDate,
    deliveryDate,
    // owner,
    ordererName,
    ordererPhone,
    ordererEmail,
    comments,
    gorderDetails,
    sumAmount,
    sumAreaMeter,
    sumAreaJa,
    sumAreaFeet,
    sumOrderPriceTotal,
  }));

  const modifyGOrder = ({
    id,
    orderSerialNo,
    orderNo,
    gprojectId,
    receiver,
    site,
    inChargeNameGclient,
    inChargePhoneGclient,
    // inChargeEmailGclient,
    deliveryAddress,
    orderDate,
    deliveryDate,
    // owner,
    ordererName,
    ordererPhone,
    ordererEmail,
    comments,
    gorderDetails,
    sumAmount,
    sumAreaMeter,
    sumAreaJa,
    sumAreaFeet,
    sumOrderPriceTotal,
  }) => dispatch(gorderActions.modify({
    id,
    orderSerialNo,
    orderNo,
    gprojectId,
    receiver,
    site,
    inChargeNameGclient,
    inChargePhoneGclient,
    // inChargeEmailGclient,
    deliveryAddress,
    orderDate,
    deliveryDate,
    // owner,
    ordererName,
    ordererPhone,
    ordererEmail,
    comments,
    gorderDetails,
    sumAmount,
    sumAreaMeter,
    sumAreaJa,
    sumAreaFeet,
    sumOrderPriceTotal,
  }));

  const onSubmit = ({
    id,
    orderSerialNo,
    orderNo,
    gprojectId,
    gclientId,
    site,
    inChargeNameGclient,
    inChargePhoneGclient,
    // inChargeEmailGclient,
    deliveryAddress,
    orderDate,
    deliveryDate,
    // owner,
    ordererName,
    ordererPhone,
    ordererEmail,
    comments,
    gorderDetails,
    sumAmount,
    sumAreaMeter,
    sumAreaJa,
    sumAreaFeet,
    sumOrderPriceTotal,
  }) => {
    setErrors([]);
    setOpenProgress(true);
    console.log({
      id,
      orderSerialNo,
      orderNo,
      gprojectId,
      gclientId,
      site,
      inChargeNameGclient,
      inChargePhoneGclient,
      // inChargeEmailGclient,
      deliveryAddress,
      orderDate,
      deliveryDate,
      // owner,
      ordererName,
      ordererPhone,
      ordererEmail,
      comments,
      gorderDetails,
      sumAmount,
      sumAreaMeter,
      sumAreaJa,
      sumAreaFeet,
      sumOrderPriceTotal,
    });
    
    // setOpenProgress(false);
    // return;

    let func;
    // if (modify) {
    //   func = modifyGOrder;
    // } else {
    //   func = addGOrder
    // }
    if (crudMode === 'U') {
      func = modifyGOrder;
    } else if (crudMode === 'C') {
      func = addGOrder
    }
    // const receiver = gclients.find(gclient => gclient.id === gclientId); // 실제 저장시 gclientId로부터 gclient를 구하여 저장한다.

    let receiver;
    // if (gclientDropDown && gclientDropDown.current && gclientDropDown.current.getOptions && gclientDropDown.current.getOptions.length > 0) { // 새 창일 때
      receiver = gclientDropDown.current.getOptions().find(option => option.value === gclientId).data; // data에는 다이얼로그 구성시 최신 GClients로부터 조회한 정보가 담겨 있다.
    // } else { //편집창일 때
    //   receiver = gproject?.gclientDetails?.find(gclientDetail => gclientDetail.code === 'FABRICATOR')?. // 가공업체 중
    //                       gclients?.map(gclient => gclient.value).find(gclient => gclient.id === gclientId);
    // }
    // init(); // TODO : 주석처리. 다이얼로그 닫을 때 init했으므로 필요없어보임. 이상없는지 확인
    // setOpenProgress(true);

    func({
      id,
      orderSerialNo,
      orderNo,
      gprojectId,
      receiver,
      site,
      inChargeNameGclient,
      inChargePhoneGclient,
      // inChargeEmailGclient,
      deliveryAddress,
      orderDate: orderDate.getDateWithStartHours(),
      deliveryDate: deliveryDate.getDateWithEndHours(),
      // owner,
      ordererName,
      ordererPhone,
      ordererEmail,
      comments,
      gorderDetails,
      sumAmount,
      sumAreaMeter,
      sumAreaJa,
      sumAreaFeet,
      sumOrderPriceTotal,
    })
      .then (res => {
        setOpenProgress(false);
        
        setOrderConfirmOpen(true);

        // handleDialogClose();
        // refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) {
          setErrors(data.errors);
          // TODO : 에러메시지 출력
          setOpenProgress(false);
        }
      });
  }

  useEffect(
    async () => {
      selectAllGClient();
      // TODO : 내 프로젝트여야 함. 현재는 모든 프로젝트 불러오고 있음.
      // 참고로 자신이 발행한 프로젝트는 GProjects.owner, 자신이 포함된 프로젝트는 GProjects.gclientDetails 를 참조하여야 한다.
      selectAllGProject();
    }, [dispatch]
  );

  const setDialogInfo = () => {
    // const init = {};
    [
      "id",
      "orderSerialNo",
      "orderNo",
      "gprojectId",
      "gclientId",
      "site",
      "inChargeNameGclient",
      "inChargePhoneGclient",
      "deliveryAddress",
      "orderDate",
      "deliveryDate",
      "orderer",
      "ordererName",
      "ordererPhone",
      "ordererEmail",
      "comments",
      "status",
      "gorderDetails"
    ].forEach(item => {
      if (item === "orderDate") {
        setValue(item, selectedRow && (new Date(selectedRow[item])).getDateWithStartHours() || defaultValues.orderDate);
      } else if (item === "deliveryDate") {
        // setValue(item, new Date())
        // 날짜 데이터의 경우 selectedRow값이 있으면 설정되나 없을 경우 new Date()해도 기존값이 보임. useState 이용해서 갱신해야 함 ??? // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용) ???
        setValue(item, selectedRow && (new Date(selectedRow[item])).getDateWithEndHours() || defaultValues.deliveryDate);
        // } else if (item === "status") {
        // setValue(item, selectedRow && selectedRow[item] || "WRITING");
      } else if (item === "gorderDetails") {
        setValue(item, selectedRow && selectedRow[item] || []);
        setGorderDetails(selectedRow?.gorderDetails);
      } else if (item === "gclientId") {
        const gcId = selectedRow && selectedRow["receiver"]?.id;
        setValue(item, gcId || undefined); // receiver 임에 유의할 것
        // setTimeout(() => gclientDropDown.current.setSelectedValue1(gcId), 5000);
        
      } else if (item === "ordererName") {
        setValue(item, selectedRow && selectedRow[item] || sessionUser.inChargeName);
      } else if (item === "ordererPhone") {
        setValue(item, selectedRow && selectedRow[item] || sessionUser.inChargePhone);
      } else if (item === "ordererEmail") {
        setValue(item, selectedRow && selectedRow[item] || sessionUser.inChargeEmail);
      } else if (item === "id") {
        setValue(item, selectedRow && selectedRow[item] || uuidv4());
      } else if (item === "status") {
        setValue(item, selectedRow && selectedRow[item] || defaultValues.status);
      } else {
        setValue(item, selectedRow && selectedRow[item] || "");
      }
    });

    // alert(from?.params?.gprojectId)

    // setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
  }

  // useEffect(
  //   () => {
  //     // console.log(`modify: ${modify}, opne: ${open}`);
  //     if (open) { // 다이얼로그가 열릴 때
  //       if (!modify) { // 등록인 경우 기존 데이터 초기화
  //         setDialogInfo();
  //       }
  //     }
  //   }, [open]
  // )
  
  useEffect(
    () => {
      if (crudMode === 'R' || crudMode === 'U' ) {
        const gcId = selectedRow && selectedRow["receiver"]?.id;
        gcId && gclientDropDown.current.setSelectedValue1(gcId);
      }
    }, [gproject]
  )
  // GOrderManagement의 handleClickOpen() 시 initWithInitialValue 호출하지 않으면 useEffect(~[selectRow]) 발생하지 않음(여기 루틴에 들어오지 않음)에 유의
  useEffect(
    async () => {
      setDialogInfo();

      // from?.params?.gprojectId : 프로젝트에서 바로 발주하는 경우
      // getValues("gprojectId") : 발주목록에서 선택한 경우
      const gprojectId = from?.params?.gprojectId || getValues("gprojectId");
      if (gprojectId) {
        const gproject = await selectGProjectDirect(gprojectId);
        if (gproject && !objectEmptyCheck(gproject)) {
          setGProject(gproject);
          setValue("gprojectId", gproject.id);
          
          crudMode === 'C' && setLastOrderInfoOrProjectInfo(gproject);
        }
      }
      
      // TODO : 임시. 데이터 로딩에 시간이 걸려 GOrderExcel안에서 데이터로딩 후 진행바 삭제. 그러나 일반의 경우 아직 미구현이므로 여기서 진행바 임시 삭제
      // selectedRow && setOpenProgress(true);
      // alert(index)
      // 
      // if(index === "1") {
      //   setOpenProgress(false);
      // }
    }, [selectedRow]
  );

  // 마지막 발주 정보 설정. 첫 발주일 경우 프로젝트 정보 설정
  const setLastOrderInfoOrProjectInfo = async (gproject) => {
    const { gorders } = await selectAllByProjectByDirect(gproject.id);
    // console.log(gorders)
    if (gorders.length > 0) {
      [
        "gclientId",
        "site",
        "inChargeNameGclient",
        "inChargePhoneGclient",
        "deliveryAddress",
        "orderDate",
        "deliveryDate",
        "ordererName",
        "ordererPhone",
        "ordererEmail",
        "comments"
      ].forEach(item => {
        if (item === "gclientId") {
          const gcId = gorders[0]["receiver"]?.id
          setValue(item, gcId);
          gclientDropDown.current.setSelectedValue1(gcId);
        } /*else if (item === "orderDate") { // TODO : 주문일과 납기일은 이전 발주와 무관해보임
          orderDate1.current.setDatePickerValue1(gorders[0][item])
          setValue(item, gorders[0][item]);
        } else if  (item === "deliveryDate") {
          deliveryDate1.current.setDatePickerValue1(gorders[0][item])
          setValue(item, gorders[0][item]);
        } */else {
          setValue(item, gorders[0][item]);
        }
      });
    } else {
      const { site, siteAddress, gclientDetails } = gproject;
    
      setValue("site", site);
      setValue("deliveryAddress", siteAddress);
      
      // TODO : 날짜(타입)가 setValue로 설정될 때, DB로 전달될 때, 저장될 때, 읽힐 때, setDatePickerValue1처럼 값을 설정할 때 각 타입 검토 필요
      const orderDate = defaultValues.orderDate;
      setValue("orderDate", orderDate);
      orderDate1.current.setDatePickerValue1(orderDate/*.toISOString()*/);
      
      const deliveryDate = defaultValues.deliveryDate;
      setValue("deliveryDate", deliveryDate);
      deliveryDate1.current.setDatePickerValue1(deliveryDate/*.toISOString()*/);
  
      // 가공업체는 프로젝트 설정시 첫번째 가공업체를 기본으로 한다.
      const gclient = gclientDetails.filter(gclient => gclient.code === 'FABRICATOR');
      if (gclient[0].gclients.length > 0) {
        const gcId = gclient[0].gclients[0].value.id;
        setValue("gclientId", gcId);
        gclientDropDown.current.setSelectedValue1(gcId);
      }
    }
  }

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   setFullScreen(true);
  // };

  const confirm = async (target) => {
    setOpenChangeProject(true)
    const { gprojectId } = target;
    const gproject = await selectGProjectDirect(gprojectId);
    if (!objectEmptyCheck(gproject)) {
      setGProject(gproject);
      setDialogInfo();
      setValue("gprojectId", gprojectId);
      setLastOrderInfoOrProjectInfo(gproject);
      
      // 사용자가 프로젝트 변경을 하면 유리내역을 초기화한다.
      setGorderDetails([]);
    }
    setOpenChangeProject(false);
  }
  
  const cancel = async (target) => {
    // 프로젝트 선택 되돌리기
    const { prevGprojectId } = target;
    setValue("gprojectId", prevGprojectId);
  }

  const handleSelectProject = async (e, oldValue) => {
    // 발주 내역이 있는 경우 프로젝트 변경을 하면 안내창을 띄운다.
    const gprojectId = e.target.value;
    const selectedGGlass = getValues("gorderDetailsBeforeValidation");
    if (selectedGGlass > 0 ) {
      setRemoveObject({ gprojectId, prevGprojectId: oldValue });
      setConfirmOpen(true);
    } else {
      const gproject = await selectGProjectDirect(gprojectId);
      if (!objectEmptyCheck(gproject)) {
        setGProject(gproject);
        setDialogInfo();
        setValue("gprojectId", gprojectId);
        setLastOrderInfoOrProjectInfo(gproject);
      }
    }
    
    // const site = getValues("site");
    // const deliveryAddress = getValues("deliveryAddress");
    // if (!site) {
    //   setValue("site", project.site);
    // }
    // if (!deliveryAddress) {
    //   setValue("deliveryAddress", project.siteAddress);
    // }
  }

  // const handleChangePeriod = (value, name) => {
  //   return true;
  // }

  const setFocusSelectProjectInput = () => {
    selectProjectInput.focus();
  }

  const setSelectProjectInputRef = element => {
    selectProjectInput = element;
  };

  const handleExportExcel = async () => {
    // alert("엑셀내보내기")

    // ExcelUtils.excelDownload(
    //   'TEST LIST',
    //   response.data,
    //   헤더리스트를 스트링으로 작성 (예시) "No,이름,주소,번호",
    //   키리스트를 스트링으로 작성 (예시) "no,name,address,ph",
    //   res
    // );

    // ExcelUtils.excelDownload(
    //   'TEST LIST',
    //   null,
    //   "No,이름,주소,번호",
    //   "no,name,address,ph",
    //   null,
    // );

    // console.log({
    //   id: getValues("id"),
    //   orderNo:getValues("orderNo"),
    //   gprojectId: getValues("gprojectId"),
    //   gclientId: getValues("gclientId"),
    //   site: getValues("site"),
    //   inChargeNameGclient: getValues("inChargeNameGclient"),
    //   inChargePhoneGclient: getValues("inChargePhoneGclient"),
    //   // inChargeEmailGclient,
    //   inChargePhoneGclient: getValues("deliveryAddress"),
    //   orderDate: getValues("orderDate"),
    //   deliveryDate: getValues("deliveryDate"),
    //   // owner,
    //   ordererName: getValues("ordererName"),
    //   ordererPhone: getValues("ordererPhone"),
    //   ordererEmail: getValues("ordererEmail"),
    //   comments: getValues("comments"),
    //   gorderDetails,
    //   sumAmount: getValues("sumAmount"),
    //   sumAreaMeter: getValues("sumAreaMeter"),
    //   sumAreaJa: getValues("sumAreaJa"),
    //   sumAreaFeet: getValues("sumAreaFeet"),
    //   sumOrderPriceTotal: getValues("sumOrderPriceTotal"),
    // });

    const gclientId = getValues("gclientId");
    let gclientName;
    // if (gclientDropDown && gclientDropDown.current && gclientDropDown.current.getOptions && gclientDropDown.current.getOptions.length > 0) {
      gclientName = gclientDropDown?.current?.getOptions()?.find(option => option.value === gclientId)?.data?.name;  
    // }
    // else { //편집창일 때
    //   gclientName = gproject?.gclientDetails?.find(gclientDetail => gclientDetail.code === 'FABRICATOR')?. // 가공업체 중
    //                       gclients?.map(gclient => gclient.value).find(gclient => gclient.id === gclientId).name;
    // }

    const orderDatas = {
      // id: getValues("id"),
      // orderSerialNo: getValues("orderSerialNo"),
      orderNo: getValues("orderNo"),
      // gprojectId: getValues("gprojectId"),
      // gclientName: gclients.find(gclient => gclient.id === getValues("gclientId")).name,
      gclientName,
      site: getValues("site"),
      inChargeNameGclient: getValues("inChargeNameGclient"),
      inChargePhoneGclient: getValues("inChargePhoneGclient"),
      // inChargeEmailGclient,
      deliveryAddress: getValues("deliveryAddress"),
      orderDate: getValues("orderDate"),
      deliveryDate: getValues("deliveryDate"),
      // owner,
      orderer: getValues("orderer"),
      ordererName: getValues("ordererName"),
      ordererPhone: getValues("ordererPhone"),
      ordererEmail: getValues("ordererEmail"),
      // comments: getValues("comments"), // TODO : 추가 필요
      gorderDetails,
      // sumAmount: getValues("sumAmount"),
      // sumAreaMeter: getValues("sumAreaMeter"),
      // sumAreaJa: getValues("sumAreaJa"),
      // sumAreaFeet: getValues("sumAreaFeet"),
      // sumOrderPriceTotal: getValues("sumOrderPriceTotal"),
    };
    
    await ExcelUtils.createExcelForOrders(orderDatas);
  }

  const handleGorderProcess = () => {
    setGorderId(selectedRow?.id);
    // setParams(params); // TODO : 필요한가?
    setSendDialogOpen(true);
  }

  return (
    <>
      <Dialog open={openProgress}>
        <DialogContent>
          <Box sx={{ width: '500px' }}>
            {"발주서 작성 중입니다."}
            <LinearProgress sx={{ mt: 1 }} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openChangeProject}>
        <DialogContent>
          <Box sx={{ width: '500px' }}>
            {"프로젝트를 변경 중입니다."}
            <LinearProgress sx={{ mt: 1 }} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          // onMinMax={tabValue === 1 && handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          {crudMode === 'C' ? "발주 등록" : ( crudMode === 'U' ? "발주 수정" : "발주 상세" )}
        </DialogTitleClose>
        <DialogContent dividers>
          {/* TODO : 엑셀그리드 형식이 아닌 입력의 경우 이보다 더 좋은 UX가 아직 개발전이므로 탭을 일단 막음 */}
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label={"엑셀"} {...a11yProps(0)} />
              <Tab label={"일반"} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}> */}
            <>
              <ul>
                {errors.map((error, idx) => <li key={idx}>{error}</li>)}
              </ul>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"id"}
                        control={control}
                        label={"아이디"}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"orderSerialNo"}
                        control={control}
                        label={"발주일련번호"}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputDropdown
                        name="gprojectId"
                        control={control}
                        label={"프로젝트"}
                        options={ gprojects.map(gproject => ({ label: gproject.site, value: gproject.id })) }
                        onChangeSelectValue={handleSelectProject}
                        // disabled={(modify || !objectEmptyCheck(gproject)) ? true : false}
                        // disabled={modify || gprojectId}
                        // disabled={/*crudMode === 'U' && */from?.params?.gprojectId}
                        inputProps={{
                          // id: "demo-controlled-open-select",
                          ref: setSelectProjectInputRef,
                          readOnly: from?.params?.gprojectId || crudMode === 'R',
                        }}
                        // inputProps={
                        //   { readOnly: true, }
                        // }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"orderNo"}
                        control={control}
                        label={"발주번호"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputDate1
                        name="orderDate"
                        control={control}
                        label={"발주일"}
                        value={getValues("orderDate")}
                        ref={orderDate1}
                        readOnly={crudMode === 'R'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputDate1
                        name="deliveryDate"
                        control={control}
                        label={"납기일"}
                        value={getValues("deliveryDate")}
                        ref={deliveryDate1}
                        readOnly={crudMode === 'R'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"comments"}
                        control={control}
                        label={"설명"}
                        multiline
                        maxRows={5}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* TODO : 외주의 경우 가공업체가 발주를 한다. 그러므로 발주주체가 가공업체일 경우는 가공업체 목록에서 본사(현재 발주하는 업체)는 제외하도록 할 것
                      => 가공업체 목록의 경우 프로젝트에 등록된 업체 기준이므로 발주주체가 가공업체일 경우 프로젝트에 없는 업체로 발주하므로(목록이 없으므로) 추가 검색하는 부분을 선택(추가검색은 가공업체중 계약된 업체) */}

                      {/**
                       * FormInputDropdown: 첫 렌더링 후 외부에서 setValue 호출해도 내부적으로 값은 반영되나 화면에 적용안됨.
                       * FormInputDropdown: 위의 사항을 수정했으나 첫 렌더링 시 설정이 안되어 ref(gclientDropDown).current.setSelectedValue1 호출해주어야 함
                       */}
                      <FormInputDropdown1
                        ref={gclientDropDown}
                        name="gclientId"
                        control={control}
                        label={"가공업체"}
                        options={
                          gproject?.gclientDetails?.find(gclientDetail => gclientDetail.code === 'FABRICATOR')?.
                            gclients?.map(gclient => {
                              // TODO : 아래 label, data 모두 gclients에서 얻어와야 한다면 여기서 한번만 find하도록 할 것
                              return {
                                label: gclient.value.name,
                                // label: gclients.find(gc => gc.id === gclient.value.id)?.name, // TODO : 회사명은 거의 바뀌지 않으므로 기존 데이터를 사용하고 있음. 검토 필요
                                value: gclient.value.id,
                                // data: gclient.value
                                data: gclients.find(gc => gc.id === gclient.value.id), // data에는 최신 GClients로부터 조회한 정보를 담는다.
                              };
                            })
                        }
                        setValue={setValue}
                        getValues={getValues}
                        inputProps={{ readOnly: crudMode === 'R' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"site"}
                        control={control}
                        label={"현장명"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"deliveryAddress"}
                        control={control}
                        label={"착지주소"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"inChargeNameGclient"}
                        control={control}
                        label={"현장소장명"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"inChargePhoneGclient"}
                        control={control}
                        label={"현장소장연락처"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormInputText
                        name={"inChargeEmailGclient"}
                        control={control}
                        label={"담당자이메일"}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <FormInputText
                        name={"owner"}
                        control={control}
                        label={"발주자명"}
                        // multiline
                        // maxRows={5}
                      /> */}
                      <FormInputText
                        name={"ordererName"}
                        control={control}
                        label={"발주자명"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"ordererPhone"}
                        control={control}
                        label={"발주자연락처"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"ordererEmail"}
                        control={control}
                        label={"발주자이메일"}
                        inputProps={
                          { readOnly: crudMode === 'R', }
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"status"}
                        control={control}
                        label={"상태"}
                        inputProps={
                          { readOnly: true, }
                        }
                        sx={{ visibility: 'hidden' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <GOrderExcel
                // initExcelDatas={selectedRow?.gorderDetails}
                initExcelDatas={gorderDetails}
                selectedRow={selectedRow}
                setValue={setValue}
                getValues={getValues}
                setOpenProgress={setOpenProgress}
                focus={setFocusSelectProjectInput}
              />
            </>
          {/* </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              {
                modify && (
                  <Grid item xs={12}>
                    <FormInputText
                      name={"id"}
                      control={control}
                      label={"아이디"}
                      disabled
                    />
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <FormInputText
                  name={"name"}
                  control={control}
                  label={"이름"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"code"}
                  control={control}
                  label={"코드"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                />
              </Grid>
            </Grid>
          </TabPanel> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportExcel}>{"엑셀내보내기"}</Button>
          {/* <Button onClick={handleSubmit(onSubmit)}>{modify ? "수정" : "저장"}</Button> */}
          {
            (crudMode === 'C' || crudMode === 'U' ) && (
              <>
                <Button onClick={handleSubmit(onSubmit)}>{crudMode === 'C' ? "저장" : ( crudMode === 'U' ? "수정" : "" ) }</Button>
                <Button onClick={() => reset()}>{"초기화"}</Button>
              </>
            )
          }
          {
            (getValues("status") === 'WRITING' || getValues("status") === 'RETURN') && (
              <Button onClick={handleGorderProcess}>{"전송"}</Button>
            )
          }
          {/* TODO : 아래 코드 안되는 이유? */}
          {/* <Button onClick={reset}>{"초기화"}</Button> */}
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        // open={openAlert}
        setAlertInfo={setAlertInfo}
      />
      <ConfirmDialog
        removeId={removeObject}
        title={"안내"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirm}
        onCancel={cancel}
      >
        {`프로젝트를 변경하면 작성중인 발주내역이 초기화됩니다. 계속 진행하시겠습니까?`}
      </ConfirmDialog>
      <ConfirmDialog
        removeId={""}
        title={"안내"}
        open={orderConfirmOpen}
        setOpen={setOrderConfirmOpen}
        onConfirm={() => {
          handleDialogClose();
          refresh();
        }}
        // onCancel={cancel}
        type={"ALERT"}
      >
        {/* {modify ? "발주서가 수정되었습니다." : "발주서가 작성되었습니다."} */}
        {crudMode === 'C' ? "발주서가 작성되었습니다." : ( crudMode === 'U' ? "발주서가 수정되었습니다." : "" ) }
      </ConfirmDialog>
    </>
  );
};

export default GOrderDialog;
