import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_G04_DOCU_ETC = 'g04docuEtc/initG04docuEtc';
const INIT_G04_DOCU_ETCS = 'g04docuEtc/initG04docuEtcs';
const SET_G04_DOCU_ETC = 'g04docuEtc/setG04docuEtc';
const REMOVE_G04_DOCU_ETC = "g04docuEtc/removeG04docuEtc";
const SET_G04_DOCU_ETCS = 'g04docuEtc/setG04docuEtcs';

const initG04docuEtc = () => ({
  type: INIT_G04_DOCU_ETC,
});

const initG04docuEtcs = () => ({
  type: INIT_G04_DOCU_ETCS,
});

const setG04docuEtc = (g04docuEtc) => ({
  type: SET_G04_DOCU_ETC,
  payload: g04docuEtc,
});

const removeG04docuEtc = () => ({
  type: REMOVE_G04_DOCU_ETC,
});

const setG04docuEtcs = (g04docuEtcs) => ({
  type: SET_G04_DOCU_ETCS,
  payload: g04docuEtcs,
});

export const create = ({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }),
  });
  const data = await response.json();
  dispatch(setG04docuEtc(data.g04docuEtc));
  return response;
};

export const modify = ({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }),
  });
  const data = await response.json();
  dispatch(setG04docuEtc(data.g04docuEtc));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs`);
  const data = await response.json();
  dispatch(setG04docuEtcs(data.g04docuEtcs));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs/query/selectAll`);
  const data = await response.json();
  dispatch(setG04docuEtcs(data.g04docuEtcs));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs/${id}`);
  const data = await response.json();
  dispatch(setG04docuEtc(data.g04docuEtc));
  return response;
}

export const initializeG04docuEtcs = () => async dispatch => {
  dispatch(initG04docuEtcs());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docuEtc());
  return response;
};

export const removeWithFile = ({ id, gclientId, g04docuEtcId, documentPath }) => async (dispatch) => {
  console.log(id, gclientId, g04docuEtcId, documentPath);
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuEtcs/${id}/withFile`, {
    method: "DELETE",
    body: JSON.stringify({ gclientId, g04docuEtcId, documentPath }),
  });
  dispatch(removeG04docuEtc());
  return response;
};

export const initializeG04docuEtc = () => async (dispatch) => {
  dispatch(initG04docuEtc());
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuEtcs/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  g04docuEtc: null,
  g04docuEtcs: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU_ETC:
      newState = Object.assign({}, state, { g04docuEtc: null });
      return newState;
    case INIT_G04_DOCU_ETCS:
      newState = Object.assign({}, state, { g04docuEtcs: initialState.g04docuEtcs });
      return newState;
    case SET_G04_DOCU_ETC:
      newState = Object.assign({}, state, { g04docuEtc: action.payload });
      return newState;
    case REMOVE_G04_DOCU_ETC:
      newState = Object.assign({}, state, { g04docuEtc: null });
      return newState;
    case SET_G04_DOCU_ETCS:
      newState = Object.assign({}, state, { g04docuEtcs: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
