// 배경 밝이 따른 글자색 (white, black) 선택
export const getTextColorByBackgroundColor = (hexColor) => {
  if (!hexColor) {
    return "black";
  }
  
  const c = hexColor.substring(1) // 색상 앞의 # 제거
  const rgb = parseInt(c, 16) // rrggbb를 10진수로 변환
  const r = (rgb >> 16) & 0xff // red 추출
  const g = (rgb >> 8) & 0xff // green 추출
  const b = (rgb >> 0) & 0xff // blue 추출
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
  
  // 색상 선택
  return luma < 127.5 ? "white" : "black" // 글자색
}

// 보색 구하기
export const getComplementaryColor = (color) => {
  /* 
    (128, 64, 250) 이란 칼라의 색이 있다면,
    255-128 = 128
    255-64 = 191
    255-250 = 5
  */

  // color = "#000000"
  let cColor = 'white';
  
  let R = color.substring(1,3);
  let G = color.substring(3,5);
  let B = color.substring(5);
  
  console.log(R, G, B);

  let cR = (255 - parseInt(R, 16)).toString(16);
  let cG = (255 - parseInt(G, 16)).toString(16);
  let cB = (255 - parseInt(B, 16)).toString(16);

  console.log(parseInt(R, 16), parseInt(G, 16), parseInt(B, 16))
  console.log(255-parseInt(R, 16), 255-parseInt(G, 16), 255-parseInt(B, 16))
  console.log(cR, cG, cB);

  cColor = `#${cR}${cG}${cB}`;
  return cColor;
}

export const getRGBfromHex = (hexColor) => {
  const c = hexColor.substring(1) // 색상 앞의 # 제거
  const rgb = parseInt(c, 16) // rrggbb를 10진수로 변환
  const r = (rgb >> 16) & 0xff // red 추출
  const g = (rgb >> 8) & 0xff // green 추출
  const b = (rgb >> 0) & 0xff // blue 추출
  
  return [r, g, b];
}