// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_GPROJECT_MAP = 'gprojectMaps/setGProjectMap';
const SET_GPROJECT_MAPS = 'gprojectMaps/setGProjectMaps';
const SET_GPROJECTS = 'gprojectMaps/setGProjects';

const setGProjectMap = (gprojectMap) => ({
  type: SET_GPROJECT_MAP,
  payload: gprojectMap,
});

const setGProjectMaps = (gprojectMaps) => ({
  type: SET_GPROJECT_MAPS,
  payload: gprojectMaps,
});

const setGProjects = (gprojects) => ({
  type: SET_GPROJECTS,
  payload: gprojects,
});

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps`);
  const data = await response.json();
  // console.log(data.gprojectMaps)
  dispatch(setGProjectMaps(data.gprojectMaps));
  return response;
}

export const selectAllByOwnerByQuery = (ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps/query/selectAll/owner/${ownerId}`);
  const data = await response.json();
  dispatch(setGProjectMaps(data.gprojectMaps));
  return response;
}

export const selectAllByGProjectAndOwnerByQuery = (gprojectId, ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps/query/selectAll/gproject/${gprojectId}/owner/${ownerId}`);
  const data = await response.json();
  dispatch(setGProjectMaps(data.gprojectMaps));
  return response;
}

export const selectGProjectsByOwnerByQuery = (ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps/query/select/projects/owner/${ownerId}`);
  const data = await response.json();
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const selectGProjectsBySearchByQuery = (ownerId, searchName, searchSite) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps/query/select/projects/owner/${ownerId}?searchName=${searchName}&searchSite=${searchSite}`);
  const data = await response.json();
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectMaps/${id}`);
  const data = await response.json();
  dispatch(setGProjectMap(data.gprojectMap));
  return response;
}

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojectMaps/${id}`);
  const data = await response.json();
  return data.gprojectMap;
}

export const initGprojectMaps = () => async dispatch => {
  dispatch(setGProjectMaps([]));
}

const initialState = {
  gprojectMap: null,
  gprojectMaps: [],
  gprojects: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GPROJECT_MAP:
      newState = Object.assign({}, state, { gprojectMap: action.payload });
      return newState;
    case SET_GPROJECT_MAPS:
      newState = Object.assign({}, state, { gprojectMaps: action.payload });
      return newState;
    case SET_GPROJECTS:
      newState = Object.assign({}, state, { gprojects: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
