import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  OpenInNew,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';

import {
  FormInputMultipleCheckbox,
  FormInputRadio,
  FormInputText,
} from "../../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../../utils";
import * as glineActions from "../../../store/gline";
import * as gprocessActions from "../../../store/gprocess";
import * as gworkOrderDetailActions from "../../../store/gworkOrderDetail";
import GWorkOrderStatusByGLineDialog from "./GWorkOrderStatusByGLineDialog";

const theme = createTheme();

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 40,
  lineHeight: '40px',
}));
// const ITEM_HEIGHT = 48;

const GWorkOrderStatusByGLineManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  // const [selectedRows, setSelectedRows] = useState([]);  
  const [selectedRow, setSelectedRow] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [gprocesses, setGprocesses] = useState([]);
  const [selectedGProcessCode, setSelectedGProcessCode] = useState("ALL");

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    // console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id, row } = params;

    setSelectedRow(row);

    // TODO : 데이터조회를 다이얼로그를 띄우기 전에 할 것인지 해당 다이얼로그에서 할 것인지 고민해볼것
    await selectAllDetailsByGLineByQuery(id);

    setOpen(true);

    setOpenBackdrop(false); // setOpen 후에 호출하는게 화면 전환이 부드러움
  }

  // const handleSelectUpDown = async ({ type, id }) => {
  //   setOpenBackdrop(true);

  //   await reorder({ type, id });
  //   setLoaded(false);
  //   await selectAllByQuery();
  //   setTimeout(() => setLoaded(true), 300)

  //   setOpenBackdrop(false);
  // }

  const remove = (removeId) => {
    dispatch(glineActions.remove(removeId))
    .then(res => selectAllByQuery())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    setOpen(true);
    setModify(false);
    // initGLine(); // 다이얼로그 초기화
    initWithInitialValue({});
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 300,
      hide: true,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'capacityType',
      headerName: '생산용량 기준',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if(params.row.glineCapacities && Array.isArray(params.row.glineCapacities) && params.row.glineCapacities.length > 0) {
          return params.row.glineCapacities[params.row.glineCapacities.length - 1].capacityType === "amount" ? "수량" : "면적";
        } else {
          return "-";
        }
      },
    },
    {
      field: 'capacity',
      headerName: '생산용량',
      width: 140,
      // editable: true,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params) => {
        // console.log(params)
        if(params.row.glineCapacities && Array.isArray(params.row.glineCapacities) && params.row.glineCapacities.length > 0) {
          return params.row.glineCapacities[params.row.glineCapacities.length - 1].capacityType === "amount" ?
            `${Number(params.row.glineCapacities[params.row.glineCapacities.length - 1].capacity["amount"]).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} EA/일`
          :
            `${Number(params.row.glineCapacities[params.row.glineCapacities.length - 1].capacity["area"]).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 평/일`
        } else {
          return "-";
        }
      },
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
      // renderCell: (params) => {
      //   console.log(params)
      //   return (
      //     <div>
      //       <IconButton
      //         aria-label="more"
      //         id="long-button"
      //         aria-controls={openFunctionMenu ? 'long-menu' : undefined}
      //         aria-expanded={openFunctionMenu ? 'true' : undefined}
      //         aria-haspopup="true"
      //         onClick={handleClick}
      //       >
      //         <MoreVert />
      //       </IconButton>
      //       <Menu
      //         id="long-menu"
      //         MenuListProps={{
      //           'aria-labelledby': 'long-button',
      //         }}
      //         anchorEl={anchorEl}
      //         open={openFunctionMenu}
      //         onClose={handleClose}
      //         PaperProps={{
      //           style: {
      //             maxHeight: ITEM_HEIGHT * 4.5,
      //             width: '20ch',
      //           },
      //         }}
      //       >
      //         {options.map((option) => (
      //           <MenuItem key={option.type} onClick={() => handleSelect({ type: option.type, id: params.id })}>
      //             <ListItemIcon>{option.icon}</ListItemIcon>
      //             <ListItemText>{option.text}</ListItemText>
      //           </MenuItem>
      //         ))}
      //       </Menu>
      //     </div>
      //   );
      // },
    },
  ];

  const rows = useSelector((state) => state.gline.glines);
  
  const { control, setValue, getValues } = useForm({ });

  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllByQuery = () => dispatch(glineActions.selectAllByQuery())
  const selectByGProcessByQuery = (gprocess) => dispatch(glineActions.selectByGProcessByQuery(gprocess))
  const selectByQuery = (id) => dispatch(glineActions.selectByQuery(id))
  const selectGProcessesDirect = () => gprocessActions.selectAllDirect()
  
  const initGLine = () => dispatch(glineActions.initGLine())
  const initWithInitialValue = (initialValue) => dispatch(glineActions.initGLineWithInitialValue(initialValue));
  const reorder = ({ type, id }) => glineActions.reorder({ type, id })
  const selectAllDetailsByGLineByQuery = (glineId) => dispatch(gworkOrderDetailActions.selectAllByGLineByQuery(glineId))

  useEffect(
    async () => {
      // try {
        // await selectAllByQuery();
        await selectByGProcessByQuery('ALL');

        const gprocessesAll = await selectGProcessesDirect();
        setGprocesses(gprocessesAll);

        await hideWatermark();
        // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      // } catch (e) {
      //   console.log("GWorkOrderStatusByGLineManagement");
      //   console.log(e);
      // }
    }, [dispatch]
  );

  const handleChangeGProcessRadioButton = async (event) => {
    setOpenBackdrop(true);
    setLoaded(false);
    
    const gprocess = event.target.value;
    
    await selectByGProcessByQuery(gprocess);

    setTimeout(() => setOpenBackdrop(false), 300);
    
    setSelectedGProcessCode(gprocess);

    setLoaded(true);
  }

  const handleChangeStatusCheckButton = async (event, value) => {
    // setStatusClick([]); // []로 설정함에 유의. ""은 값의 변화가 없으므로 useEffect[statusClick] 호출안됨
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GWorkOrderStatusByGLineDialog
          modify={modify}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAllByQuery}
        />
        <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <ItemHeader key={1} elevation={4}>
                    {"공정"}
                  </ItemHeader>
                </Grid>
                <Grid item xs={10} display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      '& > :not(style)': {
                        // width: 800,
                        height: 40,
                      },
                    }}
                  >
                    <Paper variant="outlined">
                      <Grid container>
                        <Grid item xs={12} display="flex" sx={{ pl: 1.3 }}>
                          {
                            gprocesses.length > 0 && (
                              <FormInputRadio
                                row
                                name={"gprocess"}
                                control={control}
                                options={
                                  [{ label: "전체", value: "ALL", color: 'black' }].concat(gprocesses.filter((gprocess, idx) => idx < gprocesses.length - 1).map(gprocess => {
                                    const { code, name, color } = gprocess;
                                    return {
                                      label: name,
                                      value: code,
                                      color,
                                    }
                                  }))
                                }
                                onChangeButton={handleChangeGProcessRadioButton}
                                defaultValue={'ALL'} 
                              />
                            )
                          }
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: 800, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loaded}
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                  // checkboxSelection
                  // disableSelectionOnClick
                  // onRowSelectionModelChange={(ids) => {
                  //   const selectedIDs = new Set(ids);
                  //   const selectedRows = rows.filter((row) =>
                  //     selectedIDs.has(row.id),
                  //   );
          
                  //   setSelectedRows(selectedRows);
                  // }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GWorkOrderStatusByGLineManagement;
