import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import  {
  ExpandMore,
  ExpandLess,
  Login,
  Logout,
} from '@mui/icons-material';
import { Canvas, useFrame } from "react-three-fiber";
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
} from "../form";
import { objectEmptyCheck } from "../../utils";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as g04docuActions from "../../store/g04docu";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import GGlassComponent from "./GGlassComponent";
import { gridDensityValueSelector } from "@mui/x-data-grid-pro";

function GlassBox(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    // mesh.current.rotation.y += 0.01;
    if (props.rotate === false) {
      mesh.current.rotation.y = 0;  
    } else {
      // mesh.current.rotation.y += 0.01;
      mesh.current.rotation.y += 0.03;
      if (mesh.current.rotation.y >= 3.14) {
        props.setRotate(false);
      }
    }
  });
  return (
    <mesh {...props} ref={mesh} scale={[1, 1, 1]}>
      <boxBufferGeometry args={[6, 8, 0.2]} />
      <meshStandardMaterial color="#80d8ff" />
    </mesh>
  );
}

const GGlassForm = ({
  errors,
  // modify,
  open,
  paste,
  selectedRow,
  gtypeDetailsWithGComponent,
  selectedGcomponentItems,
  setSelectedGcomponentItems,
  gtypes,
  control,
  getValues,
  setValue,
  gtypeDisabled,
  fromwhere,
  // dependentGcomponentMap,
  gtypeDetailsWithGComponentLength,
  selectedG04docuGCertifications,
  setSelectedG04docuGCertifications,
}) => {
  const [loadedGcomponents, setLoadedGcomponents] = useState(true);
  const [rotate, setRotate] = useState(false);
  const [expandLabel, setExpandLabel] = useState(false);
  const [markPosition, setMarkPosition] = useState("BottomRight");
  const [markPositionview, setMarkPositionView] = useState("inout");
  const [dependentGcomponentStateMap, setDependentGcomponentStateMap] = useState(new Map());

  const canvasRef = useRef(null);

  const dispatch = useDispatch();

  // const g04docus = useSelector((state) => state.g04docu.g04docus);

  // 데이터 관리
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))
  const selectG04docusByDivisionAndTypeDirect = (division, type) => g04docuActions.selectByDivisionAndTypeDirect(division, type)
  // const selectG04docusByDivisionDirect = (division) => g04docuGCertificationActions.selectByDivisionDirect(division)
  const selectG04docusByDivisionByQueryDirect = (division) => g04docuGCertificationActions.selectByDivisionByQueryDirect(division)
  
  useEffect(
    async () => {
      // console.log("GGlassForm")
      // console.log(selectedRow)
      if (!objectEmptyCheck(selectedRow)) {
        let values = [];
        if (fromwhere?.from === "project") {
          values = ["id", "no", "name", "standard", "specification", "otherSpecs", "comments", "gtypeId", "labelPositionView", "labelPosition"];
        } else {
          values = ["id", "name", "specification", "otherSpecs", "comments", "gtypeId", "labelPositionView", "labelPosition", "selectedG04docuGCertifications"];
        }
        
        values.forEach(item => {
          if (item === "id") {
            if (fromwhere?.from === "project" && fromwhere?.side === 'left') {
              setValue("id", uuidv4());
            } else {
              setValue("id", selectedRow && selectedRow[item] || "");
            }
          } else if (item === "labelPositionView") {
            selectedRow && selectedRow[item] ? setValue(item, selectedRow[item]) : setValue('labelPositionView', 'inout');
            setMarkPositionView(selectedRow && selectedRow[item] ? selectedRow[item] : '');
          } else if (item === "labelPosition") {
            selectedRow && selectedRow[item] ? setValue(item, selectedRow[item]) : setValue('labelPosition', 'none');
            setMarkPosition(selectedRow && selectedRow[item] ? selectedRow[item] : '');
          } else if (item === "selectedG04docuGCertifications") {
            selectedRow[item].forEach(elem => setValue(elem.id, elem.checked))
          } else {
            setValue(item, selectedRow && selectedRow[item] || "");
          }
        });
      } else {
        // TODO : 다이얼로그 창 닫을 때도 들어옴. 불필요한 동작으로 보임
        // if (!modify) {
        setValue('labelPositionView', 'inout');
        setValue('labelPosition', 'BottomRight');
        setValue("id", uuidv4());
      }
    }, [selectedRow]
  );
  
  useEffect(() => {
    if (!canvasRef) return;
    const ctx = canvasRef.current?.getContext("2d");
    const image = new Image();
    image.src = "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png";

    image.onload = function() {
      ctx?.drawImage(image, 0, 0);
    };
  }, [canvasRef]);

  const handleChangeLabelPositionView = (e) => {
    // setRotate(true);
    const positionView = e.target.value;

    setMarkPositionView(positionView);

    setValue('labelPositionView', positionView);
  }

  // const handleClickLabel = () => {
  //   setExpandLabel(!expandLabel);
  // }

  function merge(a, b, prop) {
    var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
    return reduced.concat(b);
  }

  useEffect(
    async () => {
      if (open) {
        // const g04docuGCertifications = await selectG04docusByDivisionDirect('PROCESS');
        const g04docuGCertifications = await selectG04docusByDivisionByQueryDirect('PROCESS');
        console.log(g04docuGCertifications)
        if (selectedRow.selectedG04docuGCertifications) {
          // TODO : merge 결과 체크 필요
          const merged = merge(selectedRow.selectedG04docuGCertifications, g04docuGCertifications, "id");
          setSelectedG04docuGCertifications(merged);
        } else {
          setSelectedG04docuGCertifications(g04docuGCertifications);
        }
      }
    }, [open]
  )
  const handleChangeGtype = async (e) => {
    // e.target.name은 undefined 임???
    const gtypeId = e.target.value;
    setValue("gtypeId", gtypeId);
    setLoadedGcomponents(false);
    await selectGTypeDetailsWithGComponent(gtypeId);
    setTimeout(() => setLoadedGcomponents(true), 300);
  }

  // const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent) => {
  //   console.log(gcomponent);
  //   console.log(item);
  //   const properties = item.properties.filter(property => property.id === e.target.value);
  //   console.log(properties)
    
  //   let value = "";
  //   if (properties.length > 0) {
  //     value = properties[0];
  //   }

  //   setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value);
  //   // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);

  //   const itemNew = {
  //     id: item.id,
  //     name: item.name,
  //     value,
  //   };

  //   const selectedNew = selectedGcomponentItems;
  //   selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;

  //   // TODO : 해당 값이 변할 때 의존구성요소값이 있다면 그 구성요소의 값을 설정해야 한다. 값을 변화시킬 수 있는데 화면에 설정값으로 렌터링은 아직...
  //   if (properties.length > 0 && properties[0]?.dependentGcomponentItem?.length > 0) {
  //     const dependentGcomponentItem = JSON.parse(properties[0].dependentGcomponentItem);
  //     console.log(dependentGcomponentItem)

  //     let targetGcomponentItemOrder = "";
  //     gcomponent.forEach((component, idx) => {
  //       if (component.id === dependentGcomponentItem.gcomponentId) {
  //         targetGcomponentItemOrder = idx;
  //       }
  //     });
      
  //     console.log(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`);
  //     setValue(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`, `${dependentGcomponentItem.id}`);
      
  //     const dependentGcomponentItemNew = {
  //       id: dependentGcomponentItem.gcomponentId,
  //       name: dependentGcomponentItem.name,
  //       value: dependentGcomponentItem,
  //     }

  //     selectedNew[gtypeDetailsOrder][targetGcomponentItemOrder] = dependentGcomponentItemNew;

  //     // TODO : 등록하기에서는 템플릿구조가 선택되지 않아 map 구성이 안되어 있으므로 여기서 구성 필요
  //     dependentGcomponentMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, {
  //       ...dependentGcomponentItemNew,
  //       gtypeOrder: gtypeDetailsOrder,
  //       gcomponentOrder: targetGcomponentItemOrder,
  //     });

  //     console.log(dependentGcomponentMap);
  //   } else {
  //     const map = dependentGcomponentMap.get(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
  //     if (map) {
  //       setValue(`${map.gtypeOrder}_${map.gcomponentOrder}_${map.id}`, "");
  //       selectedNew[map.gtypeOrder][map.gcomponentOrder] = {
  //         id: map.id,
  //         name: map.name,
  //         value: "",
  //       };
  //     }
  //   }
   
  //   setSelectedGcomponentItems(selectedNew);

  //   // TODO : 아래처럼 할지 useEffect 쓸지 추후 검토
  //   setSpec(selectedNew);
  // }

  // const generateGTypeDetailWithComponent = () => {
  //   if (gtypeDetailsWithGComponent && gtypeDetailsWithGComponent.length && gtypeDetailsWithGComponent.length > 0) {
  //     // 한 행에 gcomponent를 몇개 위치시킬 것인지 정한다. 현재는 3열
  //     let quotient = 3; // 1, 2, 3, 4, 6 가능
  //     const detailsCount = gtypeDetailsWithGComponent.length;
  //     if (detailsCount < 12/quotient) {
  //       quotient = detailsCount % quotient === 0 ? quotient : detailsCount % quotient;
  //     }
      
  //     return (
  //       <Grid
  //         container={true}
  //         spacing={2}
  //         sx={{ visibility: loadedGcomponents ? 'visible' : 'hidden', height: loadedGcomponents ? "" : '30px' }}
  //       >
  //          {
  //            gtypeDetailsWithGComponent.map((gtypeDetail, index) => {
  //             return <Grid item xs={12} sm={12/quotient}>
  //               <Card>
  //                 <CardHeader
  //                   sx={{ bgcolor: "#1976d2", color: "white", height: '40px' }}
  //                   align="center"
  //                   titleTypographyProps={{ variant: 'subtitle1' }}
  //                   title={gtypeDetail[0].name} />
  //                 {
  //                   <Grid container sx={{ pt: 2, pb:2 }}>
  //                     {
  //                       generateComponent(gtypeDetail, index)
  //                     }
  //                   </Grid>
  //                 }
  //               </Card>
  //             </Grid>
  //            })
  //          }
  //       </Grid>
  //     )
  //   }
  // }
  
  // const generateComponent = (gcomponent, gtypeDetailsIndex) => {
  //   const component = gcomponent.map((item, gcomponentItemIndex) => {
  //     if (item.level > gcomponent[0].level) {
  //       const indent = item.level * 2;
  //       if (item.type === 'group') {
  //         return (
  //           <Grid
  //             item xs={12}
  //             sx={{ ml: indent, mt: 1, mb: 1, mr: 2, p: 1, bgcolor: "#03a9f4", color: "white", borderRadius: "5px" }}
  //             align="center"
  //           >
  //             {item.name}
  //           </Grid>
  //         )
  //       } else if (item.type === 'property') {
  //         return (
  //           <Grid item xs={12} sx={{ ml: indent, mt: 1, mb: 1, mr: 2 }}>
  //             {/* <FormInputText */}
  //               {/* select */}
  //             <FormInputDropdown
  //               name={`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`}
  //               control={control}
  //               label={item.name}
  //               onChange={(e) => handleChangeGcomponentItem(e, item, gtypeDetailsIndex, gcomponentItemIndex, gcomponent)}
  //               options={
  //                 [{ label: '없음', value: "" }].concat(item.properties.map(property => {
  //                   return {
  //                     label: property.name,
  //                     value: property.id,
  //                   }
  //                 }))
  //               }
  //               // value={dependentGcomponentStateMap.get(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`)}
  //               // value={getGcomponentValue(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`)}
  //               // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
  //               // defaultValue={""}
  //             />
  //           </Grid>
  //         )
  //       }
  //     }
  //   });
  //   return component;
  // }

  // const getGcomponentValue = (name) => {
  //   let value;
  //   value = getValues(name)
  //   console.log(dependentGcomponentStateMap)
  //   // value = dependentGcomponentStateMap.get(name);
  //   console.log(value)
  //   // if (value) {
  //     return value;
  //   // }
  //   // return getValues(name);
  // }

  const setGcomponentItems = () => {
    // alert("123")
    // 설정한 값들을 select 박스에서 선택
    selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
      gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
        setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value.id);
      });
    });
  }

  useEffect(
    () => {
      // alert("123")
      selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
        gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
          dependentGcomponentStateMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value.id);
        });
      });
      console.log(dependentGcomponentStateMap)
      setDependentGcomponentStateMap(dependentGcomponentStateMap);
    }, [selectedGcomponentItems]
  )

  const setSpec = (selectedNew) => {
    let specification = "";
    let otherSpecs = "";

    const selected = selectedGcomponentItems || selectedNew;
    selected && selected.forEach(gtypeDetails => {
      let productName = "";
      gtypeDetails.forEach(gcomponentItem => {
        if (gcomponentItem.value.applyType === 'productName') {
          productName += gcomponentItem.value.code ? `${gcomponentItem.value.code} ` : "";
        } else if (gcomponentItem.value.applyType === 'etc') {
          otherSpecs += gcomponentItem.value.code ? `${gcomponentItem.value.code}, ` : "";
        }
      });
      productName = productName.substring(0, productName.length - 1);
      specification += (productName !== "" ? `${productName} + ` : "");
    });
    
    setValue("specification", specification.substring(0, specification.length - 3));
    setValue("otherSpecs", otherSpecs.substring(0, otherSpecs.length - 2));
  }

  const handleClickLabelSection = (position) => {
    setMarkPosition(position);

    setValue("labelPosition", position);
  }

  const handleClickInOutButton = () => {
    let mode = "";
    if (markPositionview === "inout") {
      mode = "outin";
    } else {
      mode = "inout";
    }

    setMarkPositionView(mode);
    setValue('labelPositionView', mode);
  }

  const generateGlass = () => {
    const colGrid = (row, rowIdx) => {
      return ['Left', 1, 2, 3, 'Right'].map((col, colIdx) => {
        return (
          <Grid key={`${row}${col}`} item onClick={() => (`${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight') && handleClickLabelSection(`${row}${col}`)}>
            <Box
              display="flex"
              sx={{
                height: 50,
                width: 70,
                bgcolor: `${row}${col}` === markPosition ? '#bbdefb' : '#e3f2fd',
                border: `${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight' ? '1px dashed #1976d2' : '',
                cursor: `${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight' ? 'pointer' : '',
                borderRadius: '5px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="primary.main">
                {
                  `${row}${col}` === markPosition ?
                    '라벨 '
                  :
                    (
                      rowIdx === 2 && colIdx == 2 ?
                      (
                        markPositionview === "inout" ?
                          <IconButton aria-label="inout" size="large" color="primary" onClick={handleClickInOutButton}>
                            <Logout />
                          </IconButton>
                          // <Icon fontSize="large">logout</Icon>
                        :
                          <IconButton aria-label="inout" size="large" color="primary" onClick={handleClickInOutButton}>
                            <Login />
                          </IconButton>
                          // <Icon fontSize="large">login</Icon>
                      )
                      :
                        ''
                    )
                }
              </Typography>
            </Box>
          </Grid>
        )
      })
    }
    
    return ['Top', 1, 2, 3, 'Bottom'].map((row, rowIdx) => {
      return (
        <Grid container justifyContent="center" spacing={0}>
          {colGrid(row, rowIdx)}
        </Grid>
      );
    });
  }

  const handleChangeLabelPosition = (e) => {
    const position = e.target.value;
    
    setMarkPosition(position);

    setValue("labelPosition", position);
  }

  const handleChangeCheckButton = (e, item, i) => {
    const { checked } = e.target;
    console.log(checked)

    let itemNew = Object.assign(item, selectedG04docuGCertifications[i]);
    
    itemNew.checked = checked;
    itemNew.index = i;

    console.log(itemNew);
    
    let items = selectedG04docuGCertifications;

    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    console.log(selectedNew)
    
    setSelectedG04docuGCertifications(selectedNew);
  }

  return (
    <>
      <ul>
        {errors.map((error, idx) => <li key={idx}>{error}</li>)}
      </ul>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
              />
            </Grid>
            {
              fromwhere?.from === "project" && (
                <Grid item xs={12}>
                  <FormInputText
                    name={"no"}
                    control={control}
                    label={"품번"}
                  />
                </Grid>
              )
            }
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                label={"품명"}
              />
            </Grid>
            {
              fromwhere?.from === "project" && (
                <Grid item xs={12}>
                  <FormInputText
                    name={"standard"}
                    control={control}
                    label={"규격"}
                  />
                </Grid>
              )
            }
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"specification"}
                control={control}
                label={"사양"}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"specificationDisplay"}
                control={control}
                label={"사양"}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"otherSpecs"}
                control={control}
                label={"비고"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"otherSpecsDisplay"}
                control={control}
                label={"비고"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInputText
                select
                name="labelPositionView"
                control={control}
                label={"보는 방향"}
                onChange={handleChangeLabelPositionView}
                options={[
                  {
                    label: "실내에서 봤을 때",
                    value: "inout",
                  },
                  {
                    label: "실외에서 봤을 때",
                    value: "outin",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
                // initialValue={"inout"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                select
                name="labelPosition"
                control={control}
                label={"라벨위치"}
                onChange={handleChangeLabelPosition}
                options={[
                  {
                    label: "라벨없음",
                    value: "none",
                  },
                  {
                    label: "좌측상단",
                    value: "TopLeft",
                  },
                  {
                    label: "우측상단",
                    value: "TopRight",
                  },
                  {
                    label: "좌측하단",
                    value: "BottomLeft",
                  },
                  {
                    label: "우측하단",
                    value: "BottomRight",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
                // initialValue={"BottomRight"}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Paper sx={{ height: 270, width: 370, backgroundColor: '#e3f2fd', pt: 1 }}>
                { generateGlass(/*markPosition*/) }
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              
            </Grid>

            {/* <Grid item xs={12} sm={2}>
              <Typography variant="h6" component="div">
                {"라벨표기"}
                <IconButton edge="end" aria-label="expand" onClick={handleClickLabel}>
                  { expandLabel ? <ExpandLess /> : <ExpandMore /> }
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormInputText
                select
                name="labelPositionView"
                control={control}
                label={"보는 방향"}
                onChange={handleChangeLabelPositionView}
                options={[
                  {
                    label: "실내에서 봤을 때",
                    value: "inside",
                  },
                  {
                    label: "실외에서 봤을 때",
                    value: "outside",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormInputText
                select
                name="labelPosition"
                control={control}
                label={"라벨위치"}
                // onChange={handleChangeLabelPosition}
                options={[
                  {
                    label: "좌측상단",
                    value: "LeftTop",
                  },
                  {
                    label: "우측상단",
                    value: "RightTop",
                  },
                  {
                    label: "좌측하단",
                    value: "LeftBottom",
                  },
                  {
                    label: "우측하단",
                    value: "RightBottom",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
              />
            </Grid> */}
          </Grid>
          {/* {
            expandLabel && (
              <Grid item xs={12} sx={{ height: '500px' }}>
                <Canvas>
                  <ambientLight intensity={2.0} />
                  <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                  <pointLight position={[-10, -10, -10]} />
                  <GlassBox position={[0, 0, -3]} rotate={rotate} setRotate={setRotate} />
                </Canvas>
              </Grid>
            )
          } */}
        </Grid>
        {
          fromwhere?.from !== "project" && (
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
                {"템플릿 구조를 선택하고 유리샘플을 구성합니다."}
              </Typography>
            </Grid>
          )
        }
         
        <Grid item xs={12}>
          <FormInputText
            select
            name="gtypeId"
            control={control}
            label={"템플릿 구조"}
            onChange={handleChangeGtype}
            options={
              gtypes.map(gtype => {
                return {
                  label: gtype.name,
                  value: gtype.id,
                }
              })
            }
            // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
            defaultValue={""}
            disabled={gtypeDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          {
            !loadedGcomponents && (
              <Box sx={{ mt: 2, width: '100%' }}>
                <LinearProgress />
              </Box>
            )
          }
          {
            // gtypeDetailsWithGComponent의 길이가 gtype 길이와 같을 때가 조건이 되어야 함. 그렇지 않으면 GGlassComponent안에서 최초의 defaultValues가 이전 것으로 구성되어 setValue가 되지 않음 (uncontrolled)
            loadedGcomponents && gtypeDetailsWithGComponent.length === gtypeDetailsWithGComponentLength && (
              <GGlassComponent
                gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
                selectedGcomponentItems={selectedGcomponentItems}
                setSelectedGcomponentItems={setSelectedGcomponentItems}
                setSpecValue={setValue}
              />
            )
          }
          
          {/* TODO : 아래와 같이 두 함수를 호춣시 위 함수실행이 완료된 후 아래 함수가 실행되는지(sync) 확인 필요 */}
          {/* {
            generateGTypeDetailWithComponent()
          }
          {
            setGcomponentItems()
          }
          {
            setSpec()
          } */}
        </Grid>
        <Grid item xs={12}>
          {
            fromwhere?.from !== "project" && (
              <Grid item xs={12}>
                <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
                  {"가공방법을 선택합니다."}
                </Typography>
              </Grid>
            )
          }
          {/* {JSON.stringify(g04docus)} */}
          <Grid container>
            {
              selectedG04docuGCertifications.map((g04docu, i) => {
                const { id, name, code } = g04docu;
                return (
                  <Grid item display="flex" alignItems="center" sx={{ ml : i===0 ? 0 : 2 }}>
                    <FormInputCheckbox
                      name={id}
                      control={control}
                      onChangeCheckValue={(e) => handleChangeCheckButton(e, g04docu, i)}
                    />
                    <Typography>{name}</Typography>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GGlassForm;
