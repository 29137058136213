import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  // LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Email,
  Password,
} from '@mui/icons-material';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  FormInputText,
} from "../../components/form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../../components/dialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom"
import { uploadFilePath, fileServerUrl } from '../../config';
import { objectEmptyCheck } from "../../utils";
import * as sessionActions from "../../store/session";
import * as menuActions from '../../store/menu';
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as gclientG04docuGCertificationMapActions from "../../store/gclientG04docuGCertificationMap";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gclientGComponentItemMapActions from "../../store/gclientGComponentItemMap";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientType04docuActions from "../../store/gclientType04docu";
import * as gclientActions from "../../store/gclient";
import * as userActions from "../../store/user";
import * as mailActions from "../../store/mail";

import CertifiedBox from "./CertifiedBox";
import PasswordChangeDialog from "../Password/PasswordChangeDialog";
import EmailSettingDialog from "./EmailSettingDialog";
import Users from "./Users";
import Security from "./Security";
import G04GeneralDocu from "./G04GeneralDocu";
import G04docuGCertification from "./G04docuGCertification";
import G04docuGTestRawMaterial from "./G04docuGTestRawMaterial";
import G04docuGTestSubMaterial from "./G04docuGTestSubMaterial";
import G04docuGTestProcess from "./G04docuGTestProcess";
import './Profile.css';
import G04docuEtc from './G04docuEtc';

const defaultValues = {
  id: "",
  name: "",
  bizRegNumber: "",
  password: "",
  confirmPassword: "",
  phone: "",
  address: "",
  email: "",

  // 날인문구
  // supplierLabel: "제공사",
  supplierLabel: "",
  supplier: "",
  phrase: "",

  userId: "",
  userName: "",
  mobile: "",
};

const validateFields = {
  name: "회사명",
  phone: "대표전화번호",
  address: "주소",
  email: "회사대표메일",
  // inChargeName: "담당자명",
  // inChargePhone: "담당자 전화번호",
  // inChargeEmail: "담당자 이메일",
};

const theme = createTheme();

const requiredMessage = "을(를) 입력해주세요.";
const emailMessage = "을(를) 올바른 이메일 형태로 입력해주세요.";

const validateSchema = {
  // bizRegNumber: yup.string().required(requiredMessage),
  name: yup.string().required(requiredMessage),
  phone: yup.string().required(requiredMessage),
  // password: yup.string().required(requiredMessage),
  // confirmPassword: yup.string().required(requiredMessage),
  address: yup.string().required(requiredMessage),
  email: yup.string().email(emailMessage).required(requiredMessage),
  // inChargeName_0: yup.string().required(requiredMessage),
  // inChargePhone_0: yup.string().required(requiredMessage),
  // inChargeEmail_0: yup.string().required(requiredMessage),
  // inChargeName_1: yup.string().required(requiredMessage),
  // inChargePhone_1: yup.string().required(requiredMessage),
  // inChargeEmail_1: yup.string().required(requiredMessage),
  // inChargeName_2: yup.string().required(requiredMessage),
  // inChargePhone_2: yup.string().required(requiredMessage),
  // inChargeEmail_2: yup.string().required(requiredMessage),
}

const schema = yup.object(validateSchema).required();

/** 트리데이터 구조 예시 */
const treeData = [
  {
    id: 'CL',
    label: 'CL',
    type: 'division',
    children: [
      {
        id: 'CL_3',
        label: '3 mm 파일개념',
      },
      {
        id: 'CL_4',
        label: '4 mm',
      },
    ],
  },
  {
    id: 'GRN',
    label: 'GRN',
    type: 'division',
    children: [
      {
        id: 'GRN_4',
        label: '4 mm',
      },
      {
        id: 'GRN_5',
        label: '5 mm',
      },
    ],
  },
  {
    id: 'ECT173',
    label: 'ECT173',
    type: 'division',
    children: [
      {
        id: 'ECT173_5',
        label: '5 mm',
      },
      {
        id: 'ECT173_6',
        label: '6 mm',
      },
    ],
  },
  {
    id: 'ECT264',
    label: 'ECT264',
    type: 'division',
  },
  {
    id: '복층',
    label: '복층',
    type: 'division',
    children: [
      {
        id: '복층 U1',
        label: 'U1',
        children: [
          {
            id: '16T 5+6A+5',
            label: '16T 5+6A+5 폴더개념',
            children: [
              {
                id: '16T 5+6A+5 CL 파일개념',
                label: '16T 5+6A+5 CL CL ',
              },
              {
                id: '18T 6+6A+6 GN' ,
                label: '18T 6+6A+6 GN GN',
              },
            ]
          },
          {
            id: '18T 6+6A+6',
            label: '18T 6+6A+6',
          },
        ]
      },
      {
        id: '복층 U2',
        label: 'U2',
      },
    ],
  },
];

const Profile = () => {
  const { handleSubmit, setValue, control, getValues, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });
  
  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gclientTypes = useSelector((state) => state.gclientType.gclientTypes);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);
  const gclientType04docus = useSelector((state) => state.gclientType04docu.gclientType04docus);
  const sessionUserLoginType = useSelector((state) => state.session.sessionUser.sessionUserLoginType);

  // no dispatch
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()

  // dispatch
  const dispatch = useDispatch();

  const modifyUser = ({ id, userId, name, mobile, gclientId }) => dispatch(userActions.modify({ id, userId, name, mobile, gclientId }))
  const deleteUser = (id) => dispatch(userActions.remove(id))
  const gclientLeave = (id) => dispatch(gclientActions.leave(id));
  const modifyGClient = ({ id, gclientTypes, gclientType04docus, name, bizRegNumber, password, confirmPassword, phone, address, email, inCharges, phrases, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => dispatch(gclientActions.modify({ id, gclientTypes, gclientType04docus, name, bizRegNumber, password, confirmPassword, phone, address, email, inCharges, phrases, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }))
  const selectAllByGClient = (gclientId) => dispatch(userActions.selectAllByGClient(gclientId))
  const selectGClientTypes = () => dispatch(gclientTypeActions.selectAll())
  const selectGClientType04docus = () => dispatch(gclientType04docuActions.selectAll())

  // const addInCharges = [];
  // sessionUser.inCharges.forEach((inCharge, idx) => {
  //   for (const [key, value] of Object.entries(inCharge)) {
  //     // console.log(`${key}_${idx}`)
  //     addInCharges.push({ [`${key}_${idx}`]: yup.string().required(requiredMessage) });
  //   }
  // });
  
  // const { handleSubmit, setValue, control, getValues, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(yup.object(Object.assign(validateSchema, ...addInCharges)).required()) });
  // const { handleSubmit, setValue, control, getValues, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(validateSchema) });
  
  const [phoneMask, setPhoneMask] = useState("000-0000-0000");
  const [mobileMask, setMobileMask] = useState("000-0000-0000");

  const [inCharges, setInCharges] = useState([]);

  // 헤더 토글
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [signExpanded, setSignExpanded] = useState(true);
  // 

  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedGClientId, setSelectedGClientId] = useState("");
  const [selectedGClient, setSelectedGClient] = useState({});
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState(null);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");
  const [selectedDocuFileType, setSelectedDocuFileType] = useState("");
  const [openGeneral, setOpenGeneral] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [etcType, setEtcType] = useState("");
  const [selectedGClientTypeIds, setSelectedGClientTypeIds] = useState([]);
  const [gclientType04docusGrouped, setGClientType04docusGrouped] = useState([]);
  const [selectedGClientType04docuIdsGrouped, setSelectedGClientType04docuIdsGrouped] = useState([]);

  // 비밀번호 변경 다이얼로그 관련
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [mode, setMode] = useState("");
  const [type, setType] = useState("");
  const [openEmailAppPasswordDialog, setOpenEmailAppPasswordDialog] = useState(false);
  const [openEmailAppPasswordDialogUser, setOpenEmailAppPasswordDialogUser] = useState(false);
  // 

  const [alertInfo, setAlertInfo] = useState({});

  // 회원탈퇴
  const [leaveObject, setLeaveObject] = useState({});
  const [confirmOpenLeave, setConfirmOpenLeave] = useState(false);

  useEffect(
    () => {
      if (!objectEmptyCheck(errors)) {
        console.log(errors)
        let error;
        for (const [key, value] of Object.entries(validateFields)) {
          // console.log(`${key}: ${value}`);
          if (errors[key]) {
            error = errors[key];
            error.fieldName = value;
            break;
          }
        }

        // console.log(error)
        if (error) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: `${error?.fieldName}${error?.message}`,
            open: true,
          });
        }
      }
    }, [errors]
  )

  const [URLThumbnail, setURLThumbnail] = useState();

  const createImageURL = (fileBlob) => {  // createObjectURL 방식
    if (URLThumbnail) URL.revokeObjectURL(URLThumbnail);

    const url = URL.createObjectURL(fileBlob);

    setURLThumbnail(url);
  };

  const onImageChange = (e) => {
    const { files } = e.target;

    if (!files || !files[0]) return;

    const uploadImage = files[0];

    createImageURL(uploadImage);
  };

  useEffect(
    async () => {
      // console.log(sessionUser)
      // const newValidateFields = {
      //   name: "회사명",
      //   phone: "대표전화번호",
      //   address: "주소",
      //   email: "회사대표메일",
      //   // inChargeName: "담당자명",
      //   // inChargePhone: "담당자 전화번호",
      //   // inChargeEmail: "담당자 이메일",
      // };
      
      // const inChargeObj = {
      //   inChargeName: "담당자명",
      //   inChargePhone: "담당자 전화번호",
      //   inChargeEmail: "담당자 이메일",
      // }
      // sessionUser.inCharges.forEach((inCharge, idx) => {
      //   for (const [key, value] of Object.entries(inCharge)) {
      //     // console.log(`${key}_${idx}`)
      //     newValidateFields[`${key}_${idx}`] = inChargeObj[key];
      //   }
      // });
      
      // console.log(newValidateFields)
      // setValidateFields(newValidateFields);

      setOpenBackdrop(true);

      const { sessionUserLoginType } = sessionUser;
      if (sessionUserLoginType === "GCLIENT") {
        if (sessionUser.initPassword) {
          setOpenPasswordDialog(true);
          setMode('C');
        }
      } else if (sessionUserLoginType === "USER") {
        if (sessionUser.user[0].initPassword) {
          setOpenPasswordDialog(true);
          setMode('C');
        }
      }

      // 기본정보 설정
      for (const [item, value] of Object.entries(defaultValues)) {
        setValue(item, sessionUser[item] || value);
      }

      if (sessionUser["phrases"]) {
        const { supplierLabel, supplier, phrase } = sessionUser["phrases"];
        setValue("supplierLabel", supplierLabel);
        setValue("supplier", supplier);
        setValue("phrase", phrase);
      }/* else {
        setValue("supplier", sessionUser.name);
        setValue("phrase", `이 문서는 ${sessionUser.name}가 제출처에게 발행한 문서입니다.`);
      }*/

      // console.log(sessionUser);

      const { id: gclientId, gclientTypes: myGClientTypes, gclientType04docus: myGClientType04docus, inCharges: inChargePersons } = sessionUser;
      // console.log({ gclientId, myGClientTypes, myGClientType04docus })

      if (inChargePersons && Array.isArray(inChargePersons)) {
        setInCharges(inChargePersons);
      } else {
        setInCharges([{
          inChargeName: "",
          inChargePhone: "",
          inChargeEmail: "",
        }]);
      }

      // TODO : redux 데이터가 보낸 순서대로 들어오는지 파악 필요. 지금은 그렇다고 가정하고 되어 있음. 문제발생시 이 문제때문인지 아닌지 우선 고려할 것
      await selectGClientTypes();
      await selectGClientType04docus();

      // const newSelectedGClientTypeIds = myGClientTypes.map(type => type.id);
      // setSelectedGClientTypeIds(newSelectedGClientTypeIds); // 대분류 설정
      
      // // 세분류 설정
      // const gclientType04docusByGClientType = newSelectedGClientTypeIds.map(gclientTypeId => {
      //   return {
      //     gclientTypeId,
      //     gclientTypeName: gclientTypes.find(gclientType => gclientType.id === gclientTypeId)?.name,
      //     gclientType04docus: gclientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId)
      //   }
      // })
  
      // // console.log(gclientType04docusByGClientType);
  
      // setSelectedGClientTypeIds(newSelectedGClientIds);
      // setGClientType04docusGrouped(gclientType04docusByGClientType);
      
      // const newSelectedGClientType04docuIds = newSelectedGClientTypeIds.map(gclientTypeId => {
      //   return {
      //     gclientTypeId: gclientTypeId,
      //     selectedGClientType04docuIds: myGClientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId).map(type => type.id),
      //   }
      // })
      // console.log(newSelectedGClientType04docuIds);
      // setSelectedGClientType04docuIdsGrouped(newSelectedGClientType04docuIds);
      
      const resGClients = await selectAllGClientDirect();

      // const gclientId = sessionUser.id;
      setSelectedGClientId(gclientId);
      const gclient = resGClients.find(gclient => gclient.id === gclientId)
      // console.log(gclient)
      setSelectedGClient(gclient);

      // 사용자 목록
      // await selectAllByGClient(gclientId);

      // 회원사 사용자일 때 정보 설정
      if (sessionUserLoginType === 'USER') {
        const { user } = sessionUser;
        if (user && Array.isArray(user) && user.length === 1) {
          const { userId, name, mobile } = user[0];
          setValue("userId", userId);
          setValue("userName", name);
          setValue("mobile", mobile);
        }
      }
      
      setOpenBackdrop(false);
    }, [dispatch]
  )

  // TODO : dispatch 순서 테스트. 처음에는 store 순이었다가 최종 dispatch 순서에 따르는 것으로 테스트 결과는 나옴
  // useEffect(
  //   () => {
  //     console.log("111")
  //     console.log(gclientTypes)
  //   }, [gclientTypes]
  // )

  // useEffect(
  //   () => {
  //     console.log("222")
  //     console.log(gclientType04docus)
  //   }, [gclientType04docus]
  // )

  // 초기 페이지 로딩할 때 거래선 구분값 목록 구성 및 설정
  useEffect(
    () => {
      if (gclientTypes.length > 0 && gclientType04docus.length > 0) {
        const { gclientTypes: myGClientTypes, gclientType04docus: myGClientType04docus } = sessionUser;
        // console.log({ myGClientTypes, myGClientType04docus })
        
        if (myGClientTypes && Array.isArray(myGClientTypes)) {
          // 대분류 설정 : 대분류 목록은 이미 gclientTypes를 기반으로 만들어져 있고 접속회사의 대분류 구분 값을 설정한다.
          const newSelectedGClientTypeIds = myGClientTypes.map(type => type.id);
          setSelectedGClientTypeIds(newSelectedGClientTypeIds);

          // 세분류 설정
          if (myGClientType04docus && Array.isArray(myGClientType04docus)) {
            // 1. 목록 설정
            const gclientType04docusByGClientType = newSelectedGClientTypeIds.map(gclientTypeId => {
              return {
                gclientTypeId,
                gclientTypeName: gclientTypes.find(gclientType => gclientType.id === gclientTypeId)?.name,
                gclientType04docus: gclientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId)
              }
            })
            setGClientType04docusGrouped(gclientType04docusByGClientType);
            
            // 2. 값 설정
            const newSelectedGClientType04docuIds = newSelectedGClientTypeIds.map(gclientTypeId => {
              return {
                gclientTypeId: gclientTypeId,
                selectedGClientType04docuIds: myGClientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId).map(type => type.id),
              }
            })
            // console.log(newSelectedGClientType04docuIds);
            setSelectedGClientType04docuIdsGrouped(newSelectedGClientType04docuIds);
          }
        }
      }
    }, [gclientTypes, gclientType04docus]
  )

  // 일반문서와 인증서에 기타 문서를 포함시키는 로직은 backend에서 했으나 제품/가공은 DB조회 후 frontend에서 수행 (아래 타입별로 수행) => 현재는 모두 backend에서 수행하도록 했음
  // useEffect(
  //   async () => {
  //     const gtestsAllForTree = sessionUserGComponentItemsRawMaterial.map(item => ({
  //         id: item.id,
  //         label: item.name,
  //         data: item,
  //         // type: 'ITEM',
  //         children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
  //           const { order : value1 } = a;
  //           const { order : value2 } = b;
  //           if (value1 < value2) return -1;
  //           if (value1 > value2) return 1;
  //           return 0;
  //         }).map(item1 => ({
  //           id: `${item.id}/${item1.id}`,
  //           label: item1.name,
  //           data: item1,
  //           type: 'DOCTEST',
  //         }))
  //       }
  //     ));
      
  //     setRawMaterialTreeData(gtestsAllForTree);
  //   }, [sessionUserGComponentItemsRawMaterial]
  // )

  // useEffect(
  //   async () => {
  //     const gtestsAllForTree = sessionUserGComponentItemsSubMaterialProcess.map(item => ({
  //         id: item.id,
  //         label: item.name,
  //         data: item,
  //         // type: 'ITEM',
  //         children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
  //           const { order : value1 } = a;
  //           const { order : value2 } = b;
  //           if (value1 < value2) return -1;
  //           if (value1 > value2) return 1;
  //           return 0;
  //         }).map(item1 => ({
  //           id: `${item.id}/${item1.id}`,
  //           label: item1.name,
  //           data: item1,
  //           type: 'DOCTEST',
  //         }))
  //       }
  //     ));
      
  //     setSubMaterialProcessTreeData(gtestsAllForTree);
  //   }, [sessionUserGComponentItemsSubMaterialProcess]
  // )

  // useEffect(
  //   async () => {
  //     const gtestsAllForTree = sessionUserGComponentItemsSubMaterialBuild.map(item => ({
  //         id: item.id,
  //         label: item.name,
  //         data: item,
  //         // type: 'ITEM',
  //         children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
  //             const { code : value1 } = a;
  //             const { code : value2 } = b;
  //             if (value1 < value2) return -1;
  //             if (value1 > value2) return 1;
  //             return 0;
  //           }).map(item1 => ({
  //             id: `${item.id}/${item1.id}`,
  //             label: item1.name,
  //             data: item1,
  //             type: 'DOCTEST',
  //           }))
  //       }
  //     ));
      
  //     setSubMaterialBuildTreeData(gtestsAllForTree);
  //   }, [sessionUserGComponentItemsSubMaterialBuild]
  // )

  // useEffect(
  //   async () => {
  //     const gtestsAllForTree = sessionUserProcesses.map(item => ({
  //         id: item.id,
  //         label: item.name,
  //         data: item,
  //         // type: 'ITEM',
  //         // children: item.tests?.sort((a, b) => {
  //         //   const { code : value1 } = a;
  //         //   const { code : value2 } = b;
  //         //   if (value1 < value2) return -1;
  //         //   if (value1 > value2) return 1;
  //         //   return 0;
  //         // }).map(item1 => ({
  //         //   id: `${item.id}/${item1.id}`,
  //         //   label: item1.name,
  //         //   data: item1,
  //         //   type: 'DOCTEST',
  //         // }))
  //         children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
  //           const { code : value1 } = a;
  //           const { code : value2 } = b;
  //           if (value1 < value2) return -1;
  //           if (value1 > value2) return 1;
  //           return 0;
  //         }).map(item1 => ({
  //           id: `${item.id}/${item1.id}`,
  //           label: item1.name,
  //           data: item1,
  //           type: 'DOCTEST',
  //         }))
  //       }
  //     ));
      
  //     setProcessTreeData(gtestsAllForTree);
  //   }, [sessionUserProcesses]
  // )
  
  const emailValidCheck = yup.object().shape({
    inChargeEmail: yup.string().email(),
  });//.required();

  const onSubmit = async ({ id, bizRegNumber, name, phone, address, email, supplierLabel, supplier, phrase, userId, userName, mobile, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => {
    // console.log({ id, bizRegNumber, name, phone, address, email, supplierLabel, supplier, phrase, userId, userName, mobile, initPassword, loginCount, ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership })
    // return;
    const phrases = {
      supplierLabel, supplier, phrase
    };
    // console.log(phrases)
    // return;
    // console.log(inCharges)
    // const inChargeObj = {
    //   inChargeName: "담당자명",
    //   inChargePhone: "담당자 전화번호",
    //   inChargeEmail: "담당자 이메일",
    // };

    // let valid = true;

    // for await (let inCharge of inCharges) {
    //   let idx = -1;
    //   for (const [key, value] of Object.entries(inCharge)) {
    //     idx = idx + 1;
    //     // console.log({ key, value })
    //     if (key === "inChargeNameNotValid" || key === "inChargePhoneNotValid" || key === "inChargeEmailNotValid") continue;

    //     if (value === "") {
    //     // if (!value) {
    //       valid = false;
    //       setAlertInfo({
    //         titleAlert: "안내",
    //         messageAlert: `${inChargeObj[key]}을(를) 입력해주세요.`,
    //         open: true,
    //       });
    //       return;
    //     } else {
    //       if (key === "inChargeEmail") {
    //         const emailCheckResult = await emailValidCheck.isValid({ inChargeEmail: inCharge[key]})
    //         console.log(emailCheckResult)
    //         if (!emailCheckResult) {
    //           const newInCharges = inCharges.map((inCharge, i) => (i === idx ? { ...inCharge, inChargeEmailNotValid: true } : inCharge));
    //           setInCharges(newInCharges);

    //           valid = false;
    //           setAlertInfo({
    //             titleAlert: "안내",
    //             messageAlert: `${inChargeObj[key]}${emailMessage}`,
    //             open: true,
    //           });
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }

    // inCharges.forEach(async inCharge => {
    //   for (const [key, value] of Object.entries(inCharge)) {
    //     if (!value) {
    //       valid = false;
    //       setAlertInfo({
    //         titleAlert: "안내",
    //         messageAlert: `${inChargeObj[key]}을(를) 입력해주세요.`,
    //         open: true,
    //       });
    //       return;
    //     } else {
    //       if (key === "inChargeEmail") {
    //         const emailCheckResult = await emailValidCheck.isValid({ inChargeEmail: inCharge[key]})
    //         console.log(emailCheckResult)
    //         if (!emailCheckResult) {
    //           valid = false;
    //           alert("email error")
    //           return;
    //         }
    //       }
          
    //     }
    //   }
    // })
    
    // if (!valid) return;

    // setErrors([]);
    
    setLoading(true);

    if (sessionUserRole === 'ADMIN_ROLE') {
      const myGClientTypes = gclientTypes.filter(gclientType => selectedGClientTypeIds.includes(gclientType.id));

      // console.log(selectedGClientType04docuIdsGrouped);
      // 아래 코드에서 filter를 통해 대분류가 설정되지 않은 그 하위의 세분류 항목은 제외한다. handleChangeGClientTypes 함수의 설명과 같이 이해
      const selectedGClientType04docuIds = [].concat(...selectedGClientType04docuIdsGrouped.filter(item => selectedGClientTypeIds.includes(item.gclientTypeId)).map(item => item.selectedGClientType04docuIds)); // arr1d = [].concat(...arr2d)
      const myGClientType04docus = gclientType04docus
                                    .filter(gclientType04docu => selectedGClientType04docuIds.includes(gclientType04docu.id))
                                    .map(item => ({ ...item, gclientTypeName: myGClientTypes.find(type => type.id === item.gclientTypeId)?.name }));

      // console.log({ id, bizRegNumber, name, phone, address, email, myGClientTypes, myGClientType04docus })
      // setLoading(false);
      // return

      // TODO : onSubmit은 return이 필요한가?
      // TODO : 고객 타입(GCLIENT & USER)에 따른 처리 필요
      // TODO : 회원사 사용자 정보(내정보)도 수정
      modifyGClient({ id, gclientTypes: myGClientTypes, gclientType04docus: myGClientType04docus, bizRegNumber, name, phone, address, email, inCharges, phrases, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership })
        .then (res => {
          console.log(res)
          // updateSessionUser
          // setTimeout(() => setLoading(false), 1000);
        })
        .catch (async (res) => {
          const data = await res.json();
          // if (data && data.errors) setErrors(data.errors);
        });
      
      if (sessionUserLoginType === 'USER') {
        await modifyUser({ id: sessionUser.user[0].id, userId: sessionUser.user[0].userId, name: userName, mobile, gclientId: selectedGClientId });

        await selectAllByGClient(selectedGClientId);
      }

      setTimeout(() => setLoading(false), 1000);

      await dispatch(sessionActions.restoreSessionUser());
    } else if (sessionUserRole === 'USER_ROLE') {
      await modifyUser({ id: sessionUser.user[0].id, userId: sessionUser.user[0].userId, name: userName, mobile, gclientId: selectedGClientId });
      setTimeout(() => setLoading(false), 1000);
    }
  };

  // const handleClickGeneralDoc = (e, g04GeneralDocu, mode) => {
  //   // console.log(g04GeneralDocu)
  //   // console.log(g04GeneralDocu.g04docuEtcId)
  //   if (g04GeneralDocu.g04docuEtcId === undefined) { // undefined이면 일반문서, null이면 기타에서 일반문서로 첨부한 문서 // TODO : g04docuEtcId값으로 판단해도 될지...??
  //     const { id, code, name, gclientId, documentPath, validYN, startDate, endDate } = g04GeneralDocu;
  //     if (mode === 'attachDocument') {
  //       setSelectedRow({
  //         g04GeneralDocuId: id,
  //         documentType: code,
  //         title: name,
  //         documentPath,
  //         validYN,
  //         startDate,
  //         endDate,
  //       });
    
  //       setOpenGeneral(true);
  //     } else if (mode === 'deleteDocument') {
  //       if (documentPath) {
  //         setRemoveFileObject({ gclientId, documentPath, type: 'GENERAL' });
  //         setConfirmOpenRemoveFile(true);
  //       }
  //     }
      
  //   } else {
  //     const { id, code, name, gclientId, documentPath, validYN, startDate, endDate } = g04GeneralDocu;
  //     if (mode === 'attachDocument') {
  //       setSelectedRowEtc({
  //         g04docuEtcId: id,
  //         documentType: code,
  //         title: name,
  //         documentPath,
  //         validYN,
  //         startDate,
  //         endDate,
  //       });
    
  //       setEtcType('GENERAL');
  //       setOpenEtc(true);
  //     } else if (mode === 'deleteDocument') {
  //       if (documentPath) {
  //         setEtcType('GENERAL');
  //         setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
  //         setConfirmOpenRemoveFile(true);
  //       }
  //     }
  //   }
  // }

  // const handleClickGCertification/*GTest*/ = (e, gcertification, type, mode) => {
  //   // console.log(gcertification);
  //   if (gcertification.g04docuEtcId === undefined) { // undefined이면 일반문서, null이면 기타에서 일반문서로 첨부한 문서 // TODO : g04docuEtcId값으로 판단해도 될지...??
  //     const { id, name, gclientId, documentPath, startDate, endDate } = gcertification;
  //     if (mode === 'attachDocument') {
  //       setSelectedRow({
  //         g04docuGCertificationId: id,
  //         g04docuGCertificationName: name,
  //         documentPath,
  //         startDate,
  //         endDate,
  //       });
  
  //       setSelectedDocuFileType(type);
  //       setOpen(true);
  //     } else if (mode === 'deleteDocument') {
  //       if (documentPath) {
  //         setSelectedDocuFileType(type);
  //         setRemoveFileObject({ gclientId, g04docuGCertificationId: id, documentPath, type: 'CERTIFICATION' });
  //         setConfirmOpenRemoveFile(true);
  //       }
  //     }
  //   } else {
  //     const { id, code, gclientId, name, documentPath, validYN, startDate, endDate } = gcertification;
  //     if (mode === 'attachDocument') {
  //       setSelectedRowEtc({
  //         g04docuEtcId: id,
  //         documentType: code,
  //         title: name,
  //         documentPath,
  //         validYN,
  //         startDate,
  //         endDate,
  //       });
    
  //       setEtcType('CERTIFICATION');
  //       setOpenEtc(true);
  //     } else if (mode === 'deleteDocument') {
  //       if (documentPath) {
  //         setEtcType('CERTIFICATION');
  //         setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
  //         setConfirmOpenRemoveFile(true);
  //       }
  //     }
  //   }
  // }

  // const handleClickEtc = (e, etc, mode) => {
  //   const { id, code, gclientId, name, documentPath, validYN, startDate, endDate } = etc;
  //   if (mode === 'attachDocument') {
  //     setSelectedRowEtc({
  //       g04docuEtcId: id,
  //       documentType: code,
  //       title: name,
  //       documentPath,
  //       validYN,
  //       startDate,
  //       endDate,
  //     });

  //     setEtcType('ETC');
  //     setOpenEtc(true);
  //   } else if (mode === 'deleteDocument') {
  //     if (documentPath) {
  //       setEtcType('ETC');
  //       setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
  //       setConfirmOpenRemoveFile(true);
  //     }
  //   }
  // }

  const handleClickFileDownload = async (documentPath) => {
    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];
  
    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  // const handleChangeModeG04docuGeneral = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeG04docuGeneral(newMode);

  //   selectMyG04GeneralDocus(selectedGClientId, newMode);
  // }

  // const handleChangeModeG04docuGCertification = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeG04docuGCertification(newMode);

  //   selectMyG04docuGCertifications(selectedGClientId, newMode);
  // }

  // const handleChangeModeRawMaterialG04docuGTest = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeRawMaterialG04docuGTest(newMode);

  //   selectMyItemsForTree(selectedGClientId, 'RAW_MATERIAL', newMode);
  // }

  // const handleChangeModeSubMaterialProcessG04docuGTest = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeSubMaterialProcessG04docuGTest(newMode);

  //   selectMyItemsForTree(selectedGClientId, 'SUB_MATERIAL_PROCESS', newMode);
  // }

  // const handleChangeModeSubMaterialBuildG04docuGTest = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeSubMaterialBuildG04docuGTest(newMode);

  //   selectMyItemsForTree(selectedGClientId, 'SUB_MATERIAL_BUILD', newMode);
  // }

  // const handleChangeModeProcessG04docuGTest = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeProcessG04docuGTest(newMode);

  //   selectMyProcessesForTree(selectedGClientId, newMode);
  // }

  // const handleChangeModeG04docuEtc = (e, newMode) => {
  //   e.stopPropagation();

  //   setModeG04docuEtc(newMode);

  //   selectMyG04docuEtcs(selectedGClientId, 'ETC', newMode);
  // }

  // const selectAllG04GeneralDocus = (gclientId) => {
  //   selectMyG04GeneralDocus(gclientId, modeG04docuGeneral);
  // }

  // const selectAllGCertifications = (gclientId) => {
  //   selectMyG04docuGCertifications(gclientId, modeG04docuGCertification);
  // }

  // const selectAllGComponentItems = (gclientId, division) => {
  //   let mode = "";
  //   if (division === 'RAW_MATERIAL') {
  //     mode = modeRawMaterialG04docuGTest;
  //   } else if (division === 'SUB_MATERIAL_PROCESS') {
  //     mode = modeSubMaterialProcessG04docuGTest;
  //   } else if (division === 'SUB_MATERIAL_BUILD') {
  //     mode = modeSubMaterialBuildG04docuGTest;
  //   }

  //   selectMyItemsForTree(gclientId, division, mode);
  // }

  // const selectAllProcesses = (gclientId) => {
  //   selectMyProcessesForTree(gclientId, modeProcessG04docuGTest);
  // }

  // const selectAllG04docuEtcs = (gclientId) => {
  //   selectMyG04docuEtcs(gclientId, 'ETC', modeG04docuGeneral);
  // }

  const handleChangeGClientTypes = (e, newSelectedGClientIds) => {
    // 대분류 중 세분류를 가지고 있는 경우 그 대분류 항목의 체크를 해제하면 세분류 설정값은 남아있다.
    // 이때 세분류 설정값을 초기화하려 했으나 설정 중 다시 대분류 항목을 체크했을 때 기존 세분류 설정값을 유지하는 게 편의상 유리하다고 판단하여
    // 저장시 불필요한 세분류 설정을 초기화함
    console.log(e)
    console.log(newSelectedGClientIds)

    const gclientType04docusByGClientType = newSelectedGClientIds.map(gclientTypeId => {
      return {
        gclientTypeId,
        gclientTypeName: gclientTypes.find(gclientType => gclientType.id === gclientTypeId)?.name,
        gclientType04docus: gclientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId)
      }
    })

    console.log(gclientType04docusByGClientType);

    setSelectedGClientTypeIds(newSelectedGClientIds);
    setGClientType04docusGrouped(gclientType04docusByGClientType);
  }

  // useEffect(
  //   () => {
  //     console.log({ gclientTypes, gclientType04docus })
  //     const gclientType04docusByGClientType = selectedGClientTypeIds.map(gclientTypeId => {
  //       return {
  //         gclientTypeId,
  //         gclientTypeName: gclientTypes.find(gclientType => gclientType.id === gclientTypeId)?.name,
  //         gclientType04docus: gclientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === gclientTypeId)
  //       }
  //     })
      
  //     setGClientType04docusGrouped(gclientType04docusByGClientType);
  //   }, [selectedGClientTypeIds]
  // )

  const handleChangeGClientType04docuIdsGrouped = (e, newSelected, gclientTypeId, idx) => {
    console.log({ e, newSelected, gclientTypeId, idx })
    // newSelected에 선택한 값의 배열이 그대로 들어오므로 기존 선택된 배열과 merge 등의 가공을 할 필요가 없음. (참고) const merge = [...new Set([...arr1, ...arr2])];
    const newSelectedGClientType04docuIdsGrouped = [].concat(selectedGClientType04docuIdsGrouped);
    if (newSelectedGClientType04docuIdsGrouped.length-1 < idx) {
        newSelectedGClientType04docuIdsGrouped.push({ gclientTypeId, selectedGClientType04docuIds: newSelected });
    } else {
      newSelectedGClientType04docuIdsGrouped[idx] = { gclientTypeId, selectedGClientType04docuIds: newSelected };
    }

    console.log(newSelectedGClientType04docuIdsGrouped)
    setSelectedGClientType04docuIdsGrouped(newSelectedGClientType04docuIdsGrouped);
  }

  const handleClickViewEachDoc = (documentPath) => {
    // setOpenProgress(true);
    // setShowFileViewer(false);
    
    // const ext = getFileType(documentPath)
    // setFileType(ext);

    const path = documentPath.replace(uploadFilePath, fileServerUrl);
    // console.log(path)
    // setDocumentPath(path);
    
    // setTimeout(() => {
    //   setOpenProgress(false);
    //   setShowFileViewer(true);
    // }, 1000);

    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  const handleClickPasswordChange = (e) => {
    e.stopPropagation();

    setOpenPasswordDialog(true);
    setMode('C');
  }

  // const handleClickAddInCharge = (idx) => {
  //   const newInCharges = inCharges.map(inCharge => inCharge);

  //   const newInCharge = {
  //     inChargeName: "",
  //     inChargePhone: "",
  //     inChargeEmail: "",
  //   };

  //   newInCharges.splice(idx+1, 0, newInCharge);

  //   // console.log(newInCharges);

  //   setInCharges(newInCharges);
  // }

  // const handleClickRemoveInCharge = (idx) => {
  //   // const newInCharges = JSON.parse(JSON.stringify(inCharges));
  //   const newInCharges = inCharges.map(inCharge => inCharge);
  //   newInCharges.splice(idx, 1);
    
  //   // console.log(newInCharges);
    
  //   setInCharges(newInCharges);
  // }

  // const handleClickUpDownInCharge = (type, idx) => {
  //   let newArr;
    
  //   // let newInCharges = JSON.parse(JSON.stringify(inCharges));
  //   let newInCharges = inCharges.map(inCharge => inCharge);
    
  //   // up이면 rownum에서 -1한 행과 swap된 것이고, down이면 +1한 행과 swap된 것임
  //   if (type === "up") {
  //     if (idx > 0) {
  //       newArr = changeArrayOrder(newInCharges, idx, -1);
  //     } else {
  //       return;
  //     }
  //   } else if (type === "down") {
  //     if (idx < newInCharges.length - 1) {
  //       newArr = changeArrayOrder(newInCharges, idx, 1);
  //     } else {
  //       return;
  //     }
  //   } else {
  //     newArr = newInCharges;
  //   }

  //   // console.log(newArr)
    
  //   setInCharges(newArr);
  // }

  // const handleChangeInChargeName = (e, idx) => {
  //   const { value } = e.target;
  //   const newInCharges = inCharges.map((inCharge, i) => (i === idx ? { ...inCharge, inChargeName: value, inChargeNameNotValid: value ? false : true } : inCharge));
  //   setInCharges(newInCharges);
  // }

  // const handleChangeInChargePhone = (e, idx) => {
  //   const { value } = e.target;
  //   const newInCharges = inCharges.map((inCharge, i) => (i === idx ? { ...inCharge, inChargePhone: value, inChargePhoneNotValid: value ? false : true } : inCharge));
  //   setInCharges(newInCharges);
  // }

  // const handleChangeInChargeEmail = async (e, idx) => {
  //   const { value } = e.target;

  //   // 아래 코드를 실행하면 커서가 끝으로 가는 오류가 발생하여 입력시마다 하는 이메일 체크 일단 보류하고 "수정하기" 버튼 눌렀을 때 onSubmit에서만 수행함
  //   // const emailCheckResult = await emailValidCheck.isValid({ inChargeEmail: value });
    
  //   const newInCharges = inCharges.map((inCharge, i) => (i === idx ? { ...inCharge, inChargeEmail: value, inChargeEmailNotValid: (value/* && emailCheckResult*/) ? false : true } : inCharge));
  //   setInCharges(newInCharges);
  // }

  // const handleChangeSupplierLabel = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setValue("supplierLabel", value);  
  //   } else {
  //     setValue("supplierLabel", defaultValues.supplierLabel);
  //   }
  // }

  // const handleChangeSupplier = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setValue("supplier", value);  
  //   } else {
  //     setValue("supplier", "제공사");
  //   }
  // }

  // const handleChangePhrase = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setValue("phrase", value);  
  //   } else {
  //     setValue("phrase", `이 문서는 ${getValues("supplier")}가 제출처에게 발행한 문서입니다.`);
  //   }
  // }

  // const handleClickAddUser = () => {
  //   setCrudModeUser('C');
  //   setOpenUser(true);
  // }

  const handleChangePhone = (e) => {
    const { value } = e.target;

    const areaCode02 = "02";
    const compareCode = value.substring(0, 2);
    if (compareCode === areaCode02) {
      if (value.length < 12) {
        setPhoneMask("00-000-00000"); // 한자리 여분을 두어야 함. 00-000-0000하면 더 이상 입력이 안됨
      } else {
        setPhoneMask("00-0000-0000");
      }
    } else {
      if(value.length < 13) {
        setPhoneMask("000-000-00000"); // 한자리 여분을 두어야 함
      } else {
        setPhoneMask("000-0000-0000");
      }
    }
    
    setValue('phone', value);
  }

  const handleChangeMobile = (e) => {
    const { value } = e.target;
    
    if(value.length < 13) {
      setMobileMask("000-000-00000"); // 한자리 여분을 두어야 함
    } else {
      setMobileMask("000-0000-0000");
    }
    
    setValue('mobile', value);
  }

  // TODO : 원자재/시공부자재/가공부자재/가공을 각각 나누면서 각 부분에서 아래 if가 간결해졌는데 참고를 위해 아래를 당분간 지우지 말 것
  // const removeFile = async () => {
  //   const { gclientId, g04docuGCertificationId, g04docuGTestId, g04docuEtcId, gcomponentItemId, division, documentPath, type } = removeFileObject;
  //   await removeG04docuFile(documentPath);

  //   if (type === 'GENERAL') {
  //     await removeGClientG04GeneralDocuMap({ gclientId, documentPath });
  //     selectAllG04GeneralDocus(gclientId);
  //   } else if (type === 'CERTIFICATION') {
  //     await removeGClientG04docuCertificationMap({ gclientId, g04docuGCertificationId, documentPath });
  //     selectAllGCertifications(gclientId);
  //   } else if (type === 'TEST') {
  //     await removeGClientG04docuGTestMap({ gclientId, g04docuGTestId, documentPath })
  //     if (selectedGComponentItemType === 'PROCESS') { // TODO : division과 동일한 것 같음. 추후 정리할 것
  //       selectAllProcesses(gclientId);
  //     } else {
  //       selectAllGComponentItems(gclientId, division);
  //     }
  //   } else if (type === 'ETC') {
  //     await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
  //     if (etcType === 'GENERAL') {
  //       selectAllG04GeneralDocus(gclientId);
  //     } else if (etcType === 'CERTIFICATION') {
  //       selectAllGCertifications(gclientId);
  //     } else if (etcType === 'TEST' && selectedGComponentItemType === 'PROCESS') { // TODO : division과 동일한 것 같음. 추후 정리할 것
  //       selectAllProcesses(gclientId);
  //     } else if (etcType === 'ETC') {
  //       selectAllG04docuEtcs(gclientId);
  //     } else {
  //       selectAllGComponentItems(gclientId, division);
  //     }
  //   }
  // }

  const handleClickLeave = () => {
    // selectedGClientId
    let id = "";
    if (sessionUserLoginType === 'GCLIENT') {
      id = selectedGClientId;
    } else if (sessionUserLoginType === 'USER') {
      const { user } = sessionUser;
      if (user && Array.isArray(user) && user.length === 1) {
        id = user[0].id;
      }
    }
    
    setLeaveObject({ id });
    setConfirmOpenLeave(true);
  }

  const leave = async ({ id }) => {
    console.log({ id })

    // 먼저 로그아웃 하고 나서 삭제
    await dispatch(sessionActions.logout(sessionUser));
    await dispatch(menuActions.removeAllTabMenus());

    let messageAlert;
    if (sessionUserLoginType === 'GCLIENT') {
      messageAlert = (<>
        <span>{"탈퇴가 완료되었습니다."}</span><br />
        <span>{"3개월이 지나야 재가입하실 수 있습니다."}</span><br />
        <span>{"그동안 공사다큐 서비스를 사용해 주셔서 감사합니다."}</span>
      </>);
      // 실제로 삭제하지 않고 GClients의 valid = false, leaveDate 기록
      gclientLeave(id);
     
    } else if (sessionUserLoginType === 'USER') {
      messageAlert = (<>
        <span>{"탈퇴가 완료되었습니다."}</span><br />
        <span>{"그동안 공사다큐 서비스를 사용해 주셔서 감사합니다."}</span>
      </>);
      
      // 삭제
      await deleteUser(id);
      setAlertInfo({
        titleAlert: "안내",
        messageAlert,
        open: true,
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            // display: 'flex', // 이 속성이 있을 경우 <Box>안에 있는 컴포넌트의 가로 사이즈가 늘어나면 <BOX>도 커짐
            // flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {/* 기본정보 */}
              <Grid item xs={12}>
                {/* TODO : 안의 콤포넌트에 따라 width가 달라지네??? */}
                <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                  <AccordionSummary aria-controls="basic-content" id="basic-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={10}>
                        <Typography variant="h6" component="div">{sessionUserLoginType === "GCLIENT" ? "기본정보" : "회원사 정보"}</Typography>
                      </Grid>
                      {
                        sessionUserLoginType === 'GCLIENT' && (
                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Button
                              onClick={handleClickPasswordChange}
                              fullWidth
                              variant="outlined"
                              startIcon={<Password />}
                            >
                              {"비밀번호 변경"}
                            </Button>
                          </Grid>
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12}>
                        <FormInputText
                          autoComplete="user-name"
                          name="username"
                          control={control}
                          required
                          fullWidth
                          id="username"
                          label="User Name"
                          autoFocus
                          value={sessionUser.userName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          control={control}
                          autoComplete="email"
                          value={sessionUser.email}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="bizRegNumber"
                          name={"bizRegNumber"}
                          control={control}
                          required
                          fullWidth
                          label={"사업자등록번호"}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="name"
                          name={"name"}
                          control={control}
                          required
                          fullWidth
                          label={"회사명"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="phone"
                          name={"phone"}
                          control={control}
                          required
                          fullWidth
                          label={"대표전화번호"}
                          placeholder={"대표전화번호를 입력해주세요. - 는 자동입력됩니다."}
                          InputProps={{
                            inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                            inputProps: {
                              style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                // mask: "*00-*000-0000",
                                mask: phoneMask,
                                // definitions: {
                                //   '#': /[1-9]/,
                                // },
                              },
                              readOnly: sessionUserRole !== 'ADMIN_ROLE' && true
                            }
                          }}
                          errorStyle={{ message: false, border: true }}
                          onChange={handleChangePhone}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="address"
                          name={"address"}
                          control={control}
                          required
                          fullWidth
                          label={"주소"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={sessionUserRole === 'ADMIN_ROLE' ? 10 : 12}>
                        <FormInputText
                          autoComplete="email"
                          name={"email"}
                          control={control}
                          required
                          fullWidth
                          label={"회사대표메일"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      {
                        sessionUserRole === 'ADMIN_ROLE' && (<Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"}>
                          <Button fullWidth onClick={() => setOpenEmailAppPasswordDialog(true)} startIcon={<Email />} variant="outlined">
                            {"메일설정"}
                          </Button>
                        </Grid>)
                      }
                      <Grid item xs={12}>
                        <Card sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                {"대분류"}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <ToggleButtonGroup
                                value={selectedGClientTypeIds}
                                onChange={handleChangeGClientTypes}
                                aria-label="GClient Types"
                                color="primary"
                              >
                                {
                                  gclientTypes.map(type => {
                                    const { id, name } = type;
                                    return (
                                      <ToggleButton value={id} aria-label={`대분류-${name}`} sx={{ fontWeight: 'bold' }} disabled={sessionUserRole === 'ADMIN_ROLE' ? false : true}>
                                        {name}
                                      </ToggleButton>
                                    )
                                  })
                                }
                              </ToggleButtonGroup>
                            </Grid>
                            {
                              gclientType04docusGrouped && gclientType04docusGrouped.filter(gclientType04docusGroup => gclientType04docusGroup.gclientType04docus.length > 0 ? true : false).length > 0 ? (
                                <>
                                  <Grid item xs={12}>
                                    <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                      {"세분류"}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Card sx={{ p: 2 }}>
                                      <Grid container spacing={1}>
                                      {
                                        gclientType04docusGrouped.map((gclientType04docusGroup, idx) => {
                                          if (gclientType04docusGroup.gclientType04docus.length > 0) { // 세분류가 없는 경우 출력이 필요없기 때문에
                                            return (
                                              <>
                                                <Grid item xs={12}>
                                                  <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                                    {gclientType04docusGroup.gclientTypeName}
                                                  </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <ToggleButtonGroup
                                                    value={selectedGClientType04docuIdsGrouped[idx]?.selectedGClientType04docuIds}
                                                    onChange={(e, selectedIds) => handleChangeGClientType04docuIdsGrouped(e, selectedIds, gclientType04docusGroup.gclientTypeId, idx)}
                                                    aria-label="GClient Types"
                                                    color="primary"
                                                  >
                                                    {
                                                      gclientType04docusGroup.gclientType04docus.map(type => {
                                                        const { id, name } = type;
                                                        return (
                                                          <ToggleButton value={id} aria-label={`세분류-${name}`} sx={{ fontWeight: 'bold' }} disabled={sessionUserRole === 'ADMIN_ROLE' ? false : true}>
                                                            {name}
                                                          </ToggleButton>
                                                        )
                                                      })
                                                    }
                                                  </ToggleButtonGroup>
                                                </Grid>
                                              </>
                                            )
                                          } else {
                                            return (<></>)
                                          }
                                        })
                                      }
                                      </Grid>
                                    </Card>
                                  </Grid>
                                </>
                              ) : (<></>)
                            }
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {
                            (sessionUserRole === 'USER_ROLE' || (sessionUserLoginType === 'USER' && sessionUserRole === 'ADMIN_ROLE')) && (
                              <>
                                <Grid item xs={12}>
                                  <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                    <Grid container>
                                      <Grid item xs={12} sm={10} display="flex" alignItems="center">
                                        {"내정보"}
                                      </Grid>
                                      {/* {
                                        sessionUserRole === 'ADMIN_ROLE' && (
                                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                                            <Button variant="outlined" onClick={handleClickAddUser}>{"추가"}</Button>
                                          </Grid>
                                        )
                                      } */}
                                      {/* {
                                        sessionUserRole === 'USER_ROLE' && ( */}
                                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                                            <Button
                                              onClick={handleClickPasswordChange}
                                              fullWidth
                                              variant="outlined"
                                              startIcon={<Password />}
                                            >
                                              {"비밀번호 변경"}
                                            </Button>
                                          </Grid>
                                        {/* )
                                      } */}
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={sessionUserLoginType === 'USER' ? 10 : 12}>
                                  <FormInputText
                                    autoComplete="userId"
                                    required
                                    fullWidth
                                    label="사용자 아이디(이메일)"
                                    name={"userId"}
                                    control={control}
                                    errorStyle={{ message: false, border: true }}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                {
                                  sessionUserLoginType === 'USER' && (
                                    <Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"}>
                                      <Button fullWidth onClick={() => setOpenEmailAppPasswordDialogUser(true)} startIcon={<Email />} variant="outlined">
                                        {"메일설정"}
                                      </Button>
                                    </Grid>
                                  )
                                }
                                <Grid item xs={12}>
                                  <FormInputText
                                    autoComplete="userName"
                                    name={"userName"}
                                    control={control}
                                    required
                                    fullWidth
                                    label={"이름"}
                                    errorStyle={{ message: false, border: true }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormInputText
                                    autoComplete="mobile"
                                    name={"mobile"}
                                    control={control}
                                    required
                                    fullWidth
                                    label={"모바일"}
                                    InputProps={{
                                      inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                                      inputProps: {
                                        style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                          // mask: "000-0000-0000",
                                          mask: mobileMask,
                                          definitions: {
                                            '#': /[1-9]/,
                                          },
                                        },
                                      }
                                    }}
                                    errorStyle={{ message: false, border: true }}
                                    onChange={handleChangeMobile}
                                  />
                                </Grid>
                                </>
                            )
                          }
                          {/* {
                            sessionUserRole === 'ADMIN_ROLE' && (
                              <>
                                <Grid item xs={12}>
                                  <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                    <Grid container>
                                      <Grid item xs={12} sm={10} display="flex" alignItems="center">
                                        {"담당자"}
                                      </Grid>
                                      {
                                        sessionUserRole === 'ADMIN_ROLE' && (
                                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                                            <Button variant="outlined" startIcon={<PersonAdd />} onClick={handleClickAddUser}>{"추가"}</Button>
                                          </Grid>
                                        )
                                      }
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <div style={{ height: 300, width: '100%' }}>
                                    <DataGridPro
                                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                                      sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                                      slots={{
                                        noRowsOverlay: CustomNoRowsOverlay,
                                        loadingOverlay: LinearProgress,
                                      }}
                                      columnHeaderHeight={38}
                                      rowHeight={34}
                                      loading={!loaded}
                                      rows={users}
                                      columns={columns}
                                      pageSize={pageSize}
                                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                      rowsPerPageOptions={[10, 20, 50, 100]}
                                      pagination
                                      onRowSelectionModelChange={async (ids) => {
                                      }}
                                      // onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                                    />
                                  </div>
                                </Grid>
                              </>
                            )
                          } */}
                        {/* {
                          inCharges?.map((inCharge, idx) => {
                            const { inChargeName, inChargeNameNotValid, inChargePhone, inChargePhoneNotValid, inChargeEmail, inChargeEmailNotValid } = inCharge;
                            return (
                              <>
                                <Grid item xs={3}>
                                  <FormInputText
                                    // yup validate을 사용하지는 않지만 이름이 없으면 yup validate 중 에러 발생 : toNestErrors.ts:47 Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'startsWith')
                                    name={`${inChargeName}_${idx}`}
                                    control={control}
                                    label={"담당자명"}
                                    required
                                    // error={inChargeName ? false : true}
                                    error={inChargeNameNotValid}
                                    fullWidth
                                    value={inChargeName}
                                    onChange={(e) => handleChangeInChargeName(e, idx)}
                                    errorStyle={{ message: false, border: true }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormInputText
                                    name={`${inChargePhone}_${idx}`}
                                    control={control}
                                    label={"담당자 전화번호"}
                                    required
                                    // error={inChargePhone ? false : true}
                                    error={inChargePhoneNotValid}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                                      inputProps: {
                                        style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                          mask: "000-0000-0000",
                                          definitions: {
                                            '#': /[1-9]/,
                                          },
                                        },
                                      }
                                    }}
                                    value={inChargePhone}
                                    onChange={(e) => handleChangeInChargePhone(e, idx)}
                                    errorStyle={{ message: false, border: true }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormInputText
                                    name={`${inChargeEmail}_${idx}`}
                                    control={control}
                                    label={"담당자 이메일"}
                                    required
                                    // error={inChargeEmail ? false : true}
                                    error={inChargeEmailNotValid}
                                    fullWidth
                                    value={inChargeEmail}
                                    onChange={(e) => handleChangeInChargeEmail(e, idx)}
                                    errorStyle={{ message: false, border: true }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Stack direction="row" spacing={1}>
                                    <IconButton
                                      size="small"
                                      // color="primary"
                                      onClick={() => handleClickAddInCharge(idx)}
                                    >
                                      <Add/>
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      // color="secondary"
                                      onClick={() => handleClickRemoveInCharge(idx)} disabled={inCharges.length <= 1 ? true : false}
                                    >
                                      <Remove/>
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      // color="success"
                                      onClick={() => handleClickUpDownInCharge('up', idx)}
                                    >
                                      <KeyboardArrowUp/>
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      // color="info"
                                      onClick={() => handleClickUpDownInCharge('down', idx)}
                                    >
                                      <KeyboardArrowDown/>
                                    </IconButton>
                                  </Stack>
                                </Grid>
                              </>
                            )
                          })
                        } */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton
                          onClick={handleSubmit(onSubmit)}
                          fullWidth
                          variant="contained"
                          loading={loading}
                          // sx={{ mt: 3, mb: 2 }}
                        >
                          {"수정하기"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
               {/* 담당자 */}
              { sessionUserRole === 'ADMIN_ROLE' && (<Grid item xs={12}><Users /></Grid>) }
              {/* 날인정보 */}
              <Grid item xs={12}>
                <Accordion expanded={signExpanded} onChange={() => setSignExpanded(!signExpanded)}>
                  <AccordionSummary aria-controls="sign-content" id="sign-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"날인정보"}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CertifiedBox
                              gclient={selectedGClient}
                              control={control}
                              setValue={setValue}
                              getValues={getValues}
                              defaultValues={defaultValues}
                            />
                          </Grid>
                          {
                            sessionUserRole === 'ADMIN_ROLE' && (
                              <Grid item xs={12}>
                                <LoadingButton
                                  onClick={handleSubmit(onSubmit)}
                                  fullWidth
                                  variant="contained"
                                  loading={loading}
                                  // sx={{ mt: 3, mb: 2 }}
                                >
                                  {"수정하기"}
                                </LoadingButton>
                              </Grid>
                            )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* 보안 - 정보공개여부 */}
              { sessionUserRole === 'ADMIN_ROLE' && (<Grid item xs={12}><Security /></Grid>) }
              {/* 공통서류 - 모든 회원사에 반드시 있어야 하므로 Grid가 G04GeneralDocu 바깥에 있음 */}
              <Grid item xs={12}>
                <G04GeneralDocu
                  handleClickFileDownload={handleClickFileDownload}
                  handleClickViewEachDoc={handleClickViewEachDoc}
                  selectedGClient={selectedGClient}
                />
              </Grid>
              {/* 인증서 - 공통서류를 제외한 인증서~기타는 회원사에 따라 해당 문서가 없을 수도 있으므로 Grid가 컴포넌트 안쪽에 있음. 바깥에 있으면 Grid가 공간 차지 */}
              <G04docuGCertification
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 원자재 */}
              <G04docuGTestRawMaterial
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 가공부자재 */}
              <G04docuGTestSubMaterial
                division={'SUB_MATERIAL_PROCESS'}
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 시공부자재 */}
              <G04docuGTestSubMaterial
                division={'SUB_MATERIAL_BUILD'}
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 가공 */}
              <G04docuGTestProcess
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 기타 */}
              <G04docuEtc
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              <Grid item xs={12}>
                <LoadingButton
                  onClick={handleClickLeave}
                  fullWidth
                  variant="outlined"
                  loading={loading}
                >
                  {"탈퇴하기"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* 공통서류 첨부 & 인증서/성적서 첨부 다이얼로그 */}
        {/* <G04docuFileDialogGeneral
          openGeneral={openGeneral}
          setOpenGeneral={setOpenGeneral}
          selectedRow={selectedRow}
          selectedGClientId={selectedGClientId}
          selectedGClient={selectedGClient}
          refresh={selectAllG04GeneralDocus}
        /> */}
        {/* TODO : G04docuFileDialog, G04docuFileDialogEtc가
          각 서류를 다루는 컴포넌트(
            G04GeneralDocu,
            G04docuGCertification,
            G04docuGTestRawMaterial,
            G04docuGTestSubMaterial,
            G04docuGTestProcess,
            G04docuEtc
          ) 안으로 들어가면서 (removeFile도 마찬가지) refresh 함수가 각각에 맞게 단순화됨
         아래처럼 부모 컴포넌트에서 공통으로 사용할 때는 refresh 함수 설정을 타입에 맞게 분기하였었다.
         참고 또는 각 내부 컴포넌트 테스트를 위해 완료될 때까지 주석처리하여 남겨둘 것 */}
        {/* <G04docuFileDialog
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          selectedGClientId={selectedGClientId}
          selectedGClient={selectedGClient}
          selectedGComponentItemId={selectedGComponentItemId}
          selectedGComponentItemName={selectedGComponentItemName}
          selectedGComponentItemType={selectedGComponentItemType}
          selectedDocuFileType={selectedDocuFileType}
          refresh={
            selectedDocuFileType === 'CERTIFICATION' ?
              selectAllGCertifications :
              (
                selectedGComponentItemType === 'PROCESS' ?
                  selectAllProcesses :
                  selectAllGComponentItems
              )
          }
          from={'PROFILE'}
        />
        <G04docuFileDialogEtc
          open={openEtc}
          setOpen={setOpenEtc}
          selectedRow={selectedRowEtc}
          gclientId={selectedGClientId}
          gclient={selectedGClient}
          selectedGComponentItemType={selectedGComponentItemType}
          refresh={
            etcType === 'GENERAL' ?
              selectAllG04GeneralDocus :
              (
                etcType === 'CERTIFICATION' ?
                  selectAllGCertifications :
                  (
                    etcType === 'TEST' && selectedGComponentItemType === 'PROCESS' ?
                      selectAllProcesses :
                      (
                        etcType === 'ETC' ?
                          selectAllG04docuEtcs
                          : selectAllGComponentItems
                      )
                  )
              )
          } // TODO : 일반문서/인증서/성적서 종류에 따라 refresh 함수가 다름
        /> */}
      </Container>
      <PasswordChangeDialog
        open={openPasswordDialog}
        setOpen={setOpenPasswordDialog}
        mode={mode}
        setMode={setMode}
        // setCheckValue={setValue}
        type={type}
        // setType={setType}
        // credential={getValues("bizRegNumber")}
      />
      <EmailSettingDialog
        type={"GCLIENT"}
        open={openEmailAppPasswordDialog}
        setOpen={setOpenEmailAppPasswordDialog}
        credential={getValues("bizRegNumber")}
        email={getValues("email")}
      />
      <EmailSettingDialog
        type={"USER"}
        open={openEmailAppPasswordDialogUser}
        setOpen={setOpenEmailAppPasswordDialogUser}
        credential={getValues("userId")}
        email={getValues("userId")}
      />
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      {/* <ConfirmDialog
        removeId={removeFileObject}
        title={"삭제"}
        open={confirmOpenRemoveFile}
        setOpen={setConfirmOpenRemoveFile}
        onConfirm={removeFile}
        onCancel={() => {}}
      >
        {"첨부된 서류를 삭제하시겠습니까?"}
      </ConfirmDialog> */}
      <ConfirmDialog
        removeId={leaveObject}
        title={"탈퇴안내"}
        open={confirmOpenLeave}
        setOpen={setConfirmOpenLeave}
        onConfirm={leave}
        onCancel={() => {}}
      >
        {
          sessionUserLoginType === 'GCLIENT' && (<>
            <span style={{ color: '#1976d2' }}>{"탈퇴하시면 3개월 이내 재가입이 되지 않습니다."}</span><br /><br />
            <span>{"그래도 탈퇴하시겠습니까?"}</span>
          </>)
        }
        {
          sessionUserLoginType === 'USER' && (<>
            <span style={{ color: '#1976d2' }}>{"탈퇴하시면 사용하셨던 자료는 열람이 되지 않습니다."}</span><br />
            <span style={{ color: "#ff5722" }}>{"추후 동일한 이메일 아이디로 가입하셔도 열람이 되지 않습니다."}</span><br /><br />
            <span>{"그래도 탈퇴하시겠습니까?"}</span>
          </>)
        }
      </ConfirmDialog>
    </ThemeProvider>
  );
}

export default Profile;