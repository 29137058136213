import * as React from 'react';
import {
  // CellTemplate,
  // Cell,
  // Compatible,
  // Uncertain,
  // UncertainCompatible,
  // isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes,
  getCharFromKeyCode,
} from "@silevis/reactgrid";

class TextCell2Template extends React.Component {

  getCompatibleCell = (uncertainCell) => {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder = undefined;
    
    try {
        placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
        placeholder = '';
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells

    return { ...uncertainCell, text, value, placeholder };
  }

  update = (cell, cellToMerge) => {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text, placeholder: cellToMerge.placeholder });
  }

  handleKeyDown = (cell, keyCode, ctrl, shift, alt) => {
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE)) {
      return { cell: this.getCompatibleCell({ ...cell, text: shift ? char : char.toLowerCase() }), enableEditMode: true };
    }
        
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
  }

  getClassName = (cell, isInEditMode) => {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'invalid'} ${cell.placeholder && cell.text === '' ? 'placeholder' : ''} ${className}`;
  }
  
  render = (cell, isInEditMode, onCellChanged) => {
    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      const cellText = cell.text || cell.placeholder || '';
      const textToDisplay = !isValid && cell.errorMessage ? cell.errorMessage : cellText;
      return cell.renderer ? cell.renderer(textToDisplay) : textToDisplay;
    }

    return (
      <input
        ref={input => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={e => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)}
        onBlur={e => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), e.view?.event?.keyCode !== keyCodes.ESCAPE)}
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={e => {
          // console.log(e.key)
          // e.keyCode => deprecated. e.key 사용
          // cell type => text와 동일하고 편집 중 up, down 키의 경우 편집을 멈추고 셀 이동하는 부분만 다름. (TextCellTemplate 소스를 가져다가 typescript 부분을 javascript로 바꾸고 아래 코드만 살짝 수정. 다른 문제가 발생하는지는 향후 체크)
          // if (isAlphaNumericKey(e.keyCode) || (isNavigationKey(e.keyCode))) {
          // if (isAlphaNumericKey(e.keyCode) || e.keyCode === keyCodes.LEFT_ARROW || e.keyCode === keyCodes.RIGHT_ARROW) {
          if (isAlphaNumericKey(e.keyCode) || e.key === "ArrowLeft" || e.key === "ArrowRight") {
            e.stopPropagation();
          }
        }}
      />
    );
  }
}

export default TextCell2Template;