import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_G04_DOCU_GTEST_SUBSTITUTE = 'g04docu/initG04docuGTestSubstitute';
const INIT_G04_DOCU_GTEST_SUBSTITUTES = 'g04docu/initG04docuGTestSubstitutes';
const SET_G04_DOCU_GTEST_SUBSTITUTE = 'g04docu/setG04docuGTestSubstitute';
const REMOVE_G04_DOCU_GTEST_SUBSTITUTE = "g04docu/removeG04docuGTestSubstitute";
const SET_G04_DOCU_GTEST_SUBSTITUTES = 'g04docu/setG04docuGTestSubstitutes';

const initG04docuGTestSubstitute = () => ({
  type: INIT_G04_DOCU_GTEST_SUBSTITUTE,
});

const initG04docuGTestSubstitutes = () => ({
  type: INIT_G04_DOCU_GTEST_SUBSTITUTES,
});

const setG04docuGTestSubstitute = (g04docu) => ({
  type: SET_G04_DOCU_GTEST_SUBSTITUTE,
  payload: g04docu,
});

const removeG04docuGTestSubstitute = () => ({
  type: REMOVE_G04_DOCU_GTEST_SUBSTITUTE,
});

const setG04docuGTestSubstitutes = (g04docuGTestSubstitutes) => ({
  type: SET_G04_DOCU_GTEST_SUBSTITUTES,
  payload: g04docuGTestSubstitutes,
});

export const create = ({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications, supplierId, supplier, gprojectId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes`, {
    method: "POST",
    body: JSON.stringify({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications, supplierId, supplier, gprojectId }),
  });
  const data = await response.json();
  dispatch(setG04docuGTestSubstitute(data.g04docuSubstitute));
  return response;
};

export const createWithDoc = ({
  id,
  division,
  name,
  code,
  substituteInformation,
  gcomponentItemId,
  g04docuGCertificationId,
  gstandardTypeId,
  gclientId,
  gclient,
  supplierId,
  supplier,
  gprojectId,
  site,
  documentPath,
  documentType,
  startDate,
  endDate,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/withDoc`, {
    method: "POST",
    body: JSON.stringify({
      // G04docuGTestSubstitutes
      id,
      division,
      name,
      code,
      substituteInformation,
      gcomponentItemId,
      g04docuGCertificationId,
      gstandardTypeId,
      gclientId,
      gclient,
      supplierId,
      supplier,
      gprojectId,
      site,
      // G04docuGTestSubstituteMaps
      // gclientId,
      // g04docuGTestSubstituteId, // 위의 id
      // gcomponentItemId,
      // g04docuGCertificationId,
      documentPath,
      // gclient,
      documentType,
      startDate,
      endDate,
    }),
  });
  const data = await response.json();
  dispatch(setG04docuGTestSubstitute(data.g04docuSubstitute));
  return response;
};

export const modify = ({ id, division, name, code, substituteInformation, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, supplierId, supplier, gprojectId, site }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes`, {
    method: "PUT",
    body: JSON.stringify({ id, division, name, code, substituteInformation, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, supplierId, supplier, gprojectId, site }),
  });
  const data = await response.json();
  dispatch(setG04docuGTestSubstitute(data.g04docuSubstitute));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes`);
  const data = await response.json();
  dispatch(setG04docuGTestSubstitutes(data.g04docuGTestSubstitutes));
  return response;
}

export const selectAllByQuery = ({ gclientId, division, itemOrCertificationName, name, classifications }) => async dispatch => {
  console.log({ gclientId, division, name, classifications });
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/query/selectAll`, {
    method: "POST",
    body: JSON.stringify({ gclientId, division, itemOrCertificationName, name, classifications }),
  });

  const data = await response.json();
  dispatch(setG04docuGTestSubstitutes(data.g04docuGTestSubstitutes));
  return response;
}

export const selectByGClientAndDivisionAndGComponentItemByQueryByDirect = async (gclientId, division, gcomponentItemId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/query/selectAll/gclient/${gclientId}/division/${division}/gcomponentItem/${gcomponentItemId}`);
  const data = await response.json();
  // TODO : return값 response와 data 비교해 볼 것
  // return response;
  return data.g04docuGTestSubstitutes;
}

export const countByGClientAndDivisionAndGComponentItemByQueryByDirect = async (gclientId, division, gcomponentItemId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/query/count/gclient/${gclientId}/division/${division}/gcomponentItem/${gcomponentItemId}`);
  const data = await response.json();
  return data.count;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/${id}`);
  const data = await response.json();
  dispatch(setG04docuGTestSubstitute(data.g04docuSubstitute));
  return response;
}

export const initializeG04docuGTestSubstitutes = () => async dispatch => {
  dispatch(initG04docuGTestSubstitutes());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docuGTestSubstitute());
  return response;
};

export const removeWithFile = ({ id, gclientId, g04docuGTestSubstituteId, documentPath }) => async (dispatch) => {
  console.log(id, gclientId, g04docuGTestSubstituteId, documentPath);
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/${id}/withFile`, {
    method: "DELETE",
    body: JSON.stringify({ gclientId, g04docuGTestSubstituteId, documentPath }),
  });
  dispatch(removeG04docuGTestSubstitute());
  return response;
};

export const removeWithFileDirect = async ({ id, gclientId, g04docuGTestSubstituteId, documentPath }) => {
  console.log(id, gclientId, g04docuGTestSubstituteId, documentPath);
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/${id}/withFile`, {
    method: "DELETE",
    body: JSON.stringify({ gclientId, g04docuGTestSubstituteId, documentPath }),
  });
  const data = response.json();
  return data;
};

export const initializeG04docuGTestSubstitute = () => async (dispatch) => {
  dispatch(initG04docuGTestSubstitute());
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTestSubstitutes/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  g04docuGTestSubstitute: null,
  g04docuGTestSubstitutes: [],
};

// TODO : g04docu 수정해야 함
function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU_GTEST_SUBSTITUTE:
      newState = Object.assign({}, state, { g04docuGTestSubstitute: null });
      return newState;
    case INIT_G04_DOCU_GTEST_SUBSTITUTES:
      newState = Object.assign({}, state, { g04docuGTestSubstitutes: initialState.g04docuGTestSubstitutes });
      return newState;
    case SET_G04_DOCU_GTEST_SUBSTITUTE:
      newState = Object.assign({}, state, { g04docuGTestSubstitute: action.payload });
      return newState;
    case REMOVE_G04_DOCU_GTEST_SUBSTITUTE:
      newState = Object.assign({}, state, { g04docuGTestSubstitute: null });
      return newState;
    case SET_G04_DOCU_GTEST_SUBSTITUTES:
      newState = Object.assign({}, state, { g04docuGTestSubstitutes: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
