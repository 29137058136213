import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { ConfirmDialog } from "../../components/dialog";

import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";

import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";
import G04docuFileDialog from "../G04docuFile/G04docuFileDialog";
import TreeView from "./GTreeView";

const G04docuGTestProcess = ({
  handleClickFileDownload,
  handleClickViewEachDoc,
  selectedGClient,
}) => {

  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [processTreeData, setProcessTreeData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [selectedDocuFileType, setSelectedDocuFileType] = useState("");
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);
  const [etcType, setEtcType] = useState("");
  const [open, setOpen] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState(null);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");

  const dispatch = useDispatch();

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const sessionUserProcesses = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsProcess); // 가공 성적서

  const selectMyProcessesForTree = (gclientId, status) => dispatch(g04docuGCertificationActions.selectAllMyProcessesByStatusForTree(gclientId, status)) // 가공 성적서
  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const removeGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath }) => dispatch(gclientG04docuGTestMapActions.remove({ gclientId, g04docuGTestId, documentPath }))
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))

  useEffect(
    () => {
      selectMyProcessesForTree(sessionUser.id, 'all');
    }, [dispatch]
  )

  useEffect(
    async () => {
      const gtestsAllForTree = sessionUserProcesses.map(item => ({
          id: item.id,
          label: item.name,
          data: item,
          // type: 'ITEM',
          // children: item.tests?.sort((a, b) => {
          //   const { code : value1 } = a;
          //   const { code : value2 } = b;
          //   if (value1 < value2) return -1;
          //   if (value1 > value2) return 1;
          //   return 0;
          // }).map(item1 => ({
          //   id: `${item.id}/${item1.id}`,
          //   label: item1.name,
          //   data: item1,
          //   type: 'DOCTEST',
          // }))
          children: item.tests?.sort((a, b) => { // TODO : 정렬조건 체크해 볼 것
            const { code : value1 } = a;
            const { code : value2 } = b;
            if (value1 < value2) return -1;
            if (value1 > value2) return 1;
            return 0;
          }).map(item1 => ({
            id: `${item.id}/${item1.id}`,
            label: item1.name,
            data: item1,
            type: 'DOCTEST',
          }))
        }
      ));
      
      setProcessTreeData(gtestsAllForTree);
    }, [sessionUserProcesses]
  )

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    setMode(newMode);

    selectMyProcessesForTree(sessionUser.id, newMode);
  }

  const selectAllProcesses = (gclientId) => {
    selectMyProcessesForTree(gclientId, mode);
  }

  const removeFile = async () => {
    const { gclientId, g04docuGCertificationId, g04docuGTestId, g04docuEtcId, gcomponentItemId, division, documentPath, type } = removeFileObject;
    await removeG04docuFile(documentPath);

    if (type === 'TEST') {
      await removeGClientG04docuGTestMap({ gclientId, g04docuGTestId, documentPath })
      // if (selectedGComponentItemType === 'PROCESS') { // TODO : division과 동일한 것 같음. 추후 정리할 것
        selectAllProcesses(gclientId);
      // }
    } else if (type === 'ETC') {
      await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
      if (etcType === 'TEST' && selectedGComponentItemType === 'PROCESS') { // TODO : division과 동일한 것 같음. 추후 정리할 것
        selectAllProcesses(gclientId);
      }
    }
  }
  
  return (
    <>
      {
        (processTreeData.length > 0 || mode === 'nofile' || mode === 'invalid') && (
          <Grid item xs={12}>
            <G04docuFileDialog
              open={open}
              setOpen={setOpen}
              selectedRow={selectedRow}
              selectedGClientId={sessionUser.id}
              selectedGClient={selectedGClient}
              selectedGComponentItemId={selectedGComponentItemId}
              selectedGComponentItemName={selectedGComponentItemName}
              selectedGComponentItemType={selectedGComponentItemType}
              selectedDocuFileType={selectedDocuFileType}
              refresh={selectAllProcesses}
              from={'PROFILE'}
            />
            <G04docuFileDialogEtc
              open={openEtc}
              setOpen={setOpenEtc}
              selectedRow={selectedRowEtc}
              gclientId={sessionUser.id}
              gclient={selectedGClient}
              selectedGComponentItemType={selectedGComponentItemType}
              refresh={selectAllProcesses} // TODO : 일반문서/인증서/성적서 종류에 따라 refresh 함수가 다름
            />
            <ConfirmDialog
              removeId={removeFileObject}
              title={"삭제"}
              open={confirmOpenRemoveFile}
              setOpen={setConfirmOpenRemoveFile}
              onConfirm={removeFile}
              onCancel={() => {}}
            >
              <div>
                <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
                <br /><br />
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
                  { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
                </Box>
              </div>
            </ConfirmDialog>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="basic-content" id="basic-header">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6" component="div">
                      {"가공품 성적서 / 시험성과대비표"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography variant="subtitle2" component="div" /*color="primary" */sx={{ mr: 1 }} onClick={(e) => e.stopPropagation()}>{"보기"}</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={mode}
                      exclusive
                      size="small"
                      onChange={handleChangeMode}
                    >
                      <ToggleButton value="nofile">{"성적서 없음"}</ToggleButton>
                      <ToggleButton value="invalid">{"만료일 경과"}</ToggleButton>
                      <ToggleButton value="all">{"전체"}</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <TreeView
                    selected={selected}
                    onSelect={setSelected}
                    disableRoot
                    items={processTreeData}
                    handleClickFileDownload={handleClickFileDownload}
                    handleClickViewEachDoc={handleClickViewEachDoc}
                    setSelectedRow={setSelectedRow}
                    setSelectedRowEtc={setSelectedRowEtc}
                    setSelectedGComponentItemId={setSelectedGComponentItemId}
                    setSelectedGComponentItemName={setSelectedGComponentItemName}
                    setSelectedGComponentItemType={setSelectedGComponentItemType}
                    setSelectedDocuFileType={setSelectedDocuFileType}
                    setRemoveFileObject={setRemoveFileObject}
                    setConfirmOpenRemoveFile={setConfirmOpenRemoveFile}
                    setOpen={setOpen}
                    setOpenEtc={setOpenEtc}
                    setEtcType={setEtcType}
                  />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      }
    </>
  )
}

export default G04docuGTestProcess;