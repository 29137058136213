import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const ConfirmDialog = (props) => {
  // TODO : removeId를 removeTarget으로 명칭 변경하고 관련 부분 전부 수정할 것 => 삭제할 때만 쓰는 것이 아니므로 paramObject로 변경하는 것이 좋을 듯
  const { removeId, title, children, open, setOpen, onConfirm, onCancel, type } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          // variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm(removeId);
          }}
          // color="default"
        >
          {type === "ALERT" ? "확인" : "예"}
        </Button>
        {
          onCancel && (
              <Button
              // variant="contained"
              onClick={() => {
                onCancel(removeId);
                setOpen(false)
              }}
              color="secondary"
            >
              {"아니오"}
            </Button>
          )
        }
        
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;