import React, { useState, useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
} from '@mui/material';
import  {
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import {
  FormInputDropdown,
  FormInputMultipleSelect,
} from "../form";
import {
  AlertDialog,
} from "../dialog";
import { GlassUtils } from "../../utils";
// import * as gtypeDetailActions from "../../store/gtypeDetail";
// import { gridDensityValueSelector } from "@mui/x-data-grid-pro";

const GGlassComponent = ({
  gtypeDetailsWithGComponent,
  selectedGcomponentItems,
  setSelectedGcomponentItems,
  setSpecValue,
}) => {
  const dependentGcomponentMap = new Map();

  const defaultValuesForGcomponent = {};
  
  // Gcomponentitem 설정시 다른 item에 영향을 주는 동작을 위한 설정
  // 1. defaultVaues 초기화 (undefined일 경우 setValue로 값은 설정되나 selectbox에 적용안됨)
  // 2. "없음" 설정시 영향을 받는 item도 "없음"으로 설정하기 위한 map 구성. ("없음"은 item에 등록되어 있지 않으므로 연결고리를 찾을 수 없기 때문)
  const compositeComponent = (gcomponent, gtypeDetailsIndex) => {
    gcomponent.forEach((item, gcomponentItemIndex) => {
      if (item.level > gcomponent[0].level) {
        if (item.type === 'property') {
          defaultValuesForGcomponent[`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`] = "";
          // console.log(item)
          const properties = item.properties;
          // console.log(properties);
          if (properties.length > 0) {
            properties.forEach(property => {
              // console.log(property.dependentGcomponentItem);
              if (property.dependentGcomponentItem?.length > 0) {
                const dependentGcomponentItem = JSON.parse(property.dependentGcomponentItem);

                let targetGcomponentItemOrder = "";
                gcomponent.forEach((component, idx) => {
                  if (component.id === dependentGcomponentItem.gcomponentId) {
                    targetGcomponentItemOrder = idx;
                  }
                });
                
                dependentGcomponentMap.set(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`, {
                  id: dependentGcomponentItem.gcomponentId,
                  name: dependentGcomponentItem.name,
                  code: dependentGcomponentItem.code,
                  value: dependentGcomponentItem,
                  gtypeOrder: gtypeDetailsIndex,
                  gcomponentOrder: targetGcomponentItemOrder,
                });

                // console.log(dependentGcomponentMap)
              }
            })
          }
        }
      };
    });
  }

  const [arrGcomponentVisible, setArrGcomponentVisible] = useState([]);
  const [quotientValue, setQuotientValue] = useState(3);
  
  gtypeDetailsWithGComponent.forEach((gtypeDetail, index) => {
    compositeComponent(gtypeDetail, index);
  });

  useEffect(
    () => {
      let quotient = 3; // 1, 2, 3, 4, 6 가능
      const detailsCount = gtypeDetailsWithGComponent.length;
      if (detailsCount < 12/quotient) {
        quotient = detailsCount % quotient === 0 ? quotient : detailsCount % quotient;
      }
      setQuotientValue(quotient)
      
      const newArrGcomponentVisible = []
      gtypeDetailsWithGComponent.forEach((gtypeDetail, index) => {
        const row = parseInt(index/quotient)/*+1*/; // 문자열 let a = "1000" => a = parseInt(a); 와 a = +a; 동일
        if (newArrGcomponentVisible.filter(item => item.row === row).length === 0) { // 중복제거
          newArrGcomponentVisible.push({ row, visible: true });
        }
      });
      
      setArrGcomponentVisible(newArrGcomponentVisible);
    }, [gtypeDetailsWithGComponent]
  )

  // 아래 form components의 name과 연계
  const defaultValues = Object.assign({}, defaultValuesForGcomponent);
  // console.log(defaultValues);

  // const [loadedGcomponents, setLoadedGcomponents] = useState(true);
  const [alertInfo, setAlertInfo] = useState({});
  // const [multipleValue, setMultipleValue] = useState([]);

  // const [dependentGcomponentStateMap, setDependentGcomponentStateMap] = useState(new Map());
  
  const { reset, control, setValue } = useForm({ defaultValues: defaultValues });

  // const dispatch = useDispatch();

  // 데이터 관리
  // const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))

  const handleChangeMultiGcomponentItem = (value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/) => {
    // console.log({value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/});
    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code,
      value: value.length > 0 ? value.map(v => JSON.parse(v)) : [], // 모두 선택해제되면 []로 설정
    };

    const selectedNew = selectedGcomponentItems;
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;

    setSelectedGcomponentItems(selectedNew);
    
    setSpec(selectedNew);
  }

  const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent) => {
    // "없음" 선택한 경우 해당 구성요소가 의존하고 있는 구성요소가 있을 경우 안내메시지 띄우고 "없음" 선택안되도록 함
    // properties가 0("없음")인 경우 selectedGcomponentItems[gtypeDetailsOrder]에서 dependentGcomponentItem가 있는 것 중 gcomponentId가 item.id인 것이 있으면 안내 메시지 출력 
    // console.log(gcomponent);
    // console.log(item);
    // console.log(selectedGcomponentItems);
    const properties = item.properties.filter(property => property.id === e.target.value);
    // console.log(properties)
    
    if (properties.length <= 0) { // "없음"일 경우
      const gcomponentItems = selectedGcomponentItems[gtypeDetailsOrder];
      // console.log(gcomponentItems)
      const result = gcomponentItems.filter(gcomponentItem => {
        return gcomponentItem.value?.dependentGcomponentItem && JSON.parse(gcomponentItem.value?.dependentGcomponentItem).gcomponentId === item.id
      });
      // console.log(result)
      if (result.length > 0) {
        const { name, value } = result[0];
        const messageAlert = (
          <div>
            <span style={{ color: "#1976d2" }}>{`'${name}'`}</span>{`(이)가 '`}
            <span style={{ color: "#1976d2" }}>{`${value.name}`}</span>{`'(으)로 선택된 경우 `}
            <span style={{ color: "#1976d2" }}>{`'${item.name}'`}</span>{`(은)는 `}
            {/* <span style={{ color: "red" }}>{`'없음'`}</span>{`을 선택할 수 없고,`} */}
            <br/>
            <span style={{ color: "#d32f2f",  }}><i><u>{`반드시 값을 선택`}</u></i></span>
            {`해야 합니다.`}
          </div>
        );

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });

        return;
      }
    }

    let value = "";
    if (properties.length > 0) {
      value = properties[0];
    }

    setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value);
    // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);

    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code,
      value,
    };

    const selectedNew = selectedGcomponentItems;
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;

    // TODO : 해당 값이 변할 때 의존구성요소값이 있다면 그 구성요소의 값을 설정해야 한다. 값을 변화시킬 수 있는데 화면에 설정값으로 렌터링은 아직...
    if (properties.length > 0 && properties[0]?.dependentGcomponentItem?.length > 0) {
      const dependentGcomponentItem = JSON.parse(properties[0].dependentGcomponentItem);
      // console.log(dependentGcomponentItem)

      let targetGcomponentItemOrder = "";
      gcomponent.forEach((component, idx) => {
        if (component.id === dependentGcomponentItem.gcomponentId) {
          targetGcomponentItemOrder = idx;
        }
      });
      
      // console.log(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`);
      setValue(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`, `${dependentGcomponentItem.id}`);
      
      const dependentGcomponentItemNew = {
        id: dependentGcomponentItem.gcomponentId,
        name: dependentGcomponentItem.name,
        code: dependentGcomponentItem.code,
        value: dependentGcomponentItem,
      }

      selectedNew[gtypeDetailsOrder][targetGcomponentItemOrder] = dependentGcomponentItemNew;

      // TODO : 등록하기에서는 템플릿구조가 선택되지 않아 map 구성이 안되어 있으므로 여기서 구성 필요
      dependentGcomponentMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, {
        ...dependentGcomponentItemNew,
        gtypeOrder: gtypeDetailsOrder,
        gcomponentOrder: targetGcomponentItemOrder,
      });

      // console.log(dependentGcomponentMap);
    } else {
      const map = dependentGcomponentMap.get(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
      if (map) {
        setValue(`${map.gtypeOrder}_${map.gcomponentOrder}_${map.id}`, "");
        selectedNew[map.gtypeOrder][map.gcomponentOrder] = {
          id: map.id,
          code: map.code,
          name: map.name,
          value: "",
        };
      }
    }
   
    setSelectedGcomponentItems(selectedNew);

    // TODO : 아래처럼 할지 useEffect 쓸지 추후 검토
    setSpec(selectedNew);
  }

  const getVisible = (row) => {
    const result = arrGcomponentVisible.filter(item => item.row === row);
    if (result.length > 0) {
      if (result[0].visible) {
        return "px";
      }
    }

    return "0px";
  }

  const handleClickCardHeader = (row) => {
    const newArrGcomponentVisible = arrGcomponentVisible.map(item => {
      const newItem = Object.assign({}, item);
      if (newItem.row === row) {
        newItem.visible = !newItem.visible;
      }
      return newItem;
    });

    setArrGcomponentVisible(newArrGcomponentVisible);
  }

  const getIcon = (row) => {
    let icon = (<ExpandMore />);
    const result = arrGcomponentVisible.filter(item=> item.row === row)
    if (result.length > 0) {
      if (result[0]?.visible) {
        icon = (<ExpandLess />);
      }
    }
    return icon;
  }

  const generateGTypeDetailWithComponent = () => {
    if (gtypeDetailsWithGComponent && gtypeDetailsWithGComponent.length && gtypeDetailsWithGComponent.length > 0) {
      // 한 행에 gcomponent를 몇개 위치시킬 것인지 정한다. 현재는 3열
      // let quotient = 3; // 1, 2, 3, 4, 6 가능
      // const detailsCount = gtypeDetailsWithGComponent.length;
      // if (detailsCount < 12/quotient) {
      //   quotient = detailsCount % quotient === 0 ? quotient : detailsCount % quotient;
      // }

      return (
        <Grid
          container={true}
          spacing={2}
        >
           {
             gtypeDetailsWithGComponent.map((gtypeDetail, index) => {
              console.log(arrGcomponentVisible);
              const row = parseInt(index/quotientValue);
              return <Grid item xs={12} sm={12/quotientValue}>
                <Card>
                  <CardHeader
                    sx={{ bgcolor: "#1976d2", color: "white", /*height: '40px'*/ }}
                    align="center"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    title={gtypeDetail[0].name}
                    action={
                      <IconButton aria-label="settings" onClick={() => handleClickCardHeader(row)}>
                        { getIcon(row) }
                      </IconButton>
                    }
                  />
                  {/* <CardContent> */}
                  {
                    <Box sx={{ height: getVisible(row)/*'0px'*//*'px'*/ }}>
                      <Grid container sx={{ pt: 2, pb:2 }}>
                        {/* {parseInt(index/quotient)+1} */}
                        {
                          generateComponent(gtypeDetail, index)
                        }
                      </Grid>
                    </Box>
                  }
                  {/* </CardContent> */}
                </Card>
              </Grid>
             })
           }
        </Grid>
      )
    }
  }
  
  const getMulitpleValue = (item, selectedGcomponentItems, gtypeDetailsIndex, gcomponentItemIndex) => {
    return item.properties.filter(property => selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex].value.map(component => component.code).includes(property.code))
  }

  // TODO : 그룹안에 그룹은 현재 없는 상태이므로 아래 코드는 문제가 없지만 그렇지 않을 경우 동작 여부 검토 필요
  const generateComponent = (gcomponent, gtypeDetailsIndex) => {
    const component = gcomponent.map((item, gcomponentItemIndex) => {
      if (item.level > gcomponent[0].level) {
        const indent = item.level * 2;
        if (item.type === 'group') {
          return (
            <Grid
              item xs={12}
              sx={{ ml: indent, mt: 1, mb: 1, mr: 2, p: 1, bgcolor: "#03a9f4", color: "white", borderRadius: "5px" }}
              align="center"
            >
              {item.name}
            </Grid>
          )
        } else if (item.type === 'property') {
          // console.log(item)
          return item.multipleYN ? (
            <Grid item xs={12} sx={{ ml: indent, mt: 1, mb: 1, mr: 2 }}>
              {/* {"다중선택 select로 바꿀 것"} */}
              {/* <FormInputDropdown
                name={`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`}
                control={control}
                label={item.name}
                onChange={(e) => handleChangeMultiGcomponentItem(e, item, gtypeDetailsIndex, gcomponentItemIndex, gcomponent)}
                options={
                  [{ label: '없음', value: "" }].concat(item.properties.map(property => {
                    return {
                      label: property.name,
                      value: property.id,
                    }
                  }))
                }
              /> */}
              <FormInputMultipleSelect
                id="multiple-type-select"
                name={`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`}
                control={control}
                label={item.name}
                setValue={setValue}
                onChangeItem={(value) => handleChangeMultiGcomponentItem(value, item, gtypeDetailsIndex, gcomponentItemIndex, gcomponent)}
                // value={...}
                // setMultiselectValue={...}
                dynamicItems={{ selectedGcomponentItems, gtypeDetailsIndex, gcomponentItemIndex, gcomponentItem: item }} // selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex] 넘기면 값이 고정되므로 안됨
                options={item.properties}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ ml: indent, mt: 1, mb: 1, mr: 2 }}>
              <FormInputDropdown
                name={`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`}
                control={control}
                label={item.name}
                // onChange={(e) => handleChangeGcomponentItem(e, item, gtypeDetailsIndex, gcomponentItemIndex, gcomponent)}
                onChangeSelectValue={(e) => handleChangeGcomponentItem(e, item, gtypeDetailsIndex, gcomponentItemIndex, gcomponent)}
                options={
                  [{ label: '없음', value: "" }].concat(item.properties.map(property => {
                    return {
                      label: property.name,
                      value: property.id,
                    }
                  }))
                }
                // value={dependentGcomponentStateMap.get(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`)}
                // value={getGcomponentValue(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`)}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                // defaultValue={""}
              />
            </Grid>
          )
        }
      }
    });
    return component;
  }

  // const getGcomponentValue = (name) => {
  //   let value;
  //   value = getValues(name)
  //   console.log(dependentGcomponentStateMap)
  //   // value = dependentGcomponentStateMap.get(name);
  //   console.log(value)
  //   // if (value) {
  //     return value;
  //   // }
  //   // return getValues(name);
  // }

  const setGcomponentItems = () => {
    // alert("123")
    // 설정한 값들을 select 박스에서 선택
    selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
      gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
        if (Array.isArray(gcomponentItem.value)) { // 다중선택일 경우. TODO : 현재 value가 배열인 경우가 다중선택일 경우인데 속성값으로 판단이 아니라 배열여부이므로 추후 재검토할 것
          // setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value);
          // setMultipleValue(gcomponentItem.value);
          console.log(gcomponentItem.value);
        } else {
          setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value.id);
        }
        
      });
    });
  }

  // useEffect(
  //   () => {
  //     // alert("123")
  //     selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
  //       gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
  //         dependentGcomponentStateMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value.id);
  //       });
  //     });
  //     console.log(dependentGcomponentStateMap)
  //     setDependentGcomponentStateMap(dependentGcomponentStateMap);
  //   }, [selectedGcomponentItems]
  // )

  const setSpec = (selectedNew) => {
    // let specification = "";
    // let otherSpecs = "";

    // console.log(selectedGcomponentItems);
    // console.log(selectedNew);

    // const selected = selectedGcomponentItems || selectedNew;
    // // console.log(selected)
    // selected && selected.forEach(gtypeDetails => {
    //   let productName = "";
    //   let otherSpec = "";
    //   gtypeDetails.forEach(gcomponentItem => {
    //     if (Array.isArray(gcomponentItem.value)) {
    //       gcomponentItem.value.forEach(item => {
    //         const { applyType, code } = item;
    //         if (applyType === 'productName') {
    //           // productName += code ? `${code} ` : "";
    //           productName += code ? `${code}|` : "";
    //         } else if (applyType === 'etc') {
    //           // otherSpec += code ? `${code} ` : "";
    //           otherSpec += code ? `${code}|` : "";
    //         }
    //       })
    //     } else {
    //       const { applyType, code } = gcomponentItem.value;
    //       if (applyType === 'productName') {
    //         // productName += code ? `${code} ` : "";
    //         productName += code ? `${code}|` : "";
    //       } else if (applyType === 'etc') {
    //         // otherSpecs += gcomponentItem.value.code ? `${gcomponentItem.value.code}, ` : "";
    //         // otherSpec += code ? `${code} ` : "";
    //         otherSpec += code ? `${code}|` : "";
    //       }
    //     }
    //   });
    //   productName = productName.substring(0, productName.length - 1);
    //   // specification += (productName !== "" ? `${productName} + ` : "");
    //   specification += (productName !== "" ? `${productName}+` : "");

    //   otherSpec = otherSpec.substring(0, otherSpec.length - 1);
    //   // otherSpecs += (otherSpec !== "" ? `${otherSpec} + ` : "");
    //   otherSpecs += (otherSpec !== "" ? `${otherSpec}+` : "");
    // });
    
    // // setSpecValue("specification", specification.substring(0, specification.length - 3));
    // // setSpecValue("otherSpecs", otherSpecs.substring(0, otherSpecs.length - 2));
    // setSpecValue("specification", specification.substring(0, specification.length - 1));
    // setSpecValue("specificationDisplay", specification.substring(0, specification.length - 1).replaceAll("|", " ").replaceAll("+", " + "));
    // setSpecValue("otherSpecs", otherSpecs.substring(0, otherSpecs.length - 1));
    // setSpecValue("otherSpecsDisplay", otherSpecs.substring(0, otherSpecs.length - 1).replaceAll("|", " ").replaceAll("+", " + "));

    const selected = selectedGcomponentItems || selectedNew;
    const [specification, otherSpecs] = GlassUtils.getSpecification(selected);
    // console.log({ specification, otherSpecs });
    setSpecValue("specification", specification);
    setSpecValue("specificationDisplay", specification.replaceAll("|", " ").replaceAll("+", " + "));
    setSpecValue("otherSpecs", otherSpecs);
    setSpecValue("otherSpecsDisplay", otherSpecs.replaceAll("|", " ").replaceAll("+", " + "));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* TODO : 아래와 같이 두 함수를 호춣시 위 함수실행이 완료된 후 아래 함수가 실행되는지(sync) 확인 필요 */}
        { generateGTypeDetailWithComponent() }
        { setGcomponentItems() }
        { setSpec() }
      </Grid>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </Grid>
  );
};

export default GGlassComponent;
