import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  LinearProgress,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  StringAvatar,
} from "../avatar";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gclientTypeActions from "../../store/gclientType";
import GClientTypeDialog from "./GClientTypeDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const GClientTypeManagement = ({ title }) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [modify, setModify] = useState(false);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [hexColor, setHexColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)

    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;
    
    if (type === "detail") {
      await select(id);
      // setModify(true);
      setCrudMode('R');
      setOpen(true);
    } if (type === "edit") {
      await select(id);
      // setModify(true);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAll();
    setTimeout(() => setLoaded(true), 300)

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gclientTypeActions.remove(removeId))
    .then(res => selectAll())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    setOpen(true);
    // setModify(false);
    setCrudMode('C');
    initGClientType(); // 다이얼로그 초기화
    // ["id", "name", "code", "comments"].forEach(item => setValue(item, ""))
    // setHexColor("");
    // setShowColorPicker(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleChangeToggle = async ({ params, e }) => {
    e.stopPropagation();

    await modifyGClientTypeProjectYN({ id: params.id, projectYN: e.target.checked })

    setLoaded(false);
    await selectAll();
    setTimeout(() => setLoaded(true), 300);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
      renderCell: (params) => {
        // const name = params.row?.name;
        const displayType = params.row?.displayType;
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {params.row?.name}
            <StringAvatar
              // name={name === "시공팀" ? name.substring(name.length-1) : name.substring(0, 1)}
              name={displayType}
              width={20}
              height={20}
              fontSize={"small"}
              color={params.row.color}
            />
          </Box>
        )
      }
    },
    {
      field: 'code',
      headerName: '코드',
      width: 300,
      // editable: true,
      hide: true,
    },
    {
      field: 'projectYN',
      headerName: '프로젝트 관렵업체 여부',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params) => {
        let projectYN = params.row.projectYN;
        return (
          <Switch
            // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
            // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
            // TODO : 추후 useState([]) 사용하는 방법 강구
            // defaultChecked={projectYN}
            checked={projectYN}
            onChange={(e) => handleChangeToggle({ params, e })}
          />
        )
      },
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
      // renderCell: (params) => {
      //   console.log(params)
      //   return (
      //     <div>
      //       <IconButton
      //         aria-label="more"
      //         id="long-button"
      //         aria-controls={openFunctionMenu ? 'long-menu' : undefined}
      //         aria-expanded={openFunctionMenu ? 'true' : undefined}
      //         aria-haspopup="true"
      //         onClick={handleClick}
      //       >
      //         <MoreVert />
      //       </IconButton>
      //       <Menu
      //         id="long-menu"
      //         MenuListProps={{
      //           'aria-labelledby': 'long-button',
      //         }}
      //         anchorEl={anchorEl}
      //         open={openFunctionMenu}
      //         onClose={handleClose}
      //         PaperProps={{
      //           style: {
      //             maxHeight: ITEM_HEIGHT * 4.5,
      //             width: '20ch',
      //           },
      //         }}
      //       >
      //         {options.map((option) => (
      //           <MenuItem key={option.type} onClick={() => handleSelect({ type: option.type, id: params.id })}>
      //             <ListItemIcon>{option.icon}</ListItemIcon>
      //             <ListItemText>{option.text}</ListItemText>
      //           </MenuItem>
      //         ))}
      //       </Menu>
      //     </div>
      //   );
      // },
    },
  ];

  const dispatch = useDispatch();
  const rows = useSelector((state) => state.gclientType.gclientTypes);
  const selectedRow = useSelector((state) => state.gclientType.gclientType);
  
  // 데이터 관리
  const selectAll = () => dispatch(gclientTypeActions.selectAll())
  const select = (id) => dispatch(gclientTypeActions.select(id))
  const modifyGClientTypeProjectYN = ({ id, projectYN }) => dispatch(gclientTypeActions.modifyProjectYN({ id, projectYN }))
  const initGClientType = () => dispatch(gclientTypeActions.initGClientType())
  const reorder = ({ type, id }) => gclientTypeActions.reorder({ type, id })

  useEffect(
    async () => {
      await selectAll();

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GClientTypeDialog
          // modify={modify}
          crudMode={crudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAll}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <Skeleton variant="rounded" height={show ? 0 : 800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GClientTypeManagement;
