import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  Button,
  Grid,
} from '@mui/material';
import  {
  ContactMail,
  ContactPhone,
  Logout,
  Undo,
} from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
  ConfirmDialog,
} from "../../components/dialog";
import * as errorActions from "../../store/error";
import * as sessionActions from "../../store/session";
import * as menuActions from '../../store/menu';

const cellStyle = {
  p: 3,
  borderColor: '#dcdcdc',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
};

const theme = createTheme({
  // palette: {
  //   hoverColor: "white",
  // }
  hoverColor: "#0d47a1",
});

const StyledButton = styled((props) => (
  <Button {...props}/>
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.hoverColor,
  },
}));

const ErrorPage = ({ error }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [emailContent, setEmailContent] = useState({});
  // const sessionUser = useSelector(state => state.session.user);

  // const logout = async () => {
  //   await dispatch(errorActions.occurError({ error: null }));
  //   await dispatch(sessionActions.logout());
  //   await dispatch(menuActions.removeAllTabMenus());

  //   navigate("/signin", { state: { from: location.pathname }});
  // }

  const handleClickSendErrorMail = () => {
    setConfirmOpen(true);
  }

  const send = (content) => {
    // alert("aaa")
  }

  const { response, serverResponse } = error;
  if (response) {
    const { status, statusText } = response;

    if (status) {
      // if ([401, 403].includes(status)) {
      //   console.log(sessionUser)
      //   // dispatch(sessionActions.logout()); // 실제로 만료되어 로그아웃된 상태이나 상단 메뉴를 로그아웃 상태로 하기 위하여 호출
      // }
      
      return (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Grid
              container
              spacing={0}
              display="flex"
              // direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '90vh' }}
            >
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    title={"시스템 오류"}
                    sx={{ bgcolor: "#42a5f5", color: '#ffebee', fontWeight: 'bold' }}
                  />
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent={"center"}
                      alignItems="center"
                      sx={{ color: 'red' }}
                    >
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{ color: '#42a5f5', fontSize: '1.2rem' }}>
                        <span>{"같은 오류가 반복하여 발생하면 기술지원팀에 문의바랍니다."}</span>
                        <ContactPhone sx={{ ml: 1, mr: 1 }} /><span>{"02-2131-6562"}</span>
                      </Box>
                    </Box>  
                    <Grid container spacing={0} sx={{ mt: 3, mb: 3 }} display="flex" justifyContent="center">
                      <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={cellStyle}>
                        {"상태 코드"}
                      </Grid>
                      <Grid item xs={12} sm={9} display="flex" justifyContent="center" sx={cellStyle}>
                        {status}
                      </Grid>
                      <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={cellStyle}>
                        {"상태"}
                      </Grid>
                      <Grid item xs={12} sm={9} display="flex" justifyContent="center" sx={cellStyle}>
                        {statusText}
                      </Grid>
                      <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ p: 3 }}>
                        {"상세 메시지"}
                      </Grid>
                      <Grid item xs={12} sm={9} display="flex" justifyContent="center" sx={{ p: 3 }}>
                        {/* {JSON.stringify(serverResponse, null, 2)} */}
                        {serverResponse?.message}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {/* TODO : 추후 오류 메일 보내기 기능 추가 필요 */}
                <Grid container sx={{ mt: 5 }}>
                {
                  [401, 403].includes(status) ? (
                    <Grid item xs={12} display="flex" justifyContent="center">
                      {/* 서버에서 session이 없어진 상태이나 redux에는 남아 있음. 아래처럼 "/" 이동하면 App에서의 dispatch useEffect에서 restoreUser or restoreSessionUser를 통해 다시 조회하여 signin로 이동하는 방식 */}
                      <Button variant="contained" href={"/"} startIcon={<Logout />} sx={{ bgcolor: "#42a5f5" }}>{"로그아웃"}</Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <StyledButton variant="contained" href={`${location.pathname}${location.search}`} startIcon={<Undo />} sx={{ bgcolor: "#42a5f5" }}>{"되돌아가기"}</StyledButton>
                      {/* <StyledButton variant="contained" startIcon={<ContactMail />} sx={{ bgcolor: "#f44336", ml: 1 }} onClick={handleClickSendErrorMail}>{"오류알리기"}</StyledButton> */}
                    </Grid>
                  )
                }
                </Grid>
              </Grid>
            </Grid>
            <ConfirmDialog
              removeId={emailContent}
              title={"안내"}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={send}
              onCancel={() => {}}
            >
              {"서비스 준비중입니다."}
              {/* {"관리자에게 오류내용을 보내시겠습니까?"} */}
            </ConfirmDialog>
          </Container>
        </ThemeProvider>
      );
    }
  }

  return <></>;
}

export default ErrorPage;