import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import "./GPlan.css";
import {
  dateFormat,
  getTextColorByBackgroundColor,
} from "../../utils";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";

import * as gworkOrderActions from "../../store/gworkOrder";
import * as gworkOrderProcessActions from "../../store/gworkOrderProcess";
import * as gplanActions from "../../store/gplan";

const GPlanWorkOrderDialog = ({
  open,
  setOpen,
  show,
  loaded,
  refresh,
}) => {
  const [selectedGWorkOrderId, setSelectedGWorkOrderId] = useState({});
  const [pageSize, setPageSize] = useState(100);

  const gworkOrders = useSelector((state) => state.gworkOrder.gworkOrders);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectGWorkOrdersByStatus = (status) => dispatch(gworkOrderActions.selectAllByStatus(status))
  const selectGWorkOrderProcessesDirect = (gworkOrderId) => gworkOrderProcessActions.selectGWorkOrderProcessesByQueryDirect(gworkOrderId)
  const selectGWorkOrderProcessDetailsDirect = (gworkOrderId) => gworkOrderProcessActions.selectGWorkOrderProcessDetailsByQueryDirect(gworkOrderId)
  
  const createPlansDirect = (gplans) => gplanActions.createPlansDirect(gplans)
  // useEffect(
  //   async () => {
  //   }, [dispatch]
  // )

  // useEffect(
  //   async () => {
  //     await selectAllByStatus('PRE'); // 미할당 작업의뢰 조회
      
  //     await hideWatermark();
  //     // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
  //     setShow(true);
  //     setTimeout(() => setLoaded(true), 3000);
  //   }, [open]
  // )

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 120,
      hide: true,
    },
    {
      field: 'workOrderNo',
      headerName: '의뢰번호',
      width: 320,
      valueGetter: (params) => `${params.row.workOrderNo}-${params.row.workOrderNoSuffix}`,
    },
    {
      field: 'deliveryDate',
      headerName: '납기일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'no',
      headerName: '품번',
      width: 120,
    },
    {
      field: 'standard',
      headerName: '규격',
      width: 250,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  const handleDialogClose = () => {
    setOpen(false);
  }

  const handleSelect = async () => {
    // const gworkOrderProcesses = await selectGWorkOrderProcessesDirect(selectedGWorkOrderId);
    const gworkOrderProcesses = await selectGWorkOrderProcessDetailsDirect(selectedGWorkOrderId);
    console.log(gworkOrderProcesses);

    const gplans = gworkOrderProcesses.map((process, idx) => {
      const id = uuidv4();
      const title = `${process.workOrderNo}-${idx+1}-${process.workOrderNoSuffix}`;
      return {
        id,
        gworkOrderId: process.gworkOrderId,
        gworkOrderProcessId: process.id,
        name: title,
        start: process.deliveryDate,
        // end,
        scheduleDetail: {
          allDay: true,
          // allow,
          backgroundColor: process.gprocessColor,
          borderColor: process.gprocessColor,
          // classNames,
          // constraint,
          // display,
          // durationEditable,
          // end,
          // endStr,
          extendedProps: process,
          groupId: process.gworkOrderId,
          id,
          // overlap,
          // source,
          start: process.deliveryDate,
          // startEditable,
          // startStr,
          textColor: `${getTextColorByBackgroundColor(process.gprocessColor)}`,
          title,
          // url,
        },
        // comments,
      }
    })

    // console.log(gplans)
    // return;
    const createdGPlans = await createPlansDirect(gplans); // redux에 반영시 기존 캘린더에 덮어쓴다. 따라서 Direct로 호출하고 refresh 했음
    if (createdGPlans.length > 0) {
      refresh();
    }

    handleDialogClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      // 아래 두 요소(fullWidth, maxWidth)를 다 설정해야 다이얼로그 크기가 반영됨
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {"미할당 작업의뢰목록"}
      </DialogTitleClose>
      <DialogContent dividers>
        <Grid container>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              // 아래 코드가 있어야 로딩이 바 형태로 생김
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={gworkOrders}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowClick={(params) => {
                setSelectedGWorkOrderId(params.id);
              }}
            />
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSelect}>{"선택"}</Button>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GPlanWorkOrderDialog;
