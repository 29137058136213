// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config.js';

const SET_USER = 'user/setUser';
const REMOVE_USER = "user/removeUser";
const SET_USERS = 'user/setUsers';

const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

const removeUser = () => ({
  type: REMOVE_USER,
});

const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});

export const create = ({ id, userId, name, mobile, gclientId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users`, {
    method: "POST",
    body: JSON.stringify({ id, userId, name, mobile, gclientId }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

export const modify = ({ id, userId, name, mobile, gclientId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users`, {
    method: "PUT",
    body: JSON.stringify({ id, userId, name, mobile, gclientId }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

export const modifyStatus = ({ id, status }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/${id}/status`, {
    method: "PUT",
    body: JSON.stringify({ status }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

export const modifyRole = ({ id, role}) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/${id}/role`, {
    method: "PUT",
    body: JSON.stringify({ role }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

// export const selectAll = () => async dispatch => {
//   const response = await csrfFetch(dispatch, `${authServerUrl}/api/users`);
//   const data = await response.json();
//   dispatch(setUsers(data.users));
//   return response;
// }

// export const selectAllDirect = async () => {
//   const response = await csrfFetch(null, `${authServerUrl}/api/users`);
//   const data = await response.json();
//   return data.users;
// }

export const selectAllByGClient = (gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/gclient/${gclientId}`);
  const data = await response.json();
  dispatch(setUsers(data.users));
  return response;
}

export const selectAllByGClientDirect = async (gclientId) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/users/gclient/${gclientId}`);
  const data = await response.json();
  return data.users;
}

export const selectAllByGClientByQueryDirect = async (gclientId) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/users/query/selectAll/gclient/${gclientId}`);
  const data = await response.json();
  return data.users;
}

// export const selectAllByIds = (ids) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/select/ids`, {
//     method: "POST",
//     body: JSON.stringify({ ids }),
//   });
//   const data = await response.json();
//   dispatch(setUsers(data.users));
//   return response;
// }

// export const select = (id) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/${id}`);
//   const data = await response.json();
//   dispatch(setUser(data.user));
//   return response;
// }

export const init = () => dispatch => {
  dispatch(setUser(null));
}

// export const selectByTypeByQuery = (type) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/query/selectByType/${type}`);
//   const data = await response.json();
//   dispatch(setUsers(data.users));
//   return response;
// }

export const removeByQueryDirect = async ({ userId, gclientId, belongs }) => {
    const response = await csrfFetch(null, `${authServerUrl}/api/users/query/delete/${userId}`, {
      method: "DELETE",
      body: JSON.stringify({ gclientId, belongs }),
    });
    
    return response;
}

export const remove = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/${id}`, {
    method: "DELETE",
  });
  dispatch(removeUser());
  return response;
};

// export const reorder = async ({ type, id }) => {
//   const response = await csrfFetch(null, `${authServerUrl}/api/users/reorder`, {
//     method: "POST",
//     body: JSON.stringify({ type, id }),
//   });
  
//   return response;
// }

const initialState = {
  user: null,
  users: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_USER:
      newState = Object.assign({}, state, { user: action.payload });
      return newState;
    case REMOVE_USER:
      newState = Object.assign({}, state, { user: null });
      return newState;
    case SET_USERS:
      newState = Object.assign({}, state, { users: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
