import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR, GridCellModes } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Save,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gglassActions from "../../store/gglass";
import * as gdomesticAuthActions from "../../store/gdomesticAuth";
import * as alertDialogActions from "../../store/components/alertDialog";

import GDomesticAuthAlertDialog from "./GDomesticAuthAlertDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const GDomesticAuthManagement = () => {
  const [loaded, setLoaded] = useState(false);
  const [loadedGGlass, setLoadedGGlass] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rowsGDomesticAuth, setRowsGDomesticAuth] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const [gglassId, setGGlassId] = useState("");

  // 저장/수정 다이얼로그
  const handleClickSave = async () => {
    // console.log(gglassId)
    // console.log(rowsGDomesticAuth)

    const gdomesticAuths = rowsGDomesticAuth.map(row => {
      const { id, comments, actions } = row;
      let arrTypes = [];
      for (const [item, value] of Object.entries(row)) {
        if (item.indexOf("glass") >= 0 || item.indexOf("film") >= 0)
        arrTypes.push(value);
      }
      return {
        id,
        gglassId,
        types: arrTypes.join(","),
        comments,
        authYN: actions === 'O' ? 'Y' : 'N',
        // authName: actions === 'O' ? '국내산 인증' : '국내산 미인증',
      }

    });

    console.log(gdomesticAuths)
    setLoaded(false);
    await saveGDomesticAuths({ gglassId, gdomesticAuths });

    const selectedGGlass = rowsGGlass.filter(row => row.id === gglassId);
    const messageAlert = (<>
      <span style={{ color: "#1976d2" }}>{"국내산 인증 정보"}</span>{"가 저장되었습니다."}
      <br /><br />
      {
        selectedGGlass.length === 1 && (
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
            { `품종 : ${selectedGGlass[0].name}` }
          </Box>
        )
      }
    </>);
    
    dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));

    setTimeout(() => setLoaded(true), 300);
  };

  const columnsGlass = [
    {
      field: 'name',
      headerName: '품종',
      width: 200,
    },
  ];

  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const rows = useSelector((state) => state.gdomesticAuth.gdomesticAuths);
  const selectedRow = useSelector((state) => state.gdomesticAuth.gdomesticAuth);
  const rowsGGlass = useSelector((state) => state.gglass.gglasses);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllByQuery = () => dispatch(gglassActions.selectAllByQuery())
  const selectByGGlassIdDirect = (gglassId) => gdomesticAuthActions.selectByGGlassIdDirect(gglassId)
  const saveGDomesticAuths = ({ gglassId, gdomesticAuths }) => dispatch(gdomesticAuthActions.bulkCreate({ gglassId, gdomesticAuths }))

  useEffect(
    async () => {
      await selectAllByQuery();
      
      setTimeout(() => {
        setLoaded(true);
        setLoadedGGlass(true);
      }, 300);
    }, [dispatch]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const generateOrSelectGDomesticAuthInfo = async (selRows) => {
    const additionalColum = [
      {
        field: 'comments',
        headerName: '설명',
        width: 120,
        editable: true,
      },
      {
        field: 'actions',
        headerName: '국내산 인증',
        width: 120,
        editable: true,
        type: 'singleSelect',
        headerAlign: 'center',
        align: 'center',
        valueOptions: ['O', 'X'],
      }
    ];
    
    const selectedGGlassId = selRows[0].id;
    const gdomesticAuths = await selectByGGlassIdDirect(selectedGGlassId);
    console.log(gdomesticAuths)
    
    if (gdomesticAuths.length > 0) {
      const columnsItem = [];
      const newGDomesticAuthInfo = gdomesticAuths.map((gdomesticAuths, i) => {
        const { id, types, comments, authYN } = gdomesticAuths;
        const value = {
          id,
          comments,
          actions: authYN === 'Y' ? 'O' : 'X',
        };

        const newTypes = types.split(",");
        let type = "";
        let headerName = "";
        console.log(newTypes)
        newTypes.forEach((item, j) => {
          if (item === '접합') {
            type = 'film';
            headerName = "접합";
          } else {
            type = 'glass';
            headerName = "원판";
          }

          value[`${type}${j}`] = item;

          if (i === 0) {
            columnsItem.push({
              field: `${type}${j}`,
              headerName,
              width: 150,
              headerAlign: 'center',
              align: 'center',
            });
          }
        })

        return value;
      });

      columnsItem.push(...additionalColum);
      console.log(columnsItem);
      setColumns(columnsItem);

      // authName: "국내산 인증"
      // authYN: "Y"
      // comments: "1"
      // gglassId: "76f3818b-f673-4168-9e11-17a701d19931"
      // id: "86b65506-f550-4aa2-a554-bbaf0d5a3e99"
      // types: "국내산"
      console.log(newGDomesticAuthInfo)
      setRowsGDomesticAuth(newGDomesticAuthInfo);

      return;
    }
    
    console.log(selRows)
    const { selectedGcomponentItems } = selRows[0];
    const items = [];
    const columnsItem = [];
    
    let colIdx = 0;
    selectedGcomponentItems.forEach((gtypeDetails, i) => {
      gtypeDetails.forEach((item, j) => {
        if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
          return;
        }
        
        const { code } = item;
        if (code === 'GLASS PRODUCT NAME') { // <<<주의 : 코드 하드코딩 사용>>>
          items.push(['국내산', '국내산(로이)', '수입산', '수입산(로이)']);
          columnsItem.push({
            field: `glass${colIdx}`,
            headerName: '원판',
            width: 150,
            headerAlign: 'center',
            align: 'center',
          });

          colIdx = colIdx + 1;
        } else if (code === 'FILM TYPE') { // <<<주의 : 코드 하드코딩 사용>>>
          items.push(['접합']);
          columnsItem.push({
            field: `film${colIdx}`,
            headerName: '접합',
            width: 150,
            headerAlign: 'center',
            align: 'center',
          });

          colIdx = colIdx + 1;
        }
      })
    })
    // console.log(items)
    
    columnsItem.push(...additionalColum);
    console.log(columnsItem);
    setColumns(columnsItem);

    const arrCartesian = items.reduce(
      (accumulator, currentValue) => accumulator.cross(currentValue),
    );

    // console.log(arrCartesian)

    const gdomesticAuthInfo = arrCartesian.map((i, idx) => {
      if (Array.isArray(i)) {
        return i.flat(Infinity);  
      } else {
        return i;
      }
      
    });
    // console.log(gdomesticAuthInfo)
    
    const newGDomesticAuthInfo = [];
    gdomesticAuthInfo.forEach((i, iIdx) => {
      if (Array.isArray(i)) {
        i.map((j, jIdx) => {
          if (jIdx === 0) {
            newGDomesticAuthInfo.push({ id: uuidv4() });
          }
          let type = "";
          if (j === '접합') {
            type = "film";
          } else {
            type = "glass";
          }
          newGDomesticAuthInfo[iIdx] = {
            ...newGDomesticAuthInfo[iIdx],
            [`${type}${jIdx}`]: j,
          }
        })
      } else {
        /*if (i === '접합') {
          newGDomesticAuthInfo.push({ id: uuidv4(), [`film${iIdx}`]: i });
        } else {*/
          newGDomesticAuthInfo.push({ id: uuidv4(), [`glass0`]: i });
        // }
      }
    });

    console.log(newGDomesticAuthInfo)
    setRowsGDomesticAuth(newGDomesticAuthInfo);
  }

  const processRowUpdate = (newRow) => {
    setRowsGDomesticAuth(rowsGDomesticAuth.map((row) => (row.id === newRow.id ? { ...newRow } : row)));
    return newRow;
  };

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {      
      const newModel = {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
      return newModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        {/* Alert 다이얼로그 */}
        <GDomesticAuthAlertDialog />
        <CssBaseline />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Save />}
                onClick={handleClickSave}
              >
                {"저장하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <div style={{ height: 800, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loadedGGlass}
                  rows={rowsGGlass}
                  columns={columnsGlass}
                  // pageSize={pageSize}
                  // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  // rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  // onPageChange={handlePageChange}
                  // page={page}
                  // pagination
                  disableMultipleRowSelection={true}
                  onRowSelectionModelChange={ids => {
                    const selectedIDs = new Set(ids);
                    const selRows = rowsGGlass.filter((row) => selectedIDs.has(row.id));
                    
                    if (selRows.length !== 1) return; // 현재는 단일 선택 기능만 있음. disableMultipleRowSelection={true}이므로 이 코드는 사실 현재는 필요없음
    
                    const selectedGGlassId = selRows[0].id;
                    setGGlassId(selectedGGlassId);
                    generateOrSelectGDomesticAuthInfo(selRows);
                  }}
                />
              </div>

            </Grid>
            <Grid item xs={12} sm={9}>
              <div style={{ height: 800, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loaded}
                  rows={rowsGDomesticAuth}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  onPageChange={handlePageChange}
                  page={page}
                  pagination
                  processRowUpdate={processRowUpdate}
                  cellModesModel={cellModesModel}
                  onCellModesModelChange={handleCellModesModelChange}
                  onCellClick={handleCellClick}
                  // onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GDomesticAuthManagement;
