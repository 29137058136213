import * as React from "react";
import {
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";

class SelectGGlassTemplate extends React.Component {
  constructor(props) { 
    super(props);
    console.log(props)
  }

  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }
  
  handleChange = () => {
    this.props.handleChanges();
  }

  render () {
    return (
      <FormControl fullWidth>
        <Select
          id="demo-simple-select"
          onChange={this.handleChange}
          size="small"
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

export default SelectGGlassTemplate;