import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const SET_GTESTCOMPARISON = 'gtestComparison/setGTestComparision';
const REMOVE_GTESTCOMPARISON = "gtestComparison/removeGTestComparision";
const SET_GTESTCOMPARISONS = 'gtestComparison/setGTestComparisions';

const setGTestComparision = (gtestComparison) => ({
  type: SET_GTESTCOMPARISON,
  payload: gtestComparison,
});

const removeGTestComparision = () => ({
  type: REMOVE_GTESTCOMPARISON,
});

const setGTestComparisions = (data) => ({
  type: SET_GTESTCOMPARISONS,
  payload: data,
});

export const create = ({ id, g04docuGCertificationId, item, unit, criterion, result, method }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtestComparisons`, {
    method: "POST",
    body: JSON.stringify({ id, g04docuGCertificationId, item, unit, criterion, result, method }),
  });
  const data = await response.json();
  dispatch(setGTestComparision(data.gtestComparison));
  return response;
};

export const modify = ({ id, g04docuGCertificationId, item, unit, criterion, result, method }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtestComparisons`, {
    method: "PUT",
    body: JSON.stringify({ id, g04docuGCertificationId, item, unit, criterion, result, method }),
  });
  const data = await response.json();
  dispatch(setGTestComparision(data.gtestComparison));
  return response;
};

export const selectAllByG04docuGCertifications = (g04docuGCertificationId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtestComparisons/g04docuGCcertifications/${g04docuGCertificationId}`);
  const data = await response.json();
  dispatch(setGTestComparisions(data.gtestComparisons));
  return response;
}

export const selectAllByByG04docuGCertificationsDirect = async (g04docuGCertificationId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gtestComparisons/g04docuGCcertifications/${g04docuGCertificationId}`);
  const data = await response.json();
  return data.gtestComparisons;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtestComparisons/${id}`);
  const data = await response.json();
  dispatch(setGTestComparision(data.gtestComparison));
  return response;
}

export const initGTestComparision = () => async dispatch => {
  dispatch(setGTestComparision(null));
}

export const initGTestComparisions = () => async dispatch => {
  dispatch(setGTestComparisions([]));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtestComparisons/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGTestComparision());
  return response;
};

export const reorder = async ({ type, id, g04docuGCertificationId }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gtestComparisons/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id, g04docuGCertificationId }),
  });
  
  return response;
}

const initialState = {
  gtestComparison: null,
  gtestComparisons: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GTESTCOMPARISON:
      newState = Object.assign({}, state, { gtestComparison: action.payload });
      return newState;
    case REMOVE_GTESTCOMPARISON:
      newState = Object.assign({}, state, { gtestComparison: null });
      return newState;
    case SET_GTESTCOMPARISONS:
      newState = Object.assign({}, state, { gtestComparisons: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
