// TODO : 삭제 예정
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GSUB = 'gsub/setGSub';
const REMOVE_GSUB = "gsub/removeGSub";
const SET_GSUBS = 'gsub/setGSubs';
const INIT_GSUBS_CHILD = 'gsub/initGSubsChild';
const SET_GSUBS_CHILD = 'gsub/setGSubsChild';
const SET_GSUBS_TREE = 'gsub/setGSubsTree';
const SET_GSUBS_TREE_FIND = 'gsub/setGSubsTreeFind';
const SET_GSUBS_TOP_LEVEL = 'gsub/setGSubsTopLevel';
const SET_GSUBS_BOTTOM_LEVEL = 'gsub/setGSubsBottomLevel';
const SET_GSUBS_SELECT_COUNT = 'gsub/setGSubsSelectCount';
const INIT_GSUBS_SELECT_COUNT = 'gsub/initGSubsSelectCount';
const SET_GSUBS_ALL_SELECT_COUNT = 'gsub/setGSubsAllSelectCount';

const setGSub = (gsub) => ({
  type: SET_GSUB,
  payload: gsub,
});

const removeGSub = () => ({
  type: REMOVE_GSUB,
});

const setGSubs = (gsubs) => ({
  type: SET_GSUBS,
  payload: gsubs,
});

// setGSubsChild에 빈 배열([])을 설정해도 되지만 조회 후 데이터가 없음이 아닌 초기화라고 명확하게 표현하는 게 좋을 것 같음
const initGSubsChild = () => ({
  type: INIT_GSUBS_CHILD,
});

const setGSubsChild = (subsChild) => ({
  type: SET_GSUBS_CHILD,
  payload: subsChild,
});

const setGSubsTree = (gsubsTree) => ({
  type: SET_GSUBS_TREE,
  payload: gsubsTree,
});

const setGSubsTreeFind = (gsubsTreeFind) => ({
  type: SET_GSUBS_TREE_FIND,
  payload: gsubsTreeFind,
});

const setGSubsTopLevel = (gsubsTopLevel) => ({
  type: SET_GSUBS_TOP_LEVEL,
  payload: gsubsTopLevel,
});

const setGSubsBottomLevel = (gsubsBottomLevel) => ({
  type: SET_GSUBS_BOTTOM_LEVEL,
  payload: gsubsBottomLevel,
});

const setGSubsSelectCount = () => ({
  type: SET_GSUBS_SELECT_COUNT,
});

const initGSubsSelectCount = () => ({
  type: INIT_GSUBS_SELECT_COUNT,
});

const setGSubsAllSelectCount = (count) => ({
  type: SET_GSUBS_ALL_SELECT_COUNT,
  payload: count,
});

export const create = ({ id, name, code, gunitId, comments, pid }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, gunitId, comments, pid }),
  });
  const data = await response.json();
  dispatch(setGSub(data.gsub));
  return response;
};

export const modify = ({ id, name, code, gunitId, comments, pid }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, gunitId, comments, pid }),
  });
  const data = await response.json();
  dispatch(setGSub(data.gsub));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs`);
  const data = await response.json();
  dispatch(setGSubs(data.gsubs));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/query/selectAll`);
  const data = await response.json();
  dispatch(setGSubsTree(data.gsubsTree));
  return response;
}


export const selectAllByQueryFind = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/query/selectAllFind/${id}`);
  const data = await response.json();
  dispatch(setGSubsTreeFind(data.gsubsTreeFind));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/${id}`);
  const data = await response.json();
  dispatch(setGSub(data.gsub));
  return response;
}

export const initializeGSubsChild = () => async dispatch => {
  dispatch(initGSubsChild());
}

export const selectByPid = (pid) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/child/${pid}`);
  const data = await response.json();
  dispatch(setGSubsChild(data.gsubsChild));
  return response;
}

export const selectByPidDirect = async (pid) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsubs/child/${pid}`);
  const data = await response.json();
  return data.gsubsChild;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGSub());
  return response;
};

export const selectAllTopLevel = () => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/query/selectAllTopLevel`);
  const data = await response.json();
  dispatch(setGSubsTopLevel(data.gsubsTopLevel));
  return response;
}

export const selectAllBottomLevel = () => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/query/selectAllBottomLevel`);
  const data = await response.json();
  dispatch(setGSubsBottomLevel(data.gsubsBottomLevel));
  return response;
}

export const increaseGSubsSelectCount = () => async (dispatch) => {
  dispatch(setGSubsSelectCount());
}

export const initializeGSubsSelectCount = () => async (dispatch) => {
  dispatch(initGSubsSelectCount());
}

export const countFindChildAllIncludeMeByQuery = (gtypeId) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubs/query/countFindChildAllIncludeMeByQuery/${gtypeId}`);
  const data = await response.json();
  dispatch(setGSubsAllSelectCount(data.count));
  return response;
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsubs/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gsub: null,
  gsubs: [],
  gsubsTree: [],
  gsubsTreeFind: [],
  gsubsChild: [],
  gsubsTopLevel: [],
  gsubsBottomLevel: [],
  gsubsSelectCount: 0,
  gsubsAllSelectCount: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSUB:
      newState = Object.assign({}, state, { gsub: action.payload });
      return newState;
    case REMOVE_GSUB:
      newState = Object.assign({}, state, { gsub: null });
      return newState;
    case SET_GSUBS:
      newState = Object.assign({}, state, { gsubs: action.payload });
      return newState;
    case SET_GSUBS_TREE:
      newState = Object.assign({}, state, { gsubsTree: action.payload });
      return newState;
    case SET_GSUBS_TREE_FIND:
      newState = Object.assign({}, state, { gsubsTreeFind: action.payload });
      return newState;
    case INIT_GSUBS_CHILD:
      newState = Object.assign({}, state, { gsubsChild: [] });
      return newState;
    case SET_GSUBS_CHILD:
      newState = Object.assign({}, state, { gsubsChild: action.payload });
      return newState;
    case SET_GSUBS_TOP_LEVEL:
      newState = Object.assign({}, state, { gsubsTopLevel: action.payload });
      return newState;
    case SET_GSUBS_BOTTOM_LEVEL:
      newState = Object.assign({}, state, { gsubsBottomLevel: action.payload });
      return newState;
    case SET_GSUBS_SELECT_COUNT:
      const count = state.gsubsSelectCount + 1;
      newState = Object.assign({}, state, { gsubsSelectCount: count });
      return newState;
    case INIT_GSUBS_SELECT_COUNT:
      newState = Object.assign({}, state, { gsubsSelectCount: 0 });
      return newState;
    case SET_GSUBS_ALL_SELECT_COUNT:
      newState = Object.assign({}, state, { gsubsAllSelectCount: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
