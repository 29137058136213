// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GRECEPTION = 'greception/setGReception';
const REMOVE_GRECEPTION = "greception/removeGReception";
const SET_GRECEPTIONS = 'greception/setGReceptions';
const SET_GRECEPTIONS_WITH_GWORKORDERS = "greception/setGReceptionsWithGWorkOrders";

const setGReception = (greception) => ({
  type: SET_GRECEPTION,
  payload: greception,
});

const removeGReception = () => ({
  type: REMOVE_GRECEPTION,
});

const setGReceptions = (greceptions) => ({
  type: SET_GRECEPTIONS,
  payload: greceptions,
});

const setGReceptionsWithGWorkOrders = (greceptionsWithGWorkOrders) => ({
  type: SET_GRECEPTIONS_WITH_GWORKORDERS,
  payload: greceptionsWithGWorkOrders,
});

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions");
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/query/selectAll");
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const selectAllByProjectByQuery = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptions/query/selectAll/porject/${gprojectId}`);
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const selectAllByStatusByQuery = (statusArray) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/query/selectAll/statusArray", {
    method: "POST",
    body: JSON.stringify({ statusArray }),
  });
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const selectAllByStatusReceptionByQuery = (statusReceptionArray) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/query/selectAll/statusReceptionArray", {
    method: "POST",
    body: JSON.stringify({ statusReceptionArray }),
  });
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

// TODO : 아래와 같이 업무로직이 세분화된 쿼리의 경우 redux를 사용해야 하는지 검토 필요
// 접수된 주문중 작업의뢰가 내려가지 않은 목록
export const selectGReceptionsNoWorkOrder = (statusArray) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptions/query/selectGReceptionsNoWorkOrder`);
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptions/${id}`);
  const data = await response.json();
  dispatch(setGReception(data.greception));
  return response;
}

export const selectByQuery = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptions/query/select/${id}`);
  const data = await response.json();
  dispatch(setGReception(data.greception));
  return response;
}

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `/api/greceptions/${id}`);
  const data = await response.json();
  return data.greception;
}

export const selectByQueryDirect = async (id) => {
  const response = await csrfFetch(null, `/api/greceptions/query/select/${id}`);
  const data = await response.json();
  return data.greception;
}

export const selectAllWithGWorkOrdersByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/query/selectAllWithGWorkOrders");
  const data = await response.json();
  dispatch(setGReceptionsWithGWorkOrders(data.greceptionsWithGWorkOrders));
  return response;
}

export const initGReception = () => async dispatch => {
  dispatch(setGReception(null));
  // dispatch(setGReception({}));
}

// 목록에서 선택해서 다이얼로그를 띄우거나 목록에서 등록하기를 누를 때
// 다이얼로그에서는 선택한 행 데이터의 변화를 감지하여 선택 데이터 혹은 초기화 데이터로 화면을 구성하는데
// 위의 initGReception처럼 null로 세팅하면 이후 null일 때(등록하기를 연속 누른 경우) 변화감지 X
// 아래처럼 초기값을 줄 경우 변화감지 O (빈 객체 {} 일 경우도 바뀐 것으로 간주)
export const initGReceptionWithInitialValue = (greception) => async dispatch => {
  dispatch(setGReception(greception));
}

/**
 *                           status        statusReception       statusWorkOrder
 * 발주생성                   WRITING      null                  null
 * 발주전송                   SEND          PRE-RECEIVE           null
 * 수주접수                   RECEIVE       RECEIVE               PRE
 * 수주반송                   RETURN        RETURN                null                (반송된 수주내용을 수정하여 다시 전송하는데 수정시는 계속 'RETURN' 상태임 => 목록에서 '반송' 상태)
 * 작업의뢰(일부발행)          RECEIVE       RECEIVE               ING
 * 작업의뢰(발행완료)          RECEIVE       RECEIVE               DONE
 */
export const send = ({ id, status, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/status", {
    method: "PUT",
    body: JSON.stringify({ id, status, statusReception: "PRE-RECEIVE", comments }),
  });
  const data = await response.json();
  dispatch(setGReception(data.greception));
  return response;
};

export const sendDirect = async ({ id, status, comments }) => {
  const response = await csrfFetch(null, "/api/greceptions/status", {
    method: "PUT",
    body: JSON.stringify({ id, status, statusReception: "PRE-RECEIVE", comments }),
  });
  const data = await response.json();
  return data.greception;
};

export const receive = ({ id, statusReception, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/status", {
    method: "PUT",
    body: JSON.stringify({ id, status: statusReception, statusReception, statusWorkOrder: statusReception === "RECEIVE" ? "PRE" : null, comments }),
  });
  const data = await response.json();
  dispatch(setGReception(data.greception));
  return response;
};

export const receiveDirect = async ({ id, statusReception, comments }) => {
  const response = await csrfFetch(null, "/api/greceptions/status", {
    method: "PUT",
    body: JSON.stringify({ id, status: statusReception, statusReception, statusWorkOrder: statusReception === "RECEIVE" ?  "PRE" : null, comments }),
  });
  const data = await response.json();
  return data.greception;
};

export const setStatusWorkOrder = ({ id, statusWorkOrder, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/greceptions/status", {
    method: "PUT",
    body: JSON.stringify({ id, statusWorkOrder, comments }),
  });
  const data = await response.json();
  dispatch(setGReception(data.greception));
  return response;
};

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/greceptions/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGReception());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, "/api/greceptions/reorder", {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const selectAllByProject = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptions/project/${gprojectId}`);
  const data = await response.json();
  dispatch(setGReceptions(data.greceptions));
  return response;
}

export const selectAllByProjectByDirect = async (gprojectId) => {
  const response = await csrfFetch(null, `/api/greceptions/project/${gprojectId}`);
  const data = await response.json();
  return data;
}

const initialState = {
  greception: null,
  greceptions: [],
  greceptionsWithGWorkOrders: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GRECEPTION:
      newState = Object.assign({}, state, { greception: action.payload });
      return newState;
    case REMOVE_GRECEPTION:
      newState = Object.assign({}, state, { greception: null });
      return newState;
    case SET_GRECEPTIONS:
      newState = Object.assign({}, state, { greceptions: action.payload });
      return newState;
    case SET_GRECEPTIONS_WITH_GWORKORDERS:
      newState = Object.assign({}, state, { greceptionsWithGWorkOrders: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
