import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
// import { makeStyles } from '@material-ui/styles';
// import { useDispatch } from "react-redux";
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import {
  Button,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import  {
  ArrowDropDown,
  ArrowRight,
  ChevronRight,
} from '@mui/icons-material';
import {
  usePopupState,
  bindHover,
  bindFocus,
  bindMenu,
} from 'material-ui-popup-state/hooks';

// const useCascadingMenuStyles = makeStyles((theme) => ({
//   submenu: {
//     marginTop: theme.spacing(-1),
//   },
//   title: {
//     flexGrow: 1,
//   },
//   moreArrow: {
//     marginRight: theme.spacing(-1),
//   },
// }))

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
})

function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      // 
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick]
  )

  // 하위 메뉴 생성
  let ret = null;
  if (props.menu && props.menu.submenus) {
    ret = (
      <CascadingSubmenu
        // popupId={props.menu.submenus.id}
        popupId={props.menu.id}
        title={props.menu.title}
      >
        {
          props.menu.submenus.map(submenu => {
            return (
              <CascadingMenuItem
                menu={submenu}
                href={submenu.href}
              >
                {submenu.title}
              </CascadingMenuItem>
            );
          })
        }
        {/* <CascadingMenuItem>Cheesecake</CascadingMenuItem> */}
      </CascadingSubmenu>
    )
  } else {
    ret = (
      <RouterLink to={props.href || ""} style={{ textDecoration: 'none' }}>
        <MenuItem {...props} onClick={handleClick} style={{ color: '#212121'/*"#1976d2", fontWeight: "bold"*/ }} />
      </RouterLink>
      
      // <MenuItem
      //   {...props}
      //   component={Link}
      //   onClick={handleClick}
      // />
    ) 
  }
  
  return (
    // <Link href={props.href} style={{ textDecoration: 'none' }}>
    //   <MenuItem {...props} onClick={handleClick} />
    // </Link>
    ret
  )
}

function CascadingSubmenu({ title, popupId, ...props }) {
  // const classes = useCascadingMenuStyles()
  const { parentPopupState } = React.useContext(CascadingContext)
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  })
  return (
    <React.Fragment>
      <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)} style={{ color: '#212121'/*"#1976d2", fontWeight: "bold"*/ }}>
        {/* <span className={classes.title}>{title}</span> */}
        {/* <ChevronRight className={classes.moreArrow} /> */}
        <span>{title}</span>
        {/* <ChevronRight /> */}
        <ArrowRight />
      </MenuItem>
      <CascadingMenu
        {...props}
        // classes={{ ...props.classes, paper: classes.submenu }}        
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        popupState={popupState}
      />
    </React.Fragment>
  )
}

function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  )

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  )
}

const CascadingHoverMenus = ({ menu }) => {
  // const dispatch = useDispatch();

  const popupState = usePopupState({
    popupId: menu.id,
    variant: 'popover',
  });
  
  return (
    <div>
      {
        !menu.submenus && (
          <Button
            // href={menu.href}
            sx={{ mr: 1 }}
            {...bindHover(popupState)}
            {...bindFocus(popupState)}
          >
            <RouterLink
              to={menu.href}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                // variant="h6"
                noWrap
                component="div"
                sx={{ my: 2, color: /*'white'*/'#212121', display: 'block' }}
              >
                {menu.title}
              </Typography>
            </RouterLink>
          </Button>
        )
      }
      {
        menu.submenus && (
          <>
            <Button
              sx={{ color: /*'white'*/'#212121' }}
              {...bindHover(popupState)}
              {...bindFocus(popupState)}
            >
              <Typography
                // variant="h6"
                noWrap
                component="div"
                sx={{ my: 2, color: /*'white'*/'#212121', display: 'block' }}
              >
                {menu.title}
              </Typography>
              <ArrowDropDown />
            </Button>
            <CascadingMenu
              popupState={popupState}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              {
                menu.submenus.map(submenu => {
                  return (
                    <CascadingMenuItem
                      menu={submenu}
                      href={submenu.href}
                    >
                      {submenu.title}
                    </CascadingMenuItem>
                  );
                })
              }
            </CascadingMenu>
          </>
        )
      }
    </div>
  )
}

export default CascadingHoverMenus