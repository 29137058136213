import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller } from "react-hook-form";

// const options = [
//   {
//     label: "Radio Option 1",
//     value: "1"
//   },
//   {
//     label: "Radio Option 2",
//     value: "2"
//   },
// ];

const FormInputRadio = ({ name, control, label, options, row, onChangeButton, defaultValue }) => {
  const generateRadioOptions = () => {
    /**
     * map안에서 key를 아래와 같이 하지 않으면 다음과 같은 메시지가 발생한다.
     * Warning: Each child in a list should have a unique "key" prop.
     */
    return options.map((singleOption, index) => (
      <FormControlLabel
        sx={{
          color: singleOption?.color,
        }}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio sx={{
          color: singleOption?.color,
          '&.Mui-checked': {
            color: singleOption?.color,
          },
        }}/>}
        key={index}
      />
    ));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <RadioGroup
            value={value || defaultValue}
            onChange={(e) => {
              onChange(e);
              onChangeButton && onChangeButton(e);
            }}
            // onChange={onChange}
            row={row}
          >
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

export default FormInputRadio;