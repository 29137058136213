import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Alarm,
  Delete,
  Edit,
  EditCalendar,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';

import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
} from "../../utils";
import * as gclientG04docuFileActions from "../../store/gclientG04docuFile";
import * as g04docuAlarmEmailActions from "../../store/g04docuAlarmEmail";
import * as dialogActions from "../../store/components/dialog";
import G04docuAlarmSettingDialog from "./G04docuAlarmSettingDialog";
import G04docuModifyDateDialog from "./G04docuModifyDateDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const defaultValues = {
  searchName: "",
  saveDocuTypes: [],
}

const G04docuAlarmSettingManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [docuTypes, setDocuTypes] = useState(['ALL']);
  const [columns, setColumns] = useState([]);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleClickAlarmSettings = async (params) => {
    console.log(params.row)
    setOpenBackdrop(true);
    const { gclientId, id } = params.row;
    const alarms = await selectAlarmsDirect({ gclientId, g04docuId: id });
    // TODO : docuTypes가 초기값 그대로임??? handleClickModifyDate 함수는 렌더링이 안되나? => 일단 docuTypes 변경할 때마다 setValue("saveDocuTypes", ~)하고 여기서는 getValues("saveDocuTypes")로 값을 가져온다.
    dispatch(dialogActions.setOptions({ open: true, crudMode: 'C', name: 'G04docuAlarmSettingDialog', values: { gclientId, g04docuId: id, alarms, types: getValues("saveDocuTypes"), searchName: getValues("searchName") }, action: selectAllByQuery }));
    setOpenBackdrop(false);
  }

  const handleDeleteAlarm = async (id) => {
    await removeAlarms(id);
    setLoaded(false);
    await selectAllByQuery({ gclientId: sessionUser.id, types: getValues("saveDocuTypes"), searchName: getValues("searchName") });
    setLoaded(true);
  }

  const columnsBasic = [
    {
      field: 'docuType',
      headerName: '문서종류',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        let result = "";
        const { value } = params;
        if (value) {
          if (value === 'GENERAL') {
            result = '일반문서';
          } else if (value === 'CERTIFICATION') {
            result = '인증서';
          } else if (value === 'TEST') {
            result = '성적서';
          } else if (value === 'ETC') {
            result = '기타문서';
          }
        }

        return result;
      },
    },
    {
      field: 'name',
      headerName: '이름',
      width: 400,
    },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (params.row.validYN) {
          return "없음";
        } else {
          return params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "";
        }
      },
    },
    {
      field: 'alarms',
      headerName: '알람설정',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IconButton color="primary" aria-label={"알람설정"} onClick={() => handleClickAlarmSettings(params)}>
            <Alarm />
          </IconButton>
        )
      }
    },
    {
      field: 'alarmsSet',
      headerName: '설정된 알람',
      width: 300,
      renderCell: (params) => {
        const { alarms } = params.row;
        if (alarms && Array.isArray(alarms) && alarms.length > 0) {
          return alarms.map((alarm, i) => {
            const { id, type, period } = alarm;
            let text = "";
            if (type === 'D') {
              text = " 일 전";
            } else if (type === 'W') {
              text = " 주 전";
            } else if (type === 'M') {
              text = " 개월 전";
            }

            return <Chip label={`${period}${text}`} color="primary" size="small" variant="outlined" onDelete={() => handleDeleteAlarm(id)} sx={{ mr: i === alarms.length-1 ? 0 : 1 }} />
          })
        }
        // return (
        //   <Button color="secondary" aria-label="add an alarm" startIcon={<Alarm />}>
        //   </Button>
        // )
      }
    },
    // {
    //   field: 'comments',
    //   headerName: '설명',
    //   width: 280,
    // },
  ];
  
  const rows = useSelector((state) => state.gclientG04docuFile.gclientG04docuFiles);
  
  const { control, setValue, getValues } = useForm({ defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const sessionUser = useSelector(state => state.session.sessionUser);

  const selectAllByQuery = ({ gclientId, types, searchName }) => dispatch(gclientG04docuFileActions.selectAllByQuery({ gclientId, types, searchName }))
  const selectAlarmsDirect = ({ gclientId, g04docuId }) => g04docuAlarmEmailActions.selectDirect({ gclientId, g04docuId })
  const removeAlarms = (id) => dispatch(g04docuAlarmEmailActions.remove(id))

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleClickModifyDate = async (params) => {{
    setOpenBackdrop(true);
    // TODO : docuTypes가 초기값 그대로임??? handleClickModifyDate가 이미 만들어진 채로 동작하는 것 같음. docuTypes 변경할 때마다 setValue("saveDocuTypes", ~)하고 여기서는 getValues("saveDocuTypes")로 값을 가져온다.
    dispatch(dialogActions.setOptions({ open: true, crudMode: 'U', name: 'G04docuModifyDateDialog', values: { types: getValues("saveDocuTypes"), searchName: getValues("searchName"), params },  action: selectAllByQuery }));
    setOpenBackdrop(false);
  }}

  useEffect(
    async () => {
      const { type } = sessionUser;
      if (type === 'ADMIN') {
        setColumns(columnsBasic.concat([{
          field: 'modify',
          headerName: '만료일 변경',
          width: 140,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => {
            return (
              <IconButton color="primary" aria-label={"만료일 변경"} onClick={() => handleClickModifyDate(params)}>
                <EditCalendar />
              </IconButton>
            )
          }
        }]));
      } else {
        setColumns(columnsBasic);
      }

      refresh();
    }, [dispatch]
  )

  useEffect(
    () => {
      setValue("saveDocuTypes", docuTypes);
    }, [docuTypes]
  )

  const refresh = async () => {
    const { id } = sessionUser;
    setLoaded(false);
    console.log(docuTypes)
    await selectAllByQuery({ gclientId: id, types: docuTypes, searchName: getValues("searchName") });
    setLoaded(true);
  }

  const handleClickSearch = () => {
    refresh();
  }

  const handleChangeDocuType = async (event, newDocuTypes) => {
    let all = false;
    if (newDocuTypes.length > 0 && newDocuTypes[newDocuTypes.length - 1] === 'ALL') {
      setDocuTypes(['ALL']);
      all = true;
    } else if (newDocuTypes.length > 0 && newDocuTypes[0] === 'ALL') {
      newDocuTypes.splice(0, 1);
      setDocuTypes(newDocuTypes);
    } else {
      setDocuTypes(newDocuTypes);
    }

    const { id } = sessionUser;
    setLoaded(false);
    await selectAllByQuery({ gclientId: id, types: all ? ['ALL'] : newDocuTypes, searchName: getValues("searchName") });
    setLoaded(true);
  };

  var timer;
  const handleChangeSearchName = () => {
    console.log({ name: getValues("searchName") })
    const searchName = getValues("searchName");
    
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    const { id, type } = sessionUser;
    // 타이머 설정
    timer = setTimeout(async () => {
      setLoaded(false);
      await selectAllByQuery({ gclientId: id, types: docuTypes, searchName });
      setLoaded(true);
    }, 500);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuAlarmSettingDialog />
        <G04docuModifyDateDialog />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
              {/* <Stack direction="row"> */}
                <ToggleButtonGroup
                  color="primary"
                  value={docuTypes}
                  // exclusive
                  onChange={handleChangeDocuType}
                  aria-label="문서종류"
                  size="small"
                >
                  <ToggleButton value="ALL">{"전체"}</ToggleButton>
                  <ToggleButton value="GENERAL">{"일반문서"}</ToggleButton>
                  <ToggleButton value="CERTIFICATION">{"인증서"}</ToggleButton>
                  <ToggleButton value="TEST">{"성적서"}</ToggleButton>
                  <ToggleButton value="ETC">{"기타"}</ToggleButton>
                </ToggleButtonGroup>
                <FormInputText
                  name={"searchName"}
                  control={control}
                  label={"문서이름으로 검색하세요."}
                  onCustomChange={handleChangeSearchName}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: "#E3EEFA"
                    },
                    '& .MuiFormLabel-root' : {
                      color: '#2196f3',
                      fontSize: '0.85rem',
                    },
                    input: { color: '#2196f3', fontSize: '0.85rem' },
                    width: 700,
                    ml: 1,
                  }}
                />
              {/* </Stack> */}
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                // sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickSearch}
              >
                {"검색하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              pagination
            />
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default G04docuAlarmSettingManagement;
