import React from 'react';
// TODO : 최신버전은 TreeView가 @mui/lab에서 @mui/x-tree-view로 이동했으나 기존 개발버전이 작동하지 않아 올드버전 사용
import MuiTreeView from '@mui/lab/TreeView';
// import * as MuiTreeView from '@mui/x-tree-view/TreeView';
import SvgIcon from '@mui/material/SvgIcon';
import TreeItem from './GTreeItem';
import {
  Box,
  Button,
} from '@mui/material';

// const data = [
//   {
//     id: 'applications',
//     label: 'Applications',
//     // type: 'main',
//     children: [
//       {
//         id: 'applications.calendar',
//         label: 'Calender',
//         // type: 'sub',
//       },
//       {
//         id: 'applications.chrome',
//         label: 'Chrome',
//         // type: 'sub',
//       },
//       {
//         id: 'applications.webstorm',
//         label: 'Webstorm',
//         // type: 'sub',
//       },
//     ],
//   },
//   {
//     id: 'documents',
//     label: 'Documents',
//     // type: 'main',
//     children: [
//       {
//         id: 'documents.bootstrap',
//         label: 'Bootstrap',
//         // type: 'sub',
//         children: [
//           {
//             id: 'documents.bootstrap.button',
//             label: 'Button',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.bootstrap.typography',
//             label: 'Typography',
//             // type: 'cat',
//           },
//         ],
//       },
//       {
//         id: 'documents.oss',
//         label: 'OSS',
//         // type: 'sub',
//       },
//       {
//         id: 'documents.material-ui',
//         label: 'Material UI',
//         // type: 'sub',
//         children: [
//           {
//             id: 'documents.material-ui.button',
//             label: 'Button',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.material-ui.typography',
//             label: 'Typography',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.material-ui.textfield',
//             label: 'Text Field',
//             // type: 'cat',
//           },
//         ],
//       },
//     ],
//   },
// ];

const data = [
  {
    id: "원자재",
    label: "원자재",
  },
  {
    id: "가공",
    label: "가공",
  },
  {
    id: "가공부자재",
    label: "가공부자재",
    // type: 'SUB_MATERIAL_PROCESS',
    children: [
      {
        id: "0f9661a2-4263-48d2-b010-4e239fc2f245",
        label: "제품목록 : Swisspacer",
        children: [
          {
            id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a",
            label: "거래선 : SWISSPACER",
            children: [
              {
                id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a/8217b7aa-b30b-463b-b2d7-539f076a9a26",
                label: "사업자등록증",
              },
              {
                id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a/8f273917-fb2b-46db-83c7-921bf56f6b7f",
                label: "국세 납세증명서",
              },
              {
                id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a/57391be2-a42f-4dfc-8b7c-4c0d21f3f366",
                label: "지방세 납세증명서",
              },
              {
                id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a/b28f8a57-0f20-47b6-9d93-9c6e46a2e91d",
                label: "회사소개자료",
              },
              {
                id: "0f9661a2-4263-48d2-b010-4e239fc2f245/874d3817-5775-4511-90fb-4c6c7989387a/74189699-be74-4089-b550-de672a892c7e",
                label: "납품실적",
              }
            ],
          },
          {
            id: "0f9661a2-4263-48d2-b010-4e239fc2f245/0f9661a2-4263-48d2-b010-4e239fc2f245",
            label: "Swisspacer",
            children: [
              {
                id: '인증서Swisspacer01',
                label: '인증서',
              },
              {
                id: '성적서Swisspacer01',
                label: '성적서',
              }
            ]
          }
        ],
      },
      {
        id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299",
        label: "제품목록 : 크로마테크 Plus, 크로마테크 Ultra",
        children: [
          {
            id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf",
            label: "거래선 : Roll Tech",
            children: [
              {
                id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf/8217b7aa-b30b-463b-b2d7-539f076a9a26",
                label: "사업자등록증",
              },
              {
                id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf/8f273917-fb2b-46db-83c7-921bf56f6b7f",
                label: "국세 납세증명서",
              },
              {
                id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf/57391be2-a42f-4dfc-8b7c-4c0d21f3f366",
                label: "지방세 납세증명서",
              },
              {
                id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf/b28f8a57-0f20-47b6-9d93-9c6e46a2e91d",
                label: "회사소개자료",
              },
              {
                id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/d3ed33dc-9a85-4112-8035-5aec73ca1cbf/74189699-be74-4089-b550-de672a892c7e",
                label: "납품실적",
              }
            ],
          },
          {
            id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/1d5d93a5-abb1-4c35-885e-fd7422d33a1e",
            label: "크로마테크 Plus",
            children: [
              {
                id: '인증서크로마테크 Plus01',
                label: '인증서',
              },
              {
                id: '성적서크로마테크 Plus01',
                label: '성적서',
              }
            ]
          },
          {
            id: "1d5d93a5-abb1-4c35-885e-fd7422d33a1e,9fe372e1-6345-4250-896c-e73bf7b11299/9fe372e1-6345-4250-896c-e73bf7b11299",
            label: "크로마테크 Ultra",
            children: [
              {
                id: '인증서크로마테크 Ultra01',
                label: '인증서',
              },
              {
                id: '성적서크로마테크 Ultra01',
                label: '성적서',
              }
            ]
          }
        ],
      },
    ]
  },
  {
    id: "시공부자재",
    label: "시공부자재",
  },
];

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const TreeView = ({
  // items = data,
  items,
  selected: selectedProps = [],
  onSelect: onSelectProps = () => {},
  disableMultiParentSelection = true,
  handleClickPreview,
  setSelectedRow,
  setSelectedRowEtc,
  setSelectedGComponentItemId,
  setSelectedGComponentItemName,
  setSelectedGComponentItemType,
  setSelectedDocuFileType,
  setRemoveFileObject,
  setConfirmOpenRemoveFile,
  setOpen,
  setOpenEtc,
  setEtcType,
  handleClickViewEachDoc,
  handleClickFileDownload,
}) => {
  const [expanded, setExpanded] = React.useState([
    // // 'material-ui',
    // // 'documents',
    // // 'applications',
    // 'documents',
    // 'documents.bootstrap',
    // 'documents.material-ui',
  ]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (value) => {
    onSelectProps((prevSelected) => {
      // if (
      //   prevSelected.some(
      //     (prevSelect) =>
      //       prevSelect.startsWith('main.') && prevSelect !== value,
      //   )
      // ) {
      //   return prevSelected;
      // }

      if (prevSelected.includes(value)) {
        return prevSelected.filter((prevSelect) => prevSelect !== value);
      }

      return [...prevSelected, value];
    });
  };

  // React.useEffect(() => {
  //   setSelected(selectedProps);
  // }, [selectedProps]);

  const handleExpandClick = () => {
    const ids = [];
    // TODO : 전체에서 id 키를 찾는 기능. 성능이 더 나은 것이 있는지 검토 필요
    JSON.stringify(items, function(key, value) {
      if (key === "id") 
          ids.push(value);

      return value;
    });
    
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ids : [],
    );
  };

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? "전체 펼치기" : "전체 닫기"}
        </Button>
      </Box>
      <MuiTreeView
        // className={classes.root}
        // defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpandIcon={<ChevronRightIcon />}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {
          // data.length > 0 && (
          items.length > 0 && (
            <TreeItem
              items={items}
              selected={selectedProps}
              onSelect={onSelectProps}
              disableMultiParentSelection={disableMultiParentSelection}
              handleClickViewEachDoc={handleClickViewEachDoc}
              handleClickFileDownload={handleClickFileDownload}
              setSelectedRow={setSelectedRow}
              setSelectedRowEtc={setSelectedRowEtc}
              setSelectedGComponentItemId={setSelectedGComponentItemId}
              setSelectedGComponentItemName={setSelectedGComponentItemName}
              setSelectedGComponentItemType={setSelectedGComponentItemType}
              setSelectedDocuFileType={setSelectedDocuFileType}
              setRemoveFileObject={setRemoveFileObject}
              setConfirmOpenRemoveFile={setConfirmOpenRemoveFile}
              setOpen={setOpen}
              setOpenEtc={setOpenEtc}
              setEtcType={setEtcType}
            />
          )
        }
      </MuiTreeView>
    </>
  );
};

export default TreeView;
