import Cookies from "js-cookie";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_GSEND_BASKET = 'gsendBasket/initGSendBasket';
const INIT_GSEND_BASKETS = 'gsendBasket/initGSendBaskets';
const SET_GSEND_BASKET = 'gsendBasket/setGSendBasket';
const REMOVE_GSEND_BASKET = "gsendBasket/removeGSendBasket";
const SET_GSEND_BASKETS = 'gsendBasket/setGSendBaskets';

const initGSendBasket = () => ({
  type: INIT_GSEND_BASKET,
});

const initGSendBaskets = () => ({
  type: INIT_GSEND_BASKETS,
});

const setGSendBasket = (gsendBasket) => ({
  type: SET_GSEND_BASKET,
  payload: gsendBasket,
});

const removeGSendBasket = () => ({
  type: REMOVE_GSEND_BASKET,
});

const setGSendBaskets = (gsendBaskets) => ({
  type: SET_GSEND_BASKETS,
  payload: gsendBaskets,
});

export const create = ({ id, g04docuId, gclientId, documentType, ownerId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets`, {
    method: "POST",
    body: JSON.stringify({ id, g04docuId, gclientId, documentType, ownerId }),
  });
  const data = await response.json();
  dispatch(setGSendBasket(data.gsendBasket));
  return response;
};

export const createDirect = async ({ id, g04docuId, gclientId, gclient, documentType, ownerId, name }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendBaskets`, {
    method: "POST",
    body: JSON.stringify({ id, g04docuId, gclientId, gclient, documentType, ownerId, name }),
  });
  const data = await response.json();
  return data.gsendBasket;
};

export const createBulkDirect = async (gsendBaskets) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendBaskets/bulk`, {
    method: "POST",
    body: JSON.stringify({ gsendBaskets }),
  });
  const data = await response.json();
  return data.gsendBasket;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets`);
  const data = await response.json();
  dispatch(setGSendBaskets(data.gsendBaskets));
  return response;
}

export const selectAllByOwner = (ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/owner/${ownerId}`);
  const data = await response.json();
  dispatch(setGSendBaskets(data.gsendBaskets));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/query/selectAll`);
  const data = await response.json();
  dispatch(setGSendBaskets(data.gsendBaskets));
  return response;
}

export const selectAllByOwnerByQuery = (ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/query/selectAll/owner/${ownerId}`);
  const data = await response.json();
  dispatch(setGSendBaskets(data.gsendBaskets));
  return response;
}

export const selectAllByCreatorByQuery = (creatorId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/query/selectAll/creator/${creatorId}`);
  const data = await response.json();
  dispatch(setGSendBaskets(data.gsendBaskets));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/${id}`);
  const data = await response.json();
  dispatch(setGSendBasket(data.gsendBasket));
  return response;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGSendBasket());
  return response;
};

export const removeByOwner = (ownerId) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets/owner/${ownerId}`, {
    method: "DELETE",
  });
  dispatch(removeGSendBasket());
  return response;
};

export const removeByCreator = (creatorId) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendBaskets//query/delete/creator/${creatorId}`, {
    method: "DELETE",
  });
  dispatch(removeGSendBasket());
  return response;
};

export const initializeGSendBasket = () => async (dispatch) => {
  dispatch(initGSendBasket());
}

export const initializeGSendBaskets = () => async dispatch => {
  dispatch(initGSendBaskets());
}

export const sendDirect = async ({ gclientTo, mailTo, mailCc, mailCcInternal, subject, html, gsendBaskets, options, phrases }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendBaskets/send`, {
    method: "POST",
    body: JSON.stringify({ gclientTo, mailTo, mailCc, mailCcInternal, subject, html, gsendBaskets, options, phrases }),
  });
  const data = await response.json();
  return data;
};

export const sendWithLocalFileDirect = async (formData) => {
  const options = {
    method: 'POST',
    // headers: { 'Content-Type': 'multipart/form-data' }, // TODO : 왜 이것을 지정하면 서버에서 못받지??? 추후 분석
    body: formData,
  }

  options.headers = {};
  options.headers["XSRF-Token"] = Cookies.get("XSRF-TOKEN");
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    options.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const response = await window.fetch(`${gcoreServerUrl}/api/gsendBaskets/send/files`, options);
  
  const data = await response.json();
  return data;
};

// export const reorder = async ({ type, id }) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendBaskets/reorder`, {
//     method: "POST",
//     body: JSON.stringify({ type, id }),
//   });
  
//   return response;
// }

const initialState = {
  gsendBasket: null,
  gsendBaskets: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GSEND_BASKET:
      newState = Object.assign({}, state, { gsendBasket: null });
      return newState;
    case INIT_GSEND_BASKETS:
      newState = Object.assign({}, state, { gsendBaskets: initialState.gsendBaskets });
      return newState;
    case SET_GSEND_BASKET:
      newState = Object.assign({}, state, { gsendBasket: action.payload });
      return newState;
    case SET_GSEND_BASKETS:
      newState = Object.assign({}, state, { gsendBaskets: action.payload });
      return newState;
    case REMOVE_GSEND_BASKET:
      newState = Object.assign({}, state, { gsendBasket: null });
      return newState;
    default:
      return state;
  }
}

export default reducer;
