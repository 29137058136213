import React from "react";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

const followers = [
  { "0-50k": [0, 50000] },
  { "50k-100k": [50001, 100000] },
  { "100k-250k": [100001, 250000] },
  { "250k-500k": [250001, 500000] },
  { "500k-750k": [500001, 750000] },
  { "+1M": [750001, Number.MAX_SAFE_INTEGER] }
];

export default function App() {
  // const [value, setValue] = React.useState("50k-100k");
  const [value, setValue] = React.useState("");
  const handleChange = (event) => setValue(event.target.value);
  const handleClick = (event) => setValue("500k-750k");
  return (
    <div>
      <FormControl size={"small"} fullWidth>
      <InputLabel>{"label"}</InputLabel>
      <Select
        fullWidth
        id="followers"
        labelId="followersL"
        margin="dense"
        displayEmpty
        name="followers"
        onChange={handleChange}
        value={value}
        variant="outlined"
        label={"label"}
      >
        {followers.map((element) => {
          const [[key]] = Object.entries(element);
          return (
            <MenuItem value={key} key={key}>
              {key}
            </MenuItem>
          );
        })}
      </Select>
      </FormControl>
      <Button onClick={handleClick}>Click</Button>
    </div>
  );
}