import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config';

const INIT_GCLIENT_G04_DOCU_MAPS = "gclientG04docuMaps/initGClientG04docuMaps";
const SET_GCLIENT_G04_DOCU_MAP = "gclientG04docuMaps/setGClientG04docuMap";
const SET_GCLIENT_G04_DOCU_MAPS = "gclientG04docuMaps/setGClientG04docuMaps";
const REMOVE_GCLIENT_G04_DOCU_MAP = "gclientG04docuMaps/removeGClientG04docuMap";
const INIT_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION = "gclientG04docuMaps/initGClientG04docuMapsForCertification";
const INIT_GCLIENT_G04_DOCU_MAPS_FOR_TEST = "gclientG04docuMaps/initGClientG04docuMapsForTest";
const SET_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION = "gclientG04docuMaps/setGClientG04docuMapsForCertification";
const SET_GCLIENT_G04_DOCU_MAPS_FOR_TEST = "gclientG04docuMaps/setGClientG04docuMapsForTest";

const initGClientG04docuMaps = () => ({
  type: INIT_GCLIENT_G04_DOCU_MAPS,
});

const setGClientG04docuMap = (gclientG04docuMap) => ({
  type: SET_GCLIENT_G04_DOCU_MAP,
  payload: gclientG04docuMap,
});

const setGClientG04docuMaps = (gclientG04docuMaps) => ({
  type: SET_GCLIENT_G04_DOCU_MAPS,
  payload: gclientG04docuMaps,
});

const initGClientG04docuMapsForCertification = () => ({
  type: INIT_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION,
});

const initGClientG04docuMapsForTest = () => ({
  type: INIT_GCLIENT_G04_DOCU_MAPS_FOR_TEST,
});

const setGClientG04docuMapsForCertification = (gclientG04docuMapsForTest) => ({
  type: SET_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION,
  payload: gclientG04docuMapsForTest,
});

const setGClientG04docuMapsForTest = (gclientG04docuMapsForTest) => ({
  type: SET_GCLIENT_G04_DOCU_MAPS_FOR_TEST,
  payload: gclientG04docuMapsForTest,
});

const removeGClientG04docuMap = () => ({
  type: REMOVE_GCLIENT_G04_DOCU_MAP,
});

// export const createMaps = (gclientG04docuMaps) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/bulk`, {
//     method: "POST",
//     body: JSON.stringify(gclientG04docuMaps),
//   });
//   const data = await response.json();
//   dispatch(setGClientG04docuMaps(data.gclientG04docuMaps));
//   return response;
// };

// export const createMapsDirect = async (gclientG04docuMaps) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuMaps/bulk`, {
//     method: "POST",
//     body: JSON.stringify(gclientG04docuMaps),
//   });
//   const data = await response.json();
//   return data.gclientG04docuMaps;
// };
export const create = ({ gclientId, g04docuId, gcomponentItemId, gclient, documentPath, documentType, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuId, gcomponentItemId, gclient, documentPath, documentType, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuMap(data.gclientG04docuMap));
  return response;
};

export const modifyDate = ({ gclientId, g04docuId, documentPath, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04docuId, documentPath, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuMap(data.gclientG04docuMap));
  return response;
};

// export const modify = ({ gclientId, g04docuId, gclient, documentPath }) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps`, {
//     method: "PUT",
//     body: JSON.stringify({ gclientId, g04docuId, gclient, documentPath }),
//   });
//   const data = await response.json();
//   dispatch(setGClientG04docuMap(data.gclientG04docuMap));
//   return response;
// };

export const initializeGClientG04docuMaps = () => async dispatch => {
  dispatch(initGClientG04docuMaps());
}

// TODO : 인증서. 사용안하고 있음. 검토 필요
export const selectAllByGClientByG04docu = (gclientId, g04docuId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/query/selectAll/gclient/${gclientId}/g04docu/${g04docuId}`);
  const data = await response.json();
  dispatch(setGClientG04docuMaps(data.gclientG04docuMaps));
  return response;
}

// 인증서
export const selectAllByGClientByG04docuForCertification = (gclientId, g04docuId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/query/selectAll/gclient/${gclientId}/g04docu/${g04docuId}`);
  const data = await response.json();
  dispatch(setGClientG04docuMapsForCertification(data.gclientG04docuMapsForCertification));
  return response;
}

// 성적서
export const selectAllByGClientByG04docuForTest = (gclientId, g04docuId, gcomponentItemId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/query/selectAll/gclient/${gclientId}/g04docu/${g04docuId}/gcomponentItemId/${gcomponentItemId}/type/TEST`);
  const data = await response.json();
  dispatch(setGClientG04docuMapsForTest(data.gclientG04docuMapsForTest));
  return response;
}

// TODO : init을 따로 만들지 setGClientG04docuMapsForTest를 사용할지 검토
export const initializeByGClientByG04docuForCertification = () => async dispatch => {
  dispatch(initGClientG04docuMapsForCertification());
  return;
}

// TODO : init을 따로 만들지 setGClientG04docuMapsForTest를 사용할지 검토
export const initializeByGClientByG04docuForTest = () => async dispatch => {
  dispatch(initGClientG04docuMapsForTest());
  return;
}

export const selectAllByGClient = (gclientId) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuIds = dat.g04docuIds;
  console.log(g04docuIds)
  
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/query/selectAll/gclient/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ g04docuIds }),
  });

  const data = await response.json();
  dispatch(setGClientG04docuMaps(data.gclientG04docuMaps));
  return response;
}

// export const select = (id, gclientId, itemType) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/${id}/gclient/${gclientId}/itemType/${itemType}`);
//   const data = await response.json();
//   dispatch(setGClientG04docuMap(data.gclientG04docuMap));
//   return response;
// }

// 성적서의 경우 gcomponentItemId 필요하다 생각했으나 => documentPath가 있으므로 문제 안됨
export const remove = ({ gclientId, g04docuId, documentPath }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuMaps/gclient/${gclientId}/g04docu/${g04docuId}/g04docuPath/${encodeURIComponent(documentPath)}`, {
    method: "DELETE",
  });
  dispatch(removeGClientG04docuMap());
  return response;
};

const initialState = {
  gclientG04docuMap: null,
  gclientG04docuMaps: [],
  gclientG04docuMapsForCertification: [],
  gclientG04docuMapsForTest: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_MAPS:
      newState = Object.assign({}, state, { gclientG04docuMaps: initialState.gclientG04docuMaps });
      return newState;
    case SET_GCLIENT_G04_DOCU_MAP:
      newState = Object.assign({}, state, { gclientG04docuMap: action.payload });
      return newState;
    case SET_GCLIENT_G04_DOCU_MAPS:
      newState = Object.assign({}, state, { gclientG04docuMaps: action.payload });
      return newState;
    case INIT_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION:
      newState = Object.assign({}, state, { gclientG04docuMapsForCertification: initialState.gclientG04docuMapsForCertification });
      return newState;
    case INIT_GCLIENT_G04_DOCU_MAPS_FOR_TEST:
      newState = Object.assign({}, state, { gclientG04docuMapsForTest: initialState.gclientG04docuMapsForTest });
      return newState;
    case SET_GCLIENT_G04_DOCU_MAPS_FOR_CERTIFICATION:
      newState = Object.assign({}, state, { gclientG04docuMapsForCertification: action.payload });
      return newState;
    case SET_GCLIENT_G04_DOCU_MAPS_FOR_TEST:
      newState = Object.assign({}, state, { gclientG04docuMapsForTest: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
