// 아래는 구매전 테스트를 위한 임시 코드임
export const hideWatermark = async () => {
  var divTags = document.getElementsByTagName("div");
  // console.log(divTags)
  var searchText = "MUI X: Missing license key";
  var found;

  for (var i = 0; i < divTags.length; i++) {
    if (divTags[i].textContent === searchText) {
      found = divTags[i];
      if(found) {
        setTimeout(() => found.remove(), 300);
      }
      // break;
    }
  }

  // // TODO : 화면에서 refresh(F5)하면 이 부분에서 자주 에러 발생으로 Grid 렌더링 오류 발생. 보완 필요
  // if (found) {
  //   found.remove();
  //   // found.style.visibility = "hidden";
  // }
}