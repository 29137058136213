import { csrfFetch } from "./csrf.js";

const SET_GRECEPTION_DETAILS = 'greceptionDetail/setGReceptionDetailss';

const setGReceptionDetails = (greceptionDetails) => ({
  type: SET_GRECEPTION_DETAILS,
  payload: greceptionDetails,
});

export const selectAllByGreceptionId = (greceptionId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/greceptionDetails/greceptionId/${greceptionId}`);
  const data = await response.json();
  dispatch(setGReceptionDetails(data.greceptionDetails));
  return response;
}

export const selectAllByGreceptionIdDirect = async (greceptionId) => {
  const response = await csrfFetch(null, `/api/greceptionDetails/greceptionId/${greceptionId}`);
  const data = await response.json();
  return data.greceptionDetails;
}

export const selectAllByGreceptionIdByQueryDirect = async (greceptionId) => {
  const response = await csrfFetch(null, `/api/greceptionDetails/query/selectAll/greceptionId/${greceptionId}`);
  const data = await response.json();
  return data.greceptionDetails;
}

export const selectNoWorkOrder = async (greceptionId) => {
  const response = await csrfFetch(null, `/api/greceptionDetails/query/selectNoWorkOrder/greceptionId/${greceptionId}`);
  const data = await response.json();
  return data.greceptionDetails;
}

const initialState = {
  greceptionDetails: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GRECEPTION_DETAILS:
      newState = Object.assign({}, state, { greceptionDetails: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;