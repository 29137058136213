import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
// import TextMaskCustom from "../form/inputProps/TextMaskCustom"
import * as sessionActions from "../../store/session";
import * as mailActions from "../../store/mail";
import * as errorActions from "../../store/error";

// 아래 form components의 name과 연계
const defaultValues = {
  service: "",
  credential: "",
  email: "",
  password: "",
};

let appPasswordInputRef = null;

const services = [
  {
    label: "네이버", value: "NAVER", ico: "/naver.ico", host: "smtp.naver.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_네이버.pdf",
    steps: [
      { name: "1. 메일 계정 로그인", url: "https://nid.naver.com/nidlogin.login", tip: "먼저 네이버 메일에 로그인합니다." },
      { name: "2. 메일 서버 설정", url: "https://mail.naver.com/v2/settings/smtp", tip: "보내는 메일을 설정합니다." },
      { name: "3. 앱 비밀번호 생성", url: "https://nid.naver.com/user2/help/myInfoV2?m=viewSecurity&lang=ko_KR", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "다음", value: "DAUM", ico: "/daum.ico", host: "smtp.daum.net", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_다음, 카카오.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "다음 2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "3. 메일 서버 설정", url: "https://mail.daum.net/setting/POP3IMAP", tip: "보내는 메일을 설정합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "카카오", value: "KAKAO", ico: "/kakao.ico", host: "smtp.kakao.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_다음, 카카오.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "다음 2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "3. 메일 서버 설정", url: "https://mail.daum.net/setting/POP3IMAP", tip: "보내는 메일을 설정합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "지메일", value: "GMAIL", ico: "/gmail.ico", host: "smtp.gmail.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_지메일.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://myaccount.google.com/signinoptions/twosv", tip: "2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://myaccount.google.com/apppasswords", tip: "앱 비밀번호를 생성합니다." },
      { name: "3. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "네이버웍스", value: "NAVER_WORKS", ico: "/naver_works.ico", host: "smtp.worksmobile.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_네이버웍스.pdf",
    steps: [
      { name: "1. 네이버웍스 로그인", url: "https://common.worksmobile.com/proxy/my", tip: "로그인합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://common.worksmobile.com/security-settings/app-password", tip: "앱 비밀번호를 생성합니다." },
      { name: "3. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  // {
  //   label: "직접 설정하기", value: "MANUAL",
  // },
];

const EmailSettingDialog = ({
  type,
  open,
  setOpen,
  credential,
  email,
}) => {
  const setAppPasswordInputRef = element => {
    appPasswordInputRef = element;
  };

  const [errors, setErrors] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTest, setLoadingTest] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [manual, setManual] = useState(false);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  // const sessionUserLoginType = useSelector((state) => state.session.sessionUser.sessionUserLoginType);
  // const sessionUserRole = useSelector((state) => state.session.sessionUser.sessionUserRole);

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setLoading(false);
    setLoadingTest(false);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, getValues, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
  const setEmailDirect = ({ type, credential, mailSettings }) => sessionActions.setEmailDirect({ type, credential, mailSettings })
  const sendMailTestDrect = ({ mailSettings }) => mailActions.sendMailTestDrect({ mailSettings })

  const onSubmit = async ({ service, credential, email, password }) => {
    setErrors([]);
    
    if (service === 'MANUAL') {
      
      return;
    } else {
      // TODO : 추후 yup 등 사용할 것
      // console.log({ service, credential, email, password });
      if (!service) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "서비스를 선택해주세요.",
          open: true,
        });

        return;
      }

      if (!email) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "메일계정을 입력해주세요.",
          open: true,
        });

        return;
      }

      if (!password) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "앱 비밀번호를 입력해주세요.",
          open: true,
        });

        return;
      }
    }

    let { host, port, secure } = services.find(item => item.value === service);
    
    const mailSettings = {
      host,
      port,
      secure,
      auth: {
        user: email,
        pass: password,
      }
    };

    try {
      setLoading(true);
      
      const res = await sendMailTestDrect({ mailSettings });
      
      if (res && res.info) {
        const { accepted, response } = res.info;
        if (accepted && Array.isArray(accepted) && accepted.length === 1) {
          if (accepted[0] === email/* && response.indexOf()*/) { // TODO : response 메시지는 추후 서비스별로 체크해 볼 것
            await setEmailDirect({ type, credential, mailSettings }); // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
            
            await dispatch(sessionActions.restoreSessionUser());
            
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: "메일 설정이 완료되었습니다.",
              open: true,
            });

            setTimeout(() => {
              setLoading(false);
              handleDialogClose();
            }, 1000);
          }
        } else {
          // setAlertInfo({
          //   titleAlert: "안내",
          //   messageAlert: "메일 설정 테스트가 완료되지 않았습니다. 관리자에게 문의해주세요.",
          //   open: true,
          // });
          setLoading(false);
          dispatch(errorActions.occurError({ response: null, serverResponse: res })); // 오류 처리 방식 4
        }
      }
      // await setEmailDirect({ type, credential, mailSettings }); // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
      
      // await dispatch(sessionActions.restoreSessionUser());

      // setTimeout(() => {
      //   setLoading(false);
      //   handleDialogClose();
      // }, 1000);
      
    } catch (e) {
      // setAlertInfo({
      //   titleAlert: "안내",
      //   messageAlert: e.message,
      //   open: true,
      // });
      setLoading(false);
      dispatch(errorActions.occurError({ response: e.response, serverResponse: e.serverResponse })); // 오류 처리 방식 4
      
      // handleDialogClose();
    }
  }

  useEffect(
    () => {
      setValue("credential", credential);
      setValue("email", email);

      console.log(sessionUser)
      
      if (type === "GCLIENT") {
        const service = services.find(item => item.host === sessionUser.mailSettings?.host);
        setValue("service", service ? service.value : "");
        setSelectedService(service);
      } else if (type === "USER") {
        const { user } = sessionUser;
        if (user && Array.isArray(user) && user.length === 1) {
          const service = services.find(item => item.host === sessionUser.user[0].mailSettings?.host);
          setValue("service", service ? service.value : "");
          setSelectedService(service);
        }
      }
    }, [open]
  )

  useEffect(
    () => {
      if (selectedService?.value === 'MANUAL') {
        setManual(true);
      } else {
        setManual(false);
      }
    }, [selectedService]
  )

  // const handleClickTest = async () => {
  //   const service = getValues("service");
  //   const email = getValues("email");
  //   const password = getValues("password");
  //   console.log({ service, email, password });
  //   if (!service) {
  //     setAlertInfo({
  //       titleAlert: "안내",
  //       messageAlert: "서비스를 선택해주세요.",
  //       open: true,
  //     });

  //     return;
  //   }

  //   if (!email) {
  //     setAlertInfo({
  //       titleAlert: "안내",
  //       messageAlert: "메일계정을 입력해주세요.",
  //       open: true,
  //     });

  //     return;
  //   }

  //   if (!password) {
  //     setAlertInfo({
  //       titleAlert: "안내",
  //       messageAlert: "앱 비밀번호를 입력해주세요.",
  //       open: true,
  //     });

  //     return;
  //   }

  //   let { host, port, secure } = services.find(item => item.value === service);
  //   const mailSettings = {
  //     host,
  //     port,
  //     secure,
  //     auth: {
  //       user: email,
  //       pass: password,
  //     }
  //   };
    
  //   try {
  //     setLoadingTest(true);
      
  //     const res = await sendMailTestDrect({ mailSettings });
      
  //     // console.log(res)
  //     if (res && res.info) {
  //       const { accepted, response } = res.info;
  //       if (accepted && Array.isArray(accepted) && accepted.length === 1) {
  //         if (accepted[0] === email/* && response.indexOf()*/) { // TODO : response 메시지는 추후 서비스별로 체크해 볼 것
  //           handleSubmit(onSubmit)();
  //           // setConfirmOpen(true);
  //           // setAlertInfo({
  //           //   titleAlert: "안내",
  //           //   messageAlert: "메일 설정 테스트가 완료되었습니다. 반드시 설정버튼을 눌러 설정을 완료해주세요.",
  //           //   open: true,
  //           // });
  //         }
  //       } else {
  //         setAlertInfo({
  //           titleAlert: "안내",
  //           messageAlert: "메일 설정 테스트가 완료되지 않았습니다. 관리자에게 문의해주세요.",
  //           open: true,
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     setAlertInfo({
  //       titleAlert: "안내",
  //       messageAlert: e.message,
  //       open: true,
  //     });
  //   }

  //   setTimeout(() => setLoadingTest(false), 1000);
  // }

  // const setMailSettings = () => {
  //   handleSubmit(onSubmit)();
  // }

  const handleChangeType = (e) => {
    const selService = e.target.value;
    setValue("service", selService);
    
    setSelectedService(services.find(item => item.value === selService));
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"보내는 메일 설정"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {
                selectedService && selectedService.help && selectedService.steps && (
                  <Grid item xs={12} display="flex" alignItems="center">
                    <Stack direction="row">
                      <Link
                        href={selectedService.help}
                        underline="none"
                        target="_blank"
                        rel="noopener"
                      >
                        <Typography variant="subtitle2" display="block">
                          {
                            selectedService.label ? (
                              <Tooltip title="보내는 메일을 설정하는 방법을 보시려면 링크를 클릭해주세요.">
                                <Button variant="contained">{"설정 가이드"}</Button>
                              </Tooltip>
                            ) : ""
                          }
                        </Typography>
                      </Link>
                      {
                        Array.isArray(selectedService.steps) && (
                          selectedService.steps.map((step, i) => {
                            const { name, url, tip } = step;
                            if (url) {
                              return (
                                <Link
                                  href={url}
                                  underline="none"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <Typography variant="subtitle2" display="block"><Tooltip title={tip}>
                                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>{""}</span>
                                    <Button variant="outlined">
                                      {`${name}`}
                                    </Button>
                                  </Tooltip></Typography>
                                </Link>
                              )
                            } else {
                              return (
                                <Typography variant="subtitle2" display="block"><Tooltip title={tip}>
                                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>{""}</span>
                                  <Button variant="outlined" style={{textTransform: 'none'}} onClick={() => {
                                    appPasswordInputRef.focus();
                                  }}>
                                    {`${name}`}
                                  </Button>
                                </Tooltip></Typography>
                              )
                            }
                          })
                        )
                      }
                    </Stack>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <FormInputText
                  required
                  select
                  name={"service"}
                  control={control}
                  label={"서비스"}
                  options={services}
                  onChange={handleChangeType}
                  // value={details && details.length > i && details[i]}
                />
              </Grid>
              {
                manual === true ? (
                  <>
                    <Grid item xs={12}>
                      <FormInputText
                        // margin="normal"
                        required
                        fullWidth
                        name={"host"}
                        control={control}
                        label={"SMTP 서버"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        // margin="normal"
                        required
                        fullWidth
                        name={"port"}
                        control={control}
                        label={"포트"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        required
                        select
                        name={"secure"}
                        control={control}
                        label={"보안"}
                        options={[{ label: "TRUE", value: true }, { label: "FALSE", value: false }]}
                        // onChange={handleChangeType}
                        // value={details && details.length > i && details[i]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        // margin="normal"
                        required
                        fullWidth
                        name={"email"}
                        control={control}
                        label={"메일계정"}
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <FormInputText
                        // margin="normal"
                        required
                        fullWidth
                        name={"email"}
                        control={control}
                        label={"메일계정"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        // margin="normal"
                        required
                        fullWidth
                        name={"password"}
                        control={control}
                        label={"앱 비밀번호"}
                        type="password"
                        inputProps={{ // InputProps와는 다름
                          ref: setAppPasswordInputRef,
                        }}
                      />
                    </Grid>
                  </>
                )
              }
              
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>{"메일 설정"}</LoadingButton>
          {/* <LoadingButton loading={loadingTest} onClick={handleClickTest}>{"메일 설정"}</LoadingButton> */}
          {/* <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>{"설정"}</LoadingButton> */}
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      {/* <ConfirmDialog
        removeId={null}
        title={"메일 설정"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={setMailSettings}
        onCancel={() => {}}
      >
        {"메일 설정 테스트가 완료되었습니다. 이어서 메일 설정까지 완료하시겠습니까?"}
      </ConfirmDialog> */}
    </>
  );
};

export default EmailSettingDialog;
