import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Typography,

  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  List,
  Toolbar,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  ListItem,
  Tooltip,
  Stack,
  Chip,
  Tab,
  Tabs,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {
  PieChart,
} from '@mui/icons-material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import "./GPlan.css";
import {
  FormInputCheckbox,
  FormInputRadio,
  FormInputDate,
  FormInputDropdown,
} from "../form";
import {
  AlertDialog,
} from "../dialog";
import {
  hideWatermark,
  dateFormat,
  addDays,
  objectSortByKey,
} from "../../utils";

import * as glineActions from "../../store/gline";
import * as gplanActions from "../../store/gplan";
import * as gplanPlanningActions from "../../store/gplanPlanning";
import * as gprocessActions from "../../store/gprocess";
import * as gworkOrderActions from "../../store/gworkOrder";
import * as glineCapacityActions from "../../store/glineCapacity";

import GPlanCalendar from "./GPlanCalendar";
import GPlanStatus from "./GPlanStatus";
import GPlanStatus2 from "./GPlanStatus2";
import GPlanWorkOrderList from "./GPlanWorkOrderList";
import GPlanWorkOrderDialog from "./GPlanWorkOrderDialog";

const theme = createTheme();

const defaultValues = {
  gprocess: "",
  gprocessForStatus: "ALL", // defaultValues에 없는 값은 setValue 안됨. 이것 빼먹어서 setValue("gprocessForStatus") 안되서 고생했음
  selMonth: dateFormat(Date.now(), 'MM'),
  selWeek: "",
  selDay: dateFormat(Date.now(), 'yyyy-MM-dd'),
  selYear: dateFormat(Date.now(), 'yyyy'),
};

const defaultHeaderToolbar = {
  left: 'prevYear,prev,next,nextYear today',
  center: 'title',
}

const defaultHeaderRight = "dayGridMonth,dayGridWeek,listDay";
const resourceHeaderRight = "resourceDayGridMonth,resourceDayGridWeek,resourceDayGridDay";

const defaultHeaderRights = ["dayGridMonth", "dayGridWeek", "listDay"];
const resourceHeaderRights = ["resourceDayGridMonth", "resourceDayGridWeek", "resourceDayGridDay"];

const externalGPlans = [];

const alarms = [
  { color: "#87C58C", label: "여유" }, // 80% 미만
  { color: "#F7D57C", label: "주의" }, // 80% 이상
  { color: "#EC7A67", label: "경고" }, // 100% 이상
];

// TODO : 자주 사용한다면 공통으로 올릴 것
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => {
  return {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }
});

const drawerWidth = 600;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//   }),
// }));

// const AppBar = styled("MuiAppBar", {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//   }),
// }));
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  // flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginRight: drawerWidth,
    marginRight: `calc(30%)`,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GPlanManagement = () => {

  const gplanCalendarRef = useRef(); // 자식 함수 호출하기 위함(FullCalendar의 api 호출)

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedGProcessCode, setSelectedGProcessCode] = useState("ALL");
  // const [selectedGProcess, setSelectedGProcess] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [loadedDialog, setLoadedDialog] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [weeks, setWeeks] = useState([]);
  const [searchParamsForStatus, setSearchParamsForStatus] = useState({ // 검색용
    year: dateFormat(Date.now(), 'yyyy'),
    gprocessCode: "ALL",
    dateType: "month",
    dateValue: dateFormat(Date.now(), 'MM'),
  });
  const [searchParamsForStatus2, setSearchParamsForStatus2] = useState({ // GPlanStatus2 전달용
    year: dateFormat(Date.now(), 'yyyy'),
    gprocessCode: "ALL",
    dateType: "month",
    dateValue: dateFormat(Date.now(), 'MM'),
  });
  const [alertInfo, setAlertInfo] = useState({});
  const [gprocess, setGProcess] = useState("ALL");
  // const [gprocessCodeForStatus, setGProcessCodeForStatus] = useState("ALL");
  
  const gprocesses = useSelector((state) => state.gprocess.gprocesses);
  const statusByGLine = useSelector((state) => state.gline.statusByGLine);
  const glinesForStatus = useSelector((state) => state.gline.glinesForStatus);

  // const { control } = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // 데이터 관리
  const dispatch = useDispatch();

  const selectGProcesses = () => dispatch(gprocessActions.selectAll())
  const selectGWorkOrdersByStatusAndGProcess = ({ status, gprocess }) => dispatch(gplanPlanningActions.selectGWorkOrdersByStatusAndGProcess({ status, gprocess }))
  // const selectAll = () => dispatch(gplanActions.selectAll())
  // const selectAllWithinPeriod = (start, end) => dispatch(gplanActions.selectAllWithinPeriod(start, end))
  const selectByGProcess = (gprocess, start, end, calendar, status) => dispatch(gplanActions.selectByGProcess(gprocess, start, end, calendar, status))
  const selectByGProcessDirect = (gprocess, start, end, calendar, status) => gplanActions.selectByGProcessDirect(gprocess, start, end, calendar, status)
  const selectAllGLines = () => dispatch(glineActions.selectAllByQuery())
  const selectGLinesByGProcess = (gprocess) => dispatch(glineActions.selectByGProcessByQuery(gprocess))
  const selectGWorkOrdersByStatusByQuery = (status) => dispatch(gworkOrderActions.selectAllByStatusByQuery(status))
  const setStatusByGLine = (status) => dispatch(glineActions.setStatusByGLine(status))
  const setStatusInPeriod = (status) => dispatch(glineActions.setStatusInPeriod(status))
  const selectAllByGProcessDirectByQuery = (start, end) => glineCapacityActions.selectAllByGProcessDirectByQuery(start, end)
  const setCapacityByGProcess = (gcapacities) => dispatch(glineCapacityActions.setCapacityByGProcess(gcapacities));
  const selectAllByGLineDirectByQuery = (gprocessCode, start, end) => glineCapacityActions.selectAllByGLineDirectByQuery(gprocessCode, start, end)
  const selectAllByGLineWeeklyDirectByQuery = (gprocessCode, start, end) => glineCapacityActions.selectAllByGLineWeeklyDirectByQuery(gprocessCode, start, end)
  const setCapacityByGLine = (gcapacities) => dispatch(glineCapacityActions.setCapacityByGLine(gcapacities));

  useEffect(
    async () => {
      await selectGProcesses();

      setTimeout(() => setLoaded(true), 500);

      // TODO : 수정해야 함
      // let selYear = getValues('selYear');
      // setWeeksData(selYear);
      // setValue("gprocess", selectedGProcess?.code);
    }, [dispatch]
  );

  // useEffect(
  //   () => {
  //     // 우측의 현황 헤더를 공정 색상으로 바꾸기 위해 필요
  //     const selected = gprocesses.find(gprocess => gprocess.code === selectedGProcessCode);
  //     if (selected) {
  //       setSelectedGProcess(selected);
  //     } else {
  //       setSelectedGProcess(null);
  //     }
  //   }, [selectedGProcessCode]
  // );

  // useEffect(
  //   () => {
  //     console.log("111");
  //     // 첫 로딩시 기본값으로 생산현황을 보여주기 위함임
  //     if (getValues("gprocess") === undefined) {
  //       searchByGProcess("ALL");
  //     }
  //   }, [getValues("gprocess")]
  // )

  useEffect(
    () => {
      searchByGProcess(gprocess);
    // }, [gprocess, gprocesses] // TODO : gprocesses ???. 만일 지우면 문제 발생 소지 있음
  }, [gprocess]
  )

  // useEffect(
  //   () => {
  //     // setLoaded(true);
  //   }, [statusByGLine]
  // );

  // useEffect(
  //   () => {
  //     console.log(searchParamsForStatus)
  //   }, [searchParamsForStatus]
  // )

  const CalendarAPI = () => {
    if (gplanCalendarRef.current) {
      return gplanCalendarRef.current.getAPI();
    }

    return null;
  }

  const setWeeksData = (year) => {
    const firstDate = new Date(`${year}-01-01`);
    const lastDate = new Date(`${year}-12-31`);
    const firstWeek = firstDate.getWeek();
    const lastWeek = lastDate.getWeek();
    let firstDateFirstWeek;
    if (firstWeek > 0) {
      firstDateFirstWeek = firstDate;
    } else {
      firstDateFirstWeek = new Date(addDays(firstDate, 7));
    }

    let next = firstDateFirstWeek;
    const arrWeeks = [];
    for(let i=1; i<=lastWeek; i++) {
      arrWeeks.push({
        label: i === 1 ? `0${i}주차  ( ${firstDateFirstWeek.getWeekDates()[0]} ~ ${firstDateFirstWeek.getWeekDates()[1]} )` : `${i > 9 ? "" : "0"}${i}주차  ( ${next.getWeekDates()[0]} ~ ${next.getWeekDates()[1]} )`,
        value: i === 1 ? `${i}:${firstDateFirstWeek.getWeekDates()}` : `${i}:${next.getWeekDates()}`,
      });

      next = new Date(addDays(next, 7));
    }
    setWeeks(arrWeeks);
  }

  const setDataTypeAndRefresh = (value, calendar) => {
    const searchParams = setDateType(value, calendar);

    setShowStatus(false);
    searchStatus(searchParams);
    setShowStatus(true);
  }

  // 캘린더안에서 날짜관련 변경시 (handleDates)
  const setDateType = (value, calendar/* api */) => {
    setTabValue(value); // 탭선택
    
    // return;
    let dateType;
    let dateValue;
    if (value === 0) {
      dateType = "month";
      // dateValue = getValues("selMonth");
    } else if (value === 1) {
      dateType = "week";
      // dateValue = getValues("selWeek");
    } else if (value === 2) {
      dateType = "day";
      // dateValue = dateFormat(getValues("selDay"), "yyyy-MM-dd");
    }
    
    console.log(calendar)
    
    if (calendar) {
      const year = calendar.view.currentStart.getFullYear().toString();
      // alert(year)
      setValue("selYear", year);

      if (value === 0) {
        const month = calendar.view.currentStart.getMonth()+1;
        dateValue = `${" ".repeat(2 - month.length)}${month}`;
        setValue("selMonth", dateValue);
      } else if (value === 1) {
        dateValue = `${calendar.view.currentStart.getWeek()}:${calendar.view.currentStart.getWeekDates()}`;
        console.log(dateValue)
        setValue("selWeek", dateValue);
      } else if (value === 2) {
        dateValue = dateFormat(calendar.view.currentStart, "yyyy-MM-dd");
        setValue("selDay", dateValue);
      }
    } else {
      if (value === 0) {
        dateValue = getValues("selMonth");
      } else if (value === 1) {
        dateValue = getValues("selWeek");
      } else if (value === 2) {
        dateValue = dateFormat(getValues("selDay"), "yyyy-MM-dd");
      }
    }

    const newSearchParamsForStatus = {
      ...searchParamsForStatus,
      dateType,
      dateValue,
    }
    
    setSearchParamsForStatus(newSearchParamsForStatus);

    return newSearchParamsForStatus;
  }

  const handleChangeDateType = (event, newValue) => {
    
    // setTabValue(newValue);

    setDateType(newValue);
    // let dateType;
    // let dateValue;
    // if (newValue === 0) {
    //   dateType = "month";
    //   dateValue = getValues("selMonth");
    // } else if (newValue === 1) {
    //   dateType = "week";
    //   dateValue = getValues("selWeek");
    // } else if (newValue === 2) {
    //   dateType = "day";
    //   dateValue = dateFormat(getValues("selDay"), "yyyy-MM-dd");
    // }

    // setSearchParamsForStatus({
    //   ...searchParamsForStatus,
    //   dateType,
    //   dateValue,
    // });
  };

  useEffect(
    () => {
      // TODO : getValues("selYear")가 yyyy인지 iso string인지 검토 필요(현재는 변함)
      const year = getValues("selYear");
      console.log(year)
      if (year.length === 4) { // yyyy
        setWeeksData(year);
      } else {
        setWeeksData(dateFormat(new Date(year), 'yyyy'));
      }
      
    }, [getValues("selYear")]
  )

  const handleSelectYear = (value, name) => {
    // setWeeksData(value.getFullYear());

    
    const selectedDay = new Date(getValues("selDay"));
    
    let month = selectedDay.getMonth()+1;
    month = month.length <= 1 ? `0${month}` : month;
    
    let day = selectedDay.getDate();
    day = day.length <= 1 ? `0${day}` : day;

    const year = value.getFullYear();
    // alert(`${value.getFullYear()}-${selectedDay.getMonth()+1}-${selectedDay.getDate()}`)
    setValue("selDay", `${year}-${month}-${day}`);

    setSearchParamsForStatus({
      ...searchParamsForStatus,
      year,
    });

    return true; // true 리턴해야 값이 적용됨
  }

  // useEffect(
  //   () => {
  //     const gprocessCode = getValues('gprocessForStatus');
  //     alert(gprocessCode)
  //     if (gprocessCode) {
  //       setSearchParamsForStatus({
  //         ...searchParamsForStatus,
  //         gprocessCode,
  //       });
  //     }
  //   }, [getValues('gprocessForStatus')]
  // )

  // const handleChangeGProcessRadioButtonForStatus = async (event) => {
  const handleSelectGProcess = async (event) => {
    const gprocessCode = event.target.value;
    setSearchParamsForStatus({
      ...searchParamsForStatus,
      gprocessCode,
    });
    
    // setShowStatus(false);
  }

  const handleSelectMonth = async (e, oldValue) => {
    const dateValue = e.target.value;
    setSearchParamsForStatus({
      ...searchParamsForStatus,
      dateValue,
    });

    // setShowStatus(false);
  }

  const handleSelectWeek = async (e, oldValue) => {
    const dateValue = e.target.value;
    setSearchParamsForStatus({
      ...searchParamsForStatus,
      dateValue,
    });

    // setShowStatus(false);
  }

  const handleSelectDay = (value, name) => {
    const dateValue = dateFormat(value, "yyyy-MM-dd");
    setSearchParamsForStatus({
      ...searchParamsForStatus,
      dateValue,
    });

    // setShowStatus(false);

    return true; // true 리턴해야 값이 적용됨
  }

  // GLineCapacities를 날짜로 검색하므로 유효날짜에 포함되지 않은 날짜의 생산현황은 비워져 있게 된다.
  const searchStatus = async (params) => {
    console.log("searchStatus");
    console.log(searchParamsForStatus);

    const newSearchParamsForStatus = params ? params : searchParamsForStatus;
    
    // TODO : start, end 조건을 yyyy-MM-dd 형태로 바꿀 것을 검토 (API의 파라미터를 javascript Date객체로 하는 것은 호환성을 저하시킴)
    const { year, gprocessCode, dateType, dateValue } = newSearchParamsForStatus;

    // TODO : 필요값이 입력되지 않은 경우 안내 필요
    if (dateValue === "") {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "날짜 정보를 입력해주세요.",
        open: true,
      });
      
      return;
    }

    setLoaded(false);
    
    let start;
    let end;

    if (dateType === "month") {
      start = new Date(parseInt(year), parseInt(dateValue)-1, 1);
      end = new Date(parseInt(year), parseInt(dateValue), 0);
    } else if (dateType === "week") {
      const weekPeriod = dateValue.split(":")[1];
      const weekStartEnd = weekPeriod.split(",");
      start = new Date(weekStartEnd[0]);
      end = new Date(weekStartEnd[1]);
    } else if (dateType === "day") {
      start = new Date(dateValue);
      // end = new Date(addDays(new Date(dateValue), 1));
      end = start;
    }

    console.log(start)
    console.log(end)
    
    // return;

    // const gplansDirect = await selectByGProcessDirect(gprocessCode, dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'), false, true); // 캘린더에서 생산일정 필터링
    const gplansDirect = await selectByGProcessDirect(gprocessCode, start, end, false, true); // 캘린더에서 생산일정 필터링
    console.log(gplansDirect)
    console.log(glinesForStatus)
    
    // 상태 정보
    const eventsInPeriod = {};

    if (gprocessCode === "ALL") { // 전체 공정일 때

      console.log(gprocesses)
      gprocesses.forEach(gprocess => gplansDirect.forEach(event => {
          const { gworkOrderDetails } = event.extendedProps;
          // const amount = gworkOrderDetails?.map(item => item.amount).reduce((prev, curr) => prev + curr, 0); // 구하려는 합산값이 하나였다면 이처럼 구하는 것도 방법
          let amount = 0;
          let areaJa = 0;
          gworkOrderDetails.forEach(item => {
            amount += item.amount; // amount 숫자
            areaJa += Number(item.areaJa); // amount 문자
          });
          
          const { gprocessId, gprocessCode, resourceIds } = event.extendedProps
          if (gprocessId == gprocess.id && resourceIds?.length > 0) {
            if (eventsInPeriod[`${gprocessId}_${gprocessCode}`]) {
              eventsInPeriod[`${gprocessId}_${gprocessCode}`] = {
                amount: eventsInPeriod[`${gprocessId}_${gprocessCode}`].amount + amount,
                areaJa: eventsInPeriod[`${gprocessId}_${gprocessCode}`].areaJa + areaJa,
              };
            } else {
              eventsInPeriod[`${gprocessId}_${gprocessCode}`] = {
                amount,
                areaJa,
              };
            }
          }
        })
      );

      // console.log(eventsInPeriod);
      setStatusInPeriod(eventsInPeriod);

      // TODO : 위에서 start가 yyyy-MM-dd 형태로 바뀌면 아래의 dateFormat 제거할 것
      const gcapcities = await selectAllByGProcessDirectByQuery(dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'));
      // console.log(gcapcities);

      setCapacityByGProcess(gcapcities);
    } else { // 공정 선택시
      if (dateType === "week") {
        glinesForStatus.forEach(gline => gplansDirect.forEach(event => {
            // console.log(event)
            const { extendedProps, start } = event;
            const { gworkOrderDetails, resourceIds } = extendedProps;
            // const amount = gworkOrderDetails?.map(item => item.amount).reduce((prev, curr) => prev + curr, 0); // 구하려는 합산값이 하나였다면 이처럼 구하는 것도 방법
            let amount = 0;
            let areaJa = 0;
            gworkOrderDetails.forEach(item => {
              amount += item.amount; // amount 숫자
              areaJa += Number(item.areaJa); // amount 문자
            });
            
            if (resourceIds?.length > 0 && gline.id.toString() === resourceIds[0]) {
              eventsInPeriod[start] = eventsInPeriod[start] ? eventsInPeriod[start] : {};

              if (eventsInPeriod[start][gline.id]) {
                eventsInPeriod[start][gline.id] = {
                  amount: eventsInPeriod[start][gline.id].amount + amount,
                  areaJa: eventsInPeriod[start][gline.id].areaJa + areaJa,
                };
              } else {
                eventsInPeriod[start][gline.id] = {
                  amount,
                  areaJa,
                };
              }
            }
          })
        );
      
        // const newEventsByGLine = objectSortByKey(eventsByGLine); // 키(날짜)로 정렬
        // console.log(newEventsByGLine);
        console.log(eventsInPeriod);
        setStatusInPeriod(eventsInPeriod);

        // TODO : 위에서 start가 yyyy-MM-dd 형태로 바뀌면 아래의 dateFormat 제거할 것
        const gcapcities = await selectAllByGLineWeeklyDirectByQuery(gprocessCode, dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'));
        console.log(gcapcities);

        setCapacityByGLine(gcapcities);
      } else {
        glinesForStatus.forEach(gline => gplansDirect.forEach(event => {
            const { gworkOrderDetails } = event.extendedProps;
            // const amount = gworkOrderDetails?.map(item => item.amount).reduce((prev, curr) => prev + curr, 0); // 구하려는 합산값이 하나였다면 이처럼 구하는 것도 방법
            let amount = 0;
            let areaJa = 0;
            gworkOrderDetails.forEach(item => {
              amount += item.amount; // amount 숫자
              areaJa += Number(item.areaJa); // amount 문자
            });
            
            const { resourceIds } = event.extendedProps;
            if (resourceIds?.length > 0 && gline.id.toString() === resourceIds[0]) {
              if (eventsInPeriod[gline.id]) {
                eventsInPeriod[gline.id] = {
                  amount: eventsInPeriod[gline.id].amount + amount,
                  areaJa: eventsInPeriod[gline.id].areaJa + areaJa,
                };
              } else {
                eventsInPeriod[gline.id] = {
                  amount,
                  areaJa,
                };
              }
            }
          })
        );
      
        // const newEventsByGLine = objectSortByKey(eventsByGLine); // 키(날짜)로 정렬
        // console.log(newEventsByGLine);
        console.log(eventsInPeriod);
        setStatusInPeriod(eventsInPeriod);

        // TODO : 위에서 start가 yyyy-MM-dd 형태로 바뀌면 아래의 dateFormat 제거할 것
        const gcapcities = await selectAllByGLineDirectByQuery(gprocessCode, dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'));
        console.log(gcapcities);

        setCapacityByGLine(gcapcities);
      }
    }

    setTimeout(() => setLoaded(true), 500);

    // setGProcessCodeForStatus(gprocessCode);
    console.log(newSearchParamsForStatus)
    setSearchParamsForStatus2(newSearchParamsForStatus);

    setShowStatus(true);
  }
  const handleClickSearch = async () => {
    searchStatus();
  }

  const searchByGProcess = async (gprocessCode) => {
    setOpenBackdrop(true);
    // setLoaded(false);
    // 캘린더 상의 이벤트 초기화(지우기) => 깜빡임 개선을 위해 새로 추가된 이벤트만 지우도록 함
    // CalendarAPI().removeAllEvents();
    externalGPlans.forEach(e => e.remove());
    externalGPlans.splice(0, externalGPlans.length);

    const gprocess = gprocessCode;

    // TODO : 추후 아래 쿼리 사용안함
    // 작업의뢰건을 캘린더로 옮기면(drag & drop) 아직 DB에는 반영이 안된 상태인데 아래처럼 공정에 따라 DB에서 조회하면 반영된 건도 다시 조회되고 캘린더에서 dnd 한건 필터링이 안됨 => 처리 필요
    // => 모았다가 저장하는 확정하기 방식이 아니라 즉각 저장 방식이면 저장될 때 작업의뢰건의 상태를 'ASSIGN'로 설정하면 해결됨 => 즉각 저장하는 방식이므로 매번 조회할 필요없으므로 꼭 옮길 것(2022-11-18)
    await selectGWorkOrdersByStatusAndGProcess({ status: 'PRE', gprocess });
    
    // TODO : await를 빼면 useEffect([selectedGProcessCode])에서 이벤트가 없음. 그러나 await를 하더라도 이벤트가 없을 경우가 있다면 evaluateCapacity을 하기 위해 events를 직접 구성하는 방법도 고려
    const start = CalendarAPI()?.view.activeStart;
    const end = CalendarAPI()?.view.activeEnd;
    if (gprocess === "ALL") {
      await selectAllGLines(true, true);
    } else {
      await selectGLinesByGProcess(gprocess, true, true); // 캘린더에서 리소스(공정라인) 필터링
    }
    
    // await selectByGProcess(gprocess, dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'), true, true); // 캘린더에서 생산일정 필터링
    await selectByGProcess(gprocess, start, end, true, true); // 캘린더에서 생산일정 필터링

    setTimeout(() => setOpenBackdrop(false), 300);

    // TODO : refresh 처럼 분리하면 현황 부분 필터링에 문제가 있어 수정. 정확한 원인 파악 필요
    // if (gprocess === "ALL") {
    //   await selectAllGLines();
    // } else {
    //   await selectGLinesByGProcess(gprocess); // 캘린더에서 리소스(공정라인) 필터링
    // }

    setSelectedGProcessCode(gprocess);
    
    setValue("gprocessForStatus", gprocess);

    const newSearchParamsForStatus = {
      ...searchParamsForStatus,
      gprocessCode: gprocess,
    }
    
    setSearchParamsForStatus(newSearchParamsForStatus);
    
    setShowStatus(false);
    searchStatus(newSearchParamsForStatus);
    setShowStatus(true);
    
    // setLoaded(true);
  }

  // const handleChangeGProcessRadioButton = async (e) => {
  //   searchByGProcess(e.target.value);
  // }
  const handleChangeGProcess = async (e) => {
    // const { value } = e.target;
    setGProcess(e.target.value);

    // searchByGProcess(value);
  }

  const refresh = async () => {
    externalGPlans.forEach(event => event.remove());
    externalGPlans.splice(0, externalGPlans.length);

    // 캘린더 UI 상에서의 동작으로 이벤트에 변경이 있을 때 아래와 같이 <FullCalendar>의 events={gplans} 변경시 일시적으로 이벤트가 중복됨 => Backdrop을 이용하여 현상을 일부 막음
    setOpenBackdrop(true);
    
    // 약간의 속도개선(UI상 매끄러움)을 위해 Backdrop을 사이에 두고 두개의 조회 블럭으로 분리
    const start = CalendarAPI()?.view.activeStart;
    const end = CalendarAPI()?.view.activeEnd;

    // if (selectedGProcessCode === "ALL") {
    //   // await selectAll(); // 참고로 await가 없으면 깜박임 현상 있음
    //   await selectAllWithinPeriod(start, end); // 생산일정 조회
    // } else {
    //   await selectByGProcess(selectedGProcessCode);
    // }
    
    // await selectByGProcess(selectedGProcessCode, dateFormat(start, 'yyyy-MM-dd'), dateFormat(end, 'yyyy-MM-dd'), true, true);
    await selectByGProcess(selectedGProcessCode, start, end, true, true);

    setTimeout(() => setOpenBackdrop(false), 300);

    if (selectedGProcessCode === "ALL") {
      await selectAllGLines(true, true);
    } else {
      await selectGLinesByGProcess(selectedGProcessCode, true, true);
    }
  }

  const handleChangeGLineCheckButton = (event, checked) => {
    const currentView = CalendarAPI().view.type;
    if (checked) {
      // useState => calendar api 사용으로 변경
      // setHeaderToolbar(
      //   {
      //     left: 'prevYear,prev,next,nextYear today',
      //     center: 'title',
      //     right: 'resourceDayGridMonth,resourceDayGridWeek,resourceDayGridDay establishButton'
      //   }
      // );
      CalendarAPI().setOption('headerToolbar', {
        ...defaultHeaderToolbar,
        right: resourceHeaderRight,
      });
      
      const result = defaultHeaderRights.findIndex(view => view === currentView);
      if (result >= 0) {
        CalendarAPI().changeView(resourceHeaderRights[result]);
      }
    } else {
      CalendarAPI().setOption('headerToolbar', {
        ...defaultHeaderToolbar,
        right: defaultHeaderRight,
      });

      const result = resourceHeaderRights.findIndex(view => view === currentView);
      if (result >= 0) {
        CalendarAPI().changeView(defaultHeaderRights[result]);
      }
    }
  }

  const handleClickOpen = async () => {
    setOpen(true);
    setLoadedDialog(false);

    await selectGWorkOrdersByStatusByQuery('PRE'); // 미할당 작업의뢰 조회
      
    await hideWatermark();
    // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
    setShow(true);
    setTimeout(() => setLoadedDialog(true), 300);
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);

    refresh();
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);

    refresh();
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GPlanWorkOrderDialog
          open={open}
          setOpen={setOpen}
          show={show}
          loaded={loadedDialog}
          refresh={refresh}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
          <Main open={openDrawer}>
            <Grid container spacing={2}>
              <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
                {/* <FormInputRadio
                  row
                  name={"gprocess"}
                  control={control}
                  options={
                    [{ label: "전체", value: "ALL", color: 'black' }].concat(gprocesses.map(gprocess => {
                      const { code, name, color } = gprocess;
                      return {
                        label: name,
                        value: code,
                        color,
                      }
                    }))
                  }
                  onChangeButton={handleChangeGProcessRadioButton}
                  defaultValue={"ALL"}
                /> */}
                <ToggleButtonGroup
                  color="primary"
                  value={gprocess}
                  exclusive
                  onChange={handleChangeGProcess}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value={"ALL"} sx={{ fontWeight: 'bold' }}>{"전체"}</ToggleButton>
                  {
                    gprocesses.map(gprocess => {
                      const { code, name, color } = gprocess;
                      return (
                        <ToggleButton value={code} sx={{ color: color, fontWeight: 'bold' }}>{name}</ToggleButton>
                      );
                    })
                  }
                </ToggleButtonGroup>
                <FormInputCheckbox
                  name="viewGlines"
                  control={control}
                  onChangeCheckValue={handleChangeGLineCheckButton}
                />
                <Typography>{"공정라인보기"}</Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  {"미할당 작업의뢰목록"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(openDrawer && { display: 'none' }), ml: 1, fontWeight: 'bold' }}
                  endIcon={<ChevronLeftIcon />}
                >
                  {/* <MenuIcon /> */}
                  {"현황보기"}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
              {/* <Grid item xs={9}> */}
                {/* 중앙의 달력 */}
                <GPlanCalendar
                  defaultHeaderRight={defaultHeaderRight}
                  resourceHeaderRights={resourceHeaderRights}
                  ref={gplanCalendarRef}
                  alarms={alarms}
                  externalGPlans={externalGPlans} // 깜빡임 개선을 위해 새로 추가된 이벤트만 지우도록 하는데 사용
                  selectedGProcessCode={selectedGProcessCode}
                  refresh={refresh}
                  setExternalDateTypeAndRefresh={setDataTypeAndRefresh}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <GPlanStatus
                  alarms={alarms}
                  selectedGProcess={selectedGProcess}
                />
              </Grid> */}
            </Grid>
          </Main>
          <Drawer
            sx={{
              // width: drawerWidth,
              width: `calc(30%)`,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                // width: drawerWidth,
                width: `calc(30%)`,
              },
            }}
            variant="persistent"
            anchor="right"
            open={openDrawer}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              {/* <Box sx={{ width: '100%', height: '80%', borderRadius: '5px', bgcolor: "#2C3E50", textAlign: 'middle' }}> */}
                {/* <Typography variant="subheader1"> */}
                  <Grid container sx={{ borderRadius: '5px', height: '80%', color: 'white', bgcolor: /*"#2C3E50"*/"#9c27b0", pr: 2 }}>
                    <Grid item xs={/*9*/12} display="flex" justifyContent="center" alignItems="center">
                      {/* {selectedGProcess?.name ?  `${selectedGProcess?.name} 현황` : "전체 현황"} */}
                      {"생산설비 가동률"}
                      {/* <PieChart sx={{ ml: 1 }} /> */}
                    </Grid>
                    {/* <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="center" sx={{ fontSize: '0.8em' }}>
                      <Stack direction="row" spacing={1}>
                        <Chip label={`수주량`} size="small" sx={{ bgcolor: '#80d8ff' }} />
                        <Chip label={`전체용랑`} size="small" sx={{ bgcolor: '#84ffff' }} />
                        <Chip label={`가용량`} size="small" sx={{ fontWeight: 'bold', bgcolor: '#a7ffeb' }} />
                      </Stack>
                    </Grid> */}
                  </Grid>
                {/* </Typography> */}
              {/* </Box> */}
            </DrawerHeader>
            <Divider />
            {/* 우측의 현황 */}
            <Box sx={{ width: '100%', p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormInputDate
                    name="selYear"
                    control={control}
                    label={"연도선택"}
                    onChangePeriodValue={(value) => handleSelectYear(value, "selYear")}
                    value={getValues("selYear")}
                    setValue={setValue}
                    views={['year']}
                    inputFormat={'yyyy'}
                    mask={'____'}
                  />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"flex-end"}>
                  {/* <FormInputRadio
                    row
                    name={"gprocessForStatus"}
                    control={control}
                    options={
                      [{ label: "전체", value: "ALL", color: 'black' }].concat(gprocesses.map(gprocess => {
                        const { code, name, color } = gprocess;
                        return {
                          label: name,
                          value: code,
                          color,
                        }
                      }))
                    }
                    // onChangeButton={handleChangeGProcessRadioButtonForStatus}
                    defaultValue={"ALL"}
                  /> */}
                  <FormInputDropdown
                    name="gprocessForStatus"
                    control={control}
                    label={"공정선택"}
                    options={
                      [{ label: "전체", value: "ALL", color: 'black' }].concat(gprocesses.map(gprocess => {
                        const { code, name, color } = gprocess;
                        return {
                          label: name,
                          value: code,
                          color,
                        }
                      }))
                    }
                    onChangeSelectValue={handleSelectGProcess}
                    // setValue={setValue}
                    // defaultValue={"ALL"} // TODO : ???
                    // disabled={(modify || !objectEmptyCheck(gproject)) ? true : false}
                    // inputProps={{
                    //   // id: "demo-controlled-open-select",
                    //   ref: setSelectProjectInputRef
                    // }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: '100%', pl: 2, pr: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeDateType} aria-label="basic tabs example">
                  <Tab label={"월간"} {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
                  <Tab label={"주간"} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
                  <Tab label={"일일"} {...a11yProps(2)} sx={{ fontWeight: 'bold' }} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormInputDropdown
                      name="selMonth"
                      control={control}
                      label={"월선택"}
                      options={ ["1","2","3","4","5","6","7","8","9","10","11","12"].map(item => ({ label: `${item}월`, value: item })) }
                      onChangeSelectValue={handleSelectMonth}
                      // setValue={setValue}
                      // disabled={(modify || !objectEmptyCheck(gproject)) ? true : false}
                      // inputProps={{
                      //   // id: "demo-controlled-open-select",
                      //   ref: setSelectProjectInputRef
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClickSearch}
                    >
                      {"조회"}
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormInputDropdown
                      name="selWeek"
                      control={control}
                      label={"주차선택"}
                      options={ weeks }
                      onChangeSelectValue={handleSelectWeek}
                      // disabled={(modify || !objectEmptyCheck(gproject)) ? true : false}
                      // inputProps={{
                      //   // id: "demo-controlled-open-select",
                      //   ref: setSelectProjectInputRef
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClickSearch}
                    >
                      {"조회"}
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormInputDate
                      name="selDay"
                      control={control}
                      label={"일일"}
                      value={getValues("selDay")}
                      onChangePeriodValue={(value) => handleSelectDay(value, "selDay")}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClickSearch}
                    >
                      {"조회"}
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
            <Box sx={{ p: 1 }}>
            {
              !loaded && (
                <Box sx={{ width: '100%' }}>
                  <BorderLinearProgress />
                </Box>
              )
            }
            {
              loaded && showStatus && (
                <GPlanStatus2
                  alarms={alarms}
                  // selectedGProcess={selectedGProcess}
                  searchParamsForStatus2={searchParamsForStatus2}
                  // gprocessCodeForStatus={gprocessCodeForStatus}
                />
              )
            }
            </Box>
          </Drawer>
        </Box>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
      </Container>
    </ThemeProvider>
  );
};

export default GPlanManagement;
