import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as sessionActions from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  LockOutlined,
  Login,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  // FormInputDate,
  // FormInputDropdown,
  // FormInputMultipleCheckbox,
  // FormInputRadio,
  // FormInputSlider,
  FormInputCheckbox,
  FormInputText,
} from "../../components/form";
import PasswordChangeDialog from "../Password/PasswordChangeDialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";
import * as mailActions from "../../store/mail";
import Copyright from "../layout/Copyright";

let passwordInputRef = null;

// 아래 form components의 name과 연계
const defaultValues = {
  credential: "",
  password: "",
  loginType: true,
};

const theme = createTheme();

// export default function SignIn() {
const SignIn = () => {
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchCredential = queryStrings["c"];
  const searchPassword = queryStrings["p"];
  const searchType = queryStrings["t"];
  const searchStatus = queryStrings["s"];
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
   const { handleSubmit, getValues, setValue, control } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  const location = useLocation();
  // console.log("location");
  // console.log(location.state.from);
  const sessionUser = useSelector((state) => state.session.sessionUser);

  // const sendMailDrect = ({ aaa }) => mailActions.sendMailDrect({ aaa })
  // const initPasswordDirect = ({ credential, type }) => sessionActions.initPasswordDirect({ credential, type })

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error, setError] = useState(null);
  const [logging, setLogging] = useState(false);
  const [type, setType] = useState("GCLIENT");
  const [saveId, setSaveId] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const signinButtonRef = useRef(null);

  const setPasswordInputRef = element => {
    passwordInputRef = element;
  };

  useEffect(
    () => {
      if (searchCredential || searchPassword || searchType) {
        setValue("credential", searchCredential);
        setValue("password", searchPassword);
        setValue("loginType", searchType === 'GCLIENT' ? true : false);
        setType(searchType);

        if (searchCredential && searchPassword) {
          setDisabled(false);
        }
      } else {
        const id = localStorage.getItem("credential");
        const isSave = localStorage.getItem("saveId");
        isSave && setSaveId(true);
        id && setValue("credential", id);
        
        if (isSave && id) {
          passwordInputRef.focus();
        }

        const isGClient = localStorage.getItem("loginType");
        // typeof isGClient === 'string'
        if (!JSON.parse(isGClient)) {
          setType("USER");
          setValue("loginType", false);
        } else {
          setType("GCLIENT");
          setValue("loginType", true);
        }
      }
    }, [dispatch]
  )

  if (sessionUser) {
    console.log("LoginFormPage/index sessionUser exists.");
    console.log(location);
    return <Navigate to={location.state && location.state.from ? `${location.state.from}` : "/"} />; // TODO : state 필요없는지 확인 필요
  } else {
    console.log("LoginFormPage/index no sessionUser exists.");
  }
  
  // React Hook "useEffect" is called conditionally. React Hooks must be called in the exact same order in every component render  react-hooks/rules-of-hooks
  // 오류로 위로 올림
  // useEffect(
  //   () => {
  //     const id = localStorage.getItem("C");
  //     const isSave = localStorage.getItem("saveId");
  //     if (isSave && id) {
  //       setSaveId(true);
  //       setValue("credential", id);
  //     }
  //   }, [dispatch]
  // )

  const onSubmit = ({ credential, password }) => {
    
    setError(null);

    setLogging(true);

    // console.log({ credential, password, type });
    // return;

    const status = searchStatus ? "등록완료" : ""
    return dispatch(sessionActions.login({ credential, password, type, status }))
      .then(res => {
        setLogging(false);

        if (saveId) {
          localStorage.setItem("saveId", true);
          localStorage.setItem("credential", credential);
        }
      })
      .catch(async (e) => {
        setLogging(false);
        
        // const data = await res.json();
        // if (data && data.errors) setErrors(data.errors);
        // if (res && res.errors) {
        //   setErrors(res.errors);
        // }
        console.log(e)
        setError(e);
        setFadeIn(true);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      signinButtonRef.current.click();
    }
  }

  const handleChangeCheckValue = (e, checked) => {
    if (checked) {
      setType("GCLIENT");
      localStorage.setItem("loginType", true);
    } else {
      setType("USER");
      localStorage.setItem("loginType", false);
    }

    localStorage.setItem("credential", "");
    setValue("credential", "");
  }

  const isNumeric = n => !isNaN(n);

  // const handleBlurCredential = () => {
  //   // 숫자로만 10자로 이루어진 회사명은 없다는 가정하에 편의성을 위해 사업자등록번호에 자동 하이픈(-) 부여
  //   const credential = getValues("credential");
  //   if (isNumeric(credential) && credential.length === 10) {
  //     const newCredential = `${credential.substring(0, 3)}-${credential.substring(3, 5)}-${credential.substring(5)}`;
  //     setValue("credential", newCredential);
  //   }
  // }

  const handleClickSaveId = () => {
    setSaveId(!saveId);

    localStorage.removeItem("saveId");
    localStorage.removeItem("credential");
  }

  // const handleClickSendMail = () => {
  //   // sendMailDrect({ aaa: "" });
  //   initPasswordDirect({ credential: getValues("credential"), type });
  // }

  const handleClickPasswordChange = () => {
    setOpen(true);
    setMode('I');
  }

  const handleChange = () => {
    setFadeIn(false);
    if (getValues("credential") && getValues("password")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // setErrors([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlined /></Avatar>
          <Typography component="h1" variant="h5">{"로그인"}</Typography>
          {/* TODO : 에러 표출 방식 검토해 볼 것 */}
          {/* <ul>{ errors.map((error, idx) => (<li key={idx}>{error}</li>)) }</ul> */}
          <Grid display="flex" alignItems="end" sx={{ height: 50 }}>
            <Fade in={fadeIn}>
              <Typography sx={{ color: "#ff5722" }}>
                { error?.message }
              </Typography>
            </Fade>
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Grid container>
              <Grid item xs={12} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                {
                  type === 'GCLIENT' ? (
                    <Tooltip title={"회원사로 로그인합니다."}><Typography>{"회원사"}</Typography></Tooltip>
                  ) : (
                    <Tooltip title={"회원사 사용자로 로그인합니다."}><Typography>{"회원사"}</Typography></Tooltip>
                  )
                }
                <FormInputCheckbox
                  name={"loginType"}
                  control={control}
                  onChangeCheckValue={handleChangeCheckValue}
                />
              </Grid>
            </Grid>
            {
              type === 'GCLIENT' ? (
                // <FormInputText
                //   margin="normal"
                //   required
                //   fullWidth
                //   name={"credential"}
                //   control={control}
                //   label={"사업자등록번호"}
                //   size={"medium"}
                //   autoComplete="credential"
                //   autoFocus
                //   onBlur={() => handleBlurCredential()}
                // />
                <FormInputText
                  margin="normal"
                  autoComplete="bizRegNumber"
                  name={"credential"}
                  control={control}
                  required
                  fullWidth
                  label={"회원사 아이디(사업자등록번호)"}
                  autoFocus
                  size={"medium"}
                  InputProps={{
                    inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                    inputProps: {
                      style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                        mask: "000-00-00000",
                        definitions: {
                          '#': /[1-9]/,
                        },
                      },
                    },
                  }}
                  onCustomChange={handleChange}
                />
              ) : (
                <FormInputText
                  margin="normal"
                  autoComplete="userId"
                  name={"credential"}
                  control={control}
                  required
                  fullWidth
                  label={"회원사 사용자 아이디(이메일)"}
                  autoFocus
                  size={"medium"}
                  // InputProps={{
                  //   inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                  //   inputProps: {
                  //     style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                  //       mask: "000-0000-0000",
                  //       definitions: {
                  //         '#': /[1-9]/,
                  //       },
                  //     },
                  //   },
                  // }}
                  onCustomChange={handleChange}
                />
              )
            }
            <FormInputText
              margin="normal"
              required
              fullWidth
              name={"password"}
              control={control}
              label={"비밀번호"}
              size={"medium"}
              type="password"
              autoComplete="current-password"
              onKeyPress={handleKeyPress}
              inputProps={{ // InputProps와는 다름
                ref: setPasswordInputRef,
              }}
              onCustomChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox control={control} checked={saveId} color="primary" onClick={handleClickSaveId} />}
              label="아이디 저장"
            />
            {/* <Button
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant={logging ? "outlined" : "contained"}
              sx={{ mt: 3, mb: 2 }}
              ref={signinButtonRef}
            >
              Sign In
            </Button> */}
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant={"contained"}
              sx={{ mt: 3, mb: 2 }}
              ref={signinButtonRef}
              loading={logging}
              loadingPosition="start"
              disabled={disabled}
              // startIcon={<Login />}
            >
              {"로그인"}
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleClickPasswordChange}>
                  {"비밀번호 초기화"}
                </Link>
              </Grid>
              <Grid item>
                {
                  type === "GCLIENT" ? (
                    <Link href={"/glass/signup/gclient"} variant="body2">{"회원사 가입"}</Link>
                  ) : (
                    <Link href={"/glass/signup/guser"} variant="body2">{"회원사 사용자 가입"}</Link>
                  )
                }
              </Grid>
              {/* <Grid item xs>
                <Button onClick={handleClickSendMail}>
                  {"메일 보내기 테스트"}
                </Button>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <PasswordChangeDialog
          open={open}
          setOpen={setOpen}
          mode={mode}
          setMode={setMode}
          setCheckValue={setValue}
          type={type}
          // setType={setType}
          credential={getValues("credential")}
        />
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;