import React from "react";
// import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  // MenuItem,
} from '@mui/material';

const App = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      select: {}
    }
  });
  const onSubmit = data => console.log(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => {
          console.log(field)
        return <TextField {...field} />
      }}
      />
      {/* <Controller
        name="select"
        control={control}
        render={({ field }) => <Select 
          {...field} 
          options={[
            { value: "chocolate", label: "Chocolate" },
            { value: "strawberry", label: "Strawberry" },
            { value: "vanilla", label: "Vanilla" }
          ]} 
        />}
      /> */}
      <input type="submit" />
    </form>
  );
};


export default App;