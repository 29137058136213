// import { csrfFetch } from "./csrf.js";

const SET_MENU = 'menu/setMenu';
const ADD_MENU = 'menu/addMenu';
const REMOVE_MENU = 'menu/removeMenu';
const REMOVE_ALL_MENUS = 'menu/removeAllMenus';

const setMenu = (menu) => ({
  type: SET_MENU,
  payload: menu,
});

const addMenu = (menu) => ({
  type: ADD_MENU,
  payload: menu,
});

const removeMenu = (menu) => ({
  type: REMOVE_MENU,
  payload: menu,
});

const removeAllMenus = () => ({
  type: REMOVE_ALL_MENUS,
});

export const setSelectedMenu = (menu) => async dispatch => {
  dispatch(setMenu(menu));
};

export const addTabMenu = (menu) => async dispatch => {
  dispatch(addMenu(menu));
};

export const removeTabMenu = (menu) => async (dispatch) => {
  await dispatch(removeMenu(menu));
};

export const removeAllTabMenus = (menu) => async (dispatch) => {
  dispatch(removeAllMenus());
};

const initialState = {
  menu: {},
  menus: [],
  selectedMenusByOrder: [],
  selectedMenuInfo: {},
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_MENU:
      console.log(action.payload)
      newState = Object.assign({}, state, { selectedMenuInfo: action.payload });
      return newState;
    case ADD_MENU:
      // 열려 있는 탭메뉴
      let find = false;
      
      let tabMenus = JSON.parse(sessionStorage.getItem('tabMenus'));
      if (!tabMenus) {
        tabMenus = [];
      }
      
      tabMenus.forEach(tabMenu => {
        if (tabMenu.id === action.payload.id) {
          find = true;
          return;
        }
      })
      if (!find && Object.keys(action.payload).length > 0) {
        tabMenus.push(action.payload);
      }

      sessionStorage.setItem('tabMenus', JSON.stringify(tabMenus));
      
      // 선택한 순서
      let selectedMenusByOrder = JSON.parse(sessionStorage.getItem('selectedMenusByOrder'));
      if (!selectedMenusByOrder) {
        selectedMenusByOrder = [];
      }
      
      let index = -1;
      selectedMenusByOrder.forEach((tabMenu, i) => {
        if (tabMenu.id === action.payload.id) {
          index = i;
          return;
        }
      })
      
      if (index >= 0) {
        selectedMenusByOrder.splice(index, 1);
      }

      selectedMenusByOrder.push(action.payload);

      sessionStorage.setItem('selectedMenusByOrder', JSON.stringify(selectedMenusByOrder));

      newState = Object.assign({}, state, { menus: tabMenus, selectedMenusByOrder: selectedMenusByOrder });
      
      return newState;
    case REMOVE_MENU:
      // 열려 있는 탭메뉴
      const newMenus = state.menus.filter((menu) => menu.id !== action.payload.id);
      
      sessionStorage.setItem('tabMenus', JSON.stringify(newMenus));

      // 선택한 순서
      const newSelectedMenusByOrder = state.selectedMenusByOrder.filter((menu) => menu.id !== action.payload.id);
      
      sessionStorage.setItem('selectedMenusByOrder', JSON.stringify(newSelectedMenusByOrder));

      newState = Object.assign({}, state, { menus: newMenus, selectedMenusByOrder: newSelectedMenusByOrder });
      return newState;
    case REMOVE_ALL_MENUS:
      sessionStorage.removeItem('tabMenus');
      sessionStorage.removeItem('selectedMenusByOrder');
      newState = Object.assign({}, state, { menus: [], selectedMenusByOrder: [] });
      return newState;
    default:
      return state;
  }
}

export default reducer;
