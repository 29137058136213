// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GLINE = 'gline/setGLine';
const REMOVE_GLINE = "gline/removeGLine";
const SET_GLINES = 'gline/setGLines';
const SET_GLINES_FOR_STATUS = 'gline/setGLinesForStatus';
const SET_STATUS_BY_GLINES = 'gline/setStatusByGLines';
const SET_STATUS_PERIOD = 'gline/setStatusPeriod';
const SET_STATUS_BY_GLINES_ALL = 'gline/setStatusByGLinesAll';

const setGLine = (gline) => ({
  type: SET_GLINE,
  payload: gline,
});

const removeGLine = () => ({
  type: REMOVE_GLINE,
});

const setGLines = (glines) => ({
  type: SET_GLINES,
  payload: glines,
});

const setGLinesForStatus = (glinesForStatus) => ({
  type: SET_GLINES_FOR_STATUS,
  payload: glinesForStatus,
});

const setStatus = (status) => ({
  type: SET_STATUS_BY_GLINES,
  payload: status,
});

const setStatusPeriod = (status) => ({
  type: SET_STATUS_PERIOD,
  payload: status,
});

const setStatusAll = (statusAll) => ({
  type: SET_STATUS_BY_GLINES_ALL,
  payload: statusAll,
});

// TODO : setStatus를 export 하면 아래처럼 한번 더 감쌀 필요 없이 직접 호출할 수 있음
export const setStatusByGLine = (status) => async dispatch => {
  dispatch(setStatus(status));
  return;
};

export const setStatusInPeriod = (status) => async dispatch => {
  dispatch(setStatusPeriod(status));
  return;
};

export const setStatusByGLineAll = (statusAll) => async dispatch => {
  dispatch(setStatusAll(statusAll));
  return;
};

export const create = ({ id, name, gprocessId, comments, glineCapacities }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/glines", {
    method: "POST",
    body: JSON.stringify({ id, name, gprocessId, comments, glineCapacities }),
  });
  const data = await response.json();
  dispatch(setGLine(data.gline));
  return response;
};

export const modify = ({ id, name, gprocessId, comments, glineCapacities }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/glines", {
    method: "PUT",
    body: JSON.stringify({ id, name, gprocessId, comments, glineCapacities }),
  });
  const data = await response.json();
  dispatch(setGLine(data.gline));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/glines");
  const data = await response.json();
  dispatch(setGLines(data.glines));
  return response;
}

export const selectAllByQuery = (calendar=true, status=false) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glines/query/selectAll`);
  const data = await response.json();
  
  calendar && dispatch(setGLines(data.glines));
  status && dispatch(setGLinesForStatus(data.glines));
  
  return response;
}

export const selectAllDirectByQuery = async () => {
  const response = await csrfFetch(null, `/api/glines/query/selectAll`);
  const data = await response.json();
  return data.glines;
}

export const selectByGProcessByQuery = (gprocess, calendar=true, status=false) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glines/query/selectByGProcessDirectByQuery/${gprocess}`);
  const data = await response.json();
  
  calendar && dispatch(setGLines(data.glines));
  status && dispatch(setGLinesForStatus(data.glines));

  return response;
}

export const selectByGProcessDirectByQuery = async (gprocess) => {
  const response = await csrfFetch(null, `/api/glines/query/selectByGProcessDirectByQuery/${gprocess}`);
  const data = await response.json();
  return data.glines;
}

// export const selectAllByType = (type) => async dispatch => {
//   const response = await csrfFetch(dispatch, `/api/glines/type/${type}`);
//   const data = await response.json();
//   dispatch(setGLines(data.glines));
//   return response;
// }

// export const selectAllByTypeDirect = async (type) => {
//   const response = await csrfFetch(dispatch, `/api/glines/type/${type}`);
//   const data = await response.json();
//   return data.glines;
// }

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glines/${id}`);
  const data = await response.json();
  dispatch(setGLine(data.gline));
  return response;
}

export const selectByQuery = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glines/query/select/${id}`);
  const data = await response.json();
  dispatch(setGLine(data.gline));
  return response;
}

export const initGLine = () => async dispatch => {
  dispatch(setGLine(null));
}

export const initGLineWithInitialValue = (initialValue) => async dispatch => {
  dispatch(setGLine(initialValue));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/glines/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGLine());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, "/api/glines/reorder", {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gline: null,
  glines: [],
  glinesForStatus: [],
  statusByGLine: {},
  statusPeriod: {},
  statusByGLineAll: {},
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GLINE:
      newState = Object.assign({}, state, { gline: action.payload });
      return newState;
    case REMOVE_GLINE:
      newState = Object.assign({}, state, { gline: null });
      return newState;
    case SET_GLINES:
      newState = Object.assign({}, state, { glines: action.payload });
      return newState;
    case SET_GLINES_FOR_STATUS:
      newState = Object.assign({}, state, { glinesForStatus: action.payload });
      return newState;
    case SET_STATUS_BY_GLINES:
      newState = Object.assign({}, state, { statusByGLine: action.payload });
      return newState;
    case SET_STATUS_PERIOD:
      newState = Object.assign({}, state, { statusPeriod: action.payload });
      return newState;
    case SET_STATUS_BY_GLINES_ALL:
      newState = Object.assign({}, state, { statusByGLineAll: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
