import React, { useState, useRef, useEffect } from "react";
// import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Canvas, useFrame } from "react-three-fiber";
import {
  TextField,
  Button,
  Grid,
  // MenuItem,
} from '@mui/material';
import {
  FormInputDate,
  FormInputDateRangePicker,
  FormInputDropdown,
  FormInputMultipleCheckbox,
  FormInputRadio,
  FormInputSlider,
  FormInputText,
  FormInputSwitch,
  FormInputToggleButton,
  FormInputMultipleSelect,
} from "./components/form";

function GlassBox(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    // mesh.current.rotation.y += 0.01;
    if (props.rotate === false) {
      mesh.current.rotation.y = 0;  
    } else {
      // mesh.current.rotation.y += 0.01;
      mesh.current.rotation.y += 0.03;
      if (mesh.current.rotation.y >= 3.14) {
        props.setRotate(false);
      }
    }
  });
  return (
    <mesh {...props} ref={mesh} scale={[1, 1, 1]}>
      <boxBufferGeometry args={[3, 4, 0.2]} />
      <meshStandardMaterial color="#80d8ff" />
    </mesh>
  );
}

function GlassBox1(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    // mesh.current.rotation.y += 0.01;
    if (props.rotate === false) {
      mesh.current.rotation.y = 0;  
    } else {
      // mesh.current.rotation.y += 0.01;
      mesh.current.rotation.y += 0.03;
      if (mesh.current.rotation.y >= 3.14) {
        props.setRotate(false);
      }
    }
  });
  return (
    <mesh {...props} ref={mesh} scale={[1, 1, 1]}>
      <boxBufferGeometry args={[1, 1, 0.4]} />
      <meshStandardMaterial color="hotpink" />
      <meshStandardMaterial />
    </mesh>
  );
}

const defaultValues = {
  contractPrice: "",
};

const ReactTest = () => {
  
  // const [ test, setTest ] = useState("");
  const [rotate, setRotate] = useState(false);
  
  const [priceMeterByMeter, setPriceMeterByMeter] = useState(0);
  const [priceJaByMeter, setPriceJaByMeter] = useState(0);
  const [priceFeetByMeter, setPriceFeetByMeter] = useState(0);

  const [priceMeterByJa, setPriceMeterByJa] = useState(0);
  const [priceJaByJa, setPriceJaByJa] = useState(0);
  const [priceFeetByJa, setPriceFeetByJa] = useState(0);
  
  const [priceMeterByFeet, setPriceMeterByFeet] = useState(0);
  const [priceJaByFeet, setPriceJaByFeet] = useState(0);
  const [priceFeetByFeet, setPriceFeetByFeet] = useState(0);

  const [useStateTest, setUseStateTest] = useState(0);

  const { control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const canvasRef = useRef();

  useEffect(() => {
    if (!canvasRef) return;
    const ctx = canvasRef.current?.getContext("2d");
    const image = new Image();
    image.src = "/test.jpg";

    image.onload = function() {
      ctx?.drawImage(image, 0, 0);
    };
  }, [canvasRef]);

  const handleClick1 = () => {
    // setRotate(!rotate);
    setRotate(true)
    // const value = test;
    // setTest(`${value}1`); // state 사용시 화면에 state를 사용하지 않더라도 render가 일어남
  }

  const handleClick = () => {
    // m2
    const meterToJa = 1000/303*1000/303;
    const meterToFeet = 1000/304.8*1000/304.8;
    // const contractPrice = 1000;
    const contractPrice = parseFloat(getValues("contractPrice"));
    let contractPriceMeter = contractPrice;
    let contractPriceJa = contractPrice/meterToJa;
    let contractPriceFeet = contractPrice/meterToFeet;

    // alert(`meterToJa: ${meterToJa}, meterToFeet: ${meterToFeet}`)
    // alert(`미터 기준 => contractPriceMeter: ${contractPriceMeter}, contractPriceJa: ${contractPriceJa}, contractPriceFeet: ${contractPriceFeet}`)
    setPriceMeterByMeter(contractPriceMeter);
    setPriceJaByMeter(contractPriceJa);
    setPriceFeetByMeter(contractPriceFeet);
    
    // Ja
    contractPriceMeter = contractPrice*meterToJa;
    contractPriceJa = contractPrice;
    contractPriceFeet = contractPriceMeter/meterToFeet;
    // alert(`평 기준 => contractPriceMeter: ${contractPriceMeter}, contractPriceJa: ${contractPriceJa}, contractPriceFeet: ${contractPriceFeet}`)
    setPriceMeterByJa(contractPriceMeter);
    setPriceJaByJa(contractPriceJa);
    setPriceFeetByJa(contractPriceFeet);
    
    // Feet
    contractPriceMeter = contractPrice*meterToFeet;
    contractPriceJa = contractPriceMeter/meterToJa;
    contractPriceFeet = contractPrice;
    // alert(`피트평 기준 => contractPriceMeter: ${contractPriceMeter}, contractPriceJa: ${contractPriceJa}, contractPriceFeet: ${contractPriceFeet}`)
    setPriceMeterByFeet(contractPriceMeter);
    setPriceJaByFeet(contractPriceJa);
    setPriceFeetByFeet(contractPriceFeet);
  }
  const handleClickUseStateTest = () => {
    // 1과 2는 동일
    // 1
    const newUseStateTest = useStateTest + 1;
    setUseStateTest(newUseStateTest)

    const arr = [{a:1}, {a:2}, {a:3}]
    console.log(arr)
    console.log(...arr)
    const testObject = {a: 1, b: 2, c: 3}
    console.log(...testObject)

    // 2
    // setUseStateTest(prev => {
    //   return prev + 1;
    // })
  }
  // console.log(test)
  return (
    <div>
      <Grid container>
        <Grid sm={2}>
          <FormInputText
            name={"contractPrice"}
            control={control}
            label={"수주단가"}
          />
        </Grid>
      </Grid>
      <Button onClick={handleClickUseStateTest}>useState Test</Button>
      <Button onClick={handleClick}>변환</Button>
      <table border="1">
        <tr>
          <td>m2</td>
          <td>평</td>
          <td>ft2</td>
        </tr>
        <tr>
          <td>{priceMeterByMeter}</td>
          <td>{priceJaByMeter}</td>
          <td>{priceFeetByMeter}</td>
        </tr>
        <tr>
          <td>{priceMeterByJa}</td>
          <td>{priceJaByJa}</td>
          <td>{priceFeetByJa}</td>
        </tr>
        <tr>
          <td>{priceMeterByFeet}</td>
          <td>{priceJaByFeet}</td>
          <td>{priceFeetByFeet}</td>
        </tr>
      </table>
      <div>
        <Button onClick={handleClick1}>test</Button>
        <div style={{ position: 'absolute', height: '700px' }}>
          <Canvas>
            <ambientLight intensity={2.0} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <GlassBox position={[0, 0, -3]} rotate={rotate} setRotate={setRotate} />
            <GlassBox1 position={[0, 0, -3]} rotate={rotate} setRotate={setRotate} />
          </Canvas>
        </div>
        <div style={{ height: '700px', zIndex: 999 }}>
          <pre>
          {`111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111
          111111111111111111111111`}
          </pre>
        </div>
      </div>
    </div>
  );
};


export default ReactTest;