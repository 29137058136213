import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

function PaperComponent(props) {
  // props는 Dialog가 통째로 들어옴

  // const handleStop = (e, data) => {
  //   console.log(e, data)
  // }

  // const handleStop = (e) => {
  //   // TODO : clientY - offsetY, clientX - offsetX
  //   console.log(e)
  // }

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      // TODO : 이것과 redux를 이용해서 다이얼로그 최대화하면 0, 0 원래대로 하면 dragStop의 위치로 설정 구현 고려해볼 것. 현재는 드래그 중 최대화하고 다시 원래대로 가면 처음 위치로 옴(이것이 더 심플할 수도...)
      // position={ { x: 0, y: 0 } }
      // onStop={handleStop}
      // onStop={handleStop}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default PaperComponent;