import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import * as XLSX from "xlsx";
import {
  FormInputText,
  FormInputDropdown,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gunitActions from "../../store/gunit";
import * as gglassActions from "../../store/gglass";
import * as gprojectGlassActions from "../../store/gprojectGlass"
import {
  Delete,
  Done,
  Search,
  TextsmsTwoTone
} from "@mui/icons-material";
// import GReceptionOrderByDialog from "./GReceptionOrderByDialog";
import GReceptionOrderBy from "./GReceptionOrderBy";

// 아래 form components의 name과 연계
// const defaultValues = {
//   id: "",
//   name: "",
//   code: "",
//   comments: "",
// };

// const theme = createTheme();

// TODO : GOrderExcel 참고하여 50행 이상 처리 적용할 것
const rowLength = 51;
// const columnLength = 20;
const columnLength = 19;
// const fixedColumnLength = 9;
const fixedColumnLength = 11;
// const rowLength = 10;
// const columnLength = 10;
const columnWidth = 88;
const arrRow = [];
// const arrCol = [];
for(let i=0; i<rowLength; i++) {
  // arrRow.push(i.toString());
  arrRow.push("");
}
// for(let i=0; i<columnLength; i++) {
//   arrCol.push(i.toString());
// }

const gglassMap = new Map();

const columnMap = {};
const emptyColumns = {};

const GReceptionExcel = ({
  initExcelDatas,
  selectedRow,
  setValue,
  getValues,
  setOpenProgress,
  focus,
}) => {
  
  const getColumns = () => {
    return arrCol.map((item, cIdx) => {
      let width = columnWidth;
      // 0. 항목, 1. 발행, 2. 품번, 3. 규격, 4. 사양, 크기(5. 가로, 6. 세로), 7. 수량, 단위면적(8. m2, 9. 평, 10. ft2), 면적(11. m2, 12. 평, 13. ft2), 단가(14. m2, 15. 평, 16. ft2), 17. 금액, 18. 비고
      if (cIdx === 0) {
        width = 60;
      } else if (cIdx === 1) {
        width = 70;
      } else if (cIdx === 2) {
        width = 120;
      } else if (cIdx === 3) {
        width = 300;
      } else if (cIdx === 4) {
        width = 300;
      } else if (cIdx === 5) {
        width = 100;
      } else if (cIdx === 6) {
        width = 100;
      } else if (cIdx === 7) {
        width = 100;
      } else if (cIdx === 8) {
        width = 100;
      } else if (cIdx === 9) {
        width = 100;
      } else if (cIdx === 10) {
        width = 100;
      } else if (cIdx === 11) {
        width = 100;
      } else if (cIdx === 12) {
        width = 100;
      } else if (cIdx === 13) {
        width = 100;
      } else if (cIdx === 14) {
        width = 100;
      } else if (cIdx === 15) {
        width = 100;
      } else if (cIdx === 16) {
        width = 100;
      } else if (cIdx === 17) {
        width = 140;
      } else if (cIdx === 18) {
        width = 300;
      }
      
      return {
        columnId: item,
        width, // TODO : 절대수치가 아닌 화면비율에 따른 상대수치 가능한지 검토
        resizable: true,
        reorderable: true,
      };
    });
  };

  // TODO : isOpen 동작 방식 정확한 파악 필요. 현재 사용안함
  const getDatas = () => {
    // console.log("getDatas")
    arrCol.forEach((item, idx) => {
      if (idx === 0) {
        columnMap[Number(item)] = `항목`;
      } else {
        columnMap[Number(item)] = `열 ${idx+1}`;
      }

      emptyColumns[Number(item)] = "";
    });

    return arrRow.map((item, idx) => {
      const columns = Object.assign({}, {...emptyColumns});
      // columns.isOpen = false;
      let firstColumn = (idx+1).toString();
      if (idx === rowLength-1) {
        firstColumn = "소계";
        // columns[0] = "소계";
      }

      columns[0] = firstColumn;
      
      return columns;
    });
  }
  
  const alignCenter = {
    justifyContent: "center"
  };

  const alignRight = {
    justifyContent: "right"
  };

  const getRows = (columnsOrder) => {
    const rowsReactGrid = [
      {
        rowId: "header1",
        cells: [
          { type: "header", text: "항목", rowspan: 2, style: alignCenter },
          { type: "header", text: "발행", rowspan: 2, style: alignCenter },
          { type: "header", text: "품번", rowspan: 2, style: alignCenter },
          { type: "header", text: "규격", rowspan: 2, style: alignCenter },
          { type: "header", text: "사양", rowspan: 2, style: alignCenter },
          { type: "header", text: "크기", colspan: 2, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "수량", rowspan: 2, style: alignCenter },
          { type: "header", text: "단위면적", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "면적", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "단가", colspan: 3, style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "금액", rowspan: 2, style: alignCenter },
          { type: "header", text: "비고", rowspan: 2, style: alignCenter },
        ]
      },
      {
        rowId: "header2",
        cells: [
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "" },
          { type: "header", text: "가로", style: alignCenter },
          { type: "header", text: "세로", style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "m2", style: alignCenter },
          { type: "header", text: "평", style: alignCenter },
          { type: "header", text: "ft2", style: alignCenter },
          { type: "header", text: "" },
          { type: "header", text: "" },
        ]
      },
      ...datas.map((data, idx) => {
        return {
          // rowId: data.id, // 중요
          rowId: idx, // 중요
          reorderable: true, // 중요
          height: 40, // default = 25
          cells: arrCol.map((item, cIdx) => {
            let column = {};
              
            let customStyle = {
              border : {
                left: {},
                top: {},
                right: {},
                bottom: {},
              },
            };

            // 숫자 데이터의 경우 우측정렬
            if ([0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(cIdx)) { // TODO : 배열 변수화할 것
              customStyle = Object.assign(customStyle, alignRight);
            }

            let colspan = 0;
            if (idx === datas.length - 1) {
              customStyle.color = "white";
              customStyle.backgroundColor = "#00897b";

              if (cIdx === 0) {
                colspan = 7;
                customStyle = Object.assign(customStyle, { justifyContent: "center" });
              }
            }

            if ([0, 1].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#f2f2f2" });
            }

            if ([2, 3, 4].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#fff3e0" });
            }

            if ([5, 6, 7].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e1f5fe" });
            }

            if ([8, 9, 10].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e1f5fe" });
            }

            if ([11, 12, 13].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#f1f8e9" });
            }

            if ([14, 15, 16].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#e8f5e9" });
            }

            if (cIdx === 17 && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { fontWeight: "bold", color: "#00695c", backgroundColor: "#b2dfdb" });
            }

            if ([18].includes(cIdx) && idx !== datas.length - 1 ) {
              customStyle = Object.assign(customStyle, { backgroundColor: "#fbe9e7" });
            }

            // 기준면적 셀 색상 처리
            filledCells.forEach(filledCell => {
              const { rowIdx, colIds } = filledCell;
              colIds.forEach(colId => {
                if (rowIdx === idx && Number(colId) === cIdx) {
                  customStyle = Object.assign(customStyle, { fontWeight: "bold",  color: "#1976d2", backgroundColor: "#bbdefb" }); 
                }
              });
            });

            column = {
              type: "text",
              colspan,
              nonEditable: true,
              // text: data[columnsOrder[Number(item)]], // TODO : columnsOrder 필요한가
              text: data[Number(item)],
              style: customStyle,
              renderer: (string) => {
                // console.log(string)
                // TODO : 숫자 데이터의 경우 소수점 처리. 원 데이터는 그대로 두고 render시에만 표시 가능?
                // TODO : 기능의 경우 여기에 react component 추가. select box, input 등
                // TODO : 추후 customCellTemplates 사용방법 강구할 것
                if (cIdx === 1) {
                  return (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <IconButton aria-label="search" size="small" disabled><Search /></IconButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <IconButton aria-label="delete" size="small" disabled><Delete /></IconButton>
                      </Grid>
                    </Grid>
                  );
                } else if([5, 6, 7].includes(cIdx)) {
                  return string === "" || /*Number(string).toString()*/string.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else if([8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(cIdx)) {
                  return string === "" || Number(string).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return string;
                }
              }
            };
            // }
            return column;
          })
        }
      })
    ];

    return rowsReactGrid;
  }

  const [arrCol, setArrCol] = useState(Array.from({length: columnLength}, (v, i) => i.toString()));
  const [columns, setColumns] = useState(getColumns());
  const [datas, setDatas] = useState(getDatas());
  const [filledCells, setFilledCells] = useState([]);

  const [openFindDialog, setOpenFindDialog] = useState(false);
  const [loadedFind, setLoadedFind] = useState(false);
  const [showFind, setShowFind] = useState(false);
  const [selectedRowFind, setSelectedRowsFind] = useState([]);
  const [selectedRowIdsExcel, setSelectedRowIdsExcel] = useState([]);
  // const [alertInfo, setAlertInfo] = useState({ isAlert: false, message: "" });
  const [alertInfo, setAlertInfo] = useState({});
  const [areaType, setAreaType] = useState("m2");
  const [areaGUnits, setAreaGUnits] = useState([]);

  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(100);

  // const [rows, setRows] = useState([]);

  const rows = getRows(columns.map(c => c.columnId));
  
  const rowsFind = useSelector((state) => state.gprojectGlass.gprojectGlasses);

  const defaultValues = {
    orderFirst: "",
    orderSecond: "",
    orderThird: "",
    orderFourth: "",
  }

  const useFormProperty = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control } = useFormProperty;
  const setValueThis = useFormProperty.setValue;

  // 데이터 관리
  const dispatch = useDispatch();

  // const selectAllGGlassesDirect = () => gglassActions.selectAllDirect()
  // const selectAllProjectGlassesByProjectAndAreaTypeByQuery = (gprojectId, areaType) => dispatch(gprojectGlassActions.selectAllByProjectAndAreaTypeByQuery(gprojectId, areaType));
  const selectAllProjectGlassesByProjectByQuery = (gprojectId) => dispatch(gprojectGlassActions.selectAllByProjectByQuery(gprojectId));
  const selectGUnitsAllByTypeDirect = (type) => gunitActions.selectAllByTypeDirect(type)

  const arrayColumn = (arr, n) => arr.map(x => x[n]);

  // const handleColumnsReorder = (targetColumnId, columnIds) => {
  //   const to = columns.findIndex((column) => column.columnId === targetColumnId);
  //   const columnIdxs = columnIds.map((columnId) => columns.findIndex((c) => c.columnId === columnId));
  //   setColumns(prevColumns => reorderArray(prevColumns, columnIdxs, to, "col"));
  // }

  // const handleChanges = (changes) => {
  //   setDatas((prevData) => applyChangesToDatas(changes, prevData)); 
  // }; 

  const handleColumnResize = (ci, width) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  }

  const getSortFunction = (option)  => {

    let direction = option.orderBy === "ASC" ? -1 : 1;

    return ((a, b) => {
      let prev = a[option.col].toLowerCase();
      let next = b[option.col].toLowerCase();
      
      // 공백이 있을 경우 아래로 배치
      if (prev === "" && next !== "") {
        return 1;
      }
      
      if (prev !== "" && next === "") {
        return 1;
      }

      if (option.type === 'number') {
        prev = Number(prev.replace(",", ""));
        next = Number(next.replace(",", ""));
      }

      if (prev < next) {
        return direction;
      } else if (prev > next) {
        return -direction;
      } else {
        return 0;
      }
    });
  }

  const removeRow = (delRowId) => {
    // 기준면적 셀 배경색상 삭제
    const newFilledCells = filledCells.map(filledCell => {
      const { rowIdx } = filledCell;
      if (delRowId.includes(rowIdx)) {
        return {
          rowIdx,
          colIds: [],
        }  
      } else {
        return filledCell;
      }
    });
    setFilledCells(newFilledCells);

    // 행삭제
    setDatas(prevData => {
      const newData = [...prevData.filter((data, idx) => !delRowId.includes(idx))];

      // 수량 합, 총면적 합, 금액 합
      const sumAmountArr = arrayColumn(newData, 7).slice(0, -1); // slice(0, -1)은 마지막 소계행 제외 합산이기 때문에
      const sumAreaArr = arrayColumn(newData, 11).slice(0, -1);
      const sumPrice = arrayColumn(newData, 17).slice(0, -1);

      const initialValue = 0;
      // 수량 합
      const sumAmount = sumAmountArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);

      // m2 기준으로부터 변환
      // const mmToMeter = 1000*1000;
      const meterToJa = 1000/303*1000/303;
      const meterToFeet = 1000/304.8*1000/304.8;

      const sumAreaMeter = sumAreaArr.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);
      const sumAreaJa = sumAreaMeter*meterToJa;
      const sumAreaFeet = sumAreaMeter*meterToFeet;

      // 금액 합
      const sumOrderPriceTotal = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue);

      setValue("sumAmount", sumAmount);
      setValue("sumAreaMeter", sumAreaMeter);
      setValue("sumAreaJa", sumAreaJa);
      setValue("sumAreaFeet", sumAreaFeet);
      // sumContractPriceTotal // 서버에서 계산
      setValue("sumOrderPriceTotal", sumOrderPriceTotal);

      return newData.map((item, idx) => {
        // const newItem = item;
        const newItem = Object.assign({}, item); // TODO : const newItem = item; 일 때 예상과 다르게 동작. 추후 확인
        let firstColumn = (idx+1).toString();
        if (idx === newData.length -1 ) {

          newItem[7] = sumAmount.toString(); // 수량 합

          // 총면적 합
          newItem[11] = sumAreaMeter.toString();
          newItem[12] = sumAreaJa.toString();
          newItem[13] = sumAreaFeet.toString();

          newItem[17] = sumOrderPriceTotal.toString(); // 금액 합

          firstColumn = "소계";
        }
        newItem[0] = firstColumn;
        return newItem;
      });
      // return newData;
    })
  }

  const handleFindDialogClose = () => {
    setLoadedFind(false);
    setOpenFindDialog(false);
  }

  // 기준면적 셀 색상 설정
  const changeUnitAreaCellColor = (colIds, rowId) => {
    let newFilledCells = [];
    const arr = filledCells.filter(filledCell => filledCell.rowIdx === rowId);
    if (arr.length > 0 ) {
      newFilledCells = filledCells.map(filledCell => {
        const { rowIdx } = filledCell;
        if (rowIdx === rowId) {
          return {
            rowIdx,
            colIds,
          }  
        } else {
          return filledCell;
        }
      });
    } else {
      newFilledCells = [].concat(filledCells);
      newFilledCells.push({
        rowIdx: rowId, colIds,
      })
    }

    return newFilledCells;
  }
  
  const handleSelectFind = () => {
    console.log(selectedRowFind);
    const rIdx = selectedRowIdsExcel[0];

    let newFilledCells = [];
    // console.log(newFilledCells)

    setDatas(prevData => {
      const newData = Object.assign([], prevData);
      const { orderAreaType, no, standard, specification, orderPrice, orderPriceMeter, orderPriceJa, orderPriceFeet } = selectedRowFind.row;

      newData[rIdx][2] = no;
      newData[rIdx][3] = standard;
      newData[rIdx][4] = specification;

      let colIds = [];
      // 단가 14, 15, 16 및 기준면적 셀 색상 설정
      if (orderAreaType === "m2") {
        newData[rIdx][14] = orderPrice; // or orderPriceMeter
        newData[rIdx][15] = orderPriceJa;
        newData[rIdx][16] = orderPriceFeet;
        newData[rIdx][17] = (Number(newData[rIdx][11])*Number(newData[rIdx][14])).toString();

        colIds = ["8", "11", "14"];
      } else if (orderAreaType === "평") {
        newData[rIdx][14] = orderPriceMeter;
        newData[rIdx][15] = orderPrice; // or orderPriceJa
        newData[rIdx][16] = orderPriceFeet;
        newData[rIdx][17] = (Number(newData[rIdx][12])*Number(newData[rIdx][15])).toString();

        colIds = ["9", "12", "15"];
      } else if (orderAreaType === "ft2") {
        newData[rIdx][14] = orderPriceMeter;
        newData[rIdx][15] = orderPriceJa;
        newData[rIdx][16] = orderPrice; // or orderPriceFeet
        newData[rIdx][17] = (Number(newData[rIdx][13])*Number(newData[rIdx][16])).toString();

        colIds = ["10", "13", "16"];
      }
      
      // 기준면적 셀 색상 설정
      newFilledCells = changeUnitAreaCellColor(colIds, rIdx);
      // const arr = filledCells.filter(filledCell => filledCell.rowIdx === rIdx);
      // if (arr.length > 0 ) {
      //   newFilledCells = filledCells.map(filledCell => {
      //     const { rowIdx } = filledCell;
      //     if (rowIdx === rIdx) {
      //       return {
      //         rowIdx,
      //         colIds,
      //       }  
      //     } else {
      //       return filledCell;
      //     }
      //   });
      // } else {
      //   newFilledCells = [].concat(filledCells);
      //   newFilledCells.push({
      //     rowIdx: rIdx, colIds,
      //   })
      // }

      const sumPrice = arrayColumn(newData, 17).slice(0, -1);
      const initialValue = 0;
      newData[datas.length-1][17] = sumPrice.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), initialValue).toString();
      // selectedRowFind.row.gprojectGlassId = selectedRowFind.row.id;
      newData[rIdx]["gglassDetails"] = selectedRowFind.row; // TODO : 없는 키를 삽입. 문제없는지 추가 검토 필요
      
      return newData;
    });
    
    if (newFilledCells.length > 0) {
      setFilledCells(newFilledCells);
    }
    // gglassMap.set(selectedRowFind.row.id, selectedRowFind.row);
    gglassMap.set(selectedRowFind.row.no, selectedRowFind.row);
    console.log(gglassMap)
    setOpenFindDialog(false);
  }

  // useEffect(
  //   async () => {
  //     if (openFindDialog) {
  //       setShowFind(true);
  //       // setSelectedRowIdsExcel(selectedRowIds);
  //       setTimeout(() => setLoadedFind(true), 300);
  //     }
  //   }, [openFindDialog]
  // )

  const columnsFind = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 100,
    // },
    {
      field: 'gtypeName',
      headerName: '템플릿 구조',
      width: 140,
      // editable: true,
    },
    {
      field: 'no',
      headerName: '품번',
      width: 100,
    },
    {
      field: 'name',
      headerName: '품명',
      width: 160,
      // editable: true,
    },
    // {
    //   field: 'code',
    //   headerName: '코드',
    //   width: 100,
    //   // editable: true,
    // },
    {
      field: 'standard',
      headerName: '규격',
      width: 200,
      // editable: true,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
      // editable: true,
    },
    {
      field: 'contractAreaType',
      headerName: '수주면적단위',
      width: 120,
      // editable: true,
    },
    {
      field: 'orderAreaType',
      headerName: '발주면적단위',
      width: 120,
      // editable: true,
    },
    {
      field: 'contractPriceMeter',
      headerName: '수주단가(m2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value) // sequelize DataType DECIMAL의 경우 string을 리턴하므로 천단위 콤마를 사용하려면 숫자형으로 변환해주어야 한다.
    },
    {
      field: 'orderPriceMeter',
      headerName: '발주단가(m2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'contractPriceJa',
      headerName: '수주단가(평)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'orderPriceJa',
      headerName: '발주단가(평)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'contractPriceFeet',
      headerName: '수주단가(ft2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'orderPriceFeet',
      headerName: '발주단가(ft2)',
      type: 'number',
      width: 120,
      // editable: true,
      valueGetter: (params) => Number(params.value)
    },
    {
      field: 'otherSpecs',
      headerName: '비고',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  const emptyCheck = (data/*, idx*/) => {
    let result = true;
    // 맨마지막 행은 소계로 처음에는 따로 저장하려고 했으나 목록을 가지고 서버에서 계산에서 저장하는 방식으로 결정
    // if (idx === datas.length -1) {
    //   return result;
    // }
    // console.log(data)
    for (const [key, value] of Object.entries(data)) {
      // gglassDetails 키도 다음 통과
      // if (Number(key) < fixedColumnLength-1/* -1은 비고로 제외 */ && value === "") {
      if (2 <= Number(key) && Number(key) < 18/* -1은 비고로 제외 */ && value === "") {
        result = false;
        break;
      }
    }

    return result;
  }

  const selectGGlassCheck = (data/*, idx*/) => {
    let result = true;
    // 맨마지막 행은 소계로 처음에는 따로 저장하려고 했으나 목록을 가지고 서버에서 계산에서 저장하는 방식으로 결정
    // if (idx === datas.length -1) {
    //   return result;
    // }
    // console.log(data)
    for (const [key, value] of Object.entries(data)) {
      // gglassDetails 키도 다음 통과
      // if (Number(key) < fixedColumnLength-1/* -1은 비고로 제외 */ && value === "") {
      if (2 <= Number(key) && Number(key) < 5/* -1은 비고로 제외 */ && value === "") {
        result = false;
        break;
      }
    }

    return result;
  }

  useEffect(
    async () => {
      // let arr = [];
      // if (areaType === "m2") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceMeter", "orderPriceTotalMeter", "notes"]);
      // } else if (areaType === "평") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceJa", "orderPriceTotalJa", "notes"]);
      // } else if (areaType === " ft2") {
      //   arr = arr.concat(["itemNo", /*"gglassId"*/"no", "specification", "width", "height", "amount", "areaUnitMeter", "areaMeter", "orderPriceFeet", "orderPriceTotalFeet", "notes"]);
      // }

      const newDatas = Object.assign([], datas);
      // console.log(selectedRow);
      // console.log(initExcelDatas);
      const newFilledCells = [];
      initExcelDatas && initExcelDatas?.length > 0 && initExcelDatas.forEach((row, rIdx) => {
        const {
          gglassId,
          no,
          // name,
          // gtypeId,
          // comments,
          // order,
          // orderDetails,
          // selectedGcomponentItems,
          standard,
          specification,
          // otherSpecs,
          width,
          height,
          amount,
          areaUnitMeter,
          areaUnitJa,
          areaUnitFeet,
          areaMeter,
          areaJa,
          areaFeet,
          orderAreaType,
          orderPrice,
          orderPriceMeter,
          orderPriceJa,
          orderPriceFeet,
          orderPriceTotal,
          notes,
        } = row;

        gglassMap.set(no, row);
        
        let columnIds = [];
        if (orderAreaType === "m2") {
          columnIds = [].concat(["8", "11", "14"]);
        } else if (orderAreaType === "평") {
          columnIds = [].concat(["9", "12", "15"]);
        } else if (orderAreaType === "ft2") {
          columnIds = [].concat(["10", "13", "16"]);
        }
        
        newFilledCells.push({
          rowIdx: rIdx, colIds: columnIds,
        });

        // sequelize에서 integer는 아래와 같이 문자로 바꾸고, decimal은 string으로 리턴하므로 그래도 둔다.
        newDatas[rIdx][2] = no;
        newDatas[rIdx][3] = standard || ""; // 입력시 필수 입력이나 만약을 위해 값이 없을 경우 공백처리
        newDatas[rIdx][4] = specification || ""; // 입력시 필수 입력이나 만약을 위해 값이 없을 경우 공백처리
        newDatas[rIdx][5] = width.toString();
        newDatas[rIdx][6] = height.toString();
        newDatas[rIdx][7] = amount.toString();
        newDatas[rIdx][8] = areaUnitMeter;
        newDatas[rIdx][9] = areaUnitJa;
        newDatas[rIdx][10] = areaUnitFeet;
        newDatas[rIdx][11] = areaMeter;
        newDatas[rIdx][12] = areaJa;
        newDatas[rIdx][13] = areaFeet;
        newDatas[rIdx][14] = orderPriceMeter;
        newDatas[rIdx][15] = orderPriceJa;
        newDatas[rIdx][16] = orderPriceFeet;
        newDatas[rIdx][17] = orderPriceTotal;
        newDatas[rIdx][18] = notes || "";

        newDatas[rIdx]["gglassDetails"] = row;
      });

      if (selectedRow) {
        const { sumAmount, sumAreaMeter, sumAreaJa, sumAreaFeet, sumOrderPriceTotal } = selectedRow;
        newDatas[datas.length-1][7] = sumAmount;
        newDatas[datas.length-1][11] = sumAreaMeter;
        newDatas[datas.length-1][12] = sumAreaJa;
        newDatas[datas.length-1][13] = sumAreaFeet;
        newDatas[datas.length-1][17] = sumOrderPriceTotal;
      }

      setFilledCells(newFilledCells);

      setDatas(newDatas);
      
    }, [dispatch]
  )

  useEffect(
    () => {
      const newDatas = Object.assign([], datas.filter((data/*, idx*/) => emptyCheck(data/*, idx*/)));
      const selectGGlassDatas = Object.assign([], datas.filter((data/*, idx*/) => selectGGlassCheck(data/*, idx*/)));
      
      console.log(newDatas)
      setValue("gorderDetails", newDatas);
      setValue("gorderDetailsBeforeValidation", selectGGlassDatas.length); // 프로젝트 목록에서 프로젝트 변경시 작성중인 유리 내역이 있는 경우 체크를 위해 기록. TODO : react-hook-form에 기록할지 따로 GOrderDialog에서 prop을 받을지 추후 검토할 것
      // setOpenProgress(false);
    }, [datas]
  )

  // useEffect(
  //   () => {
  //     console.log(rowsFind)
  //   }, [rowsFind]
  // )

  useEffect(
    () => {
      if (initExcelDatas && initExcelDatas.length === 0) {
        setDatas(getDatas());
        setFilledCells([]);
      }
    }, [initExcelDatas]
  )

  return (
    // <ThemeProvider theme={theme}>
      // <Container component="main" maxWidth="lg">
      <>
        <CssBaseline />
        {/* 다이얼로그 open을 통해 열고 닫는 방식에서 화면에는 띄워놓고 숨겼다가 보이는 방식으로 변경. watermark 때문 */}
        <Dialog
          // open={openFindDialog}
          open={true}
          onClose={handleFindDialogClose}
          fullWidth={true}
          maxWidth={"lg"}
          sx={{ display: openFindDialog ? 'visible' : 'none' }}
        >
          <DialogTitleClose
            onClose={handleFindDialogClose}
          >
            {"유리제품 선택"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container>
              <div style={{ height: 400, width: '100%' }}>
                {/* TODO : 유리샘플로부터 프로젝트에 등록한 유리제품 조회하도록 변경할 것 */}
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: showFind ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loadedFind}
                  rows={rowsFind}
                  columns={columnsFind}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowClick={(params) => {
                    setSelectedRowsFind(params);
                  }}
                  onRowDoubleClick={handleSelectFind}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSelectFind}>{"선택"}</Button>
            <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          alertInfo={alertInfo}
          // open={openAlert}
          setAlertInfo={setAlertInfo}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              {
                alertInfo.isAlert && (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{alertInfo?.message}</Alert>
                  </Stack>
                )
              }
            </Grid>
          </Grid>
          {
            // rows?.length > 0 && (
              <div style={{ height: 800, width: '100%', overflow: 'scroll' }}>
                <ReactGrid
                  rows={rows}
                  columns={columns}
                  onColumnResized={handleColumnResize}
                  // onColumnsReordered={handleColumnsReorder}
                  enableRowSelection
                  enableColumnSelection
                  enableRangeSelection
                  stickyLeftColumns={7}
                  stickyRightColumns={2}
                  stickyTopRows={2}
                  stickyBottomRows={1} // TODO : 소계 : 수량합, 면적합 (피트평(ft2), 평(자), 제곱미터(m2) 모두 표현)
                  // // focusLocation={{ columnId: 'name', rowId: '2' }}
                  enableFillHandle
                  labels={{
                    copyLabel: '복사하기',
                    pasteLabel: '붙여넣기',
                    cutLabel: '잘라내기',
                }}
                // highlights={highlights}
                />
              </div>
            // )
          }
        </Box>
      </>
      // </Container>
    // </ThemeProvider>
  );
};

export default GReceptionExcel;
