import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import {
  DialogTitleClose,
  PaperComponent,
} from "../../dialog";

// TODO : ConfirmDialog와 통합 여부 검토
const Modal = ({ visible, onCancel, onConfirm, children }) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };
  return (
    <Dialog
      open={visible}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose id="draggable-dialog-title">
        {"안내"}
      </DialogTitleClose>
      <DialogContent>
        {"작업 중인 데이터가 삭제됩니다. 그래도 이동하시겠습니까?"}
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm}>{"예"}</Button>
        {/* <Button onClick={() => onCancel()} color="secondary">{"아니오"}</Button> */}
        <Button onClick={onCancel} color="secondary">{"아니오"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
