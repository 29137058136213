import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  Slide,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
// import FilePondPluginGetFile from 'filepond-plugin-get-file';
import { mode } from "./config";
import { SignupGClient, SignupGUser } from "./components/GSignup";
import LoginFormPage from "./components/LoginFormPage";
import { GLogin, GLoginGClient, GLoginGUser } from "./components/GLoginFormPage";
import * as sessionActions from "./store/session";
import * as menuActions from "./store/menu";
import * as errorActions from "./store/error";
import * as gsendBasketActions from './store/gsendBasket';
import * as footerActions from './store/footer';
import * as notificationActions from './store/notification';

import { Navigation } from "./components/Navigation";
import Header from "./components/layout/Header";
// import StickyFooter from "./components/layout/StickyFooter";
import Copyright from "./components/layout/Copyright";
import CopyrightKFGWA from "./components/layout/CopyrightKFGWA";
import Intro from "./Intro";
import { Dashboard, Scheduler } from "./components/Dashboard";
import Profile from "./components/Profile";
import { User } from "./components/User";
import { ConstructionWorkTypeManagement } from "./components/ConstructionWorkType";
import { GUnitManagement } from "./components/GUnit";
import { GComponentManagement, GComponentItemManagement } from "./components/GComponent";
import { GTypeManagement } from "./components/GType";
import { GGlassManagement } from "./components/GGlass";
import { GSubManagement, GSubItemManagement } from "./components/GSub";
import { GStandardTypeManagement } from "./components/GStandardType";
import { GStandardManagement } from "./components/GStandard";
import { G04docuManagement } from "./components/G04docu";
import { G04docuGCertificationManagement } from "./components/G04docuGCertification";
import { G04docuFileManagement } from "./components/G04docuFile";
import { GClientTypeManagement } from "./components/GClientType";
import { G04GeneralDocuManagement } from "./components/G04GeneralDocu";
import { GClientType04docuManagement } from "./components/GClientType04docu";
import { GClientManagement } from "./components/GClient";
import { GProcessManagement } from "./components/GProcess";
import { GProjectManagement } from "./components/GProject";
import { G04docuMaterialApprovalManagement } from "./components/G04docuMaterialApproval";
import { G04docuGTestManagement } from "./components/G04docuGTest";
import { G04docuGCertificationByGItemOrGlassManagement } from './components/G04docuGCertificationByGItemOrGlass';
import { G04docuSearchManagement/*, G04docuSearchManagement1*/ } from './components/G04docuSearch';
import { GSendG04docuManagement, GSendG04docuDownloadPage } from './components/GSendG04docu';
import { /*GOrderExcel, GOrderExcelTest, */GOrderManagement } from "./components/GOrder";
import { GReceptionManagement } from "./components/GReception";
import { GWorkOrderManagement, GWorkOrderStatusManagement, GWorkOrderStatusByGLineManagement } from "./components/GWorkOrder";
import { GPlanManagement } from "./components/GPlan";
import { GLineManagement } from "./components/GLine";
import { GWorkOrderDetailProcessManagement } from "./components/GWorkOrderDetailProcess";
import { GDomesticAuthManagement } from "./components/GDomesticAuth";
import { G04docuAlarmSettingManagement } from "./components/G04docuAlarmSetting";
import { GSecurityByGClientManagement } from "./components/GSecurityByGClient";
import { ErrorPage } from "./components/error";
import ConsentDialog from "./ConsentDialog";

import FormDemo from "./FormDemo";
import FormSelectDemo from "./FormSelectDemo";
import FormDemo2 from "./FormDemo2";
import CalendarDemo from "./CalendarDemo";
import ReactTest from "./ReactTest";
import FormLocaleDemo from "./FormLocaleDemo";
import DemoDrawer from "./DemoDrawer";
import NotificationDialog from "./NotificationDialog";
import { adminMenus, gclientMenus, userMenus } from './menus'

import G04docuMaterialApprovalProgress from "./components/G04docuMaterialApproval/G04docuMaterialApprovalProgress";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  // FilePondPluginImagePreview,
  // // FilePondPluginGetFile,
  // FilePondPluginPdfPreview
);

// 배포 모듈에서는 로그가 나오지 않도록 한다.
if (
  // mode === "dist-development" ||
  // mode === "dist-development2" ||
  // mode === "dist-production" ||
  mode === "dist-production-idc") {
  console = window.console || {};
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function () {};
}

const cellStyleTop = {
  borderColor: '#dcdcdc',
  borderWidth: 1, borderStyle: 'solid',
  bgcolor: "#F2F2F2",
};

const cellStyleTop2 = {
  borderColor: '#dcdcdc',
  borderWidth: 1, borderStyle: 'solid',
  borderLeftWidth: 0,
};

const cellStyle = {
  borderColor: '#dcdcdc',
  borderWidth: 1, borderStyle: 'solid',
  borderTopWidth: 0,
  bgcolor: '#F7F7F7',
};

const cellStyle2 = {
  borderColor: '#dcdcdc',
  borderWidth: 1, borderStyle: 'solid',
  borderTopWidth: 0,
  borderLeftWidth: 0,
};

function RequireAuth({ sessionUser, children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  
  console.log("RequireAuth");
  console.log(location);
  const token = Cookies.get('token');
  console.log(sessionUser);
  console.log(token);
  // document.cookie
  console.log(token && jwtDecode(token));
  
  if (sessionUser) {
    return (<>{children}</>);
  } else {
    dispatch(sessionActions.logout(sessionUser));
    dispatch(menuActions.removeAllTabMenus());
    
    // 로그아웃하면 이전 로그인한 사용자에 따라 signin 위치가 다름
    const userType = localStorage.getItem("type");
    if (userType === "GCLIENT") {
      return (<Navigate to="/glass/signin/gclient" replace state={{ from: `${location.pathname}${location.search}` }}/>); // search에 ? 붙어 있음
    } else if (userType === "USER") {
      return (<Navigate to="/glass/signin/guser" replace state={{ from: `${location.pathname}${location.search}` }}/>);
    } else {
      // // 아래 설정한 state를 signin에서 useLocation를 통해 접근 가능
      return (<Navigate to="/glass/signin" replace state={{ from: `${location.pathname}${location.search}` }}/>);
      // return (<Navigate to="/intro" replace state={{ from: `${location.pathname}${location.search}` }}/>);
    }
  }   
}

LicenseInfo.setLicenseKey('2e77f6b58977c6ec2fad2a37e65cb035Tz04NTEyOCxFPTE3NDA2NDU4NDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  
  const sessionUser = useSelector(state => state.session.sessionUser);
  const error = useSelector(state => state.error.error);
  const footer = useSelector(state => state.footer.footer);

  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const selectGSendBasketsByCreatorByQuery = (creatorId) => dispatch(gsendBasketActions.selectAllByCreatorByQuery(creatorId))
  const displayFooter = (show) => dispatch(footerActions.displayFooter(show))
  const displayNotification = (show) => dispatch(notificationActions.displayNotification(show))

  // F5키를 누르면 useEffect의 /api/session 호출 후 sessionUser가 undefined가 되면서 RequireAuth에서 sigin으로 이동
  useEffect(() => {
    // TODO : 자바 테스트중
    dispatch(sessionActions.restoreSessionUser()).then(() => setIsLoaded(true)); // TODO : 이거 주석처리하면 F5(refresh) 누르면 로그인 페이지로 이동
    // setIsLoaded(true)
    
    /**
     * footer 보이기
     * 메뉴 클릭하면 로딩할 때마다 footer가 미리 보였다가 해당 메뉴 프로그램 내용이 나오면서 footer가 아래로 이동하는 듯 보여 화면로딩과 거의 동시에 footer가 보이도록 하기 위함
     * 그런데,
     *  - F5(refresh) 하면 이 함수가 다시 실행되면서 footer를 다시 보이게 하는데 화면로딩이 오래 걸리는 경우의 화면일 경우 footer가 먼저 보였다가 아래로 이동하는 듯 보이는 현상은 동일함.
     *  - TODO : 따라서, 그것이 문제라면 각 화면에서 아래 displayFooter를 실행해야 하지 않을까 생각함
     *           현재 그대로 두면 반복 코딩 피하고 F5가 아닌 경우(대다수) 최초 이후 Footer가 그대로 있기 때문에 문제가 크지 않음
     */
    setTimeout(() => displayFooter(true), 200);
  }, [dispatch]);

  useEffect(
    () => {
      if (sessionUser) {
        const { id, type, sessionUserLoginType, loginCount, consented, needConsent } = sessionUser;
        // TODO : sessionUser.consented가 true가 아니면 
        // 동의 다이얼로그 띄운다.
        // 동의 다이얼로그는 클릭하고 동의하지 않으면 닫히지 않도록 한다. handleDialogClose(e, reason)
        if (!consented || needConsent) {
          setOpen(true)
          return;
        }

        // if (type === 'ADMIN' || type === 'GCLIENT') {
        //   selectGSendBasketsByCreatorByQuery(id);
        // }
        let creatorId = "";
        if (sessionUserLoginType === 'GCLIENT') {
          creatorId = id;
        } else {
          if (sessionUser.user && Array.isArray(sessionUser.user) && sessionUser.user.length === 1) {
            creatorId = sessionUser.user[0].id;
          }
        }

        selectGSendBasketsByCreatorByQuery(creatorId);
        if (loginCount < 5) { // TODO : 공지사항을 설정하는 기능 추가 필요
          displayNotification(true);
        }
      }
    }, [sessionUser]
  )

  const handleClose = () => {
    dispatch(errorActions.occurError(null));
  }

  const  SlideTransition = (props) => <Slide {...props} direction="up" />

  const { pathname } = useLocation();
  console.log(pathname)
  const routes = (
    <>
      {/* useLocation().pathname !== "/gsendG04docus/download" 조건은 메일로 전송한 다운로드 경로인 경우 화면에 메뉴와 탭을 보이지 않도록 하기 위해서임 */}
      {/* 메뉴 : Navigation */}
      {/* { (useLocation().pathname !== "/gsendG04docus/download") && <Navigation isLoaded={isLoaded} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> } */}
      { (pathname !== "/gsendG04docus/download" && pathname !== "/intro") && <Navigation isLoaded={isLoaded} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> }
      {/* 탭 : Header */}
      {/* { (useLocation().pathname !== "/gsendG04docus/download") && <Header menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> } */}
      { (pathname !== "/gsendG04docus/download" && pathname !== "/intro") && <Header menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> }
      {
        isLoaded && (
          <Routes>
            {
              sessionUser?.type === 'ADMIN' ? (
                <Route path="/glass/dashboard" element={
                  <RequireAuth sessionUser={sessionUser}>
                    <Dashboard />
                  </RequireAuth>
                } />
              ) : (
                <Route path="/glass/dashboard" element={
                  <RequireAuth sessionUser={sessionUser}>
                    <Dashboard />
                  </RequireAuth>
                } />
              )
            }
            
            <Route path="/" element={<Intro />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/signin" element={<LoginFormPage />} />
            <Route path="/glass" element={<GLogin />} />
            <Route path="/glass/signin" element={<GLogin />} />
            <Route path="/glass/signin/gclient" element={<GLoginGClient />} />
            <Route path="/glass/signin/guser" element={<GLoginGUser />} />
            <Route path="/glass/signup/gclient" element={<SignupGClient />} />
            <Route path="/glass/signup/guser" element={<SignupGUser />} />
            <Route path="/profile" element={
              <RequireAuth sessionUser={sessionUser}>
                <Profile />
              </RequireAuth>
            } />
            <Route path="/userAccount" element={
              <RequireAuth sessionUser={sessionUser}>
                <User />
              </RequireAuth>
            } />
            <Route path="/demoDrawer" element={
              <DemoDrawer />
            } />
            <Route path="/reactHookform" element={
              <RequireAuth sessionUser={sessionUser}>
                <FormDemo />
              </RequireAuth>
            } />
            <Route path="/reactHookform2" element={
              <RequireAuth sessionUser={sessionUser}>
                <FormDemo2 />
              </RequireAuth>
            } />
            <Route path="/reactTest" element={
              <RequireAuth sessionUser={sessionUser}>
                <ReactTest />
              </RequireAuth>
            } />
            <Route path="/reactHookform3" element={
              <RequireAuth sessionUser={sessionUser}>
                <FormLocaleDemo />
              </RequireAuth>
            } />
            <Route path="/constructionWorkType" element={
              <RequireAuth sessionUser={sessionUser}>
                <ConstructionWorkTypeManagement />
              </RequireAuth>
            } />
            <Route path="/gunit" element={
              <RequireAuth sessionUser={sessionUser}>
                <GUnitManagement />
              </RequireAuth>
            } />
            <Route path="/gcomponentItem" element={
              <RequireAuth sessionUser={sessionUser}>
                <GComponentItemManagement />
              </RequireAuth>
            } />
            <Route path="/gcomponent" element={
              <RequireAuth sessionUser={sessionUser}>
                <GComponentManagement />
              </RequireAuth>
            } />
            <Route path="/gtype" element={
              <RequireAuth sessionUser={sessionUser}>
                <GTypeManagement />
              </RequireAuth>
            } />
            <Route path="/glasses" element={
              <RequireAuth sessionUser={sessionUser}>
                <GGlassManagement />
              </RequireAuth>
            } />
            <Route path="/gdomesticAuths" element={
              <RequireAuth sessionUser={sessionUser}>
                <GDomesticAuthManagement />
              </RequireAuth>
            } />
            <Route path="/gsubItem" element={
              <RequireAuth sessionUser={sessionUser}>
                <GSubItemManagement />
              </RequireAuth>
            } />
            <Route path="/gsub" element={
              <RequireAuth sessionUser={sessionUser}>
                <GSubManagement />
              </RequireAuth>
            } />
            <Route path="/gstandardTypes" element={
              <RequireAuth sessionUser={sessionUser}>
                <GStandardTypeManagement />
              </RequireAuth>
            } />
            <Route path="/gstandards" element={
              <RequireAuth sessionUser={sessionUser}>
                <GStandardManagement />
              </RequireAuth>
            } />
            <Route path="/g04docus" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuGCertifications" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuGCertificationManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuFiles" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuFileManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuAlarmSettings" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuAlarmSettingManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuGenerateFile" element={
              <RequireAuth sessionUser={sessionUser}>
                <GProjectManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuMaterialApproval" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuMaterialApprovalManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuGTests" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuGTestManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuGCertificationByGItemsOrGlasses" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuGCertificationByGItemOrGlassManagement />
              </RequireAuth>
            } />
            <Route path="/g04docuSearch" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuSearchManagement />
              </RequireAuth>
            } />
            {/* <Route path="/g04docuSearch1" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04docuSearchManagement1 />
              </RequireAuth>
            } /> */}
            <Route path="/gsendG04docus" element={
              <RequireAuth sessionUser={sessionUser}>
                <GSendG04docuManagement />
              </RequireAuth>
            } />
            <Route path="/gsendG04docus/download" element={
              // <RequireAuth sessionUser={sessionUser}>
                <GSendG04docuDownloadPage />
              // </RequireAuth>
            } />
            <Route path="/gclientTypes" element={
              <RequireAuth sessionUser={sessionUser}>
                <GClientTypeManagement />
              </RequireAuth>
            } />
            <Route path="/g04GeneralDocus" element={
              <RequireAuth sessionUser={sessionUser}>
                <G04GeneralDocuManagement />
              </RequireAuth>
            } />
            <Route path="/gclientType04docus" element={
              <RequireAuth sessionUser={sessionUser}>
                <GClientType04docuManagement />
              </RequireAuth>
            } />
            <Route path="/gclients" element={
              <RequireAuth sessionUser={sessionUser}>
                <GClientManagement />
              </RequireAuth>
            } />
            <Route path="/securitiesByGClient" element={
              <RequireAuth sessionUser={sessionUser}>
                <GSecurityByGClientManagement />
              </RequireAuth>
            } />
            <Route path="/gprocesses" element={
              <RequireAuth sessionUser={sessionUser}>
                <GProcessManagement />
              </RequireAuth>
            } />
            <Route path="/projects" element={
              <RequireAuth sessionUser={sessionUser}>
                <GProjectManagement />
              </RequireAuth>
            } />
            {/* <Route path="/gordersfromexcel" element={
              <RequireAuth sessionUser={sessionUser}>
                <GOrderExcel />
              </RequireAuth>
            } />
            <Route path="/gordersfromexceltest" element={
              <RequireAuth sessionUser={sessionUser}>
                <GOrderExcelTest />
              </RequireAuth>
            } /> */}
            <Route path="/gorders" element={
              <RequireAuth sessionUser={sessionUser}>
                <GOrderManagement />
              </RequireAuth>
            } />
            <Route path="/greceptions" element={
              <RequireAuth sessionUser={sessionUser}>
                <GReceptionManagement />
              </RequireAuth>
            } />
            <Route path="/gworkOrders" element={
              <RequireAuth sessionUser={sessionUser}>
                <GWorkOrderManagement />
              </RequireAuth>
            } />
            <Route path="/gplans" element={
              <RequireAuth sessionUser={sessionUser}>
                <GPlanManagement />
              </RequireAuth>
            } />
            <Route path="/gworkOrderStatus" element={
              <RequireAuth sessionUser={sessionUser}>
                <GWorkOrderStatusManagement />
              </RequireAuth>
            } />
            <Route path="/gworkOrderStatusByGLine" element={
              <RequireAuth sessionUser={sessionUser}>
                <GWorkOrderStatusByGLineManagement />
              </RequireAuth>
            } />
            <Route path="/glines" element={
              <RequireAuth sessionUser={sessionUser}>
                <GLineManagement />
              </RequireAuth>
            } />
            <Route path="/gworkOrderDetailProcesses" element={
              <RequireAuth sessionUser={sessionUser}>
                <GWorkOrderDetailProcessManagement />
              </RequireAuth>
            } />
            <Route path="/calendarDemo" element={
              <RequireAuth sessionUser={sessionUser}>
                <CalendarDemo />
              </RequireAuth>
            } />
            <Route path="/demoSelect" element={
              <RequireAuth sessionUser={sessionUser}>
                <FormSelectDemo />
              </RequireAuth>
            } />
          </Routes>
        )
      }
      {/* <StickyFooter /> */}
      { footer ? (pathname !== "/" && pathname !== "/intro" ? <CopyrightKFGWA /> : <Copyright />) : <></> }
      {
        error?.serverResponse?.details?.severityLevel >= 3 ? (
          <Snackbar
            open={error || false}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            TransitionComponent={SlideTransition}
            autoHideDuration={5000}
            onClose={handleClose}
            // message={error?.serverResponse?.details.message}
            // action={<IconButton
            //   size="small"
            //   aria-label="close"
            //   color="inherit"
            //   onClick={handleClose}
            // >
            //   <Close fontSize="small" />
            // </IconButton>}
          >
            <Alert
              onClose={handleClose}
              severity="warning" // success, info, warning, error
              variant="filled"
              sx={{ width: '100%' }}
            >
              {error?.serverResponse?.details.message}
            </Alert>
          </Snackbar>
        ) : (<></>)
      }
      <NotificationDialog>
        <Box sx={{ p: 1 }}>
          {"KFGWA 공사다큐 서비스를 사용하시는 고객님께 최초 1회 문서 업로드 서비스를 제공해 드립니다."}<br /><br />
          {"가지고 계신 서류를 아래 메일로 보내 주십시요."}<br /><br />
          {"보내실 메일 주소 "}<span style={{ color: "#1976d2", marginLeft: '10px' }}>{"wan@warpcore.co.kr"}</span>
          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} sx={cellStyleTop}>
              <Typography sx={{ p: 1, fontWeight: 'bold' }}>{"서류목록"}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
              <Typography sx={{ p: 1 }}>{"일반서류"}</Typography>
            </Grid>
            <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
              <Grid container sx={{ p: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 사업자등록증"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 국세 납세증명서"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 지방세 납세증명서"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 회사소개자료"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 납품실적"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 공장등록증"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 기타"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
              <Typography sx={{ p: 1 }}>{"그 외 서류"}</Typography>
            </Grid>
            <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
              <Grid container sx={{ p: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 인증서"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 성적서"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{"- 기타"}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </NotificationDialog>
      <ConsentDialog open={open} setOpen={setOpen} />
      <G04docuMaterialApprovalProgress />
    </>
  );

  if (error) {
    // error의 severityLevel에 따라 ErrorPage 또는 Snackbar로 표시
    if (error.serverResponse?.details?.severityLevel >= 3) { // 1. Snackbar로 표시
      return routes;
    } else { // 2. ErrorPage로 표시
      return (<>
        <Navigation isLoaded={false} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} />
        <ErrorPage error={error}/>
      </>);
    }
  } else { // 3. error가 없는 경우
    dispatch(errorActions.occurError(null)); // 이전에 발생한 에러 지워줌
    return routes;
  }
}

export default App;
