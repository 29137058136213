import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';

import { FormInputAutoComplete } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gclientActions from "../../store/gclient";
import Security from "../../components/Profile/Security";

const theme = createTheme();

const defaultValues = {
  gclient: {},
};

const GSecurityByGClientManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedGClientId, setSelectedGClientId] = useState("");
  const [selectedGClient, setSelectedGClient] = useState(null);
  const [gclients, setGClients] = useState([]);

  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const sessionUser = useSelector(state => state.session.sessionUser);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);

  const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues });

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllGClientDirect = () => gclientActions.selectAllDirect()

  useEffect(
    async () => {
      if (sessionUser) {
        const { id: gclientId, type } = sessionUser;
        const resGClients = await selectAllGClientDirect();
        setGClients(resGClients);

        setSelectedGClientId(gclientId);
        setSelectedGClient(resGClients.find(gclient => gclient.id === gclientId));
      }
    }, [dispatch]
  );
  
  const handleChangeGClient = (e, value) => {
    const gclientId = value.id;
    
    setSelectedGClientId(gclientId);
    setSelectedGClient(gclients.find(gclient => gclient.id === gclientId));
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={1}>
            {
              sessionUser.type === 'ADMIN' && (<Grid item xs={4} display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 1 }}>
                <FormInputAutoComplete
                  name={"gclient"}
                  control={control}
                  label={"거래선명"}
                  options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                  setValue={setValue}
                  onCustomChange={handleChangeGClient}
                />
              </Grid>)
            }
            {
              sessionUserRole === 'ADMIN_ROLE' && (
                <Grid item xs={12}>
                  <Security
                    from={
                      {
                        source: "GSecurityByGClientManagement",
                        params: {
                          gclientId: selectedGClientId,
                          // gclient: selectedGClient,
                        }
                      }
                    }
                  />
                </Grid>
              )
            }
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GSecurityByGClientManagement;
