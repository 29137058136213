import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_SECURITY_GCLIENTS_CLOSE = 'gclient/setSecurityCloseGClients';
const SET_SECURITY_GCLIENTS_OPEN = 'gclient/setSecurityOpenGClients';
const SET_SECURITY_OPENED_GCLIENTS_ABOUT_ME = 'gclient/setSecurityOpenedGClientsAboutMe';

const setSecurityCloseGClients = (gclients) => ({
  type: SET_SECURITY_GCLIENTS_CLOSE,
  payload: gclients,
});

const setSecurityOpenGClients = (gclients) => ({
  type: SET_SECURITY_GCLIENTS_OPEN,
  payload: gclients,
});

const setSecurityOpenedGClientsAboutMe = (gclients) => ({
  type: SET_SECURITY_OPENED_GCLIENTS_ABOUT_ME,
  payload: gclients,
});

export const selectSecurityCloseGClientList = (service, gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/selectSecurityCloseGClientList/${gclientId}`);
  const data = await response.json();
  dispatch(setSecurityCloseGClients(data.securityCloseGClients));
  return response;
}

export const selectSecurityCloseGClientListByBizRegNumberOrName = (service, gclientId, search) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/selectSecurityCloseGClientList/${gclientId}/search`, {
    method: "POST",
    body: JSON.stringify({ search }),
  });

  const data = await response.json();
  dispatch(setSecurityCloseGClients(data.securityCloseGClients));
  return response;
}

export const selectSecurityOpenGClientList = (service, gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/selectSecurityOpenGClientList/${gclientId}`);
  const data = await response.json();
  dispatch(setSecurityOpenGClients(data.securityOpenGClients));
  return response;
}

export const selectSecurityOpenGClientListByBizRegNumberOrName = (service, gclientId, search) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/selectSecurityOpenGClientList/${gclientId}/search`, {
    method: "POST",
    body: JSON.stringify({ search }),
  });

  const data = await response.json();
  dispatch(setSecurityOpenGClients(data.securityOpenGClients));
  return response;
}

export const addSecurityOpenGClientList = ({ service, gclientId, list }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/addSecurityOpenGClientList/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ list }),
  });
  const data = await response.json();
  dispatch(setSecurityOpenGClients(data.securityOpenGClients));
  return response;
}

export const removeSecurityOpenGClientList = ({ service, gclientId, list }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/removeSecurityOpenGClientList/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ list }),
  });
  const data = await response.json();
  dispatch(setSecurityOpenGClients(data.securityOpenGClients));
  return response;
}

export const selectOpenedAboutMeGClientList = (service, gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securities/query/${service}/selectOpenedAboutMeGClientList/${gclientId}`);
  const data = await response.json();
  dispatch(setSecurityOpenedGClientsAboutMe(data.securityOpenedGClientsAboutMe));
  return response;
}

export const selectOpenedAboutMeGClientListDirect = async (service, gclientId) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/securities/query/${service}/selectOpenedAboutMeGClientList/${gclientId}`);
  const data = await response.json();
  return data.securityOpenedGClientsAboutMe;
}

const initialState = {
  securityCloseGClients: {},
  securityOpenGClients: [],
  securityOpenedGClientsAboutMe: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SECURITY_GCLIENTS_CLOSE:
      newState = Object.assign({}, state, { securityCloseGClients: action.payload });
      return newState;
    case SET_SECURITY_GCLIENTS_OPEN:
      newState = Object.assign({}, state, { securityOpenGClients: action.payload });
      return newState;
    case SET_SECURITY_OPENED_GCLIENTS_ABOUT_ME:
      newState = Object.assign({}, state, { securityOpenedGClientsAboutMe: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
