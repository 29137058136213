import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GSTANDARD = 'gstandard/setGStandard';
const REMOVE_GSTANDARD = "gstandard/removeGStandard";
const SET_GSTANDARDS = 'gstandard/setGStandards';

const setGStandard = (gstandard) => ({
  type: SET_GSTANDARD,
  payload: gstandard,
});

const removeGStandard = () => ({
  type: REMOVE_GSTANDARD,
});

const setGStandards = (data) => ({
  type: SET_GSTANDARDS,
  payload: data,
});

export const create = ({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards`, {
    method: "POST",
    body: JSON.stringify({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }),
  });
  const data = await response.json();
  dispatch(setGStandard(data.gstandard));
  return response;
};

export const modify = ({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards`, {
    method: "PUT",
    body: JSON.stringify({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }),
  });
  const data = await response.json();
  dispatch(setGStandard(data.gstandard));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards`);
  const data = await response.json();
  dispatch(setGStandards(data));
  return response;
}

export const selectPaging = (queryPaging) => async dispatch => {
  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards/offset/${page}/limit/${pageSize}`);
  const data = await response.json();
  dispatch(setGStandards(data));
  return response;
}

export const selectAllByType = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards/type/${type}`);
  const data = await response.json();
  dispatch(setGStandards(data));
  return response;
}

export const selectAllByTypeDirect = async (type) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gstandards/type/${type}`);
  const data = await response.json();
  return data.gstandards;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards/${id}`);
  const data = await response.json();
  dispatch(setGStandard(data.gstandard));
  return response;
}

export const initGStandard = () => async dispatch => {
  dispatch(setGStandard(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gstandards/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGStandard());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gstandards/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gstandard: null,
  gstandards: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSTANDARD:
      newState = Object.assign({}, state, { gstandard: action.payload });
      return newState;
    case REMOVE_GSTANDARD:
      newState = Object.assign({}, state, { gstandard: null });
      return newState;
    case SET_GSTANDARDS:
      newState = Object.assign({}, state, { gstandards: action.payload.gstandards, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;
