// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GWORKORDER_PROCESSES = 'gworkOrder/setGWorkOrders';

const setGWorkOrderProcesses = (gworkOrderProcesses) => ({
  type: SET_GWORKORDER_PROCESSES,
  payload: gworkOrderProcesses,
});

export const selectGWorkOrderProcessesByQuery = (gworkOrderId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderProcesses/query/selectAll/gworkOrderId/${gworkOrderId}`);
  const data = await response.json();
  dispatch(setGWorkOrderProcesses(data.gworkOrderProcesses));
  return response;
}

export const selectGWorkOrderProcessesByQueryDirect = async (gworkOrderId) => {
  const response = await csrfFetch(null, `/api/gworkOrderProcesses/query/selectAll/gworkOrderId/${gworkOrderId}`);
  const data = await response.json();
  return data.gworkOrderProcesses;
}

export const selectGWorkOrderProcessDetailsByQuery = (gworkOrderId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderProcesses/query/selectAllDetails/gworkOrderId/${gworkOrderId}`);
  const data = await response.json();
  dispatch(setGWorkOrderProcesses(data.gworkOrderProcesses));
  return response;
}

export const selectGWorkOrderProcessDetailsByQueryDirect = async (gworkOrderId) => {
  const response = await csrfFetch(null, `/api/gworkOrderProcesses/query/selectAllDetails/gworkOrderId/${gworkOrderId}`);
  const data = await response.json();
  return data.gworkOrderProcesses;
}

const initialState = {
  gworkOrderProcesses: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GWORKORDER_PROCESSES:
      newState = Object.assign({}, state, { gworkOrderProcesses: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
