import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm, /*useFieldArray*/ } from "react-hook-form";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  Icon,
  Link,
  Typography,
  IconButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Divider,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Tooltip,
} from '@mui/material';
import {
  Delete,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  More,
  OpenInNew,
  Window,
  KeyboardArrowDown,
} from '@mui/icons-material';
// import OutputIcon from '@mui/icons-material/Output';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { loadCSS } from 'fg-loadcss';

import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { objectEmptyCheck } from "../../utils";
import usePrevious from "../hook/usePrevious";
import {
  FormInputDate,
  FormInputDateRangePicker,
  FormInputSwitch,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { NavigationGuard } from "../Navigation";
import GClientManagement from "../GClient/GClientManagement";
import GGlassManagement from "../GGlass/GGlassManagement";
import GOrderManagement from "../GOrder/GOrderManagement";
import * as gprojectActions from "../../store/gproject";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientActions from "../../store/gclient";
import * as gglassActions from "../../store/gglass";
import * as gtypeDetailActions from "../../store/gtypeDetail";

import { GClientList } from "../GClient";
import GProjectGlassDialog from "./GProjectGlassDialog";

const today = new Date();
let endDate = new Date();
endDate.setFullYear(today.getFullYear() + 2);

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  // name: "",
  // code: "",
  site: "",
  siteAddress: "",
  constructionCompanyName: "",
  startDate: today.getDateWithStartHours(),
  endDate: endDate.getDateWithEndHours(),
  completionDate: endDate.getDateWithEndHours(),
  comments: "",
  doneYN: false,
};

const GProjectDialog = ({
  modify,
  setModify,
  open,
  setOpen,
  selectedRow,
  // gtypeDetailsWithGComponent,
  gtypes,
  // selectedGcomponentItems,
  // setSelectedGcomponentItems,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [dateValue, setDateValue] = useState(new Date()); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
  const [checkedDoneYN, setCheckedDoneYN] = useState(false);
  // const [periodValue, setPeriodValue] = useState([null, null]); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
  const [fullScreen, setFullScreen] = useState(false);
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [clientExpanded, setClientExpanded] = useState(true);
  const [glassExpanded, setGlassExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [saveMode, setSaveMode] = useState('');
  const [gclientTypes, setGclientTypes] = useState([]);

  const [showGlassDetail, setShowGlassDetail] = useState(false);
  const [errorsGGlass, setErrorsGGlass] = useState([]);
  const [modifyGGlass, setModifyGGlass] = useState(false);
  // const [selectedRowGGlass, setSelectedRowGGlass] = useState({});
  // const [from, setFrom] = useState("");
  const [openGGlass, setOpenGGlass] = useState(false);
  const [gGlass, setGGlass] = useState({});

  // TODO : 추후 react hook form의 useFieldArray 방식을 쓸 수 있는지 검토
  const [gclientDetails, setGclientDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false); // 데이터 수정중 일 경우 페이지 이동시 안내
  
  const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});

  const [drawerState, setDrawerState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    // right: false,
  });
  const prevDrawerState = usePrevious(drawerState);

  const [params, setParams] = useState({});

  const [show, setShow] = useState(true);
  const [gtypeDetailsWithGComponentLength, setGtypeDetailsWithGComponentLength] = useState(0);

  const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  
  // let dialogTitleRect = {};

  const handleChange = (e, val, gclients, gclientCode) => {
    if (["gclientId"].includes(e.target.name)) { // TODO : DB분리대상???
      const newGclientDetails = gclientDetails.map(detail => {
        if (detail.code === gclientCode) {
          const newDetail = detail.gclients.map(item => {
            if (item.index === val.index) {
              let value = [];
              if (gclients) {
                value = gclients.filter(gclient => gclient.id === e.target.value);
              }
  
              // TODO : 추후 value ...로 빼는 방법 고려
              return {
                ...item,
                value: value.length > 0 ? value[0] : {},
              };
            } else {
              return item;
            }
          });

          return {
            code: detail.code,
            gclients: newDetail,
          }
        } else {
          return detail;
        }
      });
      // console.log(newGclientDetails);
      setGclientDetails(newGclientDetails);

      checkEdit({ newRight: null, newGclientDetails });
    }
  };

  const addNewRow = (gclientCode) => {
    const newGclientDetails = gclientDetails.map(detail => {
      if (detail.code === gclientCode) {
        return {
          code: detail.code,
          gclients: detail.gclients.concat([{ index: uuidv4(), value: {} }]),
        }
      } else {
        return detail;
      }
    });

    setGclientDetails(newGclientDetails);

    checkEdit({ newRight: null, newGclientDetails });
  };

  const clickOnDelete = (record, gclientCode) => {
    const newGclientDetails = gclientDetails.map(detail => {
      if (detail.code === gclientCode) {
        const newDetail = detail.gclients.filter(item => item !== record); // 비교 대상 객체가 같은 객체이므로 객체비교를 === 연산자로 하는 것이 가능
        
        return {
          code: detail.code,
          gclients: newDetail,
        }
      } else {
        return detail;
      }
    });

    setGclientDetails(newGclientDetails);

    checkEdit({ newRight: null, newGclientDetails });
  }

  const handleDialogClose = () => {
    // TODO : 수정한 것이 있다면 닫기 전 confirm 창 띄울 것
    setOpen(false);
    setMode('all');

    if (saveMode === "save") { // 저장(저장 후 닫기 아님) 후 닫기 시 목록 refresh 필요
      // refresh();
      const { pathname } = location;
      if (pathname === "/projects") {
        refresh(false);
      } else if (pathname === "/g04docuGenerateFile") {
        refresh(true);
      }
    }
  };

  const handleChangeDoneYN = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요 => FormInputDropdown1과 FormInputDate1은 set~함수를 만들고 외부에서 호출하도록 구현(검토 필요)
    setValue("doneYN", !checkedDoneYN)
    setCheckedDoneYN(!checkedDoneYN);
    checkEdit({ newRight: null, newGclientDetails: null });
  }

  const handleDialogMinMax = () => {
    setFullScreen(!fullScreen);
  }
  
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // TODO : 현재 react hook form의 경우 배열에서 해당 인덱스의 값 삭제시 컴포넌트의 value 값 문제로 사용하고 있지 않음
  // const { fields, append, replace, remove } = useFieldArray({ control: control, name: "gclientIds" });
  // const dialogRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();

  const gclients = useSelector((state) => state.gclient.gclients);
  const gproject = useSelector((state) => state.gproject.gproject);
  const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);
  
  // 데이터 관리
  const selectAllGClient = () => dispatch(gclientActions.selectAll());
  const addGProjectWithResult = ({ id/*, name, code*/, site, siteAddress, startDate, endDate, completionDate, gclientDetails, constructionCompanyName, comments, doneYN, selectedGlasses }) => gprojectActions.createWithResult({ id/*, name, code*/, site, siteAddress, startDate, endDate, completionDate, gclientDetails, constructionCompanyName, comments, doneYN, selectedGlasses })
  const modifyGProjectWithResult = ({ id, /*name, code, */ site, siteAddress, startDate, endDate, completionDate, gclientDetails, constructionCompanyName, comments, doneYN, selectedGlasses }) => gprojectActions.modifyWithResult({ id, /*name, code, */ site, siteAddress, startDate, endDate, completionDate, gclientDetails, constructionCompanyName, comments, doneYN, selectedGlasses })
  const selectAllGClientTypeDirect = () => gclientTypeActions.selectAllDirect();
  const selectAllGClientTypeByProjectYNDirect = (projectYN) => gclientTypeActions.selectAllByProjectYNDirect(projectYN);
  const selectDirectGlass = (id) => gglassActions.selectDirect(id);

  // 유리 등록 및 수정관련
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))

  const onSubmit = ({ id, /*name, code, */ site, siteAddress, constructionCompanyName, startDate, endDate, completionDate, comments, doneYN }, mode) => {
    // TODO : validation 처리 필요. 특히 날짜 등...
    setErrors([]);

    setSaveMode(mode);

    // TODO : 지금은 2
    // 1. startDate, endDate의 데이터 타입은 string, 2022-12-08T01:29:08.157Z
    // 2. startDate, endDate의 데이터 타입은 object
    console.log({ id, /*name, code, */ site, siteAddress, gclientDetails, constructionCompanyName, startDate, endDate, completionDate, comments, doneYN, selectedGlasses: right, mode })
    // console.log(typeof endDate);
    // console.log(startDate.toUTCString());
    // console.log(endDate.toUTCString());
    // return;

    let func;
    if (modify) {
      func = modifyGProjectWithResult;
    } else {
      func = addGProjectWithResult;
    }

    if (mode === 'save') {
      setLoading(true);
    }

    func({ id, /*name, code, */ site, siteAddress, startDate/*: startDate.getDateWithStartHours()*/, endDate/*: endDate.getDateWithEndHours()*/, completionDate, gclientDetails, constructionCompanyName, comments, doneYN, selectedGlasses: right })
      .then (res => {
        if (mode === 'saveAndClose') {
          handleDialogClose();
          // refresh();
          const { pathname } = location;
          if (pathname === "/projects") {
            refresh(false);
          } else if (pathname === "/g04docuGenerateFile") {
            refresh(true);
          }
        } else { // mode === 'save'
          setTimeout(() => setLoading(false), 1000);
          console.log(res);
          // console.log(gproject);
          setModify(true);
          setValue("id", res.gproject.id);

          // TODO : 초기화(페이지 나갈때 변경여부 묻지 않도록) 필요하고, 닫기시 목록의 refresh 필요함
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  function not(a, b) {
    return a.filter((value) => b.map(item => item.id).indexOf(value.id) === -1);
  }
  
  // function intersection(a, b) {
  //   return a.filter((value) => b.map(item => item.id).indexOf(value.id) !== -1);
  // }
  
  // function union(a, b) {
  //   return [...a, ...not(b, a)];
  // }

  // const [checkedGlasses, setCheckedGlasses] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  // const leftChecked = intersection(checkedGlasses, left);
  // const rightChecked = intersection(checkedGlasses, right);

  // const handleToggle = (value) => () => {
  //   const currentIndex = checkedGlasses.map(item => item.id).indexOf(value.id);
  //   const newChecked = [...checkedGlasses];
    
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
    
  //   setCheckedGlasses(newChecked);
  // };

  // const numberOfChecked = (items) => intersection(checkedGlasses, items).length;

  // const handleToggleAll = (items) => () => {
  //   if (numberOfChecked(items) === items.length) {
  //     setCheckedGlasses(not(checkedGlasses, items));
  //   } else {
  //     setCheckedGlasses(union(checkedGlasses, items));
  //   }
  // };

  // const handleCheckedRight = () => {
  //   setRight(right.concat(leftChecked));
  //   setLeft(not(left, leftChecked));
  //   setCheckedGlasses(not(checkedGlasses, leftChecked));
  // };

  const handleRegisterGGlassToProject = (gGlass) => {
    // console.log(gGlass);
    // TODO : id 중복체크 필요
    const newGGlass = Object.assign({}, gGlass);
    newGGlass.gglassId = gGlass.gglassId;

    console.log("right");
    console.log(right);
    console.log("To Register Glass");
    console.log(newGGlass);
    const newRight = right.concat([newGGlass])
    console.log("Registered Glasses");
    console.log(newRight);
    setRight(newRight);

    console.log(left);

    checkEdit({ newRight, newGclientDetails: null });
  };

  const handleModifyGGlassToProject = (gGlass) => {
    // console.log(gGlass);
    // console.log(right);
    const newRight = right.map(glass => {
      const newGGlass = Object.assign({}, glass.id === gGlass.id ? gGlass : glass);
      return newGGlass;
    });
    
    // console.log(newRight);
    setRight(newRight);

    checkEdit({ newRight, newGclientDetails: null });
  }

  const handleDeleteGGlassToProject = (e, gGlass) => {
    e.stopPropagation();

    setRemoveObject(gGlass);
    setConfirmOpen(true);

    // const newRight = not(right, [gGlass]);
    // setRight(newRight);

    // checkEdit({ newRight, newGclientDetails: null })
  };

  const deleteGGlassToProject = (gGlass) => {
    const newRight = not(right, [gGlass]);
    setRight(newRight);

    checkEdit({ newRight, newGclientDetails: null })
  };

  // const handleCheckedLeft = () => {
  //   setLeft(left.concat(rightChecked));
  //   setRight(not(right, rightChecked));
  //   setCheckedGlasses(not(checkedGlasses, rightChecked));
  // };

  const handleGlassDetails = async (e, value, side) => {
    e.stopPropagation();

    // 타입별 세부 구성을 미리 준비해두고 세부 다이얼로그를 오픈해야 속성값이 설정됨
    await selectGTypeDetailsWithGComponent(value.gtypeId);
    
    console.log(value);
    // state를 하나로 합쳐서 반영. 자식 컴포넌트에 state를 따로따로 전달시 로직에 따라 각 state를 동시에 사용할 때 문제가 될 수 있음
    setGGlass({
      ...value,
      side,
    });

    if (side === 'left') {
      const gglass = await selectDirectGlass(value.id);
      setSelectedGcomponentItems(gglass.selectedGcomponentItems);
    } else if (side === 'right') {
      setSelectedGcomponentItems(value.selectedGcomponentItems);
    }

    // setFrom(side);
    setOpenGGlass(true);
  }

  const getNamePositionView = (labelPositionView) => {
    let namePositionView = "";
    if (labelPositionView === 'inout') {
      namePositionView = "실내";
    } else if (labelPositionView === 'outin') {
      namePositionView = "실외";
    }

    return namePositionView;
  }

  const getNamePosition = (labelPosition) => {
    let namePosition = "";
    if (labelPosition === 'TopLeft') {
      namePosition = "좌상";
    } else if (labelPosition === 'TopRight') {
      namePosition = "우상";
    } else if (labelPosition === 'BottomLeft') {
      namePosition = "좌하";
    } else if (labelPosition === 'BottomRight') {
      namePosition = "우하";
    }

    return namePosition;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newRight = reorder(
      right,
      result.source.index,
      result.destination.index
    );

    setRight(newRight);

    checkEdit({ newRight, newGclientDetails: null });
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    // const { offsetLeft, offsetTop } = dialogRef.current;

    const dialogPosition = document.getElementById('dialog-position');
    if (dialogPosition) {
      const { left, top } = dialogPosition.getBoundingClientRect();
      // console.log(dialogTitleRect);
      if (isDragging) {
        // 클라이언트 윈도우 기준이므로 다이얼로그 상에서는 다이얼로그의 left, top을 빼주어야 한다.
        // 그러나 여기서 다이얼로그는 실제로 화면 전체(block부분포함)이므로 다이얼로그 제목에 div를 추가하여 그 위치로 계산했다.
        draggableStyle.left = draggableStyle.left - left + 16;
        draggableStyle.top = draggableStyle.top - top + 16;
      }
    }

    return {
      background: isDragging ? "#42a5f5" : "",
      ...draggableStyle,
    }
  }

  const GlassList = (title, items, side) => {
    // console.log(side)
    // console.log(left)
    // console.log(right)
    // items = selectedRow?.selectedGlasses.map(glass => glass.id);
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1, bgcolor: "#a1a1a1", color: "white" }}
          title={<Typography variant="subheader1">{title}</Typography>}
        />
        <Divider />
        {
          side === "left" && (
            <List
              sx={{
                // width: 200,
                height: 500,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
              component="div"
              role="list"
            >
              <ListItem>
                <ListItemText
                  // id={labelId}
                  primary={
                    <Grid container spacing={1}>
                      <Grid item sm={12} display="flex" justifyContent="center">
                        {"템플릿명"}
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              {items?.map((value, idx) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                
                return (
                  <ListItem
                    key={value}
                    role="listitem"
                    secondaryAction={
                      <IconButton edge="end" aria-label="openInNew" onClick={(e) => handleGlassDetails(e, value, side)}>
                        <OpenInNew />
                      </IconButton>
                    }
                    sx={{
                      ':hover': {
                        // bgcolor: 'primary.main', // theme.palette.primary.main
                        bgcolor: '#cacaca',
                        // color: 'white',
                      },
                      bgcolor: idx%2 ? "#fafafa" : "#eaeaea",
                      cursor: 'pointer'
                    }}
                    onClick={(e) => e.detail === 2 && handleGlassDetails(e, value, side)} // double click only
                    // onDoubleClick={(e) => handleGlassDetails(e, value, side)}
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <Grid container spacing={1}>
                          <Grid item sm={12}>
                            {value.name}
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          )
        }
        {
          side === "right" && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    sx={{
                      // width: 200,
                      height: 500,
                      bgcolor: 'background.paper',
                      overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                  >
                    <ListItem
                      secondaryAction={
                        <>
                          {"기능"}
                        </>
                      }
                    >
                      <ListItemText
                        // id={labelId}
                        primary={
                          <Grid container spacing={1}>
                            <Grid item sm={1} display="flex" justifyContent="center">
                              {"품번"}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item sm={2} display="flex" justifyContent="center">
                              {"품명"}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item sm={3} display="flex" justifyContent="center">
                              {"규격"}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item sm={3} display="flex" justifyContent="center">
                              {"사양"}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item sm={2} display="flex" justifyContent="center">
                              {"라벨위치"}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                          </Grid>
                        }
                      />
                    </ListItem>
                    {items?.map((value, idx) => {
                      const labelId = `transfer-list-all-item-${value}-label`;
                      // console.log(value);
                      return (
                        <Draggable key={value.id} draggableId={value.id} index={idx}>
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              key={value}
                              role="listitem"
                              secondaryAction={
                                <>
                                  <IconButton edge="end" aria-label="edit" onClick={(e) => handleGlassDetails(e, value, side)}>
                                    <Edit />
                                  </IconButton>
                                  <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteGGlassToProject(e, value)}>
                                    <Delete />
                                  </IconButton>
                                </>
                              }
                              sx={{
                                ':hover': {
                                  // bgcolor: 'primary.main', // theme.palette.primary.main
                                  bgcolor: '#cacaca',
                                  // color: 'white',
                                },
                                bgcolor: idx%2 ? "#fafafa" : "#eaeaea"
                              }}
                              onClick={(e) => e.detail === 2 && handleGlassDetails(e, value, side)} // double click only
                            >
                              <ListItemText
                                id={labelId}
                                primary={
                                  <Grid container spacing={1}>
                                    <Grid item sm={1}>
                                      {/* {value.id} */}
                                      {value.no}
                                    </Grid>
                                    <Grid item sm={2}>
                                      {value.name}
                                    </Grid>
                                    <Grid item sm={3}>
                                      {/* {value.gtypeName} */}
                                      {value.standard}
                                    </Grid>
                                    <Grid item sm={3}>
                                      {value.specification}
                                      {/* {
                                        value.specification && (
                                          <Box sx={{ borderRadius: '5px', bgcolor: '#cacaca', p: 1 }}>
                                            {value.specification}
                                          </Box>
                                        )
                                      } */}
                                    </Grid>
                                    <Grid item sm={2} display="flex" justifyContent="center">
                                      {`${getNamePositionView(value.labelPositionView)} / ${getNamePosition(value.labelPosition)}`}
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </ListItem>
                          )}
                        </Draggable>
                      )
                      })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )
        }
      </Card>
    );
  }

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    if (newMode) {
      if (newMode === 'prev') {
        setMode('selecting');
      } else {
        setMode(newMode);
      }
      if (newMode !== 'detail') {
        setShowGlassDetail(false);
      }
    }
  }

  const generateGGlasses = async () => {
    const glasses = await gglassActions.selectAllByQueryDirect();
    const selected = selectedRow?.selectedGlasses || [];
    // console.log(glasses);
    // console.log(selected);
    setLeft(glasses); // 유리 샘플
    setRight(selected); // 샘플에서 선택하여 프로젝트에 등록된(될) (샘플에서 구체적 속성이 적용된) 유리제품
  }

  const generateClientSummary = (gclientTypes, gclientDetails) => {
    // console.log(gclientTypes)
    // console.log(gclientDetails)
    // let summary = "";
    // gclientTypes.forEach(type => {
    //   gclientDetails.forEach(detail => {
    //     if (type.code === detail.code) {
    //       const gclients = detail.gclients.filter(client => client.value?.id ? true : false);
    //       if (gclients.length > 0) {
    //         summary = summary + `${type.name}(${gclients.length}) `;
    //       }
    //     }
    //   })
    // })
    // return summary;

    const arr = [];
    gclientTypes.forEach(type => {
      gclientDetails?.forEach(detail => {
        if (type.code === detail.code) {
          const gclients = detail.gclients.filter(client => client.value?.id ? true : false);
          if (gclients.length > 0) {
            arr.push(
              <Badge color="primary" /*overlap="circular"*/ badgeContent={gclients.length}>
                <Chip label={`${type.name}`} />
              </Badge>
            )
            // summary = summary + `${type.name}(${gclients.length}) `;
          }
        }
      })
    })

    return (
      <Stack direction="row" spacing={2}>
        {arr}
      </Stack>
    );
  }

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
      // Inject before JSS
      document.querySelector('#font-awesome-css') || document.head.firstChild,
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(
    async () => {
      // const gclientTypes = await selectAllGClientTypeDirect();
      const gclientTypes = await selectAllGClientTypeByProjectYNDirect(true);

      // 거래서 구분 기준으로 한 초기 데이터 구성
      const newGclienDetails = gclientTypes.map(type => {
        return {
          code: type.code,
          gclients: [{ index: uuidv4(), value: {} }],
        }
      });

      setGclientTypes(gclientTypes);
      setGclientDetails(newGclienDetails);

      /*await */selectAllGClient();

    }, [dispatch]
  );

  useEffect(
    async () => {
      // console.log(selectedRow)
      setLoading(false);
      
      // selectedRow &&
      // TODO : 객체 루프로 변경할 것
      ["id", /*"name", "code",*/ "site", "siteAddress", "startDate", "endDate", "completionDate", "gclientDetails", "constructionCompanyName", "comments", "doneYN"].forEach(item => {
        if (item === "doneYN") {
          // value 값이 있는 form control의 경우 (react hook form) setValue("name", value)로 값이 설정되나
          // value 값이 없는 경우 setValue로 form control의 설정상태가 바뀌지 않으므로 아래와 같이 처리
          // TODO : 작성한 form control(src/components/form) 안에서 setValue 시 적용된 값을 설정하는 방법 연구 필요
          setCheckedDoneYN(selectedRow && selectedRow[item] || false);
          // setValue(item, selectedRow && selectedRow[item] || ""); // 값 자체가 false 인데 ||로 인해 ""로 바뀜
          // setValue(selectedRow && selectedRow[item]) // 실수로 이렇게 했었는데 path.split is not a function 발생 (첫번째 인자 오류)
          setValue(item, selectedRow && selectedRow[item] || false);
        // } else if (item === "period") {
        //   // 저장된 기간 출력
        //   if (selectedRow["period"]) {
        //     setPeriodValue(selectedRow["period"]);
        //     setValue(item, selectedRow["period"]);
        //   }
        } else if (item === "startDate") {
          // selectedRow[item] : string, 2022-12-08T01:29:08.157Z
          // defaultValues.startDate : object, Fri Jan 06 2023 12:36:47 GMT+0900 (한국 표준시)
          setValue(item, selectedRow && (new Date(selectedRow[item])).getDateWithStartHours() || defaultValues.startDate);
        } else if (item === "endDate") {
          setValue(item, selectedRow && (new Date(selectedRow[item])).getDateWithEndHours() || defaultValues.endDate);
        } else if (item === "completionDate") {
          setValue(item, selectedRow && (new Date(selectedRow[item])).getDateWithEndHours() || defaultValues.completionDate);
        } else if (item === "gclientDetails") {
          // 저장된 거래선 출력
          setGclientDetails(selectedRow && selectedRow[item]);
        } else if (item === "id") {
          console.log(selectedRow)
          setValue(item, selectedRow && selectedRow[item] || uuidv4());
        } else {
          setValue(item, selectedRow && selectedRow[item] || "");
        }
      })
      // initDialog(); // 등록화면 초기화
      
      // 유리 목록 구성
      generateGGlasses();
      
      if (modify) {
        setBasicExpanded(false);
        setClientExpanded(false);
      } else {
        // setBasicExpanded(true);
        // setClientExpanded(true);
      }
    }, [selectedRow]
  );

  const initDialog = () => {
    // 프로젝트 선택하고 다이얼로그 띄운 후 닫고 다시 등록창 띄운 경우 초기화. 
    let initGclienDetails = [];
    const init = {};
    // TODO : 객체 루프로 변경할 것
    ["id", /*"name", "code",*/ "site", "siteAddress", "startDate", "endDate", "completionDate", "gclientDetails", "constructionCompanyName", "comments", "doneYN"].forEach(item => {
      if (item === "doneYN") {
        init[item] = false;
        setValue(item, false);
        setCheckedDoneYN(false);
      } else if (item === "startDate") {
        init[item] = defaultValues.startDate;
        setValue(item, defaultValues.startDate);
      } else if (item === "endDate") {
        init[item] = defaultValues.endDate;
        setValue(item, defaultValues.endDate);
      } else if (item === "completionDate") {
        init[item] = defaultValues.completionDate;
        setValue(item, defaultValues.completionDate);
      } else if(item === "gclientDetails") {
        // 거래선 구분 기준으로 한 초기 데이터 구성
        initGclienDetails = gclientDetails.map(gclientDetail => {
          return {
            ...gclientDetail,
            gclients: [{ index: uuidv4(), value: {} }],
          }
        });
        init[item] = initGclienDetails;
        setGclientDetails(initGclienDetails);
      } else if (item === "id") {
        setValue(item, uuidv4());
      } else {
        init[item] = "";
        setValue(item, "");
      }
    });
    
    init["selectedGlasses"] = [];
    init["order"] = undefined;
    init["orderDetail"] = undefined;
    init["createdAt"] = undefined;
    init["updatedAt"] = undefined;
    
    console.log(init);
    
    setInitData(init);

    setBasicExpanded(true);
    setClientExpanded(true);
  }

  // useEffect(
  //   () => {
  //     if (!basicExpanded) {
        
  //     }
  //   }, [basicExpanded]
  // )

  // useEffect(
  //   () => {
  //     if (!clientExpanded) {
        
  //     }
  //   }, [clientExpanded]
  // )

  useEffect(
    () => {
      // console.log(`modify: ${modify}, opne: ${open}`);
      if (open) { // 다이얼로그가 열릴 때
        if (!modify) { // 등록인 경우 기존 데이터 초기화
          initDialog();
        }
      }
    }, [open]
  )

  // TODO : 기존 데이터와 수정된 데이터를 전체 비교. 전체 비교하여 이 기능을 사용할지 말지는 선택하게 하는 방법 검토
  const checkEdit = (param) => {
    const { newRight, newGclientDetails } = param ? param : { newRight: null, newGclientDetails: null };
    console.log(">>>>>>>>>>>>>>>>>>>>>> previous data")
    const prevData = selectedRow ? selectedRow : initData;
    console.log(prevData)
    console.log(">>>>>>>>>>>>>>>>>>>>>> present data")
    const newData = {
      id: getValues('id'),
      site: getValues('site'),
      siteAddress: getValues('siteAddress'),
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
      comments: getValues('comments'), 
      doneYN: getValues('doneYN'),
      gclientDetails: newGclientDetails ? newGclientDetails : gclientDetails,
      selectedGlasses: newRight ? newRight : right,
      order: prevData.order,
      orderDetail: prevData.orderDetail,
      createdAt: prevData.createdAt,
      updatedAt: prevData.updatedAt,
    };
    console.log(newData)
    // const difference = diff(prevData, newData);
    // console.log(">>>>>>>>>>>>>>>>>>>>>> difference")
    // console.log(difference)
    // TODO : 객체 비교시 비교대상이 아닌 속성은 검토해서 최종 변경 여부 판단
    const detailedDifference = detailedDiff(prevData, newData) // returns an object with the added, deleted and update
    console.log(">>>>>>>>>>>>>>>>>>>>>> detailedDifference")
    console.log(detailedDifference)

    // TODO : 객체의 모든 속성(하위 객체 포함)의 값까지 100% 점검하지는 않으므로 혹시 그렇게 하려면 고도화 필요
    const { added, deleted, updated } = detailedDifference;
    if (objectEmptyCheck(added) && objectEmptyCheck(deleted) && objectEmptyCheck(updated)) {
      setEditing(false);
    } else {
      setEditing(true);
      // let editedSelectedGlasses = true;
      // let editedgclientDetails = true;
      // let editedEtc = true;

      // let editedAdd = !objectEmptyCheck(added);
      // let editedDelete = !objectEmptyCheck(deleted);
      // // gclientDetails에는 다이얼로그가 나타날때 그 안의 index값이 변하므로 실제로 내용을 수정하지 않아도 다르게 나타남
      // // 따라서 index만 변했다면 수정이 되지 않았다고 판단
      // const { gclientDetails } = updated;
      // if (gclientDetails) {
      //   const updatedGclientDetails = [];
      //   for (const [key, value] of Object.entries(gclientDetails)) {
      //     const gclientDetail = value;
      //     for (const [key, value] of Object.entries(gclientDetail)) {
      //       const gclients = value;
      //       for (const [key, value] of Object.entries(gclients)) {
      //         const gclient = value;
      //         const keys = Object.keys(gclient);
      //         if (!(keys.length ===1 && keys[0] === "index")) {
      //           // console.log(gclient)
      //           updatedGclientDetails.push("updated");
      //         }
      //       }
      //     }
      //   }
        
      //   if (updatedGclientDetails.length <= 0) {
      //     editedgclientDetails = false;
      //   }

      //   // console.log(Object.keys(updated))
      //   // console.log(Object.entries(defaultValues).map(item => item[0]));
      //   const a = Object.keys(updated);
      //   const b = Object.entries(defaultValues).map(item => item[0]);

      //   const result = a.filter((value) => b.map(item => item).indexOf(value) !== -1);
      //   if (result.length <= 0) {
      //     editedEtc = false;
      //   }

      // }

      // console.log(`editedSelectedGlasses: ${editedSelectedGlasses}, editedgclientDetails: ${editedgclientDetails}, editedEtc: ${editedEtc}`);
      // setEditing(editedAdd || editedDelete || /*editedSelectedGlasses || */editedgclientDetails || editedEtc);
    }
  }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    return true;
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerState({ ...drawerState, [anchor]: open });

    if (!open) {
      setShow(true);
    }
  };

  const actions = [
    { icon: <Window />, name: '유리 템플릿 관리', code: "GGLASS", component: <GGlassManagement title={"템플릿"} /> },
    { icon: <Icon baseClassName="fas" className="fa-building" fontSize="small" />, name: '거래선 관리', code: "GCLIENT", component: <GClientManagement title={"거래선"} /> },
  ];

  // 아래 두가지 방식 검토
  const handleSpeedDialActionClick = (action) => (e) => {
  // const handleSpeedDialActionClick = (e, action) => {
    // setOpen(false);
    setShow(false);
    toggleDrawer(action.code, true)(e);
  }

  useEffect(
    async () => {
      // console.log(prevDrawerState);
      // console.log(drawerState);
      if (prevDrawerState?.GGLASS === true && drawerState?.GGLASS === false ) {
        const glasses = await gglassActions.selectAllByQueryDirect();
        setLeft(glasses); // 유리 샘플
      } else if (prevDrawerState?.GCLIENT === true && drawerState?.GCLIENT === false ) {
        selectAllGClient();
      }
    }, [drawerState]
  )

  useEffect(
    () => {
      if (gtypeDetailsWithGComponent.length !== gtypeDetailsWithGComponentLength) {
        setGtypeDetailsWithGComponentLength(gtypeDetailsWithGComponent.length);
      }
    }, [gtypeDetailsWithGComponent]
  )

  const handleOrder = (e) => {
    const gprojectId = getValues("id");
    const site = getValues("site");
    setParams({
      gprojectId,
      site,
    });
    setShow(false);
    toggleDrawer("GORDER", true)(e);
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          onMinMax={handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          <div id="dialog-position" /*ref={dialogRef}*/>
          {/* <div
            id="dialog-position"
            ref={el => {
              if (el) {
                dialogTitleRect = el.getBoundingClientRect();
                // setDialogTitleBoundingClientRect()
              }
            }}
          > */}
            {modify ? "프로젝트 수정" : "프로젝트 등록"}
          </div>
          <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ position: 'absolute', top: 61, right: 24 }}
              icon={<SpeedDialIcon />}
              FabProps={{ size: "small" }}
              direction="left"
            >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={handleSpeedDialActionClick(action)}
                // onClick={(e) => handleSpeedDialActionClick(e, action)}
              />
            ))}
          </SpeedDial>
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <NavigationGuard when={editing} />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                <AccordionSummary aria-controls="basic-content" id="basic-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Typography variant="h6" component="div">{"기본정보"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} display="flex" alignItems="center">
                      {
                        !basicExpanded && (
                          // <Typography variant="body" component="div" color="primary">
                          //   {`현장명: ${getValues('site')}, 현장주소: ${getValues('siteAddress')}`}
                          // </Typography>
                          <Stack direction="row" spacing={2}>
                            <Chip label={"현장명"} />
                            <Grid display="flex" alignItems="center">
                              {getValues('site')}
                            </Grid>
                            <Chip label={"현장주소"} />
                            <Grid display="flex" alignItems="center">
                              {getValues('siteAddress')}
                            </Grid>
                          </Stack>
                        )
                      }
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"id"}
                        control={control}
                        label={"아이디"}
                        sx={{ display: 'none' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name={"site"}
                        control={control}
                        label={"현장명"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name={"siteAddress"}
                        control={control}
                        label={"현장주소"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormInputDate
                            name="startDate"
                            control={control}
                            label={"시작"}
                            onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                            value={getValues("startDate")}
                            setValue={setValue}
                            onEdit={checkEdit}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInputDate
                            name="endDate"
                            control={control}
                            label={"마감"}
                            onChangePeriodValue={(value) => handleChangePeriod(value, "endDate")}
                            value={getValues("endDate")}
                            setValue={setValue}
                            onEdit={checkEdit}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
                      <div style={{ paddingLeft: '20px', backgroundColor: checkedDoneYN ? "#5dc061" : "#d32f2f", color: 'white', borderRadius: '5px' }}>
                        <FormInputSwitch
                          name={"doneYN"}
                          checked={checkedDoneYN}
                          onChange={handleChangeDoneYN}
                          control={control}
                          label={"마감여부"}
                          color="success"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"comments"}
                        control={control}
                        label={"설명"}
                        multiline
                        maxRows={5}
                        onEdit={checkEdit}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion expanded={clientExpanded} onChange={() => setClientExpanded(!clientExpanded)}>
                <AccordionSummary aria-controls="client-content" id="client-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                    <Typography variant="h6" component="div">{"관련업체"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} display="flex" alignItems="center">
                      {
                        !clientExpanded && (
                          <Typography variant="body" component="div" color="primary">
                            {generateClientSummary(gclientTypes, gclientDetails)}
                          </Typography>
                        )
                      }
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                  {
                    gclientTypes?.map((gclientType, idx) => {
                      // 거래선 구분별로 목록을 다르게 구성해야 하므로 목록을 필터링
                      const gclientsByType = gclients.filter(gclient => {
                        const filtered = gclient.gclientTypes?.filter(item => item.code === gclientType.code);
                        return filtered?.length;
                        // if (filtered.length > 0) {
                        //   return true;
                        // } else {
                        //   return false;
                        // }
                      });
                      
                      const clientComponent = gclientsByType?.length > 0 ? (
                        <Grid item xs={12} sm={6} key={idx}>
                          <Card>
                            {/* TODO : 세로로 데이터가 화면에 너무 복잡해보여 삭제함. 필요시 아래 코드 주석 풀 것 */}
                            {/* <CardHeader
                              titleTypographyProps={{ variant:'subtitle1' }}
                              // title={`${gclientType.name} ${gclientsByType.length === 0 ? " - 등록된 업체가 없습니다." : "(" + gclientsByType.length + ")"}`}
                              title={`${gclientType.name} (${gclientsByType.length})`}
                              sx={{ color : "#1565c0"}}
                            /> */}
                            {
                              gclientsByType?.length > 0 && (
                                <CardContent>
                                  <GClientList
                                    label={gclientType.name}
                                    addClient={addNewRow}
                                    deleteClient={clickOnDelete}
                                    gclientDetails={gclientDetails}
                                    gclientOptions={[{code: "", name: "설정안함"}].concat(gclientsByType)}
                                    gclientCode={gclientType.code}
                                    onChange={handleChange}
                                  />
                                </CardContent>
                              )
                            }
                          </Card>
                        </Grid>
                      ) : (
                        <></>
                      )

                      return clientComponent;
                    })
                  }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion expanded={glassExpanded} onChange={() => setGlassExpanded(!glassExpanded)}>
                <AccordionSummary aria-controls="glass-content" id="glass-header">
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography variant="h6" component="div">{"프로젝트 적용 유리"}</Typography>
                    </Grid>
                    <Grid item sm={6} display="flex" justifyContent="flex-end" alignItems="center">
                      <Typography variant="subtitle2" component="div" color="primary" sx={{ mr: 1 }}>{"보기"}</Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={mode}
                        exclusive
                        size="small"
                        onChange={handleChangeMode}
                      >
                        <ToggleButton value="glass">{"유리 템플릿"}</ToggleButton>
                        <ToggleButton value="project">{"프로젝트 유리 내역"}</ToggleButton>
                        <ToggleButton value="all">{"전체"}</ToggleButton>
                      </ToggleButtonGroup>  
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    mode === 'glass' && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item sm={12}>{GlassList('유리 템플릿', left, 'left')}</Grid>
                        </Grid>
                      </>
                    )
                  }
                  {
                    mode === 'project' && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item sm={12}>{GlassList('프로젝트 유리 내역', right, 'right')}</Grid>
                        </Grid>
                      </>
                    )
                  }
                  {
                    mode === 'all' && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>{GlassList('유리 템플릿', left, 'left')}</Grid>
                          <Grid item sm={9}>{GlassList('프로젝트 유리 내역', right, 'right')}</Grid>
                        </Grid>
                      </>
                    )
                  }
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            modify && (
              <Button
                onClick={handleOrder}
              >
                {/* <Link
                  href={`/gorders?gprojectId=${getValues("id")}&mode=register`}
                  variant="body2"
                  underline="none"
                >
                  {"발주"}
                </Link> */}
                {"발주"}
              </Button>
            )
          }
          <LoadingButton
            size="small"
            onClick={() => handleSubmit(onSubmit)('save')}
            loading={loading}
          >
            {"저장"}
          </LoadingButton>
          <Button onClick={() => handleSubmit(onSubmit)('saveAndClose')}>{"저장 후 닫기"}</Button>
          <Button onClick={() => {
            reset();
            // setPeriodValue(null, null); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
          }}>{"초기화"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      {
        // TODO : 추후 Drawer 기능 컴포넌트화할 수 있는지 검토할 것. 그리고 Drawer도 겹치기가 가능하므로 그 활용에 관하여 염두해둘 것(겹겹이 쌓을 수 있음)
        // 현재 업무 진행 중 다른 업무(특히 기준정보나 사전 준비)로 이동하여 데이터를 등록 및 수정/삭제한 후 현재 업무로 되돌아 오는 경우
        // 현재 업무 화면에서 Drawer로 다른 업무를 띄우는 기능
        actions.map((action) => (
          <Drawer
            anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
            open={drawerState[action.code]}
            PaperProps={{
              sx: { width: "100%" },
            }}
            onClose={toggleDrawer(action.code, false)}
          >
            <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
              <Tooltip title={"닫기"}>
                <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(action.code, false)}>
                  <KeyboardArrowDown />
                </IconButton>
              </Tooltip>
            </Grid>
            {action.component}
          </Drawer>
        ))
      }
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerState["GORDER"]}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer("GORDER", false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer("GORDER", false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        <GOrderManagement
          title={`발주 ( 프로젝트 : ${params?.site} )`}
          // gprojectId={params?.gprojectId}
          from={
            {
              source: "GProjectDialog",
              params: {
                gprojectId: params?.gprojectId
              }
            }
          }
        />
      </Drawer>
      {
        gtypeDetailsWithGComponent.length === gtypeDetailsWithGComponentLength && (
          <GProjectGlassDialog
            open={openGGlass}
            getProjectValues={getValues}
            setOpenGGlass={setOpenGGlass}
            gGlass={gGlass}
            gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
            gtypes={gtypes}
            refresh={generateGGlasses}
            mode={mode}
            setMode={setMode}
            // from={from}
            handleRegisterGGlassToProject={handleRegisterGGlassToProject}
            handleModifyGGlassToProject={handleModifyGGlassToProject}
            gtypeDetailsWithGComponentLength={gtypeDetailsWithGComponentLength}
            selectedGcomponentItems={selectedGcomponentItems}
            setSelectedGcomponentItems={setSelectedGcomponentItems}
          />
        )
      }
      <ConfirmDialog
        removeId={removeObject}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteGGlassToProject}
        onCancel={() => {}}
      >
        {`"${removeObject && removeObject.no}"을(를) 삭제하시겠습니까?`}
      </ConfirmDialog>
    </>
  );
};

export default GProjectDialog;
