import React, { useEffect, useState } from 'react';

import './index.css';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import { ModalProvider } from './context/Modal';
import App from './App';

import configureStore from './store';
import { restoreCSRF, csrfFetch } from "./store/csrf";
import * as sessionActions from './store/session';

// import ReactDataGrid from 'react-data-grid';

const store = configureStore();

if (process.env.NODE_ENV !== "production") {
  restoreCSRF();

  window.csrfFetch = csrfFetch;
  window.store = store;
  window.sessionActions = sessionActions;
}

// const Carrot = () => (
//   <div style={{ color: "orange", fontSize: "100px" }}>
//     <i className="fas fa-carrot"></i>
//   </div>
// );

// const columns = [
//   { key: "id", name: "ID", editable: true },
//   { key: "title", name: "Title", editable: true },
//   { key: "complete", name: "Complete", editable: true }
// ];

// const rows = [
//   { id: 0, title: "Task 1", complete: 20 },
//   { id: 1, title: "Task 2", complete: 40 },
//   { id: 2, title: "Task 3", complete: 60 }
// ];

// class Example extends React.Component {
//   state = { rows };

//   onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
//     this.setState(state => {
//       const rows = state.rows.slice();
//       for (let i = fromRow; i <= toRow; i++) {
//         rows[i] = { ...rows[i], ...updated };
//       }
//       return { rows };
//     });
//   };
//   render() {
//     return (
//       <ReactDataGrid
//         columns={columns}
//         rowGetter={i => this.state.rows[i]}
//         rowsCount={3}
//         onGridRowsUpdated={this.onGridRowsUpdated}
//         enableCellSelect={true}
//       />
//     );
//   }
// }

function Root() {
  const [items, setItems] = useState([]);

  useEffect(() => setItems(getItems(50)), [])

  const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
      id: `item-${k}`,
      content: `item ${k}`
    }));

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  
  const onDragEnd = ({ destination, source }) => {
    // // dropped outside the list
    // if (!destination) {
    //   return;
    // }

    // const items = reorder(
    //   items,
    //   source.index,
    //   destination.index
    // );

    // setItems(items);
  };

  return (
    <ModalProvider>
      <Provider store={store}>
        <BrowserRouter>
          <DragDropContext onDragEnd={onDragEnd}>
            <App />
            {/* <Carrot /> */}
            {/* <Example /> */}
          </DragDropContext>
        </BrowserRouter>
      </Provider>
    </ModalProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
