import React, { useState, useEffect, useRef } from "react";
import allLocales from '@fullcalendar/core/locales-all';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import listPlugin from '@fullcalendar/list';

import {
  Paper,
} from '@mui/material';

const ScheduleDemo = () => {

  // const calendarRef = useRef();
  console.log("~~~~~~~~~~");
  return (
    <Paper
      style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          margin: "10px 300px",
      }}
    >
      <FullCalendar
          plugins={[dayGridPlugin]}
          // ref={this.calendarRef}
          // initialView='dayGridMonth'
          editable={true}
          events='/events/query' // a remote JSON feed that will receive start/end params
          // eventsDidUpdate={this.handleEventsDidUpdate} // eventsDidUpdate not-yet-implemented (see proposal)
        />
    </Paper>
  );
};

export default ScheduleDemo;