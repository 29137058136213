// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GTYPE = 'gtype/setGType';
const REMOVE_GTYPE = "gtype/removeGType";
const SET_GTYPES = 'gtype/setGTypes';

const setGType = (gtype) => ({
  type: SET_GTYPE,
  payload: gtype,
});

const removeGType = () => ({
  type: REMOVE_GTYPE,
});

const setGTypes = (gtypes) => ({
  type: SET_GTYPES,
  payload: gtypes,
});

export const create = ({ id, name, code, comments, gcomponents }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypes`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, comments, gcomponents }),
  });
  const data = await response.json();
  dispatch(setGType(data.gtype));
  return response;
};

export const modify = ({ id, name, code, comments, gcomponents }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypes`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, comments, gcomponents }),
  });
  const data = await response.json();
  dispatch(setGType(data.gtype));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypes`);
  const data = await response.json();
  dispatch(setGTypes(data.gtypes));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gtypes`);
  const data = await response.json();
  return data.gtypes;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypes/${id}`);
  const data = await response.json();
  dispatch(setGType(data.gtype));
  return response;
}

export const init = () => dispatch => {
  dispatch(setGType(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypes/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGType());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gtypes/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gtype: null,
  gtypes: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GTYPE:
      newState = Object.assign({}, state, { gtype: action.payload });
      return newState;
    case REMOVE_GTYPE:
      newState = Object.assign({}, state, { gtype: null });
      return newState;
    case SET_GTYPES:
      newState = Object.assign({}, state, { gtypes: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
