import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import {
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";

import * as gworkOrderActions from "../../store/gworkOrder";

// 아래 form components의 name과 연계
const defaultValues = {
  comments: "",
};

const GWorkOrderProcessDialog = ({
  open,
  setOpen,
  id,
  receiveType,
  refresh,
  onClose,
  originRefresh,
}) => {  
  const [errors, setErrors] = useState([]);

  const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues });

  const receiveWorkOrderDirect = ({ id, status, comments }) => gworkOrderActions.receiveDirect({ id, status, comments })

  const onSubmit = async ({ comments }) => {
    setErrors([]);
    
    // return;
    
    await receiveWorkOrderDirect({ id, status: receiveType.toUpperCase(), comments });

    handleDialogClose();

    // selectAllByStatusByQuery(['SEND', 'RECEIVE']);
    onClose && onClose();
    refresh && refresh();
    originRefresh && originRefresh();
  }

  const handleDialogClose = () => {
    setValue("comments", "");
    setOpen(false);
  }

  let type = "";
  if (receiveType === 'receive') {
    type = "접수";
  } else if (receiveType === 'return') {
    type = "반송";
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth="sm"
      // onConfirm={sendOrder}
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {type}
      </DialogTitleClose>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"노트"}
              multiline
              rows={3}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)}>{type}</Button>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GWorkOrderProcessDialog;
