// TODO : 찾기 다이얼로그, 등록/수정 다이얼로그가 서로 연계되어 있어 관리/다이얼로그 분리는 추후에 검토
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ContentPaste,
  Delete,
  CheckCircleOutline,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Search,
  Filter1,
  Filter2,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputText,
  FormInputSwitch,
  FormInputToggleButton,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gunitActions from "../../store/gunit";
import * as gsubItemActions from "../../store/gsubItem";
import * as gsubActions from "../../store/gsub";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  gunitId: "",
  comments: "",
  pid: "",
  pname: "",
};

// React.getInnerText = function(obj) {
//   var buf = '';
//   if( obj ) {
//     var type = typeof(obj);
//     if( type === 'string' || type === 'number' ) {
//       buf += obj;
//     } else if( type === 'object' ) {
//       var children = null;
//       if( Array.isArray(obj) ) {
//         children = obj;
//       } else {
//         var props = obj.props;
//         if( props ) {
//           children = props.children;
//         }
//       }
//       if( children ) {
//         if( Array.isArray(children) ) {
//           children.forEach(function(o) {
//             buf += React.getInnerText(o);
//           });
//         } else {
//           buf += React.getInnerText(children);
//         }
//       }
//     }
//   }
//   return buf;
// };

const theme = createTheme();

const GSubManagement = () => {
  const [modify, setModify] = useState(false);
  const [crudMode, setCrudMode] = useState('');
  const [paste, setPaste] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFindDialog, setOpenFindDialog] = useState(false);
  const [errors, setErrors] = useState([]);
  const [rowsChild, setRowsChild] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowFind, setSelectedRowsFind] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedChild, setLoadedChild] = useState(false);
  const [loadedFind, setLoadedFind] = useState(false);
  const [loadedChildGSubItems, setLoadedChildGSubItems] = useState(false);
  const [show, setShow] = useState(false);
  const [showFind, setShowFind] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenChild, setConfirmOpenChild] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedChild, setCheckedChild] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [checkedMultipleYN, setCheckedMultipleYN] = useState(false);
  const [pageSizeFind, setPageSizeFind] = useState(100);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizeChild, setPageSizeChild] = useState(100);

  // 상위/하위 구성요소 공통
  
  // 상위 구성요소
  const handleSelect = async ({ type, params }) => {
    
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setPaste(false);
      setOpen(true);
    } else if (type === "edit") {
      // TODO : 아래 내용 재검토
      // 조회 후 다이얼로그 열면 데이터는 적용된 후 다이얼로그가 나타나 화면에서 데이터가 바뀌는게 보이는 것은 없어지나
      // 다소 다이얼로그가 늦게 나타나고,
      // 반대의 경우 다이얼로그는 빨리 나타나나 나타난 후 데이터가 바뀌는 것이 보임. 바뀌는 것이 보이는 것이 동적으로 변화하여
      // (마치 의도된 로딩처럼) 보기 좋을 수 있으나 화면 구성에 따라 보기 안좋은 경우도 있음.
      await select(id);
      setCrudMode('U');
      setPaste(false);
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      setCrudMode('C');
      setPaste(true);
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    } else if (type === "addChild") {
      dialogInit();
      setValue("pid", id);
      setValue("pname", params.row.name);
      setCrudMode('C');
      setOpen(true);
    }

    setOpenBackdrop(false);
  }
  
  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);

    // 선택한 요소의 하위 요소를 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      // selectByPid(selectedRows[0].id);
      const newRowsChild = await selectByPidDirect(selectedRows[0].id);
      setRowsChild(newRowsChild);

      setTimeout(() => setLoadedChild(true), 300);
    }

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gsubActions.remove(removeId))
      .then(async res => {
        await selectAllByQuery();
        // 구성요소를 삭제할 때 삭제하는 구성요소가 선택되어 자식 목록에 자식 구성요소가 조회되어 있는 경우 자식 목록 지움
        if (selectedRows.length === 1 && selectedRows[0].id === removeId) {
          initializeGSubsChild();
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  // 하위 구성요소
  const handleSelectChild = async ({ type, id }) => {
    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setPaste(false);
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setPaste(false);
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      setCrudMode('C');
      setPaste(true);
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpenChild(true);
    }

    setOpenBackdrop(false);
  }

  const removeChild = (removeId) => {
    dispatch(gsubActions.remove(removeId))
      .then(async res => {
        await selectAllByQuery();

        // 선택한 요소의 하위 요소를 다시 검색
        if (selectedRows.length === 1) {
          // selectByPid(selectedRows[0].id);
          const newRowsChild = await selectByPidDirect(selectedRows[0].id);
          setRowsChild(newRowsChild);
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const handleChangeOrderChild = () => {
    setCheckedChild(!checkedChild);
  }

  const dialogInit = () => {
    ["id", "name", "code", "gunitId", "comments", "pid"].forEach(item => {
      if (!open && item === "id") {
        setValue(item, uuidv4());
      } else {
        setValue(item, "");
      }
    }
  )};

  // 등록 다이얼로그
  const handleClickOpen = () => {
    setOpen(true);
    setCrudMode('C');
    dialogInit();
  }

  const handleDialogClose = () => {
    setOpen(false);
    dialogInit();
  }

  // 부모아이디 찾기 다이얼로그
  const handleClickFind = async () => {
    const id = getValues("id");
    
    await selectAllByQueryFind(id);
    
    // setModify(true);

    setOpenFindDialog(true);
    
    // 임시
    setTimeout(async () => {
      await hideWatermark();
      setShowFind(true);
      setTimeout(() => setLoadedFind(true), 300);
    }, 300);
  }

  const handleFindDialogClose = () => {
    setLoadedFind(false);
    setOpenFindDialog(false);
  }

  const handleSelectFind = () => {
    setValue("pid", selectedRowFind.id);
    setOpenFindDialog(false);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Add />}
        label={"하위등록"}
        onClick={() => handleSelect({ type: 'addChild', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = useMemo(() => [
      // {
      //   field: 'id',
      //   headerName: '아이디',
      //   width: 100,
      // },
      // {
      //   field: 'name',
      //   headerName: '이름',
      //   width: 180,
      //   // editable: true,
      // },
      {
        field: 'code',
        headerName: '코드',
        width: 200,
        // editable: true,
      },
      {
        field: 'gunitId',
        headerName: '단위아이디',
        width: 100,
        // editable: true,
        hide: true,
        description: "단위를 표시합니다.",
      },
      {
        field: 'gunitName',
        headerName: '단위',
        width: 140,
        // editable: true,
        description: "단위를 표시합니다.",
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
        // editable: true,
      },
      {
        field: 'pid',
        headerName: '부모아이디',
        width: 100,
        hide: true,
        // editable: true,
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제/하위등록"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checked ? 200 : 70,
        // description: "수정/삭제/하위등록",
        // headerClassName: 'super-app-theme--header',
        // cellClassName: 'super-app-theme--cell',
        type: 'actions',
        getActions: (params) => generateActions(params),
      },
    ], [handleSelect]
  );

  const generateActionsChild = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelectChild({ type: 'detail', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelectChild({ type: 'edit', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelectChild({ type: 'paste', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelectChild({ type: 'delete', id: params.id })}
        showInMenu
      />,
    ];

    if (checkedChild) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columnsChild = useMemo(() => [
      {
        field: 'id',
        headerName: '아이디',
        width: 300,
        hide: true,
      },
      {
        field: 'name',
        headerName: '이름',
        width: 180,
        // editable: true,
      },
      {
        field: 'code',
        headerName: '코드',
        width: 120,
        // editable: true,
      },
      {
        field: 'gunitId',
        headerName: '단위아이디',
        width: 100,
        hide: true,
        // hide: true,
        // editable: true,
      },
      {
        field: 'gunitName',
        headerName: '단위',
        width: 140,
        // editable: true,
        description: "단위를 표시합니다.",
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
        // editable: true,
      },
      {
        field: 'pid',
        headerName: '부모아이디',
        width: 100,
        hide: true,
        // editable: true,
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checkedChild ? 200 : 70,
        // description: "수정/삭제",
        type: 'actions',
        getActions: (params) => generateActionsChild(params),
      },
    ], [handleSelectChild]
  );

  const columnsGSubItems = [
    {
      field: 'id',
      headerName: '아이디',
      width: 300,
      hide: true,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    // {
    //   field: 'gName',
    //   headerName: '분류',
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: 'gclient',
    //   headerName: '제조사',
    //   width: 150,
    //   // editable: true,
    //   // valueGetter: (params) => params.value?.name,
    //   // valueGetter: (params) => {
    //   //   if (params.value) {
    //   //     return params.value.map(val => val.gclient.name);
    //   //   }
    //   // },
    //   renderCell: (params) => {
    //     // const name = params.row?.name;
    //     // return (
    //     //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    //     //     {params.row?.name}
    //     //     <StringAvatar
    //     //       name={name === "시공팀" ? name.substring(name.length-1) : name.substring(0, 1)}
    //     //       width={20}
    //     //       height={20}
    //     //       fontSize={"small"}
    //     //       color={params.row.color}
    //     //     />
    //     //   </Box>
    //     // )
    //     if (params.value && Array.isArray(params.value) && params.value.length > 0) {
    //       return params.value.length === 1
    //         ?
    //           (<Button variant="text" onClick={() => handleViewManufacturers([params.value[0].gclient])}>{params.value[0].gclient.name}</Button>)
    //         :
    //           (<Button variant="text" onClick={() => handleViewManufacturers(params.value.map(val => val.gclient))}>{"2+"}</Button>)
    //     };
    //   }
    // },
    // {
    //   field: 'g04docu',
    //   headerName: '규격',
    //   width: 300,
    //   // editable: true,
    //   // valueGetter: (params) => {
    //   //   if (params.row.g04docuName && params.row.gstandardNo) {
    //   //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
    //   //   }

    //   //   return "";
    //   // },
    //   renderCell: (params) => {
    //     if (params.row.g04docuName && params.row.gstandardNo) {
    //       return <>{params.row.g04docuName}<Chip label={params.row.gstandardNo} size="small" sx={{ ml: 1 }}></Chip></>;
    //     } else {
    //       return "";
    //     }
    //   }
    // },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  const columnsFind = useMemo(() => [
    {
      field: 'id',
      headerName: '아이디',
      width: 100,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 180,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 120,
      // editable: true,
    },
    {
      field: 'gunitId',
      headerName: '단위아이디',
      width: 100,
      hide: true,
      // editable: true,
    },
    {
      field: 'gunitName',
      headerName: '단위',
      width: 140,
      // editable: true,
      description: "단위를 표시합니다.",
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'pid',
      headerName: '부모아이디',
      width: 100,
      hide: true,
      // editable: true,
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 200,
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 200,
    // },
    // {
    //   field: 'functions',
    //   headerName: "기능",
    //   width: 60,
    //   type: 'actions',
    //   hide: true,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<Edit />}
    //       label={"수정"}
    //       onClick={() => handleSelect({ type: 'edit', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Delete />}
    //       label={"삭제"}
    //       onClick={() => handleSelect({ type: 'delete', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Add />}
    //       label={"하위등록"}
    //       onClick={() => handleSelect({ type: 'addChild', id: params.id })}
    //       showInMenu
    //     />,
    //   ],
    // },
    ], [handleSelect]
  );

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  const rows = useSelector((state) => state.gsub.gsubsTree);
  const selectedRow = useSelector((state) => state.gsub.gsub);
  // const rowsChild = useSelector((state) => state.gsub.gsubsChild);
  const rowsFind = useSelector((state) => state.gsub.gsubsTreeFind);
  const gunits = useSelector((state) => state.gunit.gunits);
  const rowsGSubItems = useSelector((state) => state.gsubItem.gsubItems);
  
  // 데이터 관리
  const selectAllByQuery = () => dispatch(gsubActions.selectAllByQuery())
  const selectAllByQueryFind = (id) => dispatch(gsubActions.selectAllByQueryFind(id))
  const select = (id) => dispatch(gsubActions.select(id))
  // const selectByPid = (pid) => dispatch(gsubActions.selectByPid(pid))
  const selectByPidDirect = (pid) => gsubActions.selectByPidDirect(pid)
  const addGSub = ({ id, name, code, gunitId, comments, pid }) => dispatch(gsubActions.create({ id, name, code, gunitId, comments, pid }))
  const modifyGSub = ({ id, name, code, gunitId, comments, pid }) => dispatch(gsubActions.modify({ id, name, code, gunitId, comments, pid }))
  const reorder = ({ type, id }) => gsubActions.reorder({ type, id })
  const initializeGSubsChild = () => dispatch(gsubActions.initializeGSubsChild())
  const selectGUnitsAll = () => dispatch(gunitActions.selectAll())
  const initializeGSubItems = ()=> dispatch(gsubItemActions.initializeGSubItems()) 
  const selectAllGSubItems = () => dispatch(gsubItemActions.selectAll())
  const selectAllGSubItemsByGSubId = (gsubId) => dispatch(gsubItemActions.selectAllByGsubId(gsubId))
  
  // // 아래는 구매전 테스트를 위한 임시 코드임
  // const hideWatermark = () => {
  //   var divTags = document.getElementsByTagName("div");
  //   // console.log(divTags)
  //   var searchText = "MUI X: Missing license key";
  //   var found;

  //   for (var i = 0; i < divTags.length; i++) {
  //     if (divTags[i].textContent == searchText) {
  //       found = divTags[i];
  //       break;
  //     }
  //   }

  //   if (found) {
  //     found.remove();
  //   }
  // }

  // TODO : 추후 아이디 "중복확인" 추가할 것
  const onSubmit = ({ id, name, code, gunitId, comments, pid }) => {
    setErrors([]);

    let func;
    if (crudMode === 'U') {
      // TODO : 자기 자신을 상위(또는 하위)로 가질 수 없으므로 id, pid 같이 않도록 유효성 체크 필요
      func = modifyGSub;
    } else if (crudMode === 'C') {
      func = addGSub
    }    

    // dispatch(func({ id, name, code, gunitId, comments, pid }))
    func({ id, name, code, gunitId, comments, pid })
      .then (async res => {
        handleDialogClose();
        selectAllByQuery();
        
        dialogInit();

        if (selectedRows.length === 1) {
          // selectByPid(selectedRows[0].id);
          const newRowsChild = await selectByPidDirect(selectedRows[0].id);
          setRowsChild(newRowsChild);
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  
  setTimeout(() => hideWatermark(), 300);

  useEffect(
    async () => {
      initializeGSubItems();
      // 다른 목록과 달리 로딩시간이 길어 Backdrop 사용
      setOpenBackdrop(true);
      // await dispatch(gsubActions.selectAllByQuery());
      selectAllByQuery();
      await selectGUnitsAll();
      // await selectAllGSubItems(); // TODO : 임시주석. 필요없는것이 확인되면 삭제
      
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setOpenBackdrop(false);
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
      
    }, [dispatch]
  );

  // TODO : 아래는 목록에서도 들어온다. 검토해볼 것
  useEffect(
    () => {
      // console.log(selectedRow)
      // ["id", "name", "code", "gunitId", "comments", "pid"].forEach(item => {
      //   setValue(item, selectedRow && selectedRow[item] || "");
      // });
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
      }
    }, [selectedRow]
  );

  // useEffect(
  //   () => {
  //     if (paste) {
  //       setValue("id", uuidv4());
  //     }
  //   }, [paste]
  // );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      setTimeout(() => setLoadedChild(true), 300);
    }
    , [selectedRows]
  )

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Dialog
          open={openFindDialog}
          onClose={handleFindDialogClose}
          fullWidth={true}
          maxWidth={"md"}
          // PaperComponent={PaperComponent}
          // 팝업의 팝업은 drag and drop이 안됨.
          // aria-labelledby="draggable-dialog-title-find"
        >
          <DialogTitleClose
            // id="draggable-dialog-title-find"
            onClose={handleFindDialogClose}
            // style={{ cursor: 'move' }}
          >
            {"상위요소 선택"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container>
              <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: showFind ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  treeData
                  groupingColDef={{ headerName : "아이디 (하위요소 개수)" }}
                  getTreeDataPath={(row) => row.path}
                  // initialState={{ pinnedColumns: { left: ['id', 'name', 'code'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loadedFind}
                  rows={rowsFind}
                  columns={columnsFind}
                  pageSize={pageSizeFind}
                  onPageSizeChange={(newPageSize) => setPageSizeFind(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowClick={(params) => {
                    setSelectedRowsFind(params);
                  }}
                  // 더블클릭시 포커스가 가거나 하는 동작 때문에 잘 선택되지 않는 경우가 발생하므로
                  // 이를 해결하기 전까지는 더블클릭 이벤트 사용하지 않음
                  // onRowDoubleClick={(params) => {
                  //   setValue("pid", params.id);
                  //   handleFindDialogClose();
                  // }}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSelectFind}>{"선택"}</Button>
            <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          PaperProps={{style: {overflowY: 'visible'}}}
          open={open}
          onClose={handleDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            style={{ cursor: 'move' }}
          >
            { crudMode === 'C' && "구성요소 등록" }
            { crudMode === 'R' && "구성요소 상세" }
            { crudMode === 'U' && "구성요소 수정" }
          </DialogTitleClose>
          <DialogContent
            style={{ overflowY: 'visible' }}
            dividers
          >
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                  // disabled={modify}
                  inputProps={
                    { readOnly: true }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"name"}
                  control={control}
                  label={"이름"}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"code"}
                  control={control}
                  label={"코드"}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {/* <FormInputText
                  name={"gunitId"}
                  control={control}
                  label={"단위"}
                  multiline
                /> */}
                {/* TODO : FormInputDropdown의 경우 다이얼로그 안에서 사이즈가 맞지 않음. 그래서 FormInputText 사용 */}
                {/* TODO : FormControl에 fullWidth 적용해서 해결. 추후 다른 곳 적용 검토 */}
                {/* TODO : 읽기전용 기능 추가 필요. 아래 동작하면 동작하는 것임 */}
                <FormInputText
                  select
                  name="gunitId"
                  control={control}
                  label={"단위"}
                  // sx={{ width: 300 }}
                  options={
                    [{ label: '없음', value: undefined }].concat(gunits.map(gunit => {
                      return {
                        label: gunit.name,
                        value: gunit.id,
                      }
                    }))
                  }
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={10} sx={{ display: 'none' }}>
                <FormInputText
                  name={"pid"}
                  control={control}
                  label={"상위 구성요소아이디"}
                  disabled
                />
              </Grid>
              <Grid item xs={10}>
                <FormInputText
                  name={"pname"}
                  control={control}
                  label={"상위 구성요소명"}
                  disabled
                />
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                {/* <Button
                  variant="outlined"
                  onClick={handleClickFind}
                  // 찾기 버튼을 비활성해야 하는 경우가 있는지 다시 검토 필요
                  // disabled={!modify}
                >
                  {"찾기"}
                </Button> */}
                <Fab
                  color="primary"
                  size="small"
                  variant="extended"
                  onClick={handleClickFind}
                  // disabled={!modify}
                  // 찾기 버튼을 비활성해야 하는 경우
                  // 1. 상세보기일 경우
                  // 2. 하위등록일 경우 (crudMode가 'C'이고 pid가 설정된 경우)
                  disabled={crudMode === 'R' || (crudMode === 'C' && getValues('pid') !== undefined && getValues('pid') !== null && getValues('pid') !== "")}
                >
                  <Search />
                  {"찾기"}&nbsp;
                </Fab>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          {
              crudMode === 'C' && (
                <>
                  <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
                  <Button onClick={() => reset()}>{"초기화"}</Button>
                </>
              )
            }
            { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
            {/* TODO : 아래 코드 안되는 이유? */}
            {/* <Button onClick={reset}>{"초기화"}</Button> */}
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            mt: 3,
            '& .super-app-theme--header': {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
            '& .super-app-theme--cell' : {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <Filter1 />
              {/* <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>{"▶ 상위 구성요소"}</Typography> */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"상위 구성요소"}</Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              treeData
              groupingColDef={{ headerName : "이름 (하위요소 개수)", width: 300 }}
              getTreeDataPath={(row) => row.path}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // CheckCircleOutlineSelection
              // disableSelectionOnClick
              onRowSelectionModelChange={async (ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rows.filter((row) =>
                  selectedIDs.has(row.id),
                );
      
                setSelectedRows(selectedRows);

                // 현재는 단일 선택 기준임
                if (selectedRows.length === 1) {
                  setLoadedChild(false);
                  // selectByPid(selectedRows[0].id);
                  const newRowsChild = await selectByPidDirect(selectedRows[0].id);
                  setRowsChild(newRowsChild);

                  // 하위구성요소가 없다면 GSubItems도 검색해봄
                  if (newRowsChild.length === 0) {
                    setLoadedChildGSubItems(false);
                    selectAllGSubItemsByGSubId(selectedRows[0].id);

                    setTimeout(() => setLoadedChildGSubItems(true), 300)
                  } else {
                    initializeGSubItems();
                  }
                }
              }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <Filter2 />
              <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"하위 구성요소"}</Typography>
            </Grid>
            {
              rowsGSubItems.length === 0 && (
                <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={checkedChild}
                        onChange={handleChangeOrderChild}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={"순서"}
                  />
                </Grid>
              )
            }
          </Grid>
          {
            // rowsGSubItems.length === 0 && (
              <div style={{ height: show && rowsGSubItems.length === 0 ? 400 : 0, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: show && rowsGSubItems.length === 0 ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  loading={!loadedChild}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  rows={rowsChild}
                  columns={columnsChild}
                  pageSize={pageSizeChild}
                  onPageSizeChange={(newPageSize) => setPageSizeChild(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  // onRowSelectionModelChange={(ids) => {
                  //   const selectedIDs = new Set(ids);
                  //   const selectedRows = rows.filter((row) =>
                  //     selectedIDs.has(row.id),
                  //   );
          
                  //   setSelectedRows(selectedRows);
                  // }}
                  onRowDoubleClick={(params) => handleSelectChild({ type: 'edit', id: params.id })}
                />
              </div>
            // )
          }
          {
            // rowsGSubItems.length > 0 && ( // TODO : 이렇게 하면 어떤 이유로 DataGrid가 잘 그려지지 않음 ??? 아마도 GCompomentManagement나 GSubManagement에서 F5(refresh) 시 DataGrid 안그려지는 문제가 이 때문일 수 있음
              <div style={{ height: show && rowsGSubItems.length > 0 ? 400 : 0, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: show && rowsGSubItems.length > 0 ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  loading={!loadedChildGSubItems}
                  initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  rows={rowsGSubItems}
                  columns={columnsGSubItems}
                  pageSize={pageSizeChild}
                  onPageSizeChange={(newPageSize) => setPageSizeChild(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                />
              </div>
            // )
          }
          
        </Box>
        {/* TODO : 하나의 ConfirmDialog 쓰기 위해 useState로 onConfirm 함수 설정 시도했으나 동작하지 않아 아래와 같이 두개 사용. 추후 재검토 필요 */}
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpenChild}
          setOpen={setConfirmOpenChild}
          onConfirm={removeChild}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GSubManagement;
