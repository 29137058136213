import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as sessionActions from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Container,
  CssBaseline,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  LockOutlined,
  Login,
  PowerSettingsNew,
} from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
  // FormInputDate,
  // FormInputDropdown,
  // FormInputMultipleCheckbox,
  // FormInputRadio,
  // FormInputSlider,
  FormInputCheckbox,
  FormInputText,
} from "../../components/form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../../components/dialog";
import PasswordChangeDialog from "../Password/PasswordChangeDialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";
import * as mailActions from "../../store/mail";
import * as gclientActions from "../../store/gclient";
import CopyrightKFGWA from "../layout/CopyrightKFGWA";

let passwordInputRef = null;

// 아래 form components의 name과 연계
const defaultValues = {
  credential: "",
  password: "",
};

const theme = createTheme();

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  //margin: 4,
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1rem',
    maxWidth: 500,
    // height: 100,
    backgroundColor: "rgb(255,255,255)",
    margin: 4,
    padding: 8,
    whiteSpace: "pre-line",
    border: "solid #777 1px",
    borderRadius: "10px",
    color: '#777',
  }
}));

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    border: "solid #777 1px",
    borderRadius: "0px",
    color: '#777',
  }
};

const GLoginGClient = () => {
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchCredential = queryStrings["c"];
  const searchPassword = queryStrings["p"];
  const searchType = queryStrings["t"];
  const searchStatus = queryStrings["s"];
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
   const { handleSubmit, getValues, setValue, control } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  const location = useLocation();
  // console.log("location");
  // console.log(location.state.from);
  const sessionUser = useSelector((state) => state.session.sessionUser);

  const gclientRejoin = (bizRegNumber) => gclientActions.rejoinDirect(bizRegNumber);
  // const sendMailDrect = ({ aaa }) => mailActions.sendMailDrect({ aaa })
  // const initPasswordDirect = ({ credential, type }) => sessionActions.initPasswordDirect({ credential, type })

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error, setError] = useState(null);
  const [logging, setLogging] = useState(false);
  const [saveId, setSaveId] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [rejoinObject, setRejoinObject] = useState({});
  const [confirmOpenRejoin, setConfirmOpenRejoin] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});

  const signinButtonRef = useRef(null);

  const setPasswordInputRef = element => {
    passwordInputRef = element;
  };

  useEffect(
    () => {
      if (searchCredential || searchPassword) {
        setValue("credential", searchCredential);
        setValue("password", searchPassword);

        if (searchCredential && searchPassword) {
          // TODO :
          // setDisabled(false);
        }
      } else {
        const type = localStorage.getItem("type");
        if (type === 'GCLIENT') {
          const id = localStorage.getItem("credential");
          const isSave = localStorage.getItem("saveId");
          isSave && setSaveId(true);
          id && setValue("credential", id);
          
          if (isSave && id) {
            passwordInputRef.focus();
          }
        }
      }
    }, [dispatch]
  )

  // 로그인되어 있다면 이 페이지가 뜨지 않고 root로 이동시킴
  if (sessionUser) {
    console.log("LoginFormPage/index sessionUser exists.");
    console.log(location);
    console.log(sessionUser.initPassword)
    if (sessionUser.initPassword) {
      return <Navigate to={"/profile"} />; // TODO : state 필요없는지 확인 필요  
    }

    // TODO : JAVA
    return <Navigate to={location.state && location.state.from ? `${location.state.from}` : "/glass/dashboard"} />; // TODO : state 필요없는지 확인 필요
    // return <Navigate to={location.state && location.state.from ? `${location.state.from}` : "/gclients"} />; // TODO : state 필요없는지 확인 필요
  } else {
    console.log("LoginFormPage/index no sessionUser exists.");
  }
  
  // React Hook "useEffect" is called conditionally. React Hooks must be called in the exact same order in every component render  react-hooks/rules-of-hooks
  // 오류로 위로 올림
  // useEffect(
  //   () => {
  //     const id = localStorage.getItem("C");
  //     const isSave = localStorage.getItem("saveId");
  //     if (isSave && id) {
  //       setSaveId(true);
  //       setValue("credential", id);
  //     }
  //   }, [dispatch]
  // )

  const onSubmit = ({ credential, password }) => {
    setError(null);

    setLogging(true);

    // console.log({ credential, password, type });
    // return;

    // const status = searchStatus ? "등록완료" : ""
    return dispatch(sessionActions.login({ credential, password, type: 'GCLIENT'/*, status*/ }))
      .then(res => {
        setLogging(false);

        if (saveId) {
          localStorage.setItem("saveId", true);
          localStorage.setItem("credential", credential);
          localStorage.setItem("type", "GCLIENT");
        }
      })
      .catch(async (e) => {
        setLogging(false);
        
        // const data = await res.json();
        // if (data && data.errors) setErrors(data.errors);
        // if (res && res.errors) {
        //   setErrors(res.errors);
        // }
        console.log(e)
        setError(e);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 2000);

        if (e.details?.canRejoin) {
          setRejoinObject({ bizRegNumber: credential });
          setConfirmOpenRejoin(true);
        }
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      signinButtonRef.current.click();
    }
  }

  // const isNumeric = n => !isNaN(n);

  // const handleBlurCredential = () => {
  //   // 숫자로만 10자로 이루어진 회사명은 없다는 가정하에 편의성을 위해 사업자등록번호에 자동 하이픈(-) 부여
  //   const credential = getValues("credential");
  //   if (isNumeric(credential) && credential.length === 10) {
  //     const newCredential = `${credential.substring(0, 3)}-${credential.substring(3, 5)}-${credential.substring(5)}`;
  //     setValue("credential", newCredential);
  //   }
  // }

  const handleClickSaveId = () => {
    setSaveId(!saveId);

    localStorage.removeItem("saveId");
    localStorage.removeItem("credential");
    localStorage.removeItem("type");
  }

  // const handleClickSendMail = () => {
  //   // sendMailDrect({ aaa: "" });
  //   initPasswordDirect({ credential: getValues("credential"), type });
  // }

  const handleClickPasswordChange = () => {
    setOpen(true);
    setMode('I');
  }

  const handleChange = () => {
    setFadeIn(false);
    if (getValues("credential") && getValues("password")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const rejoin = async ({ bizRegNumber }) => {
    const res = await gclientRejoin(bizRegNumber);

    if (res === 'success') {
      setTimeout(() => {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "재가입되셨습니다. 감사합니다.",
          open: true,
        });
      }, 1000);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        > 
          {/* <img src="/kfgwa-logo.jpg"/> */}
          <Typography component="h1" variant="h4" sx={{ color: '#777' }}>{"로그인"}</Typography>
          <Grid display="flex" alignItems="end" sx={{ height: 50 }}>
            <Fade in={fadeIn}>
              <Typography sx={{ color: "#ff5722" }}>
                { error?.message }
              </Typography>
            </Fade>
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ border: "1px solid #C4C4C4", p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>{"회원사 관리자"}</Typography>
                  <FormInputText
                    margin="normal"
                    autoComplete="bizRegNumber"
                    name={"credential"}
                    control={control}
                    required
                    fullWidth
                    label={"사업자번호"}
                    placeholder={"- 는 자동입력됩니다."}
                    autoFocus
                    size={"medium"}
                    InputProps={{
                      inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                      inputProps: {
                        style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                          mask: "000-00-00000",
                          definitions: {
                            '#': /[1-9]/,
                          },
                        },
                      },
                    }}
                    onCustomChange={handleChange}
                  />
                  <FormInputText
                    margin="normal"
                    required
                    fullWidth
                    name={"password"}
                    control={control}
                    label={"비밀번호"}
                    size={"medium"}
                    type="password"
                    autoComplete="current-password"
                    onKeyPress={handleKeyPress}
                    inputProps={{ // InputProps와는 다름
                      ref: setPasswordInputRef,
                    }}
                    onCustomChange={handleChange}
                  />
                  <LoadingButton
                    // onClick={handleSubmit((param) => onSubmit(param, 'GCLIENT'))}
                    // onClick={() => handleSubmit(onSubmit)('GCLIENT')}
                    onClick={handleSubmit(onSubmit)}
                    fullWidth
                    variant={"contained"}
                    sx={{ mt: 2, mb: 2, height: '60px', bgcolor: '#0C529D', fontSize: '1.2rem' }}
                    ref={signinButtonRef}
                    loading={logging}
                    loadingPosition="start"
                    disabled={disabled}
                    startIcon={<PowerSettingsNew />}
                  >    
                    {"로그인"}
                  </LoadingButton>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox control={control} checked={saveId} color="primary" onClick={handleClickSaveId} />}
                        label="아이디 저장"
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ fontSize: "0.9rem", color: "#ff5722" }}>
                      <>
                        {"비밀번호를 잃어버리셨나요?"}<br />
                        {"또는 "}<span><u>{"KFGWA 공사다큐 관리자를 통해 회원가입 하신 후 처음 접속"}</u></span>{"하시나요?"}<br />
                        {"그러시면 아래 비밀번호 초기화를 클릭해 주세요."}
                      </>
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent={"flex-start"}>
                      <Link href="#" variant="body1" underline="none" onClick={handleClickPasswordChange}>
                        <StyledTooltip
                          title={"비밀번호를 분실하셨거나 KFGWA 공사다큐 관리자를 통해 회원가입 하신 후 최초 로그인시 비밀번호 초기화를 누르십시요. 등록된 회사대표메일로 임시비밀번호를 발급해 드립니다."}
                          followCursor
                          sx={tooltipTop}
                        >
                          {"비밀번호 초기화"}
                        </StyledTooltip>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent={"flex-end"}>
                      <Link href={"/glass/signup/gclient"} underline="none" variant="body1">{"회원사 가입"}</Link>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
                      {/* <Link href={"/glass/signin/guser"} underline="none" variant="body2">{"회원사 사용자 로그인으로 이동"}</Link> */}
                      <Link href={"/glass/signin"} underline="none" variant="body2">{"로그인 유형 선택"}</Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        <PasswordChangeDialog
          open={open}
          setOpen={setOpen}
          mode={mode}
          setMode={setMode}
          setCheckValue={setValue}
          type={"GCLIENT"}
          // setType={setType}
          credential={getValues("credential")}
        />
        <ConfirmDialog
          removeId={rejoinObject}
          title={"재가입"}
          open={confirmOpenRejoin}
          setOpen={setConfirmOpenRejoin}
          onConfirm={rejoin}
          onCancel={() => {}}
        >
          <>
            <span>{"탈퇴하신 회원사입니다."}</span><br />
            <span>{"탈퇴하신 후 3개월이 지나"}</span>&nbsp;
            <span style={{ color: '#1976d2' }}>{"재가입이 가능"}</span>
            <span>{"합니다."}</span><br />
            <span>{"다시 가입하시겠습니까?"}</span>
          </>
        </ConfirmDialog>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
      </Container>
      {/* <CopyrightKFGWA /> */}
    </ThemeProvider>
  );
}

export default GLoginGClient;