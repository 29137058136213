export const objectEmptyCheck = (object) => {
  // if (object === undefined || object === null) { // TODO : 왜 주석처리했지???
  //   return true;
  // }

  return Object.keys(object).length === 0 && object.constructor === Object;
}

export const objectSortByKey = (object) => {
  return Object.keys(object).sort().reduce(
    (obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {}
  );
}