import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_G04_DOCU_SEARCH = 'g04docu/initG04docuSearch';
const SET_G04_DOCU_SEARCH = 'g04docu/setG04docuSearch';

const initG04docuSearch = () => ({
  type: INIT_G04_DOCU_SEARCH,
});

const setG04docuSearch = (g04docuSearch) => ({
  type: SET_G04_DOCU_SEARCH,
  payload: g04docuSearch,
});

/**
 * gclientId: 거래선 아이디
 * docType: 문서종류. 일반서류/인증서/성적서/기타
 * division: 인증서와 성적서의 경우. 원자재/가공부자재/시공부자재/공정
 * searchWord: 검색어
 *  - 일반서류: 이름
 *  - 인증서: 이름/등급
 *  - 성적서: 이름/제품명/등급 (이름은 아이템명 또는 공정명, 제품명은 그 스펙)
 *  - 기타: 이름
 * classifications: 등급은 문자검색이 아닌 jsonb 검색이므로 검색방법이 달라 searchWord로 classifications를 호출전 구성함
 */
export const selectAllByQuery = ({ gclientId, type, division, searchWord, classifications, gtestNameFilter }) => async dispatch => {
  // console.log({ gclientId, type, division, searchWord, classifications, gtestNameFilter });
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuSearch/query/selectAll`, {
    method: "POST",
    body: JSON.stringify({ gclientId, type, division, searchWord, classifications, gtestNameFilter }),
  });

  const data = await response.json();
  dispatch(setG04docuSearch(data.g04docuSearch));
  return response;
}

export const selectAllNamesByQueryDirect = async ({ gclientId, name }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuSearch/query/selectAll/names`, {
    method: "POST",
    body: JSON.stringify({ gclientId, name }),
  });

  const data = await response.json();
  return data.g04docuSearchNames;
}

export const initializeG04docuSearch = () => async dispatch => {
  dispatch(initG04docuSearch());
}

const initialState = {
  g04docuSearch: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU_SEARCH:
      newState = Object.assign({}, state, { g04docuSearch: initialState.g04docuSearch });
      return newState;
    case SET_G04_DOCU_SEARCH:
      newState = Object.assign({}, state, { g04docuSearch: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
