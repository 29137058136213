// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_GCLIENT_TYPE = 'gclientType/setGClientType';
const REMOVE_GCLIENT_TYPE = "gclientType/removeGClientType";
const SET_GCLIENT_TYPES = 'gclientType/setGClientTypes';

const setGClientType = (gclientType) => ({
  type: SET_GCLIENT_TYPE,
  payload: gclientType,
});

const removeGClientType = () => ({
  type: REMOVE_GCLIENT_TYPE,
});

const setGClientTypes = (gclientTypes) => ({
  type: SET_GCLIENT_TYPES,
  payload: gclientTypes,
});

export const create = ({ id, name, code, projectYN, displayType, comments, color }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, projectYN, displayType, comments, color }),
  });
  const data = await response.json();
  dispatch(setGClientType(data.gclientType));
  return response;
};

export const modify = ({ id, name, code, projectYN, displayType, comments, color }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, projectYN, displayType, comments, color }),
  });
  const data = await response.json();
  dispatch(setGClientType(data.gclientType));
  return response;
};

export const modifyProjectYN = ({ id, projectYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes/projectYN`, {
    method: "PUT",
    body: JSON.stringify({ id, projectYN }),
  });
  const data = await response.json();
  dispatch(setGClientType(data.gclientType));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes`);
  const data = await response.json();
  dispatch(setGClientTypes(data.gclientTypes));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclientTypes`);
  const data = await response.json();
  return data.gclientTypes;
}

export const selectAllByProjectYNDirect = async (projectYN) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclientTypes/projectYN/${projectYN}`);
  const data = await response.json();
  return data.gclientTypes;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes/${id}`);
  const data = await response.json();
  dispatch(setGClientType(data.gclientType));
  return response;
}

export const initGClientType = () => dispatch => {
  dispatch(setGClientType(null));
}

export const remove = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientTypes/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGClientType());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclientTypes/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gclientType: null,
  gclientTypes: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCLIENT_TYPE:
      newState = Object.assign({}, state, { gclientType: action.payload });
      return newState;
    case REMOVE_GCLIENT_TYPE:
      newState = Object.assign({}, state, { gclientType: null });
      return newState;
    case SET_GCLIENT_TYPES:
      newState = Object.assign({}, state, { gclientTypes: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
