import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';

import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { dateFormat } from "../../utils";
import * as g04GeneralDocuActions from "../../store/g04GeneralDocu";
import G04GeneralDocuDialog from "./G04GeneralDocuDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const G04GeneralDocuManagement = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [rowCount, setRowCount] = useState(100);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;
    
    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAll(queryPaging);
    setTimeout(() => setLoaded(true), 300)

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(g04GeneralDocuActions.remove(removeId))
    .then(res => selectAll())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    setCrudMode('C');
    setOpen(true);
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowUp />}
          label={"맨위로"}
          onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowUp />}
          label={"위로"}
          onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowDown />}
          label={"아래로"}
          onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowDown />}
          label={"맨아래로"}
          onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />
      ]);
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'name',
      headerName: '이름',
      width: 240,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const rows = useSelector((state) => state.g04GeneralDocu.g04GeneralDocus);
  const rowCount = useSelector((state) => state.g04GeneralDocu.count);
  const selectedRow = useSelector((state) => state.g04GeneralDocu.g04GeneralDocu);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = (queryOptions) => dispatch(g04GeneralDocuActions.selectPaging(queryOptions))
  const select = (id) => dispatch(g04GeneralDocuActions.select(id))
  const reorder = ({ type, id }) => g04GeneralDocuActions.reorder({ type, id })

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  console.log("queryPaging")
  console.log(queryPaging)

  useEffect(
    async () => {
      // 아래 코드를 selectPaging으로 바꾸고 페이지 변경시 호출. 단점은 hideWatermark가 매번 호출됨
      // await selectAll();

      // await hideWatermark();
      // // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      // setShow(true);
      // setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  const selectPaging = async () => {
    setLoaded(false)

    await selectAll(queryPaging);
    
    setLoaded(true);
  }

  useEffect(
    () => {
      selectPaging();
    }, [queryPaging]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <G04GeneralDocuDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          queryPaging={queryPaging}
          refresh={selectAll}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              rowCount={rowCount}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default G04GeneralDocuManagement;
