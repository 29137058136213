import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, useGridApiContext, koKR } from '@mui/x-data-grid-pro';
// import { bgBG as pickersKoKR } from '@mui/x-date-pickers';
// import { bgBG as coreKoKR } from '@mui/material/locale';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Table,
  TableContainer,
  TableBody,
  // TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  DesignServices,
  SlowMotionVideo,
  SwitchAccessShortcutAdd,
  Compress,
  Dehaze,
  Add,
  Remove,
}
from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import usePrevious from "../hook/usePrevious";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  objectEmptyCheck,
  hideWatermark,
  getTextColorByBackgroundColor,
} from "../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gclientActions from "../../store/gclient";
import * as gprojectActions from "../../store/gproject";
import * as gorderActions from "../../store/gorder";
import * as gworkOrderActions from "../../store/gworkOrder";

import GWorkOrderExcel from "./GWorkOrderExcel";
import GWorkOrderProcessDialog from "./GWorkOrderProcessDialog";
import GWorkOrderDialogDetail from "./GWorkOrderDialogDetail";

const theme = createTheme(
  koKR, // x-data-grid translations
  // pickersKoKR, // x-date-pickers translations
  // coreBgKoKR, // core translations
);

// 아래 form components의 name과 연계
const defaultValues = {
  gorderId: undefined,
  // etc: "",
  // id: "",
  // gclientId: "",
  // gprojectId: undefined,
  // site: "",
  // inChargeNameGclient: "",
  // inChargePhoneGclient: "",
  // // inChargeEmailGclient: "",
  // deliveryAddress: "",
  // orderDate: new Date(),
  // deliveryDate: new Date(),
  // // owner: "",
  // ordererName: "",
  // ordererPhone: "",
  // ordererEmail: "",
  // comments: "",
  // gorderDetails: undefined,
  gworkOrderDetails: undefined,
};

// TODO : GWorkOrderExcel의 행수가 앞으로는 고정이 아닐 수도 있으므로 추후 검토할 것
const rowLength = 51;
for(let i=0 ; i<rowLength-1 ; i++) {
  defaultValues[`checkRow_${i}`] = false;
}

// console.log(defaultValues)

let selectProjectInput = null;
// let oldGProjectId = null;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    // height: 3,
    height: 0,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  width: 60,
  height: 40,
  position: 'relative',
  background: '#cacaca',
  // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  // zIndex: 1,
  // color: '#fff',
  // width: 50,
  // height: 50,
  display: 'flex',
  // borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: '10px solid white',
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    right: -10,
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: '10px solid #cacaca',
    ...(ownerState.active && {
      borderLeft: '10px solid #42a5f5',
    }),
    ...(ownerState.completed && {
      borderLeft: '10px solid #1976d2',
    }),
    ...(ownerState.error && {
      borderLeft: '10px solid #42a5f5',
    }),
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
  ...(ownerState.active && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#42a5f5',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#1976d2',
  }),
  ...(ownerState.error && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    background: '#1976d2',
  }),
}));

function ColorlibStepIcon(props) {
  // console.log("ColorlibStepIcon");
  // console.log(props);
  const { active, completed, className } = props;

  const defaultSx = { ml: 1 };
  const defaultRotate90Sx = { ml: 1, transform: "rotate(90deg)" };
  const rotate = {
    ml: 1,
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  };

  const icons = {
    // 1: <DesignServices sx={ active ? rotate : defaultSx } />,
    // 2: <SlowMotionVideo sx={ active ? rotate : defaultSx } />,
    // 3: <SwitchAccessShortcutAdd sx={ active ? rotate : defaultSx } />,
    // 4: <Compress sx={ active ? rotate : defaultSx } />,
    // 5: <Dehaze sx={ active ? rotate : defaultSx } />,
    "CUT": <DesignServices sx={ active ? rotate : defaultSx } />,
    "PREPROCESS": <SlowMotionVideo sx={ active ? rotate : defaultSx } />,
    "HEAT": <SwitchAccessShortcutAdd sx={ active ? rotate : defaultSx } />,
    "LAMINATION": <Compress sx={ active ? rotate : defaultRotate90Sx } />,
    "COUPLED": <Dehaze sx={ active ? rotate : defaultRotate90Sx } />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {/* {icons[String(props.icon)]} */}
      {icons[props.gworkOrder.gprocessCode]}
    </ColorlibStepIconRoot>
  );
}

const StyledTableCell = styled(TableCell)(({ theme, idx }) => {
  // console.log(theme.palette)
  // console.log(idx)
  const borderHead = {
    borderRightWidth: 1,
    borderRightColor: theme.palette.grey[300],
    borderRightStyle: "solid",
  };

  const borderBody = idx%2 === 1 && {
    borderRightWidth: 1,
    borderRightColor: theme.palette.grey[300],
    borderRightStyle: "solid",
  };

  return ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      ...borderHead,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: idx%2 === 0 && 140,
      ...borderBody,
    },
  })
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}));

const GWorkOrderDialog = ({
  // modify,
  crudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
  // gorderId,
  originRefresh,
  refreshParams,
}) => {
  // const detailRef = useRef(); // 자식 함수 호출하기 위함(번호판 입력값을 목록에 설정)

  // console.log(selectedRow)
  const [errors, setErrors] = useState([]);
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [gglassExpanded, setGglassExpanded] = useState(true);
  const [detailExpanded, setDetailExpanded] = useState(true);
  // const [fullScreen, setFullScreen] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  // const [tabValue, setTabValue] = useState(1);
  const [gproject, setGProject] = useState({});
  const [mode, setMode] = useState("");
  const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  // const [oldGProjectId, setOldGProjectId] = useState(null);
  const [gorderDetails, setGorderDetails] = useState([]);

  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [receiveId, setReceiveId] = useState();
  const [receiveType, setReceiveType] = useState("");
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  // const [deliveryDate, setDeliveryDate] = useState(new Date())
  
  const [amountDefects, setAmountDefects] = useState([]);
  const [amountsPersistance, setAmountsPersistance] = useState({});

  const [loading, setLoading] = useState(false);

  // const [clickAmount, setClickAmount] = useState("");
  // const [clickId, setClickId] = useState("");
  const [inputMode, setInputMode] = useState('basic');

  const [detailLengthByGProcess, setDetailLengthByGProcess] = useState(0);
  const [editingAmount, setEditingAmount] = useState(false);

  const [searchParams] = useSearchParams();
  

  const handleDialogClose = () => {
    // console.log(gproject)
    // setDialogInfo();
    setOpen(false);
    if (!objectEmptyCheck(gproject)) {
      setGProject({});
      setValue("gprojectId", null);
      // 프로젝트로부터 발주시 창을 닫고 등록하기를 누르면 useEffect ~[selectedRow]가 호출되지 않으므로 창 닫을 때 주문창을 초기화해야 한다.
      setDialogInfo();
      // [
      //   "id",
      //   "orderNo",
      //   "gprojectId",
      //   "gclientId",
      //   "site",
      //   "inChargeNameGclient",
      //   "inChargePhoneGclient",
      //   "deliveryAddress",
      //   "orderDate",
      //   "deliveryDate",
      //   "ordererName",
      //   "ordererPhone",
      //   "ordererEmail",
      //   "comments",
      //   "gorderDetails"
      // ].forEach(item => {
      //   if (item === "orderDate" || item === "deliveryDate") {
      //     setValue(item, new Date())
      //   } else if (item === "gorderDetails") {
      //     setValue(item, selectedRow && selectedRow[item] || undefined);
      //   } else {
      //     setValue(item, selectedRow && selectedRow[item] || "");
      //   }
      // });
      navigate('/gorders');
    } else {
      initDialogInfo();

      refresh && refresh();
    }
  };

  // const handleDialogMinMax = () => {
  //   setFullScreen(!fullScreen);
  // }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const gclients = useSelector((state) => state.gclient.gclients);
  const gprojects = useSelector((state) => state.gproject.gprojects);

  const gworkOrders = useSelector((state) => state.gworkOrder.gworkOrders);
  const gworkOrderDetails = useSelector((state) => state.gworkOrderDetail.gworkOrderDetails);

  // 데이터 관리
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // const selectAllGClient = () => dispatch(gclientActions.selectAll());
  const selectGClientByType = (type) => dispatch(gclientActions.selectByTypeByQuery(type));
  const selectAllGProject = () => dispatch(gprojectActions.selectAll());
  const selectGProjectDirect = (gprojectId) => gprojectActions.selectDirect(gprojectId);
  const init = () => dispatch(gorderActions.initGOrder());
  const selectAllByProjectByDirect = (gprojectId) => gorderActions.selectAllByProjectByDirect(gprojectId);
  const selectByGOrderId = (gorderId) => dispatch(gworkOrderActions.selectByGOrderId(gorderId));
  const setAmountsDirect = ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => gworkOrderActions.setAmountsDirect({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects });

  useEffect(
    async () => {
      // /*await */selectAllGClient();
      /*await */selectGClientByType('FABRICATOR');
      /*await */selectAllGProject();

    }, [dispatch]
  );

  useEffect(
    async () => {
      console.log(gworkOrderDetails);
      if (gworkOrderDetails.length > 0) {
        const gworkOrderProcessIds = gworkOrderDetails.map(item => item.gworkOrderProcessId);
        console.log(gworkOrderProcessIds)

        let gprocessLength = [...new Set(gworkOrderProcessIds)];
        console.log(gprocessLength);
        setDetailLengthByGProcess(gworkOrderDetails.length/gprocessLength.length);
      }
    }, [gworkOrderDetails]
  );

  const setDialogInfo = () => {
    const init = {};
    [
      "id",
      "orderNo",
      "gprojectId",
      "gclientId",
      "site",
      "inChargeNameGclient",
      "inChargePhoneGclient",
      "deliveryAddress",
      "orderDate",
      "deliveryDate",
      "ordererName",
      "ordererPhone",
      "ordererEmail",
      "comments",
      "status",
      "gorderDetails"
    ].forEach(item => {
      if (item === "orderDate" || item === "deliveryDate") {
        // setValue(item, new Date())
        setValue(item, selectedRow && selectedRow[item] || new Date());
      } else if (item === "gorderDetails") {
        setValue(item, selectedRow && selectedRow[item] || undefined);
        setGorderDetails(selectedRow?.gorderDetails);
      } else {
        setValue(item, selectedRow && selectedRow[item] || "");
      }
    });

    setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
  }

  const initDialogInfo = () => {
    const init = {};
    [
      "id",
      "orderNo",
      // "gclientId",
      "site",
      "deliveryDate",
      // "gorderDetails"
    ].forEach(item => {
      if (item === "deliveryDate") {
        setValue(item, new Date());
      // } else if (item === "gorderDetails") {
      //   setValue(item, selectedRow && selectedRow[item] || undefined);
      //   setGorderDetails(selectedRow?.gorderDetails);
      } else {
        setValue(item, "");
      }
    });

    setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
  }

  // useEffect(
  //   () => {
  //     // console.log(`modify: ${modify}, opne: ${open}`);
  //     if (open) { // 다이얼로그가 열릴 때
  //       if (!modify) { // 등록인 경우 기존 데이터 초기화
  //         setDialogInfo();
  //       }
  //     }
  //   }, [open]
  // )

  // 아래는 목록에서 한 행 설정시 selectedRow값이 있는 상태로 호출되고, 등록하기 누르면 목록에서 init~하여 redux 통해서 null 설정되어 selectedRow값이 null인채로 호출됨
  useEffect(
    async () => {
      setDialogInfo();
      // if (selectedRow) {
        // const init = {};
        // [
        //   "id",
        //   "orderNo",
        //   "gprojectId",
        //   "gclientId",
        //   "site",
        //   "inChargeNameGclient",
        //   "inChargePhoneGclient",
        //   "deliveryAddress",
        //   "orderDate",
        //   "deliveryDate",
        //   "ordererName",
        //   "ordererPhone",
        //   "ordererEmail",
        //   "comments",
        //   "gorderDetails"
        // ].forEach(item => {
        //   if (item === "orderDate" || item === "deliveryDate") {
        //     // 날짜 데이터의 경우 selectedRow값이 있으면 설정되나 없을 경우 new Date()해도 기존값이 보임. useState 이용해서 갱신해야 함
        //     setValue(item, selectedRow && selectedRow[item] || new Date());
        //   } else if (item === "gorderDetails") {
        //     setValue(item, selectedRow && selectedRow[item] || undefined);
        //     setGorderDetails(selectedRow?.gorderDetails);
        //   } else {
        //     setValue(item, selectedRow && selectedRow[item] || "");
        //   }
        // });
        // setInitData(init); // setState가 없으면 날짜 데이터는 설정안됨 (다이얼로그를 닫았다 열면 적용)
      // } else {
      //   setDialogInfo();
      // }
      
      // 프로젝트 수정창에서 발주를 진행한 경우
      const gprojectId = searchParams.get('gprojectId');
      const mode = searchParams.get('mode');
      if (gprojectId && mode) {
        const gproject = await selectGProjectDirect(gprojectId);
        setGProject(gproject);
        if (!objectEmptyCheck(gproject)) {
          setValue("gprojectId", gproject.id);
          setMode(mode);

          // 마지막 발주 정보 설정. 첫 발주일 경우 프로젝트 정보 설정
          setLastOrderInfoOrProjectInfo(gproject);
        }
      }
      
      // selectedRow && setOpenProgress(true);
      // alert(index)
      // // TODO : 임시. 데이터 로딩에 시간이 걸려 GWorkOrderExcel안에서 데이터로딩 후 진행바 삭제. 그러나 일반의 경우 아직 미구현이므로 여기서 진행바 임시 삭제
      // if(index === "1") {
      //   setOpenProgress(false);
      // }
      setRows([selectedRow]); // selectedRow는 객체이므로 배열로 바꾸어주어야 한다.

      setAmountsPersistance({
        amountCompleted: selectedRow?.amountCompleted,
        amountInomplete: selectedRow?.amountInomplete,
        amountDefect: selectedRow?.amountDefect,
        amountDefects: selectedRow?.amountDefects,
      });

      // setAmountDefects(selectedRow?.gprocessDefects.map((item, index) => {
      //   return {
      //     ...item,
      //     amount: item?.amount || 0,
      //   }
      // }));

      // 임시
      setTimeout(async () => {
        await hideWatermark();
        // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      }, 300);

      // TODO : 아래는 왜 사용하지??? 아마도 전체공정표기를 위해서인가??? selectedRow 체크를 하지 않아 작업의뢰 목록이 비산발적으로 사라지는 문제가 있었음
      // if (selectedRow) {
      //   await selectByGOrderId(selectedRow.gorderId);
      // }

    }, [selectedRow]
  );

  useEffect(
    () => {
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setTimeout(async () => {
        await hideWatermark();
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      }, 300);
    }, [gworkOrderDetails]
  )

  // 마지막 발주 정보 설정. 첫 발주일 경우 프로젝트 정보 설정
  const setLastOrderInfoOrProjectInfo = async (gproject) => {
    const { gorders } = await selectAllByProjectByDirect(gproject.id);
    if (gorders.length > 0) {
      [
        "gclientId",
        "site",
        "inChargeNameGclient",
        "inChargePhoneGclient",
        "deliveryAddress",
        "ordererName",
        "ordererPhone",
        "ordererEmail"
      ].forEach(item => {
        setValue(item, gorders[0][item]);
      });
    } else {
      const { site, siteAddress, gclientDetails } = gproject;
    
      setValue("site", site);
      setValue("deliveryAddress", siteAddress);
  
      // 가공업체는 프로젝트 설정시 첫번째 가공업체를 기본으로 한다.
      const gclient = gclientDetails.filter(gclient => gclient.code === 'FABRICATOR');
      if (gclient[0].gclients.length > 0) {
        setValue("gclientId", gclient[0].gclients[0].value.id);
      }
    }
  }

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   setFullScreen(true);
  // };

  // const handleChangePeriod = (value, name) => {
  //   return true;
  // }

  const setFocusSelectProjectInput = () => {
    selectProjectInput.focus();
  }

  const setSelectProjectInputRef = element => {
    selectProjectInput = element;
  };

  const handleCreateGWorkOrder = () => {
    alert("111 ")
  }

  // TODO : 왜 processRowUpdate은 안되는지??? 그래서 useState를 사용하여 persistance를 처리. 추후 재검토 필요
  const processRowUpdate = (newRow) => {
    
  };

  const handleCellEditCommit = (newCell) => {
    const { id, field, value } = newCell;

    setAmountDefects(
      amountDefects.map(i => {
        if (i.defect === field) {
          return {
            defect: field,
            amount: value,
          }
        } else {
          return i;
        }
      })
    );
  }

  const handleInputModeChange = (e, newInputMode) => {
    setInputMode(newInputMode || inputMode);

    // newInputMode === "numberPanel" && detailRef.current.enableApplyButton();

    e.stopPropagation();
  }

  const sample = [
    { name: "", value: "" },
    { name: "", value: "" },
  ];

  const getTableData = () => {
    const gcomponentItems = gworkOrderDetails[0]?.selectedGcomponentItems;
    if (!(gcomponentItems && Array.isArray(gcomponentItems) && gcomponentItems.length > 0)) {
      return;
    }
    
    // 2차원 배열 전체 사이즈를 미리 구하자.
    const columnLength = gcomponentItems.length;
    let rowLength = 0;
    gcomponentItems.forEach(gcomponent => {
      if (rowLength < gcomponent.length) {
        rowLength = gcomponent.length;
      }
    });

    // console.log(`row: ${rowLength}, col: ${columnLength}`);
    
    const newGcomponentItems = Array.from(Array(rowLength), () => Array(columnLength*2/* name, value */).fill(undefined));

    let columnIdx = 0;
    gcomponentItems.forEach((gcomponent, gcomponentIdx) => {
      gcomponent.forEach((item, itemIdx) => {
        const { name, value } = item;
        columnIdx = 0;
        newGcomponentItems[itemIdx][columnIdx + gcomponentIdx*2] = name;
        // TODO : 현재보다 단계가 더 깊어지면 아래 코드 보강 필요
        newGcomponentItems[itemIdx][++columnIdx + gcomponentIdx*2] = value.code ? `: ${value.code}`: ( Array.isArray(value) ? `: ${value.map(item=>item.code).join(" ")}` : undefined );
      });
    })

    console.log(gcomponentItems);
    console.log(newGcomponentItems);
    
    return newGcomponentItems;
  }

  const generateGlass = () => {
    const colGrid = (row, rowIdx) => {
      return ['Left', 1, 2, 3, 'Right'].map((col, colIdx) => {
        return (
          <Grid key={`${row}${col}`} item>
            <Box
              display="flex"
              sx={{
                height: 25,
                width: 40,
                bgcolor: `${row}${col}` === gworkOrderDetails[0]?.labelPosition ? '#bbdefb' : '#e3f2fd',
                border: `${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight' ? '1px dashed #1976d2' : '',
                borderRadius: '5px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="primary.main">
                {
                  `${row}${col}` === gworkOrderDetails[0]?.labelPosition ?
                    '라벨 '
                  :
                    (
                      rowIdx === 2 && colIdx == 2 ?
                      (
                        gworkOrderDetails[0]?.labelPositionView === "inout" ?
                          <Icon fontSize="large">logout</Icon>
                        :
                          <Icon fontSize="large">login</Icon>
                      )
                      :
                        ''
                    )
                }
              </Typography>
            </Box>
          </Grid>
        )
      })
    }
    
    return ['Top', 1, 2, 3, 'Bottom'].map((row, rowIdx) => {
      return (
        <Grid container justifyContent="center" spacing={0}>
          {colGrid(row, rowIdx)}
        </Grid>
      );
    });
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby={!fullScreen && "draggable-dialog-title"}
        maxWidth="lg"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          fullScreen={fullScreen}
          color={fullScreen ? (refreshParams.refreshType === 'GWORK_ORDERS' ? "white" : `${getTextColorByBackgroundColor(gworkOrderDetails[0]?.gprocessColor)}`) : ""}
          style={{ cursor: !fullScreen && 'move', backgroundColor: fullScreen ? (refreshParams.refreshType === 'GWORK_ORDERS' ? "#1976d2" : `${gworkOrderDetails[0]?.gprocessColor}`) : "" }}
        >
          {"작업의뢰서"}
        </DialogTitleClose>
        <DialogContent dividers>
          <>
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                  <AccordionSummary aria-controls="basic-content" id="basic-header">
                    <Typography variant="h6" component="div">{"기본정보"}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"의뢰번호"}
                          value={`${gworkOrderDetails[0]?.workOrderNo}-${gworkOrderDetails[0]?.workOrderNoSuffix}`}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      {/* TODO : 수주일련번호여야 함 */}
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"수주일련번호"}
                          value={gworkOrderDetails[0]?.receiveSerialNo}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"현장명"}
                          value={gworkOrderDetails[0]?.site}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputDate
                          control={control}
                          label={"납기일"}
                          value={gworkOrderDetails[0]?.deliveryDate}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"거래선명"}
                          value={gworkOrderDetails[0]?.orderer?.name}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Accordion expanded={gglassExpanded} onChange={() => setGglassExpanded(!gglassExpanded)}>
                  <AccordionSummary aria-controls="gglass-content" id="gglass-header">
                    <Typography variant="h6" component="div">{"유리정보"}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FormInputText
                                  control={control}
                                  label={"품번"}
                                  value={`${gworkOrderDetails[0]?.no}`}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormInputText
                                  control={control}
                                  label={"품명"}
                                  value={`${gworkOrderDetails[0]?.name}`}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormInputText
                                  control={control}
                                  label={"보는 방향"}
                                  value={`${gworkOrderDetails[0]?.labelPositionView === "inout" ? "실내에서 봤을 때" : "실외에서 봤을 때"}`}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                          <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                        <Paper sx={{ height: 145, width: 220, backgroundColor: '#e3f2fd', pt: 1 }}>
                          { generateGlass() }
                        </Paper>
                      </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"규격"}
                          value={`${gworkOrderDetails[0]?.standard}`}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"사양"}
                          value={`${gworkOrderDetails[0]?.specification}`}
                          multiline
                          maxRows={5}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"비고"}
                          value={`${gworkOrderDetails[0]?.otherSpecs}`}
                          multiline
                          maxRows={5}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"설명"}
                          value={gworkOrderDetails[0]?.comments}
                          multiline
                          maxRows={5}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          control={control}
                          label={"수주내역비고"}
                          value={`${gworkOrderDetails[0]?.notes}`}
                          multiline
                          maxRows={5}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Accordion expanded={gglassExpanded} onChange={() => setGglassExpanded(!gglassExpanded)}>
                  <AccordionSummary aria-controls="gglass-detail-content" id="gglass-detail-header">
                    <Typography variant="h6" component="div">{"유리상세정보"}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/* <FormInputText
                          control={control}
                          label={"상세"}
                          multiline
                          maxRows={10}
                          value={`${JSON.stringify(gworkOrderDetails[0]?.selectedGcomponentItems)}`}
                        /> */}
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 500 }} size="small" aria-label="custom pagination table">
                            <TableHead>
                            {
                              getTableData()?.map((row, idx) => {
                                if (idx === 0) {
                                  return (
                                    <TableRow>
                                      {
                                        row.map((col) => {
                                          if (col) {
                                            return (
                                              <StyledTableCell colSpan={2} align="center">
                                                {col}
                                              </StyledTableCell>
                                            );
                                          }
                                          return null;
                                        })
                                      }
                                    </TableRow>
                                  )
                                }
                              })
                            }
                            </TableHead>
                            <TableBody>
                            {
                              getTableData()?.map((row, idx) => {
                                if (idx > 0) {
                                  return (
                                    <StyledTableRow key={idx}>
                                      {
                                        row.map((col, colIdx) => {
                                          return (
                                            // <TableCell style={{ width: 160 }} align="right">
                                            // <StyledTableCell component="th" scope="row">
                                            <StyledTableCell idx={colIdx}>
                                              {col}
                                            </StyledTableCell>
                                          );
                                        })
                                      }
                                    </StyledTableRow>
                                  )
                                }
                              })
                            }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion expanded={detailExpanded} onChange={() => !editingAmount && setDetailExpanded(!detailExpanded)}>
                  <AccordionSummary aria-controls="detail-content" id="detail-header">
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <Typography variant="h6" component="div">{"상세공정"}</Typography>
                      </Grid>
                      {
                        crudMode === 'U' && (
                          <Grid item xs={10} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                            {"입력모드"}
                            <ToggleButtonGroup
                              color="primary"
                              value={inputMode}
                              exclusive
                              onChange={handleInputModeChange}
                              sx={{ ml: 2 }}
                              size="small"
                              disabled={editingAmount}
                            >
                              <ToggleButton value="basic">{"기본"}</ToggleButton>
                              <ToggleButton value="numberPanel">{"번호판"}</ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        )
                      }   
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                        { 
                          gworkOrderDetails.map((detail, idx) => {
                            if (idx%detailLengthByGProcess === detailLengthByGProcess-1) {
                              const startPos = Math.trunc(idx/detailLengthByGProcess)*detailLengthByGProcess;
                              const endPos = startPos + detailLengthByGProcess;
                              return (
                                <Grid item xs={12}>
                                  <GWorkOrderDialogDetail
                                    crudMode={crudMode}
                                    details={gworkOrderDetails.slice(startPos, endPos)}
                                    inputMode={inputMode}
                                    // setClickId={setClickId}
                                    // setClickAmount={setClickAmount}
                                    // setNumberOnPanel={setNumberOnPanel}
                                    // setOpenNumberPanel={setOpenNumberPanel}
                                    setEditingAmount={setEditingAmount}
                                    // ref={detailRef}
                                    refreshParams={refreshParams}
                                  />
                                </Grid>
                              );
                            } else {
                              return;
                            }
                          })
                        }
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <GWorkOrderProcessDialog
        open={receiveDialogOpen}
        setOpen={setReceiveDialogOpen}
        id={receiveId}
        receiveType={receiveType}
        onClose={handleDialogClose}
        refresh={refresh}
        originRefresh={originRefresh}
      />
    </>
  );
};

export default GWorkOrderDialog;
