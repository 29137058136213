import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  // Link,
  // Tab,
  // Tabs,
  Tooltip,
} from '@mui/material';
import  {
  KeyboardArrowDown,
} from '@mui/icons-material';
// import { objectEmptyCheck } from "../../utils";
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import GWorkOrderManagement from "../GWorkOrder/GWorkOrderManagement";
// import * as gclientActions from "../../store/gclient";
// import * as gprojectActions from "../../store/gproject";
import GReceptionExcel from "./GReceptionExcel";
import GReceptionProcessDialog from "./GReceptionProcessDialog";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  gclientId: "",
  site: "",
  inChargeNameGclient: "",
  inChargePhoneGclient: "",
  // inChargeEmailGclient: "",
  deliveryAddress: "",
  orderDate: new Date(),
  deliveryDate: new Date(),
  // owner: "",
  ordererName: "",
  ordererPhone: "",
  ordererEmail: "",
  comments: "",
  gorderDetails: undefined,
};

let selectProjectInput = null;
// let oldGProjectId = null;

const GReceptionDialog = ({
  modify,
  open,
  setOpen,
  selectedRow,
  refresh,
  setReceiveDialogOpen,
  // greceptionId,
  setGreceptionId,
  setReceiveType,
}) => {
  const [errors, setErrors] = useState([]);
  // const [fullScreen, setFullScreen] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  // const [tabValue, setTabValue] = useState(1);
  const [openProgress, setOpenProgress] = useState(false);
  // const [oldGProjectId, setOldGProjectId] = useState(null);
  // const [gorderDetails, setGorderDetails] = useState([]);
  // const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  // const [receiveType, setReceiveType] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [greceptionId, setGreceptionId] = useState("");
  // const [receiveSerialNo, setReceiveSerialNo] = useState("");

  const handleDialogClose = () => {
    setOpen(false);
    refresh && refresh();
  };

  // const handleDialogMinMax = () => {
  //   setFullScreen(!fullScreen);
  // }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // const gclients = useSelector((state) => state.gclient.gclients);
  // const gprojects = useSelector((state) => state.gproject.gprojects);

  // 데이터 관리
  // const dispatch = useDispatch();
  
  // const selectGClientByType = (type) => dispatch(gclientActions.selectByTypeByQuery(type));
  // const selectAllGProject = () => dispatch(gprojectActions.selectAll());

  // useEffect(
  //   async () => {
  //     /*await */selectGClientByType('FABRICATOR');
  //     /*await */selectAllGProject();

  //   }, [dispatch]
  // );

  const setFocusSelectProjectInput = () => {
    selectProjectInput.focus();
  }

  const handleGreceptionProcess = (type) => {
    setGreceptionId(selectedRow?.id);
    // setGreceptionId(greceptionId);

    if (type === 'workOrder') {
      // setReceiveSerialNo(selectedRow?.receiveSerialNo);
      setOpen(false); // 다이얼로그 있는 상태에서는 Drawer가 위로 나오지 않아 다이얼로그 닫았다가 Drawer 닫을 때 다시 여는 방식 사용. TODO : zIndex 조절로 가능한지 확인 필요
      setDrawerOpen(true);
    } else {
      setReceiveType(type);
      setReceiveDialogOpen(true);
    }
  } 

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    setDrawerOpen(open);

    setOpen(true); // 다이얼로그 있는 상태에서는 Drawer가 위로 나오지 않아 다이얼로그 닫았다가 Drawer 닫을 때 다시 여는 방식 사용. TODO : zIndex 조절로 가능한지 확인 필요
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          // onMinMax={tabValue === 1 && handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          {/* {modify ? "접수 상세" : "작업의뢰서 발행"} */}
          {"접수 상세"}
        </DialogTitleClose>
        <DialogContent dividers>
          {/* TODO : 엑셀그리드 형식이 아닌 입력의 경우 이보다 더 좋은 UX가 아직 개발전이므로 탭을 일단 막음 */}
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label={"엑셀"} {...a11yProps(0)} />
              <Tab label={"일반"} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}> */}
          <>
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputText
                      // name={"id"}
                      control={control}
                      label={"아이디"}
                      value={selectedRow?.id}
                      // TODO : 기존 readOnly를 inputProps를 통하지 않고 설정한 곳 체크하여 아래처럼 바꿀 것
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* 아래처럼 name을 주지 않으면 readOnly 효과가 생김. 그러나 inputProps로 readOnly하면 커서도 생기지 않음 */}
                    <FormInputText
                      // name={"orderSerialNo"}
                      control={control}
                      label={"수주일련번호"}
                      value={selectedRow?.receiveSerialNo}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"발주번호"}
                      value={selectedRow?.orderNo}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputDate
                      control={control}
                      label={"발주일"}
                      value={selectedRow?.orderDate} // 발주(GOrders)의 경우 (new Date(selectedRow.orderDate)).getDateWithStartHours() 로 하지만 수주의 경우 저장이 없으므로 그대로 둔다.
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputDate
                      control={control}
                      label={"납기일"}
                      value={selectedRow?.deliveryDate} // 발주(GOrders)의 경우 (new Date(selectedRow.deliveryDate)).getDateWithEndHours() 로 하지만 수주의 경우 저장이 없으므로 그대로 둔다.
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"설명"}
                      multiline
                      maxRows={5}
                      value={selectedRow?.comments}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"발주업체"}
                      value={selectedRow?.orderer?.name}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"현장명"}
                      value={selectedRow?.site}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"착지주소"}
                      value={selectedRow?.deliveryAddress}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"현장소장명"}
                      value={selectedRow?.inChargeNameGclient}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"현장소장연락처"}
                      value={selectedRow?.inChargePhoneGclient}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormInputText
                      name={"inChargeEmailGclient"}
                      control={control}
                      label={"담당자이메일"}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <FormInputText
                      name={"owner"}
                      control={control}
                      label={"발주자명"}
                      // multiline
                      // maxRows={5}
                    /> */}
                    <FormInputText
                      control={control}
                      label={"발주자명"}
                      value={selectedRow?.ordererName}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"발주자연락처"}
                      value={selectedRow?.ordererPhone}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={control}
                      label={"발주자이메일"}
                      value={selectedRow?.ordererEmail}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <GReceptionExcel
              // initExcelDatas={selectedRow?.gorderDetails}
              initExcelDatas={selectedRow?.greceptionDetails}
              selectedRow={selectedRow}
              setValue={setValue}
              getValues={getValues}
              setOpenProgress={setOpenProgress}
              focus={setFocusSelectProjectInput}
            />
          </>
        </DialogContent>
        <DialogActions>
          {
            selectedRow?.status === 'RECEIVE' && (
              // Drawer 방식으로 변경
              // <Button>
              //   <Link
              //     href={`/gworkOrders?gorderId=${getValues("id")}&mode=register`}
              //     variant="body2"
              //     underline="none"
              //   >
              //     {"작업의뢰서 발행"}
              //   </Link>
              // </Button>
              selectedRow?.statusWorkOrder === 'PRE' && <Button onClick={() => handleGreceptionProcess('workOrder')}>{"작업의뢰서 발행"}</Button>
            )
          }
          {
            selectedRow?.status !== 'RECEIVE' && selectedRow?.status !== 'RETURN' && (
              <>
                <Button onClick={() => handleGreceptionProcess('receive')}>{"접수"}</Button>
                <Button onClick={() => handleGreceptionProcess('return')}>{"반송"}</Button>
              </>
            )
          }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GWorkOrderManagement
          // title={`작업의뢰 ( 수주일련번호 : ${receiveSerialNo} )`}
          // greceptionId={greceptionId}
          title={`작업의뢰 ( 수주일련번호 : ${selectedRow?.receiveSerialNo} )`}
          greceptionId={selectedRow?.id}
        />
      </Drawer>
    </>
  );
};

export default GReceptionDialog;
