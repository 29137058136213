import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Fade,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  AccountCircle,
  QuestionMark,
  ImportContacts,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { v4 as uuidv4 } from 'uuid';

import { objectEmptyCheck } from "../../utils";

import * as sessionActions from "../../store/session";
import * as gclientActions from "../../store/gclient";
import * as mailActions from "../../store/mail";

import { FormInputAutoComplete1, FormInputText } from "../../components/form";
import { AlertDialog } from "../../components/dialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";

import CopyrightKFGWA from "../layout/CopyrightKFGWA";
import ConsentDialog from '../../ConsentDialog';

const defaultValues = {
  gclientId: "",
  id: uuidv4(),
  userId: "", // email
  name: "", // TODO : 필드 추가 필요
  mobile: "",
  password: "",
  confirmPassword: "",
};

const validateFields = {
  gclientId: "회원사",
  userId: "사용자 아이디(이메일)",
  name: "이름",
  mobile: "전화번호",
  password: "비밀번호",
  confirmPassword: "비밀번호 확인",
};

const theme = createTheme();

const requiredMessage = "을(를) 입력해주세요.";
const emailMessage = "을(를) 올바른 이메일 형태로 입력해주세요.";

const schema = yup.object({
  gclientId: yup.string().required(requiredMessage),
  userId: yup.string().email(emailMessage).required(requiredMessage),
  name: yup.string().required(requiredMessage),
  mobile: yup.string().required(requiredMessage),
  password: yup.string().required(requiredMessage),
  confirmPassword: yup.string().required(requiredMessage),
}).required();

const SignUpGUser = () => {
  const { handleSubmit, getValues, setValue, reset, control, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionUser = useSelector((state) => state.session.sessionUser);

  const selectAllGClientDirect = () => gclientActions.selectValidAllDirect();
  const signupUserDirect = ({ id, userId, name, mobile, password, gclientId, consented }) => sessionActions.signupUserDirect({ id, userId, name, mobile, password, gclientId, consented });
  const sendSystemMailNoAuthDrect = ({ to, type, from }) => mailActions.sendSystemMailNoAuthDrect({ to, type, from })

  // const [gclientId, setGClientId] = useState("");
  const [gclients, setGClients] = useState([]);
  const [mobileMask, setMobileMask] = useState("000-0000-0000");
  const [alertInfo, setAlertInfo] = useState({});
  const [error, setError] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [consented, setConsented ] = useState(false);

  // 아래 if 문 위에 useEffect가 있어야 한다.
  useEffect(
    () => {
      const selectGClients = async () => {
        const resGClients = await selectAllGClientDirect();
        console.log(resGClients)
        setGClients(resGClients);
      }

      selectGClients();
    }, [dispatch]
  )

  useEffect(
    () => {
      console.log(errors)
      if (!objectEmptyCheck(errors)) {
        let error;
        for (const [key, value] of Object.entries(validateFields)) {
          // console.log(`${key}: ${value}`);
          if (errors[key]) {
            error = errors[key];
            error.fieldName = value;
            break;
          }
        }

        // console.log(error)
        
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: `${error.fieldName}${error.message}`,
          open: true,
        });
      }
    }, [errors]
  )

  // useEffect(
  //   () => {
  //     console.log(getValues('phone'))
  //   }, [getValues('phone')]
  // )

  if (sessionUser && consented) return <Navigate  to="/glass/dashboard" />;

  const onSubmit = async ({
    id,
    userId,
    name,
    mobile,
    password,
    confirmPassword,
    gclientId,
  }) => {
    if (!consented) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "정보 수집 및 활용 버튼을 눌러 해당 내용에 동의해주세요.",
        open: true,
      });

      return;
    }

    if (password === confirmPassword) {
      // console.log({
      //   id,
      //   userId,
      //   name,
      //   mobile,
      //   password,
      //   confirmPassword,
      //   gclientId,
      // });
      // return;

      try {
        const res = await signupUserDirect({ id, userId, name, mobile, password, gclientId, consented });
        if (res.status === 200) {
          for (const [item, value] of Object.entries(defaultValues)) {
            setValue(item, value);
          }
          
          const gclient = gclients.find(gclient => gclient.id === gclientId);

          // 회원사 관리자에게 메일 보내기. 메일관련 안내창은 별도로 띄우지 않음
          try {
            await sendSystemMailNoAuthDrect({ to: gclient.email, type: "승인요청", from: userId });
            // const messageAlert = (<><span style={{ color: "#1976d2" }}>{userId}</span>{"님께 메일을 발송하였습니다."}</>);
            // dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));
            
            // setLoaded(true);
          } catch(e) {
            // dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert: "가입 메일 보내기가 완료되지 않았습니다. 관리자에게 문의하세요." } }));
          }
          
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: `${gclient?.name ? gclient.name : "회원사"}의 사용자로 가입되었습니다. 회원사 관리자의 승인 후 로그인이 가능합니다.`,
            open: true,
          });
        }
      } catch (e) {
        console.log(e)
        setError(e);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 2000);
      }
    } else {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "비밀번호와 확인을 위해 입력한 비밀번호가 일치하지 않습니다.",
        open: true,
      });
    }
    // return setErrors(['Confirm Password field must be the same as the Password field']);
  };

  // const handleKeyDownPhone = (e) => {
  //   console.log(e.key)
  // }

  const handleChangeGClient = (e, value) => {
    if (value) {
      const { id: gclientId } = value;
      // setGClientId(gclientId);
    }
  }

  const handleChangeMobile = (e) => {
    const { value } = e.target;
    
    if(value.length < 13) {
      setMobileMask("000-000-00000"); // 한자리 여분을 두어야 함
    } else {
      setMobileMask("000-0000-0000");
    }
    
    setValue('mobile', value);
  }

  const moveLoginPage = () => {
    consented && navigate("/glass/signin/guser");
  }
  
  const handleClickConsent = () => {
    setOpen(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><AccountCircle /></Avatar>
          <Typography component="h1" variant="h5">{"회원사 사용자 가입"}</Typography>
          <Box sx={{ mt: 3 }}>
            {/* {JSON.stringify(errors)} */}
            {/* <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul> */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ display: 'none' }}>
                    <FormInputText
                      autoComplete="id"
                      name={"id"}
                      control={control}
                      required
                      fullWidth
                      label={"아이디"}
                    />
                  </Grid>
                  <Grid item xs={12} display="flex" alignItems="end" sx={{ height: 40 }}>
                    <Fade in={fadeIn}>
                      <Typography sx={{ color: "#ff5722" }}>
                        { error?.message }
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputAutoComplete1
                      fullWidth
                      control={control}
                      required
                      label={"회원사 선택"}
                      name={"gclientId"}
                      // TODO : 우신복층유리 1차 오픈 위한 코드
                      // options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
                      options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                      setValue={setValue}
                      onCustomChange={handleChangeGClient}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="userId"
                      required
                      fullWidth
                      label="이메일 아이디"
                      name={"userId"}
                      control={control}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="name"
                      name={"name"}
                      control={control}
                      required
                      fullWidth
                      label={"이름"}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="mobile"
                      name={"mobile"}
                      control={control}
                      required
                      fullWidth
                      label={"모바일"}
                      placeholder={"모바일번호를 입력해주세요. - 는 자동입력됩니다."}
                      InputProps={{
                        inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                        inputProps: {
                          style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                            // mask: "000-0000-0000",
                            mask: mobileMask,
                            definitions: {
                              '#': /[1-9]/,
                            },
                          },
                        }
                      }}
                      errorStyle={{ message: false, border: true }}
                      onChange={handleChangeMobile}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      required
                      fullWidth
                      name={"password"}
                      control={control}
                      label={"비밀번호"}
                      type="password"
                      autoComplete="new-password"
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      required
                      fullWidth
                      name={"confirmPassword"}
                      control={control}
                      label={"비밀번호 확인"}
                      type="password"
                      autoComplete="new-confirm-password"
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button
              onClick={handleClickConsent}
              fullWidth
              variant="outlined"
              startIcon={<ImportContacts />}
              sx={{ mt: 2, fontWeight: 'bold' }}
            >
              {"정보수집 및 활용 동의"}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {"가입하기"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" display="flex" alignItems="center">
                  <Link href="/glass/signin/guser" variant="body2" sx={{ mr: 3 }}>
                    {"계정이 있으십니까? 로그인해주세요."}
                  </Link>
                  <Link
                    href="/공사다큐 사용자 계정 추가 매뉴얼.pdf"
                    variant="body2"
                    underline="none"
                    target="_blank"
                    rel="noopener"
                  >
                    {"가입 절차 안내"}
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
          afterClose={moveLoginPage}
        />
        <ConsentDialog
          open={open}
          setOpen={setOpen}
          setConsented={setConsented}
          from={{ source: "SignupGUser" }}
        />
      </Container>
      {/* <CopyrightKFGWA /> */}
    </ThemeProvider>
  );
}

export default SignUpGUser;