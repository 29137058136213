import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import { uploadFilePath, fileServerUrl } from '../../config';
import TreeView from '../GProject/GProjectG04TreeView';
import PdfViewer from '../GProject/PdfViewer';
import FileViewer from 'react-file-viewer';
import * as gunitActions from "../../store/gunit";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  type: "",
  comments: "",
};
const theme = createTheme();

const G04docuMaterialApprovalDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  queryPaging,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [fullScreen, setFullScreen] = useState(true);
  const [show, setShow] = useState(true);
  const [selected, setSelected] = React.useState([]);
  const [g04docuDataWithoutDocus, setG04docuDataWithoutDocus] = useState([]);
  const [g04docuDataWithDocus, setG04docuDataWithDocus] = useState([]);
  const [fileType, setFileType] = useState("");
  const [documentPath, setDocumentPath] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);

  const handleDialogClose = () => {
    setShowFileViewer(false);
    setOpen(false);
  };

  const sessionUser = useSelector(state => state.session.sessionUser);
  
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addGUnit = ({ name, code, type, comments }) => dispatch(gunitActions.create({ name, code, type, comments }))
  const modifyGUnit = ({ id, name, code, type, comments }) => dispatch(gunitActions.modify({ id, name, code, type, comments }))
  // const generateDirect = (treeData) => g04docuFileActions.generateDirect(treeData)
  const makeMaterialApproval = ({ gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions }) => g04docuMaterialApprovalActions.makeMaterialApproval({ gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions })

  const onSubmit = ({ id, name, code, type, comments }) => {
    setErrors([]);
    
    // console.log({ id, name, code, type, comments });
    // return;
    let func;
    if (crudMode === 'U') {
      func = modifyGUnit;
    } else if ((crudMode === 'C')) {
      func = addGUnit
    }
    func({ id, name, code, type, comments })
      .then (res => {
        handleDialogClose();
        refresh(queryPaging);
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      if (selectedRow) {
        setSelected(selectedRow.excluded);
      }
    }
    , [selectedRow]
  );

  const handleDialogMinMax = () => {
    setFullScreen(!fullScreen);
  }

  const handleClickViewEachDoc = (documentPath) => {
    // setOpenProgress(true);
    // setShowFileViewer(false);
    
    // const ext = getFileType(documentPath)
    // setFileType(ext);

    const path = documentPath.replace(uploadFilePath, fileServerUrl);
    // console.log(path)
    // setDocumentPath(path);
    
    // setTimeout(() => {
    //   setOpenProgress(false);
    //   setShowFileViewer(true);
    // }, 1000);

    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  const handleClickViewMaterialApproval = (documentPath) => {
    // setOpenProgress(true);
    // setShowFileViewer(false);

    // const ext = getFileType(documentPath)
    // setFileType(ext);

    const path = documentPath.replace(uploadFilePath, fileServerUrl);
    // console.log(path)
    // setDocumentPath(path);
    
    // setTimeout(() => {
    //   setOpenProgress(false);
    //   setShowFileViewer(true);
    // }, 1000);

    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  useEffect(
    () => {
      const pgViewerWrapper = document.getElementsByClassName("pg-viewer-wrapper");
      const pgViewer = document.getElementById("pg-viewer");
      
      console.log(pgViewerWrapper)
      console.log(pgViewerWrapper[0])
      console.log(pgViewer)
      if (pgViewerWrapper && pgViewerWrapper.length > 0 && pgViewer) {
      // if (pgViewerWrapper && pgViewer) {
        pgViewer.style.backgroundColor = "grey";
        pgViewerWrapper[0].style.overflowY = "hidden";
      }
    }, [showFileViewer]
  )

  const onError = (e) => {
    console.log(e)
  }

  // TODO : GProjectG04GeneratorDialog.js에서 사용중이므로 통합할 것
  const getFileType = (documentPath) => {
    let ext = "pdf";
    const pos = documentPath.lastIndexOf(".");
    if (pos > -1) {
      ext = documentPath.substring(pos+1);
    }

    return ext;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleDialogClose}
          PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
          aria-labelledby="draggable-dialog-title"
          maxWidth="lg"
          scroll="body"
          sx={{ visibility: show ? 'visible' : 'hidden' }}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            onMinMax={handleDialogMinMax}
            fullScreen={fullScreen}
            color={fullScreen ? "white" : ""}
            style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
          >
            <div id="dialog-position" /*ref={dialogRef}*/>
            {/* <div
              id="dialog-position"
              ref={el => {
                if (el) {
                  dialogTitleRect = el.getBoundingClientRect();
                  // setDialogTitleBoundingClientRect()
                }
              }}
            > */}
              {"서류발급"}
            </div>
          </DialogTitleClose>
          <DialogContent>
            {/* <pre>{JSON.stringify({ selected }, null, 2)}</pre> */}
            {/* <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul> */}
            {/* <SplitterLayout> */}
              <TreeView
                crudMode={crudMode}
                setCrudMode={setCrudMode}
                selected={selected} // TODO : DB에 저장해두어야 할 것으로 보임
                onSelect={setSelected}
                disableRoot
                itemsWithDocus={selectedRow?.generatedInformationWithDocus} // generatedInformation는 문서정보 없는 데이터
                itemsWithoutDocus={g04docuDataWithoutDocus}
                handleClickViewEachDoc={handleClickViewEachDoc}
                handleClickViewMaterialApproval={handleClickViewMaterialApproval}
                disableMultiParentSelection={false}
                makeMaterialApproval={makeMaterialApproval}
                gclient={sessionUser}
                gproject={selectedRow?.gproject}
                from={
                  {
                    source: "G04docuMaterialApprovalDialog",
                    params: {
                      g04docuMaterialApproval: selectedRow,
                    }
                  }
                }
              />
              {/* <PdfViewer documentPath={documentPath} /> */}
              {/* {
                openProgress && (
                  <Grid display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
                    <CircularProgress color="primary" />
                  </Grid>
                )
              }
              <div style={{ width: '100%', height: fileType !== 'pdf' ? '100%' : '' }}>
                {
                  documentPath && showFileViewer && (
                    <>
                      <FileViewer
                        fileType={fileType}
                        filePath={documentPath}
                        // errorComponent={CustomErrorComponent}
                        onError={onError}
                      />
                    </>
                  )
                }
              </div> */}
            {/* </SplitterLayout> */}
          </DialogContent>
          <DialogActions>
            {/* <LoadingButton
              size="small"
              onClick={() => handleSubmit(onSubmit)('save')}
              loading={loading}
            >
              {"저장"}
            </LoadingButton> */}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default G04docuMaterialApprovalDialog;
