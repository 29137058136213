import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, useGridApiContext, koKR } from '@mui/x-data-grid-pro';
// import { bgBG as pickersKoKR } from '@mui/x-date-pickers';
// import { bgBG as coreKoKR } from '@mui/material/locale';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Table,
  TableContainer,
  TableBody,
  // TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  DesignServices,
  SlowMotionVideo,
  SwitchAccessShortcutAdd,
  Compress,
  Dehaze,
  Add,
  Remove,
  OpenInNew,
}
from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import usePrevious from "../../hook/usePrevious";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../accordion";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../../datagrid";
import {
  dateFormat,
  objectEmptyCheck,
  hideWatermark,
  getTextColorByBackgroundColor,
} from "../../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../../form";
import NumberFormatCustom from "../../form/inputProps/NumberFormatCustom";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../../dialog";

import GWorkOrderStatusDetailDialog from "./GWorkOrderStatusDetailDialog";

import * as gworkOrderDetailActions from "../../../store/gworkOrderDetail";

const defaultValues = {

};

const theme = createTheme();

const GWorkOrderStatusDialog = ({
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [fullScreen, setFullScreen] = useState(true);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputMode, setInputMode] = useState('basic');
  const [detailLengthByGProcess, setDetailLengthByGProcess] = useState(0);
  const [editingAmount, setEditingAmount] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedRowByGProcess, setSelectedRowByGProcess] = useState({});

  const [searchParams] = useSearchParams();
  

  const handleDialogClose = () => {
    setOpen(false);
    refresh && refresh();
  };
  
  const gworkOrderDetails = useSelector((state) => state.gworkOrderDetail.gworkOrderDetails);

  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllDetailsByGWorkOrderByGProcessByQuery = (workOrderNo, gprocessId) => dispatch(gworkOrderDetailActions.selectAllByGWorkOrderByGProcessByQuery(workOrderNo, gprocessId))

  useEffect(
    async () => {

    }, [dispatch]
  );

  useEffect(
    () => {
      console.log(selectedRow)
    }, [selectedRow]
  )

  const generateActions = (params) => {
    const detailAction = <GridActionsCellItem icon={<OpenInNew />} label={"상세"} onClick={() => handleSelect({ type: 'detail', params })} showInMenu />;

    let arrActions = [].concat([detailAction]);

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 120,
      hide: true,
    },
    // {
    //   field: 'gplanName',
    //   headerName: '일정관리번호',
    //   width: 340,
    //   valueGetter: (params) =>{
    //     if (params.value) {
    //       return params.value;
    //     }

    //     return "미할당";
    //   }
    // },
    {
      field: 'gprocessName',
      headerName: '공정명',
      width: 120,
    },
    // {
    //   field: 'schedule',
    //   headerName: '일정',
    //   width: 120,
    //   headerAlign: 'center',
    //   valueGetter: (params) =>{
    //     if (params.value) {
    //       return dateFormat(params.value, "yyyy-MM-dd")
    //     }

    //     return "-";
    //   }
    // },
    {
      field: 'sumAmount',
      headerName: '수량',
      width: 120,
      type: 'number',
    },
    {
      field: 'sumAmountCompleted',
      headerName: '생산',
      width: 120,
      type: 'number',
    },
    {
      field: 'sumAmountIncomplete',
      headerName: '미생산',
      width: 120,
      type: 'number',
    },
    {
      field: 'sumAmountDefect',
      headerName: '결품',
      width: 120,
      type: 'number',
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 120,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  useEffect(
    async () => {
      console.log(gworkOrderDetails);
      if (gworkOrderDetails.length > 0) {
        const gworkOrderProcessIds = gworkOrderDetails.map(item => item.gworkOrderProcessId);
        console.log(gworkOrderProcessIds)

        let gprocessLength = [...new Set(gworkOrderProcessIds)];
        console.log(gprocessLength);
        setDetailLengthByGProcess(gworkOrderDetails.length/gprocessLength.length);
      }
    }, [gworkOrderDetails]
  );

  // 아래는 목록에서 한 행 설정시 selectedRow값이 있는 상태로 호출되고, 등록하기 누르면 목록에서 init~하여 redux 통해서 null 설정되어 selectedRow값이 null인채로 호출됨
  useEffect(
    async () => {
      // 임시
      // setTimeout(async () => {
      //   await hideWatermark();
      //   // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      //   setShow(true);
      //   setTimeout(() => setLoaded(true), 300);
      // }, 300);
    }, [selectedRow]
  );

  useEffect(
    () => {
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setTimeout(async () => {
        await hideWatermark();
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      }, 300);
    }, [gworkOrderDetails]
  )

  const handleSelect = async ({ type, params }) => {
    console.log(params)
    
    // setOpenBackdrop(true); // TODO : 다이얼로그에서 또 띄우는 것은 의미없는 것 같음
    
    setSelectedRowByGProcess(params.row);

    // TODO : 데이터조회를 다이얼로그를 띄우기 전에 할 것인지 해당 다이얼로그에서 할 것인지 고민해볼것
    await selectAllDetailsByGWorkOrderByGProcessByQuery(selectedRow?.workOrderNo, params.row.gprocessId);
    
    setOpenDetail(true);

    // setOpenBackdrop(false); // setOpen 후에 호출하는게 화면 전환이 부드러움
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GWorkOrderStatusDetailDialog
          open={openDetail}
          setOpen={setOpenDetail}
          selectedRow={selectedRowByGProcess}
        />
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby={!fullScreen && "draggable-dialog-title"}
          maxWidth="lg"
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            fullScreen={fullScreen}
            color={fullScreen ? "white" : ""}
            style={{ cursor: !fullScreen && 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
          >
            {"생산현황 - 공정별"}
          </DialogTitleClose>
          <DialogContent dividers>
            <>
              <ul>
                {errors.map((error, idx) => <li key={idx}>{error}</li>)}
              </ul>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormInputText
                        control={control}
                        label={"작업의뢰번호"}
                        value={`${selectedRow?.workOrderNo}-${selectedRow?.workOrderNoSuffix}`}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        label={"발주처"}
                        value={selectedRow?.orderer?.name}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        label={"수주처"}
                        value={selectedRow?.receiver?.name}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        label={"현장명"}
                        value={selectedRow?.site}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormInputText
                        control={control}
                        label={"발주번호"}
                        value={selectedRow?.orderNo}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        label={"납기일"}
                        value={selectedRow && dateFormat(selectedRow.deliveryDate, 'yyyy-MM-dd')}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormInputText
                        control={control}
                        label={"사양"}
                        value={selectedRow?.specification}
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        // label={"수량"}
                        value={selectedRow?.sumAmount?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        InputProps={{
                          readOnly: true,
                          inputComponent: NumberFormatCustom, // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                          inputProps: {
                            style: { textAlign: "right", prefix: "수량 : ", suffix: " EA", backgroundColor: '#b2dfdb' }, // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        // label={"생산"}
                        value={selectedRow?.sumAmountCompleted?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // inputProps={ // TODO : inputProps or InputProps
                        //   { readOnly: true, }
                        // }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            style: { textAlign: "right", prefix: "생산 : ", suffix: " EA", backgroundColor: '#81d4fa' },
                            readOnly: true,
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        // label={"미생산"}
                        value={selectedRow?.sumAmountIncomplete?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            style: { textAlign: "right", prefix: "미생산 : ", suffix: " EA", backgroundColor: '#ffcdd2' },
                            readOnly: true,
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInputText
                        control={control}
                        // label={"결품"}
                        value={selectedRow?.sumAmountDefect?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            style: { textAlign: "right", prefix: "결품 : ", suffix: " EA", backgroundColor: '#ef9a9a' },
                            readOnly: true,
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    control={control}
                    label={"설명"}
                    value={selectedRow?.comments}
                    multiline
                    maxRows={5}
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 500, width: '100%' }}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                      // initialState={{ pinnedColumns: { left: [/*'status', */'id', 'workOrderNo'], right: ['gprocessName', 'deliveryDate', 'actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      loading={!loaded}
                      rows={gworkOrderDetails}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default GWorkOrderStatusDialog;
