import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_CONSTRUCTTION_WORK_TYPE = 'constructionWorkType/setConstructionWorkType';
const REMOVE_CONSTRUCTTION_WORK_TYPE = "constructionWorkType/removeConstructionWorkType";
const SET_CONSTRUCTTION_WORK_TYPES = 'constructionWorkType/setConstructionWorkTypes';

const setConstructionWorkType = (constructionWorkType) => ({
  type: SET_CONSTRUCTTION_WORK_TYPE,
  payload: constructionWorkType,
});

const removeConstructionWorkType = () => ({
  type: REMOVE_CONSTRUCTTION_WORK_TYPE,
});

const setConstructionWorkTypes = (data) => ({
  type: SET_CONSTRUCTTION_WORK_TYPES,
  payload: data,
});

export const create = ({ id, name, code, comments, color, url }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, comments, color, url }),
  });
  const data = await response.json();
  dispatch(setConstructionWorkType(data.constructionWorkType));
  return response;
};

export const modify = ({ id, name, code, comments, color, url }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, comments, color, url }),
  });
  const data = await response.json();
  dispatch(setConstructionWorkType(data.constructionWorkType));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes`);
  const data = await response.json();
  dispatch(setConstructionWorkTypes(data)); // data 임에 유의
  return response;
}

export const selectPaging = (queryPaging) => async dispatch => {
  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes/offset/${page}/limit/${pageSize}`);
  const data = await response.json();
  dispatch(setConstructionWorkTypes(data));
  return response;
}

export const selectAllByTypeDirect = async (type) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/constructionWorkTypes/type/${type}`);
  const data = await response.json();
  return data.constructionWorkTypes;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes/${id}`);
  const data = await response.json();
  dispatch(setConstructionWorkType(data.constructionWorkType));
  return response;
}

export const initConstructionWorkType = () => async dispatch => {
  dispatch(setConstructionWorkType(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/constructionWorkTypes/${id}`, {
    method: "DELETE",
  });
  dispatch(removeConstructionWorkType());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/constructionWorkTypes/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  constructionWorkType: null,
  constructionWorkTypes: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_CONSTRUCTTION_WORK_TYPE:
      newState = Object.assign({}, state, { constructionWorkType: action.payload });
      return newState;
    case REMOVE_CONSTRUCTTION_WORK_TYPE:
      newState = Object.assign({}, state, { constructionWorkType: null });
      return newState;
    case SET_CONSTRUCTTION_WORK_TYPES:
      newState = Object.assign({}, state, { constructionWorkTypes: action.payload.constructionWorkTypes, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;
