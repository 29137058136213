import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import {
  // Backdrop,
  Box,
  Button,
  // CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  PersonAdd,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";

import * as userActions from "../../store/user";
import * as dialogActions from "../../store/components/dialog";
import * as mailActions from "../../store/mail";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import * as alertDialogActions from "../../store/components/alertDialog";

import UserDialog from "./UserDialog";
import UserConfirmDialog from "./UserConfirmDialog";
import UserAlertDialog from "./UserAlertDialog";

const Users = () => {
  const [expanded, setExpanded] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);
  const users = useSelector((state) => state.user.users);

  const dispatch = useDispatch();

  const deleteUser = (id) => dispatch(userActions.remove(id))
  const modifyUserRole = ({ id, role }) => dispatch(userActions.modifyRole({ id, role }))
  const modifyUserStatus = ({ id, status }) => dispatch(userActions.modifyStatus({ id, status }))
  const selectAllByGClient = (gclientId) => dispatch(userActions.selectAllByGClient(gclientId))
  const sendSystemMailDrect = ({ to, type }) => mailActions.sendSystemMailDrect({ to, type })

  const columns = [
    {
      field: 'userId',
      headerName: '이메일 아이디',
      width: 250,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 120,
    },
    {
      field: 'mobile',
      headerName: '모바일',
      width: 150,
    },
    {
      field: 'role',
      headerName: '관리자',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let { role } = params.row;
        
        return (
          <Switch
            // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
            // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
            // TODO : 추후 useState([]) 사용하는 방법 강구
            // defaultChecked={doneYN}
            checked={role === 'ADMIN_ROLE' ? true : false}
            onChange={(e) => handleChangeToggle(e, params)}
            disabled={sessionUserRole === 'ADMIN_ROLE' ? false : true}
          />
        )
      },
    },
    {
      field: 'status',
      headerName: '상태',
      width: 120,
    },
    {
      field: 'functions',
      headerName: '기능',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params) => {
        const { status } = params.row;
        let buttons = [<Button size="small" onClick={(e) => handleClickRemoveUser(e, params)}>{"삭제"}</Button>];
        if (status === '승인요청') {
          buttons.push(<Button size="small" onClick={(e) => handleClickApproval(e, params)}>{"승인"}</Button>);
        } else if (status === "초기등록") {
          buttons.push(<Button size="small" onClick={(e) => handleClickSendMail(e, params)}>{"가입 메일 보내기"}</Button>);
        }
        return buttons;
      }
    },
  ];

  useEffect(
    async () => {
      await selectAllByGClient(sessionUser.id); // 현재 접속자가 회원사 관리자이거나 회원사 사용자이거나 상관없이 gclientId임
      setLoaded(true);
    }, [dispatch]
  )

  const handleChangeToggle = async (e, params) => {
    e.stopPropagation();

    const { checked } = e.target;
    const { id, gclientId } = params.row;
    
    const role = checked ? 'ADMIN_ROLE' : 'USER_ROLE';
    await modifyUserRole({ id, role });

    setLoaded(false);
    await selectAllByGClient(gclientId);
    setLoaded(true);
  }

  const handleClickRemoveUser = async (e, params) => {
    e.stopPropagation();

    const { id, userId, name } = params.row;
    
    const message = (<div>
      <span style={{ color: "#1976d2" }}>{`${name || ""}`}</span>{`님을 삭제하시겠습니까?`}<br /><br />
      <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
        {`이메일 아이디 : ${userId  || ""}`}
      </Box>
    </div>);

    dispatch(confirmDialogActions.setOptions({ open: true, title: "삭제", params, message, action: removeUser }));
  }

  const handleClickApproval = async (e, params) => {
    e.stopPropagation();

    const { id, userId, gclientId } = params.row;
    
    /*const { mailSettings } = sessionUser;
    if (!mailSettings) { // 승인 실행 전 메일 설정되어 있지 않으므로 메일 설정 없이 그냥 승인할지 묻도록 한다.
      const message = (<>
        <span><i><u>{"메일 설정이 되어 있지 않습니다."}</u></i></span><br/><br/>
        <span>{"메일 설정이 되어 있지 않으면"}</span>&nbsp;<span style={{ color: "#1976d2" }}>{"사용자 승인에는 문제가 없지만"}</span><br/>
        <span><i><u>{"사용자에게 메일이 발송되지 않습니다."}</u></i></span><br/><br/>
        <span>{"진행하시겠습니까?"}</span>
      </>);

      dispatch(confirmDialogActions.setOptions({ open: true, title: "승인안내", params: { id, userId, gclientId, mail: false }, message, action: handleApproval }));
    } else {*/
      approve({ id, userId, gclientId, mail: true });
    // }
  }

  // TODO : 가입 메일은 공사다큐 시스템 메일로 보내도록 할 것
  const handleClickSendMail = async (e, params) => {
    e.stopPropagation();
    
    // const { mailSettings } = sessionUser;
    // if (!mailSettings) {
    //   // alertDialogActions.setOptions({ alertInfo, setAlertInfo, setLoading })
    //   dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert: "메일설정이 완료되지 않았습니다. 먼저 메일설정을 해주세요." } }));

    //   return;
    // }

    const { userId, gclientId } = params.row;
      
    try {
      // setOpenBackdrop(true);
      setLoaded(false);

      await sendSystemMailDrect({ to: userId, type: "등록" });

      const messageAlert = (<><span style={{ color: "#1976d2" }}>{userId}</span>{"님께 메일을 발송하였습니다."}</>);
      dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));

      selectAllByGClient(gclientId);
      setLoaded(true);
      // setOpenBackdrop(false);
    } catch(e) {
      dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert: "가입 메일 보내기가 완료되지 않았습니다. 관리자에게 문의하세요." } }));
      setLoaded(true);
      // setOpenBackdrop(false);
    } 
  }

  const handleClickAddUser = () => dispatch(dialogActions.setOptions({ open: true, crudMode: 'C' }))

  const handleApproval = ({ id, userId, gclientId }) => approve({ id, userId, gclientId })

  const approve = async ({ id, userId, gclientId, mail }) => {
    try {
      // setOpenBackdrop(true);
      setLoaded(false);

      await modifyUserStatus({ id,  status: "등록완료" });

      let messageAlert;
      if (mail) {
        await sendSystemMailDrect({ to: userId, type: "승인" });

        messageAlert = (<>
          <span style={{ color: "#1976d2" }}>{userId}</span>{"님에 대한 승인이 완료되었습니다."}<br/>
          {"해당 내용을 "}<span style={{ color: "#1976d2" }}>{userId}</span>{"님께 메일을 발송하였습니다."}
        </>);
      } else {
        messageAlert = (<>
          <span style={{ color: "#1976d2" }}>{userId}</span>{"님에 대한 승인이 완료되었습니다."}
        </>);
      }
      
      dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));

      selectAllByGClient(gclientId);
      
      setLoaded(true);
      // setOpenBackdrop(false);
    } catch(e) {
      dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert: "사용자 승인에 문제가 발생하였습니다. 관리자에게 문의하세요." } }));
      setLoaded(true);
      // setOpenBackdrop(false);
    }
  }

  const removeUser = async (params) => {
    const { id, gclientId } = params.row;
    // setOpenBackdrop(true);
    setLoaded(false);

    await deleteUser(id);

    selectAllByGClient(gclientId);

    setLoaded(true);
    // setOpenBackdrop(false);
  }

  return (
    <>
      {
        sessionUserRole === 'ADMIN_ROLE' && (
          <>
            {/* <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
            {/* Confirm 다이얼로그 */}
            <UserConfirmDialog />
            {/* 사용자 추가 다이얼로그 */}
            <UserDialog refresh={selectAllByGClient} />
            {/* Alert 다이얼로그 */}
            <UserAlertDialog />
            {/* 담당자 */}
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="security-content" id="security-header">
                <Typography variant="h6" component="div">{"담당자"}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                    <Button variant="outlined" startIcon={<PersonAdd />} onClick={handleClickAddUser} sx={{ mr: 1 }}>{"추가"}</Button>
                    <Link
                      href={"/공사다큐 사용자 계정 추가 매뉴얼.pdf"}
                      underline="none"
                      target="_blank"
                      rel="noopener"
                    >
                      <Typography variant="subtitle2" display="block">
                        <Tooltip title="담당자를 추가하는 방법을 보시려면 링크를 클릭해주세요.">
                          <Button variant="text">{"추가 절차 안내"}</Button>
                        </Tooltip>
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      sx={{ cursor: 'pointer', fontSize: '0.85em', height: 300, width: '100%' }}
                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      loading={!loaded}
                      rows={users}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* 여기에 컴포넌트 들어가면 하단의 라인이 겹침 */}
          </>
        )
      }
    </>
  )
}

export default Users;