import React, { useState, useEffect/*, forwardRef, useImperativeHandle*/ } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, useGridApiContext, koKR } from '@mui/x-data-grid-pro';
// import { bgBG as pickersKoKR } from '@mui/x-date-pickers';
// import { bgBG as coreKoKR } from '@mui/material/locale';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  DesignServices,
  SlowMotionVideo,
  SwitchAccessShortcutAdd,
  Compress,
  Dehaze,
  Add,
  Remove,
}
from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import usePrevious from "../hook/usePrevious";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  objectEmptyCheck,
  hideWatermark,
  getTextColorByBackgroundColor,
} from "../../utils";
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gworkOrderDetailActions from "../../store/gworkOrderDetail";

import GWorkOrderProcessDialog from "./GWorkOrderProcessDialog";

const theme = createTheme(
  koKR, // x-data-grid translations
  // pickersKoKR, // x-date-pickers translations
  // coreBgKoKR, // core translations
);

const GWorkOrderDialogDetail = /*forwardRef(*/({
  crudMode,
  details,
  inputMode,
  // setClickId,
  // setClickAmount,
  // setNumberOnPanel,
  // setOpenNumberPanel,
  setEditingAmount,
  refreshParams,
}/*, ref*/) => {

  // // 부모 컴포넌트에서 사용할 함수를 선언
  // useImperativeHandle(ref, () => ({
  //   enableApplyButton,
  // }))

  console.log(details)

  const [errors, setErrors] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [amountDefects, setAmountDefects] = useState([]);
  const [loadingId, setLoadingId] = useState("");
  const [editingId, setEditingId] = useState("");
  const [numberOnPanel, setNumberOnPanel] = useState("0");
  const [openNumberPanel, setOpenNumberPanel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedAmountField, setSelectedAmountField] = useState("");
  const [pageSize, setPageSize] = useState(100);
  
  const [searchParams] = useSearchParams();

  const enableApplyButton = () => {
    // alert("a")
    setEditingId("111");
  }

  // TODO : 수량 계산시 경계값을 위주로 validation 필요
  const defectsColumns = () => {
    const defects = details[0]?.amountDefects.map((item, index) => {
      return {
        field: item.defect, // TODO : code가 필요하지 않을까.
        headerName: item.defect,
        width: 160,
        headerAlign: 'center',
        align: 'right',
        editable: inputMode === "basic" ? true : false,
        type: 'number',
        valueGetter: (params) => {
          // console.log(params)
          let amount = params.row[item.defect]; // 최초 params.row에 없는 경우 (DB에서 가져오지 않은 컬럼을 추가한 경우) 셀을 수정하면 params.row에 추가됨
          // if (amount === undefined) {
          //   // 수정하기 전 값이 없는 상태에서 DB 조회값으로 초기화
          //   amount = amountsPersistance.amountDefects.filter(i => i.defect === item.defect)[0].amount;
          // }
          if (amount === undefined) {
            // 수정하기 전 params.row."결합명"으로 값이 없는 상태에서 DB 조회값으로 초기화
            amount = params.row.amountDefects.find(i => i.defect === item.defect).amount;
          }

          return amount || 0;
        },
        preProcessEditCellProps: (params) => {
          const hasError = params.props.value < 0;
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params) => {
          if (inputMode === "basic" && crudMode === 'U') {
            // 편집 중 바로 '적용' 버튼 누르면 반영안됨. 따라서 편집중이면(포커스가 있으면) '적용' 버튼을 비활성화
            const { hasFocus, id } = params;
            if (hasFocus === false) {
              setEditingId("");
              // setEditingAmount(false); // TODO : 부모폼의 state 수정시 본 컴포넌트는 다시 render되면서 목록이 초기화됨. 결국 편집한 값이 되돌아감
            } else {
              setEditingId(id);
              // setEditingAmount(true);
            }
            return <GUpDown {...params} />;
          } else {
            return;
          }
        },
      }
    });

    return defects;
  }

  const basicColumns = [
    {
      field: 'currentSpecification',
      headerName: '사양',
      width: 300,
      headerAlign: 'center',
    },
    {
      field: 'currentOtherSpecs',
      headerName: '비고',
      width: 480,
      headerAlign: 'center',
    },
    {
      field: 'width',
      headerName: '가로',
      width: 160,
      headerAlign: 'center',
      type: 'number',
    },
    {
      field: 'height',
      headerName: '세로',
      width: 160,
      headerAlign: 'center',
      // align: 'right', // TODO : type이 number이면 좌측 기본 정렬임
      type: 'number', // TODO : type이 number이면 아래처럼 강제로 소수점을 넣을 필요가 없음. 다른 곳도 수정할 것
      // valueGetter: (params) => params.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'amount',
      headerName: '전체',
      width: 160,
      headerAlign: 'center',
      type: 'number',
      headerClassName: 'super-app-theme--header--total',
      cellClassName: 'super-app-theme--cell--total',
    },
    {
      field: 'amountCompleted',
      headerName: '생산',
      width: 160,
      headerAlign: 'center',
      align: 'right',
      editable: inputMode === "basic" ? true : false,
      type: 'number',
      headerClassName: 'super-app-theme--header--completed',
      cellClassName: 'super-app-theme--cell--completed',
      valueGetter: (params) => {
        const amount = params.row.amountCompleted;
        const newAmountCompleted = amount || 0;
        return newAmountCompleted;
      },
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
      renderEditCell: (params) => {
        if (inputMode === "basic" && crudMode === 'U') {
          // 편집 중 바로 '적용' 버튼 누르면 반영안됨. 따라서 편집중이면((포커스가 있으면)) '적용' 버튼을 비활성화
          const { hasFocus, id } = params;
          if (hasFocus === false) {
            setEditingId("");
            // setEditingAmount(false);
          } else {
            setEditingId(id);
            // setEditingAmount(true);
          }
          return <GUpDown {...params} />;
        } else {
          setEditingId("");
          return;
        }
      },
      // TODO : 셀 안에서 rendering 되므로 계산기 형태 UI 사용은...
      // renderEditCell: (params) => {
      //   return (
      //     <Grid container spacing={2}>
      //       <Grid item xs={12}>
      //         <Button>111</Button>
      //       </Grid>
      //       <Grid item xs={12}>
      //         <Button>111</Button>
      //       </Grid>
      //     </Grid>
          
      //   );
      // }
    },
    {
      field: 'amountIncomplete',
      headerName: '미생산',
      width: 160,
      headerAlign: 'center',
      align: 'right',
      headerClassName: 'super-app-theme--header--incomplete',
      cellClassName: 'super-app-theme--cell--incomplete',
      // editable: true,
      valueGetter: (params) => {
        const amountIncomplete = calAmountIncomplete(params);
        return amountIncomplete.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      field: 'amountDefect',
      headerName: '결품',
      width: 160,
      headerAlign: 'center',
      align: 'right',
      headerClassName: 'super-app-theme--header--defect',
      cellClassName: 'super-app-theme--cell--defect',
      // editable: true,
      // type: 'number',
      valueGetter: (params) => {
        const amountDefect = calAmountDefect(params);
        return amountDefect.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  const calAmountIncomplete = (params) => {
    let { amount, amountCompleted, amountIncomplete, amountDefects } = params.row;
    // amountDefect는 계산되어진 값으로 가져오면 undefined로 되어 있어 아래와 같이 직접 입력되는
    // 컬럼으로부터 가져온다.
    let sum = 0;
    amountDefects.forEach(item => {
      if(params.row[item.defect] === undefined) {
        // sum = sum + amountsPersistance.amountDefects.filter(i => i.defect === item.defect)[0].amount;
        sum = sum + item.amount;
      } else {
        sum = sum + (params.row[item.defect] || 0);
      }
    });

    // 주의 : 셀을 직접 편집한 것이 아니므로 params.row.amountIncomplete 값은 변하지 않음
    // 그래서 "적용" 버튼을 눌렸을 때 DB 저장전 다시 계산해주어야 한다.
    amountIncomplete = amount - (amountCompleted || 0) - (sum || 0);
    return amountIncomplete;
  }

  const calAmountDefect = (params) => {
    const { amountDefects } = params.row;
    let sum = 0;
    amountDefects.forEach(item => {
      if(params.row[item.defect] === undefined) {
        // sum = sum + amountsPersistance.amountDefects.filter(i => i.defect === item.defect)[0].amount;
        sum = sum + item.amount;
      } else {
        sum = sum + (params.row[item.defect] || 0);
      }
    });

    // 주의 : 셀을 직접 편집한 것이 아니므로 params.row.amountDefect 값은 변하지 않음
    // 그래서 "적용" 버튼을 눌렸을 때 DB 저장전 다시 계산해주어야 한다.
    return sum;
  }

  const functions = {
    field: 'action',
    headerName: '기능',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    // renderCell: (params) => <Button variant="contained" size="large" onClick={() => handleApplyClick(params)}>{"적용"}</Button>
    renderCell: (params) => (
      crudMode === 'U' && <LoadingButton
        variant="contained"
        size="large"
        sx={{ fontSize: "1.4em" }}
        onClick={() => handleApplyClick(params)}
        loading={params.id === loadingId}
        disabled={params.id === editingId}
        // loadingIndicator={"적용중"}
        loadingPosition="center"
      >
        {"적용"}
      </LoadingButton>
    )
  };

  const columns = basicColumns.concat(defectsColumns());
  columns.push(functions);

  const GUpDown = (props) => {

    let { id, value, field } = props;
    
    // setAmount(value);

    const apiRef = useGridApiContext()
  
    const handleValueChange = (event) => {
      const newValue = event.target.value; // The new value entered by the user
      console.log(apiRef)
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    // return <input type="text" value={value} onChange={handleValueChange} />;
    return (
      <Grid container spacing={1} display="flex" alignItems="center">
        <Grid item xs="3">
          <IconButton aria-label="plus" color="primary" onClick={() => { apiRef.current.setEditCellValue({ id, field, value: ++value }); }}>
            <Add />
          </IconButton>
        </Grid>
        <Grid item xs="6">
          {/* <input type="text" value={value} onChange={handleValueChange} /> */}
          <TextField
            inputProps={{ style: { textAlign: 'right', fontSize: '1.2em', fontWeight: 600 } }}
            value={value}
            onChange={handleValueChange}
          />
        </Grid>
        <Grid item xs="3">
          <IconButton aria-label="minus" color="secondary" onClick={() => { apiRef.current.setEditCellValue({ id, field, value: --value }); }}>
            <Remove />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  // 데이터 관리
  const dispatch = useDispatch();
  
  // TODO : gworkOrderDetailActions으로 수정할 것
  const setAmountsDirect = ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => gworkOrderDetailActions.setAmountsDirect({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects })
  const selectAllDetailsByIdTypeByQuery = (idType, id) => dispatch(gworkOrderDetailActions.selectAllDetailsByIdTypeByQuery(idType, id))

  // useEffect(
  //   async () => {
  //   }, [dispatch]
  // );

  // useEffect(
  //   () => {
  //     // console.log(`modify: ${modify}, opne: ${open}`);
  //     if (open) { // 다이얼로그가 열릴 때
  //       if (!modify) { // 등록인 경우 기존 데이터 초기화
  //         setDialogInfo();
  //       }
  //     }
  //   }, [open]
  // )

  // 아래는 목록에서 한 행 설정시 selectedRow값이 있는 상태로 호출되고, 등록하기 누르면 목록에서 init~하여 redux 통해서 null 설정되어 selectedRow값이 null인채로 호출됨
  useEffect(
    async () => {
      setRows(details);

      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setTimeout(async () => {
        await hideWatermark();
        setShow(true);
        setTimeout(() => setLoaded(true), 300);
      }, 300);
    }, [details]
  );

  // TODO : 왜 processRowUpdate은 안되는지??? 그래서 useState를 사용하여 persistance를 처리. 추후 재검토 필요
  const processRowUpdate = (newRow) => {
    
  };

  const handleApplyClick = async (params) => {
    const newAmountCompleted = params.row.amountCompleted;
    const newAmountIncomplete = calAmountIncomplete(params);
    const newAmountDefect = calAmountDefect(params);

    const newAmountDefects = params.row.amountDefects.map(item => {
      if (params.row[item.defect] !== undefined) {
        return {
          ...item,
          amount: params.row[item.defect],
        }
      } else {
        return item;
      }
    });
    
    console.log(params, newAmountCompleted, newAmountIncomplete, newAmountDefect, newAmountDefects);
    
    // const newRows = [].concat(rows);
    // newRows[0].amountCompleted = 100;
    // setRows(newRows);
    // return;

    setLoadingId(params.id);

    await setAmountsDirect({
      id: params.id,
      amountCompleted: newAmountCompleted,
      amountIncomplete: newAmountIncomplete,
      amountDefect: newAmountDefect,
      amountDefects: newAmountDefects,
    });

    // 목록 재검색
    const { refreshType, refreshId } = refreshParams;
    await selectAllDetailsByIdTypeByQuery(refreshType, refreshId);

    setTimeout(() => setLoadingId(""), 1000);
  }

  const handleCellEditCommit = (newCell) => {
    const { id, field, value } = newCell;
    console.log(newCell)
    setAmountDefects(
      amountDefects.map(i => {
        if (i.defect === field) {
          return {
            defect: field,
            amount: value,
          }
        } else {
          return i;
        }
      })
    );
  }

  const inputNumberOnPanel = (num) => {
    if (numberOnPanel === "0") {
      setNumberOnPanel(num);
    } else {
      setNumberOnPanel(numberOnPanel + num);
    }
  }

  const handleInput = () => {
    const newRows = rows.map(row => {
      if (row.id === selectedId) { // 현재 선택한 행의 데이터를 수정
        if (selectedAmountField === "amountCompleted") { // 생산수량 수정
          return {
            ...row,
            amountCompleted:  Number(numberOnPanel),
          }
        }
        
        // 기타 결함수량 수정
        const { amountDefects } = row;
        const newAmountDefects = amountDefects.map(item => item.defect === selectedAmountField ? { ...item, amount: Number(numberOnPanel) } : item);
  
        return {
          ...row,
          amountDefects: newAmountDefects,
        };
      }

      return row;
    });
    
    setRows(newRows);
    
    handleNumberPanelClose();
  }

  useEffect(
    () => {
      openNumberPanel && setNumberOnPanel(numberOnPanel || "0");
    }, [openNumberPanel]
  )

  const handleNumberPanelClose = () => {
    setOpenNumberPanel(false);
  }

  return (     
    <>
      <Grid item xs={6}>
        {/* TODO : 추후 공정 컬러 도입할 것. 단 공정 컬러는 작업의뢰서 발행시 컬러로 추후 바뀌면 색상에 대한 혼동도 있을 수 있음을 고려 */}
        <Button
          variant="contained"
          size="large"
          sx={{
            fontSize: "2em",
            bgcolor: `${details[0]?.gprocessColor}`,
            color: `${getTextColorByBackgroundColor(details[0]?.gprocessColor)}`,
            mb: 2,
          }}
        >
          {details[0]?.gprocessName}
        </Button>
      </Grid>
      <Box sx={{
        height: 400,
        width: '100%',
        '& .super-app-theme--header--total': {
          backgroundColor: '#b2dfdb',
          color: '#00695c',
          fontWeight: '600',
        },
        '& .super-app-theme--cell--total': {
          backgroundColor: '#b2dfdb',
          color: '#00695c',
          fontWeight: '600',
        },
        '& .super-app-theme--header--completed': {
          backgroundColor: '#81d4fa',
          color: '#0277bd',
          fontWeight: '600',
        },
        '& .super-app-theme--cell--completed': {
          backgroundColor: '#81d4fa',
          color: '#0277bd',
          fontWeight: '600',
        },
        '& .super-app-theme--header--incomplete': {
          // backgroundColor: 'rgba(255, 7, 0, 0.55)',
          backgroundColor: '#ffcdd2',
          color: '#e53935',
          fontWeight: '600',
        },
        '& .super-app-theme--cell--incomplete': {
          // backgroundColor: 'rgba(255, 7, 0, 0.55)',
          backgroundColor: '#ffcdd2',
          color: '#e53935',
          fontWeight: '600',
        },
        '& .super-app-theme--header--defect': {
          // backgroundColor: 'rgba(255, 7, 0, 0.55)',
          backgroundColor: '#ef9a9a',
          color: '#ff0000',
          fontWeight: '600',
        },
        '& .super-app-theme--cell--defect': {
          // backgroundColor: 'rgba(255, 7, 0, 0.55)',
          backgroundColor: '#ef9a9a',
          color: '#ff0000',
          fontWeight: '600',
        },
      }}>
        <ThemeProvider theme={theme}>
          <DataGridPro
            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
            columnHeaderHeight={100}
            rowHeight={100}
            sx={{
              visibility: show ? 'visible' : 'hidden',
              cursor: 'pointer',
              fontSize: '1.2em',
              boxShadow: 2,
              fontSize: '0.85em',
              // border: 2,
              // borderColor: 'primary.light',
              // '& .MuiDataGrid-cell:hover': {
              //   color: 'primary.main',
              // },
            }}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              loadingOverlay: LinearProgress,
            }}
            loading={!loaded}
            rows={rows}
            columns={columns}
            // processRowUpdate={processRowUpdate}
            onCellEditCommit={handleCellEditCommit}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // pagination
            onRowClick={(params) => {
              console.log(params);
              // setSelectedRowsFind(params);
            }}
            // onRowSelectionModelChange={(ids) => {
            //   const selectedIDs = new Set(ids);
            //   console.log(selectedIDs);
            //   const selectedRows = rows.filter((row) =>
            //     selectedIDs.has(row.id),
            //   );
    
            //   setSelectedRows(selectedRows);
            // }}
            // onCellClick={(params, event) => {
            onCellDoubleClick={(params, event) => {
              // event.defaultMuiPrevented = true;
              const { field, value, id } = params;
              if (inputMode === "numberPanel" && !["no", "standard", "specification", "amount", "amountIncomplete", "amountDefect", "action"].includes(field)) {
                setSelectedId(id);
                setSelectedAmountField(field);
                setNumberOnPanel(value);
                setOpenNumberPanel(true);

                event.stopPropagation();
              }
            }}
            // experimentalFeatures={{ newEditingApi: true }}
          />
        </ThemeProvider>
      </Box>
      <Dialog
        maxWidth="xs"
        open={openNumberPanel}
        onClose={handleNumberPanelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleClose
          id="numberPanel"
          onClose={handleNumberPanelClose}
          style={{ backgroundColor: "#1976d2", color: "white" }}
        >
          {"입력"}
        </DialogTitleClose>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <TextField
                inputProps={{ style: { textAlign: 'right', fontSize: 50 } }}
                value={numberOnPanel}
                sx={{ width: 374 }}></TextField>
            </Grid>
            <Grid item xs={12}>
              {
                <Grid container spacing={2}>
                  {
                    [1,2,3].map((item, idx) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {
                              [7, 8, 9].map(num => {
                                const number = (num - idx*3).toString();
                                return (
                                  <Grid item xs={4} display="flex" justifyContent="center">
                                    <Button
                                      variant="contained"
                                      sx={{ width: 100, height: 100, fontSize: 50, bgcolor: "#42a5f5" }}
                                      onClick={() => inputNumberOnPanel(number)}
                                    >
                                        {number}
                                    </Button>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    sx={{ width: 100, height: 100, fontSize: 50, bgcolor: "#42a5f5" }}
                    onClick={() => inputNumberOnPanel("0")}
                  >
                      {"0"}
                  </Button>
                </Grid>
                <Grid item xs={8}  display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    sx={{ width: 240, height: 100, fontSize: 50 }}
                    onClick={() => setNumberOnPanel("0")}
                  >
                      {"초기화"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInput}>{"입력"}</Button>
          <Button onClick={handleNumberPanelClose} autoFocus>
            {"닫기"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}/*)*/;

export default GWorkOrderDialogDetail;
