import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const INIT_G04_DOCU_GTEST = 'g04docu/initG04docuGTest';
const INIT_G04_DOCU_GTESTS = 'g04docu/initG04docuGTests';
const SET_G04_DOCU_GTEST = 'g04docu/setG04docuGTest';
const REMOVE_G04_DOCU_GTEST = "g04docu/removeG04docuGTest";
const SET_G04_DOCU_GTESTS = 'g04docu/setG04docuGTests';

const initG04docuGTest = () => ({
  type: INIT_G04_DOCU_GTEST,
});

const initG04docuGTests = () => ({
  type: INIT_G04_DOCU_GTESTS,
});

const setG04docuGTest = (g04docu) => ({
  type: SET_G04_DOCU_GTEST,
  payload: g04docu,
});

const removeG04docuGTest = () => ({
  type: REMOVE_G04_DOCU_GTEST,
});

const setG04docuGTests = (g04docuGTests) => ({
  type: SET_G04_DOCU_GTESTS,
  payload: g04docuGTests,
});

export const create = ({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests`, {
    method: "POST",
    body: JSON.stringify({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }),
  });
  const data = await response.json();
  dispatch(setG04docuGTest(data.g04docu));
  return response;
};

export const modify = ({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests`, {
    method: "PUT",
    body: JSON.stringify({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }),
  });
  const data = await response.json();
  dispatch(setG04docuGTest(data.g04docu));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests`);
  const data = await response.json();
  dispatch(setG04docuGTests(data.g04docuGTests));
  return response;
}

export const selectAllByQuery = ({ gclientId, division, itemOrCertificationName, name, classifications }) => async dispatch => {
  console.log({ gclientId, division, name, classifications });
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests/query/selectAll`, {
    method: "POST",
    body: JSON.stringify({ gclientId, division, itemOrCertificationName, name, classifications }),
  });

  const data = await response.json();
  dispatch(setG04docuGTests(data.g04docuGTests));
  return response;
}

export const selectByGClientAndDivisionAndGComponentItemByQueryByDirect = async (gclientId, division, gcomponentItemId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTests/query/selectAll/gclient/${gclientId}/division/${division}/gcomponentItem/${gcomponentItemId}`);
  const data = await response.json();
  // TODO : return값 response와 data 비교해 볼 것
  // return response;
  return data.g04docuGTests;
}

export const countByGClientAndDivisionAndGComponentItemByQueryByDirect = async (gclientId, division, gcomponentItemId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTests/query/count/gclient/${gclientId}/division/${division}/gcomponentItem/${gcomponentItemId}`);
  const data = await response.json();
  return data.count;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests/${id}`);
  const data = await response.json();
  dispatch(setG04docuGTest(data.g04docu));
  return response;
}

export const initializeG04docuGTests = () => async dispatch => {
  dispatch(initG04docuGTests());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docuGTest());
  return response;
};

export const removeWithFile = ({ id, gclientId, g04docuGTestId, documentPath }) => async (dispatch) => {
  console.log(id, gclientId, g04docuGTestId, documentPath);
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTests/${id}/withFile`, {
    method: "DELETE",
    body: JSON.stringify({ gclientId, g04docuGTestId, documentPath }),
  });
  dispatch(removeG04docuGTest());
  return response;
};

export const initializeG04docuGTest = () => async (dispatch) => {
  dispatch(initG04docuGTest());
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGTests/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  g04docu: null, // TODO : g04docuGTest로 수정 필요
  g04docuGTests: [],
};

// TODO : g04docu 수정해야 함
function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU_GTEST:
      newState = Object.assign({}, state, { g04docu: null });
      return newState;
    case INIT_G04_DOCU_GTESTS:
      newState = Object.assign({}, state, { g04docuGTests: initialState.g04docuGTests });
      return newState;
    case SET_G04_DOCU_GTEST:
      newState = Object.assign({}, state, { g04docu: action.payload });
      return newState;
    case REMOVE_G04_DOCU_GTEST:
      newState = Object.assign({}, state, { g04docu: null });
      return newState;
    case SET_G04_DOCU_GTESTS:
      newState = Object.assign({}, state, { g04docuGTests: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
