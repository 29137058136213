const uploadLabels = {
  labelIdle: '여기에 파일을 놓거나 <span class="filepond--label-action">파일찾기</span>를 누르세요.', // 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>', //Default label shown to indicate this is a drop area. FilePond will automatically bind browse file events to the element with CSS class .filepond--label-action
  labelInvalidField: '잘못된 파일입니다.', // 'Field contains invalid files', // Label shown when the field contains invalid files and is validated by the parent form.
  labelFileWaitingForSize: '파일 사이즈 정보 대기 중', // 'Waiting for size', // Label used while waiting for file size information
  labelFileSizeNotAvailable: '파일 사이즈 오류 발생', // 'Size not available', // Label used when no file size information was received
  labelFileLoading: '로딩 중', // 'Loading', // Label used while loading a file
  labelFileLoadError: '로딩 중 오류 발생', // 'Error during load', // Label used when file load failed
  labelFileProcessing: '업로드 중', // 'Uploading', // Label used when uploading a file
  // labelFileProcessingComplete: '업로드가 완료되었습니다.', // 'Upload complete', // Label used when file upload has completed
  labelFileProcessingComplete: '첨부', // 'Upload complete', // Label used when file upload has completed
  labelFileProcessingAborted: '업로드가 취소되었습니다.', // 'Upload cancelled', // Label used when upload was cancelled
  labelFileProcessingError: '업로드 중 오류 발생', // 'Error during upload', // Label used when something went wrong during file upload
  labelFileProcessingRevertError: '되돌리기 중 오류 발생', // 'Error during revert', // Label used when something went wrong during reverting the file upload
  labelFileRemoveError: '삭제 중 오류 발생', // 'Error during remove', // Label used to indicate something went wrong when removing the file
  labelTapToCancel: '취소하려면 클릭하세요.', // 'tap to cancel', // Label used to indicate to the user that an action can be cancelled.
  labelTapToRetry: '재시도하려면 클릭하세요.', // 'tap to retry', // Label used to indicate to the user that an action can be retried.
  labelTapToUndo: '서버에서 삭제하려면 클릭하세요.', // 'tap to undo', // Label used to indicate to the user that an action can be undone.
  labelButtonRemoveItem: '삭제', // 'Remove', // Label used for remove button
  labelButtonAbortItemLoad: '취소', // 'Abort', // Label used for abort load button
  labelButtonRetryItemLoad: '재시도', // 'Retry', // Label used for retry load button
  labelButtonAbortItemProcessing: '취소', // 'Cancel', // Label used for abort upload button
  labelButtonUndoItemProcessing: '되돌리기', // 'Undo', // Label used for undo upload button
  labelButtonRetryItemProcessing: '재시도', // 'Retry', // Label used for retry upload button
  labelButtonProcessItem: '업로드', // 'Upload', //
}

export default uploadLabels;