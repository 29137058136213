import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import GComponentItemDialog from "./GComponentItemDialog";
import { GClientManagement } from "../GClient";

const theme = createTheme();

const GComponentItemManagement = () => {
  const [crudMode, setCrudMode] = useState('');
  // const [paste, setPaste] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selManufacturers, setSelManufacturers] = useState([]);
  
  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      // await select(id);
      await selectByQuery(id)
      setCrudMode('R')
      // setPaste(false);
      setOpen(true);
    } else if (type === "edit") {
      // TODO : await 재검토 필요. 대부분 await 가 필요
      // await select(id);
      await selectByQuery(id)
      setCrudMode('U')
      // setPaste(false);
      setOpen(true);
    } else if (type === "paste") {
      // await select(id);
      await selectByQuery(id)
      setCrudMode('C')
      // setPaste(true);
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAllByQuery();

    setOpenBackdrop(false);
    
    setTimeout(() => setLoaded(true), 300)
  }

  const remove = (removeId) => {
    dispatch(gcomponentItemActions.remove(removeId))
    .then(res => selectAllByQuery())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  const handleChangeToggle = async ({ applyType, params, e }) => {
    e.stopPropagation();

    await modifyGComponentItemApplyType({ id: params.id, applyType })

    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);
  }

  const handleChangeToggleValid = async ({ params, e }) => {
    e.stopPropagation();
    
    setOpenBackdrop(true);

    await modifyGComponentItemValid({ id: params.id, valid: e.target.checked });

    setLoaded(false);
    
    await selectAllByQuery();
    
    setOpenBackdrop(false);

    setTimeout(() => setLoaded(true), 300);
  }

  const handleChangeToggleItemType = async ({ itemType, params, e }) => {
    e.stopPropagation();

    await modifyGComponentItemItemType({ id: params.id, itemType })

    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);
  }

  const handleChangeToggleMadeBy = async ({ madeBy, params, e }) => {
    e.stopPropagation();

    await modifyGComponentItemMadeBy({ id: params.id, madeBy })

    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    // initGComponentItem();
    setCrudMode('C');
    // setPaste(false);
    setOpen(true);
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleViewManufacturers = (manufacturers) => {
    setSelManufacturers(manufacturers);
    console.log(manufacturers);
    setDrawerOpen(true);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 300,
    //   hide: true,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    {
      // field: 'gcomponentName',
      field: 'gName',
      headerName: '분류',
      width: 150,
      // editable: true,
    },
    {
      field: 'type',
      headerName: '유리사양적용',
      width: 180,
      // hide: true,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let applyType = params.row.applyType;
        if (params.row.applyType === "") {
          applyType = 'none';
        }
        return (
          /**
           * 2023-05-10 : GComponentItems의 type을 GComponents로 옮길 계획이었으나 아래의 이유로 철회
           * 같은 속성(GComponents에서의 type이 property. 하위에 GComponentItems를 가지고 있음)이더라도 적용범위(이름, 비고 등)가 다를 수 있음
           * 예)
           * "표면가공" 속성에는 Sand Blast, Frit, Etching, Back Paint, Print, 모따기, Hole Ø : 6 mm 등이 있을 수 있는데
           * Sand Blast ~ Print 까지는 유리사양 중 "이름", 모따기, Hole Ø : 6 mm 등은 "비고"로 같은 속성이더라도 다를 수 있음
           */
          <ToggleButtonGroup
            color="primary"
            value={applyType}
            exclusive
            size={"small"}
          >
            <ToggleButton value="productName" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ applyType: 'productName', params, e })}>{"이름"}</ToggleButton>
            <ToggleButton value="etc" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ applyType: 'etc', params, e })}>{"비고"}</ToggleButton>
            <ToggleButton value="none" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ applyType: 'none', params, e })}>{"안함"}</ToggleButton>
          </ToggleButtonGroup>
        )
      },
    },
    {
      field: 'dependentGcomponentItem',
      headerName: '의존성',
      width: 200,
      // editable: true,
      valueGetter: (params) => {
        if (params.value) {
          return JSON.parse(params.value).name;
        }

        return "";
      },
    },
    {
      field: 'g04docu',
      // headerName: '규격',
      renderHeader: () => (<Stack direction="row" spacing={1} alignItems="center">
        <Chip label={"표준번호"} size="small" sx={{ mr: 1 }}></Chip><span>{"인증규격명"}</span>
      </Stack>),
      width: 300,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuGCertificationName && params.row.gstandardNo) {
          return <><Chip label={params.row.gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{params.row.g04docuGCertificationName}</>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'valid',
      headerName: 'ON/OFF',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => {
        let valid = params.row.valid;
        return (
          <Switch
            // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
            // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
            // TODO : 추후 useState([]) 사용하는 방법 강구
            // defaultChecked={doneYN}
            checked={valid}
            onChange={(e) => handleChangeToggleValid({ params, e })}
          />
        )
      },
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];
  
  // const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchItemType = queryStrings["searchItemType"];

  const itemTypeCol = {
    field: 'itemType',
    headerName: '자재구분',
    width: 250,
    // hide: true,
    // editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      let itemType = params.row.itemType;
      // if (params.row.itemType === "") {
      //   itemType = 'NONE';
      // }
      return (
        <ToggleButtonGroup
          color="primary"
          value={itemType}
          exclusive
          size={"small"}
        >
          <ToggleButton value="RAW_MATERIAL" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleItemType({ itemType: 'RAW_MATERIAL', params, e })}>
            <Tooltip title={"원자재"} followCursor>
              <Box>
                {"원재"}
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="SUB_MATERIAL_PROCESS" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleItemType({ itemType: 'SUB_MATERIAL_PROCESS', params, e })}>
            <Tooltip title={"가공부자재"} followCursor>
              <Box>
                {"가부"}
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="SUB_MATERIAL_BUILD" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleItemType({ itemType: 'SUB_MATERIAL_BUILD', params, e })}>
            <Tooltip title={"시공부자재"} followCursor>
              <Box>
                {"시부"}
              </Box>
            </Tooltip>
          </ToggleButton>
          {/* <ToggleButton value="NONE" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleItemType({ itemType: 'NONE', params, e })}>{"자재아님"}</ToggleButton> */}
        </ToggleButtonGroup>
      )
    },
  };

  const madeByCol = {
    field: 'madeBy',
    headerName: '생산지구분',
    width: 240,
    // hide: true,
    // editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      let { madeBy, itemType } = params.row;
      if (itemType === 'RAW_MATERIAL') {
        return (

          <ToggleButtonGroup
            color="primary"
            value={madeBy ? madeBy : "국내산"}
            exclusive
            size={"small"}
          >
            <ToggleButton value="국내산" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 40, height: 20 }} onClick={(e) => handleChangeToggleMadeBy({ madeBy: '국내산', params, e })}>
              <Tooltip title={"국내산"} followCursor>
                <Box>
                  {"국"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="국내산(로이)" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 70, height: 20 }} onClick={(e) => handleChangeToggleMadeBy({ madeBy: '국내산(로이)', params, e })}>
              <Tooltip title={"국내산(로이)"} followCursor>
                <Box>
                  {"국(low-e)"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="수입산" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 40, height: 20 }} onClick={(e) => handleChangeToggleMadeBy({ madeBy: '수입산', params, e })}>
              <Tooltip title={"수입산"} followCursor>
                <Box>
                  {"수"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="수입산(로이)" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 70, height: 20 }} onClick={(e) => handleChangeToggleMadeBy({ madeBy: '수입산(로이)', params, e })}>
              <Tooltip title={"수입산(로이)"} followCursor>
                <Box>
                  {"수(low-e)"}
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )
      } else {
        return <></>
      }
      
    },
  };

  const manufacturerCol = {
    field: 'gclients',
    headerName: '제조사',
    width: 150,
    // editable: true,
    // valueGetter: (params) => params.value?.name,
    renderCell: (params) => {
      if (params.row.name === "Clear") {
        console.log(params)
      }
      
      if (params.value && Array.isArray(params.value) && params.value.length > 0) {
        console.log(params.value)
        return params.value.length === 1
          ?
            // (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers([params.value[0].gclient])}>{params.value[0].gclient.name}</Button>)
            (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers([params.value[0]])}>{params.value[0].name}</Button>)
          :
            // (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers(params.value.map(val => val.gclient))}>{"2+"}</Button>)
            (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers(params.value)}>{"2+"}</Button>)
      };
    }
  };

  searchItemType === 'materials' && columns.splice(5, 0, itemTypeCol) && columns.splice(6, 0, madeByCol) && columns.splice(7, 0, manufacturerCol);

  const rows = useSelector((state) => state.gcomponentItem.gcomponentItems);
  const selectedRow = useSelector((state) => state.gcomponentItem.gcomponentItem);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  // const selectAllByQuery = () => dispatch(gcomponentItemActions.selectAllByQuery())
  const selectAllByItemTypesByQuery = (itemTypes) => dispatch(gcomponentItemActions.selectAllByItemTypesByQuery(itemTypes))
  // const select = (id) => dispatch(gcomponentItemActions.select(id))
  const selectByQuery = (id) => dispatch(gcomponentItemActions.selectByQuery(id))
  // const initGComponentItem = () => dispatch(gcomponentItemActions.initGComponentItem())
  const modifyGComponentItemApplyType = ({ id, applyType }) => dispatch(gcomponentItemActions.modifyApplyType({ id, applyType }))
  const modifyGComponentItemItemType = ({ id, itemType }) => dispatch(gcomponentItemActions.modifyItemType({ id, itemType }))
  const modifyGComponentItemMadeBy = ({ id, madeBy }) => dispatch(gcomponentItemActions.modifyMadeBy({ id, madeBy }))
  const modifyGComponentItemValid = ({ id, valid }) => dispatch(gcomponentItemActions.modifyGComponentItemValid({ id, valid }))
  const reorder = ({ type, id }) => gcomponentItemActions.reorder({ type, id })

  // 메뉴에서 라우팅시 같은 컴포넌트를 사용하면 queryString이 달라도 useEffect([dispatch])는 한번밖에 잡히지 않으므로 useEffect([searchParams])로 이동
  // useEffect(
  //   async () => {
  //     // console.log(location)
  //     // console.log(searchParams)
  //     const queryStrings = Object.fromEntries([...searchParams]);
  //     console.log(queryStrings["type"])

  //     await selectAllByQuery();

  //     await hideWatermark();
  //     // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
  //     setShow(true);
  //     setTimeout(() => setLoaded(true), 300);
  //   }, [dispatch]
  // );

  const selectAllByQuery = async () => {
    let itemTypes;
    if (searchItemType === 'materials') {
      itemTypes = [].concat(['RAW_MATERIAL', 'SUB_MATERIAL_PROCESS', 'SUB_MATERIAL_BUILD']);
    } else if (searchItemType === 'preferences') {
      itemTypes = [].concat(['NONE']);
    }
    
    await selectAllByItemTypesByQuery(itemTypes);
  }

  useEffect(
    async () => {
      // setShow(false); // 다른 화면과 달리 원부자재와 속성을 같이 쓰므로 이 코드가 없으면 서로 메뉴를 번갈아갈때 Skeleton이 나타나지 않음
      await selectAllByQuery();

      // await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      // setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [searchParams]
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GComponentItemDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          // paste={paste}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAllByQuery}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            {/* TODO : Skeleton 처리를 하는게 보기 좋은지 아닌지 의견 수렴 필요 */}
            {/* <Skeleton variant="rounded" height={show ? 0 : 900} /> */}
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GClientManagement
          title={`거래선 정보`}
          from={
            {
              source: "GComponentItemManagement",
              params: {
                selManufacturers,
              }
            }
          }
          // statusWorkOrder={statusWorkOrder}
          // originRefresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default GComponentItemManagement;
