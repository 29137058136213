import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  TextField,
} from '@mui/material';
import {
  Add,
  Delete,
} from "@mui/icons-material";
import { SketchPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDropdown,
  FormInputMultipleSelect,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gcomponentActions from "../../store/gcomponent";
import * as gprocessActions from "../../store/gprocess";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  steps: [],
  color: "",
  comments: "",
  defects: [],
};

const GProcessDialog = ({
  modify,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [steps, setSteps] = useState([]);
  const [hexColor, setHexColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [init, setInit] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);

    reset(defaultValues);
    initWithInitialValue({});

    setInit(true);
    setHexColor('');
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, watch, register } = useForm({ defaultValues: defaultValues });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "defects"
  });
  
  const watchFieldArray = watch("defects");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  // 데이터 관리
  const dispatch = useDispatch();

  const gcomponentsByProperty = useSelector((state) => state.gcomponent.gcomponents);

  const selectAllGComponentType = (type) => dispatch(gcomponentActions.selectByType(type));
  const addGProcess = ({ id, name, code, steps, color, comments, defects }) => dispatch(gprocessActions.create({ id, name, code, steps, color, comments, defects }))
  const modifyGProcess = ({ id, name, code, steps, color, comments, defects }) => dispatch(gprocessActions.modify({ id, name, code, steps, color, comments, defects }))
  const initWithInitialValue = (initialValue) => dispatch(gprocessActions.initGProcessWithInitialValue(initialValue));

  const onSubmit = ({ id, name, code, steps, color, comments, defects }) => {
    setErrors([]);
    
    console.log({ id, name, code, steps, color, comments, defects });
    // return;
    let func;
    if (modify) {
      func = modifyGProcess;
    } else {
      func = addGProcess
    }
    func({ id, name, code, steps, color: hexColor, comments, defects })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      await selectAllGComponentType('property'); // await를 하지 않으면 select 클릭시 아이템이 구성되지 않았다가 뒤늦게 추가되므로 await 사용
    }, [dispatch]
  );

  useEffect(
    () => {
      // console.log(selectedRow);
      ["id", "name", "code", "steps", "color", "comments", "defects"].forEach(item => {
        if (item === "id") {
          setValue(item, selectedRow && selectedRow[item] || uuidv4());
        } else if (item === "steps") {
          if (selectedRow && selectedRow[item]) {
            const arr = selectedRow[item].map(row => row.code); // TODO : id or code 추후 검토
            const selected = gcomponentsByProperty.filter(gcomponent => arr.includes(gcomponent.code));
            // console.log(selected);
            setInit(false);
            setSteps(selected);
            
            // 참고로 setValue는 ForminputMultipleSelect안에서 value를 기준으로 자동으로 하므로 호출하지 않아도 됨
          } else {
            setSteps([]); // 수정한 후 다른 row 선택 후 수정 다이얼로그 띄우면 이전 선택 타입 남아 있는 문제 해결
          }
        } else if (item === "defects") {
          setValue(item, selectedRow && selectedRow[item] || []);
        } else {
          setValue(item, selectedRow && selectedRow[item] || "");
        }
      })

      setHexColor(selectedRow?.color || "");
      setShowColorPicker(false);
    }, [selectedRow]
  );

  const popover = {
    // position: "absolute",
    zIndex: "2"
  };
  
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    '&:hover': {
      backgroundColor: 'red',
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {modify ? "공정 수정" : "공정 등록"}
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputMultipleSelect
              id="multiple-gprocess-step-select"
              name="steps"
              label={"구성요소"}
              control={control}
              setValue={setValue}
              value={steps}
              setMultiselectValue={setSteps}
              options={gcomponentsByProperty}
              initialize={init}
              setInitialize={setInit}
            />
            
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant={hexColor === "" ? "outlined" : "contained"}
              sx={{
                bgcolor: hexColor,
                ':hover': {
                  bgcolor: hexColor,
                },
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              {hexColor === "" ? "색상없음" : "색상"}
            </Button>
            {
              showColorPicker && (
                <div style={popover}>
                  <div style={cover} onClick={() => setShowColorPicker(false)} />
                  <SketchPicker
                    color={hexColor}
                    onChange={(color) => {
                      setHexColor(color.hex);
                      // console.log(color)
                    }}
                    // onSwatchHover={(color, e) => {
                    //   console.log("color", color);
                    // }}
                  />
                </div>
              )
            } 
          </Grid>
          <Grid item xs={12}>
            {/* <Button variant="outlined" onClick={() => append({ name: "" })}>{"결함원인 등록"}</Button> */}
            <Fab
              color="primary"
              size="small"
              variant="extended"
              onClick={() => append({ defect: "" })}
            >
              <Add />
              {"결함원인 등록"}&nbsp;
            </Fab>
          </Grid>
          {
            controlledFields.map((field, index) => {
              return (
                <>
                  <Grid item xs={10}>
                    <TextField size="small" fullWidth {...register(`defects.${index}.defect`)} />
                  </Grid>
                  <Grid item xs={2}>
                  <Fab
                    color="secondary"
                    size="small"
                    variant="extended"
                    onClick={() => remove(index)}
                  >
                    <Delete />
                    {"삭제"}&nbsp;
                  </Fab>
                  </Grid>
                </>
              );
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)}>{modify ? "수정" : "저장"}</Button>
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        <Button onClick={() => {
          reset(defaultValues);
          // setSteps([]);
          setInit(true);
          setHexColor('');
        }}>{"초기화"}</Button>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GProcessDialog;
