// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GDOMESTIC_AUTHS = 'gdomesticAuth/setGDomesticAuths';

const setGDomesticAuths = (gdomesticAuths) => ({
  type: SET_GDOMESTIC_AUTHS,
  payload: gdomesticAuths,
});

export const bulkCreate = ({ gglassId, gdomesticAuths }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gdomesticAuths/bulk`, {
    method: "POST",
    body: JSON.stringify({ gglassId, gdomesticAuths }),
  });
  const data = await response.json();
  dispatch(setGDomesticAuths(data.gdomesticAuths));
  return response;
};

export const selectByGGlassIdDirect = async (gglassId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gdomesticAuths/gglass/${gglassId}`);
  const data = await response.json();
  return data.gdomesticAuths;
}

export const selectByGGlassId = (gglassId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gdomesticAuths/gglass/${gglassId}`);
  const data = await response.json();
  dispatch(setGDomesticAuths(data.gdomesticAuths));
  return response;
}

export const select = (conditions) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gdomesticAuths/query/select`, {
    method: 'POST',
    body: JSON.stringify({ conditions }),
  });
  const data = await response.json();
  dispatch(setGDomesticAuths(data.gdomesticAuths));
  return response;
}

export const selectDirect = async (conditions) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gdomesticAuths/query/select`, {
    method: 'POST',
    body: JSON.stringify({ conditions }),
  });
  const data = await response.json();
  
  return data.gdomesticAuths;
}

const initialState = {
  gdomesticAuths: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GDOMESTIC_AUTHS:
      newState = Object.assign({}, state, { gdomesticAuths: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
