// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config';

const SET_GCLIENT = 'gclient/setGClient';
const REMOVE_GCLIENT = "gclient/removeGClient";
const SET_GCLIENTS = 'gclient/setGClients';

const setGClient = (gclient) => ({
  type: SET_GCLIENT,
  payload: gclient,
});

const removeGClient = () => ({
  type: REMOVE_GCLIENT,
});

const setGClients = (gclients) => ({
  type: SET_GCLIENTS,
  payload: gclients,
});

export const create = ({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "POST",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
};

export const modify = ({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "PUT",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
};

export const modifyAndGetData = ({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "PUT",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return data.gclient;
};

export const leave = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/leave/${id}`, {
    method: "PUT",
  });
  dispatch(setGClient(null));
  return response;
};

export const rejoin = (bizRegNumber) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/rejoin/bizRegNumber/${bizRegNumber}`, {
    method: "PUT",
  });
  dispatch(setGClient(null));
  return response;
};

export const rejoinDirect = async (bizRegNumber) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/rejoin/bizRegNumber/${bizRegNumber}`, {
    method: "PUT",
  });
  const data = await response.json();
  return data.result;
};

export const modifyRejectAlarmEmailYN = ({ id, rejectAlarmEmailYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/rejectAlarmEmail/${id}`, {
    method: "PUT",
    body: JSON.stringify({ rejectAlarmEmailYN }),
  });
  dispatch(setGClient(null));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`);
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/`);
  const data = await response.json();
  return data.gclients;
}

export const selectValidAllDirect = async () => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/valid`);
  const data = await response.json();
  return data.gclients;
}

export const selectAllByIds = (ids) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/select/ids`, {
    method: "POST",
    body: JSON.stringify({ ids }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/${id}`);
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
}

export const selectEmailByBizRegNumberDirect = async (bizRegNumber) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/selectEmail/bizRegNumber/${bizRegNumber}`);
  const data = await response.json();
  return data.email;
}

export const init = () => dispatch => {
  dispatch(setGClient(null));
}

export const selectByTypeByQuery = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByType/${type}`);
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByType04docusByQuery = (type04docus) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByType04docus`, {
    method: "POST",
    body: JSON.stringify({ type04docus }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByType04docusByQueryDirect = async (type04docus) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectByType04docus`, {
    method: "POST",
    body: JSON.stringify({ type04docus }),
  });
  const data = await response.json();
  return data.gclients;
}

export const selectByG04GCertificationCodesByQuery = (g04GCertificationCodes) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByG04GCertificationCodes`, {
    method: "POST",
    body: JSON.stringify({ g04GCertificationCodes }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByG04GCertificationIdsByQuery = (g04GCertificationIds) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByG04GCertificationIds`, {
    method: "POST",
    body: JSON.stringify({ g04GCertificationIds }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const remove = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGClient());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const uploadFileDirect = async (gclientId, formData) => {
  const options = {
    method: 'POST',
    // headers: { 'Content-Type': 'multipart/form-data' }, // TODO : 왜 이것을 지정하면 서버에서 못받지??? 추후 분석
    body: formData,
  }

  // TODO : 아래에서 오류 발생하여 바꾸었는데(사실 같은 방식인데 차이점을 모르겠음) 추후 분석
  // const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${gclientId}/uploadFile`, {
  //   method: "POST",
  //   // headers: {
  //   //   'Content-Type': 'multipart/form-data',
  //   //   // 'Content-type': 'x-www-form-urlencoded',
  //   // },
  //   body: formData,
  // });
  const response = await window.fetch(`${authServerUrl}/api/gclients/${gclientId}/uploadFile`, options);
  
  const data = await response.json();
  return data.file;
};

// TODO : 테스트
export const uploadFileDirect1 = async (gclientId, formData) => {
  const options = {
    method: 'POST',
    body: formData,
  }

  const response = await window.fetch(`${authServerUrl}/api/gclients/${gclientId}/uploadFile1`, options);
  
  const data = await response.json();
  return data.file;
};

export const downloadDirect = async (documentPath) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/downloadFile`, {
    method: "POST",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  // const data = await response.json();
  // return data;
  return response;
};

export const removeDirect = async (gclientId, documentPath) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${gclientId}/deleteFile`, {
    method: "DELETE",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  const data = await response.json();
  return data.file;
};

const initialState = {
  gclient: null,
  gclients: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCLIENT:
      newState = Object.assign({}, state, { gclient: action.payload });
      return newState;
    case REMOVE_GCLIENT:
      newState = Object.assign({}, state, { gclient: null });
      return newState;
    case SET_GCLIENTS:
      newState = Object.assign({}, state, { gclients: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
