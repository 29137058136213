import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { Controller } from "react-hook-form";

// const options = [
//   {
//     label: "Checkbox Option 1",
//     value: "1",
//   },
//   {
//     label: "Checkbox Option 2",
//     value: "2",
//   },
// ];

const FormInputMultipleCheckbox = ({ name, control, options, label, setValue, defaultValues, onChangeCheckValue }) => {
  const [selectedItems, setSelectedItems] = useState(defaultValues?.length > 0 ? defaultValues : []);

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  const generateMultipleCheckBoxOptions = () => {
    return options.map((option) => {
      return (
        <FormControlLabel
          key={option.value}
          label={option.label}
          control={
            <Controller
              name={name}
              render={() => (
                <Checkbox
                  checked={selectedItems.includes(option.value)}
                  onChange={(event) => {
                    handleSelect(option.value);
                    onChangeCheckValue && onChangeCheckValue(event, option.value);
                  }}
                />
              )}
              control={control}
            />
          }
        />
      );
    })
  };

  /**
   * 아래 코드에서 warning 발생
   * React Hook useEffect has missing dependencies: 'name' and 'setValue'. Either include them or
   * remove the dependency array. If 'setValue' changes too often, find the parent component that
   * defines it and wrap that definition in useCallback
   */
  /**
   * we are setting form value manually here
   * Details
   * in this component we are controlling the value and onChange both manually here. That’s why inside
   * the render function we are not using the field prop anymore. To set the value we are taking another
   * new prop named setValue here. This function is a special function of react-hook-form for setting the
   * value manually. You can ask then why are we doing this if we are manually handling the inputs? The
   * answer is when you are using react-hook-form you want all your inputs in one place. So we are giving
   * this MultiSelectCheckbox component a special treatment here so that it works with other components easily.
   */
  useEffect(() => {
    setValue(name, selectedItems);
  }, [selectedItems]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <FormLabel component="legend">{label}</FormLabel>
      <div>
        {generateMultipleCheckBoxOptions()}
      </div>
    </FormControl>
  );
}

export default FormInputMultipleCheckbox;
