import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const AlertDialog = ({ alertInfo, setAlertInfo, setLoading, afterClose }) => {
  const handleClose = () => {
    alertInfo && setAlertInfo({
      ...alertInfo,
      open: false,
    });

    setLoading && setLoading(false);

    afterClose && afterClose()
  }

  return (
    <div>
      <Dialog
        open={alertInfo?.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertInfo?.titleAlert}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertInfo?.messageAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {"확인"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;